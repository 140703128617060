import { Drawer } from '@mui/material'
import { useEffect, useState } from 'react'
import { useCreateConversionCode, useGetConversionCode, useUpdateConversionCode } from './api'
import isEmpty from 'lodash.isempty'
import {
  Button,
  Card,
  Label,
  Layout,
  LoadingDots,
  Select,
  Tag,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { onSearchTradingPartners } from 'common/components/datasources/datasource'
import { ArrowDownward } from '@mui/icons-material'
import { ConversionCode } from 'common/types/kraken-core/ConversionCode'
import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { toast } from 'react-toastify'
import isJson from 'common/helpers/isJSON'
import { isEqual } from 'lodash'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import IaCComponentReferenceWarning from 'atoms/IaCComponentReferenceWarning/IaCComponentReferenceWarning'

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
}

export enum ConversionCodeDrawerMode {
  CREATE = 'create',
  UPDATE = 'update',
}

export interface ConversionCodeDetailsProps {
  id?: string
  mode: ConversionCodeDrawerMode
  open: boolean
  onClose: () => void
}

const TextFieldStyle = {
  color: Themes.Loadsmart['color-danger-dark'].toString(),
  fontWeight: 'bolder',
}

export default function ConversionCodeDetails(props: ConversionCodeDetailsProps) {
  const [detail, setDetail] = useState<ConversionCode>({})
  const [isPendingChanges, setPendingChanges] = useState(false)
  const { data } = useGetConversionCode(
    {
      id: props.id as any,
    },
    {
      enabled: !isEmpty(props.id),
    }
  )
  const {
    mutate: update,
    isLoading: isUpdatingCode,
    isError: isUpdateError,
    error: updateCodeError,
    isSuccess: isUpdateSuccess,
  } = useUpdateConversionCode()

  const {
    mutate: create,
    isLoading: isCreating,
    isError: isErrorToCreate,
    error: createError,
    isSuccess: isCreateSuccess,
  } = useCreateConversionCode()

  const handleFieldChange = (field: string, value: any) => {
    setDetail(c => ({
      ...c,
      [field]: value,
    }))
  }

  const handleSave = () => {
    if (props.mode === ConversionCodeDrawerMode.CREATE) {
      analytics.event({
        category: AnalyticsEvent.ConversionCodesDetailsCreate,
        action: AnalyticsEventTrigger.click,
      })
      create(detail)
    } else {
      analytics.event({
        category: AnalyticsEvent.ConversionCodesDetailsUpdate,
        action: AnalyticsEventTrigger.click,
      })
      update(detail)
    }
  }

  useEffect(() => {
    if (data) {
      setDetail(data)
    }
  }, [data])

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success('Saved successfully')
      return
    }

    if (isUpdateError) {
      toast.error(`Something went wrong when saving: ${updateCodeError}`)
    }
  }, [isUpdateError, isUpdateSuccess])

  useEffect(() => {
    if (isCreateSuccess) {
      toast.success('Created successfully')
      return
    }

    if (isErrorToCreate) {
      toast.error(`Something went wrong when creating: ${createError}`)
    }
  }, [isErrorToCreate, createError, isCreateSuccess])

  useEffect(() => {
    if (props.mode === ConversionCodeDrawerMode.CREATE) {
      setDetail({})
    }
  }, [props.mode])

  useEffect(() => {
    setPendingChanges(!isEqual(data, detail) && props.mode != ConversionCodeDrawerMode.CREATE)
  }, [detail])

  return (
    <Drawer
      open={props.open}
      anchor="right"
      PaperProps={{
        style: {
          width: '30%',
        },
      }}
      onClose={props.onClose}
    >
      <Layout.Stack justify="space-between" className="h-full">
        {detail.iac_reference ? <IaCComponentReferenceWarning /> : null}
        <Layout.Stack className="p-4 w-full" align="center">
          <Card className="w-full">
            <Card.Title>Source</Card.Title>
            <Card.Separator />
            <Card.Body>
              <Layout.Stack space="s">
                <Layout.Stack space="s">
                  <Label>Partner</Label>
                  <Select
                    id="source_partner_selector"
                    name="select_source_trading_partner"
                    onChange={e => {
                      handleFieldChange(
                        'source',
                        e.target.value ? (e.target.value as TradingPartner) : null
                      )
                    }}
                    value={
                      {
                        label: detail.source?.name,
                        value: detail.source?.id,
                      } as any
                    }
                    datasources={DATASOURCES.TRADING_PARTNER}
                  />
                </Layout.Stack>

                <Layout.Stack space="s">
                  <Label>Code</Label>
                  <TextField
                    value={detail.source_code}
                    style={{ ...TextFieldStyle }}
                    onChange={e => handleFieldChange('source_code', e.target.value)}
                  />
                </Layout.Stack>

                <Layout.Stack space="s">
                  <Label>Value</Label>
                  <TextField
                    value={detail.source_value}
                    style={{ ...TextFieldStyle }}
                    onChange={e => handleFieldChange('source_value', e.target.value)}
                  />
                </Layout.Stack>
              </Layout.Stack>
            </Card.Body>
          </Card>
          <ArrowDownward />
          <Card className="w-full">
            <Card.Title>Target</Card.Title>
            <Card.Separator />
            <Card.Body>
              <Layout.Stack space="s">
                <Layout.Stack space="s">
                  <Label>Partner</Label>
                  <Select
                    id="target_partner_selector"
                    name="select_target_trading_partner"
                    onChange={e => {
                      handleFieldChange(
                        'target',
                        e.target.value ? (e.target.value as TradingPartner) : null
                      )
                    }}
                    value={
                      {
                        label: detail.target?.name,
                        value: detail.target?.id,
                      } as any
                    }
                    datasources={DATASOURCES.TRADING_PARTNER}
                  />
                </Layout.Stack>

                <Layout.Stack space="s">
                  <Label>Code</Label>
                  <TextField
                    value={detail.target_code}
                    style={{ ...TextFieldStyle }}
                    onChange={e => handleFieldChange('target_code', e.target.value)}
                  />
                </Layout.Stack>

                <Layout.Stack space="s">
                  <Label>Value</Label>
                  <TextField
                    value={detail.target_value}
                    style={{ ...TextFieldStyle }}
                    onChange={e => handleFieldChange('target_value', e.target.value)}
                  />
                </Layout.Stack>
              </Layout.Stack>
            </Card.Body>
          </Card>

          <Layout.Stack space="s" className="w-full">
            <Layout.Stack align="center" className="w-full">
              <Text variant="body-bold">Use When</Text>
              <FileViewer
                content={JSON.stringify(detail.use_when, null, 2)}
                contentType="application/json"
                options={{
                  heigth: 300,
                }}
                showOptions={false}
                onChange={e => {
                  if (isJson(e)) handleFieldChange('use_when', JSON.parse(e))
                }}
              />
            </Layout.Stack>
          </Layout.Stack>
        </Layout.Stack>

        <Layout.Group justify="flex-end" className="p-4" align="center">
          {isPendingChanges ? (
            <Tag variant="warning" size="large">
              Pending Changes
            </Tag>
          ) : null}
          {!isUpdatingCode && !isCreating ? (
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          ) : (
            <LoadingDots />
          )}
        </Layout.Group>
      </Layout.Stack>
    </Drawer>
  )
}
