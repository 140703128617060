import $RefParser from '@apidevtools/json-schema-ref-parser'
import { defaultsAjv } from './JsonForm'
import type { ErrorObject } from '@jsonforms/core/node_modules/ajv/lib/ajv'

export const defaultRefResolver = async (schema: any) => {
  return await $RefParser.dereference(schema)
}

export const parseErrorsAsString = (errors: ErrorObject[]) => {
  return defaultsAjv.errorsText(errors, { dataVar: '', separator: ' | ' })
}
