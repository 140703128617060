import Field from 'atoms/Field'
import { Label, TextField } from '@loadsmart/loadsmart-ui'
import { IntegrationTemplateFilter } from '../IntegrationOnboardingContext'

export interface TemplateFilterProps {
  filters: Array<IntegrationTemplateFilter>
  updateFilterValue: (filter: IntegrationTemplateFilter, newValue: string) => void
}

function TemplateFilterComponent(props: TemplateFilterProps) {
  function onChange(filter: IntegrationTemplateFilter, newValue: string) {
    props.updateFilterValue(filter, newValue)
  }

  return (
    <>
      {props.filters.map(filter => (
        <Field className="w-full" key={filter.property}>
          <Label>{filter.label}</Label>
          <TextField
            name={`integration-template-${filter.property}-filter`}
            onChange={e => onChange(filter, e.target.value)}
          />
        </Field>
      ))}
    </>
  )
}

export default TemplateFilterComponent
