import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get, post, put, del, patch } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import {
  RuntimeTranslationMap,
  TranslationMap,
  TranslationMapContentType,
  TranslationMapEncoding,
  TranslationMapHistory,
  TranslationMapLoader,
  TranslationTestCase,
  TranslationTestCaseDetail,
  TranslationTestCaseExecutionResult,
} from 'common/types/kraken-core/TranslationMap'

interface PaginationOptions {
  limit: number
  offset: number
}

export interface SearchTranslationMapsPageRequest {
  name?: string
  page?: number
  page_size?: number
}

export type SearchTranslationMapResponse = PaginatedResponse<TranslationMap>

export type SearchRuntimeTranslationMapResponse = PaginatedResponse<RuntimeTranslationMap>

export interface UpdateImplementationRequest {
  id: string
  encoded_implementation?: string
}

export async function searchTranslationMaps(
  data?: SearchTranslationMapsPageRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchTranslationMapResponse>> {
  const params: any = {
    ...data,
    page: data?.page || 1,
    page_size: data?.page_size || 100,
  }

  const [error, response] = await to(get('/translation_maps/settings', { ...config, params }))

  return { error, response }
}

export async function getTranslationMap(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMap>> {
  let error = null
  let response = null
  ;[error, response] = await to(get(`/translation_maps/settings/${id}`, { ...config }))
  return { error, response }
}

export async function searchTranslationMapsLoaders(
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMapLoader[]>> {
  const [error, response] = await to(get('/translation_maps/loaders', { ...config }))

  return { error, response }
}

export async function searchTranslationMapsEncodings(
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMapEncoding[]>> {
  const [error, response] = await to(get('/translation_maps/encodings', { ...config }))

  return { error, response }
}

export async function searchTranslationMapsContentTypes(
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMapContentType[]>> {
  const [error, response] = await to(get('/translation_maps/content_types', { ...config }))

  return { error, response }
}

export async function importTranslationMap(
  params: TranslationMap,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMap>> {
  const [error, response] = await to(post('/translation_maps/settings', params, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function updateTranslationMapSetting(
  params: TranslationMap,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMap>> {
  const [error, response] = await to(
    put(`/translation_maps/settings/${params.id}`, params, { ...config })
  )

  if (error) throw error

  return { error, response }
}

export async function deleteTranslationMapSetting(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMap>> {
  const [error, response] = await to(del(`/translation_maps/settings/${id}`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function updateImplementation(
  params: UpdateImplementationRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const payload = {
    encoded_implementation: params.encoded_implementation,
  }
  const [error, response] = await to(
    patch(`/translation_maps/settings/${params.id}/implementation`, payload, { ...config })
  )
  return { error, response }
}

export async function getTranslationMapHistory(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMapHistory>> {
  const [error, response] = await to(get(`/translation_maps/settings/${id}/history`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function push(
  params: TranslationMap,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    post(`/translation_maps/settings/${params.id}/push`, undefined, { ...config })
  )
  return { error, response }
}

export async function getTranslationTestCase(
  translationMapId: string,
  translationTestCaseId: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PaginatedResponse<TranslationTestCaseDetail>>> {
  let error = null
  let response = null
  ;[error, response] = await to(
    get(`/translation_maps/${translationMapId}/tests/${translationTestCaseId}`, { ...config })
  )
  return { error, response }
}

export async function searchTranslationTestCases(
  translationMapId: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PaginatedResponse<TranslationTestCase>>> {
  let error = null
  let response = null
  ;[error, response] = await to(get(`/translation_maps/${translationMapId}/tests`, { ...config }))
  return { error, response }
}

export async function createTranslationTestCase(
  translationMapId: string,
  translationTestCase: TranslationTestCaseDetail,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationTestCaseDetail>> {
  let error = null
  let response = null
  ;[error, response] = await to(
    post(`/translation_maps/${translationMapId}/tests`, translationTestCase, { ...config })
  )
  return { error, response }
}

export async function updateTranslationTestCase(
  translationMapId: string,
  translationTestCase: TranslationTestCaseDetail,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationTestCaseDetail>> {
  let error = null
  let response = null
  ;[error, response] = await to(
    patch(
      `/translation_maps/${translationMapId}/tests/${translationTestCase.id}`,
      translationTestCase,
      { ...config }
    )
  )
  return { error, response }
}

export async function deleteTranslationTestCase(
  translationMapId: string,
  translationTestCaseId: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationTestCaseDetail>> {
  let error = null
  let response = null
  ;[error, response] = await to(
    del(`/translation_maps/${translationMapId}/tests/${translationTestCaseId}`, { ...config })
  )
  return { error, response }
}

export async function runTranslationTestCase(
  translationMapId: string,
  translationTestCaseId: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationTestCaseExecutionResult>> {
  let error = null
  let response = null
  ;[error, response] = await to(
    post(`/translation_maps/${translationMapId}/tests/${translationTestCaseId}/run`, { ...config })
  )
  return { error, response }
}

export async function searchRuntimeTranslationMaps(
  data?: SearchTranslationMapsPageRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchTranslationMapResponse>> {
  const params: any = {
    ...data,
    page: data?.page || 1,
    page_size: data?.page_size || 100,
  }
  const [error, response] = await to(get('/translation_maps/runtime', { ...config, params }))
  return { error, response }
}

export async function getRuntimeTranslationMap(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationMap>> {
  const [error, response] = await to(get(`/translation_maps/runtime/${id}`, { ...config }))
  return { error, response }
}
