import qs from 'qs'
import { useEffect, useState } from 'react'
import { useDeleteDataModelSchema, useSearchDataModelSchemas } from '../api'
import { ListDataModelSchemaEnginesRequest } from 'services/kraken-core/data_model/types'
import { Breadcrumbs, Button, Card, Layout, LoadingDots, Text } from '@loadsmart/loadsmart-ui'
import DataModelSchemaListFilters from '../components/DataModelSchemaListFilters'
import DataModelSchemaListTable from '../components/DataModelSchemaListTable'
import SimplePagination from 'atoms/SimplePagination/SimplePagination'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { useHistory, useRouteMatch } from 'react-router'
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from 'molecules/ConfirmationDialog/ConfirmationDialog'
import { DataModelSchemaDetail } from 'common/types/kraken-core/DataModelSchema'

export default function DataModelSchemaList() {
  const history = useHistory()
  const { path } = useRouteMatch()

  useTabTitle('Data Models')
  useTopNavigationContext({
    children: (
      <Layout.Group className="w-full" align="center" justify="space-between">
        <Breadcrumbs
          entries={[
            {
              label: 'Data Models',
              active: true,
            },
          ]}
        />
      </Layout.Group>
    ),
  })

  const [filters, setFilters] = useState<ListDataModelSchemaEnginesRequest>({})
  const debouncedFilters = useDebouncedValue(filters, 500)

  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10,
  })
  const { data, refetch, isLoading, isError } = useSearchDataModelSchemas({
    ...debouncedFilters,
    ...pagination,
  })

  const { mutate: deleteModel, data: deletedData } = useDeleteDataModelSchema()

  const [confirmationDialog, setConfirmationDialog] = useState<
    ConfirmationDialogProps | undefined
  >()

  const handleNextPage = () => {
    if (pagination.page) {
      setPagination(c => ({ ...c, page: (c.page || 1) + 1 }))
    }
  }

  const handlePreviousPage = () => {
    if (pagination.page && pagination.page > 1) {
      setPagination(c => ({ ...c, page: (c.page || 1) - 1 }))
    }
  }
  const handleOnPage = (pageSize: number) => {
    setPagination(p => ({ ...p, page_size: pageSize, page: 1 }))
  }

  const handleOnCreateNew = () => {
    history.push(`${path}/create`)
  }

  const showConfirmationDialog = (title: string, content: string, callback: () => void) => {
    setConfirmationDialog({
      title,
      content,
      onConfirm: callback,
      onClose: () => {
        toggleConfirmationDialog()
      },
      open: true,
    })
  }

  const toggleConfirmationDialog = () => {
    setConfirmationDialog(c => {
      if (c) return { ...c, open: false }
      else {
        return {
          content: '',
          onConfirm: () => {},
          open: false,
          title: '',
        }
      }
    })
  }

  const handleOnRemove = (entity: DataModelSchemaDetail) => {
    showConfirmationDialog('Delete Multiple Records', `Are you sure you want to delete?`, () => {
      if (!entity.id) {
        return
      }
      deleteModel(entity.id)
      toggleConfirmationDialog()
    })
  }

  useEffect(() => {
    setPagination(c => ({
      ...c,
      page: 1,
    }))

    window.history.replaceState(null, '', `?${qs.stringify(filters, { skipNulls: true })}`)
  }, [debouncedFilters])

  useEffect(() => {
    refetch()
  }, [deletedData])

  return (
    <>
      {confirmationDialog ? <ConfirmationDialog {...confirmationDialog} /> : null}
      <Card>
        <Layout.Stack>
          <Card.Body>
            <DataModelSchemaListFilters
              filters={filters}
              onChange={changed => {
                setFilters(changed)
              }}
            />
          </Card.Body>

          <Card.Separator />

          <Layout.Group align="flex-end" justify="flex-end" className="w-full pr-4">
            <Button variant="primary" onClick={handleOnCreateNew}>
              Create new
            </Button>
          </Layout.Group>

          <DataModelSchemaListTable
            data={data?.results ?? []}
            onRemove={handleOnRemove}
            isLoading={isLoading}
          />
          <Layout.Box padding="s">
            <SimplePagination
              currentPage={pagination.page ?? 0}
              nextPage={handleNextPage}
              previousPage={handlePreviousPage}
              pageSize={pagination.page_size}
              canPreviousPage={pagination.page > 1}
              canNextPage={Boolean(data?.next)}
              onPageSize={handleOnPage}
            />
          </Layout.Box>
        </Layout.Stack>
      </Card>
    </>
  )
}
