import { Card, Dialog, IconButton, Layout, LoadingDots, Table, Text } from '@loadsmart/loadsmart-ui'
import Icon from 'atoms/Icon'
import { TranslationSettings } from 'common/types/kraken-core/TranslationSettings'
import isEmpty from 'lodash.isempty'
import { ReactChild, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDeleteTranslationSettings } from '../api'

export interface ListProps {
  translationSettingsCollection: Array<TranslationSettings>
  isLoading: boolean
  filters?: ReactChild
  headerActions?: ReactChild
  onEdit?: (translationSettings: TranslationSettings) => void
  onDelete?: (translationSettings: TranslationSettings) => void
}

export default function List({
  translationSettingsCollection,
  filters,
  isLoading = false,
  headerActions,
  onEdit,
  onDelete,
}: ListProps) {
  const [selectedToDelete, setSelectedToDelete] = useState<TranslationSettings>()

  const {
    data: deleted,
    mutate: deleteTranslationSettings,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    error: deletedError,
    reset: deletedReset,
  } = useDeleteTranslationSettings()

  const handleCloseDeleteConfirmation = () => {
    setSelectedToDelete(undefined)
  }

  useEffect(() => {
    if (deletedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isDeleted) {
      toast.success('Deleted with success')
    }

    setTimeout(() => {
      deletedReset()
      if (onDelete) onDelete(deleted)
      setSelectedToDelete(undefined)
    }, 0)
  }, [isDeleted, deletedError])

  return (
    <Layout.Stack className="w-full">
      <Dialog
        open={!!selectedToDelete}
        onOverlayClick={handleCloseDeleteConfirmation}
        scale="small"
      >
        <Dialog.Header>Delete</Dialog.Header>
        <Dialog.Body>Are you sure you want to delete this translation settings?</Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isDeleting}
          onConfirm={() => selectedToDelete?.id && deleteTranslationSettings(selectedToDelete.id)}
        />
        <Dialog.ActionCancel onCancel={handleCloseDeleteConfirmation} />
      </Dialog>

      <Layout.Group className="w-full">{filters}</Layout.Group>
      <Layout.Group className="w-full">
        <Card className="w-full">
          <Card.Body style={{ padding: 0 }}>
            <Layout.Stack>
              <Layout.Switcher>
                <Layout.Group align="center" className="p-4">
                  {Boolean(isLoading) && <LoadingDots />}
                </Layout.Group>

                {Boolean(headerActions) && (
                  <Layout.Group align="center" justify="flex-end" className="p-4">
                    {headerActions}
                  </Layout.Group>
                )}
              </Layout.Switcher>

              <Layout.Group className="p-0">
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell
                        style={{
                          width: '10em',
                        }}
                      >
                        ID
                      </Table.HeadCell>
                      <Table.HeadCell>Name</Table.HeadCell>
                      <Table.HeadCell>Partner</Table.HeadCell>
                      <Table.HeadCell>Translation map</Table.HeadCell>
                      <Table.HeadCell
                        style={{
                          width: '10em',
                        }}
                      >
                        Actions
                      </Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {translationSettingsCollection.map(ts => {
                      const idAsString = ts.id?.slice(0, 8) || '-'
                      return (
                        <Table.Row key={ts.id}>
                          <Table.Cell>{idAsString}</Table.Cell>
                          <Table.Cell>{ts?.name}</Table.Cell>
                          <Table.Cell>{ts.trading_partner?.name}</Table.Cell>
                          <Table.Cell>{ts.translation_map?.name}</Table.Cell>
                          <Table.Cell>
                            <Layout.Group>
                              <IconButton
                                name="edit"
                                onClick={e => {
                                  e.stopPropagation()
                                  if (onEdit) onEdit(ts)
                                }}
                              >
                                <Icon name="edit" />
                              </IconButton>
                              <IconButton
                                name="delete"
                                onClick={e => {
                                  e.stopPropagation()
                                  setSelectedToDelete(ts)
                                }}
                              >
                                <Icon name="remove" />
                              </IconButton>
                            </Layout.Group>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>

                {isEmpty(translationSettingsCollection) && (
                  <Layout.Stack className="p-2" space="l">
                    <Text variant="body-bold">No translation settings available.</Text>
                  </Layout.Stack>
                )}
              </Layout.Group>
            </Layout.Stack>
          </Card.Body>
        </Card>
      </Layout.Group>
    </Layout.Stack>
  )
}
