import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get, post } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { ExpectedReplyMessage } from 'common/types/kraken-core/Message'
import SearchResponse from 'common/types/SearchResponse'

export interface SearchExpectedReplyMessagesRequest {
  page?: number
  page_size?: number
  partner_name?: string
  created_at__gte?: string
  created_at__lte?: string
  updated_at__gte?: string
  updated_at__lte?: string
  status?: string
}

export type SearchExpectedReplyMessagePayload = SearchResponse<ExpectedReplyMessage[]>

export interface UpdateStatusExpectedReplyMessageRequest {
  id: string
  status: string
}
export interface UpdateStatusExpectedReplyMessageResponse {
  message: string
}

export async function searchExpectedReplyMessages(
  data?: SearchExpectedReplyMessagesRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchExpectedReplyMessagePayload>> {
  const params: any = {
    ...data,
  }

  const [error, response] = await to(get('/expected_reply_messages', { ...config, params }))

  return { error, response }
}

export async function getExpectedReplyMessage(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ExpectedReplyMessage>> {
  const [error, response] = await to(get(`/expected_reply_messages/${id}`, { ...config }))

  return { error, response }
}

export async function updateStatusExpectedReplyMessage(
  data: UpdateStatusExpectedReplyMessageRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<UpdateStatusExpectedReplyMessageResponse>> {
  const payload = {
    status: data.status,
  }
  const [error, response] = await to(
    post(`/expected_reply_messages/${data.id}/update_status`, payload, {
      ...config,
    })
  )
  if (error) throw error
  return { error, response }
}
