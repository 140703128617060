import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get, post, del, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import {
  GatewaySetting,
  GatewaySettingHistory,
  GatewaySettingSchema,
  GatewaySettingsTestResult,
  GatewayType,
} from 'common/types/kraken-core/GatewaySettings'

export interface SearchGatewaySettingsRequest {
  page?: number
  page_size?: number
  gateway_type?: string
}

export interface SearchGatewaySettingsPayload {
  next?: string
  previous?: string
  results: GatewaySetting[]
}

export async function searchGatewaySettings(
  data?: SearchGatewaySettingsRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchGatewaySettingsPayload>> {
  const params: any = {
    ...data,
    page: data?.page || 1,
    page_size: data?.page_size || 100,
  }

  const [error, response] = await to(get('/gateway/settings', { ...config, params }))

  return { error, response }
}

export async function getGatewaySettings(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySetting>> {
  const [error, response] = await to(get(`/gateway/settings/${id}`, { ...config }))

  return { error, response }
}

export async function searchGatewayTypes(
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewayType[]>> {
  const [error, response] = await to(get('/gateway/types', { ...config }))

  return { error, response }
}

export async function exportGatewaySettings(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySetting>> {
  const [error, response] = await to(post(`/gateway/settings/${id}/export`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function importGatewaySettings(
  params: object,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySetting>> {
  const [error, response] = await to(post('/gateway/import', params, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function getGatewaySettingsHistory(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySettingHistory>> {
  const [error, response] = await to(get(`/gateway/settings/${id}/history`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function revertHistory(
  params: GatewaySettingHistory,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySettingHistory>> {
  const [error, response] = await to(
    post(`/gateway/settings/${params.id}/history/${params.history_id}/revert`, {}, { ...config })
  )

  if (error) throw error

  return { error, response }
}

export async function deleteGatewaySetting(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySetting>> {
  const [error, response] = await to(del(`/gateway/settings/${id}`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function updateGatewaySetting(
  params: GatewaySetting,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySetting>> {
  const [error, response] = await to(put(`/gateway/settings/${params.id}`, params, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function testGatewaynSetting(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySettingsTestResult>> {
  const [error, response] = await to(post(`/gateway/settings/${id}/test`, {}, { ...config }))
  if (error) throw error
  return { error, response }
}

export async function getSchemaByGatewayType(
  gateway_type: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GatewaySettingSchema>> {
  const [error, response] = await to(get(`/gateway/types/${gateway_type}`, { ...config }))
  if (error) throw error
  return { error, response }
}
