import { ComponentType, createElement } from 'react'
import { Route, RouteProps } from 'react-router-dom'

interface Props extends RouteProps {
  component?: ComponentType
  roles?: string[]
}

function PublicRoute({ component, roles, render, children, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={props => {
        if (component) {
          return createElement(component)
        }

        if (render) {
          return render(props)
        }

        return children
      }}
    />
  )
}

export default PublicRoute
