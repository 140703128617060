import { useEffect, useState } from 'react'
import {
  Breadcrumbs,
  Dialog,
  Dropdown,
  Layout,
  Select,
  Tag,
  Text,
  TextField,
  ToggleGroup,
} from '@loadsmart/loadsmart-ui'
import { LinearProgress, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import SimplePagination from 'atoms/SimplePagination'
import { removeToken } from 'common/helpers/removeToken'
import { GatewaySetting, GatewayType } from 'common/types/kraken-core/GatewaySettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import ErrorPanel from 'molecules/ErrorPanel'
import EventLike from 'common/types/EventLike'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { ConnectionProtocol, ConnectionSetting } from 'common/types/kraken-core/ConnectionSettings'
import { onSearchProtocol } from 'pages/Connections/api'

import { parse as parseQuery, stringfy as stringfyQuery } from 'common/helpers/queryString'
import {
  onSearchGatewayTypes,
  useSearchGatewaySettings,
  onSearchConnections,
  onSearchTradingPartners,
  useDeleteGatewaySettings,
  useUpdateGatewaySetting,
} from '../api'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import ImportModal from 'pages/Gateway/components/ImportModal'
import GatewaySettingsTable from 'pages/Gateway/components/GatewaySettingsTable'
import { formatGatewayType } from 'common/helpers/kraken-core/gatewaySettings.helper'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const ACTIVE_FILTER_VALUES = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
]

const DIRECTION_FILTER_VALUES = [
  {
    label: 'Inbound',
    value: 'inbound',
  },
  {
    label: 'Outbound',
    value: 'outbound',
  },
]

const DATASOURCES = {
  TYPES: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (gt: GatewayType) => gt.gateway_type || '',
        getLabel: (gt: GatewayType) => gt.description || '',
      },
      fetch: onSearchGatewayTypes,
    }),
  ],
  PROTOCOLS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: ConnectionProtocol) => tp.protocol || '',
        getLabel: (tp: ConnectionProtocol) => tp.description || '',
      },
      fetch: onSearchProtocol,
    }),
  ],
  CONNECTIONS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: ConnectionSetting) => tp.id || '',
        getLabel: (tp: ConnectionSetting) => tp.name || '',
      },
      fetch: onSearchConnections,
    }),
  ],
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
}

const GROUP_BY_OPTIONS = [
  {
    label: 'Type',
    value: 'gateway_type',
  },
  {
    label: 'Direction',
    value: 'direction',
  },
  {
    label: 'Protocol',
    value: 'protocol',
  },
]

const GatewaySettingsList = () => {
  useTabTitle('Gateway Settings')
  const urlFilters = parseQuery(window.location.search?.slice(1) ?? '')
  const [breadCrumbs] = useState([
    {
      label: 'Gateway settings',
      active: true,
    },
  ])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [groupByKey, setGroupByKey] = useState<string>()
  const [groupedData, setGroupedData] = useState<any>()
  const [modalMode, setModalMode] = useState<'create' | 'import' | null>(null)
  const [gatewayToDelete, setGatewayToDelete] = useState<GatewaySetting | null>(null)
  const [gatewayToDisabe, setGatewayToDisabe] = useState<GatewaySetting | null>(null)
  const [filters, setFilters] = useState<any>(urlFilters)
  const debouncedFilters = useDebouncedValue<any>(filters, 500)
  const { isLoading, isFetching, error, data } = useSearchGatewaySettings({
    page,
    page_size: pageSize,
    ...debouncedFilters,
  })
  const {
    mutate: deleteGatewaySetting,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    error: deletedError,
    reset: deletedReset,
  } = useDeleteGatewaySettings()

  const {
    mutate: disableGatewaySetting,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    error: updatedError,
    reset: updateReset,
  } = useUpdateGatewaySetting()

  const handleFilterChange = (e: EventLike<any>) => {
    const { name, value } = e.target

    if (!name) return

    setPage(1)
    setFilters((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCloseDeleteConfirmation = () => {
    setGatewayToDelete(null)
  }

  const handleCloseDisableConfirmation = () => {
    setGatewayToDisabe(null)
  }

  const handleGroupBy = (e: EventLike<any>) => {
    if (groupByKey === e.target.value) {
      setGroupByKey(undefined)
    } else setGroupByKey(e.target.value)
  }

  useTopNavigationContext({
    children: (
      <Layout.Group className="w-full" align="center" justify="space-between">
        <Breadcrumbs entries={breadCrumbs} />
        <Dropdown>
          <Dropdown.Trigger>Actions</Dropdown.Trigger>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setModalMode('create')}>Create</Dropdown.Item>
            <Dropdown.Item onClick={() => setModalMode('import')}>Import</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Layout.Group>
    ),
  })

  useEffect(() => {
    if (deletedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isDeleted) {
      toast.success('Deleted with success')
    }

    setTimeout(() => {
      deletedReset()
      setGatewayToDelete(null)
    }, 0)
  }, [isDeleted, deletedError])

  useEffect(() => {
    if (isUpdated) {
      toast.success('Gateway successfully updated')
    }

    setTimeout(() => {
      updateReset()
      setGatewayToDisabe(null)
    }, 0)
  }, [isUpdated, updatedError])

  useEffect(() => {
    window.history.replaceState(null, '', `?${stringfyQuery(filters)}`)
  }, [filters])

  useEffect(() => {
    if (!groupByKey) return
    const groupByCategory = data?.results.reduce((group: any, gateway) => {
      let groupValue = (gateway as any)[`${groupByKey}`]

      if (groupByKey === 'gateway_type') {
        groupValue = formatGatewayType(String(groupValue))
      }

      group[groupValue] = group[groupValue] ?? []
      group[groupValue].push(gateway)
      return group
    }, {})

    setGroupedData(groupByCategory)
  }, [groupByKey, filters, data])

  const invertActiveGatewayStatus = (gateway: GatewaySetting) => {
    const invertStatus = (status: boolean): boolean => !status
    gateway.is_active = invertStatus(gateway.is_active)
    disableGatewaySetting(gateway)
  }

  return (
    <>
      <ImportModal onClose={() => setModalMode(null)} open={!!modalMode} mode={modalMode} />

      <Dialog open={!!gatewayToDelete} onOverlayClick={handleCloseDeleteConfirmation} scale="small">
        <Dialog.Header>Delete</Dialog.Header>
        <Dialog.Body>Are you sure you want to delete this gateway setting?</Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isDeleting}
          onConfirm={() => gatewayToDelete && deleteGatewaySetting(gatewayToDelete.id)}
        />
        <Dialog.ActionCancel onCancel={handleCloseDeleteConfirmation} />
      </Dialog>

      <Dialog
        open={!!gatewayToDisabe}
        onOverlayClick={handleCloseDisableConfirmation}
        scale="small"
      >
        {gatewayToDisabe?.is_active ? (
          <Dialog.Header>Disable</Dialog.Header>
        ) : (
          <Dialog.Header>Enable</Dialog.Header>
        )}
        <Dialog.Body>
          Are you sure you want to change the active status of this gateway?
        </Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isUpdating}
          onConfirm={() => {
            gatewayToDisabe && invertActiveGatewayStatus(gatewayToDisabe)
          }}
        />
        <Dialog.ActionCancel onCancel={handleCloseDisableConfirmation} />
      </Dialog>

      {isLoading ? (
        <Loading className="mt-8" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!data && (
          <Layout.Stack className="">
            <Layout.Stack>
              <Layout.Group justify="space-between" align="flex-start">
                <Layout.Group align="flex-start">
                  <Layout.Stack space="s">
                    <Text>Name</Text>
                    <TextField
                      id="name"
                      name="name"
                      onChange={handleFilterChange}
                      value={filters.name}
                    />
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text>Gateway Type</Text>
                    <Select
                      id="gateway_type"
                      data-testid="gateway_type"
                      name="gateway_type"
                      onChange={e =>
                        handleFilterChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: (e.target.value as GatewayType)?.gateway_type,
                          },
                        })
                      }
                      value={
                        filters.gateway_type
                          ? ({
                              label: filters.gateway_type,
                            } as any)
                          : undefined
                      }
                      datasources={DATASOURCES.TYPES}
                    />
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text>Protocol</Text>
                    <Select
                      id="protocol"
                      data-testid="protocol"
                      name="protocol"
                      onChange={e =>
                        handleFilterChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: (e.target.value as ConnectionProtocol)?.protocol,
                          },
                        })
                      }
                      value={
                        filters.protocol
                          ? ({
                              label: filters.protocol,
                            } as any)
                          : undefined
                      }
                      datasources={DATASOURCES.PROTOCOLS}
                    />
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text>Connection</Text>
                    <Select
                      id="connection"
                      data-testid="connection"
                      name="connection"
                      onChange={e =>
                        handleFilterChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: (e.target.value as ConnectionSetting)?.id,
                          },
                        })
                      }
                      value={
                        filters.connection
                          ? ({
                              label: filters.connection,
                            } as any)
                          : undefined
                      }
                      datasources={DATASOURCES.CONNECTIONS}
                    />
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text>Owner</Text>
                    <Select
                      id="owner_id"
                      name="owner_id"
                      onChange={e =>
                        handleFilterChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: (e.target.value as TradingPartner)?.id,
                          },
                        })
                      }
                      value={
                        filters.owner_id
                          ? ({
                              label: filters.owner_id,
                            } as any)
                          : undefined
                      }
                      datasources={DATASOURCES.TRADING_PARTNER}
                    />
                  </Layout.Stack>
                </Layout.Group>
              </Layout.Group>

              <Layout.Group>
                <Layout.Stack space="s">
                  <Text>Direction</Text>
                  <ToggleGroup
                    id="direction"
                    name="direction"
                    onChange={e => {
                      if (e.target.value === filters['direction']) e.target.value = null
                      handleFilterChange(e)
                    }}
                    options={DIRECTION_FILTER_VALUES}
                    value={filters.direction}
                  />
                </Layout.Stack>
                <Layout.Stack space="s">
                  <Text>Active</Text>
                  <ToggleGroup
                    id="is_active"
                    name="is_active"
                    onChange={e => {
                      if (e.target.value === filters['is_active']) e.target.value = null
                      handleFilterChange(e)
                    }}
                    options={ACTIVE_FILTER_VALUES}
                    value={filters.is_active}
                  />
                </Layout.Stack>
                <Layout.Stack space="s" aria-label="group_by">
                  <Text>Group by</Text>
                  <ToggleGroup
                    id="group_by"
                    name="group_by"
                    onChange={handleGroupBy}
                    options={GROUP_BY_OPTIONS}
                    value={groupByKey}
                  />
                </Layout.Stack>
              </Layout.Group>
            </Layout.Stack>

            <LinearProgress className={isFetching ? 'opacity-100' : 'opacity-0'} />

            {!groupByKey ? (
              <GatewaySettingsTable
                data={data.results || []}
                onRemove={gateway => setGatewayToDelete(gateway)}
                onDisable={gateway => setGatewayToDisabe(gateway)}
              />
            ) : null}

            {groupByKey && groupedData ? (
              <div>
                {Object.keys(groupedData).map(k => {
                  const gateways = groupedData[k]
                  return (
                    <Accordion key={k}>
                      <AccordionSummary>
                        <Layout.Group align="center">
                          <Tag>{k.toUpperCase()}</Tag>
                          <Tag>{gateways.length}</Tag>
                        </Layout.Group>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GatewaySettingsTable
                          data={gateways}
                          onRemove={gateway => setGatewayToDelete(gateway)}
                          onDisable={gateway => setGatewayToDisabe(gateway)}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            ) : null}

            <Layout.Box className="flex justify-end w-full">
              <SimplePagination
                currentPage={page}
                previousPage={() => setPage(prev => prev - 1)}
                nextPage={() => setPage(prev => prev + 1)}
                canPreviousPage={!!data.previous}
                canNextPage={!!data.next}
                onPage={page => setPage(page)}
                pageSize={pageSize}
                onPageSize={page => setPageSize(page)}
              />
            </Layout.Box>
          </Layout.Stack>
        )
      )}
    </>
  )
}

export default GatewaySettingsList
