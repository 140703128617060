import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { authManager } from 'common/authManager'

function LoginPage() {
  const history = useHistory()

  useEffect(() => {
    if (authManager.isAuthenticated()) history.push('/')
  }, [])
  return null
}

export default LoginPage
