import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import ConversionCodesList from './ConversionCodeList'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import { Breadcrumbs, Layout } from '@loadsmart/loadsmart-ui'

export interface ConversionCodesIndexProps {}

export default function ConversionCodesIndex(props: ConversionCodesIndexProps) {
  useTabTitle('Conversion Codes')
  useTopNavigationContext({
    children: (
      <Layout.Group className="w-full" align="center" justify="space-between">
        <Breadcrumbs
          entries={[
            {
              label: 'Conversion Codes',
              active: true,
            },
          ]}
        />
      </Layout.Group>
    ),
  })
  return <ConversionCodesList />
}
