import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Drawer, IconButton, Layout, Text, Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Icon from 'atoms/Icon'
import isJson from 'common/helpers/isJSON'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { useCallback, useMemo, useState } from 'react'
import { CustomRendererMetadata } from '../abstraction'

export const OBJECT_AS_CODE_RENDERER_META: CustomRendererMetadata = {
  type: 'ObjectAsCode',
  label: 'Object As Code',
}

function CustomFileViewer(props: { height: string; content: any; onChange: (value: any) => void }) {
  return (
    <FileViewer
      contentType="application/json"
      content={JSON.stringify(props.content, null, 2)}
      onChange={(value: any) => {
        props.onChange(value)
      }}
      options={{
        heigth: props.height,
      }}
      showOptions={false}
    />
  )
}

function ObjectAsCode(props: ControlProps) {
  const [content, setContent] = useState<any>(props.data)

  const handleChange = useCallback(
    (value: any) => {
      if (!value || !isJson(value)) return
      const convertedContent = JSON.parse(value)
      setContent(convertedContent)
      props.handleChange(props.path, convertedContent)
    },
    [props, content]
  )

  const description = useMemo(() => props.schema.description || props.description, [props])

  return (
    <>
      {props.visible ? (
        <Layout.Stack className="pt-2">
          <Layout.Stack className="pb-2">
            <Accordion>
              <AccordionSummary>
                <Layout.Group align="center">
                  <Tooltip message={description} position={TooltipPosition.Right}>
                    <Icon name="info" size={25} />
                  </Tooltip>
                  <Text variant="body-bold">{props.label}</Text>
                </Layout.Group>
              </AccordionSummary>
              <AccordionDetails data-testid="inline-file-editor">
                <CustomFileViewer
                  content={content}
                  onChange={handleChange}
                  height={`${String(window.innerHeight * 0.3)}px`}
                />
              </AccordionDetails>
            </Accordion>
          </Layout.Stack>
        </Layout.Stack>
      ) : null}
    </>
  )
}

export default withJsonFormsControlProps(ObjectAsCode)
