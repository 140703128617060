import { Button, Card, Layout, LoadingDots, Tag } from '@loadsmart/loadsmart-ui'
import { Drawer, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import isEmpty from 'lodash.isempty'

import FileUploader from 'molecules/FileUploader/FileUploader'
import { useState } from 'react'
import getID from 'common/helpers/getID'
import { validateDataModelSchemaFile } from 'services/kraken-core/data_model/dataModel.service'
import FileViewer from 'molecules/FileViewer/FileViewer'
import isJson from 'common/helpers/isJSON'

export interface DataModelSchemaValidationProps {
  dataModelSchemaId: string
  value?: string
  onChange: (changed: string) => void
}

enum ValidationStatus {
  PENDING = 'Pending',
  RUNNING = 'Running',
  FAILED = 'Failed',
  PASSED = 'Passed',
}

interface ValidationFile {
  id: string
  file: File
  status: ValidationStatus
  details?: string
}

function StatusTag(status: ValidationStatus, onClick: () => void) {
  const variant =
    status === ValidationStatus.FAILED
      ? 'danger'
      : status === ValidationStatus.PASSED
      ? 'accent'
      : 'warning'
  return (
    <Layout.Group align="center">
      <Tag variant={variant} onClick={onClick} className="cursor-pointer">
        {status}
      </Tag>
      {status === ValidationStatus.RUNNING ? <LoadingDots /> : null}
    </Layout.Group>
  )
}

export default function DataModelSchemaValidation(props: DataModelSchemaValidationProps) {
  const [files, setFiles] = useState<ValidationFile[]>([])
  const [selectedValidationFile, setSelectedValidationFile] = useState<ValidationFile>()

  const updateValidationFile = (newState: ValidationFile) => {
    setFiles(c => {
      const allExcept = c.filter(f => f.id != newState.id)
      const toBeUpdated = c.find(f => f.id === newState.id)
      if (!toBeUpdated) return c
      return [
        {
          ...newState,
        },
        ...allExcept,
      ]
    })
  }

  const handleFileUploaded = (acceptedFiles: File[]) => {
    const uploadedFiles: ValidationFile[] = acceptedFiles.map((file: File) => ({
      id: getID(),
      file,
      status: ValidationStatus.PENDING,
    }))

    setFiles(uploadedFiles)
  }

  const handleRun = () => {
    async function validate(validationFile: ValidationFile) {
      updateValidationFile({
        ...validationFile,
        status: ValidationStatus.PENDING,
      })
      const { response, error } = await validateDataModelSchemaFile({
        dataModelSchemaId: props.dataModelSchemaId,
        file: validationFile.file,
      })

      if (error) {
        updateValidationFile({
          ...validationFile,
          status: ValidationStatus.FAILED,
          details: String(error.response?.data),
        })
        return
      }

      if (response)
        updateValidationFile({
          ...validationFile,
          status: response.data.is_valid ? ValidationStatus.PASSED : ValidationStatus.FAILED,
          details: JSON.stringify(response.data.details),
        })
    }

    files.forEach(f => {
      validate(f)
    })
  }

  return (
    <>
      <Drawer
        anchor="right"
        PaperProps={{
          style: {
            width: '50%',
          },
        }}
        variant="temporary"
        open={Boolean(selectedValidationFile)}
        onClose={() => setSelectedValidationFile(undefined)}
      >
        {selectedValidationFile ? (
          <Layout.Stack>
            <Card>
              <Card.Title>
                <Layout.Group align="center">
                  {selectedValidationFile.file.name}
                  {StatusTag(selectedValidationFile.status, () => {})}
                </Layout.Group>
              </Card.Title>
              <Card.Separator />
              <Card.Body>
                <Layout.Stack>
                  <FileViewer
                    contentType={
                      isJson(selectedValidationFile.details || '') ? 'application/json' : 'text'
                    }
                    content={String(selectedValidationFile.details) || ''}
                    options={{
                      heigth: window.innerHeight * 0.8,
                    }}
                  />
                </Layout.Stack>
              </Card.Body>
            </Card>
          </Layout.Stack>
        ) : null}
      </Drawer>

      <Card>
        <Card.Title>Validate sample files over the provided schema</Card.Title>
        <Card.Separator />
        <Card.Body>
          <Layout.Stack>
            <FileUploader data-testid="file-uploader" handleUpload={handleFileUploaded} />

            {!isEmpty(files) ? (
              <Layout.Stack>
                <Layout.Group justify="flex-end">
                  <Button variant="primary" onClick={handleRun}>
                    Validate
                  </Button>
                </Layout.Group>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>File name</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files
                      .sort((a, b) =>
                        a.file.name
                          .toLocaleLowerCase()
                          .localeCompare(b.file.name.toLocaleLowerCase())
                      )
                      .map((f, index) => {
                        return (
                          <TableRow key={`file-row-${index}`}>
                            <TableCell>{f.file.name}</TableCell>
                            <TableCell width={'5%'}>
                              {StatusTag(f.status, () => setSelectedValidationFile(f))}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </Layout.Stack>
            ) : null}
          </Layout.Stack>
        </Card.Body>
      </Card>
    </>
  )
}
