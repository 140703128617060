/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
import { toast } from 'atoms/Toast'
import { AxiosResponse } from 'axios'
import downloadFile from 'common/helpers/downloadFile'
import { MessageAction } from 'common/types/kraken-core/Action'
import { MessageList } from 'common/types/kraken-core/Message'
import useCancelToken from 'hooks/useCancelToken'
// eslint-disable-next-line no-use-before-define
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import {
  getMessageActions,
  getMessages,
  readMessage,
  executeMessageAction as callExecuteMessageAction,
  ExecuteMessageActionPayload,
} from 'services/kraken-core/messages/messages.service'
import {
  convertFiltersToSearchableFilters,
  defaultFieldsValues,
} from './MessageFilter/MessageFilter.helpers'
import { MessageContextStorageManager } from './LocalStorageManager/messagesDashboardContextStoreManager'
import { MessageFilterFields } from './MessagesList/MessageList.types'
import {
  MessageDashboardView,
  MessageDetailWrapper,
  MessageListWrapper,
  MessagesActionsWrapper,
  MessagesPageRequest,
} from './types'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import { adminBaseURL } from 'common/HTTPClient/HTTPClient'
import moment from 'moment'
import { CONSTANTS } from './MessageDahsboardContext'

const messageFietersAnalyticsMappings = {
  status: AnalyticsEvent.MessageDashboardFiltersStatus,
  status_tags: AnalyticsEvent.MessageDashboardFiltersTags,
  reference_code: AnalyticsEvent.MessageDashboardFiltersReferenceCode,
  created_at_from: AnalyticsEvent.MessageDashboardFiltersCreatedAtDate,
  created_at_to: AnalyticsEvent.MessageDashboardFiltersCreatedAtDate,
  updated_at_from: AnalyticsEvent.MessageDashboardFiltersUpdatedAtDate,
  updated_at_to: AnalyticsEvent.MessageDashboardFiltersUpdatedAtDate,
  partner: AnalyticsEvent.MessageDashboardFiltersSelectPartners,
  direction: AnalyticsEvent.MessageDashboardFiltersDirection,
  delivered: AnalyticsEvent.MessageDashboardFiltersDelivered,
  transaction_type: AnalyticsEvent.MessageDashboardFiltersSelectTransactionTypes,
  exclude_transaction_type: AnalyticsEvent.MessageDashboardFiltersExcludedLastStep,
  last_step: AnalyticsEvent.MessageDashboardFiltersLastStep,
  exclude_last_step: AnalyticsEvent.MessageDashboardFiltersExcludedLastStep,
}

export interface MessagesDashboardViewContextState {
  filters: {
    fields: any
    setField: any
    updateField: (field: string, value: any) => void
  }
  messages: MessageListWrapper
  messagesPageRequest: MessagesPageRequest
  messageDetails: MessageDetailWrapper
  messagesActionWapper: MessagesActionsWrapper
  selectMessages: Function
  selectMessageAction: Function
  searchMessages: Function
  executeMessageAction: Function
  readMessageDetails: Function
  clearAllFilters: Function
  updateMessagesPageRequest: Function
}

export const MessagesDashboardViewContext = createContext<
  MessagesDashboardViewContextState | undefined
>(undefined)

export interface MessagesDashboardViewContextWrapperProps {
  view: MessageDashboardView
  onUpdateFilters?: (filters: any) => void
  children: any
}

const DASHBOARD_VIEW_CONSTANTS = {
  messageCacheDetailIntervalTime: 15000,
}

function MessagesDashboardViewContextProvider({
  children,
  onUpdateFilters,
  view,
}: MessagesDashboardViewContextWrapperProps) {
  const _messageFiltersInstace = useRef<MessageContextStorageManager>(
    new MessageContextStorageManager(view)
  )
  const [messageFields, setMessageFields] = useState<Partial<MessageFilterFields>>(view.filters)
  const { getSource, clearSource, cancelPending, isCancel } = useCancelToken()

  const setField = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const analyticEvent = (messageFietersAnalyticsMappings as any)[field]

    if (analyticEvent)
      analytics.event({
        category: analyticEvent,
        action: AnalyticsEventTrigger.change,
      })

    const value: string | null = e.target.value
    _messageFiltersInstace.current.updateFilter(field, value)
    setMessageFields(_messageFiltersInstace.current.getFilters())
  }

  const [messages, setMessages] = useState<MessageListWrapper>({
    count: 0,
    pageCount: 0,
    isLoading: false,
    actions: [],
    list: [],
    canNextPage: true,
    canPreviousPage: true,
    cachedMessages: [],
  })

  const [messagesPageRequest, setMessagesPageRquest] = useState<MessagesPageRequest>({
    index: 1,
    size: view.pageSize,
  })

  const [messageDetails, setMessageDetail] = useState<MessageDetailWrapper>({
    isLoading: false,
    messageStatusTags: [],
  })

  const [messagesActionWrapper, setMessagesActionWrapper] = useState<MessagesActionsWrapper>({
    selectedMessages: [],
    response: undefined,
  })

  const selectMessages = (selectedMessages: Array<MessageList>) => {
    setMessagesActionWrapper(state => ({
      ...state,
      selectedMessages,
    }))
  }

  const searchMessages = async () => {
    // Clear local attributes
    selectMessages([])

    // Will not apply when detail view is opened
    if (view.id != CONSTANTS.DETAIL_VIEW.id) {
      if (!messageFields.created_at_from) {
        toast.warning('Created At From Date is mandatory')
        return
      }
    }

    cancelPending()
    const source = getSource()
    setMessages({ ...messages, isLoading: true })

    const searchFilters = convertFiltersToSearchableFilters({
      fields: {
        ...messageFields,
        created_at_to: !messageFields.created_at_to
          ? moment().add(1, 'days').toISOString()
          : messageFields.created_at_to,
      },
      setField,
    })

    searchFilters.page = messagesPageRequest.index
    searchFilters.pageSize = messagesPageRequest.size

    const [error, response] = await getMessages(searchFilters, { cancelToken: source.token })

    clearSource()

    if (error) {
      if (isCancel(error)) return
      setMessages(state => ({
        ...state,
        list: [],
        pageCount: 0,
        isLoading: false,
        canNextPage: false,
        canPreviousPage: false,
      }))
      toast.error(`Something went wrong: ${error}`)
    } else if (response) {
      setMessages(state => ({
        ...state,
        list: response.data.results,
        count: response.data.count,
        pageCount: response.data.results.length,
        isLoading: false,
        canNextPage: !!response.data.next,
        canPreviousPage: !!response.data.previous,
      }))
    }
  }

  const readMessageDetails = async (uuid: string, doForceReload = false) => {
    // There is a message cached already
    const cachedMessage = messages.cachedMessages.find(m => m.message.id === uuid)
    if (cachedMessage) {
      const expiresAt = moment(cachedMessage.cachedTimestamp).add(
        DASHBOARD_VIEW_CONSTANTS.messageCacheDetailIntervalTime,
        'millisecond'
      )

      // If cache hasn't expired, use it instead of reloading the message
      const isCachedMessageExpired = expiresAt.isBefore(moment.now())
      if (!isCachedMessageExpired && !doForceReload) {
        setMessageDetail({
          ...messageDetails,
          isLoading: false,
          message: {
            ...cachedMessage.message,
          },
          uuid: cachedMessage.message.id,
        })
        return
      }
    }

    cancelPending()
    const source = getSource()
    setMessageDetail({
      ...messageDetails,
      isLoading: true,
    })

    const [error, response] = await readMessage(uuid, {
      cancelToken: source.token,
    })
    clearSource()

    if (error || !response) {
      if (isCancel(error)) return
      setMessageDetail(state => ({
        ...state,
        isLoading: false,
      }))
      toast.error(`Something went wrong: ${error}`)
    } else if (response) {
      const details = {
        ...messageDetails,
        uuid,
        message: response.data,
        isLoading: false,
      }

      // Cache the message
      setMessages(c => ({
        ...c,
        cachedMessages: [
          ...c.cachedMessages.filter(m => m.message.id !== details.message.id),
          {
            cachedTimestamp: Date.now(),
            message: details.message,
          },
        ],
      }))

      setMessageDetail(details)
    }
  }

  const loadMessageActions = async () => {
    const [error, response] = await getMessageActions()
    if (error || !response) {
      console.error(`Something went wrong when loading message actions: ${error}`)
    } else if (response) {
      setMessages(state => ({
        ...state,
        actions: response.data,
      }))
    }
  }

  const clearAllFilters = async () => {
    const fieldsWithDefaultValue: Record<string, any> = {
      ...defaultFieldsValues,
    }

    Object.keys(fieldsWithDefaultValue).forEach(field =>
      setField(field)({
        target: { value: fieldsWithDefaultValue[field] },
      } as React.ChangeEvent<HTMLInputElement>)
    )
  }

  const updateMessagesPageRequest = async (pageIndex?: number, pageSize?: number) => {
    const newPageSize = pageSize || messagesPageRequest.size
    setMessagesPageRquest({
      index: pageIndex || 1,
      size: newPageSize,
    })
    _messageFiltersInstace.current.updatePageSize(newPageSize)
  }

  const selectMessageAction = (messageAction: MessageAction) => {
    setMessagesActionWrapper(state => ({
      ...state,
      action: messageAction,
    }))
  }

  const handleMessageActionExecutionResponse = (
    response: AxiosResponse<Blob>,
    actionDescription: string
  ) => {
    const payloadFileName = 'payload'
    if (response.data) {
      const { data } = response
      const isJsonResponse = data.type === 'application/json'
      if (!isJsonResponse) downloadFile(response.data, payloadFileName, data.type)
      else {
        data.text().then(text => {
          const parsedResponse = JSON.parse(text)
          setMessagesActionWrapper(c => ({
            ...c,
            response: {
              message: 'Action is running on background',
              referenceUrl: `${adminBaseURL}/action/actioncall/${parsedResponse.action_call_id}`,
              type: data.type ?? undefined,
            },
          }))
        })
      }
    }
  }

  const executeMessageAction = async (args: Record<string, string>) => {
    const totaSelectedMessages = messagesActionWrapper.selectedMessages.length

    if (totaSelectedMessages <= 0) {
      toast.warning('Select one or more messages to execute actions')
      return
    }

    const actionDescription = messagesActionWrapper.action?.name || 'Unknow action description'
    const actionKey = messagesActionWrapper.action?.key ?? ''
    const messageIds = messagesActionWrapper.selectedMessages.map(m => m.id ?? '')
    const executeActionPayload: ExecuteMessageActionPayload = {
      key: actionKey,
      messages: messageIds,
      arguments: args,
    }
    setMessages(state => ({ ...state, isLoading: true }))
    const [error, response] = await callExecuteMessageAction(executeActionPayload, {
      responseType: 'blob',
    })
    if (error) {
      toast.error(`Somehting went wrong, ${actionDescription} wasn't executed`)
    } else if (response) {
      handleMessageActionExecutionResponse(response, actionDescription)
    }
    setMessages(state => ({ ...state, isLoading: false }))
    updateMessagesPageRequest(1, messagesPageRequest.size)
    selectMessages([])
  }

  const updateField = (field: string, value: any) => {
    _messageFiltersInstace.current.updateFilter(field, value)
    setMessageFields(_messageFiltersInstace.current.getFilters())
  }

  const contextState = {
    // Properties
    filters: {
      fields: messageFields,
      setField,
      updateField,
    },
    messages,
    messagesPageRequest,
    messageDetails,
    messagesActionWapper: messagesActionWrapper,

    // Functions
    selectMessages,
    selectMessageAction,
    searchMessages,
    executeMessageAction,
    clearAllFilters,
    readMessageDetails,
    updateMessagesPageRequest,
  }

  useEffect(function init() {
    loadMessageActions()
  }, [])

  useEffect(() => {
    // Everytime filters changed, store them
    _messageFiltersInstace.current.setFilters(messageFields)
    _messageFiltersInstace.current.updateQueryString(messageFields)

    if (messagesPageRequest.index > 1) {
      setMessagesPageRquest({
        ...messagesPageRequest,
        index: 1,
      })
    }
    if (onUpdateFilters) onUpdateFilters(messageFields)
  }, [messageFields])

  useEffect(() => {
    searchMessages()
  }, [messagesPageRequest])

  return (
    <MessagesDashboardViewContext.Provider value={contextState}>
      {children}
    </MessagesDashboardViewContext.Provider>
  )
}

export function useMessagesDashboardViewContext() {
  const context = useContext(MessagesDashboardViewContext)
  if (context === undefined) {
    throw new Error(
      'useMessagesDashboardViewContext must be used within a MessagesDashboardViewContextProvider'
    )
  }
  return context
}

export default MessagesDashboardViewContextProvider
