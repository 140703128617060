import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import {
  ConnectionProtocol,
  ConnectionSetting,
  ConnectionSettingHistory,
  ConnectionSettingsSchema,
} from 'common/types/kraken-core/ConnectionSettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import SearchResponse from 'common/types/SearchResponse'
import downloadFile from 'common/helpers/downloadFile'
import {
  deleteConnectionSetting,
  exportConnectionSettings,
  getConnectionSettings,
  getConnectionSettingsHistory,
  getSchemaByProtocol,
  importConnectionSettings,
  revertHistory,
  searchConnectionSettings,
  searchProtocols,
  testConnectionSetting,
  updateConnectionSetting,
  openCircuitBreaker,
  closeCircuitBreaker,
} from 'services/kraken-core/connection_settings/connection_settings.service'
import {
  getTradingPartner,
  searchTradingPartner,
} from 'services/kraken-core/trading_partner/tradingPartner.service'
import { searchGatewaySettings } from 'services/kraken-core/gateway_settings/gateway_settings.service'
import {
  QUERIES,
  useGetConnectionSettingsParams,
  useGetSchemaParams,
  useSearchConnectionSettingsParams,
  useSearchConnectionRelatedGatewayParams as useSearchConnectionRelatedGatewaysParams,
} from './types'
import { GatewaySetting } from '../../common/types/kraken-core/GatewaySettings'

export const useSearchConnectionSettings = (
  params: useSearchConnectionSettingsParams
): UseQueryResult<SearchResponse<ConnectionSetting[]>> => {
  const queryFn = async ({ page }: useSearchConnectionSettingsParams) => {
    const { response } = await searchConnectionSettings({
      ...params,
      page: page || 1,
      page_size: 10,
    })

    return response?.data || {}
  }

  const query = useQuery([QUERIES.SEARCH_CONNECTION_SETTINGS, params], () => queryFn(params), {
    keepPreviousData: true,
  })

  return query
}

export const useGetConnectionSettings = (
  params: useGetConnectionSettingsParams,
  options?: any
): UseQueryResult<ConnectionSetting> => {
  const queryFn = async ({ id }: useGetConnectionSettingsParams) => {
    if (!id) return {}

    const { response } = await getConnectionSettings(id)

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_CONNECTION_SETTINGS, params], () => queryFn(params), {
    enabled: !!params.id,
    ...options,
  })

  return query
}

export const onSearchTradingPartners = async ({
  query,
}: {
  query: string
}): Promise<TradingPartner[]> => {
  const result = await searchTradingPartner({
    filters: [
      {
        property: 'name',
        value: query,
      },
    ],
    page_size: 1000,
  })

  return result?.response?.data?.results
}

export const onSearchProtocol = async ({
  query,
}: {
  query: string
}): Promise<ConnectionProtocol[]> => {
  const result = await searchProtocols()

  return result?.response?.data.filter((p: ConnectionProtocol) =>
    RegExp(query, 'i').test(p.description)
  )
}

export const onExport = async (id: string, name: string) => {
  const result = await exportConnectionSettings(id)

  downloadFile(
    JSON.stringify(result.response?.data || {}),
    `connection_${name}.json`,
    'application/json'
  )
}

export const useSearchProtocols = (): UseQueryResult<ConnectionProtocol[]> => {
  const queryFn = async () => onSearchProtocol({ query: '' })

  const query = useQuery([QUERIES.SEARCH_PROTOCOLS], () => queryFn())

  return query
}

export const useGetTradingPartner = (id?: string): UseQueryResult<TradingPartner> => {
  const queryFn = async (partnerId: string) => {
    const { response } = await getTradingPartner({ id: partnerId })

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_TRADING_PARTNER, id], () => queryFn(id || ''), {
    enabled: !!id,
  })

  return query
}

export const useImportConnectionSetting = (): UseMutationResult<
  any,
  unknown,
  ConnectionSetting,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (params: ConnectionSetting) =>
      importConnectionSettings({
        ...params,
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([QUERIES.SEARCH_CONNECTION_SETTINGS])
      },
    }
  )

  return mutation
}

export const useGetConnectionSettingsHistory = (
  params: useGetConnectionSettingsParams
): UseQueryResult<ConnectionSettingHistory[]> => {
  const queryFn = async ({ id }: useGetConnectionSettingsParams) => {
    if (!id) return {}

    const { response } = await getConnectionSettingsHistory(id)

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_CONNECTION_SETTINGS_HISTORY, params], () => queryFn(params), {
    enabled: !!params.id,
  })

  return query
}

export const useRevertConnectionHistory = (): UseMutationResult<
  any,
  unknown,
  ConnectionSettingHistory,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(async (params: ConnectionSettingHistory) => revertHistory(params), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([QUERIES.GET_CONNECTION_SETTINGS_HISTORY])
    },
  })

  return mutation
}

export const useDeleteConnectionSetting = (): UseMutationResult<any, unknown, string, unknown> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (id: string) => {
      const { response } = await deleteConnectionSetting(id)

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.SEARCH_CONNECTION_SETTINGS])
      },
    }
  )

  return mutation
}

export const useUpdateConnectionSetting = (): UseMutationResult<
  any,
  unknown,
  ConnectionSetting,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (params: ConnectionSetting) => {
      const { response } = await updateConnectionSetting({ ...params })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QUERIES.GET_CONNECTION_SETTINGS,
          QUERIES.SEARCH_CONNECTION_SETTINGS,
        ])
      },
    }
  )

  return mutation
}

export const useTestConnectionSetting = (): UseMutationResult<
  any,
  unknown,
  ConnectionSetting,
  unknown
> => {
  const mutation = useMutation(async (params: ConnectionSetting) => {
    const { response } = await testConnectionSetting(params.id)
    return response?.data
  }, {})

  return mutation
}

export const useGetProtocolSchema = (
  params: useGetSchemaParams,
  options: any
): UseQueryResult<ConnectionSettingsSchema, ConnectionSetting> => {
  const queryFn = async (protocol: string) => {
    const { response } = await getSchemaByProtocol(protocol)

    return response?.data || {}
  }

  const query: UseQueryResult<ConnectionSettingsSchema, ConnectionSetting> = useQuery(
    [QUERIES.GET_CONNECTION_SETTINGS_SCHEMA, params.protocol],
    () => queryFn(params.protocol),
    { ...options }
  )

  return query
}

export const useSearchRelatedGateways = (
  params: useSearchConnectionRelatedGatewaysParams
): UseQueryResult<SearchResponse<GatewaySetting[]>> => {
  const queryFn = async ({ page, ...filters }: useSearchConnectionRelatedGatewaysParams) => {
    const { response } = await searchGatewaySettings({
      ...filters,
      page: page || 1,
      page_size: 10,
    })

    return response?.data || {}
  }
  const query = useQuery([QUERIES.SEARCH_RELATED_GATEWAY, params], () => queryFn(params), {
    keepPreviousData: true,
  })
  return query
}

export const useOpenCircuitBreaker = (): UseMutationResult<
  any,
  unknown,
  ConnectionSetting,
  unknown
> => {
  const mutation = useMutation(async (entity: ConnectionSetting) => {
    const { error, response } = await openCircuitBreaker(entity.id)
    if (error) throw new Error(error.message)
    return response?.data
  })
  return mutation
}

export const useCloseCircuitBreaker = (): UseMutationResult<
  any,
  unknown,
  ConnectionSetting,
  unknown
> => {
  const mutation = useMutation(async (entity: ConnectionSetting) => {
    const { error, response } = await closeCircuitBreaker(entity.id)
    if (error) throw new Error(error.message)
    return response?.data
  })
  return mutation
}
