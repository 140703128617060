/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
enum Environment {
  Dev = 'dev',
  QA = 'qa',
  Sandbox = 'sandbox',
  Staging = 'staging',
  Production = 'production',
  Test = 'test',
}

export default Environment
