import { useEffect, useRef, useState } from 'react'
import { DuplicateDashboardView, TemplateOnboardDashboardView } from './types'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import { Layout, Modal } from '@loadsmart/loadsmart-ui'
import TemplateOnboard from './TemplateOnboard'
import { Menu, MenuItem, Tab, Tabs } from '@mui/material'
import Icon from 'atoms/Icon'
import { Button, IconButton, Label, Switch, Text, TextField } from '@loadsmart/loadsmart-ui'
import { CONSTANTS, useTemplateOnboardDashboardContext } from './TemplateOnboardDashboardContext'
import { capitalize } from 'lodash'
import Divider from 'atoms/Divider'
import styled from 'styled-components'
import TemplateOnboardContextProvider from './TemplateOnboardContext'

enum ViewMenuModalAction {
  NEW = 'create',
  UPDATE = 'rename',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
}

const CustomTabs = styled(props => <Tabs {...props} />)({
  paddingLeft: '0.25rem',
  paddingRight: '0.25rem',
})

const CustomViewTab = styled(props => <Tab {...props} />)({
  '&.MuiTab-root': {
    minHeight: '0',
    '&:active': {
      outline: 'none',
    },
    maxWidth: '50vw',
  },
  '&.Mui-selected': {
    outline: 'none',
  },
})

const CustomDottedIcon = styled(props => (
  <div {...props}>
    <Icon name="dots-horizontal" size={20} />
  </div>
))`
  transform: rotate(90deg);
`

const MAX_TAB_VIEW_CHARACTERSLENGTH = 50

function TemplateOnboardDashboard() {
  const dashboardContext = useTemplateOnboardDashboardContext()
  const [activeView, setActiveView] = useState<TemplateOnboardDashboardView | undefined>()
  const [viewMenuAnchor, setViewMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [viewMenuModalMode, setViewMenuModalMode] = useState<string>()
  const [inheriteDataFromActiveView, setinheriteDataFromActiveView] = useState<boolean>(false)
  const [selectedView, setSelectedView] = useState<TemplateOnboardDashboardView>()

  const viewRef = useRef<TemplateOnboardDashboardView>()

  const isViewMenuOpened = Boolean(viewMenuAnchor)

  const handleOnMenuViewOpen = (element: HTMLElement) => {
    setViewMenuAnchorEl(element)
  }

  const handleCloseViewMenu = () => {
    setViewMenuAnchorEl(null)
  }

  const handleViewAction = (view: TemplateOnboardDashboardView, action: ViewMenuModalAction) => {
    setSelectedView(view)
    setViewMenuModalMode(action)
    handleCloseViewMenu()
  }

  const handleCloseViewMenuModal = () => {
    setSelectedView(undefined)
  }
  const handleCreateNewView = () => {
    const index = dashboardContext.getNextViewIndex()
    setSelectedView({
      id: Math.random().toString(),
      index,
      name: `New view - ${index}`,
      data: dashboardContext.template?.data,
      templateID: dashboardContext.getActiveTemplateIndex(),
    })
    setinheriteDataFromActiveView(true)
    setViewMenuModalMode(ViewMenuModalAction.NEW)
  }

  const handleConfirmViewMenuModalAction = () => {
    if (!viewRef.current) return

    switch (viewMenuModalMode) {
      case ViewMenuModalAction.NEW:
        analytics.event({
          category: AnalyticsEvent.TemplateOnboardDashboardViewsCreate,
          action: AnalyticsEventTrigger.click,
          params: {
            ...viewRef.current,
          },
        })
        dashboardContext.createNewView({ ...viewRef.current })

        break
      case ViewMenuModalAction.DELETE:
        analytics.event({
          category: AnalyticsEvent.TemplateOnboardDashboardViewsRemove,
          action: AnalyticsEventTrigger.click,
          params: {
            ...viewRef.current,
          },
        })
        dashboardContext.removeView(viewRef.current)
        break
      case ViewMenuModalAction.DUPLICATE:
        const duplicate: DuplicateDashboardView = {
          source: { ...viewRef.current },
        }
        analytics.event({
          category: AnalyticsEvent.TemplateOnboardDashboardViewsDuplicate,
          action: AnalyticsEventTrigger.click,
          params: {
            ...duplicate,
          },
        })
        dashboardContext.duplicateView(duplicate)
        break
      case ViewMenuModalAction.UPDATE:
        analytics.event({
          category: AnalyticsEvent.TemplateOnboardDashboardViewsUpdate,
          action: AnalyticsEventTrigger.click,
          params: {
            ...viewRef.current,
          },
        })
        dashboardContext.updateView(viewRef.current)
        break

      default:
        break
    }
    handleCloseViewMenuModal()
  }

  const getFormattedViewName = (view: TemplateOnboardDashboardView) => {
    return view.name.length > MAX_TAB_VIEW_CHARACTERSLENGTH
      ? `${view.name.slice(0, MAX_TAB_VIEW_CHARACTERSLENGTH)}...`
      : view.name
  }

  useEffect(() => {
    if (selectedView) {
      viewRef.current = { ...selectedView }
    }
  }, [selectedView])

  useEffect(() => {
    const views = dashboardContext.getViews()

    setActiveView(views?.find(v => v.id === dashboardContext.activeView))
  }, [dashboardContext.activeView, dashboardContext.getViews()])

  const views = dashboardContext.getViews()

  return (
    <div>
      <Modal open={selectedView ? true : false}>
        {selectedView ? (
          <Layout.Stack>
            <Layout.Group justify="space-between" align="center">
              <Text variant="heading-md-bold">{`${capitalize(viewMenuModalMode)} view`}</Text>
              <IconButton onClick={handleCloseViewMenuModal}>
                <Icon name="close" />
              </IconButton>
            </Layout.Group>
            <Divider />

            {viewMenuModalMode === ViewMenuModalAction.NEW ||
            viewMenuModalMode === ViewMenuModalAction.UPDATE ||
            viewMenuModalMode === ViewMenuModalAction.DUPLICATE ? (
              <Layout.Stack>
                <Label>Name</Label>
                <TextField
                  defaultValue={selectedView.name}
                  placeholder="view name"
                  onChange={e => {
                    if (viewRef.current) viewRef.current.name = e.target.value
                  }}
                />
                {viewMenuModalMode === ViewMenuModalAction.NEW ? (
                  <Layout.Group align="center">
                    <Text variant="caption">Apply current selected Data?</Text>
                    <Switch
                      active={inheriteDataFromActiveView}
                      onToggle={() => setinheriteDataFromActiveView(c => !c)}
                    />
                  </Layout.Group>
                ) : null}
              </Layout.Stack>
            ) : null}

            {viewMenuModalMode === ViewMenuModalAction.DELETE ? (
              <Layout.Stack>
                <Text>
                  Are you sure you want to completly delete view{' '}
                  <Text variant="body-bold">{selectedView?.name}</Text>?
                </Text>
              </Layout.Stack>
            ) : null}

            <Layout.Group justify="center" align="center">
              <Button onClick={handleCloseViewMenuModal}>Cancel</Button>
              <Button variant="primary" onClick={handleConfirmViewMenuModalAction}>
                Confirm
              </Button>
            </Layout.Group>
          </Layout.Stack>
        ) : null}
      </Modal>
      <CustomTabs
        value={dashboardContext.activeView}
        scrollButtons="auto"
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
      >
        {views &&
          views.map(view => {
            return (
              <CustomViewTab
                id={view.id}
                key={view.id}
                value={view.id}
                onClick={() => {
                  dashboardContext.setActiveView(view.id)
                }}
                title={view.name}
                label={
                  <Layout.Group align="center" space="s" justify="space-between">
                    <Text>{getFormattedViewName(view)}</Text>

                    <CustomDottedIcon
                      onClick={(e: any) => {
                        handleOnMenuViewOpen(e.currentTarget)
                      }}
                    />

                    <Menu
                      anchorEl={viewMenuAnchor}
                      open={isViewMenuOpened && dashboardContext.activeView === view.id}
                      onClose={handleCloseViewMenu}
                    >
                      {view.id != CONSTANTS.DEFAULT_VIEW.id ? (
                        <MenuItem
                          onClick={() => handleViewAction(view, ViewMenuModalAction.UPDATE)}
                        >
                          <Layout.Group>
                            <Icon name="edit" />
                            <Text>Edit view</Text>
                          </Layout.Group>
                        </MenuItem>
                      ) : null}

                      <MenuItem
                        onClick={() => handleViewAction(view, ViewMenuModalAction.DUPLICATE)}
                      >
                        <Layout.Group>
                          <Icon name="copy" />
                          <Text>Duplicate view</Text>
                        </Layout.Group>
                      </MenuItem>
                      {view.id != CONSTANTS.DEFAULT_VIEW.id ? (
                        <MenuItem
                          onClick={() => handleViewAction(view, ViewMenuModalAction.DELETE)}
                        >
                          <Layout.Group>
                            <Icon name="remove" />
                            <Text>Delete view</Text>
                          </Layout.Group>
                        </MenuItem>
                      ) : null}
                    </Menu>
                  </Layout.Group>
                }
              />
            )
          })}
        <CustomViewTab
          id="new-view"
          icon={<Icon name="add" />}
          iconPosition="end"
          label={<Text variant="body-bold">New view</Text>}
          onClick={handleCreateNewView}
        />
      </CustomTabs>
      {activeView && dashboardContext.template ? (
        <div key={activeView.id}>
          <TemplateOnboardContextProvider
            viewId={activeView.id}
            template={dashboardContext.template}
          >
            <TemplateOnboard />
          </TemplateOnboardContextProvider>
        </div>
      ) : null}
    </div>
  )
}

export default TemplateOnboardDashboard
