import { BannerAction, Button, Layout, Modal, Steps } from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import { removeToken } from 'common/helpers/removeToken'
import { RouteRead } from 'common/types/kraken-core/Routes'
import ErrorPanel from 'molecules/ErrorPanel'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { useCallback, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { useCreateRoute } from '../api'
import RouteDetailForm from './RouteDetailForm'

export enum RouteCreateModalMode {
  CREATE = 'create',
  IMPORT = 'import',
}

export interface RouteCreateModalProps {
  mode: RouteCreateModalMode
  onClose?: () => void
  opened: boolean
}

const importSteps: any = [
  {
    complete: false,
    id: 'upload-step',
    label: 'Upload',
  },
  {
    complete: false,
    id: 'verify-step',
    label: 'Verify Data',
  },
  {
    complete: false,
    id: 'finish-step',
    label: 'Finish',
  },
]

const createSteps: any = [
  {
    complete: false,
    id: 'form-step',
    label: 'Route Data',
  },
  {
    complete: false,
    id: 'finish-step',
    label: 'Finish',
  },
]

const emptyNewRoute: RouteRead = {
  definition: {
    execution: '',
    steps: [],
  },
}

const RouteCreateModal = ({ mode, onClose, opened }: RouteCreateModalProps) => {
  const history = useHistory()
  const [importedData, setImportedData] = useState<any>()
  const [currentStep, setCurrentStep] = useState<string>(
    mode === RouteCreateModalMode.CREATE ? createSteps[0].id : importSteps[0].id
  )
  const [model, setModel] = useState<RouteRead>()
  const {
    mutate: createRoute,
    data: createdRoute,
    isLoading: isCreatingRoute,
    isSuccess: isCreateRouteSucces,
    isError: isCreateRouteError,
    error: createRouteError,
  } = useCreateRoute()

  const handleCreate = async () => {
    try {
      if (!model) throw new Error("There's something wrong with model data")
      createRoute(model)
    } catch (error) {
      toast.error(`Failure when creating route: ${error}`)
    }
  }

  const handleOnClose = () => {
    if (onClose) onClose()
  }

  const handleFileUploaded = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()

      reader.onerror = () => {
        toast.error("There's some problems in your file, please review.")
      }

      reader.onload = () => {
        const binaryStr = reader.result
        setImportedData((prevState: any) => ({
          ...prevState,
          ...JSON.parse(binaryStr?.toString() || '{}'),
        }))
      }
      reader.readAsText(file)
    })
  }, [])

  useEffect(() => {
    setCurrentStep(mode === RouteCreateModalMode.CREATE ? createSteps[0].id : importSteps[0].id)
  }, [mode])

  useEffect(() => {
    if (createRouteError || isCreateRouteError) {
      toast.error(`Error when creating route: ${createRouteError}`)
    }

    if (isCreateRouteSucces) toast.success(`Route successfully created`)
  }, [isCreateRouteError, createRouteError, isCreateRouteSucces])

  return (
    <>
      <Modal className="step-modal" open={opened} scale="large">
        <Steps
          steps={mode === RouteCreateModalMode.CREATE.toString() ? createSteps : importSteps}
          current={currentStep}
          id={'create-steps'}
          className="stick"
        >
          {/* Create */}
          <Steps.Step id="form-step">
            <Layout.Stack>
              <RouteDetailForm
                route={emptyNewRoute}
                onChange={changed => {
                  setModel(changed)
                }}
              />
              <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button
                  variant="primary"
                  disabled={!model}
                  onClick={() => {
                    handleCreate()
                    setCurrentStep('finish-step')
                  }}
                >
                  Next
                </Button>
              </Layout.Group>
            </Layout.Stack>
          </Steps.Step>

          {/* Import */}
          <Steps.Step id="upload-step">
            <Layout.Stack>
              <Dropzone onDrop={handleFileUploaded} accept="application/json">
                {({ getRootProps, getInputProps }) => (
                  <div>
                    <div
                      {...getRootProps()}
                      className="bg-neutral-lighter p-8 border-dashed border-primary-500 border rounded"
                    >
                      <input {...getInputProps()} />
                      <p>Drag and drop some files here, or click to select files</p>
                    </div>
                  </div>
                )}
              </Dropzone>
              {importedData && (
                <FileViewer
                  options={{}}
                  content={JSON.stringify(importedData, null, 2)}
                  contentType="application/json"
                  showOptions={false}
                />
              )}

              <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button
                  variant="primary"
                  disabled={!importedData}
                  onClick={() => setCurrentStep('verify-step')}
                >
                  Next
                </Button>
              </Layout.Group>
            </Layout.Stack>
          </Steps.Step>

          <Steps.Step id="verify-step">
            <Layout.Stack>
              <RouteDetailForm
                route={importedData}
                onChange={changed => {
                  setModel(changed)
                }}
              />

              <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button onClick={() => setCurrentStep('upload-step')}>Back</Button>
                <Button
                  variant="primary"
                  disabled={!importedData}
                  onClick={() => {
                    handleCreate()
                    setCurrentStep('finish-step')
                  }}
                >
                  Next
                </Button>
              </Layout.Group>
            </Layout.Stack>
          </Steps.Step>

          <Steps.Step id="finish-step">
            <Layout.Stack>
              {isCreatingRoute && <Loading className="mt-8 justify-center" />}
              {createRouteError && (
                <ErrorPanel error={removeToken(JSON.stringify(createRouteError, null, 2))} />
              )}
              {createdRoute && (
                <BannerAction
                  action="Click here to see"
                  onActionButtonClick={() => {
                    history.push(`routes/${createdRoute.id}`)
                  }}
                  scale="default"
                  title="Route successfully created"
                  variant="success"
                  dismissible={false}
                />
              )}
              <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
                <Button onClick={() => setCurrentStep('verify-step')}>Back</Button>
                <Button onClick={handleOnClose}>Close</Button>
              </Layout.Group>
            </Layout.Stack>
          </Steps.Step>
        </Steps>
      </Modal>
    </>
  )
}

export default RouteCreateModal
