import {
  Banner,
  Layout,
  LoadingDots,
  Switch,
  Tabs,
  Tag,
  Text,
  Tooltip,
} from '@loadsmart/loadsmart-ui'
import {
  ConnectionSetting,
  ConnectionSettingsSchema,
} from 'common/types/kraken-core/ConnectionSettings'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import JsonForm, { defaultsAjv } from 'molecules/JsonForm/JsonForm'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { GatewaySetting } from 'common/types/kraken-core/GatewaySettings'
import { useSearchProtocols } from 'pages/Connections/api'
import localGatewayTypeSchemas from '../schema/types'
import localGatewayProtocolSchemas from '../schema/protocols'
import ProcessorCollection from '../../../common/components/processor/ProcessorCollection'
import { useGetGatewaySchema } from '../api'
import Icon from 'atoms/Icon'
import { parseErrorsAsString } from 'molecules/JsonForm/common'

export interface DefinitionValidationResult {
  isValid: boolean
  errors?: string
}

export interface GatewayDefinitionProps {
  gateway: GatewaySetting
  connection?: ConnectionSetting
  onChangeRawDefinition: (value: any) => void
  onChangeGatewayTypeDefinition: (value: any) => void
  onValidationResult: (result: DefinitionValidationResult) => void
}

const FallbackErrorRoot = ({ error, resetErrorBoundary }: any) => (
  <div>Error when rendering definition: {error}</div>
)

const FallbackErrorProcessorForm = ({ error, resetErrorBoundary }: any) => (
  <div>Error when rendering Processors form: {error}</div>
)

const FallbackErrorGatewayTypeForm = ({ error, resetErrorBoundary }: any) => (
  <div>Error when rendering Gateway Type form: {error}</div>
)

const PRE_PROCESSOR_KEY = 'gateway:processors:pre'
const POST_PROCESSOR_KEY = 'gateway:processors:post'
const GATEWAY_TYPES_NOT_BASED_ON_PROTOCOL = [
  'gateway:input:shipment-events',
  'gateway:input:load-events',
  'gateway:input:as2',
  'gateway:input:carrier-events',
]

function GatewaySettingsDefitinion({
  gateway,
  connection,
  onChangeRawDefinition,
  onChangeGatewayTypeDefinition,
  onValidationResult,
}: GatewayDefinitionProps) {
  const [rawDefinition, setRawDefinition] = useState<any>(gateway.definition)
  const [isRawDefinitionView, setRawDefintionView] = useState<boolean>(false)

  const [gatewayTypeSchema, setGatewayTypeSchema] = useState({})
  const [gatewayTypeUiSchema, setGatewayTypeUiSchema] = useState()
  const hasGeneralGatewayTypeDefinition = useRef<boolean>(false)
  const hasProtocolDefinition = useRef<boolean>(false)

  const [protocolSchema, setProtocolSchema] = useState({})
  const [protocolUiSchema, setProtocolUiSchema] = useState()
  const [protocolData, setProtocolData] = useState({})

  const [protocolDefinitionValidationResult, setProtocolDefinitionValidationResult] = useState<
    DefinitionValidationResult
  >({
    isValid: true,
  })
  const [generalDefinitionValidationResult, setGeneralDefinitionValidationResult] = useState<
    DefinitionValidationResult
  >({
    isValid: true,
  })

  const [gatewayTypeDefinitionData, setGatewayTypeDeftinionData] = useState<any>({})

  const remoteGatewayTypeSchema = useRef<ConnectionSettingsSchema | undefined>(undefined)
  const [preProcessors, setPreProcessors] = useState<any>(rawDefinition[PRE_PROCESSOR_KEY])
  const [postProcessors, setPostProcessors] = useState<any>(rawDefinition[POST_PROCESSOR_KEY])

  const { isLoading: isLoadingGatewayTypeSchema } = useGetGatewaySchema(
    {
      gateway_type: gateway.gateway_type,
    },
    {
      enabled: !!gateway.gateway_type,
      onSuccess: (data: ConnectionSettingsSchema) => {
        remoteGatewayTypeSchema.current = data
      },
    }
  )

  const { data: protocols } = useSearchProtocols()

  const processProtocolValidationResult = useCallback(
    async (output: any) => {
      let validationResult: DefinitionValidationResult = {
        isValid: true,
      }
      if (output.errors && output.errors.length > 0) {
        const errors = parseErrorsAsString(output.errors)
        validationResult = {
          isValid: !errors,
          errors,
        }
      }
      setProtocolDefinitionValidationResult(validationResult)
    },
    [gatewayTypeDefinitionData]
  )

  const processGeneralDefinitionValidationResult = async (output: any) => {
    let validationResult: DefinitionValidationResult = {
      isValid: true,
    }
    if (output.errors && output.errors.length > 0) {
      const errors = parseErrorsAsString(output.errors)
      validationResult = {
        isValid: !errors,
        errors,
      }
    }
    setGeneralDefinitionValidationResult(validationResult)
  }

  const updateRawDefinitionByKey = (key: string, value: any) => {
    const definition = {
      ...rawDefinition,
      [key]: value,
    }

    setRawDefinition(definition)
  }

  const initializeDefinition = () => {
    // Will initialize all definition break down into separated blocks based on the raw definition
    const definition = rawDefinition

    setPreProcessors(
      Array.isArray(definition[PRE_PROCESSOR_KEY]) ? definition[PRE_PROCESSOR_KEY] : []
    )
    setPostProcessors(
      Array.isArray(definition[POST_PROCESSOR_KEY]) ? definition[POST_PROCESSOR_KEY] : []
    )

    if (gateway?.gateway_type) {
      const gatewayType = gateway.gateway_type
      const gatewayTypeCurrentDefinition = definition[gatewayType] || {}

      let { [gatewayType]: localSchema } = localGatewayTypeSchemas

      // Override remote schema in favor of local if any available
      if (remoteGatewayTypeSchema.current) {
        let localGatewayTypeSchemaModel
        if (localSchema && localSchema.model) localGatewayTypeSchemaModel = localSchema.model
        const remoteGatewayTypeSchemaModel = JSON.parse(
          remoteGatewayTypeSchema.current.schema || '{}'
        )
        localSchema = {
          ...localSchema,
          model: localGatewayTypeSchemaModel ?? remoteGatewayTypeSchemaModel,
        }
      }

      // If there's no schema, forward to raw view
      if (!localSchema) {
        setGatewayTypeDeftinionData(gatewayTypeCurrentDefinition)
        setGatewayTypeSchema({})
        setGatewayTypeUiSchema(undefined)
        setRawDefintionView(true)
        return
      }

      // If there's schema build break down
      if (localSchema.model) {
        // Update gateway schema spliting general and protocol definitions
        hasProtocolDefinition.current =
          GATEWAY_TYPES_NOT_BASED_ON_PROTOCOL.indexOf(gatewayType) < 0 &&
          connection?.protocol !== undefined
        if (hasProtocolDefinition.current) {
          // Find current target protocol and setup the respective schema
          const currentTargetProcotol = connection?.protocol || 'unknown'
          const extractedProtocolSchema = {
            ...localSchema.model,
            properties: {
              [currentTargetProcotol]: localSchema.model.properties[currentTargetProcotol] || {
                type: 'object',
              },
            },
          }
          const protocolExtractedData = {
            [currentTargetProcotol]: gatewayTypeCurrentDefinition[currentTargetProcotol] || {},
          }

          const {
            [currentTargetProcotol]: localGatewayProtocolSchema,
          } = localGatewayProtocolSchemas

          setProtocolSchema(extractedProtocolSchema)
          setProtocolData(protocolExtractedData)
          setProtocolUiSchema(
            (localGatewayProtocolSchema && localGatewayProtocolSchema.ui) || undefined
          )
        }

        // Find general gateway properties and group them
        const gatewayTypeGeneralPropertyKeys = Object.keys(localSchema.model.properties).filter(
          key => {
            const isProtocol = protocols?.find(p => p.protocol === key)
            return !isProtocol
          }
        )

        let gatewayTypeGeneralProperties = {}
        gatewayTypeGeneralPropertyKeys.forEach(gatewayPropertyKey => {
          gatewayTypeGeneralProperties = {
            ...gatewayTypeGeneralProperties,
            [gatewayPropertyKey]: localSchema.model.properties[gatewayPropertyKey],
          }
        })

        hasGeneralGatewayTypeDefinition.current =
          Object.keys(gatewayTypeGeneralProperties).length > 0
        localSchema.model = {
          ...localSchema.model,
          properties: {
            ...gatewayTypeGeneralProperties,
          },
        }

        setGatewayTypeDeftinionData(gatewayTypeCurrentDefinition)
        setGatewayTypeSchema(localSchema.model)
        setGatewayTypeUiSchema(localSchema.ui)
        setRawDefintionView(false)
      }
    }
  }

  const onChangeGatewayTypeGeneralDefinition = (value: any) => {
    // Update local state
    setGatewayTypeDeftinionData(value.data)

    // Local callbacks
    updateRawDefinitionByKey(gateway.gateway_type, value.data)
    processGeneralDefinitionValidationResult(value)

    // Callback parent component
    onChangeGatewayTypeDefinition(value.data)
  }

  const onChangeProtocolDefinition = (value: any) => {
    if (!connection?.protocol || !value) return

    const newData = {
      ...gatewayTypeDefinitionData,
      [connection.protocol]: value.data[connection.protocol],
    }
    // Update local state
    setProtocolData(value.data)
    setGatewayTypeDeftinionData(newData)

    // Local callbacks
    updateRawDefinitionByKey(gateway.gateway_type, newData)
    processProtocolValidationResult(value)

    // Callback parent component
    onChangeGatewayTypeDefinition(newData)
  }

  const getPostProcessorsFromRawDefinition = () => {
    return rawDefinition[POST_PROCESSOR_KEY] || []
  }

  const getPreProcessorsFromRawDefinition = () => {
    return rawDefinition[PRE_PROCESSOR_KEY] || []
  }

  useEffect(() => {
    // Force async update
    setTimeout(() => {
      setRawDefinition(gateway.definition)
      initializeDefinition()
    }, 0)
  }, [gateway.definition])

  useEffect(() => {
    remoteGatewayTypeSchema.current = undefined
    initializeDefinition()
  }, [gateway.gateway_type])

  useEffect(() => {
    initializeDefinition()
  }, [connection])

  useEffect(() => {
    if (!remoteGatewayTypeSchema.current) return
    initializeDefinition()
  }, [remoteGatewayTypeSchema.current])

  useEffect(
    function onSwitchRawView() {
      if (isRawDefinitionView) return
      initializeDefinition()
    },
    [isRawDefinitionView]
  )

  useEffect(
    function onRawDefnitionChanged() {
      if (!rawDefinition) return
      onChangeRawDefinition(JSON.stringify(rawDefinition))
    },
    [rawDefinition]
  )

  useEffect(() => {
    const isBothDefinitionsValid =
      generalDefinitionValidationResult.isValid && protocolDefinitionValidationResult.isValid
    onValidationResult({
      isValid: isBothDefinitionsValid,
      errors: '',
    })
  }, [protocolDefinitionValidationResult.isValid, generalDefinitionValidationResult.isValid])

  return (
    <ErrorBoundary FallbackComponent={FallbackErrorRoot}>
      <Layout.Stack>
        <Layout.Group className="mb-4" align="center" space="m">
          <Switch onToggle={() => setRawDefintionView(v => !v)} active={isRawDefinitionView} />
          <Text>Raw visualization</Text>
          {isLoadingGatewayTypeSchema && <LoadingDots />}
        </Layout.Group>

        {isRawDefinitionView && !isLoadingGatewayTypeSchema && (
          <Layout.Stack>
            <FileViewer
              options={{
                heigth: 500,
                theme: FileViewTheme.DEFAULT,
              }}
              content={JSON.stringify(rawDefinition, null, 2)}
              contentType="application/json"
              onChange={(value: any) => {
                const definition = JSON.parse(value)
                setRawDefinition(definition)
                setGatewayTypeDeftinionData(definition[gateway.gateway_type])
              }}
            />
          </Layout.Stack>
        )}

        {!isRawDefinitionView && gateway.gateway_type && (
          <Layout.Stack>
            <ErrorBoundary FallbackComponent={FallbackErrorProcessorForm}>
              <Tabs>
                <Tabs.Items>
                  <Tabs.Item
                    default
                    name="gateway-type-definition"
                    data-testid="gateway-type-definition-tab"
                  >
                    <Layout.Group align="center">
                      <Layout.Group data-testid="gateway-type-definition-tab-name" align="center">
                        {`${gateway.gateway_type.toUpperCase()} Definition`}
                      </Layout.Group>
                    </Layout.Group>
                  </Tabs.Item>
                  <Tabs.Item name="pre-processors" data-testid="pre-processors-tab">
                    <Layout.Group align="center">
                      Pre-Processors
                      <Tag variant="accent">{getPreProcessorsFromRawDefinition().length}</Tag>
                    </Layout.Group>
                  </Tabs.Item>
                  <Tabs.Item name="post-processors" data-testid="post-processors-tab">
                    <Layout.Group align="center">
                      Post-Processors
                      <Tag variant="accent">{getPostProcessorsFromRawDefinition().length}</Tag>
                    </Layout.Group>
                  </Tabs.Item>
                </Tabs.Items>
                <Tabs.Panels>
                  <Tabs.Panel
                    name="gateway-type-definition"
                    data-testid="gateway-type-definition-tab-panel"
                  >
                    <ErrorBoundary FallbackComponent={FallbackErrorGatewayTypeForm}>
                      <Tabs>
                        <Tabs.Items>
                          {hasGeneralGatewayTypeDefinition.current && (
                            <Tabs.Item name="general-definition" default>
                              <Layout.Group align="center">
                                General
                                <Tag className="invisible" />
                                {!generalDefinitionValidationResult?.isValid && (
                                  <Tooltip message={generalDefinitionValidationResult.errors}>
                                    <Tag variant="danger">!!</Tag>
                                  </Tooltip>
                                )}
                              </Layout.Group>
                            </Tabs.Item>
                          )}
                          {hasProtocolDefinition.current && connection && (
                            <Tabs.Item
                              name="protocol-definition"
                              default={!hasGeneralGatewayTypeDefinition.current}
                            >
                              <Layout.Group align="center">
                                Protocol
                                <Tag>{String(connection?.protocol.toUpperCase())}</Tag>
                                {!protocolDefinitionValidationResult?.isValid && (
                                  <Tooltip message={protocolDefinitionValidationResult.errors}>
                                    <Tag variant="danger">!!</Tag>
                                  </Tooltip>
                                )}
                              </Layout.Group>
                            </Tabs.Item>
                          )}
                        </Tabs.Items>
                        <Tabs.Panels>
                          {hasGeneralGatewayTypeDefinition.current && (
                            <Tabs.Panel
                              name="general-definition"
                              data-testid="gateway-type-definition-dynamic-form"
                            >
                              <Layout.Stack>
                                {!generalDefinitionValidationResult.isValid && (
                                  <Banner
                                    scale="default"
                                    title={String(generalDefinitionValidationResult.errors)}
                                    variant="danger"
                                    dismissible={false}
                                  />
                                )}

                                <JsonForm
                                  key="json-form-general-definition"
                                  data-testid="gateway-type-definition-component"
                                  schema={gatewayTypeSchema}
                                  data={gatewayTypeDefinitionData}
                                  uiSchema={gatewayTypeUiSchema}
                                  onChange={value => {
                                    if (!value) return
                                    onChangeGatewayTypeGeneralDefinition(value)
                                  }}
                                />
                              </Layout.Stack>
                            </Tabs.Panel>
                          )}
                          {hasProtocolDefinition.current && (
                            <Tabs.Panel name="protocol-definition">
                              <Layout.Stack>
                                {!protocolDefinitionValidationResult.isValid && (
                                  <Banner
                                    scale="default"
                                    title={String(protocolDefinitionValidationResult.errors)}
                                    variant="danger"
                                    dismissible={false}
                                  />
                                )}

                                <JsonForm
                                  key="json-form-protocol-definition"
                                  data-testid="gateway-type-protocol-definition-component"
                                  schema={protocolSchema}
                                  data={protocolData}
                                  uiSchema={protocolUiSchema}
                                  onChange={value => {
                                    if (!value) return
                                    onChangeProtocolDefinition(value)
                                  }}
                                />
                              </Layout.Stack>
                            </Tabs.Panel>
                          )}
                        </Tabs.Panels>
                      </Tabs>
                    </ErrorBoundary>
                  </Tabs.Panel>
                  <Tabs.Panel name="pre-processors" data-testid="pre-processors-tab-panel">
                    <ProcessorCollection
                      key="pre-processors"
                      data={preProcessors}
                      onChange={value => {
                        updateRawDefinitionByKey(PRE_PROCESSOR_KEY, value)
                        // setPreProcessors(value)
                      }}
                      title="Pre-Processors"
                    />
                  </Tabs.Panel>
                  <Tabs.Panel name="post-processors" data-testid="post-processors-tab-panel">
                    <ProcessorCollection
                      key="post-processors"
                      data={postProcessors}
                      onChange={value => {
                        updateRawDefinitionByKey(POST_PROCESSOR_KEY, value)
                        // setPostProcessors(value)
                      }}
                      title="Post-Processors"
                    />
                  </Tabs.Panel>
                </Tabs.Panels>
              </Tabs>
            </ErrorBoundary>
          </Layout.Stack>
        )}
      </Layout.Stack>
    </ErrorBoundary>
  )
}

export default GatewaySettingsDefitinion
