import { Banner, Layout, Table, Tag } from '@loadsmart/loadsmart-ui'
import { TestExecutionResult } from 'common/types/kraken-core/Common'
import { useCallback } from 'react'

export interface TestExecutionResultProps {
  result: TestExecutionResult
}

function TestExecutionResultComponent({ result }: TestExecutionResultProps) {
  const resultLog = useCallback(() => {
    if (!result.result_log || result.result_log.length === 0) return []

    return result.result_log.map((log, index) => ({
      index: index + 1,
      entry: log,
    }))
  }, [result])

  const ResultBanner = () => {
    const variant: any = result.success ? 'success' : 'danger'
    const successMessage = 'Test was successfully executed'
    const failureMessage = 'Test execution has failed'
    const title = result.success ? successMessage : failureMessage
    return (
      <div>
        <Banner data-testid="test-result-banner" variant={variant} title={title} />
      </div>
    )
  }

  return (
    <Layout.Stack>
      <ResultBanner />
      <Table data-testid="test-result-table-log" scale="small">
        <Table.Head>
          <Table.HeadCell>#</Table.HeadCell>
          <Table.HeadCell>Log</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {resultLog().map(log => (
            <Table.Row key={`log_entry_${log.index}`}>
              <Table.Cell style={{ whiteSpace: 'break-spaces' }}>
                <Tag>{log.index}</Tag>
              </Table.Cell>
              <Table.Cell style={{ whiteSpace: 'break-spaces' }}>{log.entry}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Layout.Stack>
  )
}

export default TestExecutionResultComponent
