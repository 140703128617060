import { PropsWithChildren, ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import FocusTrap from 'focus-trap-react'

import './Modal.css'

export interface ModalProps {
  id: string
  open: boolean
  children?: ReactNode
  className?: string
}

export interface ModalHeaderProps {
  className?: string
}

function ModalHeader({ children, className }: PropsWithChildren<ModalHeaderProps>) {
  return (
    <header
      className={clsx(
        'flex flex-col items-center justify-center rg-modal-header text-neutral-darkest text-2xl font-bold mb-6 text-center',
        className
      )}
    >
      {children}
      <hr className="w-full mt-2" />
    </header>
  )
}

export interface ModalFooterProps {
  className?: string
}

function ModalFooter({ children, className }: PropsWithChildren<ModalFooterProps>) {
  return <footer className={clsx('mt-6', className)}>{children}</footer>
}

export interface ModalBodyProps {
  className?: string
}

function ModalBody({ children, className }: PropsWithChildren<ModalBodyProps>) {
  return (
    <div className={clsx('flex flex-col overflow-auto rg-modal-body ', className)}>{children}</div>
  )
}

const modalRoot = document.getElementById('modal-root')

function Modal({ id, className, children, open = false, ...others }: ModalProps) {
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return function resetBodyOverflow() {
      document.body.classList.remove('overflow-hidden')
    }
  }, [open])

  if (!modalRoot) {
    return null
  }

  return ReactDOM.createPortal(
    <FocusTrap active={open}>
      <div
        {...others}
        className={clsx(
          'z-20 fixed inset-0 p-8 bg-black bg-opacity-50 flex items-center overflow-y-auto',
          {
            hidden: !open,
            'flex-col': open,
          }
        )}
      >
        <section
          id={id}
          className={clsx(
            'rg-modal shadow-md p-6 bg-background border border-background',
            className
          )}
        >
          {children}
        </section>
      </div>
    </FocusTrap>,
    modalRoot
  )
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
