import { SideNavigation } from '@loadsmart/loadsmart-ui'
import Logo from 'atoms/Logo/Logo'
import { authManager } from 'common/authManager'
import Environment from 'common/types/Environment'
import { MouseEventHandler, ReactNode, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'
import getEnv from '../../common/helpers/getEnv'
import styled from 'styled-components'

interface MenuItem {
  path?: string
  label: string
  onClick?: Function
  subItems?: Array<MenuItem>
}

function MenuItemComponent({
  menuItem,
  children,
  onClick,
}: {
  menuItem: MenuItem
  children?: ReactNode
  onClick: MouseEventHandler
}) {
  let isActive = false
  const location = useLocation()
  isActive = location.pathname.startsWith(menuItem.path || 'unknown')
  return (
    <SideNavigation.Menu.Item
      key={`menu_item_${menuItem.path}`}
      label={menuItem.label}
      url={menuItem.path}
      active={isActive}
      onClick={(e: any) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </SideNavigation.Menu.Item>
  )
}

const StyledSidebar = styled(SideNavigation)`
  height: 200vh;
`

export function Sidebar() {
  const history = useHistory()

  const menuItems: Array<MenuItem> = [
    {
      label: 'Messages',
      path: '/messages',
      subItems: [],
    },
    {
      label: 'Expected Messages ',
      path: '/expected-reply-messages',
      subItems: [],
    },
    {
      label: 'Trading Partner',
      path: '/trading-partner',
      subItems: [],
    },
    {
      label: 'Routes',
      path: '/routes',
      subItems: [],
    },
    {
      label: 'Gateways',
      path: '/gateways',
      subItems: [],
    },
    {
      label: 'Connections',
      path: '/connections',
      subItems: [],
    },
    {
      label: 'Translation',
      subItems: [
        {
          label: 'Maps',
          path: '/translation-maps',
          subItems: [],
        },
        {
          label: 'Settings',
          path: '/translation-settings',
          subItems: [],
        },
      ],
    },
    {
      label: 'Onboarding',
      path: '/integration/onboarding',
      subItems: [],
    },
    {
      label: 'Partnership',
      path: '/partnership',
      subItems: [],
    },
    {
      label: 'Conversion Codes',
      path: '/conversion-codes',
      subItems: [],
    },
    {
      label: 'Data Models',
      path: '/data-model/schemas',
      subItems: [],
    },
  ]

  if (getEnv() != Environment.Production) {
    menuItems.push({
      label: 'Integration tests',
      path: '/integration-tests',
      subItems: [],
    })
  }

  const logoutMenu = {
    label: 'Logout',
    onClick: () => {
      authManager.logout()
    },
    subItems: [],
  }

  const openMenuItem = useCallback(
    (path: string | undefined) => {
      if (path) history.push(path || '')
    },
    [menuItems]
  )

  return (
    <StyledSidebar>
      <SideNavigation.Logo url="/" className="cursor-pointer">
        <Logo width={150} />
      </SideNavigation.Logo>
      {menuItems.map(m => {
        const hasSubItems = m.subItems && m.subItems.length > 0
        return (
          <MenuItemComponent
            key={`item_${m.path}`}
            menuItem={m}
            onClick={() => {
              if (m.onClick) m.onClick()
              if (!hasSubItems) openMenuItem(m.path)
            }}
          >
            {m.subItems &&
              m.subItems.length > 0 &&
              m.subItems.map(item => (
                <MenuItemComponent
                  key={`sub_item_${item.path}`}
                  menuItem={item}
                  onClick={() => {
                    if (item.onClick) item.onClick()
                    openMenuItem(item.path)
                  }}
                />
              ))}
          </MenuItemComponent>
        )
      })}

      <SideNavigation.Separator />
      <SideNavigation.Menu>
        <MenuItemComponent
          key="menu_item_logout"
          onClick={logoutMenu.onClick}
          menuItem={logoutMenu}
        />
      </SideNavigation.Menu>
    </StyledSidebar>
  )
}
