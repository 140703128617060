import { Breadcrumbs, Layout } from '@loadsmart/loadsmart-ui'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { useIntegrationTestContext } from 'pages/IntegrationTest/IntegrationTestContext'
import IntegrationTestCaseList from '../components/IntegrationTestCaseList'

export default function IntegrationTestCasePlayground() {
  const context = useIntegrationTestContext()

  useTopNavigationContext({
    children: <Breadcrumbs entries={[{ label: 'Integration Test Case Playground' }]} />,
  })

  return (
    <Layout.Group>
      <IntegrationTestCaseList />
    </Layout.Group>
  )
}
