/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import { MessageRead } from './Message'

export enum ExpectedReplyMessageDirection {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

export enum ExpectedReplyMessageStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  ACCEPTED_WITH_ERRORS = 'accepted-with-errors',
  ACCEPTED_PARTIALLY = 'accepted-partially',
  REJECTED = 'rejected',
}

export enum ExpectedReplyMessageType {
  FACK = 'fack',
}

export interface ExpectedReplyMessage {
  id: string
  reply_message?: MessageRead
  request_message?: MessageRead
  direction: ExpectedReplyMessageDirection
  deleted_at?: string
  due: string
  rejection_reason?: string
  status: ExpectedReplyMessageStatus
  reply_type: ExpectedReplyMessageType
  task_id?: string
  meta?: string
  request?: string
  reply?: string
  created_at: string
  updated_at: string
  replied_at?: string
}
