export const IntegrationTestCaseExpectationCheckerSchema = {
  type: 'array',
  items: {
    $ref: '#/definitions/IntegrationTestCaseExpectationChecker',
  },

  definitions: {
    IntegrationTestCaseExpectationChecker: {
      title: 'CheckerSchema',
      type: 'object',
      properties: {
        description: {
          title: 'Description to identify checker case',
          default: '',
          type: 'string',
        },
        filter_by: {
          title: 'Filter to find message(s) to run the checker',
          default: {
            strategy: 'message:filter:id',
            order_by: 'created_at',
          },
          allOf: [{ $ref: '#/definitions/IntegrationTestMessageFilterByDefinition' }],
        },
        strategy: {
          title: 'Checker strategy to be used',
          enum: [
            'checker:message-count',
            'checker:message-payload',
            'checker:message-headers',
            'checker:lookup-entries',
            'checker:message-receipt',
          ],
          type: 'string',
        },
        strategy_definition: {
          title: 'Definitions for Checker Strategy',
          anyOf: [
            { $ref: '#/definitions/CheckerMessageCountDefinition' },
            { $ref: '#/definitions/CheckerMessageBodyDefinition' },
            { $ref: '#/definitions/CheckerMessageHeaderDefinition' },
            { $ref: '#/definitions/CheckerMessageReceiptDefinition' },
            { $ref: '#/definitions/CheckerMessageLookupEntriesDefinition' },
          ],
        },
      },
      required: ['strategy', 'strategy_definition'],
    },
    IntegrationTestMessageFilterByDefinition: {
      title: 'Filter Message by Definition',
      type: 'object',
      properties: {
        strategy: {
          title: 'Strategy to Filter Messages for Assertion',
          enum: ['message:filter:id', 'message:filter:correlation-code', 'message:filter:headers'],
          type: 'string',
        },
        headers: {
          title: 'Filter by Headers',
          description: 'Always filtered by header Integration-Test-Execution',
          type: 'object',
        },
        message_id: {
          title: 'Message ID to be filtered (expression)',
          description: 'Default is message.id',
          type: 'string',
        },
        correlation_code: {
          title: 'Correlation Code to be filtered (expression)',
          description: 'Default is message.correlation_code',
          type: 'string',
        },
        order_by: {
          title: 'Order message filter by',
          default: 'created_at',
          enum: ['created_at', '-created_at'],
          options: {
            enum_titles: ['Created At - Asc', 'Created At - Desc'],
          },
          type: 'string',
        },
      },
      required: ['strategy'],
    },
    CheckerMessageCountDefinition: {
      title: 'Message Count Checker Definition',
      type: 'object',
      properties: {
        expected_count: {
          title: 'Expected Number of Messages to assert',
          type: 'integer',
        },
      },
      required: ['expected_count'],
    },
    FileFieldSchema: {
      title: 'File Field',
      type: 'object',
      properties: {
        encoded_data_url: {
          title: 'HTTP Encoded Data Url',
          type: 'string',
        },
        content_type: { title: 'Content Type', type: 'string' },
        name: { title: 'Name', type: 'string' },
      },
      required: ['encoded_data_url', 'content_type', 'name'],
    },
    CheckerMessageBodyDefinition: {
      title: 'Message Body Checker Definition',
      type: 'object',
      properties: {
        expected_body: {
          title: 'Expected Message Body to assert',
          allOf: [{ $ref: '#/definitions/FileFieldSchema' }],
        },
      },
      required: ['expected_body'],
    },
    CheckerMessageHeaderDefinition: {
      title: 'Message Headers Checker Definition',
      type: 'object',
      properties: {
        expected_headers: {
          title: 'Expected Headers to assert',
          type: 'object',
        },
      },
      required: ['expected_headers'],
    },
    CheckerMessageReceiptDefinition: {
      title: 'Message Receipt Checker Definition',
      type: 'object',
      properties: {
        status_code: {
          title: 'Status Code to the Receipt',
          type: 'integer',
        },
        receipt_type: { title: 'Type of the Receipt', type: 'string' },
        expected_payload: {
          title: 'Expected Payload of the Receipt',
          type: 'string',
        },
      },
      required: ['status_code', 'receipt_type', 'expected_payload'],
    },
    CheckerMessageLookupEntriesDefinition: {
      title: 'Message Lookup Entries Checker Definition',
      type: 'object',
      properties: {
        lookup_name: {
          title: 'Lookup Table name (expression)',
          type: 'string',
        },
        lookup_key: {
          title: 'Lookup Table Key (expression)',
          type: 'string',
        },
        lookup_content: {
          title: 'Lookup Table Content to assert',
          type: 'object',
        },
      },
      required: ['lookup_name', 'lookup_key'],
    },
  },
}

export const IntegrationTestCaseExpectationCheckerSchemaUiSchema = {
  type: 'Control',
  scope: '#/',
  options: {
    detail: {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/description',
        },
        {
          type: 'Categorization',
          elements: [
            {
              type: 'Category',
              label: 'Query',
              elements: [
                {
                  type: 'Group',
                  elements: [
                    {
                      type: 'HorizontalLayout',
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/filter_by/properties/strategy',
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/filter_by/properties/order_by',
                        },
                      ],
                    },

                    {
                      type: 'Control',
                      scope: '#/properties/filter_by/properties/headers',
                      rule: {
                        effect: 'SHOW',
                        condition: {
                          scope: '#/properties/filter_by/properties/strategy',
                          schema: { enum: ['message:filter:headers'] },
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/filter_by/properties/message_id',
                      rule: {
                        effect: 'SHOW',
                        condition: {
                          scope: '#/properties/filter_by/properties/strategy',
                          schema: { enum: ['message:filter:id'] },
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/filter_by/properties/correlation_code',
                      rule: {
                        effect: 'SHOW',
                        condition: {
                          scope: '#/properties/filter_by/properties/strategy',
                          schema: { enum: ['message:filter:correlation-code'] },
                        },
                      },
                    },
                  ],
                },
              ],
            },
            {
              type: 'Category',
              label: 'Assertion',
              elements: [
                {
                  type: 'Group',

                  elements: [
                    {
                      type: 'Control',
                      label: 'Strategy',
                      scope: '#/properties/strategy',
                    },
                    {
                      type: 'FileField',
                      scope: '#/properties/strategy_definition/properties/expected_body',
                      rule: {
                        effect: 'SHOW',
                        condition: {
                          scope: '#/properties/strategy',
                          schema: { enum: ['checker:message-payload'] },
                        },
                      },
                    },
                    {
                      type: 'FileField',
                      scope: '#/properties/strategy_definition/properties/expected_headers',
                      rule: {
                        effect: 'SHOW',
                        condition: {
                          scope: '#/properties/strategy',
                          schema: { enum: ['checker:message-headers'] },
                        },
                      },
                    },
                    {
                      type: 'Control',
                      scope: '#/properties/strategy_definition/properties/expected_count',
                      rule: {
                        effect: 'SHOW',
                        condition: {
                          scope: '#/properties/strategy',
                          schema: { enum: ['checker:message-count'] },
                        },
                      },
                    },
                    {
                      type: 'Group',
                      rule: {
                        effect: 'SHOW',
                        condition: {
                          scope: '#/properties/strategy',
                          schema: { enum: ['checker:message-receipt'] },
                        },
                      },
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/strategy_definition/properties/status_code',
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/strategy_definition/properties/receipt_type',
                        },
                        {
                          type: 'FileField',
                          scope: '#/properties/strategy_definition/properties/expected_payload',
                        },
                      ],
                    },
                    {
                      type: 'Group',
                      rule: {
                        effect: 'SHOW',
                        condition: {
                          scope: '#/properties/strategy',
                          schema: { enum: ['checker:lookup-entries'] },
                        },
                      },
                      elements: [
                        {
                          type: 'Control',
                          scope: '#/properties/strategy_definition/properties/lookup_name',
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/strategy_definition/properties/lookup_key',
                        },
                        {
                          type: 'Control',
                          scope: '#/properties/strategy_definition/properties/lookup_content',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
}

export const IntegrationTestCaseInputHttpMockSchema = {
  title: 'HTTP Mocks for Integration Tests',
  type: 'array',
  items: { $ref: '#/definitions/HttpMockSchema' },
  definitions: {
    FileFieldSchema: {
      title: 'File Field',
      type: 'object',
      properties: {
        encoded_data_url: {
          title: 'HTTP Encoded Data Url',
          type: 'string',
        },
        content_type: { title: 'Content Type', type: 'string' },
        name: { title: 'Name', type: 'string' },
      },
      required: ['encoded_data_url', 'content_type', 'name'],
    },
    HttpMockSchema: {
      title: 'HTTP Mock',
      type: 'object',
      properties: {
        mock_name: {
          title: 'Mock name (unique)',
          description: 'Should be declared like a function name & Unique!',
          type: 'string',
        },
        path: {
          title: 'Path to match',
          description: 'regex',
          type: 'string',
        },
        method: {
          title: 'Method to match',
          enum: ['GET', 'POST', 'PUT', 'DELETE', 'OPTIONS', 'PATCH'],
          type: 'string',
        },
        status_code: { title: 'Status Code', type: 'integer' },
        headers: { title: 'Headers', default: {}, type: 'object' },
        content: {
          title: 'Content',
          allOf: [{ $ref: '#/definitions/FileFieldSchema' }],
        },
      },
      required: ['mock_name', 'path', 'status_code', 'content'],
    },
  },
}

export const IntegrationTestCaseInputHttpMockUiSchema = {
  type: 'Control',
  scope: '#/',
  options: {
    detail: {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/mock_name',
        },
        {
          type: 'Control',
          scope: '#/properties/path',
        },
        {
          type: 'Control',
          scope: '#/properties/method',
        },

        {
          type: 'Control',
          scope: '#/properties/status_code',
        },
        {
          type: 'FileField',
          scope: '#/properties/content',
        },
        {
          type: 'Control',
          scope: '#/properties/headers',
        },
      ],
    },
  },
}

export const IntegrationTestCaseInputFtpSftpMockUiSchema = {
  type: 'Control',
  scope: '#/',
  options: {
    detail: {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'FileField',
          scope: '#/properties/content',
        },
      ],
    },
  },
}

export const IntegrationTestCaseInputAwsS3MockUiSchema = {
  type: 'Control',
  scope: '#/',
  options: {
    detail: {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/key',
        },
        {
          type: 'FileField',
          scope: '#/properties/content',
        },
      ],
    },
  },
}

export const IntegrationTestCaseInputLookUpTableMockSchema = {
  title: 'Lookup Table Mock',
  type: 'array',
  items: { $ref: '#/definitions/InputDefinitionLookUpTableEntry' },
  definitions: {
    InputDefinitionLookUpTableEntry: {
      title: 'Input LookUp Table Entry',
      type: 'object',
      properties: {
        key: { title: 'Entry key', type: 'string' },
        name: { title: 'Entry name', type: 'string' },
        values: { title: 'Dictionary of values', type: 'object' },
      },
      required: ['key', 'name', 'values'],
    },
  },
}

export const IntegrationTestCaseInputFtpSftpMockSchema = {
  title: 'FTP/sFTP Mock',
  type: 'array',
  items: { $ref: '#/definitions/InputDefinitionFtpSftpEntry' },
  definitions: {
    FileFieldSchema: {
      title: 'File Field',
      type: 'object',
      properties: {
        encoded_data_url: {
          title: 'FTP/sFTP Encoded Data Url',
          type: 'string',
        },
        content_type: { title: 'Content Type', type: 'string' },
        name: { title: 'Name', type: 'string' },
      },
      required: ['encoded_data_url', 'content_type', 'name'],
    },
    InputDefinitionFtpSftpEntry: {
      title: 'FTP/sFTP Mock',
      type: 'object',
      properties: {
        content: {
          title: 'Content',
          allOf: [{ $ref: '#/definitions/FileFieldSchema' }],
        },
      },
      required: ['content'],
    },
  },
}

export const IntegrationTestCaseInputAwsS3MockSchema = {
  title: 'AWS S3 Mock',
  type: 'array',
  items: { $ref: '#/definitions/InputDefinitionAwsS3Entry' },
  definitions: {
    FileFieldSchema: {
      title: 'File Field',
      type: 'object',
      properties: {
        encoded_data_url: {
          title: 'AWS S3 Encoded Data Url',
          type: 'string',
        },
        content_type: { title: 'Content Type', type: 'string' },
        name: { title: 'Name', type: 'string' },
      },
      required: ['encoded_data_url', 'content_type', 'name'],
    },
    InputDefinitionAwsS3Entry: {
      title: 'AWS S3 Mock',
      type: 'object',
      properties: {
        key: {
          title: 'File Key',
          description: 'The file key.',
          type: 'string',
        },
        content: {
          title: 'Content',
          allOf: [{ $ref: '#/definitions/FileFieldSchema' }],
        },
      },
      required: ['content', 'key'],
    },
  },
}
