const webhookSchema = {
  title: 'Webhook Settings',
  type: 'object',
  properties: {
    'HTTP-Authentication': {
      title: 'Authentication',
      allOf: [
        {
          $ref: '#/definitions/HttpAuthenticationSchema',
        },
      ],
    },
    'HTTP-Permission': {
      title: 'Permissions',
      allOf: [
        {
          $ref: '#/definitions/HttpPermissionSchema',
        },
      ],
    },
  },
  definitions: {
    CredentialStorage: {
      title: 'CredentialStorage',
      description: 'An enumeration.',
      enum: ['local', 'vault'],
      type: 'string',
    },
    HTTPBasicSchema: {
      title: 'HTTP Basic Auth',
      type: 'object',
      properties: {
        username: {
          title: 'Username',
          type: 'string',
        },
        password: {
          title: 'Password',
          type: 'string',
        },
        storage: {
          title: 'Storage Type',
          default: 'local',
          allOf: [
            {
              $ref: '#/definitions/CredentialStorage',
            },
          ],
        },
      },
    },
    HTTPAuthenticationBasicSchema: {
      title: 'Basic',
      type: 'object',
      properties: {
        repository: {
          title: 'Repository',
          enum: ['inline', 'vault', 'per-partner'],
          type: 'string',
        },
        inline: {
          title: 'Inline',
          tite: 'HTTP Basic Auth',
          allOf: [
            {
              $ref: '#/definitions/HTTPBasicSchema',
            },
          ],
        },
        vault: {
          title: 'Vault',
          tite: 'HTTP Basic Auth',
          allOf: [
            {
              $ref: '#/definitions/HTTPBasicSchema',
            },
          ],
        },
      },
    },
    HTTPExtraSchema: {
      title: 'Extra',
      type: 'object',
      properties: {},
    },
    HTTPClaimsSchema: {
      title: 'Claims',
      type: 'object',
      properties: {
        iss: {
          title: 'ISS',
          type: 'string',
        },
        extra: {
          title: 'Extra',
          allOf: [
            {
              $ref: '#/definitions/HTTPExtraSchema',
            },
          ],
        },
      },
    },
    HTTPAuthroizationHeaderSchema: {
      title: 'Authorization Header',
      type: 'object',
      properties: {
        name: {
          title: 'Name',
          default: 'Authorization',
          type: 'string',
        },
        value: {
          title: 'Value',
          type: 'string',
        },
        prefix: {
          title: 'Prefix',
          type: 'string',
        },
      },
    },
    HTTPJWTSchema: {
      title: 'HTTPJWTSchema',
      type: 'object',
      properties: {
        algorithm: {
          title: 'Algorithm',
          enum: ['RS256'],
          type: 'string',
        },
        value: {
          title: 'Value',
          options: {
            inputAttributes: {
              input_height: '200px',
            },
          },
          type: 'string',
        },
      },
    },
    HTTPAuthenticationJWTSchema: {
      title: 'JWT',
      type: 'object',
      properties: {
        claims: {
          title: 'Claims',
          allOf: [
            {
              $ref: '#/definitions/HTTPClaimsSchema',
            },
          ],
        },
        repository: {
          title: 'Repository',
          enum: ['settings', 'inline', 'partner:jwt:public-key', 'vault'],
          type: 'string',
        },
        headers: {
          title: 'Authorization Header',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthroizationHeaderSchema',
            },
          ],
        },
        inline: {
          title: 'Inline',
          allOf: [
            {
              $ref: '#/definitions/HTTPJWTSchema',
            },
          ],
        },
        vault: {
          title: 'vault',
          allOf: [
            {
              $ref: '#/definitions/HTTPJWTSchema',
            },
          ],
        },
      },
      required: ['claims'],
    },
    HTTPAuthenticationTokenSchema: {
      title: 'Token',
      type: 'object',
      properties: {
        repository: {
          title: 'Repository',
          default: 'bear-token-per-partner',
          type: 'string',
        },
        keyword: {
          title: 'Keyword',
          default: 'token',
          type: 'string',
        },
      },
    },
    TokenFromEndpointAuthenticationCredentialsSchema: {
      title: 'Credentials',
      type: 'object',
      properties: {
        'http:auth:basic': {
          title: 'Basic',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthenticationBasicSchema',
            },
          ],
        },
      },
    },
    HTTPHeadersSchema: {
      title: 'Header',
      type: 'object',
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        value: {
          title: 'Value',
          type: 'string',
        },
      },
    },
    TokenFromEndpointAuthenticationRequestSchema: {
      title: 'Request',
      type: 'object',
      properties: {
        'HTTP-Verb': {
          title: 'Verb',
          default: 'http:post',
          enum: ['http:delete', 'http:get', 'http:options', 'http:post', 'http:put', 'http:patch'],
          options: {
            enum_titles: ['DELETE', 'GET', 'OPTIONS', 'POST', 'PUT', 'PATCH'],
          },
          type: 'string',
        },
        endpoint: {
          title: 'Endpoint',
          type: 'string',
        },
        headers: {
          title: 'Headers',
          description: 'HTTP headers to be used on the authentication request',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPHeadersSchema',
          },
        },
        data: {
          title: 'Data (Expression)',
          description: "The body used on the authentication request. Use '{}' when empty body",
          default: '',
          options: {
            inputAttributes: {
              input_height: '200px',
            },
          },
          type: 'string',
        },
        use_authorization_header_with_b64_authentication: {
          title: 'Add Basic Authorization Header',
          description: 'It will add the header with Authorization: Basic <encoded b64>',
          type: 'boolean',
        },
      },
      required: ['endpoint'],
    },
    TokenFromEndpointAuthenticationResponseSchema: {
      title: 'Response',
      type: 'object',
      properties: {
        data: {
          title: 'Data (Expression)',
          default: '{{ response.json().access_token }}',
          options: {
            inputAttributes: {
              input_height: '200px',
            },
          },
          type: 'string',
        },
      },
    },
    TokenFromEndpointAuthenticationSessionCacheSchema: {
      title: 'Session Cache Settings',
      type: 'object',
      properties: {
        token_expiration: {
          title: 'Token Expiration',
          description:
            'Total amount of time in seconds the token will be valid to be used before expire',
          type: 'integer',
        },
        token_expires_at: {
          title: 'Token Expires At',
          description:
            'An expression that will be dynamically evaluated when checking if token still valid',
          type: 'string',
        },
      },
    },
    TokenFromEndpointAuthenticationSessionSchema: {
      title: 'Session Configuration',
      type: 'object',
      properties: {
        header: {
          title: 'Authorization Header',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthroizationHeaderSchema',
            },
          ],
        },
        cache: {
          title: 'Cache Settings',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationSessionCacheSchema',
            },
          ],
        },
        must_inherit_request_headers: {
          title: 'Must Inherit Request Headers',
          description: 'Will inherit the request headers used in the authentication request',
          type: 'boolean',
        },
        inherit_headers_from_token_endpoint_request: {
          title: 'Inherit Headers from token endpoint request',
          description:
            'Define which headers (using the header name) will be inherited from the previous request made to the token endpoint',
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
    TokenFromEndpointAuthenticationSchema: {
      title: 'Token From Endpoint',
      type: 'object',
      properties: {
        credentials: {
          title: 'Credentials',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationCredentialsSchema',
            },
          ],
        },
        request: {
          title: 'Request',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationRequestSchema',
            },
          ],
        },
        response: {
          title: 'Response',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationResponseSchema',
            },
          ],
        },
        session: {
          title: 'Session Configuration',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationSessionSchema',
            },
          ],
        },
      },
      required: ['credentials', 'request', 'response', 'session'],
    },
    HTTPCustomHeadersSchema: {
      title: 'Header',
      type: 'object',
      properties: {
        key: {
          title: 'Key',
          type: 'string',
        },
        value: {
          title: 'Value',
          type: 'string',
        },
      },
    },
    HTTPCustomSchema: {
      title: 'HTTP Custom Auth',
      type: 'object',
      properties: {
        headers: {
          title: 'Headers',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPCustomHeadersSchema',
          },
        },
      },
    },
    CustomAuthenticationSchema: {
      title: 'Custom',
      type: 'object',
      properties: {
        repository: {
          title: 'Repository',
          enum: ['inline', 'vault'],
          type: 'string',
        },
        inline: {
          title: 'Inline',
          tite: 'HTTP Custom Auth',
          allOf: [
            {
              $ref: '#/definitions/HTTPCustomSchema',
            },
          ],
        },
        vault: {
          title: 'Vault',
          tite: 'HTTP Custom Auth',
          allOf: [
            {
              $ref: '#/definitions/HTTPCustomSchema',
            },
          ],
        },
      },
    },
    HttpAuthenticationSchema: {
      title: 'Authentication',
      type: 'object',
      properties: {
        method: {
          title: 'Method',
          enum: [
            'http:auth:basic',
            'http:auth:custom',
            'http:auth:jwt',
            'http:auth:none',
            'http:otm:auth:custom',
            'http:auth:token',
            'http:auth:token-from-url',
          ],
          options: {
            enum_titles: [
              'BASIC',
              'CUSTOM',
              'JWT',
              'NONE',
              'OTM_AUTH',
              'TOKEN',
              'TOKEN_FROM_ENDPOINT',
            ],
          },
          type: 'string',
        },
        'http:auth:basic': {
          title: 'Basic',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthenticationBasicSchema',
            },
          ],
        },
        'http:auth:jwt': {
          title: 'JWT',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthenticationJWTSchema',
            },
          ],
        },
        'http:auth:token': {
          title: 'Token',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthenticationTokenSchema',
            },
          ],
        },
        'http:auth:token-from-url': {
          title: 'Token From Endpoint',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationSchema',
            },
          ],
        },
        'http:auth:custom': {
          title: 'Custom',
          allOf: [
            {
              $ref: '#/definitions/CustomAuthenticationSchema',
            },
          ],
        },
      },
    },
    AliceAfterPublicApiAuthorizerSchema: {
      title: 'Alice After Public Api Authorizer',
      type: 'object',
      properties: {
        client_id: {
          title: 'Client ID from Intermediary',
          type: 'string',
        },
        client_secret: {
          title: 'Client Secret from Intermediary',
          type: 'string',
        },
        cache_timeout: {
          title: 'Scopes Cache Timeout In Seconds',
          type: 'integer',
        },
        url: {
          title: 'Validation URL',
          minLength: 1,
          maxLength: 2083,
          format: 'uri',
          type: 'string',
        },
      },
      required: ['client_id', 'client_secret'],
    },
    HttpPermissionSchema: {
      title: 'Permissions',
      type: 'object',
      properties: {
        action: {
          title: 'Action',
          type: 'string',
        },
        scopes: {
          title: 'Scopes',
          type: 'object',
          additionalProperties: {
            type: 'string',
          },
        },
        alice_oauth_after_paa: {
          title: 'Alice After Public Api Authorizer',
          allOf: [
            {
              $ref: '#/definitions/AliceAfterPublicApiAuthorizerSchema',
            },
          ],
        },
      },
    },
  },
}
export default webhookSchema
