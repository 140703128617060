import { Card } from '@loadsmart/loadsmart-ui'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { useEffect, useState } from 'react'

export interface DataModelSchemaStructureProps {
  contentType: string
  value?: string
  onChange: (changed: string) => void
}

export default function DataModelSchemaStructure(props: DataModelSchemaStructureProps) {
  const [decodedContent, setDecodedContent] = useState('')

  const handleOnChange = (changed: string) => {
    props.onChange(btoa(changed))
  }

  useEffect(() => {
    if (props.value) setDecodedContent(atob(props.value))
  }, [props.value])

  return (
    <Card>
      <Card.Title>Provide the data schema structure</Card.Title>
      <Card.Subtitle>A schema structure defines a data model, e.i: XSD, JSON</Card.Subtitle>
      <Card.Separator />
      <Card.Body>
        <FileViewer
          contentType={props.contentType}
          content={decodedContent}
          options={{}}
          onChange={handleOnChange}
        />
      </Card.Body>
    </Card>
  )
}
