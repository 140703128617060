import { Tag } from '@loadsmart/loadsmart-ui'

export interface MessageDeliveredStatusCompoonentProps {
  delivered: boolean
}

export default function MessageDeliveredStatusCompoonent(
  props: MessageDeliveredStatusCompoonentProps
) {
  if (props.delivered) {
    return (
      <Tag size="default" variant="accent">
        Delivered
      </Tag>
    )
  }

  return (
    <Tag size="default" variant="danger">
      <span className="text-nowrap">Not Delivered</span>
    </Tag>
  )
}
