import { ProcessorPlugin, ProcessorPluginList } from 'common/types/kraken-core/Processors'
import { useQuery, UseQueryResult } from 'react-query'
import { getProcessor, listProcessors } from 'services/kraken-core/processors/processors.service'

export interface useGetProcessorParams {
  name: string
}

export const useListProcessor = (options: any): UseQueryResult<Array<ProcessorPluginList>> => {
  const queryFn = async () => {
    const { response } = await listProcessors()
    return response?.data
  }
  const query = useQuery(['processors/get/list'], () => queryFn(), { ...options })
  return query
}

export const useGetProcessor = (params: useGetProcessorParams): UseQueryResult<ProcessorPlugin> => {
  const queryFn = async ({ name }: useGetProcessorParams) => {
    const { response } = await getProcessor(name)
    return response?.data
  }
  const query = useQuery(['processors/get/detail'], () => queryFn(params))
  return query
}
