import { Accordion, Banner, Layout, Tabs, Text } from '@loadsmart/loadsmart-ui'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import isJson from 'common/helpers/isJSON'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { useEffect, useMemo, useRef, useState } from 'react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { toast } from 'react-toastify'
import { SearchTradingPartnerHistoryPayload } from 'services/kraken-core/trading_partner/tradingPartner.service'
import { useUpdateTranslationSetting } from '../api'

export interface TranslationSettingsDeprecatedProps {
  tradingPartner: TradingPartner
  tradingPartnerHistory?: SearchTradingPartnerHistoryPayload[]
}

export default function TranslationSettingsDeprecated({
  tradingPartner,
  tradingPartnerHistory,
}: TranslationSettingsDeprecatedProps) {
  const [translationSettings, setTranslationSettings] = useState<any>(
    tradingPartner.translation_settings
  )

  const rawTranslationSettings = useRef<any>()

  const {
    mutateAsync: updateTranslationSettingsMutation,
    error: errorOnUpdateTranslationSettings,
  } = useUpdateTranslationSetting()

  const memoizedInboundHistory = useMemo(
    () =>
      tradingPartnerHistory
        ?.map(history => ({
          inbound_translation_settings: history.translation_settings?.inbound || {},
          history_id: history.history_id,
          history_user: history.history_user,
          history_date: history.history_date,
          history_change_reason: history.history_change_reason,
        }))
        ?.filter(
          (history, index, array) =>
            index === 0 ||
            // eslint-disable-next-line max-len
            JSON.stringify(history.inbound_translation_settings) !==
              JSON.stringify(array[index - 1]?.inbound_translation_settings)
        ),
    [tradingPartnerHistory]
  )

  const memoizedOutboundHistory = useMemo(
    () =>
      tradingPartnerHistory
        ?.map(history => ({
          outbound_translation_settings: history.translation_settings?.outbound || {},
          history_id: history.history_id,
          history_user: history.history_user,
          history_date: history.history_date,
          history_change_reason: history.history_change_reason,
        }))
        ?.filter(
          (history, index, array) =>
            index === 0 ||
            // eslint-disable-next-line max-len
            JSON.stringify(history.outbound_translation_settings) !==
              JSON.stringify(array[index - 1]?.outbound_translation_settings)
        ),
    [tradingPartnerHistory]
  )

  const parseHistory = (history?: any): string => {
    if (!history) return ''

    return JSON.stringify(history || {}, null, 2)
  }

  const handleSave = async () => {
    if (!tradingPartner.id || !rawTranslationSettings.current) return
    await updateTranslationSettingsMutation({
      tradingPartnerId: tradingPartner.id,
      translationSettings: rawTranslationSettings.current,
    })

    if (errorOnUpdateTranslationSettings) {
      toast.error(`Failed to update translation settings: ${errorOnUpdateTranslationSettings}`)
      return
    }
    toast.success(`Successfully updated`)
    setTranslationSettings(rawTranslationSettings.current)
  }

  useEffect(() => {
    setTranslationSettings(tradingPartner.translation_settings)
    rawTranslationSettings.current = tradingPartner.translation_settings
  }, [tradingPartner])

  return (
    <Tabs className="flex flex-col mt-4" direction="horizontal">
      <Tabs.Items>
        <Tabs.Item default name="inbound">
          Inbound
        </Tabs.Item>

        <Tabs.Item name="outbound">Outbound</Tabs.Item>
      </Tabs.Items>
      <Tabs.Panels className="trading-partner-tabs">
        <Tabs.Panel name="inbound">
          <FileViewer
            options={{
              heigth: 500,
            }}
            content={JSON.stringify(translationSettings?.inbound, null, 2)}
            contentType="application/json"
            onChange={(value: any) => {
              if (!value || !isJson(value)) return
              rawTranslationSettings.current = {
                ...rawTranslationSettings.current,
                inbound: JSON.parse(value),
              }
            }}
            onSave={() => {
              handleSave()
            }}
          />

          <Layout.Box padding="none" className="mt-4 mb-8">
            <Text variant="heading-sm" className="ph-4">
              History
            </Text>

            {memoizedInboundHistory &&
              memoizedInboundHistory.map((history, index) => (
                <Accordion key={history.history_id}>
                  <Accordion.Toggle>
                    <Layout.Group>
                      {history.history_id}
                      {' - '}
                      {LSDate(history.history_date)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                      {history.history_user && ` by ${history.history_user}`}
                    </Layout.Group>
                  </Accordion.Toggle>

                  <Accordion.Body>
                    {history.history_change_reason && (
                      <Banner
                        title={history.history_change_reason}
                        variant="warning"
                        dismissible={false}
                        className="mt-4 mb-4"
                      />
                    )}

                    <ReactDiffViewer
                      splitView
                      hideLineNumbers
                      oldValue={parseHistory(
                        memoizedInboundHistory?.[index + 1]?.inbound_translation_settings
                      )}
                      newValue={parseHistory(history?.inbound_translation_settings)}
                      compareMethod={DiffMethod.TRIMMED_LINES}
                      styles={{
                        line: {
                          fontSize: 12,
                        },
                      }}
                    />
                  </Accordion.Body>
                </Accordion>
              ))}
          </Layout.Box>
        </Tabs.Panel>

        <Tabs.Panel name="outbound">
          <FileViewer
            options={{
              heigth: 500,
            }}
            content={JSON.stringify(translationSettings?.outbound, null, 2)}
            contentType="application/json"
            onChange={(value: any) => {
              if (!value || !isJson(value)) return
              rawTranslationSettings.current = {
                ...rawTranslationSettings.current,
                outbound: JSON.parse(value),
              }
            }}
            onSave={() => {
              handleSave()
            }}
          />

          <Layout.Box padding="none" className="mt-4 mb-8">
            <Text variant="heading-sm" className="ph-4">
              History
            </Text>

            {memoizedOutboundHistory &&
              memoizedOutboundHistory.map((history, index) => (
                <Accordion key={history.history_id}>
                  <Accordion.Toggle>
                    <Layout.Group>
                      {history.history_id}
                      {' - '}
                      {LSDate(history.history_date)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                      {history.history_user && ` by ${history.history_user}`}
                    </Layout.Group>
                  </Accordion.Toggle>

                  <Accordion.Body>
                    {history.history_change_reason && (
                      <Banner
                        title={history.history_change_reason}
                        variant="warning"
                        dismissible={false}
                        className="mt-4 mb-4"
                      />
                    )}

                    <ReactDiffViewer
                      splitView
                      hideLineNumbers
                      oldValue={parseHistory(
                        memoizedOutboundHistory?.[index + 1]?.outbound_translation_settings
                      )}
                      newValue={parseHistory(history?.outbound_translation_settings)}
                      compareMethod={DiffMethod.TRIMMED_LINES}
                      styles={{
                        line: {
                          fontSize: 12,
                        },
                      }}
                    />
                  </Accordion.Body>
                </Accordion>
              ))}
          </Layout.Box>
        </Tabs.Panel>
      </Tabs.Panels>
    </Tabs>
  )
}
