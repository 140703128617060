function toArray<T>(value?: T | T[] | null): T[] {
  if (value == null) {
    return []
  }

  if (Array.isArray(value)) {
    return value
  }

  return [value]
}

export default toArray
