import { useTheme, Paper } from '@mui/material'
import { useEffect, useRef } from 'react'
import Icon from 'atoms/Icon'

export interface AdviceProps {
  type: 'alert' | 'warning' | 'info'
  value: string
}

function Advice({ type, value }: AdviceProps) {
  const theme = useTheme()

  const mappingColor: any = {
    alert: {
      background: theme.palette.error.light,
      text: theme.palette.error.contrastText,
      icon: 'warning',
    },
    warning: {
      background: theme.palette.warning.light,
      text: theme.palette.warning.contrastText,
      icon: 'warning',
    },
    info: {
      background: theme.palette.secondary.light,
      text: theme.palette.secondary.contrastText,
      icon: 'info',
    },
  }

  const adviceValue = useRef<HTMLSpanElement>(null)
  const adviceType: any = type ?? ''
  const mapping = mappingColor[adviceType] ?? mappingColor.info
  const backgroundColor = mapping.background
  const textColor = mapping.text
  const iconName = mapping.icon

  useEffect(() => {
    if (adviceValue.current) {
      adviceValue.current.innerHTML = value
    }
  }, [value])

  return (
    <Paper
      className="w-full"
      style={{
        padding: '10px',
        marginBottom: '10px',
        borderRadius: 10,
        background: backgroundColor,
        color: textColor,
      }}
    >
      <div className="flex flex-row self-start">
        <div className="flex self-start">
          <Icon name={iconName} size={30} />
        </div>
        <div className="pt-2 pl-2">
          <span ref={adviceValue} />
        </div>
      </div>
    </Paper>
  )
}

export default Advice
