import { adminBaseURL } from 'common/HTTPClient/HTTPClient'

export interface HTTPCommonSchema {
  authBasicSchema: any
  remoteMethodDetail: any
}

const commonSchema: HTTPCommonSchema = {
  authBasicSchema: {
    type: 'Group',
    label: 'Basic',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/repository',
      },
      {
        type: 'Group',
        rule: {
          effect: 'SHOW',
          condition: {
            scope: '#/properties/repository',
            schema: {
              const: 'inline',
            },
          },
        },
        elements: [
          {
            type: 'Control',
            scope: '#/properties/inline',
          },
        ],
      },
      {
        type: 'Group',
        rule: {
          effect: 'SHOW',
          condition: {
            scope: '#/properties/repository',
            schema: {
              const: 'vault',
            },
          },
        },
        elements: [
          {
            type: 'Control',
            scope: '#/properties/vault',
          },
        ],
      },
    ],
  },
  remoteMethodDetail: {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/key',
      },
      {
        type: 'Control',
        scope: '#/properties/description',
      },
      {
        type: 'Categorization',
        elements: [
          {
            type: 'Category',
            label: 'Request',
            elements: [
              {
                type: 'Group',
                elements: [
                  {
                    type: 'VerticalLayout',
                    elements: [
                      {
                        type: 'Control',
                        scope: '#/properties/request/properties/HTTP-Verb',
                      },
                      {
                        type: 'Control',
                        scope: '#/properties/request/properties/path',
                      },
                    ],
                  },
                  {
                    type: 'Categorization',
                    elements: [
                      {
                        type: 'Category',
                        label: 'Params',
                        elements: [
                          {
                            type: 'HorizontalLayout',
                            elements: [
                              {
                                type: 'Control',
                                scope: '#/properties/request/properties/header_params',
                              },
                              {
                                type: 'Control',
                                scope: '#/properties/request/properties/query_params',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'Category',
                        label: 'Retry Strategy',
                        elements: [
                          {
                            type: 'Group',
                            label: 'Retry Strategy',
                            elements: [
                              {
                                type: 'Control',
                                scope: '#/properties/request/properties/request_retry',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        type: 'Category',
                        label: 'Payload',
                        elements: [
                          {
                            type: 'Control',
                            scope: '#/properties/request/properties/payload/properties/body',
                            options: {
                              detail: {
                                type: 'Group',
                                elements: [
                                  {
                                    type: 'Advice',
                                    options: {
                                      adviceType: 'info',
                                      adviceValue:
                                        'To define how your request body will look like.<br/>' +
                                        'You can set an expression (static) or point to an existing ' +
                                        `static file by code or ID ' + 
                                    '(see <b><a href='${adminBaseURL}/core/staticfile'>` +
                                        'Static Files</a></b>)',
                                    },
                                  },
                                  {
                                    type: 'Control',
                                    scope: '#/properties/static_expression',
                                  },

                                  {
                                    type: 'Control',
                                    scope: '#/properties/expression_from',
                                  },
                                ],
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'Category',
            label: 'Response',
            elements: [
              {
                type: 'Group',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/response/properties/receipt',
                  },
                  {
                    type: 'Group',
                    label: 'Replacements',
                    elements: [
                      {
                        type: 'ListWithDetail',
                        scope: '#/properties/response/properties/replacement/properties/rules',
                        options: {
                          detail: {
                            type: 'HorizontalLayout',
                            label: 'When',
                            elements: [
                              {
                                type: 'VerticalLayout',
                                elements: [
                                  {
                                    type: 'Group',
                                    label: 'When',
                                    elements: [
                                      {
                                        type: 'Control',
                                        scope: '#/properties/when/properties/status_code',
                                      },
                                      {
                                        type: 'Control',
                                        scope: '#/properties/when/properties/text',
                                      },
                                      {
                                        type: 'Control',
                                        scope: '#/properties/when/properties/expression',
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                type: 'Group',
                                label: 'Then',
                                elements: [
                                  {
                                    type: 'Control',
                                    scope: '#/properties/then',
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}

export default commonSchema
