import { Layout, Text } from '@loadsmart/loadsmart-ui'
import { ErrorPanelProps } from './types'

const ErrorPanel = ({ title, error }: ErrorPanelProps) => (
  <Layout.Grid className="w-full bg-danger-light m-2 p-4 rounded" data-testid="error-panel">
    <Text color="banner-title-color-danger" variant="heading-sm-bold">
      {title || 'Some error occur in your request'}
    </Text>
    <Text color="color-danger-dark" variant="body">
      <pre style={{ whiteSpace: 'break-spaces' }}>{error}</pre>
    </Text>
  </Layout.Grid>
)

export default ErrorPanel
