// General imports, like internal and external libraries
import Loading from 'atoms/Loading'
import { Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'

import './index.css'

// Containers (Every page of our module)
import ConnectionSettingsList from './containers/ConnectionSettingsList'
import ConnectionSettingsDetails from './containers/ConnectionSettingsDetails'

const TradingPartnerIndex = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={path}>
          <ConnectionSettingsList />
        </Route>
        <Route path={`${path}/:id`} exact>
          <ConnectionSettingsDetails />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default TradingPartnerIndex
