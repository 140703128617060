import { useEffect, useState } from 'react'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import {
  MessageFailureRead,
  MessageHeaders,
  MessageHistoryRead,
  MessageRead,
} from 'common/types/kraken-core/Message'
import MessageRouteFlowDraw from 'molecules/MessageRouteFlowDraw/MessageRouteFlowDraw'
import {
  readMessageFailures,
  readMessageHistory,
} from 'services/kraken-core/messages/messages.service'
import { readRouteDetails } from 'services/kraken-core/routes/routes.service'
import useCancelToken from 'hooks/useCancelToken'
import { RouteRead } from 'common/types/kraken-core/Routes'

export interface MessageRouteFlowProps {
  message: MessageRead
}

const MessageRouteFlow = ({ message }: MessageRouteFlowProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [history, setHistory] = useState<MessageHistoryRead[]>([])
  const [failures, setFailures] = useState<MessageFailureRead[]>([])
  const [route, setRoute] = useState<RouteRead | undefined>()

  const { getSource, clearSource, cancelPending, isCancel } = useCancelToken()

  useEffect(() => {
    const source = getSource()
    const routeCode = message.headers[MessageHeaders.ROUTE_CODE]

    ;(async () => {
      const [historyError, historyResponse] = await readMessageHistory(message.id, {
        cancelToken: source.token,
      })

      const [routeError, routeResponse] = await readRouteDetails(routeCode, {
        cancelToken: source.token,
      })

      const [failuresError, failuresResponse] = await readMessageFailures(message.id, {
        cancelToken: source.token,
      })

      clearSource()
      setIsLoading(false)

      const error = historyError ?? routeError ?? failuresError ?? null

      if (error && !isCancel) return toast.error(`Something went wrong: ${error}`)

      setHistory(historyResponse?.data || [])
      setFailures(failuresResponse?.data || [])
      setRoute(routeResponse?.data)
    })()

    return () => cancelPending()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <Loading className="justify-center mt-8" />
  }

  return <MessageRouteFlowDraw history={history} route={route} failures={failures} />
}

export default MessageRouteFlow
