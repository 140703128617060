import { Card, Layout, Link, Tag, Text } from '@loadsmart/loadsmart-ui'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import DirectionTag from 'atoms/DirectionTag/DirectionTag'
import Icon from 'atoms/Icon'
import MessageDeliveredStatusCompoonent from 'common/components/message/MessageDeliveredStatusComponent'
import { IntegrationTestCaseExecutionResultGeneratedMessage } from 'common/types/kraken-core/IntegrationTest'
import isEmpty from 'lodash.isempty'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { useIntegrationTestContext } from 'pages/IntegrationTest/IntegrationTestContext'
import { useState } from 'react'

export interface IntegrationTestCaseExecutionResultDetailsProps {}

export default function IntegrationTestCaseExecutionResultDetails(
  props: IntegrationTestCaseExecutionResultDetailsProps
) {
  const context = useIntegrationTestContext()
  const [messageDetails, setMessageDetails] = useState<
    IntegrationTestCaseExecutionResultGeneratedMessage
  >()

  const result = context.localTestCase?.execution.result

  return (
    <>
      {context.localTestCase ? (
        <Layout.Stack space="m" className="w-full">
          {!context.localTestCase.execution.isRunning && result ? (
            <>
              <Layout.Box className="p-2">
                <Layout.Stack space="s">
                  <Text variant="body-bold">Details</Text>
                  <Layout.Grid className="w-full p-4 rounded bg-neutral-lighter">
                    <pre style={{ whiteSpace: 'break-spaces' }}>{result.details}</pre>
                  </Layout.Grid>
                </Layout.Stack>
              </Layout.Box>

              <Layout.Box className="p-2">
                <Card>
                  <Card.Title>
                    <Layout.Stack space="s">
                      <Text variant="body-bold">Output</Text>
                      <Text>All outputs generated during the test execution</Text>
                    </Layout.Stack>
                  </Card.Title>
                  <Card.Separator />
                  <Card.Body>
                    <div>
                      <Accordion>
                        <AccordionSummary>
                          <Layout.Group align="center">
                            <Text variant="body-bold">Messages</Text>
                            <Tag variant="accent">{result.generated?.messages.length || 0}</Tag>
                          </Layout.Group>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table size="small" padding="normal">
                            <TableHead key="head">
                              <TableRow>
                                <TableCell width="10%">#</TableCell>
                                <TableCell width="30%">Transaction-Type</TableCell>
                                <TableCell width="15%">Party</TableCell>
                                <TableCell width="15%">Counterparty</TableCell>
                                <TableCell width="15%">Direction</TableCell>
                                <TableCell key="delivered" width="15%">
                                  Delivered?
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {result.generated?.messages.map(message => {
                                const headers = message.headers
                                const isDelivered = Boolean(headers['Delivered'])
                                const transactionType = String(headers['Transaction-Type'])
                                const direction: any = String(headers['Direction'])
                                const id = message.id?.slice(0, 8) || '-'
                                const party = message.party
                                const counterparty = message.counterparty
                                return (
                                  <>
                                    <TableRow
                                      key={`message_summary_${message.id}`}
                                      hover
                                      onClick={e => {
                                        setMessageDetails(c =>
                                          c && c.id === message.id ? undefined : message
                                        )
                                      }}
                                      className="cursor-pointer"
                                    >
                                      <TableCell>
                                        <a
                                          target="_blank"
                                          href={`/messages/${message.id}`}
                                          className="cursor-pointer"
                                          rel="noreferrer"
                                        >
                                          <Tag>{id}</Tag>
                                        </a>
                                      </TableCell>
                                      <TableCell>{transactionType}</TableCell>
                                      <TableCell>
                                        <Link target="_blank" href={`trading_partner/${party.id}`}>
                                          <Text variant="link">
                                            {party.name.slice(0, 20).concat('...')}
                                          </Text>
                                        </Link>
                                      </TableCell>
                                      <TableCell>
                                        <Link
                                          target="_blank"
                                          href={`trading_partner/${counterparty.id}`}
                                        >
                                          <Text variant="link">
                                            {counterparty.name.slice(0, 20).concat('...')}
                                          </Text>
                                        </Link>
                                      </TableCell>
                                      <TableCell>
                                        <DirectionTag direction={direction} />
                                      </TableCell>
                                      <TableCell key="delivered" width={100}>
                                        <MessageDeliveredStatusCompoonent delivered={isDelivered} />
                                      </TableCell>
                                    </TableRow>

                                    <TableRow key={`message_details_${message.id}`}>
                                      <TableCell
                                        style={{ paddingBottom: 0, paddingTop: 0 }}
                                        colSpan={6}
                                      >
                                        <Collapse
                                          in={
                                            Boolean(messageDetails) &&
                                            messageDetails?.id === message.id
                                          }
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Layout.Stack className="pt-4" space="none">
                                            <Text variant="caption-bold">Headers</Text>
                                            <FileViewer
                                              contentType="application/json"
                                              content={JSON.stringify(headers, null, 2)}
                                              options={{}}
                                              showOptions={false}
                                            />
                                          </Layout.Stack>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary>
                          <Layout.Group align="center">
                            <Text variant="body-bold">Checkers</Text>
                            <Tag variant={result.success ? 'accent' : 'danger'}>
                              {result.checkers_outcomes?.length || 0}
                            </Tag>
                          </Layout.Group>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {result.checkers_outcomes?.map((checkerOutome, index) => {
                              return (
                                <Accordion key={checkerOutome.description}>
                                  <AccordionSummary>
                                    <Layout.Group align="center">
                                      {checkerOutome.success ? (
                                        <Tag variant="accent">
                                          <Icon name="check" />
                                        </Tag>
                                      ) : (
                                        <Tag variant="danger">
                                          <Icon name="close" />
                                        </Tag>
                                      )}
                                      <Text>{checkerOutome.description}</Text>
                                      {!isEmpty(checkerOutome.details) ? (
                                        <Tag variant={checkerOutome.success ? 'accent' : 'danger'}>
                                          {checkerOutome.details}
                                        </Tag>
                                      ) : null}
                                    </Layout.Group>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <FileViewer
                                      contentType="application/json"
                                      content={JSON.stringify(checkerOutome, null, 2)}
                                      options={{}}
                                      showOptions={false}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              )
                            })}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Card.Body>
                </Card>
              </Layout.Box>
            </>
          ) : null}
        </Layout.Stack>
      ) : null}
    </>
  )
}
