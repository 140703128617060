import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { isEmpty } from 'lodash'
import { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { CustomRendererMetadata } from '../abstraction'
import validateCheckboxSelectionSchemaValidator from './checkboxSelectionSchemaValidator'

export const CHECKBOX_SELECTION_RENDERER_META: CustomRendererMetadata = {
  type: 'CheckboxSelection',
  label: 'Checkbox Selector',
}

interface CheckboxOption {
  label: string
  value: string
  checked: boolean
}

const DIRECTION = {
  vertical: 'flex-col',
  horizontal: 'flex-row',
}

const CheckboxSelectionControl = (props: ControlProps) => {
  const [errors, setErrors] = useState<Array<string>>([])
  const [options, setOptions] = useState<Array<CheckboxOption>>([])
  const [direction, setDirection] = useState(DIRECTION.vertical)

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    let selectedOption = options.find(o => o.value === event.target.value)
    if (!selectedOption) return

    selectedOption = {
      ...selectedOption,
      checked: !selectedOption?.checked,
    }

    const updatedOptions = options.filter(o => o.value !== event.target.value)
    updatedOptions.push(selectedOption)
    props.handleChange(
      props.path,
      updatedOptions.filter(o => o.checked).map(o => o.value)
    )
    setOptions(updatedOptions)
  }

  useEffect(() => {
    const extractOptions = () => {
      const { items } = props.schema
      // @ts-ignore
      let enumValues: any = items?.enum
      if (!enumValues) {
        enumValues = props.schema.enum
      }
      const currentData: Array<string> = props.data ?? []
      const mappedOptions = enumValues.map((value: any) => ({
        label: value,
        value,
        checked: !!currentData.find(v => v === value),
      }))
      setOptions(mappedOptions)
    }

    const extractUiSchemaOptions = () => {
      const uiSchemaOptions = props.uischema.options
      if (!uiSchemaOptions || !('direction' in uiSchemaOptions)) {
        return
      }

      const { direction: directionSchema } = uiSchemaOptions

      if (directionSchema === 'vertical') {
        setDirection(DIRECTION.vertical)
      } else {
        setDirection(DIRECTION.horizontal)
      }
    }

    const newErrors = validateCheckboxSelectionSchemaValidator(props.schema)
    setErrors(newErrors)
    if (isEmpty(newErrors)) {
      extractOptions()
      extractUiSchemaOptions()
    }
  }, [props])

  const sortedOptions = (): Array<CheckboxOption> =>
    options.sort((a, b) => (a.value < b.value ? -1 : 1))

  return (
    <>
      {!isEmpty(errors) && String(errors)}
      {isEmpty(errors) && (
        <div className="flex flex-col">
          <div className="flex">
            <div className="MuiTypography-h5 pb-4">
              {String(props.uischema.label || props.schema.title || '')}
            </div>
          </div>
          <div className={`flex flex-wrap ${direction}`}>
            {sortedOptions().map((option: CheckboxOption) => (
              <div key={`checkbox-selection-${option.value}`} className="flex">
                <FormControlLabel
                  key={`checkbox-selection-form-control-label-${option.value}`}
                  label={option.value}
                  control={
                    <Checkbox
                      key={`checkbox-selection-control-${option.value}`}
                      checked={option.checked}
                      value={option.value}
                      onChange={handleCheckboxChange}
                    />
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default withJsonFormsControlProps(CheckboxSelectionControl)
