import { useCallback, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useHistory } from 'react-router'
import {
  Modal,
  Steps,
  Layout,
  Text,
  Button,
  TextField,
  Switch,
  Select,
  ToggleGroup,
  BannerAction,
} from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { toast } from 'atoms/Toast'
import Loading from 'atoms/Loading'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import { GatewaySettingsDirection } from 'common/types/kraken-core/GatewaySettings'
import { ConnectionSetting } from 'common/types/kraken-core/ConnectionSettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { ImportModalProps, ImportStep, ImportSteps, ImportedGatewaySetting } from '../types'
import {
  onSearchConnections,
  useSearchGatewayTypes,
  useSearchConnectionProtocols,
  useGetTradingPartner,
  onSearchTradingPartners,
  useImportGatewaySetting,
} from '../api'

const ImportModal = ({ onClose, open, mode }: ImportModalProps) => {
  const history = useHistory()
  const [importedData, setImportedData] = useState<ImportedGatewaySetting | null>(null)
  const [actualStep, setActualStep] = useState<ImportStep>('upload-step')
  const [isExistingConnection, setIsExistingConnection] = useState<boolean>(false)

  const { data: gatewayTypesList } = useSearchGatewayTypes()
  const { data: protocolsList } = useSearchConnectionProtocols()
  const { data: ownerData } = useGetTradingPartner(importedData?.connection?.owner)
  const {
    mutate,
    isLoading: importLoading,
    error: importError,
    data: importMutateData,
  } = useImportGatewaySetting()

  useEffect(() => {
    setActualStep(mode === 'import' ? 'upload-step' : 'gateway-verify-step')

    if (mode === 'create') {
      setImportedData({
        rawDefinition: '',
        rawUseWhen: '',
        id: '',
        created_at: '',
        updated_at: '',
        is_active: true,
        name: '',
        owner: '',
        definition: {},
        use_when: {},
        connection: {
          vault: {},
          name: '',
          definition: {},
          rawDefinition: '',
          is_active: true,
          protocol: '',
        },
        connectionId: '',
        direction: 'inbound',
      })
    }
  }, [mode])

  const handleFileUploaded = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()

      reader.onerror = () => {
        toast.error("There's some problems in your file, please review.")
      }

      reader.onload = () => {
        const binaryStr = reader.result
        setImportedData(prevState => ({
          ...prevState,
          ...JSON.parse(binaryStr?.toString() || '{}'),
        }))
      }

      reader.readAsText(file)
    })
  }, [])

  const handleImport = () => {
    if (!importedData) return

    const { rawDefinition, rawUseWhen, connection, ...rest } = importedData

    try {
      const connectionDefinition = connection?.rawDefinition
        ? JSON.parse(connection.rawDefinition)
        : rest.definition
      const gatewayDefinition = rawDefinition ? JSON.parse(rawDefinition) : rest.definition
      const gatewayUseWhen = rawUseWhen ? JSON.parse(rawUseWhen) : rest.use_when

      mutate({
        ...rest,
        definition: gatewayDefinition,
        use_when: gatewayUseWhen,
        connection: {
          ...connection,
          definition: connectionDefinition,
        },
        connectionId: isExistingConnection ? rest.connectionId : undefined,
      })

      setActualStep('finish-step')
    } catch (error) {
      toast.warning("There's some problems in you form, please review.")
    }
  }

  const handleClose = () => {
    setImportedData(prevData => ({
      connectionId: prevData?.connectionId,
    }))
    setActualStep('upload-step')

    onClose()
  }

  const steps: ImportSteps =
    mode === 'import'
      ? [
          {
            complete: false,
            id: 'upload-step',
            label: 'Upload',
          },
          {
            complete: false,
            id: 'gateway-verify-step',
            label: 'Gateway Data',
          },
          {
            complete: false,
            id: 'connection-verify-step',
            label: 'Connection Data',
          },
          {
            complete: false,
            id: 'finish-step',
            label: 'Finish',
          },
        ]
      : [
          {
            complete: false,
            id: 'gateway-verify-step',
            label: 'Gateway Data',
          },
          {
            complete: false,
            id: 'connection-verify-step',
            label: 'Connection Data',
          },
          {
            complete: false,
            id: 'finish-step',
            label: 'Finish',
          },
        ]

  const DATASOURCES = {
    CONNECTIONS: [
      () => ({
        type: 'string',
        adapter: {
          getKey: (tp: ConnectionSetting) => tp.id || '',
          getLabel: (tp: ConnectionSetting) => `${tp.name} (${tp.id})`,
        },
        fetch: onSearchConnections,
      }),
    ],
    TRADING_PARTNER: [
      () => ({
        type: 'string',
        adapter: {
          getKey: (tp: TradingPartner) => tp.id || '',
          getLabel: (tp: TradingPartner) => tp.name || '',
        },
        fetch: onSearchTradingPartners,
      }),
    ],
  }

  return (
    <Modal className="step-modal" scale="large" open={open}>
      <Steps current={actualStep} id="steps-import" steps={steps}>
        <Steps.Step id="upload-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            <Text variant="heading-sm-bold">Upload your Connection Settings JSON file</Text>

            <Dropzone onDrop={handleFileUploaded} accept="application/json">
              {({ getRootProps, getInputProps }) => (
                <div>
                  <div
                    {...getRootProps()}
                    className="
                                            bg-neutral-lighter p-8 border-dashed border-primary-500 border rounded
                                        "
                  >
                    <input {...getInputProps()} />
                    <p>Drag n drop some files here, or click to select files</p>
                  </div>
                </div>
              )}
            </Dropzone>

            {importedData && (
              <FileViewer
                options={{
                  readonly: true,
                  heigth: 250,
                  theme: FileViewTheme.DEFAULT,
                }}
                content={JSON.stringify(importedData, null, 2)}
                contentType="application/json"
                showOptions={false}
              />
            )}

            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Cancel</Button>

              <Button
                variant="primary"
                disabled={!importedData}
                onClick={() => setActualStep('gateway-verify-step')}
              >
                Next
              </Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>

        <Steps.Step id="gateway-verify-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            <Layout.Group>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text>Name</Text>
                <TextField
                  value={importedData?.name}
                  onChange={e =>
                    setImportedData(prevData => {
                      if (!prevData) return null

                      return {
                        ...prevData,
                        name: e.target.value,
                      }
                    })
                  }
                />
              </Layout.Stack>

              <Layout.Stack className="pt-4" space="s">
                <Text>Active</Text>
                <Switch
                  active={importedData?.is_active}
                  onToggle={e =>
                    setImportedData(prevData => {
                      if (!prevData) return null

                      return {
                        ...prevData,
                        is_active: e.target.checked,
                      }
                    })
                  }
                />
              </Layout.Stack>
            </Layout.Group>

            <Layout.Group>
              <Layout.Stack className="flex-1">
                <Text>Gateway type</Text>
                {gatewayTypesList && (
                  <Select
                    id="gateway-type"
                    name="gateway-type"
                    onChange={e =>
                      setImportedData(prevData => {
                        if (!prevData) return null

                        return {
                          ...prevData,
                          gateway_type: (e.target.value as any)?.value,
                        }
                      })
                    }
                    options={gatewayTypesList.map(gatewayType => ({
                      label: gatewayType.description,
                      value: gatewayType.gateway_type,
                    }))}
                    value={
                      {
                        value: importedData?.gateway_type || '-',
                        label:
                          gatewayTypesList.find(p => p.gateway_type === importedData?.gateway_type)
                            ?.description || '-',
                      } as Selectable
                    }
                  />
                )}
              </Layout.Stack>
              <Layout.Stack>
                <Text>Direction</Text>
                <ToggleGroup
                  id="direction"
                  name="direction"
                  onChange={e =>
                    setImportedData(prevData => {
                      if (!prevData) return null
                      if (!e.target.value) return prevData

                      return {
                        ...prevData,
                        direction: e.target.value as GatewaySettingsDirection,
                      }
                    })
                  }
                  value={importedData?.direction}
                  options={[
                    {
                      label: 'Inbound',
                      value: 'inbound',
                    },
                    {
                      label: 'Outbound',
                      value: 'outbound',
                    },
                  ]}
                />
              </Layout.Stack>
            </Layout.Group>

            <Layout.Stack className="flex-1">
              <Text>Definition</Text>
              <FileViewer
                options={{
                  heigth: 100,
                  theme: FileViewTheme.DEFAULT,
                }}
                content={JSON.stringify(importedData?.definition, null, 2)}
                contentType="application/json"
                showOptions={false}
                onChange={value =>
                  setImportedData(prevData => {
                    if (!prevData) return null

                    return {
                      ...prevData,
                      rawDefinition: value,
                    }
                  })
                }
              />
            </Layout.Stack>

            <Layout.Stack className="flex-1">
              <Text>Use when</Text>
              <FileViewer
                options={{
                  heigth: 100,
                  theme: FileViewTheme.DEFAULT,
                }}
                content={JSON.stringify(importedData?.use_when, null, 2)}
                contentType="application/json"
                showOptions={false}
                onChange={value =>
                  setImportedData(prevData => {
                    if (!prevData) return null

                    return {
                      ...prevData,
                      rawUseWhen: value,
                    }
                  })
                }
              />
            </Layout.Stack>

            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Cancel</Button>

              <Button variant="primary" onClick={() => setActualStep('connection-verify-step')}>
                Next
              </Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>

        <Steps.Step id="connection-verify-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            <Layout.Group>
              <Layout.Stack className="pt-4" space="s">
                <Text>Use an existing connection</Text>
                <Switch
                  active={isExistingConnection}
                  onToggle={() => setIsExistingConnection(prev => !prev)}
                />
              </Layout.Stack>

              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text>Connection</Text>
                <Select
                  id="connection"
                  name="connection"
                  onChange={e =>
                    setImportedData(prevData => {
                      if (!prevData) return null

                      return {
                        ...prevData,
                        connectionId: (e.target.value as ConnectionSetting)?.id,
                      }
                    })
                  }
                  datasources={DATASOURCES.CONNECTIONS}
                  disabled={!isExistingConnection}
                />
              </Layout.Stack>
            </Layout.Group>

            <Layout.Group>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text>Name</Text>
                <TextField
                  value={importedData?.connection?.name}
                  disabled={isExistingConnection}
                  onChange={e =>
                    setImportedData(prevData => {
                      if (!prevData) return null

                      return {
                        ...prevData,
                        connection: {
                          ...prevData.connection,
                          name: e.target.value,
                        },
                      }
                    })
                  }
                />
              </Layout.Stack>

              <Layout.Stack className="pt-4" space="s">
                <Text>Active</Text>
                <Switch
                  active={importedData?.connection?.is_active}
                  disabled={isExistingConnection}
                  onToggle={e =>
                    setImportedData(prevData => {
                      if (!prevData) return null

                      return {
                        ...prevData,
                        connection: {
                          ...prevData.connection,
                          is_active: e.target.checked,
                        },
                      }
                    })
                  }
                />
              </Layout.Stack>
            </Layout.Group>

            <Layout.Group>
              <Layout.Stack className="flex-1">
                <Text>Protocol</Text>
                {protocolsList && (
                  <Select
                    id="protocol"
                    name="protocol"
                    disabled={isExistingConnection}
                    options={protocolsList.map(protocol => ({
                      label: protocol.description,
                      value: protocol.protocol,
                    }))}
                    value={
                      {
                        value: importedData?.connection?.protocol || '-',
                        label:
                          protocolsList.find(p => p.protocol === importedData?.connection?.protocol)
                            ?.description || '-',
                      } as Selectable
                    }
                    onChange={e =>
                      setImportedData(prevData => {
                        if (!prevData) return null

                        return {
                          ...prevData,
                          connection: {
                            ...prevData.connection,
                            protocol: (e.target.value as any)?.value,
                          },
                        }
                      })
                    }
                  />
                )}
              </Layout.Stack>

              <Layout.Stack className="flex-1">
                <Text>Owner</Text>
                <Select
                  id="owner"
                  name="owner"
                  disabled={isExistingConnection}
                  value={
                    ownerData
                      ? ({
                          value: ownerData.id,
                          label: ownerData.name,
                        } as Selectable)
                      : null
                  }
                  onChange={e =>
                    setImportedData(prevData => {
                      console.log(e.target.value)
                      if (!prevData) return null

                      return {
                        ...prevData,
                        connection: {
                          ...prevData.connection,
                          owner: (e.target.value as TradingPartner)?.id || '',
                        },
                      }
                    })
                  }
                  datasources={DATASOURCES.TRADING_PARTNER}
                />
              </Layout.Stack>
            </Layout.Group>

            <Layout.Stack className="flex-1">
              <Text>Definition</Text>
              <FileViewer
                options={{
                  heigth: 100,
                  theme: FileViewTheme.DEFAULT,
                  readonly: isExistingConnection,
                }}
                content={JSON.stringify(importedData?.connection?.definition, null, 2)}
                contentType="application/json"
                showOptions={false}
                onChange={value =>
                  setImportedData(prevData => {
                    if (!prevData) return null

                    return {
                      ...prevData,
                      connection: {
                        ...prevData.connection,
                        rawDefinition: value,
                      },
                    }
                  })
                }
              />
            </Layout.Stack>

            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <div className="flex-1">
                <Button onClick={() => setActualStep('gateway-verify-step')}>Back</Button>
              </div>

              <Button onClick={handleClose}>Cancel</Button>

              <Button variant="primary" onClick={handleImport}>
                Import
              </Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>

        <Steps.Step id="finish-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            {importLoading && <Loading className="mt-8 justify-center" />}
            {importError && (
              <ErrorPanel error={removeToken(JSON.stringify(importError, null, 2))} />
            )}
            {importMutateData && (
              <BannerAction
                action="Click here to see"
                onActionButtonClick={() => {
                  history.push(`gateways/${importMutateData.response.data?.id}`)
                }}
                scale="default"
                title="Gateway successfully imported."
                variant="success"
                dismissible={false}
              />
            )}
            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Close</Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>
      </Steps>
    </Modal>
  )
}

export default ImportModal
