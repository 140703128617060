/* eslint-disable no-unused-vars */
import { Button, Drawer, Layout } from '@loadsmart/loadsmart-ui'
import { useCallback, useRef, useState } from 'react'
import { TranslationSettingsProps } from '../types'
import Icon from 'atoms/Icon'
import TranslationSettingsDeprecated from './TranslationSettingsDeprecated'
import { useSearchTranslationSettings } from 'pages/TranslationSettings/api'
import List from 'pages/TranslationSettings/components/List'
import { TranslationSettings } from 'common/types/kraken-core/TranslationSettings'
import TranslationSettingsDetail from 'pages/TranslationSettings/containers/TranslationSettingsDetail'

const TranslationSettingsComponent = ({
  tradingPartner,
  tradingPartnerHistory,
}: TranslationSettingsProps) => {
  const [isDrawerOpem, setDrawerIsOpen] = useState<boolean>(false)
  const [selectedTranslationSettings, setSelectedTranslationSettings] = useState<
    TranslationSettings
  >()
  const onSaveCallback = useRef<any>()
  const [isDeprecatedMode, setDeprecatedMode] = useState<boolean>(false)

  const { data: translationSettingsList, refetch } = useSearchTranslationSettings({
    trading_partner: tradingPartner,
  })

  const onSave = useCallback(() => {
    if (onSaveCallback.current) {
      onSaveCallback.current()
      setDrawerIsOpen(false)
      setTimeout(() => {
        refetch()
      }, 500)
    }
  }, [onSaveCallback.current])

  const onCreateNew = useCallback(() => {
    setSelectedTranslationSettings(undefined)
    setDrawerIsOpen(true)
  }, [])

  const onEdit = useCallback((translationSettings: TranslationSettings) => {
    setSelectedTranslationSettings(translationSettings)
    setDrawerIsOpen(true)
  }, [])

  const onDelete = useCallback((translationSettings: TranslationSettings) => {
    setTimeout(() => {
      refetch()
    }, 500)
  }, [])

  return (
    <Layout.Stack space="none">
      <Drawer
        open={isDrawerOpem}
        onClose={() => {
          setDrawerIsOpen(false)
          setSelectedTranslationSettings(undefined)
          refetch()
        }}
      >
        <Drawer.Body>
          {selectedTranslationSettings?.id && (
            <TranslationSettingsDetail
              tradingPartner={tradingPartner}
              translationSettingsId={selectedTranslationSettings.id}
              onLoadCallback={(loaded, onSave) => {
                onSaveCallback.current = onSave
              }}
            />
          )}

          {!selectedTranslationSettings?.id && (
            <TranslationSettingsDetail
              tradingPartner={tradingPartner}
              onLoadCallback={(loaded, onSave) => {
                onSaveCallback.current = onSave
              }}
            />
          )}
        </Drawer.Body>
        <Drawer.Footer>
          <Layout.Group className="w-full" justify="flex-end" align="flex-end">
            <Button variant="primary" onClick={onSave}>
              Save
            </Button>
          </Layout.Group>
        </Drawer.Footer>
      </Drawer>

      <Layout.Switcher className="p-0">
        <Layout.Group className="pt-4" align="center" justify="flex-end">
          <Button
            onClick={() => {
              setDeprecatedMode(!isDeprecatedMode)
            }}
            variant="warning"
            leading={!isDeprecatedMode ? <Icon name="skull" /> : <Icon name="bolt" />}
          >
            {!isDeprecatedMode ? 'Deprecated Mode' : 'Actual Mode'}
          </Button>
          <Button variant="primary" onClick={onCreateNew}>
            Create new
          </Button>
        </Layout.Group>
      </Layout.Switcher>

      {!isDeprecatedMode && (
        <List
          isLoading={false}
          translationSettingsCollection={translationSettingsList?.results || []}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}

      {isDeprecatedMode && (
        <TranslationSettingsDeprecated
          tradingPartner={tradingPartner}
          tradingPartnerHistory={tradingPartnerHistory}
        />
      )}
    </Layout.Stack>
  )
}

export default TranslationSettingsComponent
