import analytics from 'common/analytics'
import { User } from 'common/types/User'
import { createContext, useContext, useEffect, useState } from 'react'

export interface UserContextState {
  user: User | undefined
  setUser: (user: User) => void
}

export const UserContext = createContext<UserContextState | undefined>(undefined)

export interface UserContextProps {
  children: any
}

export function UserContextProvider({ children }: UserContextProps) {
  const [user, setUser] = useState<User>()

  const handleSetUser = (user: User) => {
    setUser(user)
  }

  useEffect(() => {
    if (user) analytics.setUser(user)
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: handleSetUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUserContext() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }
  return context
}
