import { withJsonFormsControlProps } from '@jsonforms/react'
import { ControlProps } from '@jsonforms/core'
import CSVReader from 'react-csv-reader'
import './csv.reader.style.css'
import Pill from 'atoms/Pill'
import Field from 'atoms/Field'
import { Label } from '@loadsmart/loadsmart-ui'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import validateCSVReaderSchema from './csvReaderSchemaValidator'
import { CustomRendererMetadata } from '../abstraction'

export const CSV_READER_RENDERER_META: CustomRendererMetadata = {
  type: 'CsvReader',
  label: 'CSV Reader',
}

const transformHeader = (header: string) => header.toLowerCase()

const CsvReaderControl = (props: ControlProps) => {
  const errors: Array<string> = validateCSVReaderSchema(props.schema)
  const uiSchemaOptions: any = props.uischema?.options || {}
  const title = uiSchemaOptions.title || 'Provide a CSV file with these columns'
  const titleSpan = useRef<HTMLSpanElement>(null)
  const [propertiesKeys, setPropertiesKeys] = useState<Array<any>>([])
  const [data, setData] = useState<Array<any>>([{}])
  const [papaparseOptions] = useState({
    header: true,
    transformHeader,
    dynamicTyping: true,
    skipEmptyLines: true,
  })

  const handleOnFileLoad = (uploadedData: any) => {
    props.handleChange(props.path, uploadedData)
  }

  useEffect(() => {
    if (props.schema.items) {
      const { items } = props.schema
      const dataFromProps = props.data
      setPropertiesKeys(Object.keys((items as any).properties).sort())
      setData(dataFromProps)
    }
  }, [props])

  useEffect(() => {
    if (titleSpan.current) {
      titleSpan.current.innerHTML = title
    }
  }, [title])

  return (
    <>
      {!isEmpty(errors) && String(errors)}
      {isEmpty(errors) && (
        <>
          <Field>
            <Label>
              <span ref={titleSpan} />
            </Label>
            {propertiesKeys && (
              <div key="csv-reader-columns-properties" className="flex flex-row">
                {propertiesKeys.map(property => (
                  <div key={`csv-reader-columns-property-${property}`} className="flex">
                    <Pill>{property}</Pill>
                  </div>
                ))}
              </div>
            )}
          </Field>

          <CSVReader
            cssInputClass="csv-reader"
            onFileLoaded={handleOnFileLoad}
            onError={() => {}}
            parserOptions={papaparseOptions}
          />

          {data && (
            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow key="csv-reader-table-header">
                    {Object.keys(data[0]).map((key: any, index) => (
                      <TableCell key={`csv-reader-table-header-cell-${index}`}>{key}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((d: any) => (
                    <TableRow key={`csv-reader-table-row-${String(d)}`}>
                      {Object.values(d).map((value: any, index) => (
                        <TableCell key={`csv-reader-table-body-row-cell-${index}`}>
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  )
}

export default withJsonFormsControlProps(CsvReaderControl)
