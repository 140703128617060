import { Button, IconButton, Layout, Tag, TextField } from '@loadsmart/loadsmart-ui'
import { Divider, Drawer, LinearProgress, Paper, Tab, Tabs, Tooltip } from '@mui/material'
import Icon from 'atoms/Icon'
import {
  IntegrationTestCaseDetail,
  IntegrationTestCaseExpectationDefinition,
} from 'common/types/kraken-core/IntegrationTest'
import { isEqual } from 'lodash'
import isEmpty from 'lodash.isempty'
import ErrorPanel from 'molecules/ErrorPanel'
import {
  TabViewIndex,
  useIntegrationTestContext,
} from 'pages/IntegrationTest/IntegrationTestContext'
import { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useCreateIntegrationTestCase, useUpdateIntegrationTestCase } from '../api'
import IntegrationTestCaseDetailEntryPoint from './IntegrationTestCaseDetailEntryPoint'
import IntegrationTestCaseDetailInputtDefinition from './IntegrationTestCaseDetailInputDefinition'
import IntegrationTestCaseExecutionResultDetails from './IntegrationTestCaseExecutionResultDetails'
import IntegrationTestCaseDetailExpectationDefinitionDetail from './IntegrationTestCaseExpectationDefinition'
import IntegrationTestCaseStatus from './IntegrationTestCaseStatus'

export interface IntegrationTestCaseDetailProps {
  onClose?: () => void
}

export default function IntegrationTestCaseDetailDrawer(props: IntegrationTestCaseDetailProps) {
  const context = useIntegrationTestContext()
  const [isDirty, setDirty] = useState(false)
  const [entrypointDefinition, setEntryPointDefinition] = useState<{
    inputGateway?: string
    inputRoute?: string
  }>()
  const [localTestCase, setLocalTestCase] = useState<IntegrationTestCaseDetail>()
  const originalTest = useRef<IntegrationTestCaseDetail>()
  const [expectationDefinition, setExpectationDefinition] = useState<
    IntegrationTestCaseExpectationDefinition
  >()
  const [inputDefinition, setInputDefinition] = useState<any>()
  const [validation, setValidation] = useState<string>()
  const {
    mutate: updateTestCase,
    error: updateTestCaseError,
    isSuccess: isUpdateTestCaseSuccess,
    isError: isUpdateTestCaseError,
    isLoading: isUpdating,
  } = useUpdateIntegrationTestCase()

  const {
    mutate: createTestCase,
    error: createTestCaseError,
    isSuccess: isCreateTestCaseSuccess,
    isError: isCreateTestCaseError,
    isLoading: isCreating,
  } = useCreateIntegrationTestCase()

  const validateTestCaseMandatoryFields = (): boolean => {
    if (!localTestCase) return false
    let isValid = true
    const validationErrors = []
    if (isEmpty(localTestCase.input_gateway)) {
      validationErrors.push('Input Gateway is mandatory')
      isValid = false
    }

    if (isEmpty(localTestCase.input_route)) {
      validationErrors.push('Input Route is mandatory')
      isValid = false
    }

    if (isEmpty(localTestCase.suite)) {
      validationErrors.push('Input Gateway is mandatory')
      isValid = false
    }

    if (isEmpty(localTestCase.description)) {
      validationErrors.push('Description is mandatory')
      isValid = false
    }

    if (!isValid) {
      setValidation(validationErrors.join(', '))
    } else {
      setValidation(undefined)
    }
    return isValid
  }

  const handleOnClose = () => {
    if (props.onClose) props.onClose()
    context.toggleTestCaseDetailView()
  }

  const handleOnSave = async () => {
    if (!localTestCase) {
      toast.error("There's no test case defined!")
      return
    }

    if (!validateTestCaseMandatoryFields()) {
      toast.error('Validation error')
      return
    }

    if (isEmpty(localTestCase?.id)) {
      createTestCase({
        suite_id: context.suite?.id,
        object: localTestCase,
      })
    } else {
      // Update
      updateTestCase({
        id: localTestCase?.id,
        suite_id: context.suite?.id,
        object: localTestCase,
      })
    }
    setDirty(false)
  }

  const handleTabChange = (tabIndex: number) => {
    context.setTestDetailViewState({
      ...context.testDetailViewState,
      activeViewTab: tabIndex,
    })
  }

  const EntryPointComponent = useMemo(() => {
    return (
      <IntegrationTestCaseDetailEntryPoint
        definition={{
          inputGateway: entrypointDefinition?.inputGateway,
          inputRoute: entrypointDefinition?.inputRoute,
        }}
        onChange={(propertyName, value) => {
          setLocalTestCase(c => ({
            ...c,
            [propertyName]: value,
          }))
        }}
      />
    )
  }, [entrypointDefinition])

  const InputDefinitionComponent = useMemo(() => {
    return (
      <IntegrationTestCaseDetailInputtDefinition
        definition={inputDefinition}
        onChange={(propertyName, value) => {
          if (propertyName) {
            setLocalTestCase(c => ({
              ...c,
              [propertyName]: value,
            }))
          } else {
            setLocalTestCase(c => ({
              ...c,
              input_definition: value,
            }))
          }
        }}
      />
    )
  }, [inputDefinition])

  const ExpectationDefinitionComponent = useMemo(() => {
    return (
      <IntegrationTestCaseDetailExpectationDefinitionDetail
        definition={expectationDefinition}
        onChange={value => {
          setLocalTestCase(c => ({
            ...c,
            expectation_definition: value,
          }))
        }}
      />
    )
  }, [expectationDefinition])

  useEffect(() => {
    setValidation(undefined)
    originalTest.current = context.testCase
    setLocalTestCase(context.testCase)
    setEntryPointDefinition({
      inputGateway: context.testCase?.input_gateway,
      inputRoute: context.testCase?.input_route,
    })
    setExpectationDefinition(context.testCase?.expectation_definition)
    setInputDefinition({
      http_mocks: context.testCase?.http_mocks,
      lookup_table_mocks: context.testCase?.lookup_table_mocks,
      ftp_mocks: context.testCase?.ftp_mocks,
      inputDefinition: context.testCase?.input_definition,
    })
    setDirty(false)
  }, [context.testCase])

  useEffect(
    function onMutationError() {
      if (createTestCaseError) toast.error(`Error whe creating test: ${createTestCaseError}`)
      if (updateTestCaseError) toast.error(`Error whe updating test: ${updateTestCaseError}`)
    },
    [createTestCaseError, updateTestCaseError]
  )

  useEffect(
    function onMutationSuccess() {
      if (isUpdateTestCaseSuccess) {
        toast.success('Succesfully saved')
      }
    },
    [isUpdateTestCaseSuccess]
  )

  useEffect(
    function onMutationSuccess() {
      if (isCreateTestCaseSuccess) {
        toast.success('Succesfully created')
      }
    },
    [isCreateTestCaseSuccess]
  )

  useEffect(() => {
    if (originalTest.current && localTestCase) {
      setDirty(!isEqual(originalTest.current, localTestCase))
    }
  }, [localTestCase])

  return (
    <Drawer
      open={context.testDetailViewState.isTestCaseDetailViewOpened}
      anchor="right"
      PaperProps={{
        style: {
          width: '60%',
        },
      }}
      onClose={handleOnClose}
    >
      {isCreating || isUpdating ? <LinearProgress /> : null}

      {localTestCase ? (
        <Layout.Stack
          className="h-full"
          justify="space-between"
          style={{
            position: 'relative',
          }}
        >
          <Layout.Stack
            className="w-full"
            space="none"
            style={{
              position: 'relative',
            }}
          >
            <Layout.Stack
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              <Paper elevation={0}>
                <Layout.Group className="p-6" align="center" justify="space-between">
                  <Layout.Stack space="s">
                    <TextField
                      value={localTestCase.description}
                      onChange={e => {
                        setLocalTestCase(c => ({
                          ...c,
                          description: e.target.value,
                        }))
                      }}
                      placeholder="Description"
                      size={100}
                      required
                    />
                  </Layout.Stack>

                  <Layout.Group align="center">
                    {context.localTestCase ? (
                      <IntegrationTestCaseStatus
                        test={context.localTestCase}
                        loadingSize={30}
                        tagSize="large"
                      />
                    ) : null}

                    <Tooltip
                      title={
                        !isDirty
                          ? 'Run test'
                          : 'There are pending changes, save them before running the test'
                      }
                    >
                      <IconButton
                        onClick={e => {
                          context.runTestCase()
                        }}
                        scale="large"
                        disabled={context.localTestCase?.execution.isRunning || isDirty}
                      >
                        <Icon name="bolt" size={20} />
                      </IconButton>
                    </Tooltip>
                  </Layout.Group>
                </Layout.Group>

                <Divider />
              </Paper>
            </Layout.Stack>

            <Layout.Stack className="p-4">
              {!isEmpty(validation) ? (
                <ErrorPanel title="Validation Error" error={validation} />
              ) : null}

              <Tabs
                textColor="primary"
                indicatorColor="primary"
                value={context.testDetailViewState.activeViewTab}
                onChange={(_, val) => handleTabChange(val)}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Entry Point" />
                <Tab label="Inputs" />
                <Tab label="Expectations" />
                <Tab label="Execution" />
              </Tabs>

              <Divider />

              <div
                style={{
                  display:
                    context.testDetailViewState.activeViewTab === TabViewIndex.ENTRY_POINT
                      ? ''
                      : 'none',
                }}
              >
                {EntryPointComponent}
              </div>
              <div
                style={{
                  display:
                    context.testDetailViewState.activeViewTab === TabViewIndex.INPUTS ? '' : 'none',
                }}
              >
                {InputDefinitionComponent}
              </div>
              <div
                style={{
                  display:
                    context.testDetailViewState.activeViewTab === TabViewIndex.EXPECTATIONS
                      ? ''
                      : 'none',
                }}
              >
                {ExpectationDefinitionComponent}
              </div>
              <div
                style={{
                  display:
                    context.testDetailViewState.activeViewTab === TabViewIndex.EXECUTION
                      ? ''
                      : 'none',
                }}
              >
                <IntegrationTestCaseExecutionResultDetails />
              </div>
            </Layout.Stack>
          </Layout.Stack>

          <Layout.Stack
            style={{
              position: 'sticky',
              bottom: 0,
              zIndex: 1,
            }}
          >
            <Paper>
              <Divider />
              <Layout.Group className="p-6" justify="space-between">
                <Button onClick={handleOnClose}>Cancel</Button>

                <Layout.Group align="center">
                  {isDirty ? (
                    <Tag variant="warning" size="large">
                      Pending Changes
                    </Tag>
                  ) : null}
                  <Button variant="primary" onClick={handleOnSave} disabled={!isDirty}>
                    Save
                  </Button>
                </Layout.Group>
              </Layout.Group>
            </Paper>
          </Layout.Stack>
        </Layout.Stack>
      ) : null}
    </Drawer>
  )
}
