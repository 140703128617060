import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Breadcrumbs,
  Layout,
  Select,
  Table,
  Tag,
  Text,
  TextField,
  ToggleGroup,
  Dropdown,
  IconButton,
  Dialog,
} from '@loadsmart/loadsmart-ui'
import { LinearProgress } from '@mui/material'
import SimplePagination from 'atoms/SimplePagination'
import { toast } from 'atoms/Toast'
import EventLike from 'common/types/EventLike'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { TranslationMap, TranslationMapLoader } from 'common/types/kraken-core/TranslationMap'
import Loading from 'atoms/Loading'
import { MessageDirection } from 'common/types/kraken-core/Message'
import Icon from 'atoms/Icon'
import { parse, stringfy } from 'common/helpers/queryString'
import { useSearchTranslationMaps, onSearchLoaders, useDeleteTranslationMapSetting } from '../api'
import ImportModal from '../components/ImportModal'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import isEmpty from 'lodash.isempty'
import PushableEntityStatusTag from '../../../common/components/development/PusableEntityStatus'
import DirectionTag from 'atoms/DirectionTag/DirectionTag'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const DATASOURCES = {
  TRANSLATION_MAP_LOADER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tt: TranslationMapLoader) => tt.loader || '',
        getLabel: (tt: TranslationMapLoader) => tt.description || '',
      },
      fetch: onSearchLoaders,
    }),
  ],
}

const DIRECTION_FILTER_VALUES = [
  {
    label: 'Inbound',
    value: MessageDirection.INBOUND,
  },
  {
    label: 'Outbound',
    value: MessageDirection.OUTBOUND,
  },
]

const TranslationMapsList = () => {
  useTabTitle('Translation Maps')
  const urlFilters = parse(window.location.search?.slice(1) ?? '')
  const breadCrumbs = [
    {
      label: 'Translation maps',
      active: true,
    },
  ]
  const [page, setPage] = useState(1)
  const [modalMode, setModalMode] = useState<'create' | 'import' | null>(null)
  const [translationMapToDelete, setTranslationMapToDelete] = useState<TranslationMap | null>(null)
  const [filters, setFilters] = useState<any>(urlFilters)
  const debouncedFilters = useDebouncedValue<any>(filters, 500)
  const { isLoading, error, data, isFetching } = useSearchTranslationMaps({
    page,
    ...debouncedFilters,
  })
  const {
    mutate: deleteTranslationMap,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    error: deletedError,
    reset: deletedReset,
  } = useDeleteTranslationMapSetting()

  const topNavigationContext = useTopNavigationContext()

  useEffect(() => {
    if (deletedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isDeleted) {
      toast.success('Deleted with success')
    }

    setTimeout(() => {
      deletedReset()
      setTranslationMapToDelete(null)
    }, 0)
  }, [isDeleted, deletedError])

  useEffect(() => {
    window.history.replaceState(null, '', `?${stringfy(filters)}`)
  }, [filters])

  const COLUMNS = [
    {
      Header: '#',
      Cell(row: TranslationMap) {
        const idAsString = row.id?.slice(0, 8) || '-'

        return (
          <Tag variant="default">
            <Link to={`translation-maps/${row.id}`}>{idAsString}</Link>
          </Tag>
        )
      },
    },
    {
      Header: 'Name',
      Cell(row: TranslationMap) {
        return <div className="w-96 truncate">{row.name}</div>
      },
    },
    {
      Header: 'Output Content type',
      Cell(row: TranslationMap) {
        return row.output_content_type
      },
    },
    {
      Header: 'Loader',
      Cell(row: TranslationMap) {
        return row.loader
      },
    },
    {
      Header: '',
      Cell(row: TranslationMap) {
        return <DirectionTag direction={row.use_when_direction} />
      },
    },
    {
      Header: 'Status',
      alignment: 'right',
      Cell(row: TranslationMap) {
        return row.status ? <PushableEntityStatusTag status={row.status} size="default" /> : null
      },
    },
    {
      Header: 'Updated at',
      alignment: 'right',
      Cell(row: TranslationMap) {
        return LSDate(row.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM) || '-'
      },
    },
    {
      Header: 'Actions',
      alignment: 'right',
      Cell(row: TranslationMap) {
        return (
          <IconButton
            onClick={e => {
              setTranslationMapToDelete(row)
              e.stopPropagation()
            }}
          >
            <Icon name="remove" />
          </IconButton>
        )
      },
    },
  ]

  const handleFilterChange = (e: EventLike<any>) => {
    const { name, value } = e.target

    if (!name) return

    setFilters((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCloseDeleteConfirmation = () => {
    setTranslationMapToDelete(null)
  }

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group
          data-testid="header"
          align="center"
          justify="space-between"
          className="w-full"
        >
          <Breadcrumbs entries={breadCrumbs} />
          <div>
            <Dropdown>
              <Dropdown.Trigger>Actions</Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setModalMode('create')}>Create</Dropdown.Item>
                <Dropdown.Item onClick={() => setModalMode('import')}>Import</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Layout.Group>
      ),
    })
  }, [])

  return (
    <>
      <ImportModal onClose={() => setModalMode(null)} open={!!modalMode} mode={modalMode} />

      <Dialog
        open={!!translationMapToDelete}
        onOverlayClick={handleCloseDeleteConfirmation}
        scale="small"
      >
        <Dialog.Header>Delete</Dialog.Header>
        <Dialog.Body>Are you sure you want to delete this translation map setting?</Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isDeleting}
          onConfirm={() =>
            translationMapToDelete && deleteTranslationMap(translationMapToDelete.id)
          }
        />
        <Dialog.ActionCancel onCancel={handleCloseDeleteConfirmation} />
      </Dialog>

      {isLoading ? (
        <Loading className="mt-8" />
      ) : (
        !!data && (
          <Layout.Stack className="w-full mt-6 overflow-scroll">
            <Layout.Stack>
              <Layout.Group align="flex-end">
                <div className="flex flex-col">
                  <Text>Name</Text>
                  <TextField
                    id="name"
                    name="name"
                    onChange={handleFilterChange}
                    value={filters.name}
                  />
                </div>
                <div className="flex flex-col">
                  <Text>Loader</Text>
                  <Select
                    id="loader"
                    name="loader"
                    onChange={handleFilterChange}
                    datasources={DATASOURCES.TRANSLATION_MAP_LOADER}
                    value={
                      filters.loader
                        ? ({
                            label: filters.loader.description,
                          } as any)
                        : undefined
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <Text>Direction</Text>
                  <ToggleGroup
                    id="direction"
                    name="direction"
                    className="flex justify-center"
                    onChange={handleFilterChange}
                    options={DIRECTION_FILTER_VALUES}
                    value={
                      !isEmpty(filters.direction)
                        ? (DIRECTION_FILTER_VALUES.find(
                            v => String(v.value) === String(filters.direction)
                          )?.value as any)
                        : undefined
                    }
                  />
                </div>
              </Layout.Group>
            </Layout.Stack>

            <LinearProgress className={isFetching ? 'opacity-100' : 'opacity-0'} />

            <Table className="w-full" data-testid="translation-maps-list">
              <Table.Head>
                {COLUMNS.map(column => (
                  <Table.Cell key={column.Header} alignment={column.alignment as any}>
                    {column.Header}
                  </Table.Cell>
                ))}
              </Table.Head>
              <Table.Body>
                {(data.results || []).map((row: any) => (
                  <Table.Row key={row.id}>
                    {COLUMNS.map(cell => (
                      <Table.Cell
                        className="text-sm"
                        key={cell.Header}
                        alignment={cell.alignment as any}
                      >
                        {cell.Cell(row)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Layout.Box className="flex justify-end w-full">
              <SimplePagination
                currentPage={page}
                previousPage={() => setPage(prev => prev - 1)}
                nextPage={() => setPage(prev => prev + 1)}
                canPreviousPage={!!data.previous && !isFetching}
                canNextPage={!!data.next && !isFetching}
              />
            </Layout.Box>
          </Layout.Stack>
        )
      )}
    </>
  )
}

export default TranslationMapsList
