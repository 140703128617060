import { datadogRum } from '@datadog/browser-rum'
import getEnv from './helpers/getEnv'
import { User } from './types/User'
import mixpanel from 'mixpanel-browser'
import Environment from './types/Environment'

export enum AnalyticsEvent {
  //Test Event
  TestEvent = 'Test Event',

  // Message Dashboard Filters,
  MessageDashboardFiltersSelectPartners = 'Message Dashboard / Filters / Select Partners',
  MessageDashboardFiltersSelectTransactionTypes = 'Message Dashboard / Filters / Select Transaction Types',
  MessageDashboardFiltersSelectExcludedTransactionTypes = 'Message Dashboard / Filters / Excluded Transaction Types',
  MessageDashboardFiltersReferenceCode = 'Message Dashboard / Filters / Reference Code',
  MessageDashboardFiltersShowAdvancedFilters = 'Message Dashboard / Filters / Show Advanced Filters',
  MessageDashboardFiltersDirection = 'Message Dashboard / Filters / Direction',
  MessageDashboardFiltersDelivered = 'Message Dashboard / Filters / Delivered',
  MessageDashboardFiltersCreatedAtDate = 'Message Dashboard / Filters / Created Date',
  MessageDashboardFiltersUpdatedAtDate = 'Message Dashboard / Filters / Updated Date',
  MessageDashboardFiltersLastStep = 'Message Dashboard / Filters / Last Step',
  MessageDashboardFiltersExcludedLastStep = 'Message Dashboard / Filters / Excluded Last Step',
  MessageDashboardFiltersCreatedDate = 'Message Dashboard / Filters / Created Date',
  MessageDashboardFiltersClearAll = 'Message Dashboard / Filters / Clear All',
  MessageDashboardFiltersHitSearch = 'Message Dashboard / Filters / Hit Search',
  MessageDashboardFiltersStatus = 'Message Dashboard / Filters / Status',
  MessageDashboardFiltersTags = 'Message Dashboard / Filters / Tags',

  // Message Dashboard Actions
  MessageDashboardActionsSelectAction = 'Message Dashboard / Actions / Select',
  MessageDashboardActionsApplyAction = 'Message Dashboard / Actions / Apply',

  // Message Dashboard Views
  MessageDashboardViewsCreate = 'Message Dashboard / Views / Create',
  MessageDashboardViewsUpdate = 'Message Dashboard / Views / Update',
  MessageDashboardViewsRemove = 'Message Dashboard / Views / Remove',
  MessageDashboardViewsDuplicate = 'Message Dashboard / Views / Duplicate',

  // Message Dashboard Listing
  MessageDashboardListOpenDetail = 'Message Dashboard / List / Open Detail',
  MessageDashboardListNavigateBetween = 'Message Dashboard / List / Navigate Between Messages',
  MessageDashboardListPaginate = 'Message Dashboard / List / Paginate',
  MessageDashboardSelect = 'Message Dashboard / List / Select',

  // Message Detail
  MessageDetailCopyLink = 'Message / Detail / Copy Link',
  MessageDetailReload = 'Message / Detail / Reload',
  MessageDetailClose = 'Message / Detail / Close',
  MessageDetailViewFullpage = 'Message / Detail / View Full Page',
  MessageDetailSelectAction = 'Message / Detail / Select Action',
  MessageDetailApplyAction = 'Message / Detail / Apply Action',
  MessageDetailSwitchTab = 'Message / Detail / Switch Tab',
  MessageDetailUpdateTabContent = 'Message / Detail / Update Tab Content',
  MessageDetailHistoryRevert = 'Message / Detail / History / Revert',
  MessageDetailHistoryViewBody = 'Message / Detail / History / View Body',
  MessageDetailActionsSelect = 'Message / Detail / Actions / Select',
  MessageDetailActionsApply = 'Message / Detail / Actions / Apply',
  MessageDetailLogFilter = 'Message / Detail / Logs / Filter',

  // Conversion Codes
  ConversionCodesListFilters = 'Conversion Codes / List / Filters',
  ConversionCodesListPaginate = 'Conversion Codes / List / Paginate',
  ConversionCodesDetailsCreate = 'Conversion Codes / Detail / Create',
  ConversionCodesDetailsUpdate = 'Conversion Codes / Detail / Update',
  ConversionCodesAction = 'Conversion Codes / List / Action',

  // Template Onboard Dashboard Views
  TemplateOnboardDashboardViewsCreate = 'Template Onboard Dashboard / Views / Create',
  TemplateOnboardDashboardViewsUpdate = 'Template Onboard Dashboard / Views / Update',
  TemplateOnboardDashboardViewsRemove = 'Template Onboard Dashboard / Views / Remove',
  TemplateOnboardDashboardViewsDuplicate = 'Template Onboard Dashboard / Views / Duplicate',
}

export enum AnalyticsEventTrigger {
  click = 'click',
  select = 'select',
  redirect = 'redirect',
  success = 'success',
  error = 'error',
  hover = 'hover',
  view = 'view',
  keydown = 'keydown',
  change = 'change',
}

interface EventArgs {
  category: AnalyticsEvent
  action: string
  label?: string
  value?: number | string
  nonInteraction?: boolean
  transport?: 'beacon' | 'xhr' | 'image'
  params?: any
}

const service = 'kraken-frontend'

let isUserSet = false

const doesTrack = () => [Environment.Production, Environment.Sandbox].includes(getEnv())

const initDataDog = () => {
  if (
    process.env.REACT_APP_DATADOG_RUM_APP_ID &&
    process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN &&
    process.env.REACT_APP_ENVIRONMENT &&
    process.env.REACT_APP_RELEASE_VERSION
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
      service,
      env: process.env.REACT_APP_ENVIRONMENT,
      version: process.env.REACT_APP_RELEASE_VERSION,
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: true,
    })
  }
}

const initMixPanel = () => {
  if (process.env.REACT_APP_MIXPANEL_APP_ID) mixpanel.init(process.env.REACT_APP_MIXPANEL_APP_ID)
}

const trackerInitializers = [initDataDog, initMixPanel]

export default {
  init: () => {
    try {
      if (!doesTrack()) {
        return
      }
      trackerInitializers.forEach(initFunction => initFunction())
    } catch (error) {
      console.error(`Failure when intializing analytics: ${error}`)
    }
  },
  setUser: (user: User) => {
    try {
      if (!doesTrack() || isUserSet) {
        return
      }
      const formattedData = user.id
        ? {
            id: user.id,
            name: user.first_name,
            email: user.email,
          }
        : user
      const userData = { ...formattedData }
      mixpanel.identify(userData.id)
      datadogRum.setUser(userData)
      isUserSet = true
    } catch (error) {
      console.error(`Failure when setting analytic user: ${error}`)
    }
  },
  event: (args: EventArgs) => {
    try {
      if (!doesTrack()) {
        return
      }
      mixpanel.track(args.action, { ...args })
      datadogRum.addAction(args.action, { ...args })
    } catch (error) {
      console.error(`Failure when generating analytic event: ${error}`)
    }
  },
  reset: () => {
    if (!doesTrack()) {
      return
    }
    mixpanel.reset()
  },
}
