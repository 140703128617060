import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { del, get, post, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { PaginatedRequest, PaginatedResponse } from 'common/types/kraken-core/Pagination'
import {
  Partnership,
  PartnershipCreateUpdate,
  PartnershipDetail,
} from 'common/types/kraken-core/Partnership'

export interface SearchPartnershipPageRequest extends PaginatedRequest {
  party_name?: string
  counterparty_name?: string
}

export async function getPartnership(
  id: number,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PartnershipDetail>> {
  const [error, response] = await to(get(`/partnership/${id}`, { ...config }))
  return { error, response }
}

export async function createPartnership(
  partnership: PartnershipCreateUpdate,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PartnershipCreateUpdate>> {
  const [error, response] = await to(post(`/partnership`, partnership, { ...config }))
  return { error, response }
}

export async function updatePartnership(
  partnership: PartnershipCreateUpdate,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PartnershipCreateUpdate>> {
  const [error, response] = await to(
    put(`/partnership/${partnership.id}`, partnership, { ...config })
  )
  return { error, response }
}

export async function deletePartnership(
  id: number,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PartnershipDetail>> {
  const [error, response] = await to(del(`/partnership/${id}`, { ...config }))
  return { error, response }
}

export async function searchPartnership(
  data?: SearchPartnershipPageRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PaginatedResponse<Partnership>>> {
  const params: any = {
    party_name: data?.party_name,
    counterparty_name: data?.counterparty_name,
    page: data?.page || 1,
    page_size: data?.page_size || 10,
  }
  const [error, response] = await to(get('/partnership', { ...config, params }))
  return { error, response }
}
