import { Box } from '@mui/material'
import { ILinkDefaultProps, LinkDefault } from '@mrblenny/react-flow-chart'

const LinkCustom = (props: ILinkDefaultProps) => {
  const { startPos, endPos, link } = props
  const centerX = startPos.x + (endPos.x - startPos.x) / 2 - 10
  const centerY = startPos.y + (endPos.y - startPos.y) / 2 - 10
  const failures =
    link.properties?.failures?.map((f: { exception: any }) => f.exception).join('\n') || null

  return (
    <>
      <LinkDefault {...props} className={failures ? 'link-error' : ''} />
      {failures && (
        <Box className="link" style={{ left: centerX, top: centerY }}>
          {failures}
        </Box>
      )}
    </>
  )
}

export default LinkCustom
