import simpleHeaderSwitcherUiSchema from './simpleHeaderSwitcher/uiSchema'
import lookUpTableSaveUiSchema from './LookUpTableSave/uiSchema'
import lookUpTableRetrieveUiSchema from './LookUpTableRetrieve/uiSchema'
import RuleBasedUiSchema from './RuleBased/uiSchema'

const schemas: any = {
  'processor:simple-header-switcher': {
    ui: simpleHeaderSwitcherUiSchema,
  },
  'processor:lookup-table:save': {
    ui: lookUpTableSaveUiSchema,
  },
  'processor:lookup-table:retrieve': {
    ui: lookUpTableRetrieveUiSchema,
  },
  'processor:metrics:count': {
    ui: RuleBasedUiSchema,
  },
  'processor:cloner': {
    ui: RuleBasedUiSchema,
  },
  'processor:json:splitter': {
    ui: RuleBasedUiSchema,
  },
  'processor:delegate-when-accepts': {
    ui: RuleBasedUiSchema,
  },
}

export default schemas
