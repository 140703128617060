import Loading from 'atoms/Loading'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { TranslationSettingsDetail as TranslationSettingsDetailDTO } from 'common/types/kraken-core/TranslationSettings'
import isEmpty from 'lodash.isempty'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import {
  useCreateTranslationSettings,
  useGetTranslationSettings,
  useUpdateTranslationSettings,
} from '../api'
import Form, { FormValidation } from '../components/Form'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import { Layout } from '@loadsmart/loadsmart-ui'
import IaCComponentReferenceWarning from 'atoms/IaCComponentReferenceWarning/IaCComponentReferenceWarning'

export interface TranslationSettingsDetailProps {
  translationSettingsId?: string
  tradingPartner?: TradingPartner
  onLoadCallback?: (translationSettings: TranslationSettingsDetailDTO, onSave: Function) => void
}

const TranslationSettingsDetail = ({
  translationSettingsId,
  tradingPartner,
  onLoadCallback,
}: TranslationSettingsDetailProps) => {
  useTabTitle('Translation Settings')
  const [formState, setFormState] = useState<TranslationSettingsDetailDTO>({})
  const stateToBeSaved = useRef<TranslationSettingsDetailDTO>()
  const validation = useRef<FormValidation>()
  const {
    data: updated,
    mutate: update,
    error: updateError,
    isSuccess: isUpdated,
    reset: updateReset,
  } = useUpdateTranslationSettings()
  const { data, isLoading } = useGetTranslationSettings(
    {
      id: translationSettingsId || '',
    },
    {
      enabled: translationSettingsId ? true : false,
    }
  )
  const {
    mutate: create,
    error: createError,
    isSuccess: isCreated,
    data: created,
  } = useCreateTranslationSettings()

  const onUpdate = useCallback(
    (translationSettings: TranslationSettingsDetailDTO, validationState?: FormValidation) => {
      if (translationSettings) stateToBeSaved.current = translationSettings
      if (validationState) validation.current = validationState
    },
    [translationSettingsId, onLoadCallback]
  )

  const onSave = useCallback(async () => {
    if (validation.current && !isEmpty(Object.values(validation.current))) {
      toast.error('There are validation errors, review definition')
      return false
    }

    if (stateToBeSaved.current) {
      if (!stateToBeSaved.current?.id) {
        create(stateToBeSaved.current)
      } else update(stateToBeSaved.current)

      // Reset state
      stateToBeSaved.current = undefined
      setFormState({
        name: '',
      })
      return true
    }
  }, [stateToBeSaved.current, validation.current])

  useEffect(() => {
    if (onLoadCallback && stateToBeSaved.current) onLoadCallback(stateToBeSaved.current, onSave)
  }, [data, onLoadCallback, stateToBeSaved.current, onSave])

  useEffect(() => {
    if (updateError) {
      toast.error(`Something went wrong: ${String(updateError)}`)
    }

    if (isUpdated) {
      toast.success('Updated with success')
    }

    setTimeout(() => {
      updateReset()
    }, 0)
  }, [isUpdated, updateError])

  useEffect(() => {
    if (createError) {
      toast.error(`Somwthing went wrong when creating: ${createError}`)
      return
    }
    if (isCreated) toast.success(`Successfully created`)
  }, [isCreated, createError, created])

  useEffect(() => {
    stateToBeSaved.current = data
    setFormState(data || {})
  }, [data])

  useEffect(() => {
    if (!translationSettingsId) {
      stateToBeSaved.current = {}
      setFormState({
        name: '',
        translation_map: undefined,
        trading_partner: undefined,
        use_when: undefined,
      })
    }
  }, [translationSettingsId])

  if (isLoading) return <Loading />

  return (
    <Layout.Stack>
      {formState.iac_reference ? <IaCComponentReferenceWarning /> : null}
      <Form
        tradingPartnerFromContext={tradingPartner}
        translationSettings={formState}
        onUpdate={onUpdate}
      />
    </Layout.Stack>
  )
}

export default TranslationSettingsDetail
