import { toast } from 'atoms/Toast'
import { IntegrationTemplate } from 'common/types/kraken-core/IntegrationTemplate'

const DEFAULT_EXPORTED_FILE_NAME = 'exported'

export function exportData(template: IntegrationTemplate, data: any) {
  if (!template) {
    toast.error('There is no integration template loaded')
    return
  }

  const counterpartyName: string = data.counterparty
    ? data.counterparty.name
    : DEFAULT_EXPORTED_FILE_NAME
  const formattedCounterpartyName = counterpartyName.replaceAll(' ', '_')

  const templateKey = template.key ?? ''
  const formatedTemplateKey = templateKey.replaceAll(':', '_')
  const fileName = `${formattedCounterpartyName}_${formatedTemplateKey}_onboarding`

  const link = document.createElement('a')
  const encodedURI = encodeURIComponent(JSON.stringify(data))
  const href = `data:application/json;charset=utf-8,${encodedURI}`
  link.setAttribute('href', href)
  link.setAttribute('download', fileName)
  link.click()
}

export function importData(setterCallback: (data: any) => void) {
  const expectedFileType = 'json'
  function handleFile(event: any) {
    const file = event.target.files.item(0)
    if (!file.type.match(`.*${expectedFileType}`)) {
      toast.error(`Invalid file type, expected is ${expectedFileType}`)
      return
    }

    const fileReader = new FileReader()
    fileReader.onload = () => {
      const loadedJson = String(fileReader.result)
      const parsedJson = JSON.parse(loadedJson)
      setterCallback(parsedJson)
      toast.success('File successfully imported')
    }
    fileReader.readAsText(file)
  }

  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.addEventListener('change', handleFile, false)
  input.click()
}
