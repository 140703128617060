/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import { MessageDirection } from 'common/types/kraken-core/Message'
import { PaginatedRequest } from 'common/types/kraken-core/Pagination'
import {
  TranslationMapLoader,
  TranslationTestCase,
  TranslationTestCaseDetail,
  TranslationTestCaseExecutionResult,
} from 'common/types/kraken-core/TranslationMap'

export enum QUERIES {
  GET_TRANSLATION_MAP = 'translation_map/get',
  GET_RUNTIME_TRANSLATION_MAP = 'translation_map/runtime/get',
  GET_TRANSLATION_TEST_CASE = 'translation_map/test_case/get',
  GET_TRANSLATION_MAP_HISTORY = 'translation_map/history/get',
  SEARCH_TRANSLATION_MAP = 'translation_map/search',
  SEARCH_LOADERS = 'loader/search',
  SEARCH_ENCODINGS = 'encoding/search',
  SEARCH_CONTENT_TYPES = 'content_type/search',
  SEARCH_TRANSLATION_TEST_CASES = 'translation_map/test_case/search',
}

export interface useGetTranslationMapParams {
  id: string
}

export interface useGetTranslationTestCaseParams {
  translationMapId: string
  translationTestCaseId: string
}

export interface useSearchTranslationMapsParams {
  page?: number
  name?: string
  loader?: TranslationMapLoader
  direction: MessageDirection
}

export interface useUpdateImplementationParams {
  id: string
  encoded_implementation?: string
}

export interface useSearchTranslationTestCasesParams extends PaginatedRequest {
  translationMapId: string
}

export interface useCreateTranslationTestCaseParams {
  translationMapId: string
  translationTestCase: TranslationTestCaseDetail
}

export interface useUpdateTranslationTestCaseParams {
  translationMapId: string
  translationTestCase: TranslationTestCaseDetail
}

export interface useDeleteTranslationTestCaseParams {
  translationMapId: string
  translationTestCaseId: string
}

export interface useRunTranslationTestCaseParams {
  translationMapId: string
  translationTestCaseId: string
}

export interface useCloneTranslationTestCaseParams {
  translationMapId: string
  translationTestCaseId: string
}

export type ImportStep = 'upload-step' | 'verify-step' | 'finish-step'

export type ImportSteps = {
  complete: boolean
  id: ImportStep
  label: string
}[]

export interface ImportModalProps {
  onClose: () => void
  open: boolean
  mode: 'import' | 'create' | null
}

export interface DevelopmentTranslationTestCase {
  testCaseDetail?: TranslationTestCase
  isRunning: boolean
  execution?: TranslationTestCaseExecutionResult
}
