/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import { INodeInnerDefaultProps } from '@mrblenny/react-flow-chart'
import { RouteProcessorNormalized } from 'common/Route.helpers'

export enum QUERIES {
  SEARCH_ROUTES = 'routes/search',
  GET_ROUTE = 'routes/get',
  GET_ROUTE_HISTORY = 'routes_history/get',
}

export interface useSearchRoutesParams {
  page?: number
  code?: string
}

export interface useGetRouteParams {
  id: string
}

export type HandleOpenModal = ({ node }: Pick<INodeInnerDefaultProps, 'node'>) => void

export interface StepModalProps {
  open: boolean
  handleClose: () => void
  step: {
    name: string
    processors: RouteProcessorNormalized[]
  }
}
