import { IconButton, Layout, Tag, Text, Switch } from '@loadsmart/loadsmart-ui'
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer } from '@mui/material'
import DirectionTag from 'atoms/DirectionTag/DirectionTag'
import Icon from 'atoms/Icon'
import { formatGatewayType } from 'common/helpers/kraken-core/gatewaySettings.helper'
import { GatewaySetting } from 'common/types/kraken-core/GatewaySettings'
import isEmpty from 'lodash.isempty'
import { Link } from 'react-router-dom'

export interface GatewaySettingsTableProps {
  data: Array<GatewaySetting>
  onRemove: (gateway: GatewaySetting) => void
  onDisable: (gateway: GatewaySetting) => void
}

const GatewaySettingsTable = ({ data, onRemove, onDisable }: GatewaySettingsTableProps) => {
  const COLUMNS = [
    {
      Header: '#',
      style: {
        width: '5%',
      },
      Cell(row: GatewaySetting) {
        const idAsString = row.id?.slice(0, 8) || '-'

        return (
          <Tag variant="default">
            <Link to={`gateways/${row.id}`}>{idAsString}</Link>
          </Tag>
        )
      },
    },
    {
      Header: 'Direction',
      style: {
        width: '5%',
      },
      Cell(row: GatewaySetting) {
        return <DirectionTag direction={row.direction} size="default" />
      },
    },
    {
      Header: 'Protocol',
      style: {
        width: '10%',
      },
      Cell(row: GatewaySetting) {
        if (!row.protocol || isEmpty(row.protocol)) {
          return null
        }
        const delimiterCharacterAmount = 15
        const formattedProtocol =
          row.protocol.length > delimiterCharacterAmount
            ? row.protocol?.slice(0, delimiterCharacterAmount).concat('...')
            : row.protocol
        return <Tag size="default">{formattedProtocol.toUpperCase()}</Tag>
      },
    },
    {
      Header: 'Type',
      style: {
        width: '10%',
      },
      Cell(row: GatewaySetting) {
        return formatGatewayType(row.gateway_type)
      },
    },
    {
      Header: 'Name',
      style: {
        width: '30%',
      },
      Cell(row: GatewaySetting) {
        return row.name
      },
    },
    {
      Header: 'Use When',
      style: {
        width: '30%',
      },
      Cell(row: GatewaySetting) {
        return (
          <Layout.Stack space="none">
            {row.use_when && row.use_when instanceof Object
              ? Object.keys(row.use_when).map((key: string) => {
                  const value = row.use_when[key]
                  return (
                    <Layout.Group space="s" key={key}>
                      <Text variant="caption-bold">{key}</Text>
                      <Text variant="caption">{String(value)}</Text>
                    </Layout.Group>
                  )
                })
              : null}
          </Layout.Stack>
        )
      },
    },
    {
      Header: 'Active',
      style: {
        width: '5%',
        alignment: 'left',
      },
      Cell(row: GatewaySetting) {
        return (
          <Switch
            active={row.is_active}
            onToggle={() => {
              onDisable(row)
            }}
          />
        )
      },
    },
    {
      Header: 'Actions',
      style: {
        width: '1%',
        alignment: 'right',
      },
      Cell(row: GatewaySetting) {
        return (
          <IconButton
            scale="small"
            onClick={e => {
              onRemove(row)
              e.stopPropagation()
            }}
          >
            <Icon name="remove" />
          </IconButton>
        )
      },
    },
  ]

  return (
    <Layout.Stack>
      <TableContainer>
        <Table size="small" padding="normal" data-testid="gateway-settings-list">
          <TableHead>
            <TableRow key={'head'}>
              {COLUMNS.map(column => (
                <TableCell
                  key={column.Header}
                  width={column.style.width}
                  align={(column.style.alignment as any) || 'left'}
                >
                  <Text variant="button-sm">{column.Header}</Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map(row => (
              <TableRow hover tabIndex={-1} key={row.id}>
                {COLUMNS.map(cell => {
                  return (
                    <TableCell
                      key={`${cell.Header}.${row.id}`}
                      width={cell.style.width}
                      align={(cell.style.alignment as any) || 'left'}
                    >
                      {cell.Cell(row)}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout.Stack>
  )
}

export default GatewaySettingsTable
