const httpSchema = {
  title: 'HTTP Client Settings',
  type: 'object',
  properties: {
    url: {
      title: 'URL (root)',
      minLength: 1,
      maxLength: 2083,
      format: 'uri',
      type: 'string',
    },
    test: {
      title: 'Test Settings',
      allOf: [
        {
          $ref: '#/definitions/TestHttpSchema',
        },
      ],
    },
    'HTTP-Authentication': {
      title: 'Authentication',
      allOf: [
        {
          $ref: '#/definitions/HttpAuthenticationSchema',
        },
      ],
    },
    override: {
      title: 'Override Defaults',
      allOf: [
        {
          $ref: '#/definitions/HttpOverrideSchema',
        },
      ],
    },
    request_retry: {
      title: 'Request Retry Settings',
      allOf: [
        {
          $ref: '#/definitions/RequestRetrySchema',
        },
      ],
    },
    remote_methods: {
      title: 'Remote Methods',
      type: 'array',
      items: {
        $ref: '#/definitions/HTTPRemoteMethod',
      },
    },
  },
  required: ['url', 'test', 'HTTP-Authentication'],
  definitions: {
    HTTPHeadersSchema: {
      title: 'Header',
      type: 'object',
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        value: {
          title: 'Value',
          type: 'string',
        },
      },
    },
    HTTPQueryParamSchema: {
      title: 'Query Param',
      type: 'object',
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        value: {
          title: 'Value',
          type: 'string',
        },
      },
    },
    HTTPStatus: {
      title: 'HTTPStatus',
      description: 'An enumeration.',
      enum: [
        100,
        101,
        200,
        201,
        202,
        203,
        204,
        205,
        206,
        207,
        208,
        226,
        300,
        301,
        302,
        303,
        304,
        305,
        306,
        307,
        308,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        422,
        423,
        424,
        426,
        428,
        429,
        431,
        451,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
      ],
      type: 'integer',
    },
    HTTPMethods: {
      title: 'HTTPMethods',
      description: 'An enumeration.',
      enum: ['HEAD', 'GET', 'POST', 'PUT', 'DELETE', 'OPTIONS', 'TRACE'],
      type: 'string',
    },
    RequestRetrySchema: {
      title: 'Request Retry Settings',
      type: 'object',
      properties: {
        max_retries: {
          title: 'Max Retries',
          type: 'integer',
        },
        retry_backoff: {
          title: 'Retry Backoff',
          type: 'integer',
        },
        timeout: {
          title: 'Request Timeout',
          type: 'integer',
        },
        status_forcelist: {
          title: 'Status Force List',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPStatus',
          },
        },
        allowed_methods: {
          title: 'Allowed Methods List',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPMethods',
          },
        },
      },
    },
    HTTPDynamicPayloadBodyRenderExpressionFrom: {
      title: 'Expression From',
      type: 'object',
      properties: {
        template_path: {
          title: 'Expression from path',
          type: 'string',
        },
        static_file_code: {
          title: 'Static file code',
          type: 'string',
        },
        static_file_id: {
          title: 'Static file ID',
          type: 'string',
        },
      },
    },
    HTTPDynamicPayloadBodyRenderDefinition: {
      title: 'HTTPDynamicPayloadBodyRenderDefinition',
      type: 'object',
      properties: {
        static_expression: {
          title: 'Static expression',
          description: 'Expression to be applied as the request body',
          type: 'string',
        },
        expression_from: {
          title: 'Expression From',
          allOf: [
            {
              $ref: '#/definitions/HTTPDynamicPayloadBodyRenderExpressionFrom',
            },
          ],
        },
      },
    },
    HTTPRemoteMethodRequestPayload: {
      title: 'HTTP Remote Method Request Payload',
      type: 'object',
      properties: {
        body: {
          $ref: '#/definitions/HTTPDynamicPayloadBodyRenderDefinition',
        },
      },
      required: ['body'],
    },
    HTTPRemoteMethodRequest: {
      title: 'Remote Method Request',
      type: 'object',
      properties: {
        'HTTP-Verb': {
          title: 'Verb',
          enum: ['http:delete', 'http:get', 'http:options', 'http:post', 'http:put', 'http:patch'],
          options: {
            enum_titles: ['DELETE', 'GET', 'OPTIONS', 'POST', 'PUT', 'PATCH'],
          },
          type: 'string',
        },
        path: {
          title: 'URL Path',
          type: 'string',
        },
        header_params: {
          title: 'Header Params',
          description: 'HTTP headers parameters to be used on the request',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPHeadersSchema',
          },
        },
        query_params: {
          title: 'Query Params',
          description: 'HTTP query parameters to be used on the request',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPQueryParamSchema',
          },
        },
        request_retry: {
          title: 'Request Retry Settings',
          description: 'How the request must behave when retrying to be made',
          allOf: [
            {
              $ref: '#/definitions/RequestRetrySchema',
            },
          ],
        },
        payload: {
          title: 'HTTP Remote Method Request Payload',
          allOf: [
            {
              $ref: '#/definitions/HTTPRemoteMethodRequestPayload',
            },
          ],
        },
      },
      required: ['HTTP-Verb', 'path'],
    },
    HTTPRemoteMethodResponseReceipt: {
      title: 'Response Receipt',
      type: 'object',
      properties: {
        key: {
          title: 'Receipt Key',
          type: 'string',
        },
      },
      required: ['key'],
    },
    HTTPWhen: {
      title: 'When',
      type: 'object',
      properties: {
        status_code: {
          title: 'HTTP Status Code',
          allOf: [
            {
              $ref: '#/definitions/HTTPStatus',
            },
          ],
        },
        expression: {
          title: 'Expression to be evaluated',
          description:
            'Will be evaluated as boolean, response and rule are available in the context',
          type: 'string',
        },
        text: {
          title: 'HTTP Response Text Matches (REGEX)',
          type: 'string',
        },
      },
      required: ['status_code', 'text'],
    },
    HTTPThen: {
      title: 'Then Replace With',
      type: 'object',
      properties: {
        status_code: {
          title: 'HTTP Status Code',
          allOf: [
            {
              $ref: '#/definitions/HTTPStatus',
            },
          ],
        },
      },
      required: ['status_code'],
    },
    HTTPReplacementSchema: {
      title: 'HTTPReplacementSchema',
      type: 'object',
      properties: {
        when: {
          title: 'When',
          allOf: [
            {
              $ref: '#/definitions/HTTPWhen',
            },
          ],
        },
        then: {
          title: 'Then Replace With',
          allOf: [
            {
              $ref: '#/definitions/HTTPThen',
            },
          ],
        },
      },
      required: ['when', 'then'],
    },
    MethodResponseReplacement: {
      title: 'HTTP Replaced Responses',
      type: 'object',
      properties: {
        rules: {
          title: 'Method Replacement Rules',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
      },
      required: ['rules'],
    },
    HTTPRemoteMethodResponse: {
      title: 'Remote Method Response',
      type: 'object',
      properties: {
        receipt: {
          title: 'Response Receipt',
          allOf: [
            {
              $ref: '#/definitions/HTTPRemoteMethodResponseReceipt',
            },
          ],
        },
        replacement: {
          title: 'Reponse Replacement',
          allOf: [
            {
              $ref: '#/definitions/MethodResponseReplacement',
            },
          ],
        },
      },
      required: ['receipt'],
    },
    MetricsSettings: {
      title: 'Settings',
      type: 'object',
      properties: {
        backend: {
          title: 'Backend',
          default: 'StatsDBackend',
          enum: ['StatsDBackend'],
          type: 'string',
        },
        name: {
          title: 'Name',
          type: 'string',
        },
        prefix: {
          title: 'Prefix',
          type: 'string',
        },
        tags: {
          title: 'Tags',
          type: 'object',
        },
      },
      required: ['name', 'prefix'],
    },
    HTTPRemoteMethod: {
      title: 'Remote Method',
      type: 'object',
      properties: {
        key: {
          title: 'Key',
          description: 'Unique identification for this remote method',
          type: 'string',
        },
        description: {
          title: 'Description',
          description: 'What is the purpose of this remote method?',
          type: 'string',
        },
        request: {
          title: 'Remote Method Request',
          description: 'To define how the HTTP request will be made',
          allOf: [
            {
              $ref: '#/definitions/HTTPRemoteMethodRequest',
            },
          ],
        },
        response: {
          title: 'Remote Method Response',
          description: 'To define how the HTTP response will be processed',
          allOf: [
            {
              $ref: '#/definitions/HTTPRemoteMethodResponse',
            },
          ],
        },
        metric: {
          title: 'Dynamic Metric Settings',
          allOf: [
            {
              $ref: '#/definitions/MetricsSettings',
            },
          ],
        },
      },
      required: ['key', 'description', 'request', 'response'],
    },
    TestHttpRemoteMethod: {
      title: 'Test HTTP Remote Method',
      type: 'object',
      properties: {
        key: {
          title: 'Remote Method Key',
          type: 'string',
        },
        definition: {
          title: 'Remote Method',
          allOf: [
            {
              $ref: '#/definitions/HTTPRemoteMethod',
            },
          ],
        },
      },
    },
    TestHttpSchema: {
      title: 'Test Settings',
      type: 'object',
      properties: {
        method: {
          title: 'Tester Method',
          default: 'http:connector:tester:verb',
          enum: [
            'http:connector:tester:inline-remote-method',
            'http:connector:tester:remote-method-by-reference',
            'http:connector:tester:verb',
          ],
          options: {
            enum_titles: ['REMOTE_METHOD_INLINE', 'REMOTE_METHOD_REFERENCE', 'VERB'],
          },
          type: 'string',
        },
        'HTTP-Verb': {
          title: 'Verb',
          enum: ['http:delete', 'http:get', 'http:options', 'http:post', 'http:put', 'http:patch'],
          options: {
            enum_titles: ['DELETE', 'GET', 'OPTIONS', 'POST', 'PUT', 'PATCH'],
          },
          type: 'string',
        },
        'HTTP-Endpoint': {
          title: 'Endpoint',
          type: 'string',
        },
        remote_method: {
          title: 'Test HTTP Remote Method',
          allOf: [
            {
              $ref: '#/definitions/TestHttpRemoteMethod',
            },
          ],
        },
      },
    },
    CredentialStorage: {
      title: 'CredentialStorage',
      description: 'An enumeration.',
      enum: ['local', 'vault'],
      type: 'string',
    },
    HTTPBasicSchema: {
      title: 'HTTP Basic Auth',
      type: 'object',
      properties: {
        username: {
          title: 'Username',
          type: 'string',
        },
        password: {
          title: 'Password',
          type: 'string',
        },
        storage: {
          title: 'Storage Type',
          default: 'local',
          allOf: [
            {
              $ref: '#/definitions/CredentialStorage',
            },
          ],
        },
      },
    },
    HTTPAuthenticationBasicSchema: {
      title: 'Basic',
      type: 'object',
      properties: {
        repository: {
          title: 'Repository',
          enum: ['inline', 'vault', 'per-partner'],
          type: 'string',
        },
        inline: {
          title: 'Inline',
          tite: 'HTTP Basic Auth',
          allOf: [
            {
              $ref: '#/definitions/HTTPBasicSchema',
            },
          ],
        },
        vault: {
          title: 'Vault',
          tite: 'HTTP Basic Auth',
          allOf: [
            {
              $ref: '#/definitions/HTTPBasicSchema',
            },
          ],
        },
      },
    },
    HTTPExtraSchema: {
      title: 'Extra',
      type: 'object',
      properties: {},
    },
    HTTPClaimsSchema: {
      title: 'Claims',
      type: 'object',
      properties: {
        iss: {
          title: 'ISS',
          type: 'string',
        },
        extra: {
          title: 'Extra',
          allOf: [
            {
              $ref: '#/definitions/HTTPExtraSchema',
            },
          ],
        },
      },
    },
    HTTPAuthroizationHeaderSchema: {
      title: 'Authorization Header',
      type: 'object',
      properties: {
        name: {
          title: 'Name',
          default: 'Authorization',
          type: 'string',
        },
        value: {
          title: 'Value',
          type: 'string',
        },
        prefix: {
          title: 'Prefix',
          type: 'string',
        },
      },
    },
    HTTPJWTSchema: {
      title: 'HTTPJWTSchema',
      type: 'object',
      properties: {
        algorithm: {
          title: 'Algorithm',
          enum: ['RS256'],
          type: 'string',
        },
        value: {
          title: 'Value',
          options: {
            inputAttributes: {
              input_height: '200px',
            },
          },
          type: 'string',
        },
      },
    },
    HTTPAuthenticationJWTSchema: {
      title: 'JWT',
      type: 'object',
      properties: {
        claims: {
          title: 'Claims',
          allOf: [
            {
              $ref: '#/definitions/HTTPClaimsSchema',
            },
          ],
        },
        repository: {
          title: 'Repository',
          enum: ['settings', 'inline', 'partner:jwt:public-key', 'vault'],
          type: 'string',
        },
        headers: {
          title: 'Authorization Header',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthroizationHeaderSchema',
            },
          ],
        },
        inline: {
          title: 'Inline',
          allOf: [
            {
              $ref: '#/definitions/HTTPJWTSchema',
            },
          ],
        },
        vault: {
          title: 'vault',
          allOf: [
            {
              $ref: '#/definitions/HTTPJWTSchema',
            },
          ],
        },
      },
      required: ['claims'],
    },
    HTTPAuthenticationTokenSchema: {
      title: 'Token',
      type: 'object',
      properties: {
        repository: {
          title: 'Repository',
          default: 'bear-token-per-partner',
          type: 'string',
        },
        keyword: {
          title: 'Keyword',
          default: 'token',
          type: 'string',
        },
      },
    },
    TokenFromEndpointAuthenticationCredentialsSchema: {
      title: 'Credentials',
      type: 'object',
      properties: {
        'http:auth:basic': {
          title: 'Basic',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthenticationBasicSchema',
            },
          ],
        },
      },
    },
    TokenFromEndpointAuthenticationRequestSchema: {
      title: 'Request',
      type: 'object',
      properties: {
        'HTTP-Verb': {
          title: 'Verb',
          default: 'http:post',
          enum: ['http:delete', 'http:get', 'http:options', 'http:post', 'http:put', 'http:patch'],
          options: {
            enum_titles: ['DELETE', 'GET', 'OPTIONS', 'POST', 'PUT', 'PATCH'],
          },
          type: 'string',
        },
        endpoint: {
          title: 'Endpoint',
          type: 'string',
        },
        headers: {
          title: 'Headers',
          description: 'HTTP headers to be used on the authentication request',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPHeadersSchema',
          },
        },
        data: {
          title: 'Data (Expression)',
          description: "The body used on the authentication request. Use '{}' when empty body",
          default: '',
          options: {
            inputAttributes: {
              input_height: '200px',
            },
          },
          type: 'string',
        },
        use_authorization_header_with_b64_authentication: {
          title: 'Add Basic Authorization Header',
          description: 'It will add the header with Authorization: Basic <encoded b64>',
          type: 'boolean',
        },
      },
      required: ['endpoint'],
    },
    TokenFromEndpointAuthenticationResponseSchema: {
      title: 'Response',
      type: 'object',
      properties: {
        data: {
          title: 'Data (Expression)',
          default: '{{ response.json().access_token }}',
          type: 'string',
        },
      },
    },
    TokenFromEndpointAuthenticationSessionCacheSchema: {
      title: 'Session Cache Settings',
      type: 'object',
      properties: {
        token_expiration: {
          title: 'Token Expiration',
          description:
            'Total amount of time in seconds the token will be valid to be used before expire',
          type: 'integer',
        },
        token_expires_at: {
          title: 'Token Expires At',
          description:
            'An expression that will be dynamically evaluated when checking if token still valid',
          type: 'string',
        },
      },
    },
    TokenFromEndpointAuthenticationSessionSchema: {
      title: 'Session Configuration',
      type: 'object',
      properties: {
        header: {
          title: 'Authorization Header',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthroizationHeaderSchema',
            },
          ],
        },
        cache: {
          title: 'Cache Settings',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationSessionCacheSchema',
            },
          ],
        },
        must_inherit_request_headers: {
          title: 'Must Inherit Request Headers',
          description: 'Will inherit the request headers used in the authentication request',
          type: 'boolean',
        },
        inherit_headers_from_token_endpoint_request: {
          title: 'Inherit Headers from token endpoint request',
          description:
            'Define which headers (using the header name) will be ' +
            'inherited from the previous request made to the token endpoint',
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
    TokenFromEndpointAuthenticationSchema: {
      title: 'Token From Endpoint',
      type: 'object',
      properties: {
        credentials: {
          title: 'Credentials',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationCredentialsSchema',
            },
          ],
        },
        request: {
          title: 'Request',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationRequestSchema',
            },
          ],
        },
        response: {
          title: 'Response',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationResponseSchema',
            },
          ],
        },
        session: {
          title: 'Session Configuration',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationSessionSchema',
            },
          ],
        },
      },
      required: ['credentials', 'request', 'response', 'session'],
    },
    HTTPCustomHeadersSchema: {
      title: 'Header',
      type: 'object',
      properties: {
        key: {
          title: 'Key',
          type: 'string',
        },
        value: {
          title: 'Value',
          type: 'string',
        },
      },
    },
    HTTPCustomSchema: {
      title: 'HTTP Custom Auth',
      type: 'object',
      properties: {
        headers: {
          title: 'Headers',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPCustomHeadersSchema',
          },
        },
      },
    },
    CustomAuthenticationSchema: {
      title: 'Custom',
      type: 'object',
      properties: {
        repository: {
          title: 'Repository',
          enum: ['inline', 'vault'],
          type: 'string',
        },
        inline: {
          title: 'Inline',
          tite: 'HTTP Custom Auth',
          allOf: [
            {
              $ref: '#/definitions/HTTPCustomSchema',
            },
          ],
        },
        vault: {
          title: 'Vault',
          tite: 'HTTP Custom Auth',
          allOf: [
            {
              $ref: '#/definitions/HTTPCustomSchema',
            },
          ],
        },
      },
    },
    HttpAuthenticationSchema: {
      title: 'Authentication',
      type: 'object',
      properties: {
        method: {
          title: 'Method',
          enum: [
            'http:auth:basic',
            'http:auth:custom',
            'http:auth:jwt',
            'http:auth:none',
            'http:otm:auth:custom',
            'http:auth:token',
            'http:auth:token-from-url',
          ],
          options: {
            enum_titles: [
              'BASIC',
              'CUSTOM',
              'JWT',
              'NONE',
              'OTM_AUTH',
              'TOKEN',
              'TOKEN_FROM_ENDPOINT',
            ],
          },
          type: 'string',
        },
        'http:auth:basic': {
          title: 'Basic',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthenticationBasicSchema',
            },
          ],
        },
        'http:auth:jwt': {
          title: 'JWT',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthenticationJWTSchema',
            },
          ],
        },
        'http:auth:token': {
          title: 'Token',
          allOf: [
            {
              $ref: '#/definitions/HTTPAuthenticationTokenSchema',
            },
          ],
        },
        'http:auth:token-from-url': {
          title: 'Token From Endpoint',
          allOf: [
            {
              $ref: '#/definitions/TokenFromEndpointAuthenticationSchema',
            },
          ],
        },
        'http:auth:custom': {
          title: 'Custom',
          allOf: [
            {
              $ref: '#/definitions/CustomAuthenticationSchema',
            },
          ],
        },
      },
    },
    HttpOverrideSchema: {
      title: 'Override Defaults',
      type: 'object',
      properties: {
        response_replacements: {
          title: 'HTTP Replaced Responses',
          allOf: [
            {
              $ref: '#/definitions/MethodResponseReplacement',
            },
          ],
        },
      },
    },
  },
}

export default httpSchema
