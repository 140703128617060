import { Layout, Tag } from '@loadsmart/loadsmart-ui'
import { useEffect, useState } from 'react'
import { CustomAutocomplete, StyledDiv, StyledInput } from './TagSelector.style'
import { Tooltip } from '@mui/material'

export interface TagSelectorValueWrapper {
  value: string
  description?: string
}
export interface TagSelectorProps {
  values?: Array<string>
  options?: Array<TagSelectorValueWrapper>
  onChange?: (values: Array<string>) => void
  multiple?: boolean
}

export default function TagSelector(props: TagSelectorProps) {
  const [doesShowOptions, showOptions] = useState<boolean>(false)
  const [selectedValues, setSelectedValues] = useState(props.values || [])
  const options = new Set(
    [
      ...selectedValues.sort((a, b) => a.localeCompare(b)),
      ...(props.options?.sort((a, b) => a.value.localeCompare(b.value)) || []).map(
        tag => tag.value
      ),
    ] || []
  )

  const handleAutocompleteChange = (event: any, value: any) => {
    setSelectedValues(value)
    if (props.onChange) props.onChange(value)
  }

  const getTagValueDescription = (value: string) => {
    if (props.options) {
      return props.options.find(st => st.value === value)?.description || 'No available description'
    }
  }

  useEffect(() => {
    setSelectedValues(props.values || [])
  }, [props])

  return (
    <StyledDiv>
      <CustomAutocomplete
        data-testid="tag-selector"
        multiple
        freeSolo
        open={doesShowOptions}
        options={Array.from(options)}
        value={selectedValues}
        onChange={handleAutocompleteChange}
        onFocus={() => showOptions(true)}
        onBlur={() => showOptions(false)}
        renderInput={params => (
          <StyledInput {...params} variant="outlined" label="Select or enter values" />
        )}
        renderTags={(value, getTagProps) => {
          return (
            <Layout.Group space="none" align="center" className="pl-2">
              {!doesShowOptions &&
                selectedValues.slice(0, 2).map((option, index) => (
                  <Tag
                    {...getTagProps({ index })}
                    key={String(index)}
                    variant="accent"
                    removable
                    onRemove={() => {
                      getTagProps({ index }).onDelete(option)
                    }}
                  >
                    {String(option)}
                  </Tag>
                ))}
              {!doesShowOptions && selectedValues.length > 3 ? (
                <Tag variant="accent">+{selectedValues.length - 3}</Tag>
              ) : null}

              {selectedValues.length > 0 && doesShowOptions ? (
                <Tag variant="accent">{selectedValues.length}</Tag>
              ) : null}
            </Layout.Group>
          )
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <Tooltip title={getTagValueDescription(String(option))}>
              <div>
                <Tag variant="accent">{String(option)}</Tag>
              </div>
            </Tooltip>
          </li>
        )}
      />
    </StyledDiv>
  )
}
