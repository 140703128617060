import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get, post, del, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import {
  ConnectionProtocol,
  ConnectionSetting,
  ConnectionSettingHistory,
  ConnectionSettingsSchema,
  ConnectionSettingsTestResult,
} from 'common/types/kraken-core/ConnectionSettings'

export interface SearchConnectionSettingsRequest {
  page?: number
  page_size?: number
}

export interface SearchConnectionSettingsPayload {
  next?: string
  previous?: string
  results: ConnectionSetting[]
}

export async function searchConnectionSettings(
  data?: SearchConnectionSettingsRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchConnectionSettingsPayload>> {
  const params: any = {
    ...data,
    page: data?.page || 1,
    page_size: data?.page_size || 100,
  }

  const [error, response] = await to(get('/connection/settings', { ...config, params }))

  return { error, response }
}

export async function getConnectionSettings(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSetting>> {
  const [error, response] = await to(get(`/connection/settings/${id}`, { ...config }))

  return { error, response }
}

export async function exportConnectionSettings(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSetting>> {
  const [error, response] = await to(post(`/connection/settings/${id}/export`, { ...config }))

  return { error, response }
}

export async function openCircuitBreaker(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSetting>> {
  const [error, response] = await to(post(`/connection/settings/${id}/circuit_open`, { ...config }))

  return { error, response }
}

export async function closeCircuitBreaker(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSetting>> {
  const [error, response] = await to(
    post(`/connection/settings/${id}/circuit_close`, { ...config })
  )

  return { error, response }
}

export async function importConnectionSettings(
  params: ConnectionSetting,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSetting>> {
  const [error, response] = await to(post('/connection/import', params, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function searchProtocols(
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionProtocol[]>> {
  const [error, response] = await to(get('/connection/protocols', { ...config }))

  return { error, response }
}

export async function getConnectionSettingsHistory(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSettingHistory>> {
  const [error, response] = await to(get(`/connection/settings/${id}/history`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function revertHistory(
  params: ConnectionSettingHistory,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSettingHistory>> {
  const [error, response] = await to(
    post(`/connection/settings/${params.id}/history/${params.history_id}/revert`, {}, { ...config })
  )

  if (error) throw error

  return { error, response }
}

export async function deleteConnectionSetting(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSetting>> {
  const [error, response] = await to(del(`/connection/settings/${id}`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function updateConnectionSetting(
  params: ConnectionSetting,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSetting>> {
  const [error, response] = await to(
    put(`/connection/settings/${params.id}`, params, { ...config })
  )

  if (error) throw error

  return { error, response }
}

export async function testConnectionSetting(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSettingsTestResult>> {
  const [error, response] = await to(post(`/connection/settings/${id}/test`, {}, { ...config }))
  if (error) throw error
  return { error, response }
}

export async function getSchemaByProtocol(
  protocol: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConnectionSettingsSchema>> {
  const [error, response] = await to(get(`/connection/protocols/${protocol}`, { ...config }))
  if (error) throw error
  return { error, response }
}
