import { Button, Layout, Switch, Tag, Text } from '@loadsmart/loadsmart-ui'
import isJson from 'common/helpers/isJSON'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import FileViewer from 'molecules/FileViewer/FileViewer'
import JsonForm from 'molecules/JsonForm/JsonForm'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useUpdateComponentSetting } from '../../api'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import schema from './schema.json'
import isEmpty from 'lodash.isempty'

export interface ComponentSettingsProps {
  tradingPartner: TradingPartner
}

export default function ComponentSettings(props: ComponentSettingsProps) {
  const [rawView, setRawView] = useState<boolean>(false)
  const componentSettingsRef = useRef<any>({})

  const { mutate, isSuccess, isError, error } = useUpdateComponentSetting()

  useEffect(() => {
    componentSettingsRef.current = props.tradingPartner.component_settings || {}
  }, [props.tradingPartner])

  const handleOnChange = (changed: any) => {
    componentSettingsRef.current = { ...changed }
  }

  const handleOnSave = async () => {
    if (!props.tradingPartner.id) {
      toast.error('There is no trading partner selected')
      return
    }

    mutate({
      componentSettings: componentSettingsRef.current,
      tradingPartnerId: props.tradingPartner.id,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Successfully saved')
      return
    }

    if (isError) {
      toast.error(`Somwthing went wrong: ${error}`)
      return
    }
  }, [isSuccess, isError])

  return (
    <Layout.Stack space="m" className="p-4">
      <Layout.Group justify="space-between">
        <Layout.Group align="center">
          <Text variant="caption-bold">Raw view?</Text>
          <Switch active={rawView} onToggle={() => setRawView(c => !c)} />
        </Layout.Group>

        <Button variant="primary" onClick={handleOnSave}>
          Save
        </Button>
      </Layout.Group>
      {rawView ? (
        <FileViewer
          content={JSON.stringify(componentSettingsRef.current, null, 2)}
          contentType="application/json"
          options={{
            heigth: 600,
          }}
          onChange={changed => {
            if (isJson(changed)) handleOnChange(JSON.parse(changed))
          }}
        />
      ) : (
        <div>
          {Object.keys(schema.properties).map((key, index) => {
            const componentTitle = ((schema.properties as any)[key] as any).title
            const componentSchema = (schema.properties as any)[key]
            const componentData = componentSettingsRef.current[key]
            return (
              <Accordion key={key}>
                <AccordionSummary>
                  <Layout.Group align="center">
                    <Text>{componentTitle}</Text>
                    {!isEmpty(componentData) ? <Tag variant="accent">Has definition</Tag> : null}
                  </Layout.Group>
                </AccordionSummary>
                <AccordionDetails>
                  <JsonForm
                    data={componentData}
                    schema={componentSchema}
                    onChange={changed => {
                      if (isEmpty(changed.data)) return
                      handleOnChange({
                        ...componentSettingsRef.current,
                        [key]: changed.data,
                      })
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      )}
    </Layout.Stack>
  )
}
