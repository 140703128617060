export interface ExpectedReplyMessage {
  id: string
  due: string
  meta: Record<string, string>
  status: string
  reply?: string
  request?: string
}

export interface MessageList {
  id?: string
  status?: string
  party?: {
    id: string
    name: string
  }
  counterparty?: {
    id: string
    name: string
  }
  direction: MessageDirection
  transaction_type: TransactionType
  party_reference: string
  counterparty_reference: string
  customer_ref: string
  delivered: boolean
  ls_ref: string
  created_at: string
  updated_at: string
}

export interface MessageRead {
  id: string
  status?: string
  status_tags?: Array<string>
  transaction_type: TransactionType
  party_reference: string
  counterparty_reference: string
  party: {
    id: string
    name: string
  }
  counterparty: {
    id: string
    name: string
  }
  delivered: string
  state: string
  expectations_as_request: Array<ExpectedReplyMessage>
  expectations_as_reply: Array<ExpectedReplyMessage>
  created_at: string
  updated_at: string
  deleted_at: string
  correlation_code: string
  body: string
  headers: Record<string, string>
  content_type: string
  direction: MessageDirection
  partnership: number
}

export interface MessageHistoryRead {
  id: string
  status?: string
  status_tags?: Array<string>
  correlation_code: string
  created_at: string
  origin_id: string
  source_id: string
  target_id: string
  partnership_id?: string
  headers: Record<string, string>
  body: string
  storage_body_url: string
}

export interface MessageLogRead {
  log: string
  created_at: string
  log_level: string
}

export interface MessageReceiptRead {
  created_at: string
  receipt_type: string
  status_code: string
  body_url: string
  headers: Record<string, string>
}

export interface MessageFailureRead {
  id: string
  created_at: string
  updated_at: string
  fixed: boolean
  last_step: string
  exception: string
  problem?: string
}

export enum MessageDirection {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

export enum MessageLogLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum MessageHeaders {
  TRANSACTION_TYPE = 'Transaction-Type',
  PRE_TRANSLATION_CONTENT_TYPE = 'Pre-Translation-Content-Type',
  CONTENT_TYPE = 'Content-Type',
  DELIVERED = 'Delivered',
  DIRECTION = 'Direction',
  IGNORED = 'Ignored',
  LAST_STEP = 'Last-Step',
  ROUTE_CODE = 'Route-Code',
  TRANSLATION_MODEL_VERSION = 'Translation-Model-Version',
  TRANSLATION_MAP = 'Translation-Map',
  VALIDATION_ERRORS = 'Validation-Errors',
  CHILDREN_UUIDS = 'Children-UUIDs',
  CHILDREN_COUNT = 'Children-Count',
  PROCESSOR = 'Processor',
}

export interface TransactionType {
  id: string
  key: string
  description: string
}

export interface MessageStatus {
  status: string
  description: string
}

export interface MessageStatusTag {
  tag: string
  description: string
}

export interface Installation {
  id: string
  name: string
}

export interface IntegrationModule {
  id: string
  name: string
  code: string
}
