import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form'
import {
  Layout,
  Tabs,
  Textarea,
  Text,
  Button,
  TextField,
  Switch,
  ToggleGroup,
} from '@loadsmart/loadsmart-ui'
import Icon from 'atoms/Icon'
import { toast } from 'atoms/Toast'
import { TradingPartnerInstructions } from 'common/types/kraken-core/TradingPartner'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import { InstructionsProps } from '../types'
import { useUpdateTradingPartnerInstructions } from '../api'

const InstructionsTab = ({ instructions }: InstructionsProps) => {
  const { id: tradingParnerId } = useParams<{ id?: string }>()
  const { register, handleSubmit, control } = useForm<TradingPartnerInstructions>({
    defaultValues: instructions,
  })
  const { fields, append, remove } = useFieldArray<TradingPartnerInstructions>({
    control,
    name: 'extra_notes',
  })
  const {
    mutate: updateInstructions,
    error: mutateError,
    isLoading: mutationIsLoading,
    isSuccess,
    reset,
  } = useUpdateTradingPartnerInstructions()

  useEffect(() => {
    if (mutateError) {
      toast.error(`Something wrong when updating instructions: ${mutateError}`)
      reset()
    }
  }, [mutateError])

  useEffect(() => {
    if (isSuccess) {
      toast.success('Instructions sucessfully updated')
      reset()
    }
  }, [isSuccess])

  const addNewSegment = () => {
    append({
      segment: {
        value: '',
        element_index: 0,
      },
      qualifier: {
        value: '',
        index: 0,
      },
    })
  }

  const removeSegment = (index: number) => {
    remove(index)
  }
  const onSubmit: SubmitHandler<TradingPartnerInstructions> = data => {
    if (!tradingParnerId) return

    updateInstructions({
      id: tradingParnerId,
      instructions: data,
    })
  }

  return (
    <>
      <Tabs className="flex flex-col mt-4" direction="horizontal">
        <Tabs.Items>
          <Tabs.Item default name="general-notes">
            General notes
          </Tabs.Item>

          <Tabs.Item name="pickup-notes">Pickup notes</Tabs.Item>

          <Tabs.Item name="delivery-notes">Delivery notes</Tabs.Item>

          <Tabs.Item name="extra-notes">Extra notes</Tabs.Item>

          <Tabs.Item name="appointment">Appointment</Tabs.Item>
        </Tabs.Items>

        <Tabs.Panels className="trading-partner-tabs">
          <Tabs.Panel name="general-notes">
            <Layout.Stack className="pt-4" space="none">
              <Text variant="heading-sm">Before</Text>
              <Textarea {...register('general_notes.before')} />
            </Layout.Stack>
          </Tabs.Panel>

          <Tabs.Panel name="pickup-notes">
            <Layout.Group space="l">
              <Layout.Stack className="pt-4" space="none">
                <Text variant="heading-sm">Appointment include</Text>
                <Controller
                  name="pickup_notes.appointment.include"
                  control={control}
                  render={({ field }) => (
                    <Switch active={field.value} onToggle={e => field.onChange(e)} />
                  )}
                />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Appointment qualifier</Text>
                <TextField {...register('pickup_notes.appointment.qualifier')} />
              </Layout.Stack>
            </Layout.Group>
            <Layout.Stack className="pt-4" space="none">
              <Text variant="heading-sm">Text</Text>
              <Textarea {...register('pickup_notes.text')} />
            </Layout.Stack>
            <Layout.Stack className="pt-4" space="none">
              <Text variant="heading-sm">When</Text>
              <Controller
                name="pickup_notes.when"
                control={control}
                render={({ field }) => (
                  <FileViewer
                    options={{
                      heigth: 250,
                      theme: FileViewTheme.DEFAULT,
                    }}
                    content={JSON.stringify(instructions.pickup_notes?.when, null, 2)}
                    contentType="application/json"
                    onChange={value =>
                      field.onChange({
                        target: { value },
                      })
                    }
                  />
                )}
              />
            </Layout.Stack>
          </Tabs.Panel>

          <Tabs.Panel name="delivery-notes">
            <Layout.Group space="l">
              <Layout.Stack className="pt-4" space="none">
                <Text variant="heading-sm">Appointment include</Text>
                <Controller
                  name="delivery_notes.appointment.include"
                  control={control}
                  render={({ field }) => (
                    <Switch active={field.value} onToggle={e => field.onChange(e)} />
                  )}
                />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Appointment qualifier</Text>
                <TextField {...register('delivery_notes.appointment.qualifier')} />
              </Layout.Stack>
            </Layout.Group>
            <Layout.Stack className="pt-4" space="none">
              <Text variant="heading-sm">Text</Text>
              <Textarea {...register('delivery_notes.text')} />
            </Layout.Stack>
            <Layout.Stack className="pt-4" space="none">
              <Text variant="heading-sm">When</Text>
              <Controller
                name="delivery_notes.when"
                control={control}
                render={({ field }) => (
                  <FileViewer
                    options={{
                      heigth: 250,
                      theme: FileViewTheme.DEFAULT,
                    }}
                    content={JSON.stringify(instructions.delivery_notes?.when, null, 2)}
                    contentType="application/json"
                    onChange={value =>
                      field.onChange({
                        target: { value },
                      })
                    }
                  />
                )}
              />
            </Layout.Stack>
          </Tabs.Panel>

          <Tabs.Panel name="extra-notes">
            {fields.map((extraNote, index) => (
              <Layout.Group key={extraNote.id} justify="flex-end">
                <Layout.Stack className="pt-4 flex-1" space="none">
                  <Text variant="heading-sm">Segment value</Text>
                  <TextField {...register(`extra_notes.${index}.segment.value` as const)} />
                </Layout.Stack>
                <Layout.Stack className="pt-4 flex-1" space="none">
                  <Text variant="heading-sm">Segment element</Text>
                  <TextField {...register(`extra_notes.${index}.segment.element_index` as const)} />
                </Layout.Stack>
                <Layout.Stack className="pt-4 flex-1" space="none">
                  <Text variant="heading-sm">Qualifier value</Text>
                  <TextField {...register(`extra_notes.${index}.qualifier.value` as const)} />
                </Layout.Stack>
                <Layout.Stack className="pt-4 flex-1" space="none">
                  <Text variant="heading-sm">Qualifier index</Text>
                  <TextField {...register(`extra_notes.${index}.qualifier.index` as const)} />
                </Layout.Stack>
                <Layout.Stack className="pt-4 flex-1 justify-center mt-4" space="none">
                  <Button
                    leading={<Icon name="remove" size={16} />}
                    scale="large"
                    variant="tertiary"
                    className="mt-2"
                    onClick={() => removeSegment(index)}
                  >
                    Remove segment
                  </Button>
                </Layout.Stack>
              </Layout.Group>
            ))}
            <Layout.Box>
              <Button
                leading={<Icon name="add" size={16} />}
                onClick={() => addNewSegment()}
                scale="large"
                variant="tertiary"
              >
                Add segment
              </Button>
            </Layout.Box>
          </Tabs.Panel>

          <Tabs.Panel name="appointment">
            <Layout.Group>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Date format</Text>
                <TextField {...register('appointment.date.format')} />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Date element</Text>
                <TextField {...register('appointment.date.element')} />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Time format</Text>
                <TextField {...register('appointment.time.format')} />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Time element</Text>
                <TextField {...register('appointment.time.element')} />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Timezone element</Text>
                <TextField {...register('appointment.timezone.element')} />
              </Layout.Stack>
            </Layout.Group>
            <Layout.Group>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Segment</Text>
                <TextField {...register('appointment.segment')} />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Qualifier</Text>
                <TextField {...register('appointment.qualifier')} />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Qualifier position</Text>
                <TextField {...register('appointment.qualifier_pos')} type="number" />
              </Layout.Stack>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Where to place</Text>
                <ToggleGroup
                  {...register('appointment.where_to_place')}
                  value={instructions.appointment?.where_to_place}
                  options={[
                    {
                      label: 'Top',
                      value: 'top',
                    },
                  ]}
                />
              </Layout.Stack>
            </Layout.Group>
            <Layout.Stack className="pt-4" space="none">
              <Text variant="heading-sm">Template</Text>
              <Textarea {...register('appointment.template')} />
            </Layout.Stack>
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>

      <Layout.Box padding="none" className="mt-4 flex justify-end">
        <Button onClick={handleSubmit(onSubmit)} variant="primary" disabled={mutationIsLoading}>
          Save
        </Button>
      </Layout.Box>
    </>
  )
}

export default InstructionsTab
