import { Tag } from '@loadsmart/loadsmart-ui'

export interface ActiveStatusTagProps {
  active: boolean
  size?: 'small' | 'large' | 'default' | undefined
}

export default function ActiveStatusTag({ active = true, size = 'default' }: ActiveStatusTagProps) {
  return active ? (
    <Tag size={size} variant="accent">
      Active
    </Tag>
  ) : (
    <Tag size={size} variant="danger">
      Inactive
    </Tag>
  )
}
