import { Layout, Switch, Tag, Text } from '@loadsmart/loadsmart-ui'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Advice from 'atoms/Advice'
import isJson from 'common/helpers/isJSON'
import { IntegrationTestCaseExpectationDefinition } from 'common/types/kraken-core/IntegrationTest'
import FileViewer from 'molecules/FileViewer/FileViewer'
import JsonForm from 'molecules/JsonForm/JsonForm'
import {
  IntegrationTestCaseExpectationCheckerSchema,
  IntegrationTestCaseExpectationCheckerSchemaUiSchema,
} from 'pages/IntegrationTest/schemas/IntegrationTestCaseSchema'
import { useEffect, useMemo, useRef, useState } from 'react'

export interface IntegrationTestCaseDetailExpectationDefinitionProps {
  definition?: IntegrationTestCaseExpectationDefinition
  onChange: (definition: any) => void
}

export default function IntegrationTestCaseDetailExpectationDefinitionDetail(
  props: IntegrationTestCaseDetailExpectationDefinitionProps
) {
  const [definition, setDefinition] = useState(props.definition)
  const rawDefinition = useRef<any>(props.definition)
  const jsonFormDefinition = useRef<any>(props.definition)
  const [rawView, setRawView] = useState<boolean>(false)
  const checkersFromProps = props.definition?.checkers
  const [checkers, setCheckers] = useState<Array<any>>(checkersFromProps as any)

  const handleChange = (definition: any) => {
    props.onChange(definition)
  }

  useEffect(() => {
    setDefinition(props.definition)
  }, [props.definition])

  useEffect(() => {
    if (!rawView) {
      setDefinition(rawDefinition.current)
    } else {
      setDefinition(jsonFormDefinition.current)
    }
  }, [rawView])

  return (
    <Layout.Stack className="p-2">
      <Layout.Group justify="space-between">
        <Text variant="body">To define what are the expectations for this test</Text>

        <Layout.Group align="center">
          <Text variant="caption">Raw view?</Text>
          <Switch active={rawView} onToggle={() => setRawView(c => !c)} />
        </Layout.Group>
      </Layout.Group>

      <div style={{ display: rawView ? '' : 'none' }}>
        <FileViewer
          content={JSON.stringify(definition, null, 2)}
          contentType="application/json"
          options={{}}
          onChange={changed => {
            if (isJson(changed)) {
              const parsed = JSON.parse(changed)
              rawDefinition.current = parsed
              handleChange(parsed)
            }
          }}
        />
      </div>

      <div style={{ display: !rawView ? '' : 'none' }}>
        <Accordion>
          <AccordionSummary>
            <Layout.Group align="center">
              <Text>Checkers</Text>
              {checkers && <Tag variant="accent">{checkers.length}</Tag>}
            </Layout.Group>
          </AccordionSummary>
          <AccordionDetails>
            <Layout.Stack>
              <Advice
                type="info"
                value="Checkers help us to <b>query</b> for particular messages and <b>assert</b> they have an expected state. It's possible to have multiple checkers at once"
              />
              <JsonForm
                data={definition?.checkers}
                onChange={changed => {
                  setCheckers(changed.data)
                  const newDefinition = {
                    ...definition,
                    checkers: changed.data,
                  }
                  jsonFormDefinition.current = newDefinition
                  // setDefinition(newDefinition)
                  handleChange(newDefinition)
                }}
                schema={IntegrationTestCaseExpectationCheckerSchema}
                uiSchema={IntegrationTestCaseExpectationCheckerSchemaUiSchema}
              />
            </Layout.Stack>
          </AccordionDetails>
        </Accordion>
      </div>
    </Layout.Stack>
  )
}
