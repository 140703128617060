import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Breadcrumbs, Card, Layout, Tabs } from '@loadsmart/loadsmart-ui'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import Loading from 'atoms/Loading'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'
import Details from '../components/Details'
import InstructionsTab from '../components/Instructions'
import ConversionCodes from '../components/ConversionCodes'
import TranslationSettingsComponent from '../components/TranslationSettings'
import NotificationsTab from '../components/NotificationsTab'
import ContactsTab from '../components/ContactsTab'
import {
  useGetTradingPartner,
  useGetTradingPartnerHistory,
  useGetTradingPartnerInstructions,
} from '../api'
import TradingPartnerCodeComponent from 'pages/TradingPartner/components/TradingPartnerCode'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import ComponentSettings from '../components/ComponentSettings/ComponentSettings'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const TradingPartnerDetail = () => {
  useTabTitle('Trading Partner')
  const { id } = useParams<{ id?: string }>()
  const history = useHistory()
  const { isLoading, error, data } = useGetTradingPartner(id)
  const { data: tradingPartnerHistory } = useGetTradingPartnerHistory(id)
  const {
    isLoading: instructionsLoading,
    error: instructionsError,
    data: instructionsData,
  } = useGetTradingPartnerInstructions(data?.id)

  const topNavigationContext = useTopNavigationContext()

  const [breadCrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([
    {
      label: 'Trading Partner',
      url: '#',
      onClick: () => {
        history.push('/trading-partner')
      },
      active: true,
    },
  ])

  useEffect(() => {
    if (data?.name) {
      setBreadcrumbs([
        {
          label: 'Trading Partner',
          url: '#',
          onClick: () => {
            history.push('/trading-partner')
          },
          active: true,
        },
        {
          label: data.name || '',
        },
      ])
    }
  }, [data?.name, history])

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Stack space="s">
          <Breadcrumbs entries={breadCrumbs} />
        </Layout.Stack>
      ),
    })
  }, [breadCrumbs])

  return (
    <Layout.Stack className="w-full">
      {isLoading || instructionsLoading ? (
        <Loading className="mt-8 mb-8 justify-center" />
      ) : error || instructionsError ? (
        <ErrorPanel error={removeToken(JSON.stringify(error || instructionsError, null, 2))} />
      ) : (
        !!data && (
          <Layout.Stack>
            <Card className="p-4">
              <Layout.Stack space="s">
                <Details tradingPartner={data} />
              </Layout.Stack>
            </Card>

            <Card className="p-2">
              <Tabs className="flex flex-col" direction="horizontal">
                <Tabs.Items>
                  <Tabs.Item name="translation-settings" default>
                    Translation settings
                  </Tabs.Item>
                  <Tabs.Item name="component-settings">Component Settings</Tabs.Item>
                  <Tabs.Item name="conversion-codes">Conversion Codes</Tabs.Item>

                  <Tabs.Item name="instructions">Instructions</Tabs.Item>

                  <Tabs.Item name="contacts">Contacts</Tabs.Item>

                  <Tabs.Item name="notifications">Notifications</Tabs.Item>

                  <Tabs.Item name="codes">Codes</Tabs.Item>
                </Tabs.Items>

                <Tabs.Panels className="trading-partner-tabs">
                  <Tabs.Panel name="conversion-codes">
                    <ConversionCodes tradingPartner={data} />
                  </Tabs.Panel>

                  <Tabs.Panel name="translation-settings">
                    <TranslationSettingsComponent
                      tradingPartner={data}
                      tradingPartnerHistory={tradingPartnerHistory}
                    />
                  </Tabs.Panel>

                  <Tabs.Panel name="component-settings">
                    <ComponentSettings tradingPartner={data} />
                  </Tabs.Panel>

                  <Tabs.Panel name="instructions">
                    {instructionsData && <InstructionsTab instructions={instructionsData} />}
                  </Tabs.Panel>

                  <Tabs.Panel name="notifications">
                    <NotificationsTab
                      tradingPartner={data}
                      notificationSettings={data.notification_settings}
                      tradingPartnerHistory={tradingPartnerHistory}
                    />
                  </Tabs.Panel>

                  <Tabs.Panel name="contacts">
                    <ContactsTab tradingPartner={data} />
                  </Tabs.Panel>

                  <Tabs.Panel name="codes">
                    <TradingPartnerCodeComponent tradingPartner={data} />
                  </Tabs.Panel>
                </Tabs.Panels>
              </Tabs>
            </Card>
          </Layout.Stack>
        )
      )}
    </Layout.Stack>
  )
}

export default TradingPartnerDetail
