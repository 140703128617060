import { IntegrationTemplate } from 'common/types/kraken-core/IntegrationTemplate'
import { exportData, importData } from './common'
import { Button, Tooltip, Layout } from '@loadsmart/loadsmart-ui'

export interface TemplateActionsProps {
  template: IntegrationTemplate
  generatedData: any
  setGeneratedData: any
  clearFunction: any
  askForConfirmationFunction?: any
}

function TemplateActions({
  template,
  generatedData,
  setGeneratedData,
  clearFunction,
  askForConfirmationFunction,
}: TemplateActionsProps) {
  const actions: Array<any> = [
    {
      label: 'Export',
      description: 'Export all current data to a file',
      onClick: () => exportData(template, generatedData),
    },
    {
      label: 'Import',
      description: 'Import data from a file',
      onClick: () => importData(setGeneratedData),
    },
    {
      label: 'Clear',
      description: 'Clear all current data',
      onClick: clearFunction,
    },
  ]

  if (askForConfirmationFunction) {
    actions.push({
      label: 'Confirm',
      description: 'Confirm all current data and start the onboard process',
      variant: 'primary',
      onClick: askForConfirmationFunction,
    })
  }

  return (
    <div className="flex justify-end pt-1">
      <Layout.Group space={'s'}>
        {actions.map(a => (
          <Tooltip key={a.label} message={a.description}>
            <Button variant={a.variant} onClick={a.onClick}>
              {a.label}
            </Button>
          </Tooltip>
        ))}
      </Layout.Group>
      <div />
    </div>
  )
}

export default TemplateActions
