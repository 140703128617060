import { Layout, Text } from '@loadsmart/loadsmart-ui'
import { Alert, Snackbar } from '@mui/material'
import ExternalLink from 'atoms/ExternalLink'
import { MessagesActionCallResponse } from 'pages/Messages/types'

export interface MessageActionCallResponseProps {
  open: boolean
  onClose: () => void
  response?: MessagesActionCallResponse
}

export default function MessageActionCallResponse(props: MessageActionCallResponseProps) {
  if (!props.response) return null

  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      onClose={props.onClose}
    >
      <Alert severity="info" onClose={props.onClose}>
        <Layout.Group align="center">
          <Text>{props.response.message}</Text>
          <ExternalLink href={props.response.referenceUrl} target="_blank">
            Status
          </ExternalLink>
        </Layout.Group>
      </Alert>
    </Snackbar>
  )
}
