import { Accordion, Banner, Layout, Text } from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { useGetTranslationMapHistory } from '../api'
import { TranslationMapHistory } from 'common/types/kraken-core/TranslationMap'

export interface TranslationMapHistoryProps {
  id: string
}

function HistoryComponent({ id }: TranslationMapHistoryProps) {
  const {
    isLoading: isLoadingHistory,
    error: errorHistory,
    data: dataHistory,
  } = useGetTranslationMapHistory({ id })

  const parseHistory = (history?: TranslationMapHistory): string => {
    if (!history) return ''

    const { ...rest } = history

    return JSON.stringify(rest, null, 2)
  }

  return (
    <Accordion>
      <Accordion.Toggle>
        <Text>History</Text>
      </Accordion.Toggle>
      <Accordion.Body>
        {isLoadingHistory ? (
          <Loading className="mt-8 justify-center" />
        ) : (
          !!dataHistory &&
          dataHistory.map((history, index) => (
            <Accordion key={history.history_id}>
              <Accordion.Toggle>
                <Layout.Group>
                  {history.history_id}
                  {' - '}
                  {LSDate(history.history_date)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                  {history.history_user && ` by ${history.history_user}`}
                </Layout.Group>
              </Accordion.Toggle>

              <Accordion.Body>
                {history.history_change_reason && (
                  <Banner
                    title={history.history_change_reason}
                    variant="warning"
                    dismissible={false}
                    className="mt-4 mb-4"
                  />
                )}

                <ReactDiffViewer
                  splitView
                  hideLineNumbers
                  oldValue={parseHistory(dataHistory?.[index + 1])}
                  newValue={parseHistory(history)}
                  compareMethod={DiffMethod.TRIMMED_LINES}
                  styles={{
                    line: {
                      fontSize: 12,
                    },
                  }}
                />
              </Accordion.Body>
            </Accordion>
          ))
        )}
      </Accordion.Body>
    </Accordion>
  )
}

export default HistoryComponent
