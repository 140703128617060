import {
  Layout,
  Text,
  Table,
  Modal,
  Button,
  IconButton,
  Label,
  Select,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { DetailTabsProps } from '../types'
import {
  useCreateCode,
  useDeleteCode,
  useGetTradingPartnerCodes,
  useGetTradingPartnerCodeType,
  useUpdateCode,
} from '../api'
import Loading from 'atoms/Loading'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'
import { useEffect, useState } from 'react'
import { TradingPartnerCode } from 'common/types/kraken-core/TradingPartner'
import Icon from 'atoms/Icon'
import Divider from 'atoms/Divider'
import { capitalize, isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'

enum FormMode {
  CREATE = 'create',
  UPDATE = 'update',
  REMOVE = 'remove',
}

const TradingPartnerCodeComponent = ({ tradingPartner }: DetailTabsProps) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE)
  const [detail, setDetail] = useState<TradingPartnerCode>()
  const { data: tradingPartnerCodes, isLoading, error, refetch } = useGetTradingPartnerCodes(
    tradingPartner.id
  )

  const {
    mutate: create,
    isError: isCreateError,
    isSuccess: isCreateSuccess,
    error: createError,
  } = useCreateCode()
  const {
    mutate: update,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
    error: updateError,
  } = useUpdateCode()
  const {
    mutate: remove,
    isError: isRemoveError,
    isSuccess: isRemoveSuccess,
    error: removeError,
  } = useDeleteCode()

  const { data: codeTypes } = useGetTradingPartnerCodeType()

  const handleOnClickCreate = () => {
    setFormMode(FormMode.CREATE)
    setDetail({
      type: '',
      value: '',
      owner: tradingPartner.id as any,
    })
    setIsFormOpen(true)
  }

  const handleOnClickUpdate = (code: TradingPartnerCode) => {
    setFormMode(FormMode.UPDATE)
    setDetail(code)
    setIsFormOpen(true)
  }

  const handleOnClickRemove = (code: TradingPartnerCode) => {
    setFormMode(FormMode.REMOVE)
    setDetail(code)
    setIsFormOpen(true)
  }

  const isValid = (code: TradingPartnerCode) => {
    return !isEmpty(code.type) && !isEmpty(code.value)
  }

  const handleModalFormConfirmation = () => {
    if (!detail) {
      toast.error('Nothing has been selected')
      return
    }

    if ([FormMode.CREATE, FormMode.UPDATE].find(f => f === formMode)) {
      if (!isValid(detail)) {
        toast.error('Code is invalid, review form')
        return
      }
    }

    switch (formMode) {
      case FormMode.CREATE:
        create({
          tradingPartner,
          type: detail.type,
          value: detail.value,
        })
        break

      case FormMode.UPDATE:
        update({
          tradingPartner,
          id: detail.id as any,
          type: detail.type,
          value: detail.value,
        })
        break

      case FormMode.REMOVE:
        remove({
          tradingPartner,
          id: detail.id as any,
        })
        break

      default:
        toast.error(`Action ${formMode} is not supported yet`)
        break
    }
    closeModalForm()
  }

  const closeModalForm = () => {
    setIsFormOpen(false)
    setDetail(undefined)
  }

  useEffect(() => {
    if (isCreateError) {
      toast.error(`Something went wrong when creating code: ${createError}`)
      return
    }

    if (isCreateSuccess) toast.success('Successfully created')
    refetch()
  }, [isCreateError, isCreateSuccess, createError])

  useEffect(() => {
    if (isUpdateError) {
      toast.error(`Something went wrong when updating code: ${updateError}`)
      return
    }

    if (isUpdateSuccess) toast.success('Successfully updated')
    refetch()
  }, [isUpdateError, isUpdateSuccess, updateError])

  useEffect(() => {
    if (isRemoveError) {
      toast.error(`Something went wrong when deleteing code: ${removeError}`)
      return
    }

    if (isRemoveSuccess) toast.success('Successfully deleted')
    refetch()
  }, [isRemoveError, isRemoveSuccess, removeError])

  if (isLoading) return <Loading className="mt-8 mb-8 justify-center" />

  if (error) return <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />

  return (
    <>
      <Modal open={isFormOpen} onOverlayClick={closeModalForm}>
        <Layout.Stack>
          <Layout.Group justify="space-between" align="center">
            <Text variant="heading-md-bold">{`${capitalize(formMode)} Code`}</Text>
            <IconButton onClick={closeModalForm}>
              <Icon name="close" />
            </IconButton>
          </Layout.Group>
          <Divider />

          {[FormMode.CREATE, FormMode.UPDATE].find(v => v === formMode) ? (
            <Layout.Stack>
              <Layout.Stack space="s">
                <Label required>Type</Label>
                <Select
                  name="code-type"
                  value={
                    {
                      value: detail?.type || '',
                      label: detail?.type,
                    } as Selectable
                  }
                  onChange={change =>
                    setDetail((c: any) => ({
                      ...c,
                      type: change.target.value ? (change.target.value as any).value : null,
                    }))
                  }
                  options={codeTypes?.map(c => {
                    return {
                      label: c.description,
                      value: c.type,
                    }
                  })}
                />
              </Layout.Stack>

              <Layout.Stack space="s">
                <Label required>Value</Label>
                <TextField
                  value={detail?.value}
                  onChange={change => {
                    setDetail((c: any) => {
                      return {
                        ...c,
                        value: change.target.value,
                      }
                    })
                  }}
                  required
                  status={isEmpty(detail?.value) ? ('danger' as any) : ''}
                />
              </Layout.Stack>
            </Layout.Stack>
          ) : null}

          {FormMode.REMOVE === formMode ? (
            <Layout.Stack>
              <Text>
                Are you sure you want to completly delete code{' '}
                <Text variant="body-bold">{detail?.value}</Text> of type {detail?.type}?
              </Text>
            </Layout.Stack>
          ) : null}

          <Layout.Group justify="center" align="center">
            <Button onClick={closeModalForm}>Cancel</Button>
            <Button variant="primary" onClick={handleModalFormConfirmation}>
              Confirm
            </Button>
          </Layout.Group>
        </Layout.Stack>
      </Modal>
      <Layout.Stack className="p-2" space="l">
        <Layout.Group align="center" justify="flex-end" className="pt-2">
          <Button variant="primary" onClick={handleOnClickCreate}>
            Create new
          </Button>
        </Layout.Group>

        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Type</Table.HeadCell>
              <Table.HeadCell>Value</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {tradingPartnerCodes?.map(tradingPartnerCode => (
              <Table.Row key={tradingPartnerCode.id}>
                <Table.Cell>{tradingPartnerCode.type.toUpperCase()}</Table.Cell>
                <Table.Cell>{tradingPartnerCode.value}</Table.Cell>
                <Table.Cell alignment="right" style={{ width: '10vw' }}>
                  <Layout.Group align="center">
                    <IconButton onClick={() => handleOnClickRemove(tradingPartnerCode)}>
                      <Icon name="remove" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOnClickUpdate(tradingPartnerCode)}
                      name="update"
                    >
                      <Icon name="edit" />
                    </IconButton>
                  </Layout.Group>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {Boolean(tradingPartnerCodes?.length === 0) && (
          <Layout.Stack className="p-2" space="l">
            <Text variant="body-bold">No trading partner codes available.</Text>
          </Layout.Stack>
        )}
      </Layout.Stack>
    </>
  )
}

export default TradingPartnerCodeComponent
