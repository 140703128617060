import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import getEnv from '../helpers/getEnv'
import Environment from '../types/Environment'

const API_LOCAL_URL = process.env.API_LOCAL_URL || 'http://localhost:8000'
const API_HOST_URL = process.env.API_HOST_URL || 'https://edi-platform-core'
const API_DOMAIN = process.env.API_DOMAIN || 'loadsmart.io'
const API_PATH = process.env.API_PATH || 'api/v1'
const ADMIN_PATH = process.env.ADMIN_PATH || '/__admin__'

export function getBaseURL(): string {
  switch (getEnv()) {
    case Environment.Production:
      return `${API_HOST_URL}.${API_DOMAIN}`
    case Environment.Sandbox:
      return `${API_HOST_URL}.sandbox.${API_DOMAIN}`
    case Environment.Staging:
      return `${API_HOST_URL}.staging.${API_DOMAIN}`
    case Environment.QA:
      return `${API_HOST_URL}.qa.${API_DOMAIN}`
    default:
      return `${API_LOCAL_URL}`
  }
}

export const baseURL = `${getBaseURL()}/${API_PATH}`
export const adminBaseURL = `${getBaseURL()}/${ADMIN_PATH}`

function createHTTPClient(overriddes?: AxiosRequestConfig): AxiosInstance {
  const headers: Record<string, string | undefined> = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  }

  const client = axios.create({
    headers,
    withCredentials: true,
    baseURL,
    ...overriddes,
  })

  return client
}

export default createHTTPClient
