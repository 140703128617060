import { Layout, Switch, Tag, Text } from '@loadsmart/loadsmart-ui'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import Loading from 'atoms/Loading/Loading'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMMSS } from 'common/Date.helpers'
import { MessageFailureRead, MessageRead } from 'common/types/kraken-core/Message'
import { useGetMessageFailures } from 'pages/Messages/api'
import { Fragment, useEffect, useState } from 'react'

export interface MessageFailuresProps {
  message: MessageRead
}

const UNKNOWN_FAILURE_PROBLEM = 'Unknown'

export default function MessageFailures(props: MessageFailuresProps) {
  const [isGroupedByProblem, setIsGroupedByProblem] = useState(false)
  const [failureDetails, setFailureDetails] = useState<MessageFailureRead | undefined>()
  const [problems, setProblems] = useState<Array<string>>()

  const { data: allFailures, isLoading, isFetching } = useGetMessageFailures({
    id: props.message.id,
  })

  const handleToggleDetails = (detailsToToggle: MessageFailureRead) => {
    if (failureDetails?.id === detailsToToggle.id) {
      setFailureDetails(undefined)
    } else {
      setFailureDetails(detailsToToggle)
    }
  }
  const formattedException = (exception: string | undefined) => {
    if (exception) {
      if (exception.length > 30) {
        return exception.slice(0, 27).concat('...')
      }
      return exception
    }
    return ''
  }

  const formattedProblem = (exception: string | undefined) => {
    if (exception) {
      if (exception.length > 20) {
        return exception.slice(0, 17).concat('...')
      }
      return exception
    }
    return ''
  }

  const FailuresList = (failures?: Array<MessageFailureRead>) => {
    const collection = failures ?? allFailures

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Created At.</TableCell>
            {!isGroupedByProblem ? <TableCell>Problem</TableCell> : null}
            <TableCell>Exception</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection?.map((f, index) => {
            return (
              <Fragment key={`${f.id}_${index} `}>
                <TableRow onClick={() => handleToggleDetails(f)} className="cursor-pointer">
                  <TableCell>{LSDate(f.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMMSS)}</TableCell>

                  {!isGroupedByProblem ? (
                    <TableCell>
                      {f.problem ? (
                        <Tag variant="default">{formattedProblem(f.problem)}</Tag>
                      ) : null}
                    </TableCell>
                  ) : null}

                  <TableCell>
                    <Layout.Box>
                      <pre>{formattedException(f.exception)}</pre>
                    </Layout.Box>
                  </TableCell>
                  <TableCell>{LSDate(f.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMMSS)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    data-testid="failure-details"
                  >
                    <Collapse in={Boolean(failureDetails) && failureDetails?.id === f.id}>
                      <Layout.Box className="overflow-x-auto">
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                          {failureDetails?.exception || ''}
                        </pre>
                      </Layout.Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  useEffect(() => {
    if (allFailures) {
      const problems = allFailures.map(f => f.problem ?? UNKNOWN_FAILURE_PROBLEM)
      setProblems(Array.from(new Set(problems)))
    }
  }, [allFailures])

  useEffect(() => {
    setFailureDetails(undefined)
  }, [isGroupedByProblem])

  return (
    <Layout.Stack className="p-2">
      {isLoading || isFetching ? (
        <Loading className="justify-center mt-8" />
      ) : (
        <Layout.Stack space="l">
          <Layout.Group align="center">
            <Switch active={isGroupedByProblem} onToggle={() => setIsGroupedByProblem(c => !c)} />
            <Text className="pl-2">Group by problem</Text>
          </Layout.Group>

          {isGroupedByProblem ? (
            <Layout.Stack space="none">
              {problems?.map(problem => {
                let specificFailuresForThisProblem: Array<MessageFailureRead> = []

                if (allFailures) {
                  if (problem === UNKNOWN_FAILURE_PROBLEM) {
                    specificFailuresForThisProblem = allFailures.filter(f => !f.problem)
                  } else {
                    specificFailuresForThisProblem = allFailures.filter(f => f.problem === problem)
                  }
                }

                return (
                  <Accordion key={problem}>
                    <AccordionSummary>
                      <Tag variant="default">{problem}</Tag>
                    </AccordionSummary>
                    <AccordionDetails>
                      {FailuresList(specificFailuresForThisProblem)}
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </Layout.Stack>
          ) : (
            FailuresList()
          )}
        </Layout.Stack>
      )}
    </Layout.Stack>
  )
}
