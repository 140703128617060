import { useEffect, useState } from 'react'
import { Paper, Modal, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMMSS_AMPM } from 'common/Date.helpers'
import { MessageRead, MessageReceiptRead } from 'common/types/kraken-core/Message'
import { readMessageReceipts } from 'services/kraken-core/messages/messages.service'
import useCancelToken from 'hooks/useCancelToken'
import { IconButton, Layout, Tag, Tooltip } from '@loadsmart/loadsmart-ui'
import { ErrorBoundary } from 'react-error-boundary'
import FileViewer from 'molecules/FileViewer/FileViewer'
import axios from 'axios'
import Icon from 'atoms/Icon'

export interface MessageReceiptsProps {
  message: MessageRead
}

interface BodyContentReaderState {
  content?: string
  contentType?: string
  url?: string
  open: boolean
}

const ModalFileViewerFallbackComponent = ({ error, resetErrorBoundary }: any) => (
  <Paper className="p-4 my-auto ml-2 mr-2 flex flex-col text-xs" style={{ width: '90%' }}>
    Error on reading content: {String(error)}
  </Paper>
)

const MessageReceiptsView = ({ message }: MessageReceiptsProps) => {
  const [modalHeaders, setModalHeaders] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [receipts, setReceipts] = useState<MessageReceiptRead[]>([])
  const [body, setBody] = useState<BodyContentReaderState>()

  const { getSource, clearSource, cancelPending, isCancel } = useCancelToken()

  useEffect(() => {
    const source = getSource()

    ;(async () => {
      setIsLoading(true)
      const [error, response] = await readMessageReceipts(message.id, {
        cancelToken: source.token,
      })

      clearSource()
      setIsLoading(false)

      if (error && !isCancel) return toast.error(`Something went wrong: ${error}`)

      return setReceipts(response?.data || [])
    })()

    return () => cancelPending()
  }, [message.id])

  const openHeadersModal = (receipt: MessageReceiptRead) => {
    const headers = Object.keys(receipt.headers)
      .map((k: any) => ({
        key: k,
        value: receipt.headers[k] ?? '',
      }))
      .sort((a, b) => (a.key > b.key ? 1 : -1))

    setModalHeaders(headers)
  }

  const handleViewBody = (receipt: MessageReceiptRead) => {
    setBody({
      open: true,
      content: undefined,
      contentType: undefined,
    })
    ;(async () => {
      axios({
        method: 'get',
        url: receipt.body_url,
        responseType: 'blob',
      })
        .then(response => {
          const blob = response.data
          if (!blob) {
            throw Error("Response doesn't have data")
          }
          setBody({
            url: receipt.body_url,
            open: true,
          })
          return blob.text()
        })
        .then(stringValue => {
          let contentType = 'text/plain'
          if (receipt.headers && receipt.headers instanceof Object) {
            const receiptHeaders: any = {}
            Object.keys(receipt.headers).map(k => {
              receiptHeaders[k.toLocaleLowerCase()] = receipt.headers[k]
            })

            const receiptContentType = receiptHeaders['content-type']
            if (receiptContentType) {
              contentType = receiptContentType.split(';').pop() || contentType
            }
          }

          setBody(s => ({
            ...s,
            content: stringValue,
            contentType: contentType,
            open: true,
          }))
        })
        .catch(error => {
          setBody({ open: false })
          toast.error(`Something went wrong: ${error}`)
        })
    })()
  }

  return (
    <>
      <Modal
        id="receipt-headers-modal"
        open={!!modalHeaders.length}
        onClose={() => setModalHeaders([])}
        className="flex tems-center justify-center"
      >
        <Paper className="p-4 w-1/2" style={{ overflow: 'scroll' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modalHeaders.map(row => (
                <TableRow key={row.key}>
                  <TableCell>
                    <Tag size="large">{row.key}</Tag>
                  </TableCell>
                  <TableCell>
                    <Layout.Group
                      style={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {row.value}
                    </Layout.Group>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Modal>

      <Modal
        id="receipt-body-modal"
        open={!!body?.open}
        onClose={() => setBody({ open: false })}
        className="flex justify-center"
      >
        <Paper className="p-4 my-auto ml-2 mr-2 flex flex-col text-xs" style={{ width: '90%' }}>
          <ErrorBoundary FallbackComponent={ModalFileViewerFallbackComponent}>
            {!body?.content && <Loading className="justify-center mt-4" />}

            {body?.content && (
              <FileViewer
                options={{
                  readonly: false,
                  heigth: 500,
                }}
                content={body.content}
                contentType={body.contentType || 'txt'}
                url={body.url}
              />
            )}
          </ErrorBoundary>
        </Paper>
      </Modal>

      <Layout.Box background="neutral-white">
        {isLoading && <Loading className="justify-center mt-8" />}
        {!isLoading && (
          <Table data-testid="message-receipts-list" size="small">
            <TableHead>
              <TableCell>Created At</TableCell>
              <TableCell>Receipt Type</TableCell>
              <TableCell>Status Code</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableHead>
            <TableBody data-testid="message-receipts-list-body">
              {receipts?.map(row => (
                <TableRow key={`${row.created_at}-${row.receipt_type}`}>
                  <TableCell>
                    {LSDate(row.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMMSS_AMPM)}
                  </TableCell>
                  <TableCell>{row.receipt_type}</TableCell>
                  <TableCell>{row.status_code}</TableCell>
                  <TableCell align="right">
                    <Layout.Group justify="flex-end">
                      <Tooltip message="View body">
                        <IconButton onClick={() => handleViewBody(row)}>
                          <Icon name="clip" size={20} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip message="View headers">
                        <IconButton onClick={() => openHeadersModal(row)}>
                          <Icon name="info" size={20} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip message="Download">
                        <IconButton
                          onClick={() => {
                            window.open(row.body_url)
                          }}
                        >
                          <Icon name="download" size={20} />
                        </IconButton>
                      </Tooltip>
                    </Layout.Group>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Layout.Box>
    </>
  )
}

export default MessageReceiptsView
