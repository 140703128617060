import { materialCells, materialRenderers } from '@jsonforms/material-renderers'

import { default as csvReaderControlTester } from './CsvReader/csvReaderControlTester'
import { default as CsvReaderControl } from './CsvReader/CsvReaderControl'
import CheckboxSelectionControl from './CheckboxSelection/CheckboxSelectionControl'
import checkboxSelectionControlTester from './CheckboxSelection/checkboxSelectionControlTester'
import fileUploaderControlTester from './FileUploader/fileUploaderControlTester'
import FileUploaderControl from './FileUploader/FileUploaderControl'
import ControlWithAdvice from './ControlWithAdvice/ControlWithAdvice'
import ControlWithAdviceTester from './ControlWithAdvice/ControlWithAdviceTester'
import { AdviceRenderer } from './AdviceRenderer/AdviceRender'
import adviceRenderTester from './AdviceRenderer/adviceRenderTester'
import ObjectAsCode from './ObjectAsCode/ObjectAsCode'
import objectAsCodeTester from './ObjectAsCode/objectAsCodeTester'
import EntitySelector from './EntitySelector/EntitySelector'
import EntitySelectorTester from './EntitySelector/EntitySelectorTester'
import { customTextControlTester } from './CustomStringControl/CustomTextControl'
import customTextControl from './CustomStringControl/CustomTextControl'
import FileFieldControl from './FileField/FileFieldControl'
import fileFieldTester from './FileField/fileFieldControlTestes'

export const customRenderers = [
  {
    renderer: CsvReaderControl,
    tester: csvReaderControlTester,
  },
  {
    renderer: CheckboxSelectionControl,
    tester: checkboxSelectionControlTester,
  },
  {
    renderer: FileUploaderControl,
    tester: fileUploaderControlTester,
  },
  {
    renderer: ControlWithAdvice,
    tester: ControlWithAdviceTester,
  },
  {
    renderer: AdviceRenderer,
    tester: adviceRenderTester,
  },
  {
    renderer: ObjectAsCode,
    tester: objectAsCodeTester,
  },
  {
    renderer: EntitySelector,
    tester: EntitySelectorTester,
  },
  {
    renderer: customTextControl,
    tester: customTextControlTester,
  },
  {
    renderer: FileFieldControl,
    tester: fileFieldTester,
  },
]

export const defaultRenderers = [...materialRenderers]

export const defaultCells = [...materialCells]
