const loadsmartApiSchema = {
  title: 'Loadsmart API Settings',
  type: 'object',
  properties: {
    account_uuid_from_partner: {
      title: 'Account UUID related to Shipper/Carrier',
      type: 'string',
      format: 'uuid',
    },
    is_system_connection: {
      title: 'Indicates if this is a system connection',
      type: 'boolean',
    },
    override: {
      title: 'Override Defaults',
      allOf: [
        {
          $ref: '#/definitions/LoadsmartAPIOverrideSchema',
        },
      ],
    },
  },
  required: ['account_uuid_from_partner'],
  definitions: {
    JWTImplementation: {
      title: 'JWTImplementation',
      description: 'An enumeration.',
      enum: ['core.utils.JWTAuth'],
      type: 'string',
    },
    JWTSchema: {
      title: 'JWT Customization',
      type: 'object',
      properties: {
        implementation: {
          title: 'Implementation',
          allOf: [
            {
              $ref: '#/definitions/JWTImplementation',
            },
          ],
        },
        iss: {
          title: 'Issuer (iss)',
          type: 'string',
        },
        extra_claims: {
          title: 'Extra Claims',
          type: 'object',
        },
      },
      required: ['implementation', 'iss'],
    },
    CredentialStorage: {
      title: 'CredentialStorage',
      description: 'An enumeration.',
      enum: ['local', 'vault'],
      type: 'string',
    },
    CredentialsSchema: {
      title: 'Credentials',
      type: 'object',
      properties: {
        private_key: {
          title: 'Private Key',
          type: 'string',
        },
        jwt: {
          title: 'JWT Customization',
          allOf: [
            {
              $ref: '#/definitions/JWTSchema',
            },
          ],
        },
        client_id_from_intermediary: {
          title: 'Client ID for Intermediary',
          type: 'string',
        },
        storage: {
          title: 'Storage Type',
          default: 'local',
          allOf: [
            {
              $ref: '#/definitions/CredentialStorage',
            },
          ],
        },
      },
    },
    HTTPStatus: {
      title: 'HTTPStatus',
      description: 'An enumeration.',
      enum: [
        100,
        101,
        200,
        201,
        202,
        203,
        204,
        205,
        206,
        207,
        208,
        226,
        300,
        301,
        302,
        303,
        304,
        305,
        306,
        307,
        308,
        400,
        401,
        402,
        403,
        404,
        405,
        406,
        407,
        408,
        409,
        410,
        411,
        412,
        413,
        414,
        415,
        416,
        417,
        422,
        423,
        424,
        426,
        428,
        429,
        431,
        451,
        500,
        501,
        502,
        503,
        504,
        505,
        506,
        507,
        508,
        509,
        510,
        511,
      ],
      type: 'integer',
    },
    HTTPWhen: {
      title: 'When',
      type: 'object',
      properties: {
        status_code: {
          title: 'HTTP Status Code',
          allOf: [
            {
              $ref: '#/definitions/HTTPStatus',
            },
          ],
        },
        text: {
          title: 'HTTP Response Text Matches (REGEX)',
          type: 'string',
        },
      },
      required: ['status_code', 'text'],
    },
    HTTPThen: {
      title: 'Then Replace With',
      type: 'object',
      properties: {
        status_code: {
          title: 'HTTP Status Code',
          allOf: [
            {
              $ref: '#/definitions/HTTPStatus',
            },
          ],
        },
      },
      required: ['status_code'],
    },
    HTTPReplacementSchema: {
      title: 'HTTPReplacementSchema',
      type: 'object',
      properties: {
        when: {
          title: 'When',
          allOf: [
            {
              $ref: '#/definitions/HTTPWhen',
            },
          ],
        },
        then: {
          title: 'Then Replace With',
          allOf: [
            {
              $ref: '#/definitions/HTTPThen',
            },
          ],
        },
      },
      required: ['when', 'then'],
    },
    MethodQuoteCreateSchema: {
      title: 'Logistics Quote Create',
      type: 'object',
      properties: {
        'logistics:quote:request': {
          title: 'Logistics Quote Requested HTTP Replacements',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
      },
      required: ['logistics:quote:request'],
    },
    MethodShipmentEventSchema: {
      title: 'Shipment Event',
      type: 'object',
      properties: {
        'shipment:check-in-pickup': {
          title: 'Check-in Pickup HTTP Replacements',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
        'shipment:check-out-pickup': {
          title: 'Check-out Pickup HTTP Replacements',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
        'shipment:check-in-delivery': {
          title: 'Check-in Delivery HTTP Replacements',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
        'shipment:check-out-delivery': {
          title: 'Check-out Delivery HTTP Replacements',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
      },
    },
    MethodShipmentOfferAcceptSchema: {
      title: 'Shipment Offer Accept',
      type: 'object',
      properties: {
        'load:accepted': {
          title: 'Load Accepted HTTP Replacements',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
      },
      required: ['load:accepted'],
    },
    MethodShipmentTenderAcceptSchema: {
      title: 'Shipment Tender Accept',
      type: 'object',
      properties: {
        'shipment:tender:accept': {
          title: 'Tender Acceptance HTTP Replacements',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
      },
      required: ['shipment:tender:accept'],
    },
    MetricsSettings: {
      title: 'Settings',
      type: 'object',
      properties: {
        backend: {
          title: 'Backend',
          default: 'StatsDBackend',
          enum: ['StatsDBackend'],
          type: 'string',
        },
        name: {
          title: 'Name',
          type: 'string',
        },
        prefix: {
          title: 'Prefix',
          type: 'string',
        },
        tags: {
          title: 'Tags',
          type: 'object',
        },
      },
      required: ['name', 'prefix'],
    },
    MethodMetricsSettings: {
      title: 'Per Method',
      type: 'object',
      properties: {
        method: {
          title: 'Method',
          enum: [
            'container:movement',
            'carrier:account:create',
            'carrier:integration:create',
            'shipper:account:create',
            'shipper:integration:create',
            'shipment:estimate-duration',
            'facilities:search',
            'carrier:container:get',
            'get_shipment',
            'shipment:get-driver',
            'intermodal:ports:get',
            'intermodal:shipment-movements:post',
            'load:create:tender',
            'load:delete',
            'load:register',
            'load:retrieve:tender',
            'quote:book',
            'quote:create',
            'quote:list',
            'quote:request-create-and-poll',
            'quote:send',
            'search:partner',
            'shipment:event',
            'shipment:location',
            'shipment:offer:accept',
            'shipment:tender:accept',
            'shopkeeper:load:dropped',
            'shopkeeper:load:removed:response',
            'shopkeeper:offer:accepted',
            'shopkeeper:offer:created:response',
            'shopkeeper:offer:revoked:response',
          ],
          options: {
            enum_titles: [
              'CONTAINER_MOVEMENT',
              'CREATE_CARRIER_ACCOUNT',
              'CREATE_CARRIER_INTEGRATION',
              'CREATE_SHIPPER_ACCOUNT',
              'CREATE_SHIPPER_INTEGRATION',
              'ESTIMATE_DURATION',
              'FACILITIES_SEARCH',
              'GET_CARRIER_CONTAINER',
              'GET_SHIPMENT',
              'GET_SHIPMENT_DRIVER',
              'INTERMODAL_GET_PORTS',
              'INTERMODAL_POST_MOVEMENT',
              'LOAD_CREATE_TENDER',
              'LOAD_DELETE',
              'LOAD_REGISTER',
              'LOAD_RETRIEVE_TENDER',
              'QUOTE_BOOK',
              'QUOTE_CREATE',
              'QUOTE_LIST',
              'QUOTE_REQUEST_CREATE_AND_POLL',
              'QUOTE_SEND',
              'SEARCH_PARTNER',
              'SHIPMENT_EVENT',
              'SHIPMENT_LOCATION',
              'SHIPMENT_OFFER_ACCEPT',
              'SHIPMENT_TENDER_ACCEPT',
              'SHOPKEEPER_LOAD_DROPPED',
              'SHOPKEEPER_LOAD_REMOVED_RESPONSE',
              'SHOPKEEPER_OFFER_ACCEPTED',
              'SHOPKEEPER_OFFER_CREATED_RESPONSE',
              'SHOPKEEPER_OFFER_REVOKED_RESPONSE',
            ],
          },
          type: 'string',
        },
        settings: {
          title: 'Settings',
          allOf: [
            {
              $ref: '#/definitions/MetricsSettings',
            },
          ],
        },
      },
      required: ['method', 'settings'],
    },
    MethodEstimateDurationSchema: {
      title: 'Estimate Duration',
      type: 'object',
      properties: {
        'logistics:quote:request': {
          title: 'Estimate Duration HTTP Replacements',
          type: 'array',
          items: {
            $ref: '#/definitions/HTTPReplacementSchema',
          },
        },
      },
      required: ['logistics:quote:request'],
    },
    PollingSchema: {
      title: 'Polling Definition',
      type: 'object',
      properties: {
        timeout: {
          title: 'Total seconds amount until timeout',
          type: 'number',
        },
        time_interval: {
          title: 'Time interval in seconds between each poll',
          type: 'number',
        },
      },
    },
    MethodQuoteRequestCreateAndPollSchema: {
      title: 'Quote Request Create and Poll',
      type: 'object',
      properties: {
        polling: {
          $ref: '#/definitions/PollingSchema',
        },
      },
      required: ['polling'],
    },
    LoadsmartAPIOverrideSchema: {
      title: 'Override Defaults',
      type: 'object',
      properties: {
        url: {
          title: 'URL',
          minLength: 1,
          maxLength: 2083,
          format: 'uri',
          type: 'string',
        },
        client_id_from_intermediary: {
          title: 'Client ID for Intermediary',
          type: 'string',
        },
        credentials: {
          title: 'Credentials',
          allOf: [
            {
              $ref: '#/definitions/CredentialsSchema',
            },
          ],
        },
        'quote:create': {
          title: 'Logistics Quote Create',
          allOf: [
            {
              $ref: '#/definitions/MethodQuoteCreateSchema',
            },
          ],
        },
        'shipment:event': {
          title: 'Shipment Event',
          allOf: [
            {
              $ref: '#/definitions/MethodShipmentEventSchema',
            },
          ],
        },
        'shipment:offer:accept': {
          title: 'Shipment Offer Accept',
          allOf: [
            {
              $ref: '#/definitions/MethodShipmentOfferAcceptSchema',
            },
          ],
        },
        'shipment:tender:accept': {
          title: 'Shipment Tender Accept',
          allOf: [
            {
              $ref: '#/definitions/MethodShipmentTenderAcceptSchema',
            },
          ],
        },
        metrics: {
          title: 'Metrics',
          type: 'array',
          items: {
            $ref: '#/definitions/MethodMetricsSettings',
          },
        },
        'shipment:estimate-duration': {
          title: 'Estimate Duration',
          allOf: [
            {
              $ref: '#/definitions/MethodEstimateDurationSchema',
            },
          ],
        },
        'quote:request-create-and-poll': {
          title: 'Quote Request Create and Poll',
          allOf: [
            {
              $ref: '#/definitions/MethodQuoteRequestCreateAndPollSchema',
            },
          ],
        },
      },
    },
  },
}

export default loadsmartApiSchema
