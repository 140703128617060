import downloadFile from 'common/helpers/downloadFile'
import { ConnectionProtocol } from 'common/types/kraken-core/ConnectionSettings'
import {
  GatewaySetting,
  GatewaySettingHistory,
  GatewaySettingSchema,
  GatewayType,
} from 'common/types/kraken-core/GatewaySettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import SearchResponse from 'common/types/SearchResponse'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import {
  searchConnectionSettings,
  searchProtocols,
} from 'services/kraken-core/connection_settings/connection_settings.service'
import {
  exportGatewaySettings,
  getGatewaySettings,
  getGatewaySettingsHistory,
  importGatewaySettings,
  searchGatewaySettings,
  searchGatewayTypes,
  revertHistory,
  deleteGatewaySetting,
  updateGatewaySetting,
  testGatewaynSetting,
  getSchemaByGatewayType,
} from 'services/kraken-core/gateway_settings/gateway_settings.service'
import {
  getTradingPartner,
  searchTradingPartner,
} from 'services/kraken-core/trading_partner/tradingPartner.service'
import {
  ImportedGatewaySetting,
  QUERIES,
  useGetGatewaySchemaParams,
  useGetGatewaySettingsParams,
  useSearchGatewaySettingsParams,
} from './types'

export const useSearchGatewaySettings = (
  params: useSearchGatewaySettingsParams
): UseQueryResult<SearchResponse<GatewaySetting[]>> => {
  const queryFn = async ({ page, ...filters }: useSearchGatewaySettingsParams) => {
    const { response } = await searchGatewaySettings({
      ...filters,
      page: page || 1,
      page_size: filters.page_size || 10,
    })

    return response?.data || {}
  }

  const query = useQuery([QUERIES.SEARCH_GATEWAY_SETTINGS, params], () => queryFn(params), {
    keepPreviousData: true,
  })

  return query
}

export const useGetGatewaySettings = (
  params: useGetGatewaySettingsParams,
  config?: any
): UseQueryResult<GatewaySetting> => {
  const queryFn = async ({ id }: useGetGatewaySettingsParams) => {
    const { response } = await getGatewaySettings(id)
    return response?.data || {}
  }
  const query = useQuery([QUERIES.GET_GATEWAY_SETTINGS, params], () => queryFn(params), {
    ...config,
    cacheTime: 0,
  })
  return query
}

export const onSearchGatewayTypes = async ({
  query,
}: {
  query: string
}): Promise<GatewayType[]> => {
  const result = await searchGatewayTypes()
  return result?.response?.data.filter((p: GatewayType) => RegExp(query, 'i').test(p.description))
}

export const onSearchConnections = async ({ query }: { query: string }): Promise<GatewayType[]> => {
  const params = {
    name: query,
  }

  const result = await searchConnectionSettings({
    ...params,
    page_size: 100,
  })

  return result?.response?.data?.results
}

export const useSearchGatewayTypes = (options?: any): UseQueryResult<GatewayType[]> => {
  const queryFn = async () => {
    const { response } = await searchGatewayTypes()

    return response?.data || {}
  }
  const query = useQuery([QUERIES.SEARCH_GATEWAY_TYPES], () => queryFn())
  return query
}

export const onExport = async (id: string, name: string) => {
  const result = await exportGatewaySettings(id)

  downloadFile(
    JSON.stringify(result.response?.data || {}),
    `gateway_${name}.json`,
    'application/json'
  )
}

export const useSearchConnectionProtocols = (): UseQueryResult<ConnectionProtocol[]> => {
  const queryFn = async () => {
    const { response } = await searchProtocols()

    return response?.data || {}
  }

  const query = useQuery([QUERIES.SEARCH_CONNECTION_PROTOCOLS], () => queryFn())

  return query
}

export const useGetTradingPartner = (id?: string): UseQueryResult<TradingPartner> => {
  const queryFn = async (partnerId: string) => {
    const { response } = await getTradingPartner({ id: partnerId })

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_TRADING_PARTNER, id], () => queryFn(id || ''), {
    enabled: !!id,
  })

  return query
}

export const onSearchTradingPartners = async ({
  query,
}: {
  query: string
}): Promise<TradingPartner[]> => {
  const result = await searchTradingPartner({
    filters: [
      {
        property: 'name',
        value: query,
      },
    ],
    page_size: 50,
  })

  return result?.response?.data?.results
}

export const useImportGatewaySetting = (): UseMutationResult<
  any,
  unknown,
  ImportedGatewaySetting,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (params: ImportedGatewaySetting) =>
      importGatewaySettings({
        ...params,
        connection: params.connectionId ? { id: params.connectionId } : params.connection,
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([QUERIES.SEARCH_GATEWAY_SETTINGS])
      },
    }
  )

  return mutation
}

export const useGetGatewaySettingsHistory = (
  params: useGetGatewaySettingsParams
): UseQueryResult<GatewaySettingHistory[]> => {
  const queryFn = async ({ id }: useGetGatewaySettingsParams) => {
    if (!id) return {}

    const { response } = await getGatewaySettingsHistory(id)

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_GATEWAY_SETTINGS_HISTORY, params], () => queryFn(params), {
    enabled: !!params.id,
  })

  return query
}

export const useRevertGatewayHistory = (): UseMutationResult<
  any,
  unknown,
  GatewaySettingHistory,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(async (params: GatewaySettingHistory) => revertHistory(params), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([QUERIES.GET_GATEWAY_SETTINGS_HISTORY])
    },
  })

  return mutation
}

export const useDeleteGatewaySettings = (): UseMutationResult<any, unknown, string, unknown> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (id: string) => {
      const { response } = await deleteGatewaySetting(id)

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.SEARCH_GATEWAY_SETTINGS])
      },
    }
  )

  return mutation
}

export const useUpdateGatewaySetting = (): UseMutationResult<
  any,
  unknown,
  GatewaySetting,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (params: GatewaySetting) => {
      const { response } = await updateGatewaySetting({ ...params })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QUERIES.GET_GATEWAY_SETTINGS,
          QUERIES.SEARCH_GATEWAY_SETTINGS,
        ])
      },
    }
  )

  return mutation
}

export const useTestGatewaySetting = (): UseMutationResult<
  any,
  unknown,
  GatewaySetting,
  unknown
> => {
  const mutation = useMutation(async (params: GatewaySetting) => {
    const { response } = await testGatewaynSetting(params.id)
    return response?.data
  }, {})

  return mutation
}

export const useGetGatewaySchema = (
  params: useGetGatewaySchemaParams,
  options?: any
): UseQueryResult<GatewaySettingSchema, GatewaySetting> => {
  const queryFn = async (gateway_type: string) => {
    const { response } = await getSchemaByGatewayType(gateway_type)

    return response?.data || {}
  }

  const query: UseQueryResult<GatewaySettingSchema, GatewaySetting> = useQuery(
    [QUERIES.GET_GATEWAY_SETTINGS_SCHEMA, params.gateway_type],
    () => queryFn(params.gateway_type),
    { ...options }
  )

  return query
}
