import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import {
  TranslationSettings,
  TranslationSettingsDetail,
} from 'common/types/kraken-core/TranslationSettings'
import isEmpty from 'lodash.isempty'
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query'
import {
  createTranslationSettings,
  deleteTranslationSettings,
  getTranslationSettings,
  searchTranslationSettings,
  updateTranslationSettings,
} from 'services/kraken-core/translation_settings/translationSettings.service'
import {
  QUERIES,
  useGetTranslationSettingsParams,
  useSearchTranslationSettingsParams,
} from './types'

export const useSearchTranslationSettings = (
  params?: useSearchTranslationSettingsParams
): UseQueryResult<PaginatedResponse<TranslationSettings>> => {
  const queryFn = async (filters: any) => {
    const { response } = await searchTranslationSettings({
      ...filters,
    })
    return response?.data || {}
  }

  const query = useQuery([QUERIES.SEARCH_TRANSLATION_SETTINGS, params], () => queryFn(params), {})
  return query
}

export const useGetTranslationSettings = (
  params: useGetTranslationSettingsParams,
  config?: any
): UseQueryResult<TranslationSettingsDetail> => {
  const queryFn = async ({ id }: useGetTranslationSettingsParams) => {
    if (isEmpty(id)) return {}
    const { response } = await getTranslationSettings(id)
    return response?.data || {}
  }
  const query = useQuery([QUERIES.GET_TRANSLATION_SETTINGS, params], () => queryFn(params), {
    keepPreviousData: false,
    ...config,
  })
  return query
}

export const useUpdateTranslationSettings = (): UseMutationResult<
  any,
  unknown,
  TranslationSettingsDetail,
  unknown
> => {
  const mutation = useMutation(async (params: TranslationSettingsDetail) => {
    const { response, error } = await updateTranslationSettings({ ...params })
    if (error) throw JSON.stringify(error.response?.data)
    return response?.data
  })
  return mutation
}

export const useCreateTranslationSettings = (): UseMutationResult<
  any,
  unknown,
  TranslationSettingsDetail,
  unknown
> => {
  const mutation = useMutation(async (params: TranslationSettingsDetail) => {
    const { response, error } = await createTranslationSettings({ ...params })
    if (error) throw JSON.stringify(error.response?.data)
    return response?.data
  })
  return mutation
}

export const useDeleteTranslationSettings = (): UseMutationResult<
  any,
  unknown,
  string,
  unknown
> => {
  const mutation = useMutation(async (id: string) => {
    const { response, error } = await deleteTranslationSettings(id)
    if (error) throw JSON.stringify(error.response?.data)
    return response?.data
  })
  return mutation
}
