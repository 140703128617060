import { Button, Dialog, Layout } from '@loadsmart/loadsmart-ui'
import {} from '@mui/material'
import React, { useEffect, useState } from 'react'

export interface ConfirmationDialogProps {
  open: boolean
  title: string
  content: React.ReactNode | string
  actions?: React.ReactNode
  onConfirm: () => void
  onClose?: () => void
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const [open, setOpen] = useState(props.open)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <Dialog open={open}>
      <Dialog.Header>{props.title}</Dialog.Header>
      <Dialog.Body>{props.content}</Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionCancel
          onCancel={() => {
            if (props.onClose) props.onClose()
          }}
        >
          Cancel
        </Dialog.ActionCancel>
        <Dialog.ActionConfirm
          onConfirm={() => {
            props.onConfirm()
            setOpen(false)
          }}
        >
          Confirm
        </Dialog.ActionConfirm>
      </Dialog.Actions>
      <Layout.Stack />
    </Dialog>
  )
}
