import { Layout, Switch, Tag, Text } from '@loadsmart/loadsmart-ui'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import isJson from 'common/helpers/isJSON'
import { IntegrationTestCaseInputDefinition } from 'common/types/kraken-core/IntegrationTest'
import isEmpty from 'lodash.isempty'
import FileViewer from 'molecules/FileViewer/FileViewer'
import JsonForm from 'molecules/JsonForm/JsonForm'
import {
  IntegrationTestCaseInputHttpMockSchema,
  IntegrationTestCaseInputHttpMockUiSchema,
  IntegrationTestCaseInputLookUpTableMockSchema,
  IntegrationTestCaseInputFtpSftpMockSchema,
  IntegrationTestCaseInputFtpSftpMockUiSchema,
  IntegrationTestCaseInputAwsS3MockSchema,
  IntegrationTestCaseInputAwsS3MockUiSchema,
} from 'pages/IntegrationTest/schemas/IntegrationTestCaseSchema'
import { useEffect, useMemo, useRef, useState } from 'react'

export interface IntegrationTestCaseDetailInputDefinitionProps {
  definition?: {
    inputDefinition?: IntegrationTestCaseInputDefinition
    http_mocks: Array<object>
    lookup_table_mocks: Array<object>
    ftp_mocks: Array<object>
  }
  onChange: (propertyName: string | undefined, value: any) => void
}

export default function IntegrationTestCaseDetailInputtDefinition(
  props: IntegrationTestCaseDetailInputDefinitionProps
) {
  const [rawView, setRawView] = useState<boolean>(false)
  const [lastUpdate, setLastUpdate] = useState(Date.now())

  const definitionFromProps = {
    ...props.definition?.inputDefinition,
    http_mocks: props.definition?.http_mocks || [],
    lookup_table_mocks: props.definition?.lookup_table_mocks || [],
    ftp_mocks: props.definition?.ftp_mocks || [],
  }
  const [definition, setDefinition] = useState<any>(definitionFromProps)

  const rawDefinitionRef = useRef<any>(definitionFromProps)
  const definitionRef = useRef<any>(definitionFromProps)

  const onChangeCallback = (value: IntegrationTestCaseInputDefinition) => {
    props.onChange(undefined, value)
  }

  const handleOnPayloadChange = (value: string) => {
    setDefinition((d: any) => ({
      ...d,
      payload: value,
    }))
  }

  const handleOnHeadersChange = (value: any) => {
    setDefinition((d: any) => ({
      ...d,
      headers: value,
    }))
  }

  const handleOnHttpMocksChange = (value: any) => {
    props.onChange('http_mocks', value)
    setDefinition((d: any) => ({ ...d, http_mocks: value }))
  }

  const handleOnLookupTableMocksChange = (value: any) => {
    props.onChange('lookup_table_mocks', value)
    setDefinition((d: any) => ({ ...d, lookup_table_mocks: value }))
  }

  const handleOnFtpSftpMocksChange = (value: any) => {
    props.onChange('ftp_mocks', value)
    setDefinition((d: any) => ({ ...d, ftp_mocks: value }))
  }

  const handleOnAwsS3MocksChange = (value: any) => {
    props.onChange('aws_s3_mocks', value)
    setDefinition((d: any) => ({ ...d, aws_s3_mocks: value }))
  }

  const HeadersEditorView = useMemo(() => {
    return (
      <FileViewer
        content={JSON.stringify(definitionRef.current.headers, null, 2)}
        contentType="application/json"
        options={{}}
        onChange={value => {
          if (isJson(value)) {
            const headers = JSON.parse(value)
            handleOnHeadersChange(headers)
            definitionRef.current = {
              ...definitionRef.current,
              headers,
            }
          }
        }}
      />
    )
  }, [rawView, lastUpdate])

  const PayloadEditorView = useMemo(() => {
    return (
      <FileViewer
        content={atob(definitionRef.current?.payload || '')}
        contentType=""
        options={{}}
        onChange={value => {
          const encoded = btoa(value)
          handleOnPayloadChange(encoded)
          definitionRef.current = {
            ...definitionRef.current,
            payload: encoded,
          }
        }}
      />
    )
  }, [rawView, lastUpdate])

  const HttpMockView = useMemo(() => {
    return (
      <JsonForm
        data={definitionRef.current?.http_mocks}
        onChange={changed => {
          if (changed.data) {
            handleOnHttpMocksChange(changed.data)
            definitionRef.current = {
              ...definitionRef.current,
              http_mocks: changed.data,
            }
          }
        }}
        schema={IntegrationTestCaseInputHttpMockSchema}
        uiSchema={IntegrationTestCaseInputHttpMockUiSchema}
      />
    )
  }, [rawView, lastUpdate])

  const LookupTableMockView = useMemo(() => {
    return (
      <JsonForm
        data={definitionRef.current?.lookup_table_mocks}
        onChange={changed => {
          if (changed.data) {
            handleOnLookupTableMocksChange(changed.data)
            definitionRef.current = {
              ...definitionRef.current,
              lookup_table_mocks: changed.data,
            }
          }
        }}
        schema={IntegrationTestCaseInputLookUpTableMockSchema}
      />
    )
  }, [rawView, lastUpdate])

  const FtpSftpMockView = useMemo(() => {
    return (
      <JsonForm
        data={definitionRef.current?.ftp_mocks}
        onChange={changed => {
          if (changed.data) {
            handleOnFtpSftpMocksChange(changed.data)
            definitionRef.current = {
              ...definitionRef.current,
              ftp_mocks: changed.data,
            }
          }
        }}
        schema={IntegrationTestCaseInputFtpSftpMockSchema}
        uiSchema={IntegrationTestCaseInputFtpSftpMockUiSchema}
      />
    )
  }, [rawView, lastUpdate])

  const AwsS3MockView = useMemo(() => {
    return (
      <JsonForm
        data={definitionRef.current?.aws_s3_mocks}
        onChange={changed => {
          if (changed.data) {
            handleOnAwsS3MocksChange(changed.data)
            definitionRef.current = {
              ...definitionRef.current,
              aws_s3_mocks: changed.data,
            }
          }
        }}
        schema={IntegrationTestCaseInputAwsS3MockSchema}
        uiSchema={IntegrationTestCaseInputAwsS3MockUiSchema}
      />
    )
  }, [rawView, lastUpdate])

  const EditorRawView = useMemo(() => {
    return (
      <FileViewer
        content={JSON.stringify(
          {
            ...rawDefinitionRef.current,
          },
          null,
          2
        )}
        contentType="application/json"
        options={{
          heigth: 500,
        }}
        onChange={changed => {
          if (isJson(changed)) {
            const parsed = JSON.parse(changed)
            rawDefinitionRef.current = parsed
            setDefinition(parsed)
            handleOnHttpMocksChange(parsed.http_mocks)
            handleOnLookupTableMocksChange(parsed.lookup_table_mocks)
            handleOnFtpSftpMocksChange(parsed.ftp_mocks)
            handleOnAwsS3MocksChange(parsed.aws_s3_mocks)
          }
        }}
      />
    )
  }, [rawView, lastUpdate])

  useEffect(() => {
    onChangeCallback(definition)
  }, [definition])

  useEffect(() => {
    definitionRef.current = definitionFromProps
    rawDefinitionRef.current = definitionFromProps
    setLastUpdate(Date.now())
  }, [props.definition])

  return (
    <Layout.Stack className="p-2">
      <Layout.Group justify="space-between" space="s">
        <Text variant="body">To define what are the input arguments</Text>
        <Layout.Group align="center">
          <Text variant="caption">Raw view?</Text>
          <Switch
            active={rawView}
            onToggle={() => {
              if (!rawView) {
                rawDefinitionRef.current = definitionRef.current
              } else {
                definitionRef.current = rawDefinitionRef.current
              }
              setRawView(c => !c)
            }}
          />
        </Layout.Group>
      </Layout.Group>

      {rawView ? EditorRawView : null}

      {!rawView ? (
        <div>
          <Accordion>
            <AccordionSummary>
              <Layout.Group>
                <Text>HTTP Payload</Text>
                {!isEmpty(definition.payload) ? <Tag variant="accent">Has definition</Tag> : null}
              </Layout.Group>
            </AccordionSummary>
            <AccordionDetails>{PayloadEditorView}</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Layout.Group>
                <Text>HTTP Headers</Text>
                {!isEmpty(definition.headers) ? <Tag variant="accent">Has definition</Tag> : null}
              </Layout.Group>
            </AccordionSummary>
            <AccordionDetails>{HeadersEditorView}</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Layout.Group>
                <Text>HTTP Mocks</Text>
                {!isEmpty(definition.http_mocks) ? (
                  <Tag variant="accent">Has definition</Tag>
                ) : null}
              </Layout.Group>
            </AccordionSummary>
            <AccordionDetails>{HttpMockView}</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Layout.Group>
                <Text>Lookup Table Mocks</Text>
                {!isEmpty(definition.lookup_table_mocks) ? (
                  <Tag variant="accent">Has definition</Tag>
                ) : null}
              </Layout.Group>
            </AccordionSummary>
            <AccordionDetails>{LookupTableMockView}</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Layout.Group>
                <Text>FTP-sFTP Mocks</Text>
                {!isEmpty(definition.ftp_mocks) ? <Tag variant="accent">Has definition</Tag> : null}
              </Layout.Group>
            </AccordionSummary>
            <AccordionDetails>{FtpSftpMockView}</AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Layout.Group>
                <Text>AWS S3 Mocks</Text>
                {!isEmpty(definition.aws_s3_mocks) ? (
                  <Tag variant="accent">Has definition</Tag>
                ) : null}
              </Layout.Group>
            </AccordionSummary>
            <AccordionDetails>{AwsS3MockView}</AccordionDetails>
          </Accordion>
        </div>
      ) : null}
    </Layout.Stack>
  )
}
