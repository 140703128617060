import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import {
  Partnership,
  PartnershipCreateUpdate,
  PartnershipDetail,
} from 'common/types/kraken-core/Partnership'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import {
  createPartnership,
  deletePartnership,
  getPartnership,
  searchPartnership,
  updatePartnership,
} from 'services/kraken-core/partnership/partnership.service'
import { QUERIES, useGetPartnershipParams, useSearchPartnershipParams } from './types'

export const useSearchPartnership = (
  params: useSearchPartnershipParams
): UseQueryResult<PaginatedResponse<Partnership>> => {
  const queryFn = async (filters: useSearchPartnershipParams) => {
    const { response } = await searchPartnership({
      ...filters,
    })
    return response?.data || {}
  }
  const query = useQuery([QUERIES.SEARCH_PARTNERSHIP, params], () => queryFn(params), {
    keepPreviousData: true,
  })
  return query
}

export const useGetPartnership = (
  params: useGetPartnershipParams,
  options?: any
): UseQueryResult<PartnershipDetail> => {
  const queryFn = async ({ id }: useGetPartnershipParams) => {
    if (!id) return {}
    const { response } = await getPartnership(id)
    return response?.data || {}
  }
  const query = useQuery([QUERIES.GET, params], () => queryFn(params), {
    enabled: !!params.id,
    ...options,
  })
  return query
}

export const useUpdatePartnership = (): UseMutationResult<
  any,
  unknown,
  PartnershipCreateUpdate,
  unknown
> => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async (partnership: PartnershipCreateUpdate) => {
      const { response } = await updatePartnership(partnership)
      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.UPDATE])
      },
    }
  )
  return mutation
}

export const useCreatePartnership = (): UseMutationResult<
  any,
  unknown,
  PartnershipCreateUpdate,
  unknown
> => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async (partnership: PartnershipCreateUpdate) => {
      const { response } = await createPartnership(partnership)
      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.CREATE])
      },
    }
  )
  return mutation
}

export const useDeletePartnership = (): UseMutationResult<any, unknown, number, unknown> => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async (id: number) => {
      const { response } = await deletePartnership(id)
      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.DELETE])
      },
    }
  )
  return mutation
}
