import { Link } from '@loadsmart/loadsmart-ui'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'

export interface LinkToPartnerProps {
  partner: TradingPartner
  label?: string
}

export default function LinkToParnter(props: LinkToPartnerProps) {
  const partner = props.partner
  const name =
    partner.name && partner.name?.length > 20
      ? partner.name.slice(0, 20).concat('...')
      : partner.name
  return <Link href={`/trading-partner/${partner.id}`}>{props.label || name}</Link>
}
