import LSDate from 'common/Date.helpers'
import { IChart } from '@mrblenny/react-flow-chart'
import {
  MessageFailureRead,
  MessageHeaders,
  MessageHistoryRead,
} from 'common/types/kraken-core/Message'
import { RouteStep } from 'common/types/kraken-core/Routes'

export interface CreateNodesProps {
  routeSteps: RouteStep[]
}

export interface FeedHeadersProps {
  history: MessageHistoryRead[]
}

export interface FeedFailuresProps {
  failures: MessageFailureRead[]
}

export interface FeedLinks {
  failures: MessageFailureRead[]
}

export default class ChartBuilder {
  chart: IChart

  constructor() {
    this.chart = {
      scale: 1,
      offset: {
        x: 0,
        y: 0,
      },
      nodes: {},
      links: {},
      selected: {},
      hovered: {},
    }
  }

  createNodes = ({ routeSteps }: CreateNodesProps): ChartBuilder => {
    routeSteps?.forEach((step, index) => {
      this.chart.nodes[step.name] = {
        id: step.name,
        type:
          index === 0
            ? 'input-only'
            : index + 1 === routeSteps.length
            ? 'output-only'
            : 'input-outpout',
        position: {
          x: 50,
          y: 50 + 150 * index,
        },
        ports: {},
        properties: {
          index,
          stepName: step.name,
          description: step.description,
          previousStepName: routeSteps?.[index - 1]?.name,
          processors: step.processors,
        },
      }
    })

    return this
  }

  feedHeaders = ({ history }: FeedHeadersProps): ChartBuilder => {
    history
      .sort((a, b) => (LSDate(a.created_at)?.is.before(LSDate(b.created_at)) ? -1 : 1))
      .forEach(h => {
        const stepName = h.headers[MessageHeaders.LAST_STEP]
        const nodeStep = this.chart.nodes[stepName]

        if (!nodeStep) return

        this.chart.nodes[stepName] = {
          ...nodeStep,
          properties: {
            ...(nodeStep?.properties ?? {}),
            headers: {
              ...(nodeStep?.properties?.headers ?? {}),
              ...h.headers,
            },
          },
        }
      })

    return this
  }

  feedPreviousHeaders = () => {
    const nodesArray = Object.entries(this.chart.nodes)

    nodesArray.forEach(([stepName, node], index) => {
      const previousNode = nodesArray[index - 1]?.[1] ?? {}

      this.chart.nodes[stepName] = {
        ...node,
        properties: {
          ...(node?.properties ?? {}),
          prevHeaders: previousNode.properties?.headers,
        },
      }
    })

    return this
  }

  feedFailures = ({ failures }: FeedFailuresProps) => {
    const nodesArray = Object.entries(this.chart.nodes)

    nodesArray.forEach(([stepName, node], index) => {
      const stepFailure = failures.filter(f => f.last_step === node.properties?.stepName)

      this.chart.nodes[stepName] = {
        ...node,
        properties: {
          ...(node?.properties ?? {}),
          hasFailures: stepFailure?.length ?? false,
        },
      }
    })

    return this
  }

  feedPorts = () => {
    const nodesArray = Object.entries(this.chart.nodes)

    nodesArray.forEach(([stepName, node], index) => {
      const linkColor = node.properties?.hasFailures ? '#E80045' : '#28d454'

      if (index + 1 === nodesArray?.length) {
        this.chart.nodes[stepName].ports = {
          port1: {
            id: 'port1',
            type: 'input',
            properties: {
              linkColor,
            },
          },
        }
      } else if (index === 0) {
        this.chart.nodes[stepName].ports = {
          port2: {
            id: 'port2',
            type: 'output',
            properties: {
              linkColor,
            },
          },
        }
      } else {
        this.chart.nodes[stepName].ports = {
          port1: {
            id: 'port1',
            type: 'input',
            properties: {
              linkColor,
            },
          },
          port2: {
            id: 'port2',
            type: 'output',
            properties: {
              linkColor: '#28d454',
            },
          },
        }
      }
    })

    return this
  }

  feedLinks = ({ failures }: FeedFailuresProps) => {
    const nodesArray = Object.entries(this.chart.nodes)

    nodesArray.forEach(([stepName, node], index) => {
      const previousNodeName = nodesArray[index - 1]?.[0] ?? null

      if (index + 1 !== nodesArray?.length) {
        this.chart.links[`link-${stepName}`] = {
          id: `link-${stepName}`,
          from: {
            nodeId: stepName,
            portId: 'port2',
          },
          to: {
            nodeId: nodesArray?.[index + 1]?.[0],
            portId: 'port1',
          },
        }
      }

      if (previousNodeName) {
        const stepFailure = failures.filter(f => f.last_step === stepName)

        this.chart.links[`link-${previousNodeName}`].properties = {
          failures: stepFailure || [],
        }
      }
    })

    return this
  }

  createChildrenNodes = () => {
    const nodesArray = Object.entries(this.chart.nodes)

    nodesArray.forEach(([stepName, node], index) => {
      const previousChildrens: string[] =
        node?.properties?.prevHeaders?.[MessageHeaders.CHILDREN_UUIDS] ?? []
      const children: string[] = node?.properties?.headers?.[MessageHeaders.CHILDREN_UUIDS] ?? []

      if (previousChildrens.length < children.length) {
        children.forEach((child, childrenIndex) => {
          this.chart.nodes[`child-${child}-${stepName}`] = {
            id: `child-${child}-${stepName}`,
            type: 'child',
            position: {
              x: 600,
              y: 60 + 150 * index + 100 * childrenIndex,
            },
            ports: {
              port1: {
                id: 'port1',
                type: 'left',
                properties: {
                  type: 'child',
                  linkColor: '#929AA1',
                },
              },
            },
            properties: {
              stepName: child,
            },
          }

          this.chart.nodes[stepName] = {
            ...node,
            ports: {
              ...node.ports,
              port3: {
                id: 'port3',
                type: 'right',
                properties: {
                  type: 'child',
                  linkColor: '#929AA1',
                },
              },
            },
          }

          this.chart.links[`link-${child}-${stepName}`] = {
            id: `link-${child}-${stepName}`,
            from: {
              nodeId: stepName,
              portId: 'port3',
            },
            to: {
              nodeId: `child-${child}-${stepName}`,
              portId: 'port1',
            },
          }
        })
      }
    })

    return this
  }
}
