import { Button, Drawer, Label, Layout, Switch, Tabs } from '@loadsmart/loadsmart-ui'
import isJson from 'common/helpers/isJSON'
import isEmpty from 'lodash.isempty'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import FormBuilderEditor from './FormBuilderEditor'
import FormBuilderPreview from './FormBuilderPreview'

const CUSTOM_TAB_PANEL_STYLE = {
  marginTop: '-2rem',
}
export interface FormBuilderSaveCallbackResponse {
  schema?: object
  uiSchema?: object
}

export type FormBuilderOnUpdateCallbackResponse = FormBuilderSaveCallbackResponse

export interface FormBuilderProps {
  schema: any
  uiSchema: any
  onSave?: (response: FormBuilderSaveCallbackResponse) => void
  onUpdate?: (response: FormBuilderOnUpdateCallbackResponse) => void
  onPreview?: () => void
}

interface SchemaWrapper {
  json: any
  isValid?: boolean
}

interface Form {
  schema: any
  uiSchema: any
  data: any
}

const FormBuilder = ({ schema, uiSchema, onSave, onUpdate, onPreview }: FormBuilderProps) => {
  const [data, setData] = useState<any>()
  const [isLivePreview, setLivePreview] = useState<boolean>(false)

  const [schemaWrapper, setSchemaWrapper] = useState<SchemaWrapper>({
    json: schema,
    isValid: false,
  })
  const [uiSchemaWrapper, setUiSchemaWrapper] = useState<SchemaWrapper>({
    json: uiSchema,
    isValid: false,
  })

  const [form, setForm] = useState<Form>({
    schema: null,
    uiSchema: null,
    data: null,
  })

  const [previewDrawerIsOpen, setPreviewDrawer] = useState<boolean>(false)

  const preview = () => {
    if (onPreview) onPreview()
    setForm({
      schema: schemaWrapper.json,
      uiSchema: uiSchemaWrapper.json,
      data: data,
    })
    if (!isLivePreview) setPreviewDrawer(true)
    return
  }

  const handleOnSave = useCallback(() => {
    if (!schemaWrapper.isValid || !uiSchemaWrapper.isValid) {
      toast.error('Schema or UI-Schema are invalid')
      return
    }

    if (onSave) onSave({ schema: schemaWrapper?.json, uiSchema: uiSchemaWrapper?.json })
  }, [onSave, schemaWrapper, uiSchemaWrapper])

  useEffect(() => {
    setSchemaWrapper({
      json: schema || {},
      isValid: true,
    })
    setUiSchemaWrapper({
      json: uiSchema,
      isValid: true,
    })
  }, [schema, uiSchema])

  useEffect(() => {
    if (schemaWrapper.isValid && uiSchemaWrapper.isValid && isLivePreview) {
      preview()
    }
  }, [schemaWrapper.json, uiSchemaWrapper.json, isLivePreview])

  useEffect(() => {
    if (onUpdate) onUpdate({ schema: schemaWrapper.json, uiSchema: uiSchemaWrapper.json })
  }, [schemaWrapper.json, uiSchemaWrapper.json])

  const SchemaEditor = useMemo(() => {
    return (
      <FormBuilderEditor
        json={schema}
        onChange={(value: any) => {
          if (!isEmpty(value) && !isJson(value)) {
            setSchemaWrapper(v => {
              return {
                ...schemaWrapper,
                isValid: false,
              }
            })
            return
          }

          setSchemaWrapper({
            json: isEmpty(value) ? null : JSON.parse(value),
            isValid: true,
          })
        }}
      />
    )
  }, [schema])

  const UiSchemaEditor = useMemo(() => {
    return (
      <FormBuilderEditor
        json={uiSchema}
        onChange={(value: any) => {
          if (!isEmpty(value) && !isJson(value)) {
            setUiSchemaWrapper(v => {
              return {
                ...uiSchemaWrapper,
                isValid: false,
              }
            })
            return
          }

          setUiSchemaWrapper({
            json: isEmpty(value) ? null : JSON.parse(value),
            isValid: true,
          })
        }}
      />
    )
  }, [uiSchema])

  const PreviewForm = useMemo(() => {
    return (
      <FormBuilderPreview
        data={form.data}
        schema={form.schema}
        uiSchema={form.uiSchema}
        onDataChange={(value: any) => {
          setData(isEmpty(value) ? null : JSON.parse(value))
        }}
        onFormChange={(value: any) => {
          setForm(s => {
            return {
              ...s,
              data: value.data,
            }
          })
        }}
      />
    )
  }, [form])

  return (
    <Layout.Stack className="w-full">
      <Drawer open={previewDrawerIsOpen} onClose={() => setPreviewDrawer(false)}>
        <Drawer.Body>{PreviewForm}</Drawer.Body>
      </Drawer>

      {/* Parameters */}
      <Layout.Switcher className="p-2">
        <Layout.Group align="center">
          <Label>Live preview?</Label>
          <Switch active={isLivePreview} onClick={() => setLivePreview(!isLivePreview)} />
        </Layout.Group>
        <Layout.Group justify="flex-end">
          <Layout.Group align="center">
            {onSave && (
              <Button onClick={handleOnSave} variant="primary">
                Save
              </Button>
            )}

            <Button onClick={() => preview()} disabled={isLivePreview}>
              Preview
            </Button>
          </Layout.Group>
        </Layout.Group>
      </Layout.Switcher>

      <Layout.Switcher>
        {/* Schema & UiSchema editors */}
        <div
          style={{
            maxWidth: isLivePreview ? window.innerWidth * 0.35 : window.innerWidth,
          }}
        >
          <Tabs>
            <Tabs.Items>
              <Tabs.Item name="schema" default>
                <Layout.Group>Schema</Layout.Group>
              </Tabs.Item>
              <Tabs.Item name="ui-schema">
                <Layout.Group>UI Schema</Layout.Group>
              </Tabs.Item>
            </Tabs.Items>

            <Tabs.Panels>
              <Tabs.Panel name="schema" style={{ ...CUSTOM_TAB_PANEL_STYLE }}>
                {SchemaEditor}
              </Tabs.Panel>
              <Tabs.Panel name="ui-schema" style={{ ...CUSTOM_TAB_PANEL_STYLE }}>
                {UiSchemaEditor}
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        </div>

        {isLivePreview && PreviewForm}
      </Layout.Switcher>
    </Layout.Stack>
  )
}

export default FormBuilder
