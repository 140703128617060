/* eslint-disable no-shadow */
import { ExpectedReplyMessage } from 'common/types/kraken-core/ExpectedReplyMessage'
import SearchResponse from 'common/types/SearchResponse'
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query'
import {
  getExpectedReplyMessage,
  searchExpectedReplyMessages,
  updateStatusExpectedReplyMessage,
} from 'services/kraken-core/expected_reply_messages/expectedReplyMessages.service'
import {
  QUERIES,
  useGetExpectedReplyMessageParams,
  useSearchExpectedReplyMessageParams,
  useUpdateStatusExpectedReplyMessageParams,
} from './types'

export const useSearchExpectedReplyMessages = (
  params: useSearchExpectedReplyMessageParams
): UseQueryResult<SearchResponse<ExpectedReplyMessage[]>> => {
  const queryFn = async ({
    page,
    pageSize,
    partnerName,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    status,
  }: useSearchExpectedReplyMessageParams) => {
    const { response } = await searchExpectedReplyMessages({
      page: page || 1,
      page_size: pageSize || 10,
      partner_name: partnerName,
      created_at__gte: createdAtStart,
      created_at__lte: createdAtEnd,
      updated_at__gte: updatedAtStart,
      updated_at__lte: updatedAtEnd,
      status,
    })

    return response?.data || {}
  }

  const query = useQuery([QUERIES.SEARCH_EXPECTED_REPLY_MESSAGES, params], () => queryFn(params), {
    keepPreviousData: true,
  })

  return query
}

export const useGetExpectedReplyMessage = (
  params: useGetExpectedReplyMessageParams
): UseQueryResult<ExpectedReplyMessage> => {
  const queryFn = async ({ id }: useGetExpectedReplyMessageParams) => {
    const { response } = await getExpectedReplyMessage(id)

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_EXPECTED_REPLY_MESSAGE, params], () => queryFn(params))

  return query
}

export const useUpdateStatusExpectedReplyMessage = (
  params: useUpdateStatusExpectedReplyMessageParams
): UseMutationResult<any, unknown, useUpdateStatusExpectedReplyMessageParams, unknown> => {
  const mutation = useMutation(async (params: useUpdateStatusExpectedReplyMessageParams) => {
    const { response } = await updateStatusExpectedReplyMessage({
      id: params.id,
      status: params.status,
    })
    return response?.data
  })

  return mutation
}
