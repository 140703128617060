import { Layout, Link, Text } from '@loadsmart/loadsmart-ui'
import { ReactComponent as NotFoundIllustration } from './not-found-art.svg'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

function NotFound() {
  useTabTitle('Sorry... not Found')
  return (
    <Layout.Group
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '70vh',
        zoom: '130%',
      }}
    >
      <Layout.Stack>
        <NotFoundIllustration />
        <Text className="mt-4 text-center" variant="heading-sm-bold">
          Oops, this page was not found!
        </Text>
        <Text className="mt-1 text-center" variant="body">
          We&apos;re sorry, the page doesn&apos;t exist.
        </Text>
        <Link href="/">Go back to home</Link>
      </Layout.Stack>
    </Layout.Group>
  )
}

export default NotFound
