/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import {
  ConversionCode,
  ConversionCodeCategoryDirection,
} from 'common/types/kraken-core/ConversionCode'
import {
  NotificationSetting,
  TradingPartner,
  TradingPartnerContact,
  TradingPartnerInstructions,
} from 'common/types/kraken-core/TradingPartner'
import { SearchTradingPartnerHistoryPayload } from 'services/kraken-core/trading_partner/tradingPartner.service'

export enum QUERIES {
  SEARCH_TRADING_PARTNER = 'trading_partner/search',
  GET_TRADING_PARTNER_HISTORY = 'trading_partner_history/get',
  GET_TRADING_PARTNER_INSTRUCTIONS = 'trading_partner_instructions/get',
  GET_TRADING_PARTNER = 'trading_partner/get',
  GET_TRADING_PARTNER_CODES = 'trading_partner/codes/get',
  GET_TRADING_PARTNER_CODE_TYPES = 'trading_partner/code/types/get',
  SEARCH_CONVERSION_CODES_CATEGORIES = 'conversion_codes/search',
  SEARCH_CONVERSION_CODES = 'conversion_codes/get',
  GET_CONVERSION_CODE_HISTORY = 'conversion_codes_history/get',
  UPDATE_CONVERSION_CODE = 'conversion_codes/update',
  SEARCH_NOTIFICATION_TYPES = 'notification_types/search',
  SEARCH_CONTACTS = 'contacts/search',
  SEARCH_CONTACTS_HISTORY = 'contacts_history/get',
  SEARCH_CONTACTS_TYPES = 'contacts_types/search',
}

export interface useSearchTradingPartnersParams {
  page?: number
  name?: string
}

export interface useSearchTradingPartnerContactsParams {
  page: number
  id?: string
}

export interface useSearchTradingPartnerContactHistoryparams {
  id?: string
  contact?: Partial<TradingPartnerContact>
}

export interface useSearchConversionCodesCategoryParams {
  page?: number
  description?: string
}

export interface useSearchConversionCodesParams {
  page?: number
  query?: {
    target?: string | null
    source?: string | null
    source_code?: string | null
    target_code?: string | null
    use_when?: string | null
    target_value?: string
    source_value?: string
    direction?: ConversionCodeCategoryDirection
  }
}

export interface DetailTabsProps {
  tradingPartner: TradingPartner
}

export interface ConversionCodesProps {
  tradingPartner: TradingPartner
}

export interface TranslationSettingsProps {
  tradingPartner: TradingPartner
  tradingPartnerHistory?: SearchTradingPartnerHistoryPayload[]
}

export interface InstructionsProps {
  instructions: TradingPartnerInstructions
}

export interface NotificationTabProps {
  tradingPartner: TradingPartner
  notificationSettings?: NotificationSetting[]
  tradingPartnerHistory?: SearchTradingPartnerHistoryPayload[]
}

export interface ContactsTabProps {
  tradingPartner: TradingPartner
}

export interface UpdateConversionCodesProps {
  open: boolean
  onClose: () => any
  onSuccess: () => any
  conversionCode?: ConversionCode
  direction: ConversionCodeCategoryDirection
}
