import { PaginatedRequest } from 'common/types/kraken-core/Pagination'
import { SearchPartnershipPageRequest } from 'services/kraken-core/partnership/partnership.service'

export enum QUERIES {
  SEARCH_PARTNERSHIP = 'partnership/search',
  GET = 'partnership/get',
  DELETE = 'partnership/delete',
  CREATE = 'partnership/create',
  UPDATE = 'partnership/uodate',
}
export interface useSearchPartnershipParams
  extends PaginatedRequest,
    SearchPartnershipPageRequest {}

export interface useGetPartnershipParams {
  id: number
}
