import { Button, Layout, LoadingDots, Modal, Text } from '@loadsmart/loadsmart-ui'
import ReactDOM from 'react-dom'
import Logo from 'atoms/Logo/Logo'
import Environment from 'common/types/Environment'
import { useCallback, useState } from 'react'
import getEnv from '../../common/helpers/getEnv'
import { authManager } from 'common/authManager'

const LoginModal = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const env = getEnv()

  const login = () => {
    authManager.signInWithRedirect()
    setLoading(true)
  }

  const root = document.getElementById('login-modal-root')
  if (!root) return null
  if (env === Environment.Test || env === Environment.Dev) return null
  if (authManager.isAuthenticated()) {
    return null
  }

  return ReactDOM.createPortal(
    <Modal id="login-modal" open>
      <Layout.Stack space="xl" align="center">
        <Logo />
        <>
          <Text variant="heading-sm">You&lsquo;ve been disconected from Kraken.</Text>

          {isLoading && <LoadingDots />}
          {!isLoading && !authManager.isAuthenticated() && (
            <Button onClick={login}>Login with Okta</Button>
          )}
        </>
      </Layout.Stack>
    </Modal>,
    root
  )
}

export default LoginModal
