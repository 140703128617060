import { Select } from '@loadsmart/loadsmart-ui'
import { SelectDatasourceFunction } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { Partnership } from 'common/types/kraken-core/Partnership'
import isEmpty from 'lodash.isempty'
import { onSearchPartnership } from 'common/components/datasources/datasource'
import { useEffect, useState } from 'react'
import { getPartnership } from 'services/kraken-core/partnership/partnership.service'

export interface GatewaySettingsSelectorProps {
  selected?: {
    label?: string
    id?: string
  }
  onSelect: (selected: Partnership) => void
  dataSources?: SelectDatasourceFunction<any>[]
}

const DATASOURCES = {
  PARTNERSHIP: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (entity: Partnership) => entity.id || '',
        getLabel: (entity: Partnership) => `${entity.party.name} - ${entity.counterparty.name}`,
      },
      fetch: onSearchPartnership,
    }),
  ],
}

export default function PartnershipSelector(props: GatewaySettingsSelectorProps) {
  const [partnershipLabel, setPartnershipLabel] = useState<string | undefined>(
    props.selected?.label
  )

  const partnershipAsString = (partnership: Partnership) => {
    return `${partnership.party.name} - ${partnership.counterparty.name}`
  }

  useEffect(() => {
    async function get() {
      if (props.selected?.id) {
        const response = await getPartnership(Number(props.selected.id))

        if (response.error) {
          setPartnershipLabel('Unknown')
        }
        const partnership: Partnership = response.response?.data
        setPartnershipLabel(partnershipAsString(partnership))
      }
    }

    get()
  }, [props.selected?.id])

  return (
    <Select
      id="partnership"
      name="partnership"
      onChange={e => {
        const partnership = e.target.value as Partnership
        props.onSelect(e.target.value as Partnership)

        if (partnership) {
          setPartnershipLabel(partnershipAsString(partnership))
        } else {
          setPartnershipLabel(undefined)
        }
      }}
      value={
        isEmpty(partnershipLabel)
          ? null
          : ({
              label: partnershipLabel,
              value: props.selected?.id,
            } as Selectable)
      }
      datasources={props.dataSources || DATASOURCES.PARTNERSHIP}
      placeholder="Select a partnership"
    />
  )
}
