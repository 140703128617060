import { Select } from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { RouteRead } from 'common/types/kraken-core/Routes'
import isEmpty from 'lodash.isempty'
import { onSearchRoute } from 'common/components/datasources/datasource'
import { useEffect, useMemo, useState } from 'react'
import { getRoute } from 'services/kraken-core/routes/routes.service'

export interface RouteSelectorProps {
  selected?: {
    label?: string
    id?: string
  }
  onSelect: (selected: RouteRead) => void
}

const DATASOURCES = {
  ROUTE: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (entity: RouteRead) => entity.id || '',
        getLabel: (entity: RouteRead) => entity.code || '',
      },
      fetch: onSearchRoute,
    }),
  ],
}

export default function RouteSelector(props: RouteSelectorProps) {
  const [selectedDataDetails, setSelectedDataDetails] = useState<RouteRead>()
  const [selected, setSelected] = useState<any>({
    value: props.selected?.id,
    label: props.selected?.label,
  })

  useEffect(() => {
    async function get() {
      if (!props.selected?.id) return
      const d = await getRoute({ id: props.selected.id })
      if (!d.error) {
        setSelectedDataDetails(d.response?.data)
      }
    }
    get()
  }, [props.selected])

  useEffect(() => {
    if (selectedDataDetails) {
      setSelected((c: any) => ({
        ...c,
        value: selectedDataDetails.id,
        label: selectedDataDetails.code,
      }))
    }
  }, [selectedDataDetails])

  const MemoizedSelector = useMemo(() => {
    const isValueNotFullyLoaded = selected.label === selected.value || isEmpty(selected.label)
    return (
      <Select
        name="select_route"
        onChange={e => props.onSelect(e.target.value as RouteRead)}
        value={isValueNotFullyLoaded ? null : ({ ...selected } as Selectable)}
        datasources={DATASOURCES.ROUTE}
        placeholder="Select a Route"
      />
    )
  }, [selected])

  return <>{MemoizedSelector}</>
}
