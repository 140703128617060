import { Layout } from '@loadsmart/loadsmart-ui'
import FileViewer from 'molecules/FileViewer/FileViewer'

export interface RouteStepsRawProps {
  steps: Array<object>
  onChange?: (changed: string) => void
}

const RouteStepsRaw = (props: RouteStepsRawProps) => {
  const { steps, onChange } = props

  return (
    <Layout.Box padding="none">
      <FileViewer
        options={{
          heigth: 500,
        }}
        content={JSON.stringify(steps, null, 2)}
        contentType="application/json"
        onChange={(changed: any) => {
          if (onChange) onChange(changed)
        }}
      />
    </Layout.Box>
  )
}

export default RouteStepsRaw
