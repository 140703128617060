export const TestCaseInputConversionCodeSchema = {
  type: 'array',
  items: {
    $ref: '#/definitions/ConversionCode',
  },
  definitions: {
    ConversionCode: {
      type: 'object',
      properties: {
        source_code: {
          type: 'string',
        },
        target_code: {
          type: 'string',
        },
        source_value: {
          type: 'string',
        },
        target_value: {
          type: 'string',
        },
      },
    },
  },
}

export const TestCaseInputLookupTableEntriesSchema = {
  type: 'array',
  items: {
    $ref: '#/definitions/LookupTableEntries',
  },
  definitions: {
    LookupTableEntries: {
      type: 'object',
      properties: {
        name: { title: 'Entry name', type: 'string' },
        key: { title: 'Entry key', type: 'string' },
        values: { title: 'Dictionary of values', type: 'object' },
      },
      required: ['key', 'name', 'values'],
    },
  },
}

export const TestCaseInputMessageReceiptsSchema = {
  type: 'array',
  items: {
    $ref: '#/definitions/MessageReceipts',
  },
  definitions: {
    MessageReceipts: {
      type: 'object',
      properties: {
        receipt_type: { title: 'Receipt Type', type: 'string' },
        status_code: { title: 'Status Code', type: 'string' },
        headers: { title: 'Headers', type: 'object' },
        body: {
          title: 'Body',
          type: 'object',
          properties: {
            name: {
              type: 'string',
            },
            encoded_data_url: {
              type: 'string',
            },
            content_type: {
              type: 'string',
            },
          },
        },
      },
      required: ['receipt_type'],
    },
  },
}

export const TestCaseInputMessageReceiptsUiSchema = {
  type: 'Control',
  scope: '#/',
  options: {
    detail: {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/receipt_type',
        },
        {
          type: 'Control',
          scope: '#/properties/status_code',
        },
        {
          type: 'FileField',
          scope: '#/properties/body',
        },
      ],
    },
  },
}
