import { Layout, Tag } from '@loadsmart/loadsmart-ui'
import { Tooltip } from '@mui/material'
import { IntegrationTemplate } from 'common/types/kraken-core/IntegrationTemplate'

export interface TemplateOwnershipTagsProps {
  template: IntegrationTemplate
}

export default function TemplateOwnershipTags(props: TemplateOwnershipTagsProps) {
  if (!props.template) {
    return null
  }

  const template = props.template

  return (
    <Layout.Group align="center">
      {template?.party ? (
        <Tooltip title="Party">
          <div>
            <Tag variant="accent">{template.party.name}</Tag>
          </div>
        </Tooltip>
      ) : null}
      {template.counterparty ? (
        <Tooltip title="Counterparty">
          <div>
            <Tag variant="accent">{template.counterparty.name}</Tag>
          </div>
        </Tooltip>
      ) : null}
    </Layout.Group>
  )
}
