/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum QUERIES {
  SEARCH_CONNECTION_SETTINGS = 'connection_settings/search',
  GET_CONNECTION_SETTINGS = 'connection_settings/get',
  GET_CONNECTION_SETTINGS_HISTORY = 'connection_settings_history/get',
  GET_CONNECTION_SETTINGS_SCHEMA = 'connection_settings/protocols/schema/get',
  SEARCH_PROTOCOLS = 'protocols/search',
  GET_TRADING_PARTNER = 'trading_partner/get',
  SEARCH_RELATED_GATEWAY = 'connection_settings/related/gateways/get',
}

export type ImportStep = 'upload-step' | 'verify-step' | 'finish-step'

export type ImportSteps = {
  complete: boolean
  id: ImportStep
  label: string
}[]

export interface useSearchConnectionSettingsParams {
  page?: number
}

export interface useDeleteConnectionSettingsParams {
  page?: number
}

export interface useGetConnectionSettingsParams {
  id?: string
}

export interface useGetSchemaParams {
  protocol: string
}

export interface ImportModalProps {
  onClose: () => void
  open: boolean
  mode: 'import' | 'create' | null
}

export interface useSearchConnectionRelatedGatewayParams {
  page?: number
  connection?: string
}
