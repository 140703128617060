import TopNavigationContext, {
  TopNavigationContextState,
} from 'app/TopNavigationHeader/TopNavigationContext'
import { useContext, useEffect, useMemo } from 'react'

const useTopNavigationContext = (state?: TopNavigationContextState) => {
  const context = useContext(TopNavigationContext)

  useEffect(() => {
    if (state) context.updateState(state)
    else
      context.updateState({
        children: <></>,
      })
  }, [])
  return context
}

export default useTopNavigationContext
