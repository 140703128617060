import { Label, Layout, Select, Switch, Table, Text } from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { Divider } from '@mui/material'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMMSS } from 'common/Date.helpers'
import { onSearchTradingPartners } from 'common/helpers/kraken-core/hooks'
import EventLike from 'common/types/EventLike'
import { Partnership, PartnershipDetail } from 'common/types/kraken-core/Partnership'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { ChangeEvent } from 'react'

export interface ParntershipFormProps {
  partnership: PartnershipDetail
  onUpdate?: (partnership: PartnershipDetail) => void
}

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
}

export default function ParntershipForm({ partnership, onUpdate }: ParntershipFormProps) {
  const handleFieldUpdate = (event: EventLike<any>) => {
    const fieldName = event.target.name as string
    let value = event.target.value

    if (fieldName === 'is_active') {
      value = event.target.checked
    }

    if (onUpdate) {
      console.log(fieldName, value)
      onUpdate({
        ...partnership,
        [fieldName]: value,
      })
    }
  }

  return (
    <Layout.Stack space="s">
      {partnership.id ? (
        <>
          <Layout.Group justify="space-between" align="flex-start">
            <Layout.Stack space="s">
              <Text variant="caption-bold">ID</Text>
              <Text variant="caption">{partnership.id}</Text>
            </Layout.Stack>
            <Layout.Stack space="s">
              <Text variant="caption-bold">UUID</Text>
              <Text variant="caption">{partnership.uuid}</Text>
            </Layout.Stack>
            <Layout.Stack space="s" aria-label="created_at">
              <Text variant="caption-bold">Created at</Text>
              <Text variant="caption">
                {LSDate(partnership.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMMSS)}
              </Text>
            </Layout.Stack>
            <Layout.Stack space="s" aria-label="updated_at">
              <Text variant="caption-bold">Updated at</Text>
              <Text variant="caption">
                {LSDate(partnership.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMMSS)}
              </Text>
            </Layout.Stack>
            <Layout.Stack space="s">
              <Text variant="caption-bold">Active?</Text>
              <Switch
                name="is_active"
                active={partnership.is_active}
                onToggle={handleFieldUpdate}
                onChange={handleFieldUpdate}
              />
            </Layout.Stack>
          </Layout.Group>
          <Divider className="p-2" />
        </>
      ) : null}

      <Layout.Switcher className="w-full">
        <Layout.Stack space="s">
          <Label>Party</Label>
          <Select
            name="party"
            id="select-trading-partner"
            onChange={e => handleFieldUpdate(e)}
            value={
              partnership.party
                ? ({
                    value: partnership.party.id,
                    label: partnership.party.name,
                  } as Selectable)
                : undefined
            }
            datasources={DATASOURCES.TRADING_PARTNER}
          />
        </Layout.Stack>
        <Layout.Stack space="s">
          <Label>Counter Party</Label>
          <Select
            name="counterparty"
            id="select-trading-partner"
            onChange={e => handleFieldUpdate(e)}
            value={
              partnership.counterparty
                ? ({
                    value: partnership.counterparty.id,
                    label: partnership.counterparty.name,
                  } as Selectable)
                : undefined
            }
            datasources={DATASOURCES.TRADING_PARTNER}
          />
        </Layout.Stack>
      </Layout.Switcher>
    </Layout.Stack>
  )
}
