/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react'
import {
  Layout,
  Modal,
  Table,
  TextField,
  Text,
  Dialog,
  IconButton,
  Select,
  Button,
  Tabs,
  Accordion,
  Banner,
  Drawer,
} from '@loadsmart/loadsmart-ui'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import Icon from 'atoms/Icon'
import { toast } from 'atoms/Toast'
import { NotificationSetting, NotificationType } from 'common/types/kraken-core/TradingPartner'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import {
  useCreateNotificationSetting,
  useDeleteNotificationSetting,
  useSearchNotificationTypes,
  useUpdateNotificationSetting,
} from '../api'
import { NotificationTabProps } from '../types'

const NotificationsTab = ({
  tradingPartner,
  notificationSettings,
  tradingPartnerHistory,
}: NotificationTabProps) => {
  const [keyToEdit, setKeyToEdit] = useState<string | null>(null)
  const [modeModal, setModeModal] = useState<'edit' | 'create' | null>(null)
  const [modalData, setModalData] = useState<Partial<NotificationSetting> | null>(null)
  const [notificationToDelete, setNotificationToDelete] = useState<NotificationSetting | null>(null)

  const { data: notificationTypes } = useSearchNotificationTypes()
  const {
    mutate: deleteNotificationSetting,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    error: deletedError,
    reset: deletedReset,
  } = useDeleteNotificationSetting()
  const {
    mutate: createNotificationSetting,
    isLoading: isCreating,
    isSuccess: isCreated,
    error: createdError,
    reset: createdReset,
  } = useCreateNotificationSetting()
  const {
    mutate: updateNotificationSetting,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    error: updatedError,
    reset: updatedReset,
  } = useUpdateNotificationSetting()

  const handleCloseModal = () => {
    setModalData(null)
    setModeModal(null)
  }

  useEffect(() => {
    if (deletedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isDeleted) {
      toast.success('Deleted with success')
    }

    setTimeout(() => {
      deletedReset()
      setNotificationToDelete(null)
    }, 0)
  }, [isDeleted, deletedError])

  useEffect(() => {
    if (createdError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isCreated) {
      toast.success('Created with success')
    }

    setTimeout(() => {
      createdReset()
      handleCloseModal()
    }, 0)
  }, [isCreated, createdError])

  useEffect(() => {
    if (updatedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isUpdated) {
      toast.success('Updated with success')
    }

    setTimeout(() => {
      updatedReset()
      handleCloseModal()
    }, 0)
  }, [isUpdated, updatedError])

  const memoizedHistory = useMemo(
    () =>
      tradingPartnerHistory
        ?.map(history => ({
          notification_settings: history.notification_settings || [],
          history_id: history.history_id,
          history_user: history.history_user,
          history_date: history.history_date,
          history_change_reason: history.history_change_reason,
        }))
        ?.filter((history, index, array) => {
          const historyNotificationSettings = JSON.stringify(history.notification_settings)
          const notificationsSettings = JSON.stringify(array[index - 1]?.notification_settings)
          return index === 0 || historyNotificationSettings !== notificationsSettings
        }),
    [tradingPartnerHistory]
  )

  const handleCloseDeleteConfirmation = () => {
    setNotificationToDelete(null)
  }

  const handleOpenModal = (
    mode: 'edit' | 'create' | null,
    data: Partial<NotificationSetting> | null
  ) => {
    setModalData(data)
    setModeModal(mode)

    if (mode === 'edit') {
      setKeyToEdit(JSON.stringify(data))
    }
  }

  const handleChangeModalData = (key: any, value: any) => {
    setModalData(prev =>
      prev
        ? {
            ...prev,
            service: {
              ...(prev.service ?? {}),
              [key]: value,
            },
          }
        : {}
    )
  }

  const handleUpdate = () => {
    if (!keyToEdit) return
    if (!modalData) return

    const newNotificationSettings =
      tradingPartner.notification_settings?.map(ns => {
        if (JSON.stringify(ns) !== keyToEdit) {
          return ns
        }

        return modalData
      }) ?? []

    updateNotificationSetting({
      tradingPartner,
      notificationSettings: newNotificationSettings,
    })
  }

  const parseHistory = (history?: any): string => {
    if (!history) return ''

    const { history_id, history_date, history_change_reason, history_user, ...rest } = history

    return JSON.stringify(rest, null, 2)
  }

  const COLUMNS = [
    {
      Header: 'Service',
      Cell(row: NotificationSetting) {
        return row.service.type
      },
    },
    {
      Header: 'When',
      Cell(row: NotificationSetting, types?: NotificationType[]) {
        return (
          types?.find(nTypes => nTypes.when === row.service.when)?.description || row.service.when
        )
      },
    },
    {
      Header: 'Filter',
      Cell(row: NotificationSetting) {
        return <div className="truncate w-96">{JSON.stringify(row.service.filter)}</div>
      },
    },
    {
      Header: 'Contact',
      alignment: 'left',
      Cell(row: NotificationSetting) {
        return row.service.address
      },
    },
    {
      Header: 'Actions',
      alignment: 'right',
      Cell(row: NotificationSetting) {
        return (
          <IconButton
            onClick={e => {
              setNotificationToDelete(row)
              e.stopPropagation()
            }}
            scale="large"
          >
            <Icon name="remove" />
          </IconButton>
        )
      },
    },
  ]

  return (
    <>
      <Dialog
        open={!!notificationToDelete}
        onOverlayClick={handleCloseDeleteConfirmation}
        scale="small"
      >
        <Dialog.Header>Delete</Dialog.Header>
        <Dialog.Body>Are you sure you want to delete this notification setting?</Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isDeleting}
          onConfirm={() =>
            notificationToDelete &&
            deleteNotificationSetting({
              notificationSetting: notificationToDelete,
              tradingPartner,
            })
          }
        />
        <Dialog.ActionCancel onCancel={handleCloseDeleteConfirmation} />
      </Dialog>

      <Drawer open={!!modeModal} className="w-full">
        <Drawer.Header>
          <Layout.Stack className="flex-1 h-full overflow-scroll">
            <Layout.Group>
              <Layout.Stack className="flex-1" space="none">
                <Text variant="heading-sm">Type</Text>
                <Select
                  id="type"
                  name="type"
                  onChange={e => handleChangeModalData('type', (e.target.value as any)?.value)}
                  options={[
                    {
                      value: 'email',
                      label: 'email',
                    },
                    {
                      value: 'slack',
                      label: 'slack',
                    },
                  ]}
                  value={
                    {
                      value: modalData?.service?.type || '',
                      label: modalData?.service?.type || '',
                    } as Selectable
                  }
                />
              </Layout.Stack>

              <Layout.Stack className="flex-1" space="none">
                <Text variant="heading-sm">When</Text>
                {notificationTypes && (
                  <Select
                    id="when"
                    name="when"
                    onChange={e => handleChangeModalData('when', (e.target.value as any)?.value)}
                    options={
                      notificationTypes?.map(type => ({
                        label: type.description || '',
                        value: type.when || '',
                      })) || []
                    }
                    value={
                      {
                        value: modalData?.service?.when || '',
                        label:
                          notificationTypes?.find(p => p.when === modalData?.service?.when)
                            ?.description ?? '',
                      } as Selectable
                    }
                  />
                )}
              </Layout.Stack>
            </Layout.Group>

            <Layout.Group>
              <Layout.Stack className="flex-1" space="none">
                <Text variant="heading-sm">Contact</Text>
                <TextField
                  value={modalData?.service?.address ?? ''}
                  onChange={e => handleChangeModalData('address', e.target.value)}
                />
              </Layout.Stack>
            </Layout.Group>
          </Layout.Stack>
        </Drawer.Header>

        <Drawer.Body className="h-full">
          <Tabs className="flex flex-col" direction="horizontal">
            <Tabs.Items>
              <Tabs.Item name="template" default>
                Template
              </Tabs.Item>
              <Tabs.Item name="renderer" default>
                Renderer
              </Tabs.Item>
              <Tabs.Item name="filter" default>
                Filter
              </Tabs.Item>
            </Tabs.Items>

            <Tabs.Panels>
              <Tabs.Panel name="template" className="h-full">
                <Layout.Stack>
                  <FileViewer
                    options={{
                      heigth: 700,
                      theme: FileViewTheme.DEFAULT,
                    }}
                    content={modalData?.service?.template || ''}
                    contentType="jinja"
                    showOptions={false}
                    onChange={value => handleChangeModalData('template', value)}
                  />
                </Layout.Stack>
              </Tabs.Panel>
              <Tabs.Panel name="renderer">
                <Layout.Stack>
                  <FileViewer
                    options={{
                      heigth: 700,
                      theme: FileViewTheme.DEFAULT,
                    }}
                    content={JSON.stringify(modalData?.service?.template_renderer, null, 2)}
                    contentType="application/json"
                    showOptions={false}
                    onChange={value =>
                      handleChangeModalData('template_renderer', JSON.parse(value || '{}'))
                    }
                  />
                </Layout.Stack>
              </Tabs.Panel>
              <Tabs.Panel name="filter">
                <Layout.Stack>
                  <FileViewer
                    options={{
                      heigth: 700,
                      theme: FileViewTheme.DEFAULT,
                    }}
                    content={JSON.stringify(modalData?.service?.filter, null, 2)}
                    contentType="application/json"
                    showOptions={false}
                    onChange={value => handleChangeModalData('filter', JSON.parse(value || '{}'))}
                  />
                </Layout.Stack>
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>

          <Layout.Stack>
            <Layout.Stack className="h-full overflow-scroll" />
          </Layout.Stack>
        </Drawer.Body>

        <Drawer.Footer>
          <Layout.Group>
            <Layout.Stack className="flex-1">
              <Button onClick={handleCloseModal}>Cancel</Button>
            </Layout.Stack>

            <Layout.Stack className="flex-1">
              {modeModal === 'create' && (
                <Button
                  disabled={isCreating}
                  onClick={() =>
                    modalData &&
                    createNotificationSetting({
                      notificationSetting: modalData,
                      tradingPartner,
                    })
                  }
                  variant="primary"
                >
                  Create
                </Button>
              )}
              {modeModal === 'edit' && (
                <Button disabled={isUpdating} onClick={handleUpdate} variant="primary">
                  Edit
                </Button>
              )}
            </Layout.Stack>
          </Layout.Group>
        </Drawer.Footer>
      </Drawer>

      <Modal open={false} onOverlayClick={handleCloseModal} />

      <Layout.Stack space="xl">
        <Tabs className="flex flex-col" direction="horizontal">
          <Tabs.Items className="mt-2">
            <Tabs.Item default name="list">
              List
            </Tabs.Item>

            <Tabs.Item name="history">History</Tabs.Item>
          </Tabs.Items>

          <Tabs.Panels className="notification-tabs">
            <Tabs.Panel name="list" className="p-2">
              <Table
                className="w-full text-nowrap notification-list"
                data-testid="connection-settings-list"
              >
                <Table.Caption>
                  <Layout.Box padding="none" className="justify-end flex flex-1">
                    <Button
                      leading={<Icon name="add" />}
                      onClick={() => handleOpenModal('create', {})}
                    >
                      Add notification setting
                    </Button>
                  </Layout.Box>
                </Table.Caption>
                <Table.Head>
                  <Table.Row>
                    {COLUMNS.map(column => (
                      <Table.Cell key={column.Header} alignment={column.alignment as any}>
                        {column.Header}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {notificationSettings?.map(row => (
                    <Table.Row
                      className="cursor-pointer"
                      key={`${JSON.stringify(row.service)}`}
                      onClick={() => handleOpenModal('edit', row)}
                    >
                      {COLUMNS.map(cell => (
                        <Table.Cell
                          className="text-sm"
                          key={cell.Header}
                          alignment={cell.alignment as any}
                        >
                          {cell.Cell(row, notificationTypes)}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Tabs.Panel>

            <Tabs.Panel name="history">
              {memoizedHistory &&
                memoizedHistory.map((history, index) => (
                  <Accordion key={history.history_id}>
                    <Accordion.Toggle>
                      <Layout.Group>
                        {history.history_id}
                        {' - '}
                        {LSDate(history.history_date)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                        {history.history_user && ` by ${history.history_user}`}
                      </Layout.Group>
                    </Accordion.Toggle>

                    <Accordion.Body>
                      {history.history_change_reason && (
                        <Banner
                          title={history.history_change_reason}
                          variant="warning"
                          dismissible={false}
                          className="mt-4 mb-4"
                        />
                      )}

                      <ReactDiffViewer
                        hideLineNumbers
                        oldValue={parseHistory(memoizedHistory?.[index + 1])}
                        newValue={parseHistory(history)}
                        compareMethod={DiffMethod.TRIMMED_LINES}
                        styles={{
                          line: {
                            fontSize: 12,
                          },
                        }}
                      />
                    </Accordion.Body>
                  </Accordion>
                ))}
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Layout.Stack>
    </>
  )
}

export default NotificationsTab
