import { Layout, TextField } from '@loadsmart/loadsmart-ui'
import Icon from 'atoms/Icon'
import { DATE_FORMAT_MMDDYYYYHHMMSS } from 'common/Date.helpers'
import moment, { Moment } from 'moment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import styled from 'styled-components'
import { useRef, useState } from 'react'
import isEmpty from 'lodash.isempty'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

export interface DatetimeRange {
  start?: Moment | string
  end?: Moment | string
}

export interface RangeDatetimePickerProps {
  onStartChange: (value: Moment | string | undefined) => void
  onEndChange: (value: Moment | string | undefined) => void
  value?: DatetimeRange
  format?: string
}

const CustomTextField = styled(TextField)``

const RangeDatetimePicker = ({
  onStartChange,
  onEndChange,
  value,
  format,
}: RangeDatetimePickerProps) => {
  const startInputRef = useRef<any>()
  const endInputRef = useRef<any>()

  const [startCalendarOpen, setStartCalendarOpen] = useState(false)
  const [endCalendarOpen, setEndCalendarOpen] = useState(false)

  const toggleStartCalendar = () => {
    setStartCalendarOpen(c => !c)
  }

  const toggleEndCalendar = () => {
    setEndCalendarOpen(c => !c)
  }

  const handleStartChange = (value: string | Moment | undefined | null) => {
    if (!value) {
      onStartChange(undefined)
      return
    }

    const parsed = moment(value)
    if (parsed && parsed.isValid()) {
      onStartChange(parsed.toISOString())
    }
  }

  const handleEndChange = (value: string | Moment | undefined | null) => {
    if (!value) {
      onEndChange(undefined)
      return
    }

    const parsed = moment(value)
    if (parsed && parsed.isValid()) {
      onEndChange(parsed.toISOString())
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Layout.Group align="center" space="s">
        <DateTimePicker
          value={!isEmpty(value?.start) ? value?.start : null}
          open={startCalendarOpen}
          onChange={e => {
            handleStartChange(e)
          }}
          onClose={() => {
            setStartCalendarOpen(false)
          }}
          PopperProps={{ anchorEl: startInputRef.current }}
          renderInput={params => (
            <Layout.Group>
              <CustomTextField
                ref={startInputRef}
                {...params.inputProps}
                placeholder="From"
                trailing={
                  <div className="cursor-pointer" onClick={() => toggleStartCalendar()}>
                    <Icon name="calendar" />
                  </div>
                }
                size={18}
              />
            </Layout.Group>
          )}
          inputFormat={format || DATE_FORMAT_MMDDYYYYHHMMSS}
        />
        <Icon name="arrow-right" />

        <DateTimePicker
          value={!isEmpty(value?.end) ? value?.end : null}
          open={endCalendarOpen}
          onChange={e => handleEndChange(e)}
          onClose={() => {
            setEndCalendarOpen(false)
          }}
          PopperProps={{ anchorEl: endInputRef.current }}
          renderInput={params => (
            <Layout.Group>
              <CustomTextField
                ref={endInputRef}
                {...params.inputProps}
                placeholder="To"
                trailing={
                  <div className="cursor-pointer" onClick={() => toggleEndCalendar()}>
                    <Icon name="calendar" />
                  </div>
                }
                size={18}
              />
            </Layout.Group>
          )}
          inputFormat={format || DATE_FORMAT_MMDDYYYYHHMMSS}
        />
      </Layout.Group>
    </LocalizationProvider>
  )
}

export default RangeDatetimePicker
