import commonSchema from '.'

export interface HttpAuthenticationUiSchema {
  authCustom: any
  authBasicSchema: any
  authTokenFromUrl: any
  authJwt: any
  authToken: any
}

const httpAuthenticationUiSchema: HttpAuthenticationUiSchema = {
  authCustom: {
    type: 'Group',
    label: 'Custom',
    rule: {
      effect: 'SHOW',
      condition: {
        scope: '#/properties/HTTP-Authentication/properties/method',
        schema: {
          const: 'http:auth:custom',
        },
      },
    },
    elements: [
      {
        type: 'Control',
        scope: '#/properties/HTTP-Authentication/properties/http:auth:custom/properties/repository',
      },
      {
        type: 'Group',
        rule: {
          effect: 'SHOW',
          condition: {
            scope:
              '#/properties/HTTP-Authentication/properties/http:auth:custom/properties/repository',
            schema: {
              const: 'inline',
            },
          },
        },
        elements: [
          {
            type: 'Control',
            scope: '#/properties/HTTP-Authentication/properties/http:auth:custom/properties/vault',
          },
        ],
      },
      {
        type: 'Group',
        rule: {
          effect: 'SHOW',
          condition: {
            scope:
              '#/properties/HTTP-Authentication/properties/http:auth:custom/properties/repository',
            schema: {
              const: 'vault',
            },
          },
        },
        elements: [
          {
            type: 'Control',
            scope: '#/properties/HTTP-Authentication/properties/http:auth:custom/properties/vault',
          },
        ],
      },
    ],
  },
  authBasicSchema: {
    type: 'Control',
    scope: '#/properties/HTTP-Authentication/properties/http:auth:basic',
    options: {
      detail: commonSchema.authBasicSchema,
    },
    rule: {
      effect: 'SHOW',
      condition: {
        scope: '#/properties/HTTP-Authentication/properties/method',
        schema: {
          const: 'http:auth:basic',
        },
      },
    },
  },
  authTokenFromUrl: {
    type: 'Group',
    label: 'Token From Remote URL',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/HTTP-Authentication/properties/http:auth:token-from-url',
        options: {
          detail: {
            type: 'Categorization',
            elements: [
              {
                type: 'Category',
                label: 'Credentials',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/credentials/properties/http:auth:basic',
                    options: {
                      detail: commonSchema.authBasicSchema,
                    },
                  },
                ],
              },
              {
                type: 'Category',
                label: 'Request',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/request',
                    options: {
                      detail: {
                        type: 'Group',
                        elements: [
                          {
                            type: 'Control',
                            scope: '#/properties/HTTP-Verb',
                          },
                          {
                            type: 'Control',
                            scope: '#/properties/endpoint',
                          },
                          {
                            type: 'Control',
                            scope: '#/properties/headers',
                          },
                          {
                            type: 'Control',
                            scope: '#/properties/data',
                            options: {
                              multi: true,
                            },
                          },
                          {
                            type: 'Control',
                            scope: '#/properties/use_authorization_header_with_b64_authentication',
                          },
                        ],
                      },
                    },
                  },
                ],
              },
              {
                type: 'Category',
                label: 'Response',
                elements: [
                  {
                    type: 'Group',
                    elements: [
                      {
                        type: 'Control',
                        scope: '#/properties/response',
                        options: {
                          detail: {
                            type: 'VerticalLayout',
                            elements: [
                              {
                                type: 'Advice',
                                options: {
                                  adviceType: 'info',
                                  adviceValue:
                                    'Expression is available, use like this: ' +
                                    '<b>{{ response.json().access_token }}</b>',
                                },
                              },
                              {
                                type: 'Control',
                                scope: '#/properties/data',
                                options: {
                                  multi: true,
                                },
                              },
                            ],
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                type: 'Category',
                label: 'Session',
                elements: [
                  {
                    type: 'Group',
                    elements: [
                      {
                        type: 'Control',
                        scope: '#/properties/session',
                        options: {
                          detail: {
                            type: 'VerticalLayout',
                            elements: [
                              {
                                type: 'Group',
                                label: 'Header',
                                elements: [
                                  {
                                    type: 'Advice',
                                    options: {
                                      adviceType: 'info',
                                      adviceValue: 'Header where the token will be applied',
                                    },
                                  },
                                  {
                                    type: 'Control',
                                    scope: '#/properties/header',
                                  },
                                ],
                              },
                              {
                                type: 'Group',
                                label: 'Cache',
                                elements: [
                                  {
                                    type: 'Control',
                                    scope: '#/properties/cache',
                                  },
                                ],
                              },
                              {
                                type: 'Group',
                                label: 'Options',
                                elements: [
                                  {
                                    type: 'Control',
                                    scope: '#/properties/must_inherit_request_headers',
                                  },
                                  {
                                    type: 'Control',
                                    scope:
                                      '#/properties/inherit_headers_from_token_endpoint_request',
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    rule: {
      effect: 'SHOW',
      condition: {
        scope: '#/properties/HTTP-Authentication/properties/method',
        schema: {
          const: 'http:auth:token-from-url',
        },
      },
    },
  },
  authJwt: {
    type: 'Group',
    label: 'JWT',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/HTTP-Authentication/properties/http:auth:jwt',
        options: {
          detail: {
            type: 'Categorization',
            elements: [
              {
                type: 'Category',
                label: 'Claims',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/claims',
                  },
                ],
              },
              {
                type: 'Category',
                label: 'Repository',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/repository',
                  },
                ],
              },
              {
                type: 'Category',
                label: 'Headers',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/headers',
                  },
                ],
              },
              {
                type: 'Category',
                label: 'Inline',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/inline',
                  },
                ],
              },
              {
                type: 'Category',
                label: 'Vault',
                elements: [
                  {
                    type: 'Control',
                    scope: '#/properties/vault',
                  },
                ],
              },
            ],
          },
        },
      },
    ],
    rule: {
      effect: 'SHOW',
      condition: {
        scope: '#/properties/HTTP-Authentication/properties/method',
        schema: {
          const: 'http:auth:jwt',
        },
      },
    },
  },
  authToken: {
    type: 'Group',
    label: 'Token',
    elements: [
      {
        type: 'Control',
        scope: '#/properties/HTTP-Authentication/properties/http:auth:token',
        options: {
          detail: {
            type: 'VerticalLayout',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/repository',
              },
              {
                type: 'Control',
                scope: '#/properties/keyword',
              },
            ],
          },
        },
      },
    ],
    rule: {
      effect: 'SHOW',
      condition: {
        scope: '#/properties/HTTP-Authentication/properties/method',
        schema: {
          const: 'http:auth:token',
        },
      },
    },
  },
}

export default httpAuthenticationUiSchema
