import { Tag } from '@loadsmart/loadsmart-ui'
import { CircularProgress } from '@mui/material'
import {
  LocalIntegrationTestCase,
  LocalIntegrationTestCaseExecutionType,
} from 'common/types/kraken-core/IntegrationTest'

export interface IntegrationTesstCaseStatusProps {
  test: LocalIntegrationTestCase
  loadingSize?: number
  tagSize?: 'small' | 'default' | 'large'
  onClick?: () => void
}

const IntegrationTestCaseStatus = (props: IntegrationTesstCaseStatusProps) => {
  const test = props.test
  const defaultIconSize = props.loadingSize || 20
  const didFail =
    !test.execution.isRunning &&
    test.execution &&
    (test.execution.status === LocalIntegrationTestCaseExecutionType.FAILED ||
      test.execution.status === LocalIntegrationTestCaseExecutionType.ERRROR)
  const didSucceed =
    !test.execution.isRunning &&
    test.execution &&
    test.execution.status === LocalIntegrationTestCaseExecutionType.PASSED
  return test.execution.isRunning ? (
    <CircularProgress size={defaultIconSize} />
  ) : didFail ? (
    <Tag onClick={props.onClick} variant="danger" className="cursor-pointer" size={props.tagSize}>
      Failed
    </Tag>
  ) : didSucceed ? (
    <Tag onClick={props.onClick} variant="accent" className="cursor-pointer" size={props.tagSize}>
      Passed
    </Tag>
  ) : (
    <Tag variant="default" onClick={props.onClick}>
      Not executed
    </Tag>
  )
}

export default IntegrationTestCaseStatus
