import { Accordion, Banner, Button, Layout, Text, LoadingDots } from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { removeToken } from 'common/helpers/removeToken'
import { RouteHistory } from 'common/types/kraken-core/Routes'
import ErrorPanel from 'molecules/ErrorPanel'
import { useEffect } from 'react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { useGetRouteHistory, useRevertRouteHistory } from '../api'

export interface RouteHistoryProps {
  id: string
}

function HistoryComponent({ id }: RouteHistoryProps) {
  const {
    isLoading: isLoadingHistory,
    error: errorHistory,
    data: dataHistory,
  } = useGetRouteHistory({ id })

  const parseHistory = (history?: RouteHistory): string => {
    if (!history) return ''

    const { ...rest } = history

    return JSON.stringify(rest, null, 2)
  }

  const {
    mutate: revert,
    isLoading: revertLoading,
    isSuccess: revertSuccess,
    error: revertError,
    reset: revertDataReset,
  } = useRevertRouteHistory()

  const handleRevert = (history: RouteHistory) => {
    revert(history)
  }

  useEffect(() => {
    if (revertSuccess) {
      toast.success('Route history sucessfully reverted')
      revertDataReset()
    }
  }, [revertSuccess])

  return (
    <Accordion>
      <Accordion.Toggle>
        <Text>History</Text>
      </Accordion.Toggle>
      <Accordion.Body>
        {isLoadingHistory ? (
          <Loading className="mt-8 justify-center" />
        ) : errorHistory || revertError ? (
          <ErrorPanel error={removeToken(JSON.stringify(errorHistory || revertError, null, 2))} />
        ) : (
          !!dataHistory &&
          dataHistory.map((history, index) => (
            <Accordion key={history.history_id}>
              <Accordion.Toggle>
                <Layout.Group>
                  {history.history_id}
                  {' - '}
                  {LSDate(history.history_date)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                  {history.history_user && ` by ${history.history_user}`}
                </Layout.Group>
              </Accordion.Toggle>

              <Accordion.Body>
                <Layout.Box padding="none" textAlign="left">
                  {revertLoading ? (
                    <LoadingDots />
                  ) : (
                    <Button variant="primary" onClick={() => handleRevert(history)}>
                      Revert
                    </Button>
                  )}
                </Layout.Box>

                {history.history_change_reason && (
                  <Banner
                    title={history.history_change_reason}
                    variant="warning"
                    dismissible={false}
                    className="mt-4 mb-4"
                  />
                )}

                <ReactDiffViewer
                  splitView
                  hideLineNumbers
                  oldValue={parseHistory(dataHistory?.[index + 1])}
                  newValue={parseHistory(history)}
                  compareMethod={DiffMethod.TRIMMED_LINES}
                  styles={{
                    line: {
                      fontSize: 12,
                    },
                  }}
                />
              </Accordion.Body>
            </Accordion>
          ))
        )}
      </Accordion.Body>
    </Accordion>
  )
}

export default HistoryComponent
