export interface IntegrationTestSuite {
  id: string
  description: string
}

export interface IntegrationTestCase {
  id: string
  description: string
}

export enum LocalIntegrationTestCaseExecutionType {
  NOT_EXECUTED = 'not_executed',
  FAILED = 'failed',
  PASSED = 'passed',
  ERRROR = 'error',
}

export interface LocalIntegrationTestCase extends IntegrationTestCase {
  execution: {
    isRunning: boolean
    status: LocalIntegrationTestCaseExecutionType
    result?: IntegrationTestCaseExecutionResult
  }
}

export interface IntegrationTestCaseInputDefinition {
  payload: string
  headers?: object
}

export interface IntegrationTestCaseExpectationDefinition {
  checkers: Array<object>
}

export interface IntegrationTestCaseDetail {
  id?: string
  suite?: string
  description?: string
  input_gateway?: string
  input_route?: string
  http_mocks?: Array<object>
  lookup_table_mocks?: Array<object>
  ftp_mocks?: Array<object>
  input_definition?: IntegrationTestCaseInputDefinition
  expectation_definition?: IntegrationTestCaseExpectationDefinition
}

export interface IntegrationTestCaseExecutionResultCheckerOutcome {
  description: string
  strategy: string
  success: boolean
  details: string
  expectations: any
}
export interface IntegrationTestCaseExecutionResultGeneratedMessage {
  id: string
  headers: any
  url: string
  party: {
    id: string
    name: string
  }
  counterparty: {
    id: string
    name: string
  }
}
export interface IntegrationTestCaseExecutionResultGenerated {
  messages: Array<IntegrationTestCaseExecutionResultGeneratedMessage>
}
export interface IntegrationTestCaseExecutionResult {
  success: boolean
  details: string
  generated?: IntegrationTestCaseExecutionResultGenerated
  checkers_outcomes?: Array<IntegrationTestCaseExecutionResultCheckerOutcome>
}
