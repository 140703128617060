import { AuthState, OktaAuthOptions } from '@okta/okta-auth-js'
import Environment from 'common/types/Environment'
import getEnv from '../common/helpers/getEnv'

const ENV = getEnv()

function getClientId() {
  switch (ENV) {
    case Environment.Production:
      return process.env.REACT_APP_OIDC_CLIENT_ID_PRODUCTION
    case Environment.Sandbox:
      return process.env.REACT_APP_OIDC_CLIENT_ID_SANDBOX
    case Environment.Staging:
      return process.env.REACT_APP_OIDC_CLIENT_ID_STAGING
    case Environment.QA:
      return process.env.REACT_APP_OIDC_CLIENT_ID_QA
    default:
      return process.env.REACT_APP_OIDC_CLIENT_ID_SANDBOX
  }
}

const CLIENT_ID = getClientId()
const ISSUER = 'https://loadsmart.okta.com'
const OKTA_TESTING_DISABLEHTTPSCHECK = false
const REDIRECT_URI = `${window.location.origin}/oidc/login/callback`

const config: { oidc: OktaAuthOptions & { disableHttpsCheck: any } } = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    devMode: [Environment.Sandbox, Environment.Dev, Environment.Test].includes(ENV),
    scopes: ['openid', 'email', 'offline_access'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    services: {
      autoRenew: true,
      autoRemove: true,
      syncStorage: true,
    },
    tokenManager: {
      expireEarlySeconds: 15,
      autoRemove: true,
      autoRenew: true,
      secure: true,
    },
    transformAuthState: async (authInstance: any, authState: AuthState) => {
      if (ENV === Environment.Test || ENV === Environment.Dev) {
        return {
          ...authState,
          isAuthenticated: true,
          idToken: {
            idToken: 'test',
            claims: {
              sub: 'test',
            },
            issuer: 'test',
            clientId: 'test',
            expiresAt: 2147483647,
            authorizeUrl: 'test',
            scopes: [],
          },
          accessToken: {
            accessToken: 'test',
            tokenType: 'test',
            userinfoUrl: 'test',
            claims: {
              sub: 'test',
            },
            issuer: 'test',
            clientId: 'test',
            expiresAt: 2147483647,
            authorizeUrl: 'test',
            scopes: [],
          },
        }
      }

      return authState
    },
  },
}

export default config
