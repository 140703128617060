import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { MessageAction } from 'common/types/kraken-core/Action'

export interface SearchActionsPayload {
  next?: string
  previous?: string
  results: MessageAction[]
}

export async function searchActions(
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchActionsPayload>> {
  const params = {
    page_size: 1000,
  }

  const [error, response] = await to(get('/action/definition', { ...config, params }))

  return { error, response }
}
