import { Breadcrumbs, Dropdown, Layout, Text, Textarea } from '@loadsmart/loadsmart-ui'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import { Paper } from '@mui/material'
import ClipboardLabel from 'atoms/ClipboardLabel'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { removeToken } from 'common/helpers/removeToken'
import {
  ExpectedReplyMessage,
  ExpectedReplyMessageStatus,
} from 'common/types/kraken-core/ExpectedReplyMessage'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import ErrorPanel from 'molecules/ErrorPanel'
import FileViewer from 'molecules/FileViewer/FileViewer'
import {
  useGetExpectedReplyMessage,
  useUpdateStatusExpectedReplyMessage,
} from 'pages/ExpectedReplyMessage/api'
import ExpectedReplyMessageStatusTag from 'pages/ExpectedReplyMessage/components/ExpectedReplyMessageStatusTag'
import ExpcetedReplyRelatedMessage from 'pages/ExpectedReplyMessage/components/ExpectedReplyRelatedMessage'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'

const ExpectedReplyMessageDetail = () => {
  useTabTitle('Expected Reply Message')
  const browserHistory = useHistory()
  const { id } = useParams<{ id: string }>()
  const [statusAction] = useState<string>(ExpectedReplyMessageStatus.ACCEPTED)
  const [expectedReplyMessage, setExpectedReplyMessage] = useState<ExpectedReplyMessage>()
  const [breadCrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([
    {
      label: 'Expected Reply Messages',
      active: true,
      url: '#',
      onClick: () => {
        browserHistory.push('/expected-reply-messages')
      },
    },
  ])

  const handleUpdateStatus = useRef<Function>(() => {})

  const { refetch, isLoading, error, data: rawData } = useGetExpectedReplyMessage({ id })

  const {
    mutate: updateStatus,
    isLoading: isUpdatingStatus,
    isSuccess: didSuccessfullyUpdateStatus,
    error: updateStatusError,
  } = useUpdateStatusExpectedReplyMessage({ id, status: statusAction })

  const topNavigationContext = useTopNavigationContext()

  const isLoadingAction = useMemo<boolean>(() => isUpdatingStatus, [isUpdatingStatus])

  handleUpdateStatus.current = (id: string, status: ExpectedReplyMessageStatus) => {
    updateStatus({ id, status })
  }

  useEffect(() => {
    if (updateStatusError) {
      toast.error(`Something went wrong: ${String(updateStatusError)}`)
    }

    if (didSuccessfullyUpdateStatus) {
      toast.success('Successfully updated')
      setTimeout(() => {
        refetch()
      }, 0)
    }
  }, [didSuccessfullyUpdateStatus, updateStatusError])

  useEffect(() => {
    if (rawData) {
      setBreadcrumbs(prev => [
        prev[0],
        {
          label: rawData.id,
        },
      ])
      if (rawData) {
        setExpectedReplyMessage(rawData)
      }
    }
  }, [rawData])

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group className="w-full" align="center" justify="space-between">
          <Layout.Group align="center" className="flex-1">
            <Layout.Box padding="none" className="flex-1">
              <Breadcrumbs entries={breadCrumbs} />
            </Layout.Box>
            {isLoadingAction && <Loading className="justify-center" />}
            <div>
              <Dropdown data-testid="update-status-dropdown" disabled={isLoading}>
                <Dropdown.Trigger>Actions</Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      handleUpdateStatus.current(id, ExpectedReplyMessageStatus.ACCEPTED)
                    }
                  >
                    Accept
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      handleUpdateStatus.current(id, ExpectedReplyMessageStatus.REJECTED)
                    }
                  >
                    Reject
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Layout.Group>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs, isLoading])

  return (
    <Layout.Stack className="w-full" space="s">
      {isLoading ? (
        <Loading className="mt-8 justify-center" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!expectedReplyMessage && (
          <Paper className="p-4 mt-4 w-full">
            <Layout.Group space="m" className="flex mb-4">
              <div className="flex-2" data-testid="expected-reply-message-id">
                <Text>ID</Text>
                <ClipboardLabel value={expectedReplyMessage.id} />
              </div>
              <div className="flex-1" data-testid="expected-reply-message-replied-at">
                <Text>Replied at</Text>
                <ClipboardLabel
                  value={LSDate(expectedReplyMessage.replied_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                />
              </div>
              <div className="flex-1" data-testid="expected-reply-message-created-at">
                <Text>Created at</Text>
                <ClipboardLabel
                  value={LSDate(expectedReplyMessage.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                />
              </div>
              <div className="flex-1" data-testid="expected-reply-message-updated-at">
                <Text>Updated at</Text>
                <ClipboardLabel
                  value={LSDate(expectedReplyMessage.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                />
              </div>
              <div className="flex-1" data-testid="expected-reply-message-deleted-at">
                <Text>Deleted at</Text>
                <ClipboardLabel
                  value={LSDate(expectedReplyMessage.deleted_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                />
              </div>
            </Layout.Group>
            <Layout.Group space="m" className="flex mt-4 mb-8">
              <Layout.Stack data-testid="expected-reply-message-request" className="flex-2">
                <Text>Request Message</Text>
                <ExpcetedReplyRelatedMessage
                  relatedMessage={expectedReplyMessage.request_message}
                />
              </Layout.Stack>
              <Layout.Stack data-testid="expected-reply-message-reply" className="flex-2">
                <Text>Reply Message</Text>
                <ExpcetedReplyRelatedMessage relatedMessage={expectedReplyMessage.reply_message} />
              </Layout.Stack>
              <Layout.Stack data-testid="expected-reply-message-status" className="flex-1">
                <Text>Status</Text>
                <ExpectedReplyMessageStatusTag size="large" status={expectedReplyMessage.status} />
              </Layout.Stack>
            </Layout.Group>
            <Layout.Group className="flex mt-4 mb-8">
              <Layout.Stack
                data-testid="expected-reply-message-rejection-reason"
                className="flex-1"
              >
                <Text>Rejection Reason</Text>
                <Textarea value={expectedReplyMessage.rejection_reason} readOnly />
              </Layout.Stack>
            </Layout.Group>
            <Layout.Group className="flex mt-4 mb-8">
              <Layout.Stack data-testid="expected-reply-message-meta" className="flex-1">
                <Text>Meta</Text>
                <FileViewer
                  content={JSON.stringify(expectedReplyMessage.meta)}
                  contentType="application/json"
                  options={{
                    heigth: 300,
                  }}
                />
              </Layout.Stack>
            </Layout.Group>
          </Paper>
        )
      )}
    </Layout.Stack>
  )
}

export default ExpectedReplyMessageDetail
