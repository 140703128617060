import { toast } from 'atoms/Toast'
import { IntegrationTemplate } from 'common/types/kraken-core/IntegrationTemplate'
import React, { useCallback, useEffect, useState, useContext, createContext } from 'react'
import { getTemplate } from 'services/kraken-core/integration_template/integration.template.service'
import {
  CreateNewDashboardView,
  DuplicateDashboardView,
  TemplateOnboardDashboardView,
} from './types'
import isEmpty from 'lodash.isempty'
import {
  TemplateOnboardDashboardContextLocalStorage,
  TemplateOnboardDashboardContextStoreManager,
} from '../LocalStorageManager/templateOnboardDashboardContextStoreManager'
import getID from 'common/helpers/getID'

export interface TemplateOnboardDashboardContextProps {
  templateId: string
  children: React.ReactNode
}

export interface ConfirmationReponse {
  success?: boolean
  message?: string
  templateID?: string
}

export const TemplateOnboardDashboardContext = createContext<
  TemplateOnboardDashboardContextState | undefined
>(undefined)

export interface TemplateOnboardDashboardContextState {
  template?: IntegrationTemplate
  confirmationResponse?: ConfirmationReponse
  isLoading?: boolean
  activeView: string | null
  getNextViewIndex: () => number
  confirmOnboard?: (data: any) => Promise<boolean>
  resetForNewOnboarding?: () => void
  getActiveTemplateIndex: () => string
  getViews: () => Array<TemplateOnboardDashboardView> | undefined
  removeView: (view: TemplateOnboardDashboardView) => void
  updateView: (view: TemplateOnboardDashboardView) => void
  duplicateView: (view: DuplicateDashboardView) => TemplateOnboardDashboardView
  createNewView: (view: CreateNewDashboardView) => TemplateOnboardDashboardView
  setActiveView: (id: string) => void
}
const viewsCounter: Record<string, number> = {}

function TemplateOnboardDashboardContextWrapper({
  children,
  templateId,
}: TemplateOnboardDashboardContextProps) {
  const [template, setTemplate] = useState<IntegrationTemplate>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [views, setViews] = useState<Array<TemplateOnboardDashboardView>>([])
  const [viewsForTemplate, setViewsForTemplate] = useState<Array<TemplateOnboardDashboardView>>([])
  const [activeView, setActiveView] = useState<string | null>(null)
  const [newViewIndex, setNewViewIndex] = useState<number>(0)

  // Actions
  const getTemplateAndUpdateState = useCallback(async (templateId: string) => {
    setIsLoading(true)
    await getTemplate(templateId).then(r => {
      const { response } = r
      if (r.error) {
        toast.error(r.error.message)
      }

      const template: IntegrationTemplate = response?.data
      setTemplate(template)
    })
    setIsLoading(false)
  }, [])

  const updateContextInLocalStorage = (context: TemplateOnboardDashboardContextLocalStorage) => {
    TemplateOnboardDashboardContextStoreManager.saveDashboardContext(context)
  }

  const handleRemoveView = useCallback(
    (view: TemplateOnboardDashboardView) => {
      const currentContext = TemplateOnboardDashboardContextStoreManager.getDashboardContext()
      const updatedViews = [...currentContext?.views].filter(v => v.id !== view.id)
      TemplateOnboardDashboardContextStoreManager.saveDashboardContext({
        ...currentContext,
        views: updatedViews,
      })
      setViews(updatedViews)
      setActiveView(views[0].id)
    },
    [views]
  )

  const handleUpdateView = (view: TemplateOnboardDashboardView) => {
    const currentContext = TemplateOnboardDashboardContextStoreManager.getDashboardContext()
    const updatedViews = [...currentContext?.views].filter(v => v.id !== view.id)
    TemplateOnboardDashboardContextStoreManager.saveDashboardContext({
      ...currentContext,
      views: [...updatedViews, view],
    })
    setViews([...updatedViews, view])
  }

  const handleCreateNewView = (create: CreateNewDashboardView) => {
    if (!viewsCounter[templateId]) {
      viewsCounter[templateId] = 0
    }

    if (isEmpty(create.data)) {
      create.data = template?.data
    }

    const newView = {
      id: getID(),
      index: create?.index,
      name: create?.name,
      data: create.data,
      templateID: templateId,
    }
    const currentContext = TemplateOnboardDashboardContextStoreManager.getDashboardContext()
    const updatedViews = [...currentContext.views, newView]
    TemplateOnboardDashboardContextStoreManager.saveDashboardContext({
      ...currentContext,
      views: updatedViews,
    })
    setViews(updatedViews)
    setActiveView(newView.id)
    return newView
  }

  const handleDuplicateView = (duplicate: DuplicateDashboardView) => {
    const newDuplicated: CreateNewDashboardView = {
      name: duplicate.source.name,
      data: duplicate.source.data,
      index: getNextViewIndex(),
      templateID: duplicate.source.templateID,
    }
    return handleCreateNewView(newDuplicated)
  }

  const handleGetViews = () => {
    return viewsForTemplate
  }
  const getNextViewIndex = () => {
    const newViewIndexToBeRetorned = newViewIndex
    setNewViewIndex(previousValue => previousValue + 1)
    return newViewIndexToBeRetorned
  }
  // Effects
  useEffect(() => {
    if (isEmpty(templateId)) {
      setViewsForTemplate([])
      setNewViewIndex(1)
    }
    const dashboardContext = TemplateOnboardDashboardContextStoreManager.getDashboardContext()
    if (dashboardContext && dashboardContext.views) {
      const allViewsForTemplate = dashboardContext.views.filter(v => v.templateID === templateId)
      const sortedViewsForTemplate = allViewsForTemplate.sort((v1, v2) =>
        v1.index >= v2.index ? 1 : -1
      )
      const lastView = sortedViewsForTemplate[sortedViewsForTemplate.length - 1]
      setViewsForTemplate(sortedViewsForTemplate)
      setNewViewIndex((lastView?.index ?? 0) + 1)
    } else {
      setViewsForTemplate([])
      setNewViewIndex(1)
    }
  }, [views, templateId])

  useEffect(() => {
    getTemplateAndUpdateState(templateId)
  }, [templateId])

  useEffect(() => {
    console.log('Init...')

    const context = TemplateOnboardDashboardContextStoreManager.getDashboardContext()

    console.log('Existing: ', context)
    if (!context.views || isEmpty(context.views)) {
      console.log("There's no existing views... creating a default one")
      const defaultView: TemplateOnboardDashboardView = {
        ...CONSTANTS.DEFAULT_VIEW,
        index: getNextViewIndex(),
        templateID: templateId,
      }
      TemplateOnboardDashboardContextStoreManager.init(defaultView)
      setViews([defaultView])
      setActiveView(defaultView.id)
    } else if (!TemplateOnboardDashboardContextStoreManager.hasItemWithTemplateID(templateId)) {
      console.log("There's no view with the specified templateID... creating one")
      const defaultView: TemplateOnboardDashboardView = {
        ...CONSTANTS.DEFAULT_VIEW,
        index: getNextViewIndex(),
        id: getID(),
        data: {},
        templateID: templateId,
      }
      const updatedViews = [...context.views, defaultView]
      const updatedContext = { ...context, views: updatedViews }
      TemplateOnboardDashboardContextStoreManager.saveDashboardContext(updatedContext)
      setViews(updatedViews)
      setActiveView(defaultView.id)
    } else {
      console.log('Context has existing views...')
      setViews(context.views)
      const views = context.views.filter(v => v.templateID == templateId)
      const sortedViews = views.sort((v1, v2) => (v1.index > v2.index ? 1 : -1))
      const activeView = sortedViews[0]
      if (activeView) {
        setActiveView(activeView.id)
      }
    }
  }, [])

  // State
  const state: TemplateOnboardDashboardContextState = {
    isLoading,
    template,
    getNextViewIndex: getNextViewIndex,
    activeView,
    getActiveTemplateIndex: () => (template?.id ? template.id.toString() : ''),
    setActiveView: (id: string) => setActiveView(id),
    createNewView: handleCreateNewView,
    updateView: handleUpdateView,
    removeView: handleRemoveView,
    duplicateView: handleDuplicateView,
    getViews: handleGetViews,
  }
  return (
    <TemplateOnboardDashboardContext.Provider value={state}>
      {children}
    </TemplateOnboardDashboardContext.Provider>
  )
}

export const CONSTANTS = {
  DEFAULT_VIEW: {
    index: 0,
    id: getID(),
    name: 'Onboarding',
    data: {},
    templateID: '',
  },
}

export function useTemplateOnboardDashboardContext() {
  const context = useContext(TemplateOnboardDashboardContext)
  if (context === undefined) {
    throw new Error(
      'useTemplateOnboardDashboardContext must be used within a TemplateOnboardDashboardContextWrapper'
    )
  }
  return context
}
export default TemplateOnboardDashboardContextWrapper
