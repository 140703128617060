import {
  Accordion,
  Card,
  Label,
  Layout,
  Select,
  Switch,
  Tag,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import Icon from 'atoms/Icon'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { MessageDirection } from 'common/types/kraken-core/Message'
import { TranslationMap, TranslationMapLoaderEnum } from 'common/types/kraken-core/TranslationMap'
import isEmpty from 'lodash.isempty'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { useEffect, useMemo, useState } from 'react'
import { useSearchContentTypes, useSearchEncodings, useSearchLoaders } from '../api'

export interface TranslationMapDetailsFormProps {
  translationMap: TranslationMap
  onUpdate: (translationMap: TranslationMap) => void
}

const DIRECTION_FILTER_VALUES = [
  {
    label: 'Inbound',
    value: MessageDirection.INBOUND,
  },
  {
    label: 'Outbound',
    value: MessageDirection.OUTBOUND,
  },
]

export default function GeneralDetails(props: TranslationMapDetailsFormProps) {
  const [translationMap, setTranslationMap] = useState<TranslationMap>(props.translationMap)

  const { data: loadersList } = useSearchLoaders()
  const { data: encodingsList } = useSearchEncodings()
  const { data: contentTypesList } = useSearchContentTypes()

  const handleChange = (key: any, value: any) => {
    setTranslationMap((prev: any) =>
      prev
        ? {
            ...prev,
            [key]: value,
          }
        : null
    )
  }

  const isPathInputDisabled = translationMap.loader
    ? translationMap.loader.startsWith(TranslationMapLoaderEnum.RUNTIME_DATABASE_LOADER)
    : false

  const LoaderSelector = useMemo(() => {
    return loadersList ? (
      <Select
        id="loader"
        name="loader"
        onChange={e => {
          if (e.target.value) handleChange('loader', (e.target.value as any).value)
          else handleChange('loader', undefined)
        }}
        options={loadersList.map(loader => ({
          label: loader.description,
          value: loader.loader,
        }))}
        value={
          {
            value: translationMap?.loader || '-',
            label: loadersList.find(p => p.loader === translationMap?.loader)?.description || '-',
          } as Selectable
        }
      />
    ) : null
  }, [loadersList, translationMap.loader])

  const DirectionSelector = useMemo(() => {
    return (
      <Select
        id="use_when_direction"
        name="use_when_direction"
        className="flex justify-center"
        options={DIRECTION_FILTER_VALUES}
        onChange={e => handleChange('use_when_direction', (e.target.value as any).value)}
        value={
          {
            value: translationMap?.use_when_direction || '-',
            label:
              DIRECTION_FILTER_VALUES.find(p => p.value === translationMap?.use_when_direction)
                ?.label || '-',
          } as Selectable
        }
      />
    )
  }, [])

  const InputContentTypeSelector = useMemo(() => {
    return contentTypesList ? (
      <Select
        id="input_content_type"
        name="input_content_type"
        options={contentTypesList.map(contentType => ({
          label: contentType.description,
          value: contentType.content_type,
        }))}
        onChange={e => {
          const value = e.target.value ? (e.target.value as any).value : null
          handleChange('input_content_type', value)
        }}
        value={
          {
            value: translationMap?.input_content_type || '-',
            label:
              contentTypesList.find(p => p.content_type === translationMap?.input_content_type)
                ?.description || '-',
          } as Selectable
        }
      />
    ) : null
  }, [contentTypesList])

  const InputEncodingSelector = useMemo(() => {
    return encodingsList ? (
      <Select
        id="input_encoding"
        name="input_encoding"
        onChange={e => {
          const value = e.target.value ? (e.target.value as any).label : null
          handleChange('input_encoding', value)
        }}
        options={encodingsList.map(encoding => ({
          label: encoding.description,
          value: encoding.encoding,
        }))}
        value={
          {
            value: translationMap?.input_encoding || '',
            label: translationMap?.input_encoding,
          } as Selectable
        }
      />
    ) : null
  }, [encodingsList])

  const OutputContentTypeSelector = useMemo(() => {
    return contentTypesList ? (
      <Select
        id="output_content_type"
        name="output_content_type"
        options={contentTypesList.map(contentType => ({
          label: contentType.description,
          value: contentType.output_content_type,
        }))}
        onChange={e => {
          const value = e.target.value ? (e.target.value as any).value : null
          handleChange('output_content_type', value)
        }}
        value={
          {
            value: translationMap?.output_content_type || '-',
            label:
              contentTypesList.find(
                p => p.output_content_type === translationMap?.output_content_type
              )?.description || '-',
          } as Selectable
        }
      />
    ) : null
  }, [contentTypesList])

  const OutputEncondingSelector = useMemo(() => {
    return encodingsList ? (
      <Select
        id="output_encoding"
        name="output_encoding"
        onChange={e => {
          const value = e.target.value ? (e.target.value as any).value : null
          handleChange('output_encoding', value)
        }}
        options={encodingsList.map(encoding => ({
          label: encoding.description,
          value: encoding.output_encoding,
        }))}
        value={
          {
            value: translationMap?.output_encoding || '-',
            label:
              encodingsList.find(p => p.output_encoding === translationMap?.output_encoding)
                ?.description || '-',
          } as Selectable
        }
      />
    ) : null
  }, [encodingsList])

  useEffect(() => {
    props.onUpdate(translationMap)
  }, [translationMap])

  return (
    <Layout.Group>
      <Layout.Group className="w-full" justify="space-between">
        {translationMap.id ? (
          <>
            <Layout.Stack className="flex-2">
              <Text variant="caption-bold" color="color-neutral">
                ID
              </Text>
              <Text variant="heading-sm-bold">{translationMap.id}</Text>
            </Layout.Stack>
            <Layout.Stack>
              <Text variant="caption-bold" color="color-neutral">
                Created at
              </Text>
              <Text variant="heading-sm-bold">
                {LSDate(translationMap.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
              </Text>
            </Layout.Stack>
            <Layout.Stack>
              <Text variant="caption-bold" color="color-neutral">
                Updated at
              </Text>
              <Text variant="heading-sm-bold">
                {LSDate(translationMap.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
              </Text>
            </Layout.Stack>

            <Layout.Stack>
              <Text variant="caption-bold" color="color-neutral">
                Deleted at
              </Text>
              <Text variant="heading-sm-bold">
                {LSDate(translationMap.deleted_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
              </Text>
            </Layout.Stack>
            <Layout.Stack>
              <Text variant="caption-bold" color="color-neutral">
                Reuse original message
              </Text>
              <Switch
                active={!!translationMap?.output_reuse_original_message}
                onToggle={e => handleChange('output_reuse_original_message', e.target.checked)}
              />
            </Layout.Stack>
          </>
        ) : null}
      </Layout.Group>
      <Layout.Stack className="w-full" space="s">
        <Label required>Name</Label>
        <TextField
          value={translationMap.name}
          status={isEmpty(translationMap.name) ? ('danger' as any) : null}
          onChange={e => handleChange('name', e.target.value)}
        />
      </Layout.Stack>
      <Layout.Stack className="w-full" space="s">
        <Label>Code</Label>
        <TextField
          value={translationMap.code}
          onChange={e => handleChange('code', e.target.value)}
        />
      </Layout.Stack>
      <Layout.Switcher className="w-full">
        <Layout.Stack className="w-full">
          <Card className="p-2">
            <Card.Title>
              <Layout.Group align="center">
                <Icon name="cog" size={25} />
                Execution
              </Layout.Group>
            </Card.Title>
            <Card.Body>
              <Layout.Stack>
                <Layout.Group>
                  <Layout.Stack space="s">
                    <Text>Direction</Text>
                    {DirectionSelector}
                  </Layout.Stack>
                  <Layout.Stack className="flex-1" space="s">
                    <Label required>Loader</Label>
                    {LoaderSelector}
                  </Layout.Stack>
                </Layout.Group>

                <Layout.Stack className="w-full" space="s">
                  <Label required>Name Pattern</Label>
                  <TextField
                    value={translationMap.output_name_pattern}
                    onChange={e => handleChange('output_name_pattern', e.target.value)}
                  />
                </Layout.Stack>

                <Layout.Stack className="w-full" space="s">
                  <Label required={!isPathInputDisabled}>Path</Label>
                  <TextField
                    value={translationMap.path}
                    onChange={e => handleChange('path', e.target.value)}
                    disabled={isPathInputDisabled}
                  />
                </Layout.Stack>
              </Layout.Stack>
            </Card.Body>
          </Card>
        </Layout.Stack>

        {/* Input content type */}
        <Card className="p-2">
          <Card.Title>
            <Layout.Group align="center">
              <Icon name="import" size={25} />
              Input
            </Layout.Group>
          </Card.Title>
          <Card.Body>
            <Layout.Stack>
              <Layout.Stack space="s">
                <Label required>Content-Type</Label>
                {InputContentTypeSelector}
              </Layout.Stack>
              <Layout.Stack space="s">
                <Label required>Encoding</Label>
                {InputEncodingSelector}
              </Layout.Stack>
            </Layout.Stack>
          </Card.Body>
        </Card>

        {/* Output content type */}
        <Card className="p-2">
          <Card.Title>
            <Layout.Group align="center">
              <Icon name="export" size={25} />
              Output
            </Layout.Group>
          </Card.Title>

          <Card.Body>
            <Layout.Stack>
              <Layout.Stack space="s">
                <Label required>Content-Type</Label>
                {OutputContentTypeSelector}
              </Layout.Stack>
              <Layout.Stack space="s">
                <Label required>Encoding</Label>
                {OutputEncondingSelector}
              </Layout.Stack>
              <Layout.Stack>
                <Accordion>
                  <Accordion.Toggle>
                    <Text>Headers</Text>
                  </Accordion.Toggle>
                  <Accordion.Body>
                    <FileViewer
                      options={{
                        heigth: 500,
                      }}
                      content={JSON.stringify(translationMap.output_headers || {}, null, 2)}
                      contentType="application/json"
                      showOptions={false}
                      onChange={value => handleChange('output_headers', JSON.parse(value || '{}'))}
                    />
                  </Accordion.Body>
                </Accordion>
              </Layout.Stack>
            </Layout.Stack>
          </Card.Body>
        </Card>
      </Layout.Switcher>

      <Accordion className="w-full">
        <Accordion.Toggle>
          <Layout.Group align="center">
            <Text variant="heading-sm-bold">Use when</Text>
            <Tag variant="warning">Deprecated</Tag>
          </Layout.Group>
        </Accordion.Toggle>
        <Accordion.Body className="w-full" style={{ width: '90%' }}>
          <FileViewer
            options={{
              heigth: 300,
            }}
            content={JSON.stringify(translationMap.use_when_facts, null, 2)}
            contentType="application/json"
            showOptions={false}
            onChange={value => handleChange('use_when_facts', JSON.parse(value || '{}'))}
          />
        </Accordion.Body>
      </Accordion>
    </Layout.Group>
  )
}
