import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMMSS_AMPM } from 'common/Date.helpers'
import { MessageRead, MessageLogRead, MessageLogLevel } from 'common/types/kraken-core/Message'
import { readMessageLogs } from 'services/kraken-core/messages/messages.service'
import useCancelToken from 'hooks/useCancelToken'
import { Layout, Tag, TagProps, Text, Tooltip } from '@loadsmart/loadsmart-ui'
import Icon from 'atoms/Icon'
import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import { TableCell, TableContainer, TableHead, Table, TableRow } from '@mui/material'
import styled from 'styled-components'
import isEmpty from 'lodash.isempty'
import analytics, { AnalyticsEvent } from 'common/analytics'

export interface MessageLogsProps {
  message: MessageRead
}

interface StyledFilterTagProps extends TagProps {
  selected: boolean
}

const TAG_ALL = 'ALL'

const StyledTag = (props: StyledFilterTagProps) => <Tag {...props}>{props.children}</Tag>

const StyledFilterTag = styled(StyledTag)`
  border: ${props => (props.selected ? '3px solid' : '')};
`

const MessageLogsView = ({ message }: MessageLogsProps) => {
  const [logTags, setLogTags] = useState<Array<React.ReactElement>>([])
  const [filteredLogTags, setFilteredLogTags] = useState<Array<string>>([TAG_ALL])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [logs, setLogs] = useState<MessageLogRead[]>([])

  const { getSource, clearSource, cancelPending, isCancel } = useCancelToken()

  const logLevelStyle = useCallback((level: string) => {
    const mapping: any = {
      ERROR: {
        style: {
          backgroundColor: Themes.Loadsmart['banner-background-danger'],
        },
        symbol: (
          <Tooltip message="Error">
            <Icon name="close" />
          </Tooltip>
        ),
      },
      WARNING: {
        style: {
          backgroundColor: Themes.Loadsmart['color-warning-light'],
        },
        symbol: (
          <Tooltip message="Warning">
            <Icon name="warning" />
          </Tooltip>
        ),
      },
    }
    return mapping[level] || ''
  }, [])

  const onClikTagLevelFilter = (level: string) => {
    const selected = filteredLogTags.filter(t => t === level).length > 0
    analytics.event({
      action: 'click',
      category: AnalyticsEvent.MessageDetailLogFilter,
      value: level,
    })

    if (level === TAG_ALL) {
      setFilteredLogTags(c => [level])
    } else {
      if (selected) {
        setFilteredLogTags(c => c.filter(v => v != level))
      } else {
        const tagsExceptAll = filteredLogTags.filter(v => v != TAG_ALL)
        setFilteredLogTags(c => [...tagsExceptAll, level])
      }
    }
  }

  useEffect(() => {
    const source = getSource()

    ;(async () => {
      setIsLoading(true)
      const [error, response] = await readMessageLogs(message.id, {
        cancelToken: source.token,
      })

      clearSource()
      setIsLoading(false)

      if (error && !isCancel) return toast.error(`Something went wrong: ${error}`)

      return setLogs(response?.data || [])
    })()

    return () => cancelPending()
  }, [message.id])

  useEffect(
    function updateLogFilterTags() {
      const tags = [
        <StyledFilterTag
          key={'tag_all'}
          className="cursor-pointer"
          onClick={() => onClikTagLevelFilter(TAG_ALL)}
          selected={filteredLogTags.filter(t => t === TAG_ALL).length > 0}
        >
          ALL
        </StyledFilterTag>,
      ]
      const levels = new Set(logs.filter(c => !isEmpty(c.log_level)).map(c => c.log_level))
      levels.forEach(level => {
        const upperCaseLevel = level.toUpperCase()
        const style = logLevelStyle(level).style
        tags.push(
          <StyledFilterTag
            key={`tag_${level}`}
            className="cursor-pointer"
            style={style}
            onClick={() => onClikTagLevelFilter(level)}
            selected={filteredLogTags.filter(t => t === upperCaseLevel).length > 0}
          >
            {upperCaseLevel}
          </StyledFilterTag>
        )
      })
      setLogTags(
        tags.sort((a, b) => {
          if (a.key && b.key) {
            return a.key.toLocaleString().localeCompare(b.key.toString())
          }
          return 0
        })
      )
    },
    [logs, filteredLogTags]
  )

  useEffect(() => {
    if (filteredLogTags.length === 0) {
      setFilteredLogTags([TAG_ALL])
    }
  }, [filteredLogTags])

  return (
    <Layout.Box background="neutral-white">
      {isLoading && <Loading className="justify-center mt-8" />}

      {!isLoading && (
        <Layout.Stack>
          <Layout.Group data-testid="message-logs-filterable-tags">
            {logTags.map(logTag => {
              return logTag
            })}
          </Layout.Group>
          <TableContainer data-testid="message-logs-list">
            <Table size="small" padding="normal" data-testid="message-logs-list-body">
              <TableHead>
                <TableCell>Created At</TableCell>
                <TableCell size="small" width={1} />
                <TableCell>Log</TableCell>
              </TableHead>
              {logs
                ?.filter(log => {
                  if (filteredLogTags.find(l => l === TAG_ALL)) return log
                  return filteredLogTags.find(l => l === log.log_level)
                })
                .map(log => {
                  const levelStyle: any = logLevelStyle(log.log_level)
                  const createdAt = LSDate(log.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMMSS_AMPM)
                  return (
                    <TableRow key={`${log.created_at}-${log.log}`} style={levelStyle.style}>
                      <TableCell>
                        <Layout.Group>
                          <Text>{createdAt}</Text>
                        </Layout.Group>
                      </TableCell>
                      <TableCell size="small" width={1}>
                        {levelStyle.symbol}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'break-spaces' }}>
                        <Layout.Group>{log.log}</Layout.Group>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </Table>
          </TableContainer>
        </Layout.Stack>
      )}
    </Layout.Box>
  )
}

export default MessageLogsView
