import { useState } from 'react'
import { Tabs, Tab, Divider } from '@mui/material'
import { MessageRead } from 'common/types/kraken-core/Message'

import ExpectedReplyMessagesView from '../Common/ExpectedReplyMessagesView'
import MessageHeadersTable from '../Common/MessageHeadersTable'
import BodyContentReader from './BodyContentReader'
import MessageHistoryView from '../Common/MessageHistory'
import MessageLogsView from '../Common/MessageLogs'
import MessageReceiptsView from '../Common/MessageReceipts'
import MessageRouteFlow from '../Common/MessageRouteFlow'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import { useMessagesDashboardContext } from 'pages/Messages/MessageDahsboardContext'
import MessageFailures from '../Common/MessageFailures'

export interface AbstractMessageInformationProps {
  message: MessageRead
  onExpansion: (panel: string, isExpanded: boolean) => void
  isExpanded: boolean
}

export interface MessageInformationWrapperProps {
  message: MessageRead
}

function MessageInformation({ message }: MessageInformationWrapperProps) {
  const dashboardContext = useMessagesDashboardContext()
  const [value, setValue] = useState(dashboardContext.getDetailView().details?.activeTabIndex || 0)

  const handleChange = (newValue: number) => {
    analytics.event({
      category: AnalyticsEvent.MessageDetailSwitchTab,
      action: AnalyticsEventTrigger.click,
    })
    setValue(newValue)
    dashboardContext.updateView({
      ...dashboardContext.getDetailView(),
      details: {
        activeTabIndex: newValue,
      },
    })
  }

  return (
    <>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={(_, val) => handleChange(val)}
        className="mt-4"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Headers" style={{ outline: 'none' }} />
        <Tab label="Body" style={{ outline: 'none' }} />
        <Tab label="Logs" style={{ outline: 'none' }} />
        <Tab label="History" style={{ outline: 'none' }} />
        <Tab label="Receipts" style={{ outline: 'none' }} />
        <Tab label="Failures" style={{ outline: 'none' }} />
        <Tab label="Expected reply messages" style={{ outline: 'none' }} />
        <Tab label="Route flow" style={{ outline: 'none' }} />
      </Tabs>

      <Divider />

      {value === 0 && (
        <MessageHeadersTable key="headers-table" message={message} readOnly={false} />
      )}

      {value === 1 && <BodyContentReader message={message} />}

      {value === 2 && <MessageLogsView message={message} />}

      {value === 3 && <MessageHistoryView message={message} />}

      {value === 4 && <MessageReceiptsView message={message} />}

      {value === 5 && <MessageFailures message={message} />}

      {value === 6 && <ExpectedReplyMessagesView key="expected-reply-messages" message={message} />}

      {value === 7 && <MessageRouteFlow message={message} />}
    </>
  )
}

export default MessageInformation
