import { Autocomplete, InputBase, TextField } from '@mui/material'
import styled from 'styled-components'

export const StyledDiv = styled.div`
  -webkit-transition-property: background, border-color, box-shadow, color, fill, left, stroke,
    -webkit-transform, opacity;
  -webkit-transition-property: background, border-color, box-shadow, color, fill, left, stroke,
    transform, opacity;
  transition-property: background, border-color, box-shadow, color, fill, left, stroke, transform,
    opacity;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 450ms;
  transition-duration: 450ms;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  height: 2.25rem;
  min-width: 15rem;
  color: #0a0a0b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  background: #ffffff;
  border-color: #313336;
  color: #313336;
`
export const StyledInput = styled(TextField)({
  '& .MuiInputBase-root': {
    border: 'none',
    boxShadow: 'none',
    background: 'none',
    minWidth: '20rem',
    padding: '0 !important',
    margin: 0,
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    padding: 0,
    border: 'none',
    display: 'none',
  },
  '& .MuiInputLabel-root': {
    display: 'none',
    padding: 0,
    margin: 0,
  },
  '& .MuiInputBase-input': {
    paddingRight: 10,
    marginRight: '2rem',
    maxHeight: '1.25rem',
    minWidth: '15rem',
  },
})

export const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-endAdornment': {},
  '& .MuiAutocomplete-popper': {
    minWidth: 300,
  },
})
