/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import {
  Layout,
  Modal,
  Table,
  TextField,
  Text,
  Button,
  IconButton,
  Dialog,
  Tabs,
  Accordion,
  Banner,
  Label,
  Select,
} from '@loadsmart/loadsmart-ui'
import {
  TradingPartnerContact,
  TradingPartnerContactType,
} from 'common/types/kraken-core/TradingPartner'
import SimplePagination from 'atoms/SimplePagination'
import Icon from 'atoms/Icon'
import { toast } from 'atoms/Toast'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import Loading from 'atoms/Loading'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'
import { SearchContactHistoryPayload } from 'services/kraken-core/trading_partner/tradingPartner.service'
import {
  useCreateContact,
  useDeleteContact,
  useSearchContactHistory,
  useSearchContacts,
  useSearchContactsTypes,
  useUpdateContact,
} from '../api'
import { ContactsTabProps } from '../types'
import isEmpty from 'lodash.isempty'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'

const ContactsTab = ({ tradingPartner }: ContactsTabProps) => {
  const [page, setPage] = useState(1)
  const [modalData, setModalData] = useState<Partial<TradingPartnerContact> | null>(null)
  const [contactToDelete, setContactToDelete] = useState<TradingPartnerContact | null>(null)
  const formRef = useRef<Partial<TradingPartnerContact>>()

  const { data: contacts, isFetching } = useSearchContacts({
    page,
    id: tradingPartner.id,
  })
  const { data: contactsTypes } = useSearchContactsTypes()
  const {
    mutate: update,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    error: updatedError,
    reset: updateReset,
  } = useUpdateContact()
  const {
    mutate: remove,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    error: deletedError,
    reset: deletedReset,
  } = useDeleteContact()
  const {
    mutate: create,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    error: createError,
  } = useCreateContact()
  const {
    data: selectedContactHistory,
    isLoading: isLoadingHistory,
    error: errorHistory,
  } = useSearchContactHistory({
    id: tradingPartner.id,
    contact: (modalData as Partial<TradingPartnerContact>) || undefined,
  })

  const handleCloseModal = () => {
    setModalData(null)
  }

  const handleCloseDeleteConfirmation = () => {
    setContactToDelete(null)
  }

  const handleOnClikCreateNew = () => {
    setModalData({
      owner: tradingPartner.id,
    })
    if (modalData) formRef.current = modalData
  }

  const handleOnClickUpdate = (contact: TradingPartnerContact) => {
    setModalData(contact)
  }

  const handleConfirmForm = () => {
    if (!modalData) {
      toast.error('Invalid contact, review data')
      return
    }

    if (modalData.id) {
      update(modalData)
    } else {
      create(modalData as any)
    }
  }

  useEffect(() => {
    if (createError) {
      toast.error(`Something went wrong when creating: ${String(createError)}`)
    }

    if (isCreateSuccess) {
      toast.success('Created with success')
    }

    setTimeout(() => {
      setModalData(null)
    }, 0)
  }, [isCreateError, isCreateSuccess, createError])

  useEffect(() => {
    if (updatedError) {
      toast.error(`Something went wrong: ${String(updatedError)}`)
    }

    if (isUpdated) {
      toast.success('Updated with success')
    }

    setTimeout(() => {
      updateReset()
      setModalData(null)
    }, 0)
  }, [isUpdated, updatedError])

  useEffect(() => {
    if (deletedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isDeleted) {
      toast.success('Deleted with success')
    }

    setTimeout(() => {
      deletedReset()
      setContactToDelete(null)
    }, 0)
  }, [isDeleted, deletedError])

  const parseHistory = (history?: SearchContactHistoryPayload): string => {
    if (!history) return ''

    const { history_id, history_date, history_change_reason, history_user, ...rest } = history

    return JSON.stringify(rest, null, 2)
  }

  const COLUMNS = [
    {
      Header: 'ID',
      Cell(row: TradingPartnerContact) {
        return <div className="w-48">{row.id}</div>
      },
    },
    {
      Header: 'Type',
      Cell(row: TradingPartnerContact, types?: TradingPartnerContactType[]) {
        return types?.find(cTypes => cTypes.type === row.type)?.description || row.type
      },
    },
    {
      Header: 'Name',
      Cell(row: TradingPartnerContact) {
        return row.full_name
      },
    },
    {
      Header: 'Actions',
      alignment: 'right',
      Cell(row: TradingPartnerContact) {
        return (
          <Layout.Group justify="flex-end">
            <IconButton onClick={() => handleOnClickUpdate(row)}>
              <Icon name="edit" />
            </IconButton>
            <IconButton
              onClick={e => {
                setContactToDelete(row)
                e.stopPropagation()
              }}
            >
              <Icon name="remove" />
            </IconButton>
          </Layout.Group>
        )
      },
    },
  ]

  return (
    <>
      <Dialog
        open={!!contactToDelete}
        onOverlayClick={handleCloseDeleteConfirmation}
        scale="small"
        className="contact-history-modal"
      >
        <Dialog.Header>Delete contact</Dialog.Header>
        <Dialog.Body>Are you sure you want to delete this contact?</Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isDeleting}
          onConfirm={() => contactToDelete && remove(contactToDelete)}
        />
        <Dialog.ActionCancel onCancel={handleCloseDeleteConfirmation} />
      </Dialog>

      <Modal open={!!modalData} onOverlayClick={handleCloseModal} scale="large">
        <Tabs className="flex flex-col contact-history-modal" direction="horizontal">
          <Tabs.Items className="mt-2">
            <Tabs.Item default name="details">
              Details
            </Tabs.Item>

            {modalData?.id ? <Tabs.Item name="history">History</Tabs.Item> : null}
          </Tabs.Items>

          <Tabs.Panels className="contact-tabs overflow-scroll">
            <Tabs.Panel name="details" className="h-full">
              <Layout.Stack className="h-full pt-4" justify="space-between" align="stretch">
                <Layout.Stack space="s">
                  <Layout.Stack className="flex-1">
                    {!isEmpty(modalData?.id) ? (
                      <Layout.Stack className="flex-1" space="s">
                        <Text>ID</Text>
                        <Text>{modalData?.id}</Text>
                      </Layout.Stack>
                    ) : null}

                    <Layout.Stack space="s">
                      <Label required>Full name</Label>
                      <TextField
                        key={`full_name_${modalData?.id}`}
                        value={modalData?.full_name}
                        onChange={e => setModalData(c => ({ ...c, full_name: e.target.value }))}
                      />
                    </Layout.Stack>

                    <Layout.Group>
                      <Layout.Stack className="flex-1" space="s">
                        <Label>Phone Number</Label>
                        <TextField
                          key={`phone_number_${modalData?.id}`}
                          value={modalData?.phone_number}
                          onChange={e =>
                            setModalData(
                              prev =>
                                prev && {
                                  ...prev,
                                  phone_number: e.target.value,
                                }
                            )
                          }
                        />
                      </Layout.Stack>

                      <Layout.Stack className="flex-1" space="s">
                        <Label>Email</Label>
                        <TextField
                          key={`email_${modalData?.id}`}
                          value={modalData?.email}
                          onChange={e =>
                            setModalData(
                              prev =>
                                prev && {
                                  ...prev,
                                  email: e.target.value,
                                }
                            )
                          }
                        />
                      </Layout.Stack>

                      <Layout.Stack space="s">
                        <Label required>Type</Label>
                        <Select
                          key={`type_${modalData?.id}`}
                          name="contact-type"
                          options={contactsTypes?.map(c => ({
                            label: c.description,
                            value: c.type,
                          }))}
                          value={
                            {
                              value: modalData?.type,
                              label: modalData?.type,
                            } as Selectable
                          }
                          onChange={e =>
                            setModalData(c => ({
                              ...c,
                              type: e.target.value ? (e.target.value as any).value : null,
                            }))
                          }
                        />
                      </Layout.Stack>
                    </Layout.Group>
                  </Layout.Stack>
                </Layout.Stack>
                <Layout.Group className="flex-1" align="flex-end">
                  <Layout.Stack className="flex-1">
                    <Button onClick={handleCloseModal}>Cancel</Button>
                  </Layout.Stack>

                  <Layout.Stack className="flex-1">
                    <Button disabled={isUpdating} variant="primary" onClick={handleConfirmForm}>
                      {modalData?.id ? `Update` : 'Create'}
                    </Button>
                  </Layout.Stack>
                </Layout.Group>
              </Layout.Stack>
            </Tabs.Panel>

            <Tabs.Panel name="history" className="p-4">
              <Layout.Stack>
                <Layout.Group>
                  <Layout.Stack space="none" justify="space-between">
                    <Text variant="caption-bold">Created at</Text>
                    <Text>{LSDate(modalData?.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}</Text>
                  </Layout.Stack>

                  <Layout.Stack className="flex-1" space="none">
                    <Text variant="caption-bold">Updated at</Text>
                    <Text>{LSDate(modalData?.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}</Text>
                  </Layout.Stack>

                  <Layout.Stack className="flex-1" space="none">
                    <Text variant="caption-bold">Deleted at</Text>
                    <Text>{LSDate(modalData?.deleted_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}</Text>
                  </Layout.Stack>
                </Layout.Group>
                {isLoadingHistory ? (
                  <Loading className="mt-8 justify-center" />
                ) : errorHistory ? (
                  <ErrorPanel error={removeToken(JSON.stringify(errorHistory, null, 2))} />
                ) : (
                  !!selectedContactHistory &&
                  selectedContactHistory.map((history, index) => (
                    <Accordion key={history.history_id}>
                      <Accordion.Toggle>
                        <Layout.Group>
                          {history.history_id}
                          {' - '}
                          {LSDate(history.history_date)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                          {history.history_user && ` by ${history.history_user}`}
                        </Layout.Group>
                      </Accordion.Toggle>

                      <Accordion.Body>
                        {history.history_change_reason && (
                          <Banner
                            title={history.history_change_reason}
                            variant="warning"
                            dismissible={false}
                            className="mt-4 mb-4"
                          />
                        )}

                        <ReactDiffViewer
                          hideLineNumbers
                          oldValue={parseHistory(selectedContactHistory?.[index + 1])}
                          newValue={parseHistory(history)}
                          compareMethod={DiffMethod.TRIMMED_LINES}
                          styles={{
                            line: {
                              fontSize: 12,
                            },
                          }}
                        />
                      </Accordion.Body>
                    </Accordion>
                  ))
                )}
              </Layout.Stack>
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Modal>

      <Layout.Stack className="p-2" space="xl">
        <Layout.Group justify="flex-end" className="pt-2">
          <Button variant="primary" onClick={handleOnClikCreateNew}>
            Create New
          </Button>
        </Layout.Group>
        <Table className="w-full" data-testid="connection-settings-list">
          <Table.Head>
            {COLUMNS.map(column => (
              <Table.Cell key={column.Header} alignment={column.alignment as any}>
                {column.Header}
              </Table.Cell>
            ))}
          </Table.Head>
          <Table.Body>
            {contacts?.results.map(row => (
              <Table.Row className="cursor-pointer" onClick={() => setModalData(row)} key={row.id}>
                {COLUMNS.map(cell => (
                  <Table.Cell
                    className="text-sm"
                    key={cell.Header}
                    alignment={cell.alignment as any}
                  >
                    {cell.Cell(row, contactsTypes)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Layout.Box className="flex justify-end w-full">
          <SimplePagination
            currentPage={page}
            previousPage={() => setPage(prev => prev - 1)}
            nextPage={() => setPage(prev => prev + 1)}
            canPreviousPage={!!contacts?.previous && !isFetching}
            canNextPage={!!contacts?.next && !isFetching}
          />
        </Layout.Box>
      </Layout.Stack>
    </>
  )
}

export default ContactsTab
