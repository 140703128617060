import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useState } from 'react'
import { ControlProps } from '@jsonforms/core'
import { uploadFile } from 'services/kraken-core/common/upload_files/uploadfiles.service'
import { toast } from 'atoms/Toast'
import { Link } from '@mui/material'
import Icon from 'atoms/Icon'
import { Tooltip } from '@loadsmart/loadsmart-ui'
import FileUploader from '../../../FileUploader/FileUploader'
import { CustomRendererMetadata } from '../abstraction'

export const FILE_UPLOADER_RENDERER_META: CustomRendererMetadata = {
  type: 'FileUploader',
  label: 'File Uploader',
}
interface FileUploadControlState {
  file?: File
  url?: string
}

function FileUploaderControl(props: ControlProps) {
  const [state, setState] = useState<FileUploadControlState>()
  const { label } = props

  async function handleUpload(files: File[]) {
    for (const file of files) {
      const result = await uploadFile({ file })
      if (!result.error) {
        const data = result.response?.data
        const url = data.url_to_download
        props.handleChange(props.path, url)
      } else {
        toast.error(`Error while uploading ${file.name}: ${result.error}`)
      }
    }
  }

  function removeUploadedFile() {
    props.handleChange(props.path, undefined)
    setState({
      ...state,
      url: undefined,
    })
  }

  useEffect(() => {
    if (props.data) {
      setState(s => ({
        ...s,
        url: props.data,
      }))
    }
  }, [props])

  return (
    <div className="flex flex-col pt-4">
      <div className="pb-1">{label}</div>
      <div>
        {!state?.url && <FileUploader handleUpload={handleUpload} />}
        {state?.url && (
          <div className="flex flex-row items-center">
            <div className="m-1">
              <Link href={state.url} target="_blank">
                File uploaded
              </Link>
            </div>
            <div className="p-1" onClick={removeUploadedFile}>
              <Tooltip message="Remove uploaded file">
                <Icon name="close" role="button" />
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default withJsonFormsControlProps(FileUploaderControl)
