import {
  IntegrationTestCase,
  IntegrationTestCaseDetail,
  IntegrationTestCaseExecutionResult,
} from 'common/types/kraken-core/IntegrationTest'
import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query'
import {
  createIntegtationTestCase,
  deleteIntegrationTestCase,
  getIntegrationTestCaseDetail,
  getIntegrationTestCaseList,
  runIntegrationTestCase,
  updateIntegrationTestCase,
} from 'services/kraken-core/integration_test/integration_test.service'
import {
  IntegrationTestCaseCreate,
  IntegrationTestCaseDelete,
  IntegrationTestCaseGetDetail,
  IntegrationTestCaseGetList,
  IntegrationTestCaseRunCommand,
  IntegrationTestCaseUpdate,
} from './types'

export enum QUERIES {
  GET_INTEGRATION_TEST_CASE_DETAIL = 'integration_test_case/get/detail',
  GET_INTEGRATION_TEST_CASE_LIST = 'integration_test_case/get/list',
}

export const useGetIntegrationTestCaseList = (
  entity: IntegrationTestCaseGetList,
  options?: any
): UseQueryResult<PaginatedResponse<IntegrationTestCase>> => {
  const queryFn = async (params: IntegrationTestCaseGetList) => {
    const { error, response } = await getIntegrationTestCaseList(entity)
    if (error) throw new Error(error.message)
    return response?.data
  }
  const query = useQuery([QUERIES.GET_INTEGRATION_TEST_CASE_LIST, entity], () => queryFn(entity), {
    enabled: !!entity.suite_id,
    ...options,
  })
  return query
}

export const useGetIntegrationTestCaseDetail = (
  entity?: IntegrationTestCaseGetDetail,
  options?: any
): UseQueryResult<IntegrationTestCaseDetail> => {
  const queryFn = async (params: IntegrationTestCaseGetDetail) => {
    const { error, response } = await getIntegrationTestCaseDetail(params)
    if (error) throw new Error(error.message)
    return response?.data
  }
  const query = useQuery(
    [QUERIES.GET_INTEGRATION_TEST_CASE_DETAIL, entity],
    async () => await queryFn(entity || {}),
    {
      ...options,
    }
  )
  return query
}

export const useUpdateIntegrationTestCase = (): UseMutationResult<
  IntegrationTestCaseDetail,
  unknown,
  IntegrationTestCaseUpdate,
  unknown
> => {
  const mutation = useMutation(async (entity: IntegrationTestCaseUpdate) => {
    const { error, response } = await updateIntegrationTestCase(entity)
    if (error) throw new Error(error.message)
    return response?.data
  })
  return mutation
}

export const useCreateIntegrationTestCase = (): UseMutationResult<
  IntegrationTestCaseDetail,
  unknown,
  IntegrationTestCaseCreate,
  unknown
> => {
  const mutation = useMutation(async (entity: IntegrationTestCaseCreate) => {
    const { error, response } = await createIntegtationTestCase(entity)
    if (error) throw new Error(error.message)
    return response?.data
  })
  return mutation
}

export const useDeleteIntegrationTestCase = (): UseMutationResult<
  IntegrationTestCaseDetail,
  unknown,
  IntegrationTestCaseDelete,
  unknown
> => {
  const mutation = useMutation(async (entity: IntegrationTestCaseDelete) => {
    const { error, response } = await deleteIntegrationTestCase(entity)
    if (error) throw new Error(error.message)
    return response?.data
  })
  return mutation
}

export const useRunIntegrationTestCase = (): UseMutationResult<
  IntegrationTestCaseExecutionResult,
  unknown,
  IntegrationTestCaseRunCommand,
  unknown
> => {
  const mutation = useMutation(async (entity: IntegrationTestCaseRunCommand) => {
    const { error, response } = await runIntegrationTestCase(entity)
    if (error) throw new Error(error.message)
    return response?.data
  })
  return mutation
}
