import { Label, Layout } from '@loadsmart/loadsmart-ui'
import GatewaySettingsSelector from 'common/components/selectors/GatewaySettingsSelector'
import RouteSelector from 'common/components/selectors/RouteSelector'
import { GatewaySetting } from 'common/types/kraken-core/GatewaySettings'
import { onSearchInputGateways } from 'common/components/datasources/datasource'
import { onSearchPolledGateways } from 'common/components/datasources/datasource'

export interface IntegrationTestCaseDetailEntryPointProps {
  definition: {
    inputGateway?: string
    inputRoute?: string
  }
  onChange: (propertyName: string, value: any) => void
}

const DATASOURCES = {
  ENTRY_POINT_GATEWAY_SETTINGS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (entity: GatewaySetting) => entity.id || '',
        getLabel: (entity: GatewaySetting) => entity.name || '',
      },
      fetch: onSearchInputGateways,
    }),
    () => ({
      type: 'string',
      adapter: {
        getKey: (entity: GatewaySetting) => entity.id || '',
        getLabel: (entity: GatewaySetting) => entity.name || '',
      },
      fetch: onSearchPolledGateways,
    }),
  ],
}

export default function IntegrationTestCaseDetailEntryPoint(
  props: IntegrationTestCaseDetailEntryPointProps
) {
  return (
    <Layout.Stack className="p-2">
      <Layout.Stack space="s">
        <Label required>Input Gateway</Label>
        <GatewaySettingsSelector
          selected={{
            id: props.definition.inputGateway,
            label: props.definition.inputGateway,
          }}
          onSelect={selected => {
            if (selected) props.onChange('input_gateway', selected.id)
            else props.onChange('input_gateway', null)
          }}
          dataSources={DATASOURCES.ENTRY_POINT_GATEWAY_SETTINGS}
        />
      </Layout.Stack>
      <Layout.Stack space="s">
        <Label required>Input Route</Label>
        <RouteSelector
          selected={{
            id: props.definition.inputRoute,
            label: props.definition.inputRoute,
          }}
          onSelect={selected => {
            if (selected) props.onChange('input_route', selected.id)
            else props.onChange('input_route', null)
          }}
        />
      </Layout.Stack>
    </Layout.Stack>
  )
}
