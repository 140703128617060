import { Layout, TopNavigation } from '@loadsmart/loadsmart-ui'
import { authManager } from 'common/authManager'
import { useContext } from 'react'
import TopNavigationContext from './TopNavigationContext'

function TopNavigationHeader() {
  const context = useContext(TopNavigationContext)

  if (!authManager.isAuthenticated()) {
    return null
  }

  return (
    <Layout.Stack
      space="none"
      className="w-full stick fixed"
      style={{
        paddingLeft: '12.5rem',
        paddingRight: '0rem',
        zIndex: 1,
      }}
    >
      <TopNavigation data-testid="top-navigation">{context.currentState?.children}</TopNavigation>
    </Layout.Stack>
  )
}

export default TopNavigationHeader
