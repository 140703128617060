import { IconButton, Tag } from '@loadsmart/loadsmart-ui'
import { CircularProgress } from '@mui/material'
import Icon from 'atoms/Icon'
import { TranslationTestCaseResult } from 'common/types/kraken-core/TranslationMap'
import { DevelopmentTranslationTestCase } from 'pages/TranslationMaps/types'
import styled from 'styled-components'

export interface TesstCaseExecutionStatusProps {
  test: DevelopmentTranslationTestCase
  onClick?: () => void
}

const TestCaseExecutionStatus = (props: TesstCaseExecutionStatusProps) => {
  const test = props.test
  const defaultIconSize = 20
  const didFail = !test.isRunning && test.execution && !test.execution.success
  const didSucceed = !test.isRunning && test.execution && test.execution.success
  return test.isRunning ? (
    <CircularProgress size={defaultIconSize} />
  ) : didFail ? (
    <Tag onClick={props.onClick} variant="danger" className="cursor-pointer">
      Failed
    </Tag>
  ) : didSucceed ? (
    <Tag onClick={props.onClick} variant="accent" className="cursor-pointer">
      Passed
    </Tag>
  ) : (
    <Tag variant="default">Not executed</Tag>
  )
}

export default TestCaseExecutionStatus
