import { Layout } from '@loadsmart/loadsmart-ui'
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Pill from 'atoms/Pill'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { ExpectedReplyMessage, MessageRead } from 'common/types/kraken-core/Message'

export interface MessageExpectedReplyMessagesProps {
  message: MessageRead
}

export interface LocalExpectedReplyMessage extends ExpectedReplyMessage {
  type: 'Request' | 'Reply'
  messageId?: string
}

function ExpectedReplyMessagesView({ message }: MessageExpectedReplyMessagesProps) {
  const ermAsReply: Array<LocalExpectedReplyMessage> = message.expectations_as_reply.map(
    expectedReplyMessage => ({
      ...expectedReplyMessage,
      type: 'Reply',
      messageId: expectedReplyMessage.request,
    })
  )

  const ermAsRequest: Array<LocalExpectedReplyMessage> = message.expectations_as_request.map(
    expectedReplyMessage => ({
      ...expectedReplyMessage,
      type: 'Request',
      messageId: expectedReplyMessage.reply,
    })
  )

  const mergedExpectedReplyMessages: Array<LocalExpectedReplyMessage> = [
    ...ermAsRequest,
    ...ermAsReply,
  ]

  const columns = [
    {
      key: 'erm-type',
      value: 'Type',
    },
    {
      key: 'erm-go-to-message',
      value: 'Message',
    },
    {
      key: 'erm-status',
      value: 'Status',
    },
    {
      key: 'erm-due-date',
      value: 'Due Date',
    },
    {
      key: 'erm-meta',
      value: 'Meta',
    },
  ]

  const rows = mergedExpectedReplyMessages.map(erm => ({
    id: erm.id,
    type: erm.type,
    status: erm.status,
    due: erm.due,
    meta: JSON.stringify(erm.meta),
    messageId: erm.messageId,
  }))

  return (
    <Layout.Box background="neutral-white">
      <TableContainer data-testid="erm-list">
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map(c => (
                <TableCell key={`table-column-${c.value}`}>{c.value}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-testid="erm-list-body">
            {rows.map(r => (
              <TableRow key={r.id}>
                <TableCell>
                  <Pill>{r.type}</Pill>
                </TableCell>
                <TableCell>
                  <Link target="_blank" href={`/messages/${r.messageId}`} hidden={!r.messageId}>
                    Go to message
                  </Link>
                </TableCell>
                <TableCell>
                  <Pill>{r.status}</Pill>
                </TableCell>
                <TableCell component="th" scope="row" width={200}>
                  {LSDate(r.due)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                </TableCell>
                <TableCell>{String(r.meta)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout.Box>
  )
}

export default ExpectedReplyMessagesView
