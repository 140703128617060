import { Chip } from '@mui/material'

export interface TradingPartnerTagProps {
  id: string
  name: string
  label?: string
}

export default function TradingPartnerTag(props: TradingPartnerTagProps) {
  const handleClick = () => {
    window.open(`trading-partner/${props.id}`, '_blank')
  }

  const slicedName =
    props.name.length > 15
      ? `${String(props.name).toUpperCase().slice(0, 15)}...`
      : props.name.toUpperCase()

  return (
    <Chip
      onClick={handleClick}
      onAuxClick={handleClick}
      label={slicedName}
      size="small"
      title={props.name}
    />
  )
}
