import { Tag } from '@loadsmart/loadsmart-ui'
import isEmpty from 'lodash.isempty'

export interface PushableEntityStatusTagProps {
  status: string
  size?: 'small' | 'default' | 'large'
}

const PushableEntityStatusTag = ({ status, size }: PushableEntityStatusTagProps) => {
  let formattedStatus = ''
  if (status) {
    formattedStatus = status.replaceAll('_', ' ')
  }
  const tagVariant = status != 'PUSHED' ? 'warning' : 'success'

  if (isEmpty(formattedStatus)) return <></>

  return (
    <Tag size={size || 'large'} variant={tagVariant as any}>
      {formattedStatus}
    </Tag>
  )
}

export default PushableEntityStatusTag
