/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useEffect, useState } from 'react'

import { Breadcrumbs, Label, Layout, Select, Table, TextField } from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import SimplePagination from 'atoms/SimplePagination'
import Header from 'atoms/Header'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM, DATE_FORMAT_YYYYMMDD } from 'common/Date.helpers'
import { removeToken } from 'common/helpers/removeToken'
import ErrorPanel from 'molecules/ErrorPanel'

import { ExpectedReplyMessage } from 'common/types/kraken-core/ExpectedReplyMessage'
import ExpcetedReplyRelatedMessage from 'pages/ExpectedReplyMessage/components/ExpectedReplyRelatedMessage'
import Pill from 'atoms/Pill'
import ExpectedReplyMessageStatusTag from 'pages/ExpectedReplyMessage/components/ExpectedReplyMessageStatusTag'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { LinearProgress } from '@mui/material'
import Field from 'atoms/Field'
import { DateRangePicker } from 'atoms/DatePicker'
import EventLike from 'common/types/EventLike'
import { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { Link } from 'react-router-dom'
import { STATUS_OPTIONS } from 'pages/ExpectedReplyMessage/common'
import { useSearchExpectedReplyMessages } from '../../api'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const COLUMNS = [
  {
    Header: '#',
    Cell(row: ExpectedReplyMessage) {
      const idAsString = row.id?.slice(0, 8) || '-'

      return (
        <Pill>
          <Link to={`expected-reply-messages/${row.id}`}>{idAsString}</Link>
        </Pill>
      )
    },
  },
  {
    Header: 'Status',
    Cell(row: ExpectedReplyMessage) {
      return <ExpectedReplyMessageStatusTag status={row.status} />
    },
  },
  {
    Header: 'Request',
    Cell(row: ExpectedReplyMessage) {
      return <ExpcetedReplyRelatedMessage relatedMessage={row.request_message} />
    },
  },
  {
    Header: 'Reply',
    Cell(row: ExpectedReplyMessage) {
      return <ExpcetedReplyRelatedMessage relatedMessage={row.reply_message} />
    },
  },
  {
    Header: 'Created at',
    accessor: 'created_at',
    alignment: 'right',
    Cell(row: ExpectedReplyMessage) {
      return LSDate(row.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM) || '-'
    },
  },
  {
    Header: 'Updated at',
    accessor: 'updated_at',
    alignment: 'right',
    Cell(row: ExpectedReplyMessage) {
      return LSDate(row.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM) || '-'
    },
  },
]

const ExpectedReplyMessageList = () => {
  useTabTitle('Expected Reply Messages')
  const [breadCrumbs] = useState([
    {
      label: 'Expected Reply Messages',
      active: true,
    },
  ])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [pageSizeLimit] = useState(30)
  const [createdAtRange, setCreatedAtRange] = useState<any>({
    start: null,
    end: null,
  })
  const [updatedAtRange, setUpdatedAtRange] = useState<any>({
    start: null,
    end: null,
  })

  const [filters, setFilters] = useState<any>({
    createdAtStart: '',
    createdAtEnd: '',
    updatedAtStart: '',
    updatedAtEnd: '',
  })
  const debouncedFilters = useDebouncedValue<any>(filters, 500)
  const { isFetching, isLoading, error, data } = useSearchExpectedReplyMessages({
    page,
    pageSize,
    ...debouncedFilters,
  })

  const topNavigationContext = useTopNavigationContext()

  const resetPagination = () => {
    setPage(1)
  }

  const handleFilterChange = (e: EventLike<any>) => {
    const { name, value } = e.target
    if (!name) return
    setFilters((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleDatePickerCreatedFilter = (event: EventLike<{ startDate: any; endDate: any }>) => {
    const { startDate } = event.target.value
    const { endDate } = event.target.value

    setCreatedAtRange({
      start: startDate,
      end: endDate,
    })

    setFilters(() => ({
      ...filters,
      createdAtEnd: endDate ? LSDate(endDate.toISOString())?.format(DATE_FORMAT_YYYYMMDD) : null,
      createdAtStart: startDate
        ? LSDate(startDate.toISOString())?.format(DATE_FORMAT_YYYYMMDD)
        : null,
    }))
  }

  const handleDatePickerUpdatedFilter = (event: EventLike<{ startDate: any; endDate: any }>) => {
    const { startDate } = event.target.value
    const { endDate } = event.target.value

    setUpdatedAtRange({
      start: startDate,
      end: endDate,
    })

    setFilters(() => ({
      ...filters,
      updatedAtEnd: endDate ? LSDate(endDate.toISOString())?.format(DATE_FORMAT_YYYYMMDD) : null,
      updatedAtStart: startDate
        ? LSDate(startDate.toISOString())?.format(DATE_FORMAT_YYYYMMDD)
        : null,
    }))
  }

  useEffect(() => {
    resetPagination()
  }, [filters])

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group className="w-full">
          <Breadcrumbs entries={breadCrumbs} />
        </Layout.Group>
      ),
    })
  }, [breadCrumbs])

  return (
    <>
      {isLoading ? (
        <Loading className="mt-8" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!data && (
          <Layout.Stack className="w-full mt-6">
            <Layout.Stack>
              <Layout.Group align="flex-end">
                <Field>
                  <Label>Partner Name</Label>
                  <TextField
                    id="partnerName"
                    name="partnerName"
                    data-testid="partner-filter-input"
                    onChange={handleFilterChange}
                  />
                </Field>
                <Field>
                  <Label>Status</Label>
                  <Select
                    id="status"
                    name="status"
                    data-testid="status-filter-input"
                    onChange={e =>
                      handleFilterChange({
                        ...e,
                        target: {
                          name: e.target.name,
                          value: (e.target.value as GenericOption)?.value,
                        },
                      })
                    }
                    options={STATUS_OPTIONS}
                  />
                </Field>
                <Field className="flex">
                  <Label>Created At</Label>
                  <DateRangePicker
                    name="created-date-range-filter-input"
                    startDate={createdAtRange.start}
                    endDate={createdAtRange.end}
                    onChange={handleDatePickerCreatedFilter}
                  />
                </Field>
                <Field className="flex">
                  <Label>Updated At</Label>
                  <DateRangePicker
                    name="updated-date-range-filter-input"
                    startDate={updatedAtRange.start}
                    endDate={updatedAtRange.end}
                    onChange={handleDatePickerUpdatedFilter}
                  />
                </Field>
              </Layout.Group>
            </Layout.Stack>
            <LinearProgress className={isFetching ? 'opacity-100' : 'opacity-0'} />
            <Table className="w-full" data-testid="expected-reply-message-list">
              <Table.Head>
                {COLUMNS.map(column => (
                  <Table.Cell key={column.Header} alignment={column.alignment as any}>
                    {column.Header}
                  </Table.Cell>
                ))}
              </Table.Head>
              <Table.Body>
                {data.results.map(row => (
                  <Table.Row key={row.id}>
                    {COLUMNS.map(cell => (
                      <Table.Cell
                        className="text-sm"
                        key={cell.Header}
                        alignment={cell.alignment as any}
                      >
                        {cell.Cell(row)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Layout.Box className="flex justify-end w-full">
              <SimplePagination
                currentPage={page}
                previousPage={() => setPage(prev => prev - 1)}
                nextPage={() => setPage(prev => prev + 1)}
                onPageSize={pageSize => setPageSize(pageSize)}
                canPreviousPage={!!data.previous}
                canNextPage={!!data.next}
                pageSize={pageSize}
                pageSizeLimit={pageSizeLimit}
              />
            </Layout.Box>
          </Layout.Stack>
        )
      )}
    </>
  )
}

export default ExpectedReplyMessageList
