import { useCallback, useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useHistory } from 'react-router'
import {
  Modal,
  Steps,
  Layout,
  Text,
  Button,
  TextField,
  Switch,
  Select,
  BannerAction,
} from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import { toast } from 'atoms/Toast'
import { ConnectionSetting } from 'common/types/kraken-core/ConnectionSettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import Loading from 'atoms/Loading'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'
import { ImportModalProps, ImportStep, ImportSteps } from '../types'
import {
  onSearchTradingPartners,
  useGetTradingPartner,
  useImportConnectionSetting,
  useSearchProtocols,
} from '../api'
import ConnectionSettingsDefitinion, {
  DefinitionValidationResult,
} from './ConnectionSettingsDefinition'

const ImportModal = ({ onClose, open, mode }: ImportModalProps) => {
  const history = useHistory()
  const [importedData, setImportedData] = useState<ConnectionSetting | null>()
  const [actualStep, setActualStep] = useState<ImportStep>('upload-step')
  const [formValidation, setFormValidation] = useState({
    isValid: false,
    errors: [],
    show: false,
  })

  const rawDefinition = useRef<any>()
  const definitionValidationResult = useRef<DefinitionValidationResult>()
  const {
    mutate,
    isLoading: importLoading,
    error: importError,
    data: importMutateData,
  } = useImportConnectionSetting()
  const { data: protocolsList } = useSearchProtocols()
  const { data: ownerData } = useGetTradingPartner(importedData?.owner)

  const validateForm = useCallback(
    (data: any) => {
      setFormValidation((previous: any) => {
        const errors = []
        if (!data.owner) errors.push('Owner is mandatory')
        if (!data.protocol) errors.push('Protocol is mandatory')
        if (!data.name) errors.push('Name is mandatory')

        if (!definitionValidationResult.current?.isValid) {
          errors.push(`Wrong definition: ${definitionValidationResult.current?.errors}`)
        }

        return {
          ...previous,
          isValid: errors.length === 0,
          errors,
          show: false,
        }
      })
    },
    [definitionValidationResult.current]
  )

  useEffect(() => {
    setActualStep(mode === 'import' ? 'upload-step' : 'verify-step')

    if (mode === 'create') {
      setImportedData({
        id: '',
        created_at: '',
        updated_at: '',
        is_active: true,
        name: '',
        protocol: '',
        owner: '',
        definition: {},
        vault: {
          id: '',
        },
      })
    }
  }, [mode])

  useEffect(() => {
    if (!importedData) return
    const { ...rest } = importedData
    validateForm(rest)
  }, [importedData, definitionValidationResult.current])

  const handleFileUploaded = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()

      reader.onerror = () => {
        toast.error("There's some problems in your file, please review.")
      }

      reader.onload = () => {
        const binaryStr = reader.result
        setImportedData(JSON.parse(binaryStr?.toString() || '{}'))
      }

      reader.readAsText(file)
    })
  }, [])

  const handleClose = () => {
    setImportedData(null)
    setActualStep('upload-step')
    onClose()
  }

  const handleImport = useCallback(() => {
    if (!importedData) return
    const { ...rest } = importedData

    let connectionDefinition

    try {
      connectionDefinition = rawDefinition.current || rest.definition
      if (!formValidation.isValid) {
        setFormValidation(previous => ({
          ...previous,
          show: true,
        }))
        return
      }

      mutate({
        ...rest,
        definition: connectionDefinition,
      })

      setActualStep('finish-step')
    } catch (error) {
      toast.warning("There's some problems in you form, please review.")
    }
  }, [rawDefinition.current, importedData, formValidation])

  const steps: ImportSteps =
    mode === 'import'
      ? [
          {
            complete: false,
            id: 'upload-step',
            label: 'Upload',
          },
          {
            complete: false,
            id: 'verify-step',
            label: 'Verify',
          },
          {
            complete: false,
            id: 'finish-step',
            label: 'Finish',
          },
        ]
      : [
          {
            complete: false,
            id: 'verify-step',
            label: 'Connection data',
          },
          {
            complete: false,
            id: 'finish-step',
            label: 'Finish',
          },
        ]

  const DATASOURCES = {
    TRADING_PARTNER: [
      () => ({
        type: 'string',
        adapter: {
          getKey: (tp: TradingPartner) => tp.id || '',
          getLabel: (tp: TradingPartner) => tp.name || '',
        },
        fetch: onSearchTradingPartners,
      }),
    ],
  }

  return (
    <Modal className="step-modal" scale="large" open={open}>
      <Steps current={actualStep} id="steps-import" steps={steps}>
        <Steps.Step id="upload-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            <Text variant="heading-sm-bold">Upload your Connection Settings JSON file</Text>

            <Dropzone onDrop={handleFileUploaded} accept="application/json">
              {({ getRootProps, getInputProps }) => (
                <div>
                  <div
                    {...getRootProps()}
                    className="
                                            bg-neutral-lighter p-8 border-dashed border-primary-500 border rounded
                                        "
                  >
                    <input {...getInputProps()} />
                    <p>Drag n drop some files here, or click to select files</p>
                  </div>
                </div>
              )}
            </Dropzone>

            {importedData && (
              <FileViewer
                options={{
                  readonly: true,
                  heigth: 250,
                  theme: FileViewTheme.DEFAULT,
                }}
                content={JSON.stringify(importedData, null, 2)}
                contentType="application/json"
                showOptions={false}
              />
            )}

            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Cancel</Button>

              <Button
                variant="primary"
                disabled={!importedData}
                onClick={() => setActualStep('verify-step')}
              >
                Next
              </Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>

        <Steps.Step id="verify-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            {formValidation.show && (
              <ErrorPanel
                title="There is something wrong with your form"
                error={String(formValidation.errors)}
              />
            )}

            <Layout.Group>
              <Layout.Stack className="pt-4 flex-1" space="none">
                <Text variant="heading-sm">Name</Text>
                <TextField
                  value={importedData?.name}
                  onChange={e =>
                    setImportedData(prevData => {
                      if (!prevData) return null

                      return {
                        ...prevData,
                        name: e.target.value,
                      }
                    })
                  }
                />
              </Layout.Stack>

              <Layout.Stack className="pt-4" space="s">
                <Text variant="heading-sm">Active</Text>
                <Switch
                  active={importedData?.is_active}
                  onToggle={e =>
                    setImportedData(prevData => {
                      if (!prevData) return null

                      return {
                        ...prevData,
                        is_active: e.target.checked,
                      }
                    })
                  }
                />
              </Layout.Stack>
            </Layout.Group>

            <Layout.Group>
              <Layout.Stack className="flex-1">
                <Text>Protocol</Text>
                {protocolsList && (
                  <Select
                    id="protocol"
                    name="protocol"
                    onChange={e =>
                      setImportedData(prevData => {
                        if (!prevData) return null

                        return {
                          ...prevData,
                          protocol: (e.target.value as any)?.value,
                        }
                      })
                    }
                    options={protocolsList.map(protocol => ({
                      label: protocol.description,
                      value: protocol.protocol,
                    }))}
                    value={
                      {
                        value: importedData?.protocol || '-',
                        label:
                          protocolsList.find(p => p.protocol === importedData?.protocol)
                            ?.description || '-',
                      } as Selectable
                    }
                  />
                )}
              </Layout.Stack>

              <Layout.Stack className="flex-1">
                <Text>Owner</Text>
                <Select
                  id="owner"
                  name="owner"
                  onChange={e =>
                    setImportedData(prevData => {
                      console.log(e.target.value)
                      if (!prevData) return null

                      return {
                        ...prevData,
                        owner: (e.target.value as TradingPartner)?.id || '',
                      }
                    })
                  }
                  value={
                    ownerData
                      ? ({
                          value: ownerData.id,
                          label: ownerData.name,
                        } as Selectable)
                      : null
                  }
                  datasources={DATASOURCES.TRADING_PARTNER}
                />
              </Layout.Stack>
            </Layout.Group>

            <Layout.Stack className="flex-1">
              <Text>Definition</Text>

              {importedData && (
                <ConnectionSettingsDefitinion
                  connection={importedData}
                  onChangeProtocolDefinition={value => {
                    if (!value) return
                    rawDefinition.current = {
                      ...importedData.definition,
                      [importedData.protocol]: value,
                    }
                  }}
                  onChangeRawDefinition={value => {
                    rawDefinition.current = JSON.parse(value || '')
                  }}
                  onValidationResult={value => {
                    definitionValidationResult.current = value
                    validateForm(rawDefinition.current)
                  }}
                />
              )}
            </Layout.Stack>

            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Cancel</Button>

              <Button variant="primary" onClick={handleImport}>
                {mode === 'import' && 'Import'}
                {mode === 'create' && 'Create'}
              </Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>

        <Steps.Step id="finish-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            {importLoading && <Loading className="mt-8 justify-center" />}
            {importError && (
              <ErrorPanel error={removeToken(JSON.stringify(importError, null, 2))} />
            )}
            {importMutateData && (
              <BannerAction
                action="Click here to see"
                onActionButtonClick={() => {
                  history.push(`connections/${importMutateData.response.data?.id}`)
                }}
                scale="default"
                title="Connection successfully imported."
                variant="success"
                dismissible={false}
              />
            )}
            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Close</Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>
      </Steps>
    </Modal>
  )
}

export default ImportModal
