import { IconButton, Layout } from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import { useEffect } from 'react'
import MessageInformation from '../MessageDetail/Accordion/MessageInformation'
import MessageDetailHeader from '../MessageDetail/MessageDetailHeader'
import MessageSummary from '../MessageDetail/MessageSummary'
import { useMessagesDashboardViewContext } from '../MessagesDashboardViewContext'
import Icon from 'atoms/Icon'
import { Drawer, LinearProgress, Tooltip } from '@mui/material'
import isEmpty from 'lodash.isempty'
import { toast } from 'react-toastify'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import { OpenInBrowser, OpenInFull, OpenInNew } from '@mui/icons-material'

export interface MessageQuickViewProps {
  open: boolean
  onClose?: () => void
  messageId?: string
}

const MessageQuickView = ({ open, messageId, onClose }: MessageQuickViewProps) => {
  const context = useMessagesDashboardViewContext()
  const message = context.messageDetails.message
  const isLoading = context.messageDetails.isLoading
  const messageDetailsLink = `/messages/${message?.id}`

  useEffect(() => {
    if (messageId && !isEmpty(messageId)) {
      context.readMessageDetails(messageId)
    }
  }, [messageId])

  return (
    <Drawer
      data-testid="message-quick-view"
      open={open}
      onClose={onClose}
      anchor="right"
      variant="persistent"
      PaperProps={{
        style: {
          width: '50%',
          overflowY: 'auto',
        },
        elevation: 10,
      }}
      BackdropProps={{
        invisible: true,
      }}
    >
      <Layout.Stack space="none">
        <div
          style={{
            height: '0px',
          }}
        >
          <LinearProgress
            data-testid="loading"
            style={{
              display: !isLoading ? 'none' : '',
            }}
          />
        </div>

        {message ? (
          <Layout.Stack style={{ overflow: 'clip', height: '100%' }} className="p-2">
            <Layout.Stack className="p-4 pb-2">
              <Layout.Group align="center" justify="space-between">
                <MessageDetailHeader message={message} />
                <Layout.Group align="center">
                  <Tooltip title="Reload">
                    <IconButton
                      onClick={e => {
                        analytics.event({
                          category: AnalyticsEvent.MessageDetailReload,
                          action: AnalyticsEventTrigger.click,
                        })
                        context.readMessageDetails(message.id, true)
                      }}
                      scale="default"
                    >
                      <Icon name="retry" size={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy message link">
                    <IconButton
                      onClick={e => {
                        analytics.event({
                          category: AnalyticsEvent.MessageDetailCopyLink,
                          action: AnalyticsEventTrigger.click,
                        })
                        navigator.clipboard.writeText(`${location.origin}${messageDetailsLink}`)
                        toast.info('Copied to clipboard')
                      }}
                      scale="default"
                    >
                      <Icon name="copy" size={20} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Open full screen">
                    <IconButton
                      onClick={e => {
                        analytics.event({
                          category: AnalyticsEvent.MessageDetailViewFullpage,
                          action: AnalyticsEventTrigger.click,
                        })
                        window.open(messageDetailsLink, '_blank')
                        e.preventDefault()
                      }}
                      scale="default"
                    >
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Close [Esc]">
                    <IconButton
                      onClick={e => {
                        analytics.event({
                          category: AnalyticsEvent.MessageDetailClose,
                          action: AnalyticsEventTrigger.click,
                        })
                        if (onClose) onClose()
                      }}
                      scale="default"
                    >
                      <Icon name="close" size={20} />
                    </IconButton>
                  </Tooltip>
                </Layout.Group>
              </Layout.Group>
            </Layout.Stack>
            <MessageSummary message={message} />
            <Layout.Stack style={{ overflow: 'auto' }}>
              <MessageInformation message={message} />
            </Layout.Stack>
          </Layout.Stack>
        ) : null}
      </Layout.Stack>
    </Drawer>
  )
}

export default MessageQuickView
