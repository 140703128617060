import { useEffect } from 'react'

export const useTabTitle = (title: string): Function => {
  const changeDocumentTitle = (title: string) => {
    document.title = title
  }

  useEffect(() => {
    changeDocumentTitle(title)
  }, [title])

  return changeDocumentTitle
}
