import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get, post, del, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import {
  NotificationType,
  TradingPartner,
  TradingPartnerInstructions,
  TradingPartnerContact,
  TradingPartnerContactType,
  NotificationSetting,
  TradingPartnerCode,
} from 'common/types/kraken-core/TradingPartner'
import { patch } from '../../../common/HTTPClient/HTTPRequest'

interface PaginationOptions {
  limit: number
  offset: number
}

interface TradingPartnerBased {
  tradingPartner: TradingPartner
}

export interface SearchTradingPartnerPageRequest {
  page?: number
  page_size?: number
  filters?: Array<any>
}

export interface GetTradingPartnerReadRequest {
  id: string
}

export interface UpdateTradingPartnerReadRequest {
  id: string
  instructions: TradingPartnerInstructions
}

export interface GetTradingPartnerContactHistory {
  id: string
  contact: Partial<TradingPartnerContact>
}

export interface UpdateTradingPartnerContact {
  id: string
  contact: Partial<TradingPartnerContact>
}

export interface CreateTradingPartnerContact {
  tradingPartnerId: string
  contact: Partial<TradingPartnerContact>
}

export interface DeleteNotificationSetting extends TradingPartnerBased {
  notificationSetting: NotificationSetting
}

export interface CreateNotificationSetting extends TradingPartnerBased {
  notificationSetting: Partial<NotificationSetting>
}

export interface CreateTradingPartnerCode extends TradingPartnerBased {
  type: string
  value: string
}

export interface UpdateNotificationSetting extends TradingPartnerBased {
  notificationSettings: Partial<NotificationSetting>[]
}

export interface UpdateTradingPartnerCode extends TradingPartnerBased {
  id: string
  type: string
  value: string
}

export interface DeleteTradingPartnerCode extends TradingPartnerBased {
  id: string
}

export interface UpdateTranslationSetting {
  tradingPartnerId: string
  translationSettings: Partial<any>
}

export interface UpdateComponentSetting {
  tradingPartnerId: string
  componentSettings: Partial<any>
}

export interface RevertMessageHistoryParams {
  messageId: string
  historyId: string
}

export interface SearchTradingPartnerPayload {
  count: number
  next: PaginationOptions
  previous: PaginationOptions
  results: TradingPartner[]
}

export interface SearchContactsPayload {
  next: PaginationOptions
  previous: PaginationOptions
  results: TradingPartnerContact[]
}

export interface SearchContactHistoryPayload extends TradingPartnerContact {
  history_id: number
  history_user?: string
  history_date: string
  history_change_reason?: string
}

export interface SearchTradingPartnerHistoryPayload extends TradingPartner {
  history_id: number
  history_user?: string
  history_date: string
  history_change_reason?: string
}

export interface SearchContactsRequest {
  id: string
  page?: number
  page_size?: number
}

export interface GetTradingPartnerPayload {
  id?: string
  name?: string
  created_at?: string
  updated_at?: string
  metadata?: any
  is_active?: boolean
  first_responder?: number
}

export async function searchTradingPartner(
  data?: SearchTradingPartnerPageRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchTradingPartnerPayload>> {
  let error = null
  let response = null

  const params: any = {
    page: data?.page || 1,
    page_size: data?.page_size || 100,
  }
  const filterByName = data?.filters?.find(f => f.property === 'name').value
  if (filterByName) params.name = filterByName
  ;[error, response] = await to(get('/trading_partners', { ...config, params }))

  return { error, response }
}

export async function getTradingPartner(
  data: GetTradingPartnerReadRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GetTradingPartnerPayload>> {
  let error = null
  let response = null
  ;[error, response] = await to(get(`/trading_partners/${data?.id}`, { ...config }))
  return { error, response }
}

export async function getTradingPartnerInstructions(
  data: GetTradingPartnerReadRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TradingPartnerInstructions>> {
  let error = null
  let response = null
  ;[error, response] = await to(get(`/trading_partners/${data?.id}/instructions`, { ...config }))
  return { error, response }
}

export async function updateTradingPartnerInstructions(
  data: UpdateTradingPartnerReadRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    post(`/trading_partners/${data.id}/instructions`, { ...data.instructions }, { ...config })
  )

  if (error) throw error

  return response?.data
}

export async function onSearchTradingPartner(): Promise<SearchTradingPartnerPayload> {
  const params = {
    page: 1,
    page_size: 25,
  }

  const [error, response] = await to(get('/trading_partners', { params }))

  if (error) throw error

  return response?.data
}

export async function revertMessageHistory(
  data: RevertMessageHistoryParams,
  config?: AxiosRequestConfig
): Promise<void> {
  await to(post(`/messages/${data?.messageId}/history/${data.historyId}/revert`, { ...config }))
}

export async function onSearchNotificationTypes(): Promise<NotificationType[]> {
  const [error, response] = await to(get('/notification/types'))

  if (error) throw error

  return response?.data
}

export async function onSearchContacts(
  data: SearchContactsRequest,
  config?: AxiosRequestConfig
): Promise<SearchContactsPayload> {
  const params = {
    page: data.page || 1,
    page_size: data.page_size || 25,
  }

  const [error, response] = await to(
    get(`/trading_partners/${data.id}/contacts`, { ...config, params })
  )

  if (error) throw error

  return response?.data
}

export async function onSearchContactHistory(
  data: GetTradingPartnerContactHistory,
  config?: AxiosRequestConfig
): Promise<SearchContactHistoryPayload[]> {
  const [error, response] = await to(
    get(`/trading_partners/${data.id}/contacts/${data.contact.id}/history`, {
      ...config,
    })
  )

  if (error) throw error

  return response?.data
}

export async function onGetTradingPartnerHistory(
  id: string,
  config?: AxiosRequestConfig
): Promise<SearchTradingPartnerHistoryPayload[]> {
  const [error, response] = await to(
    get(`/trading_partners/${id}/history`, {
      ...config,
    })
  )

  if (error) throw error

  return response?.data
}

export async function onSearchContactsTypes(): Promise<TradingPartnerContactType[]> {
  const [error, response] = await to(get('/contacts/types'))
  if (error) throw error
  return response?.data
}

export async function updateContact(
  data: UpdateTradingPartnerContact,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    put(
      `/trading_partners/${data.id}/contacts/${data.contact.id}`,
      { ...data.contact },
      { ...config }
    )
  )

  if (error) throw error

  return response?.data
}

export async function createContact(
  data: CreateTradingPartnerContact,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    post(`/trading_partners/${data.tradingPartnerId}/contacts`, { ...data.contact }, { ...config })
  )
  return { response, error }
}

export async function deleteContact(
  data: UpdateTradingPartnerContact,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    del(`/trading_partners/${data.id}/contacts/${data.contact.id}`, { ...config })
  )

  if (error) throw error

  return response?.data
}

export async function deleteNotificationSetting(
  data: DeleteNotificationSetting,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const filteredNotificationsSettings = data.tradingPartner.notification_settings
    ? data.tradingPartner.notification_settings.filter(
        setting => JSON.stringify(setting) !== JSON.stringify(data.notificationSetting)
      )
    : []
  const [error, response] = await to(
    post(
      `/trading_partners/${data.tradingPartner.id}/notification_settings`,
      [...filteredNotificationsSettings],
      {
        ...config,
      }
    )
  )

  if (error) throw error

  return response?.data
}

export async function createNotificationSetting(
  data: CreateNotificationSetting,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const newNotificationsSettings = [
    ...(data.tradingPartner.notification_settings ?? []),
    data.notificationSetting,
  ]

  const [error, response] = await to(
    post(
      `/trading_partners/${data.tradingPartner.id}/notification_settings`,
      newNotificationsSettings,
      {
        ...config,
      }
    )
  )

  if (error) throw error

  return response?.data
}

export async function updateNotificationSetting(
  data: UpdateNotificationSetting,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    post(
      `/trading_partners/${data.tradingPartner.id}/notification_settings`,
      data.notificationSettings,
      {
        ...config,
      }
    )
  )

  if (error) throw error

  return response?.data
}

export async function updateTranslationSetting(
  data: UpdateTranslationSetting,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    patch(
      `/trading_partners/${data.tradingPartnerId}/translation_settings`,
      data.translationSettings,
      {
        ...config,
      }
    )
  )
  if (error) throw error
  return response?.data
}

export async function updateComponentSetting(
  data: UpdateComponentSetting,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    patch(`/trading_partners/${data.tradingPartnerId}/component_settings`, data.componentSettings, {
      ...config,
    })
  )
  return { error, response }
}

export async function getTradingPartnerCodeTypes(
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GetTradingPartnerPayload>> {
  const [error, response] = await to(get(`/trading_partner/codes/types`, { ...config }))
  return { error, response }
}

export async function getTradingPartnerCodes(
  data: GetTradingPartnerReadRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<GetTradingPartnerPayload>> {
  const [error, response] = await to(get(`/trading_partners/${data?.id}/codes`, { ...config }))
  return { error, response }
}

export async function createCode(
  data: CreateTradingPartnerCode,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    post(
      `/trading_partners/${data.tradingPartner.id}/codes`,
      {
        type: data.type,
        value: data.value,
        owner: data.tradingPartner.id,
      },
      {
        ...config,
      }
    )
  )
  return { error, response }
}

export async function updateCode(
  data: UpdateTradingPartnerCode,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    patch(
      `/trading_partners/${data.tradingPartner.id}/codes/${data.id}`,
      {
        type: data.type,
        value: data.value,
      },
      {
        ...config,
      }
    )
  )
  return { error, response }
}

export async function deleteCode(
  data: DeleteTradingPartnerCode,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(
    del(`/trading_partners/${data.tradingPartner.id}/codes/${data.id}`)
  )
  return { error, response }
}
