/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { GatewaySetting } from 'common/types/kraken-core/GatewaySettings'

export enum QUERIES {
  SEARCH_GATEWAY_SETTINGS = 'gateway_settings/search',
  GET_GATEWAY_SETTINGS = 'gateway_settings/get',
  SEARCH_GATEWAY_TYPES = 'gateway_types/search',
  SEARCH_CONNECTION_PROTOCOLS = 'connection_protocols/search',
  GET_TRADING_PARTNER = 'trading_partner/get',
  GET_GATEWAY_SETTINGS_HISTORY = 'gateway_settings_history/get',
  GET_GATEWAY_SETTINGS_SCHEMA = 'gateway_settings/types/schema',
}

export type ImportStep =
  | 'upload-step'
  | 'gateway-verify-step'
  | 'connection-verify-step'
  | 'finish-step'

export type ImportSteps = {
  complete: boolean
  id: ImportStep
  label: string
}[]

export interface useSearchGatewaySettingsParams {
  page?: number
  page_size?: number
  code?: string
}

export interface useGetGatewaySettingsParams {
  id: string
}

export interface ImportModalProps {
  onClose: () => void
  open: boolean
  mode: 'import' | 'create' | null
}

export type ImportedGatewaySetting = Partial<Omit<GatewaySetting, 'connection'>> & {
  rawDefinition?: string
  rawUseWhen?: string
  connection?: {
    name?: string
    is_active?: boolean
    protocol?: string
    owner?: string
    definition?: {
      [key: string]: object
    }
    rawDefinition?: string
    vault?: object
  }
  connectionId?: string
}

export interface useGetGatewaySchemaParams {
  gateway_type: string
}
