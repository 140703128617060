import { ReactNode } from 'react'
import { Box } from '@mui/material'
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import './ClipboardLabel.css'

export interface ClipboardLabelProps {
  className?: string
  value?: string
  disabled?: boolean
  trailing?: ReactNode
}

function ClipboardLabel({ className, value, disabled, trailing }: ClipboardLabelProps) {
  const { enqueueSnackbar } = useSnackbar()

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(value || '').then(() =>
      enqueueSnackbar('Copied to clipboard!', {
        autoHideDuration: 1000,
      })
    )
  }

  return (
    <Box
      className={clsx(
        'clipboard-label text-neutral-dark flex flex-row items-center p-1',
        className
      )}
      onClick={handleCopyToClipboard}
    >
      <FileCopyOutlined fontSize="small" className="m-2" />
      {value}
      {trailing}
    </Box>
  )
}

export default ClipboardLabel
