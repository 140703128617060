import { HTMLAttributes, ReactNode } from 'react'
import clsx from 'clsx'
import Feedback from '../Feedback'
import Status from '../../common/types/Status'

export interface FieldProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  error?: string
  children: ReactNode
}

function Field({ className, children, error, ...others }: FieldProps) {
  return (
    <div className={clsx('flex flex-col space-y-2', className)} {...others}>
      {children}
      {Boolean(error) && <Feedback status={Status.Danger}>{error}</Feedback>}
    </div>
  )
}

export default Field
