const uiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'General',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Advice',
              options: {
                adviceType: 'info',
                adviceValue: 'The <b>Route</b> the message will be forwarded to',
              },
            },
            {
              type: 'Control',
              scope: '#/properties/webhook/properties/Route-Code',
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Request',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/webhook/properties/request',
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Response',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Advice',
              options: {
                adviceType: 'warning',
                adviceValue:
                  'Response settings are temporarily unavailable on forms view, go to raw visualization to set them',
              },
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Throttle',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/webhook/properties/throttle',
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Permissions',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/webhook/properties/HTTP-Permission',
            },
          ],
        },
      ],
    },
  ],
}

export default uiSchema
