import { Button, Label, Layout, Switch, Text } from '@loadsmart/loadsmart-ui'
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material'
import { MessageRead } from 'common/types/kraken-core/Message'
import { useEffect, useState, useCallback, useRef, useContext } from 'react'
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined'
import FileViewer from 'molecules/FileViewer/FileViewer'
import isJson from 'common/helpers/isJSON'
import ExternalLink from 'atoms/ExternalLink'
import { useUpdateMessageHeaders } from 'pages/Messages/api'
import { toast } from 'atoms/Toast'
import { useMessagesDashboardViewContext } from 'pages/Messages/MessagesDashboardViewContext'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'

export interface MessageHeadersTablePros {
  message?: MessageRead
  readOnly?: boolean | true
}

export interface MessageHeaderFieldProps {
  className?: string
  headerKey: string
  headerValue: any
  onChange: (value: any) => void
  readOnly: boolean
}

const HIGHLIGHTED_HEADERS = [
  'Transaction-Type',
  'Pre-Translation-Content-Type',
  'Content-Type',
  'Delivered',
  'Direction',
  'Ignored',
  'Last-Step',
  'Route-Code',
  'Translation-Model-Version',
  'Translation-Map',
  'Validation-Errors',
]

const headerURLs: { [x: string]: (v: string) => string } = {
  'Input-Gateway-UUID': rawValue => `/gateways/${rawValue}`,
  'Input-Gateway': rawValue => `/gateways/${rawValue}`,
  'Output-Gateway-UUID': rawValue => `/gateways/${rawValue}`,
  'Route-Code': rawValue => `/routes/code/${rawValue}`,
  'Translation-Map': rawValue => `/translation-maps/runtime/${rawValue}`,
  'Parent-Id': rawValue => `/messages/${rawValue}`,
}

function MessageHeaderField({
  headerKey,
  headerValue,
  onChange,
  readOnly,
}: MessageHeaderFieldProps) {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(headerValue || '').then(() =>
      toast.info('Copied to clipboard!', {
        autoClose: 1000,
      })
    )
  }
  const isDiabled = ['object', 'number'].indexOf(typeof headerValue) >= 0
  return (
    <Layout.Box padding="none">
      <Layout.Group>
        {typeof headerValue === 'boolean' && (
          <Switch
            onToggle={(event: any) => {
              onChange(!headerValue)
            }}
            active={headerValue}
            disabled={readOnly}
          />
        )}

        {typeof headerValue !== 'boolean' && (
          <TextField
            id="input-with-icon-textfield"
            value={headerValue}
            disabled={isDiabled || readOnly}
            onChange={event => {
              onChange(event.target.value)
            }}
            InputProps={{
              inputMode: typeof headerValue === 'number' ? 'numeric' : 'text',
              startAdornment: (
                <InputAdornment position="start">
                  <FileCopyOutlined
                    fontSize="small"
                    onClick={handleCopyToClipboard}
                    role="button"
                  />
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="standard"
          />
        )}
      </Layout.Group>
    </Layout.Box>
  )
}

function MessageHeadersTable({ message, readOnly = true }: MessageHeadersTablePros) {
  const context = useMessagesDashboardViewContext()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [headers, setHeaders] = useState<Array<any>>([])
  const [rawView, setRawView] = useState<boolean>(false)

  const {
    mutateAsync: updateMessageHeaders,
    error: updateMessageHeadersError,
    reset: resetUpdateMessageHeaders,
  } = useUpdateMessageHeaders()

  const rawHeaders = useRef<any>(undefined)

  const readMessageHeaders = useCallback(() => {
    if (!message || !message.headers) return

    const h = Object.keys(message.headers)
      .map((k: any) => ({
        key: k,
        value: message.headers[k] ?? '',
        highlighted: HIGHLIGHTED_HEADERS.includes(k),
      }))
      .sort((a, b) => (a.key > b.key ? 1 : -1))

    setHeaders(h)
  }, [message])

  const updateHeader = (headerKey: string, headerValue: any) => {
    const otherHeaders = headers.filter(h => h.key !== headerKey)
    const targetHeader = headers.find(h => h.key === headerKey)
    const updatedHeaders = [
      ...otherHeaders,
      {
        ...targetHeader,
        value: headerValue,
      },
    ].sort((a: any, b: any) => (a.key >= b.key ? 1 : -1))
    setHeaders(updatedHeaders)
    setIsDirty(true)
  }

  const getParsedHeaders = () => Object.fromEntries(headers.map(h => [h.key, h.value]))

  const handleSave = async () => {
    analytics.event({
      category: AnalyticsEvent.MessageDetailUpdateTabContent,
      action: AnalyticsEventTrigger.change,
      params: {
        tabName: 'headers',
      },
    })
    if (!message || !message.id) {
      toast.error('There is no available message in the context')
      return
    }

    let headersToUpdate: any
    if (!rawView) {
      headersToUpdate = getParsedHeaders()
    } else {
      headersToUpdate = rawHeaders.current
    }

    await updateMessageHeaders({
      headers: headersToUpdate,
      id: message?.id,
    })

    if (updateMessageHeadersError) {
      toast.error(`Something didn't work as expected: ${updateMessageHeadersError}`)
      return
    }
    toast.success('Headers successfully updated')

    // Clean
    rawHeaders.current = undefined
    resetUpdateMessageHeaders()
    setIsDirty(false)
    context.readMessageDetails(message.id)
  }

  useEffect(() => {
    readMessageHeaders()
  }, [message])

  useEffect(() => {
    if (!rawView) {
      readMessageHeaders()
    }
  }, [rawView])

  return (
    <Layout.Stack space="none" className="p-4">
      <Layout.Box className="flex" padding="m">
        <Layout.Group align="center">
          <Label>Raw View</Label>
          <Switch onToggle={() => setRawView(v => !v)} active={rawView} />
        </Layout.Group>

        {!readOnly && (
          <div className="flex flex-1 justify-end p-1">
            <Button onClick={() => handleSave()} variant="primary" disabled={!isDirty}>
              Save
            </Button>
          </div>
        )}
      </Layout.Box>

      <Layout.Stack>
        {!rawView && (
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Text variant="heading-sm-bold">Primary</Text>
                  </TableCell>
                </TableRow>
                {headers
                  .filter(row => row.highlighted)
                  .map(row => (
                    <TableRow key={`table-row-${row.key}`}>
                      <TableCell component="th" scope="row" width={window.innerWidth * 0.2}>
                        {/* <Tag size="large"> */}
                        <Layout.Group align="center">
                          <Text variant="heading-sm">{String(row.key)}</Text>
                          {headerURLs[row.key] && (
                            <ExternalLink
                              fontSize="small"
                              className=""
                              href={headerURLs[row.key]?.(row.value)}
                              target="blank"
                            />
                          )}
                        </Layout.Group>
                        {/* </Tag> */}
                      </TableCell>

                      <TableCell>
                        <MessageHeaderField
                          headerKey={row.key}
                          headerValue={row.value}
                          readOnly={readOnly}
                          onChange={(value: any) => {
                            analytics.event({
                              category: AnalyticsEvent.MessageDetailUpdateTabContent,
                              action: AnalyticsEventTrigger.change,
                              params: {
                                target: 'headers',
                                value: {
                                  [row.key]: value,
                                },
                              },
                            })
                            updateHeader(row.key, value)
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {!rawView && (
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Text variant="heading-sm-bold">Others</Text>
                  </TableCell>
                </TableRow>

                {headers
                  .filter(row => !row.highlighted)
                  .map(row => (
                    <TableRow key={`table-row-${row.key}`}>
                      <TableCell component="th" scope="row" width={window.innerWidth * 0.2}>
                        <Layout.Group align="center">
                          <Text variant="heading-sm">{String(row.key)}</Text>
                          {headerURLs[row.key] && (
                            <ExternalLink
                              fontSize="small"
                              className=""
                              href={headerURLs[row.key]?.(row.value)}
                              target="blank"
                            />
                          )}
                        </Layout.Group>
                      </TableCell>
                      <TableCell>
                        <MessageHeaderField
                          headerKey={row.key}
                          headerValue={row.value}
                          readOnly={readOnly}
                          onChange={(value: any) => {
                            analytics.event({
                              category: AnalyticsEvent.MessageDetailUpdateTabContent,
                              action: AnalyticsEventTrigger.change,
                              params: {
                                target: 'headers',
                                value: {
                                  [row.key]: value,
                                },
                              },
                            })
                            updateHeader(row.key, value)
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Layout.Stack>

      {rawView && (
        <FileViewer
          content={JSON.stringify(getParsedHeaders())}
          contentType="application/json"
          onChange={(value: any) => {
            if (!isJson(value)) return
            const parsedHeaders = JSON.parse(value)
            rawHeaders.current = parsedHeaders
            setIsDirty(true)
          }}
          options={{
            heigth: '500px',
          }}
        />
      )}
    </Layout.Stack>
  )
}

export default MessageHeadersTable
