import { ConnectionSetting } from 'common/types/kraken-core/ConnectionSettings'
import { GatewaySetting, GATEWAY_TYPES } from 'common/types/kraken-core/GatewaySettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { searchConnectionSettings } from 'services/kraken-core/connection_settings/connection_settings.service'
import { searchTradingPartner } from 'services/kraken-core/trading_partner/tradingPartner.service'
import { searchGatewaySettings } from 'services/kraken-core/gateway_settings/gateway_settings.service'
import { RouteList } from 'common/types/kraken-core/Routes'
import { searchRoutes } from 'services/kraken-core/routes/routes.service'
import { Partnership } from 'common/types/kraken-core/Partnership'
import { searchPartnership } from 'services/kraken-core/partnership/partnership.service'
import { searchMessageStatusesChoises } from 'services/kraken-core/messages/messages.service'
import { Installation, IntegrationModule, MessageStatus } from 'common/types/kraken-core/Message'
import { listEngines } from 'services/kraken-core/data_model/dataModel.service'
import { DataModelSchemaEngineList } from 'common/types/kraken-core/DataModelSchema'
import { searchInstallations } from 'services/kraken-core/installations/installations.service'
import { searchIntegrationModule } from '../../../services/kraken-core/integration_module/integration.service'

export const onSearchTradingPartners = async ({
  query,
}: {
  query: string
}): Promise<TradingPartner[]> => {
  const result = await searchTradingPartner({
    filters: [
      {
        property: 'name',
        value: query,
      },
    ],
    page_size: 100,
  })
  return result?.response?.data?.results
}

export const onSearchConnections = async ({
  query,
}: {
  query: string
}): Promise<ConnectionSetting[]> => {
  const params = { name: query }
  const result = await searchConnectionSettings({
    ...params,
    page_size: 100,
  })
  return result?.response?.data?.results
}

export const onSearchGateways = async ({ query }: { query: string }): Promise<GatewaySetting[]> => {
  const params = { name: query }
  const result = await searchGatewaySettings({
    ...params,
    page_size: 100,
  })
  return result?.response?.data?.results
}

export const onSearchInputGateways = async ({
  query,
}: {
  query: string
}): Promise<GatewaySetting[]> => {
  const params = { name: query }
  const result = await searchGatewaySettings({
    ...params,
    gateway_type: GATEWAY_TYPES.INPUT_WEB,
    page_size: 100,
  })
  return result?.response?.data?.results
}

export const onSearchPolledGateways = async ({
  query,
}: {
  query: string
}): Promise<GatewaySetting[]> => {
  const params = { name: query }
  const result = await searchGatewaySettings({
    ...params,
    gateway_type: GATEWAY_TYPES.INPUT_POLLED,
    page_size: 100,
  })
  return result?.response?.data?.results
}

export const onSearchRoute = async ({ query }: { query: string }): Promise<RouteList[]> => {
  const result = await searchRoutes({
    page_size: 200,
    filters: [
      {
        property: 'code',
        value: query,
      },
    ],
  })
  return result?.response?.data?.results
}

export const onSearchPartnership = async ({ query }: { query: string }): Promise<Partnership[]> => {
  const params = { name: query }
  const result = await searchPartnership({
    ...params,
    page_size: 100,
  })
  return result?.response?.data?.results
}

export const onSearchMessageStatus = async ({
  query,
}: {
  query: string
}): Promise<MessageStatus[]> => {
  const params = { status: query }
  const [error, result] = await searchMessageStatusesChoises({
    ...params,
    page_size: 100,
  })
  return result?.data || []
}

export const onSearchDataModelSchemaEngines = async ({
  query,
}: {
  query: string
}): Promise<DataModelSchemaEngineList[]> => {
  const params = { status: query }
  const response = await listEngines({
    ...params,
    page_size: 100,
  })
  return response?.results || []
}

export const onSearchInstallations = async ({
  query,
}: {
  query: string
}): Promise<Installation[]> => {
  const result = await searchInstallations({
    filters: [
      {
        property: 'name',
        value: query,
      },
      {
        property: 'installation_type',
        value: 'kac',
      },
    ],
    page_size: 100,
  })
  return result?.response?.data?.results
}

export const onSearchIntegrationModules = async ({
  query,
}: {
  query: string
}): Promise<IntegrationModule[]> => {
  const result = await searchIntegrationModule({
    filters: [
      {
        property: 'name',
        value: query,
      },
    ],
    page_size: 100,
  })
  return result?.response?.data?.results
}
