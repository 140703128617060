import { Box } from '@mui/material'
import { INodeInnerDefaultProps } from '@mrblenny/react-flow-chart'
import { Link } from '@loadsmart/loadsmart-ui'
import LSString from 'common/String.helpers'
import { HandleOpenModal } from '../types'

const NodeCustom = ({ handleOpenModal }: { handleOpenModal: HandleOpenModal }) => ({
  node,
}: INodeInnerDefaultProps) => {
  const rawHeaders = node.properties?.headers ?? {}
  const rawPrevHeaders = node.properties?.prevHeaders ?? {}
  const hasFailures = node.properties?.hasFailures
  const hasHistory = Object.keys(rawHeaders).length >= 1

  if (node.type === 'child') {
    return (
      <Box className="child bg-neutral-lighter text-sm p-2">
        <Box>Splitted message</Box>
        <Link href={`/messages/${node.properties?.stepName}`} target="_blank">
          {node.properties?.stepName}
        </Link>
      </Box>
    )
  }

  return (
    <Box
      className={`step p-2 ${hasFailures ? 'step--failure' : ''} ${
        !hasHistory ? 'step--disabled' : ''
      }`}
      onClick={() =>
        hasHistory &&
        handleOpenModal({
          headers: rawHeaders,
          prevHeaders: rawPrevHeaders,
        })
      }
    >
      <Box>
        {node.properties.description || LSString.normalizeCode(node.properties?.stepName ?? '')}
      </Box>
    </Box>
  )
}

export default NodeCustom
