import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './ToastHost.css'

function ToastHost() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={7500}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
    />
  )
}

export default ToastHost
