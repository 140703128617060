/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Breadcrumbs,
  Layout,
  Text,
  Tabs,
  Dropdown,
  Button,
  Banner,
  ToggleGroup,
  TextField,
  Select,
  Drawer,
  LoadingDots,
  Switch,
  Tag,
  Card,
} from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import { removeToken } from 'common/helpers/removeToken'
import ErrorPanel from 'molecules/ErrorPanel'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import { GatewayType } from 'common/types/kraken-core/GatewaySettings'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { ConnectionSetting } from 'common/types/kraken-core/ConnectionSettings'
import { useGetTradingPartner } from 'pages/TradingPartner/api'
import { useGetConnectionSettings } from 'pages/Connections/api'

import TestExecutionResultComponent from 'molecules/TestExecutionResult/TestExecutionResultComponent'
import Icon from 'atoms/Icon'
import GatewaySettingsDefitinion, {
  DefinitionValidationResult,
} from 'pages/Gateway/components/GatewaySettingsDefinition'
import HistoryComponent from 'pages/Gateway/components/HistoryComponent'
import {
  onExport,
  onSearchConnections,
  onSearchGatewayTypes,
  useGetGatewaySettings,
  useTestGatewaySetting,
  useUpdateGatewaySetting,
} from '../api'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import ConditionalCallableLink from 'atoms/EntityInspectorLink/ConditionalCallableLink'
import PartnershipSelector from 'common/components/selectors/PartnershipSelector'
import { Public, SecurityRounded } from '@mui/icons-material'
import isEmpty from 'lodash.isempty'
import { Tooltip } from '@mui/material'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import IaCComponentReferenceWarning from 'atoms/IaCComponentReferenceWarning/IaCComponentReferenceWarning'

const GatewaySettingsDetails = () => {
  useTabTitle('Gateway Settings')
  const browserHistory = useHistory()
  const { id } = useParams<{ id: string }>()
  const [breadCrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([
    {
      label: 'Gateway settings',
      active: true,
      url: '#',
      onClick: () => {
        browserHistory.push('/gateways')
      },
    },
  ])

  const [gatewayData, setGatewayData] = useState<any>()
  const [cachedGatewayData, setCachedGatewayData] = useState<any>()
  const [connectionData, setConnectionData] = useState<ConnectionSetting>()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isTestPanelOpened, setTestPanelOpened] = useState<boolean>(false)
  const [errors, setErrors] = useState<string | undefined>()

  const rawDefinition = useRef<any>()
  const rawGatewayTypeDefinition = useRef<any>()
  const definitionValidationResult = useRef<DefinitionValidationResult>()

  const handleSave = useRef<Function>(() => {})
  const handleTestExecution = useRef<Function>(() => {})
  const handleExport = useRef<Function>(() => {})

  const { isLoading, error, data: rawData } = useGetGatewaySettings({ id })
  const {
    data: ownerData,
    refetch: refetchOwnerData,
    isLoading: isLoadingOwner,
    isRefetching: isRefetchingOwner,
  } = useGetTradingPartner(connectionData?.owner)
  const { refetch: refetchConnection } = useGetConnectionSettings(
    { id: gatewayData?.connection },
    {
      onSuccess: (data: ConnectionSetting) => {
        setConnectionData(data)
      },
    }
  )
  const {
    mutate: updateGatewaySetting,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    error: updatedError,
    reset: updateReset,
  } = useUpdateGatewaySetting()

  const {
    mutate: testGatewaySettings,
    data: testGatewayResult,
    isSuccess: didTestSuccessfully,
    isLoading: isRunningTest,
    error: testFailedError,
  } = useTestGatewaySetting()

  useEffect(() => {
    if (rawData) {
      setBreadcrumbs((prev: any) => [
        prev[0],
        {
          label: rawData.name,
        },
      ])

      // Live update data
      setGatewayData(rawData)

      // Cached data
      setCachedGatewayData({
        name: rawData.name,
      })
    }
  }, [rawData])

  useEffect(() => {
    setGatewayData((prev: any) =>
      prev
        ? {
            ...prev,
            owner_description: ownerData?.name,
          }
        : null
    )
  }, [ownerData?.name])

  useEffect(() => {
    setGatewayData((prev: any) =>
      prev
        ? {
            ...prev,
            connection_description: connectionData?.name,
          }
        : null
    )
  }, [connectionData?.name])

  useEffect(() => {
    if (updatedError) {
      toast.error(`Something went wrong: ${String(updatedError)}`)
    }

    if (isUpdated) {
      toast.success('Updated with success')
    }

    setTimeout(() => {
      updateReset()
    }, 0)
  }, [isUpdated, updatedError])

  useEffect(() => {
    setIsDirty(true)
    setErrors(undefined)
  }, [rawGatewayTypeDefinition, rawDefinition])

  const connectionValue = useMemo(
    () => ({
      value: gatewayData?.connection,
      label: gatewayData?.connection_description,
    }),
    [gatewayData?.connection_description]
  )

  const partnershipValue = useMemo(
    () => ({
      id: gatewayData?.partnership,
      label: undefined,
    }),
    [gatewayData?.partnership]
  )

  const typeValue = useMemo(
    () => ({
      value: gatewayData?.gateway_type,
      label: gatewayData?.gateway_type_description || gatewayData?.gateway_type,
    }),
    [gatewayData?.gateway_type]
  )

  const handleChangeAndUpdate = (key: any, value: any) => {
    setGatewayData((prev: any) =>
      prev
        ? {
            ...prev,
            [key]: value,
          }
        : null
    )
    setIsDirty(true)
  }

  const handleChangeAndCache = (key: any, value: any) => {
    setCachedGatewayData((prev: any) => ({
      ...prev,
      [key]: value,
    }))
    setIsDirty(true)
  }

  handleExport.current = () => {
    if (!gatewayData?.id) return

    onExport(gatewayData.id, gatewayData.name)
  }

  handleTestExecution.current = () => {
    if (!gatewayData?.id) return
    testGatewaySettings({ ...gatewayData })
    setTestPanelOpened(true)
  }

  const onConnectionChange = useCallback(async (newConnection: ConnectionSetting) => {
    if (!newConnection) {
      setConnectionData(undefined)
      return
    }
    handleChangeAndUpdate('connection', newConnection.id)
    handleChangeAndUpdate('connection_description', newConnection.name)
    refetchOwnerData()
    await refetchConnection()
  }, [])

  handleSave.current = async () => {
    setErrors(undefined)

    if (definitionValidationResult.current && !definitionValidationResult.current.isValid) {
      setErrors(`Definition is not valid`)
      return
    }

    const data: any = {
      ...gatewayData,
      ...cachedGatewayData,
    }

    // Use raw definition or protocol definition
    if (rawDefinition.current) {
      data.definition = rawDefinition.current
    } else if (gatewayData?.gateway_type) {
      data.definition = {
        ...data.definition,
        [gatewayData.gateway_type]: rawGatewayTypeDefinition.current,
      }
    }
    updateGatewaySetting(data)
  }

  const DIRECTION_FILTER_VALUES = [
    {
      label: 'Inbound',
      value: 'inbound',
    },
    {
      label: 'Outbound',
      value: 'outbound',
    },
  ]

  const DATASOURCES = {
    TYPES: [
      () => ({
        type: 'string',
        adapter: {
          getKey: (gt: GatewayType) => gt.gateway_type || '',
          getLabel: (gt: GatewayType) => gt.description || '',
        },
        fetch: onSearchGatewayTypes,
      }),
    ],
    CONNECTIONS: [
      () => ({
        type: 'string',
        adapter: {
          getKey: (tp: ConnectionSetting) => tp.id || '',
          getLabel: (tp: ConnectionSetting) => tp.name || '',
        },
        fetch: onSearchConnections,
      }),
    ],
  }

  const settings = useMemo(() => {
    const definitions = (
      <GatewaySettingsDefitinion
        gateway={gatewayData}
        connection={connectionData}
        onChangeGatewayTypeDefinition={(value: any) => {
          if (!value) return
          rawGatewayTypeDefinition.current = value
        }}
        onChangeRawDefinition={value => {
          rawDefinition.current = JSON.parse(value || '')
        }}
        onValidationResult={(result: DefinitionValidationResult) => {
          definitionValidationResult.current = result
        }}
      />
    )

    if (gatewayData && connectionData) {
      return definitions
    } else {
      return <LoadingDots />
    }
  }, [gatewayData, connectionData])

  const history = useMemo(() => <HistoryComponent id={id} />, [id])

  const topNavigationContext = useTopNavigationContext()

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group align="center" className="flex-1">
          <Layout.Box padding="none" className="flex-1">
            <Breadcrumbs entries={breadCrumbs} />
          </Layout.Box>
          <Button
            variant="primary"
            disabled={!isDirty || isUpdating}
            onClick={() => handleSave.current()}
          >
            Save
          </Button>
          <Button trailing={<Icon name="bolt" />} onClick={() => handleTestExecution.current()}>
            Test
          </Button>
          <div>
            <Dropdown>
              <Dropdown.Trigger>Actions</Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport.current()}>Export</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs, isDirty, isUpdating])

  return (
    <Layout.Stack className="p- w-full" space="s">
      {errors && (
        <Banner scale="default" title={String(errors)} variant="danger" dismissible={false} />
      )}

      <Drawer open={isTestPanelOpened}>
        <Drawer.Header>Test Result</Drawer.Header>
        <Drawer.Body className="h-full">
          {testFailedError && <ErrorPanel error={String(testFailedError)} title="" />}
          {isRunningTest && <LoadingDots />}
          {!isRunningTest && testGatewayResult && (
            <TestExecutionResultComponent result={testGatewayResult} />
          )}
        </Drawer.Body>
        <Drawer.Footer>
          <Button onClick={() => setTestPanelOpened(false)}>Close</Button>
        </Drawer.Footer>
      </Drawer>

      {isLoading ? (
        <Loading className="mt-8 justify-center" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!gatewayData && (
          <Card>
            <Card.Body>
              <Layout.Stack space="s">
                {gatewayData.iac_reference ? <IaCComponentReferenceWarning /> : null}
                <Layout.Group space="m" className="mt-2 mb-4" justify="space-between">
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      ID
                    </Text>
                    <Text variant="heading-sm-bold">{gatewayData.id}</Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Created at
                    </Text>
                    <Text variant="heading-sm-bold">
                      {LSDate(gatewayData.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Updated at
                    </Text>
                    <Text variant="heading-sm-bold">
                      {LSDate(gatewayData.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Deleted at
                    </Text>
                    <Text variant="heading-sm-bold">
                      {LSDate(gatewayData.deleted_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Endpoint URLs
                    </Text>
                    <Layout.Group>
                      {!isEmpty(gatewayData.private_endpoint) ? (
                        <Tooltip title={`Private: ${gatewayData.private_endpoint}`}>
                          <SecurityRounded
                            onClick={() => {
                              navigator.clipboard.writeText(gatewayData.private_endpoint)
                              toast.info('Copied!')
                            }}
                            className="cursor-pointer"
                          />
                        </Tooltip>
                      ) : null}

                      {!isEmpty(gatewayData.public_endpoint) ? (
                        <Tooltip title={`Public: ${gatewayData.public_endpoint}`}>
                          <Public
                            onClick={() => {
                              navigator.clipboard.writeText(gatewayData.public_endpoint)
                              toast.info('Copied!')
                            }}
                            className="cursor-pointer"
                          />
                        </Tooltip>
                      ) : null}
                    </Layout.Group>
                  </Layout.Stack>
                  <Layout.Group data-testid="gateway-setting-active">
                    <Layout.Stack space="xs">
                      <Text variant="caption-bold" color="color-neutral">
                        Active
                      </Text>
                      <Switch
                        id="is_active"
                        name="is_active"
                        className="flex justify-center"
                        onToggle={e => handleChangeAndUpdate('is_active', !gatewayData.is_active)}
                        active={gatewayData.is_active}
                      />
                    </Layout.Stack>
                  </Layout.Group>
                </Layout.Group>

                <Layout.Group space="m" className="mb-2 w-full" justify="space-between">
                  <Layout.Stack data-testid="gateway-setting-name" space="s" className="w-3/4">
                    <Text>Name</Text>
                    <TextField
                      value={cachedGatewayData.name}
                      onChange={e => handleChangeAndCache('name', e.target.value)}
                    />
                  </Layout.Stack>
                  <Layout.Stack space="s" data-testid="gateway-setting-direction">
                    <Text>Direction</Text>
                    <ToggleGroup
                      id="direction"
                      name="direction"
                      className="flex justify-center"
                      onChange={e => handleChangeAndUpdate('direction', e.target.value)}
                      options={DIRECTION_FILTER_VALUES}
                      value={gatewayData.direction}
                    />
                  </Layout.Stack>
                </Layout.Group>

                <Layout.Group space="s">
                  <Layout.Stack space="s" data-testid="gateway-setting-type">
                    <Text>Gateway Type</Text>
                    <Select
                      id="gateway_type"
                      name="gateway_type"
                      onChange={e => {
                        handleChangeAndUpdate(
                          'gateway_type',
                          (e.target.value as GatewayType)?.gateway_type
                        )
                        handleChangeAndUpdate(
                          'gateway_type_description',
                          (e.target.value as GatewayType)?.description
                        )
                      }}
                      value={typeValue as Selectable}
                      datasources={DATASOURCES.TYPES}
                    />
                  </Layout.Stack>

                  <Layout.Stack space="s" data-testid="partnership">
                    <Text>Partnership</Text>
                    <PartnershipSelector
                      selected={partnershipValue}
                      onSelect={selected => {
                        handleChangeAndUpdate('partnership', selected ? selected.id : null)
                      }}
                    />
                  </Layout.Stack>

                  <Layout.Stack space="s" data-testid="gateway-setting-connection">
                    <Layout.Group align="center" justify="flex-start">
                      <ConditionalCallableLink
                        onClick={
                          connectionData
                            ? () => {
                                window.open(`/connections/${connectionData.id}`, '_blank')
                              }
                            : undefined
                        }
                        label="Connection"
                      />
                      {connectionData?.protocol && (
                        <Tag size="small">{connectionData.protocol.toUpperCase()}</Tag>
                      )}
                    </Layout.Group>

                    <Select
                      id="connection"
                      name="connection"
                      onChange={e => {
                        onConnectionChange(e.target.value as ConnectionSetting)
                      }}
                      value={(connectionValue || '-') as Selectable}
                      datasources={DATASOURCES.CONNECTIONS}
                    />
                  </Layout.Stack>
                  {(isLoadingOwner || isRefetchingOwner) && <LoadingDots />}
                  {!isLoadingOwner && !isRefetchingOwner && connectionData?.owner && (
                    <Layout.Stack space="s" className="flex-1" data-testid="gateway-setting-owner">
                      <Layout.Group justify="flex-start">
                        <ConditionalCallableLink
                          onClick={
                            connectionData?.owner
                              ? () => {
                                  window.open(`/trading-partner/${connectionData.owner}`)
                                }
                              : undefined
                          }
                          label="Owner"
                        />
                      </Layout.Group>
                      <TextField value={gatewayData.owner_description} readOnly />
                    </Layout.Stack>
                  )}
                </Layout.Group>

                <Layout.Group space="l" />

                <Tabs className="flex flex-col" direction="horizontal">
                  <Tabs.Items>
                    <Tabs.Item default name="tab-1">
                      Definition
                    </Tabs.Item>
                    <Tabs.Item name="tab-2">Use when</Tabs.Item>
                    <Tabs.Item name="tab-3">History</Tabs.Item>
                  </Tabs.Items>

                  <Tabs.Panels className="file-view-tab">
                    <Tabs.Panel name="tab-1">
                      <Layout.Stack className="mt-4">{settings}</Layout.Stack>
                    </Tabs.Panel>

                    <Tabs.Panel name="tab-2">
                      <Layout.Stack className="p-4">
                        <FileViewer
                          options={{
                            heigth: 500,
                            theme: FileViewTheme.DEFAULT,
                          }}
                          content={JSON.stringify(gatewayData.use_when, null, 2)}
                          contentType="application/json"
                          onChange={value =>
                            handleChangeAndUpdate('use_when', JSON.parse(value || '{}'))
                          }
                        />
                      </Layout.Stack>
                    </Tabs.Panel>

                    <Tabs.Panel name="tab-3">
                      <Layout.Stack className="p-4">{history}</Layout.Stack>
                    </Tabs.Panel>
                  </Tabs.Panels>
                </Tabs>
              </Layout.Stack>
            </Card.Body>
          </Card>
        )
      )}
    </Layout.Stack>
  )
}

export default GatewaySettingsDetails
