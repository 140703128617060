const uiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Polling',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/Route-Code',
            },
            {
              type: 'Control',
              scope: '#/properties/Polling-Period-In-Minutes',
            },
            {
              type: 'Control',
              scope: '#/properties/headers',
            },
          ],
        },
      ],
    },
  ],
}

export default uiSchema
