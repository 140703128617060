import { Layout, Section, Switch, Text } from '@loadsmart/loadsmart-ui'
import { Accordion, AccordionDetails, AccordionSummary, Drawer } from '@mui/material'
import isEmpty from 'lodash.isempty'
import { DevelopmentTranslationTestCase } from 'pages/TranslationMaps/types'
import { useEffect, useState } from 'react'
import DiffViewer from 'react-diff-viewer'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TestCaseExecutionStatus from './TestCaseExecutionStatus'
import Fab from '@mui/material/Fab'
import Icon from 'atoms/Icon'
import FileViewer from 'molecules/FileViewer/FileViewer'

export interface TestCaseExecutionResultProps {
  open: boolean
  onClose: () => void
  localTest: DevelopmentTranslationTestCase
}

const MAX_PAYLOAD_CAHRACTERS_CHUNK = 1000
const SLICE_CHUNK = MAX_PAYLOAD_CAHRACTERS_CHUNK

const TestCaseExecutionResult = (props: TestCaseExecutionResultProps) => {
  const originalOldValue = props.localTest.execution?.expected_payload || ''
  const originalNewValue = props.localTest.execution?.translated_payload || ''

  const hasDiff = originalNewValue != originalOldValue

  const [slice, setSlice] = useState([0, MAX_PAYLOAD_CAHRACTERS_CHUNK])

  const [newValue, setNewValue] = useState<string>(
    props.localTest.execution?.expected_payload?.slice(0, MAX_PAYLOAD_CAHRACTERS_CHUNK) || ''
  )
  const [oldValue, setOldValue] = useState<string>(
    props.localTest.execution?.translated_payload?.slice(0, MAX_PAYLOAD_CAHRACTERS_CHUNK) || ''
  )

  const [splitView, setSplitView] = useState(false)
  const [showDiffOnly, setShowDiffOnly] = useState(true)

  const handleSliceNextChunck = () => {
    if (slice[1] < originalOldValue.length) setSlice(c => [c[0] + SLICE_CHUNK, c[1] + SLICE_CHUNK])
  }

  const handlePrevNextChunck = () => {
    if (slice[0] < originalOldValue.length) setSlice(c => [c[0] - SLICE_CHUNK, c[1] - SLICE_CHUNK])
  }

  useEffect(() => {
    if (originalNewValue) setNewValue(originalNewValue?.slice(slice[0], slice[1]))
    if (originalOldValue) setOldValue(originalOldValue?.slice(slice[0], slice[1]))
  }, [slice])

  return (
    <Drawer
      open={props.open}
      onClose={() => props.onClose()}
      anchor="right"
      PaperProps={{
        className: 'drawer',
      }}
    >
      <Layout.Stack justify="space-between" className="h-full">
        <Layout.Group className="w-full p-4">
          <Layout.Stack className="w-full">
            <Layout.Group align="center">
              <TestCaseExecutionStatus test={props.localTest} />
              <Text variant="heading-md-bold">{props.localTest.testCaseDetail?.description}</Text>
            </Layout.Group>
            <Layout.Stack>
              {!isEmpty(props.localTest.execution?.details) ? (
                <Section>
                  <Section.Title>Details</Section.Title>
                  <Layout.Grid className="w-full m-2 p-4 rounded bg-neutral-lighter">
                    <pre style={{ whiteSpace: 'break-spaces' }}>
                      {props.localTest.execution?.details}
                    </pre>
                  </Layout.Grid>
                </Section>
              ) : null}

              <div>
                <Accordion defaultExpanded={hasDiff}>
                  <AccordionSummary data-testid="diff-accordion">
                    <Text>Payload Diff</Text>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Layout.Stack>
                      <Layout.Group align="center" justify="space-between">
                        <Layout.Group align="center" space="s">
                          <Layout.Group space="s" align="center">
                            <Text variant="caption-bold">Split view?</Text>
                            <Switch
                              active={splitView}
                              onToggle={() => setSplitView(c => !c)}
                              title="split-view"
                            />
                          </Layout.Group>
                          <Layout.Group space="s" align="center">
                            <Text variant="caption-bold">Show diff only?</Text>
                            <Switch
                              active={showDiffOnly}
                              onToggle={() => setShowDiffOnly(c => !c)}
                              title="show-diff-only"
                            />
                          </Layout.Group>
                        </Layout.Group>
                        {originalOldValue.length > MAX_PAYLOAD_CAHRACTERS_CHUNK ? (
                          <Layout.Group>
                            <Fab onClick={handleSliceNextChunck} variant="extended" size="small">
                              <Layout.Group align="center">
                                <ExpandMoreIcon />
                                <Text variant="body-bold">Load More</Text>
                              </Layout.Group>
                            </Fab>
                            <Fab onClick={handlePrevNextChunck} variant="extended" size="small">
                              <Layout.Group align="center">
                                <ExpandLessIcon />
                                <Text variant="body-bold">Load Previous</Text>
                              </Layout.Group>
                            </Fab>
                          </Layout.Group>
                        ) : null}
                      </Layout.Group>
                      <DiffViewer
                        oldValue={oldValue}
                        newValue={newValue}
                        splitView={splitView}
                        showDiffOnly={showDiffOnly}
                        extraLinesSurroundingDiff={2}
                      />
                    </Layout.Stack>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary data-testid="translated-payload-accordion">
                    <Text>Translated Payload</Text>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FileViewer
                      contentType="application/json"
                      content={originalNewValue}
                      options={{}}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            </Layout.Stack>
          </Layout.Stack>
        </Layout.Group>
      </Layout.Stack>
    </Drawer>
  )
}

export default TestCaseExecutionResult
