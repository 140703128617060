/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
import { toast } from 'atoms/Toast'
import {
  IntegrationOnboardConfirmationResponse,
  IntegrationTemplate,
} from 'common/types/kraken-core/IntegrationTemplate'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { ConfirmationReponse } from './TemplateOnboardDashboardContext'
import {
  ConfirmIntegrationOnboarding,
  confirmIntegrationOnboard,
} from 'services/kraken-core/integration_template/integration.template.service'
import { TemplateOnboardDashboardContextStoreManager } from '../LocalStorageManager/templateOnboardDashboardContextStoreManager'
import { TemplateOnboardDashboardView } from './types'

export interface TemplateOnboardContextState {
  template: IntegrationTemplate | undefined
  view: TemplateOnboardDashboardView | undefined
  confirmationResponse: ConfirmationReponse | undefined
  isLoading: boolean
  confirmOnboard: (data: any) => Promise<boolean>
  resetForNewOnboarding: () => void
  updateView: (view: TemplateOnboardDashboardView) => void
}

export const TemplateOnboardContext = createContext<TemplateOnboardContextState | undefined>(
  undefined
)

export interface TemplateOnboardContextProviderProps {
  viewId: string
  template: IntegrationTemplate
  children: any
}

function TemplateOnboardContextProvider({
  children,
  template,
  viewId,
}: TemplateOnboardContextProviderProps) {
  const [view, setView] = useState<TemplateOnboardDashboardView>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [confirmationResponse, setConfirmationResponse] = useState<
    ConfirmationReponse | undefined
  >()

  async function confirmOnboard(data: any): Promise<boolean> {
    if (!template) {
      toast.error('There is no template selected')
      return false
    }
    setIsLoading(true)
    const confirmarion: ConfirmIntegrationOnboarding = {
      template,
      data,
    }
    const result = false
    await confirmIntegrationOnboard(confirmarion).then(r => {
      if (r.error) {
        toast.error(String(r.error))
        return
      }

      const response: IntegrationOnboardConfirmationResponse = r.response?.data
      setConfirmationResponse(s => ({
        ...s,
        success: response.success,
        message: response.message || '',
        templateID: response.metadata.template_id || '',
      }))
    })
    setIsLoading(false)
    return result
  }

  function updateView(viewToUpdate: TemplateOnboardDashboardView) {
    const currentContext = TemplateOnboardDashboardContextStoreManager.getDashboardContext()
    const views = currentContext.views.filter(v => v.id != viewToUpdate.id)
    views.push(viewToUpdate)
    TemplateOnboardDashboardContextStoreManager.saveDashboardContext({
      views,
    })
    setView(viewToUpdate)
  }

  function resetForNewOnboarding() {
    setConfirmationResponse({})
    setIsLoading(false)
  }

  const contextState: TemplateOnboardContextState = {
    template,
    view,
    confirmationResponse,
    isLoading,
    confirmOnboard,
    resetForNewOnboarding,
    updateView,
  }

  useEffect(() => {
    console.log(`Loading view ${viewId} from local storage context`)
    const context = TemplateOnboardDashboardContextStoreManager.getDashboardContext()
    setView(context.views?.find(v => v.id === viewId))
  }, [])

  return (
    <TemplateOnboardContext.Provider value={contextState}>
      {children}
    </TemplateOnboardContext.Provider>
  )
}

export function useTemplateOnboardContext() {
  const context = useContext(TemplateOnboardContext)
  if (context === undefined) {
    throw new Error(
      'useTemplateOnboardContext must be used within a TemplateOnboardContextProvider'
    )
  }
  return context
}

export default TemplateOnboardContextProvider
