import { Button, Drawer, Layout, LoadingDots } from '@loadsmart/loadsmart-ui'
import { PartnershipDetail } from 'common/types/kraken-core/Partnership'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useCreatePartnership, useGetPartnership, useUpdatePartnership } from '../api'
import ParntershipForm from '../components/PartnershipForm'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

export interface PartnershipDetailProps {
  id?: number
  open: boolean
  onClose: () => void
}

const NEW_EMPTY_PARTNERSHIP: PartnershipDetail = {}

export default function PartnershipDetailComponent({ open, id, onClose }: PartnershipDetailProps) {
  useTabTitle('Partnership')
  const [partnership, setPartnership] = useState<PartnershipDetail>({ ...NEW_EMPTY_PARTNERSHIP })
  const {
    mutate: createPartnership,
    error: createError,
    isSuccess: createSuccess,
  } = useCreatePartnership()
  const {
    mutate: updatePartnership,
    error: updateError,
    isSuccess: updateSuccess,
  } = useUpdatePartnership()

  const { data, isLoading } = useGetPartnership({ id: id as any })

  const handleOnFormUpdate = (partnership: PartnershipDetail) => {
    console.log('ON UPDATE')
    if (partnership)
      setPartnership(c => ({
        ...partnership,
      }))
  }

  const handleSave = () => {
    if (partnership.id) {
      updatePartnership({
        ...partnership,
        counterparty: partnership.counterparty?.id,
        party: partnership.party?.id,
      })
    } else {
      createPartnership({
        ...partnership,
        counterparty: partnership.counterparty?.id,
        party: partnership.party?.id,
      })
    }
  }

  useEffect(() => {
    if (!isLoading && data) {
      setPartnership(data)
      console.log(data)
    }
  }, [data, isLoading])

  useEffect(() => {
    if (!id) setPartnership({ ...NEW_EMPTY_PARTNERSHIP })
  }, [id])

  useEffect(() => {
    const error = updateError || createError
    if (error) toast.error(`Something went wrong: ${error}`)
  }, [updateError, createError])

  useEffect(() => {
    const success = createSuccess || updateSuccess
    if (success) {
      toast.success(`Successfully saved`)
      onClose()
    }
  }, [updateSuccess, createSuccess])

  console.log('render')

  return (
    <Drawer open={open} onClose={onClose}>
      <Drawer.Header>{id ? 'Update' : 'Create'}</Drawer.Header>
      <Drawer.Body>
        {!isLoading ? (
          <ParntershipForm partnership={partnership} onUpdate={handleOnFormUpdate} />
        ) : (
          <LoadingDots />
        )}
      </Drawer.Body>

      <Drawer.Footer>
        <Layout.Group className="w-full" align="center" justify="flex-end">
          <Button name="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button name="save" variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Layout.Group>
      </Drawer.Footer>
    </Drawer>
  )
}
