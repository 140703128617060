import DataModelSchemaList from './containers/DataModelSchemaList'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import DataModelSchemaDetails, {
  DataModelSchemaDetailMode,
} from './containers/DataModelSchemaDetail'

export interface DataModelSchemaIndexProps {}

export default function DataModelSchemasIndex(props: DataModelSchemaIndexProps) {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <DataModelSchemaList />
      </Route>
      <Route exact path={`${path}/create`}>
        <DataModelSchemaDetails mode={DataModelSchemaDetailMode.CREATE} />
      </Route>
      <Route path={`${path}/:id`}>
        <DataModelSchemaDetails mode={DataModelSchemaDetailMode.UPDATE} />
      </Route>
    </Switch>
  )
}
