import { MessageAction } from 'common/types/kraken-core/Action'
import {
  Installation,
  MessageFailureRead,
  MessageStatus,
  MessageStatusTag,
  TransactionType,
} from 'common/types/kraken-core/Message'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { useQuery, UseQueryResult, UseMutationResult, useMutation } from 'react-query'
import { searchActions } from 'services/kraken-core/actions/actions.service'
import {
  searchTransactionTypes,
  UpdateMessageHeaders,
  updateMessageHeaders,
  searchMessageStatusesChoises,
  getMessageStatusTags,
  readMessageFailures,
} from 'services/kraken-core/messages/messages.service'
import {
  revertMessageHistory,
  searchTradingPartner,
} from 'services/kraken-core/trading_partner/tradingPartner.service'
import { GetMessageFailuresParams } from './types'
import { searchInstallations } from '../../services/kraken-core/installations/installations.service'

export const onSearchTransactionTypes = async ({
  query,
}: {
  query: string
}): Promise<TransactionType[]> => {
  const results: Map<string, TransactionType> = new Map()

  const [errorRequestByKey, responseByKey] = await searchTransactionTypes({
    key: query,
    page_size: 1000,
  })

  if (!errorRequestByKey) {
    ;(responseByKey?.data.results || []).forEach(r => {
      if (!results.has(r.key)) results.set(r.key, r)
    })
  }

  const [errorRequestByDescription, responseByDescription] = await searchTransactionTypes({
    description: query,
  })

  if (!errorRequestByDescription) {
    ;(responseByDescription?.data.results || []).forEach(r => {
      if (!results.has(r.key)) results.set(r.key, r)
    })
  }

  return Array.from(results, ([, value]) => value)
}

export const onSearchTradingPartners = async ({
  query,
}: {
  query: string
}): Promise<TradingPartner[]> => {
  const result = await searchTradingPartner({
    filters: [
      {
        property: 'name',
        value: query,
      },
    ],
    page_size: 1000,
  })

  return result?.response?.data?.results || []
}

export const onRevertMessageHistory = async ({
  messageId,
  historyId,
}: {
  messageId: string
  historyId: string
}): Promise<void> => {
  await revertMessageHistory({
    messageId,
    historyId,
  })
}

export const useSearchActions = (): UseQueryResult<MessageAction[]> => {
  const queryFn = async () => {
    const { response } = await searchActions()

    return response?.data?.results || []
  }

  const query = useQuery(['search_actions'], () => queryFn())

  return query
}

export const useUpdateMessageHeaders = (): UseMutationResult<
  any,
  unknown,
  UpdateMessageHeaders,
  unknown
> => {
  const mutation = useMutation(async (params: UpdateMessageHeaders) => {
    await updateMessageHeaders(params)
  }, {})
  return mutation
}

export const useGetMessageStatusesChoises = (): UseQueryResult<MessageStatus[]> => {
  const queryFn = async () => {
    const [error, response] = await searchMessageStatusesChoises()
    return response?.data || []
  }

  const query = useQuery(['messages_status_choices'], () => queryFn())
  return query
}

export const useGetMessageStatusTags = (): UseQueryResult<MessageStatusTag[]> => {
  const queryFn = async () => {
    const [error, response] = await getMessageStatusTags()
    return response?.data || []
  }
  const query = useQuery(['messages/status_tags'], () => queryFn())
  return query
}

export const useGetMessageFailures = (
  params: GetMessageFailuresParams,
  config?: any
): UseQueryResult<MessageFailureRead[]> => {
  const queryFn = async () => {
    const [error, response] = await readMessageFailures(params.id)
    return response?.data || []
  }
  const query = useQuery([`messages/${params.id}/failures`], () => queryFn(), {
    ...config,
    enabled: !!params.id,
  })
  return query
}
