function validateCSVReaderSchema(schema: any): Array<string> {
  const errors: Array<string> = []
  if (!schema) {
    errors.push('There is no schema available!')
    return errors
  }

  const schemaType = schema.type
  if (!schemaType) {
    errors.push('There is no schema type defined')
    return errors
  }

  const expectedType = 'array'
  if (schemaType !== expectedType) {
    errors.push(`Schema type is not compatible. Received 
        '${schemaType}', Expected: ${expectedType}`)
    return errors
  }

  const schemaArrayItems: any = schema.items
  if (!schemaArrayItems) {
    errors.push("Schema doesn't have 'items' definition")
    return errors
  }

  if (!schemaArrayItems.type) {
    errors.push("Schema 'items' definition doesn't have 'type' definition")
    return errors
  }

  const expectedArrayItemsType = 'object'
  if (schemaArrayItems.type !== expectedArrayItemsType) {
    errors.push(
      `Schema 'items' has an incompatible 'type' 
            definition. Received: ${schemaArrayItems.type}, 
            Expected: ${expectedArrayItemsType}`
    )
    return errors
  }

  if (!schemaArrayItems.properties) {
    errors.push("Schema 'items' definition doens't have 'properties' definition")
    return errors
  }

  return []
}

export default validateCSVReaderSchema
