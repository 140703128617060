import { Banner } from '@loadsmart/loadsmart-ui'
import React from 'react'

interface WarningPanelProps {
  title?: string
  description?: string
  dismissible?: boolean
}

const DEFAULT_WARNING_TITLE = 'Warning'
const DEFAULT_WARNING_DESCRIPTION =
  'This component is being managed by an Infrastructure as Code (IaC) tool. Changes must be made on IaC repository.'

const IaCComponentReferenceWarning: React.FC<WarningPanelProps> = ({
  title = DEFAULT_WARNING_TITLE,
  description = DEFAULT_WARNING_DESCRIPTION,
  dismissible = false,
}) => {
  return (
    <Banner
      variant="warning"
      title={title}
      scale="large"
      description={description}
      dismissible={dismissible}
    />
  )
}

export default IaCComponentReferenceWarning
