export enum ConversionCodeCategoryDirection {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

export interface ConversionCodeCategory {
  id?: string
  key?: string
  party?: {
    id: string
    name: string
  }
  description?: string
  source_code?: string
  target_code?: string
  use_when?: string
  direction?: ConversionCodeCategoryDirection
  created_at?: string
  deleted_at?: string
  updated_at?: string
}

export interface ConversionCode {
  id?: string
  key?: string
  iac_reference?: object
  source?: {
    id: string
    name: string
  }
  target?: {
    id: string
    name: string
  }
  source_code?: string
  target_code?: string
  source_value?: string
  target_value?: string
  direction?: ConversionCodeCategoryDirection
  created_at?: string
  deleted_at?: string
  updated_at?: string
  use_when?: any
}
