/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import qs from 'qs'
import { MessageDashboardView } from '../types'
import isEmpty from 'lodash.isempty'

export const MESSAGE_DASHBOARD_VIEW_LOCAL_STORAGE_KEY = 'message-dashboard-view-context'

export interface MessagesDashboardContextStorageManager {
  setFilters(filters: any): void
}

export interface MessageDashboardContextLocalStorage {
  views: Array<MessageDashboardView>
}

export class MessageDashboardContextStoreManager {
  static init(): MessageDashboardContextLocalStorage {
    const newContext: MessageDashboardContextLocalStorage = {
      views: [],
    }
    this.saveDashboardContext(newContext)
    return newContext
  }

  static getDashboardContext = (): MessageDashboardContextLocalStorage => {
    const context = JSON.parse(
      localStorage.getItem(MESSAGE_DASHBOARD_VIEW_LOCAL_STORAGE_KEY) || '{}'
    )
    if (isEmpty(context)) return MessageDashboardContextStoreManager.init()
    return context
  }

  static saveDashboardContext = (context: MessageDashboardContextLocalStorage): void => {
    localStorage.setItem(MESSAGE_DASHBOARD_VIEW_LOCAL_STORAGE_KEY, JSON.stringify(context))
  }
}

export class MessageDashboardViewContextStorageManager {
  _view: MessageDashboardView

  constructor(view: MessageDashboardView) {
    this._view = view
  }

  getViewContextFromStorage = (): MessageDashboardView | undefined => {
    const contextFound = MessageDashboardContextStoreManager.getDashboardContext()
    if (!contextFound || !contextFound.views) return undefined
    const viewContextFound: MessageDashboardView | undefined = contextFound.views.find(
      v => v.id === this._view.id
    )
    return viewContextFound
  }

  saveViewContextInStorage = () => {
    const context = MessageDashboardContextStoreManager.getDashboardContext()
    context.views = context.views.filter(v => v.id !== this._view.id)
    context.views.push(this._view)
    MessageDashboardContextStoreManager.saveDashboardContext(context)
  }

  updateFilter = (filter: string, value: any) => {
    if (typeof value !== 'boolean' && isEmpty(value)) {
      value = null
    }
    this._view.filters[filter] = value
  }

  updatePageSize = (pageSize: number) => {
    this._view.pageSize = pageSize
    this.saveViewContextInStorage()
  }

  getFilters = () => {
    return this._view.filters
  }

  updateQueryString(filters?: any) {
    window.history.replaceState(null, '', `?${qs.stringify(filters, { skipNulls: true })}`)
  }

  setFilters(filters: any): void {
    this._view.filters = { ...filters }
    this.saveViewContextInStorage()
  }
}

export { MessageDashboardViewContextStorageManager as MessageContextStorageManager }
