function validateCheckboxSelectionSchemaValidator(schema: any): Array<string> {
  const errors: Array<string> = []
  if (!schema) {
    errors.push('There is no schema available!')
    return errors
  }

  const schemaType = schema.type
  if (!schemaType) {
    errors.push('There is no schema type defined')
    return errors
  }

  const expectedType = 'array'
  if (schemaType !== expectedType) {
    errors.push(`Schema type is not compatible. Received 
          '${schemaType}', Expected: ${expectedType}`)
    return errors
  }

  const schemaArrayItems: any = schema.items
  if (!schemaArrayItems) {
    errors.push("Schema doesn't have 'items' definition")
    return errors
  }

  const expectedArrayItemsTypes = ['string', 'object']
  if (expectedArrayItemsTypes.filter(type => schemaArrayItems.type === type).length <= 0) {
    errors.push(
      `Schema 'items' has an incompatible 'type' 
              definition. Received: ${schemaArrayItems.type}, 
              Expected: ${expectedArrayItemsTypes.join(',')}`
    )
    return errors
  }

  if (!schemaArrayItems.enum && !schema.enum) {
    errors.push("Was not possible to find 'enum' field at the provided schema")
    return errors
  }

  return []
}

export default validateCheckboxSelectionSchemaValidator
