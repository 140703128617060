const uiSchema: any = {
  type: 'Control',
  scope: '#/',
  options: {
    detail: {
      type: 'VerticalLayout',
      elements: [
        {
          type: 'Group',
          label: 'When',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/when',
            },
          ],
        },
        {
          type: 'Group',
          label: 'Then',
          elements: [
            {
              type: 'Control',
              title: 'When',
              scope: '#/properties/then',
            },
          ],
        },
      ],
    },
  },
}

export default uiSchema
