import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { del, get, post, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { ConversionCode } from 'common/types/kraken-core/ConversionCode'

export interface ListConversionCodeRequest {
  source?: string | null
  target?: string | null
  source_code?: string | null
  target_code?: string | null
  source_value?: string | null
  target_value?: string | null
  use_when?: string | null
  page?: number
  page_size?: number
}

export interface ListConversionCodeReponse {
  count: number
  next: string
  previous: string
  results: Array<ConversionCode>
}

export interface ConversionCodeHistory extends ConversionCode {
  history_id: number
  history_user?: string
  history_date: string
  history_change_reason?: string
}

export async function listConversionCode(
  data?: ListConversionCodeRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ListConversionCodeReponse>> {
  const params = { ...data }

  const [error, response] = await to(get('/conversion/codes', { params, ...config }))

  if (error) throw error

  return { error, response }
}

export async function updateConversionCode(
  conversionCode: ConversionCode,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConversionCode>> {
  const [error, response] = await to(
    put(`/conversion/codes/${conversionCode.id}`, conversionCode, { ...config })
  )

  if (error) throw error

  return { error, response }
}

export async function createConversionCode(
  conversionCode: ConversionCode,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConversionCode>> {
  const [error, response] = await to(post('/conversion/codes', conversionCode, { ...config }))
  return { error, response }
}

export async function deleteConversionCode(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConversionCode>> {
  const [error, response] = await to(del(`/conversion/codes/${id}`, { ...config }))
  return { error, response }
}

export async function getConversionCodeHistory(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ListConversionCodeReponse>> {
  const [error, response] = await to(get(`/conversion/codes/${id}/history`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function getConversionCode(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ConversionCode>> {
  const [error, response] = await to(get(`/conversion/codes/${id}`, { ...config }))
  if (error) throw error
  return { error, response }
}
