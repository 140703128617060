import { useState } from 'react'

export interface Pagination {
  page: number
  pageSize: number
  next: () => void
  previous: () => void
  updatePageSizeTo: (pageSize: number) => void
  reset: () => void
}

export interface usePaginationProps {
  page: number
  pageSize: number
}

export default function usePagination(props: usePaginationProps): Pagination {
  const [state, setState] = useState({
    pageSize: props.pageSize,
    page: props.page,
  })

  function reset() {
    setState(c => ({
      page: props.page,
      pageSize: props.pageSize,
    }))
  }

  function next() {
    setState(c => ({
      ...c,
      page: c.page + 1,
    }))
  }

  function previous() {
    setState(c => ({
      ...c,
      page: c.page - 1,
    }))
  }

  function updatePageSizeTo(pageSize: number) {
    setState(c => ({
      ...c,
      pageSize,
    }))
  }

  return {
    page: state.page,
    pageSize: state.pageSize,
    next,
    previous,
    updatePageSizeTo,
    reset,
  }
}
