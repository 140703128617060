import qs from 'qs'
import { Label, Layout, Table, TextField } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'
import Field from 'atoms/Field'
import Loading from 'atoms/Loading'
import SimplePagination from 'atoms/SimplePagination'
import { removeToken } from 'common/helpers/removeToken'
import { ConversionCodeCategory } from 'common/types/kraken-core/ConversionCode'
import useDebouncedValue from 'hooks/useDebouncedValue'
import ErrorPanel from 'molecules/ErrorPanel'
import { useState } from 'react'
import { useSearchConversionCodesCategories } from '../api'
import { ConversionCodesProps } from '../types'

const COLUMNS = [
  {
    Header: 'Party',
    Cell(row: ConversionCodeCategory) {
      return row.party?.name
    },
  },
  {
    Header: 'Description',
    Cell(row: ConversionCodeCategory) {
      return row.description
    },
  },
]

const ConversionCodes = ({ tradingPartner }: ConversionCodesProps) => {
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [description, setDescription] = useState<string>('')
  const debouncedDescription = useDebouncedValue<string>(description, 500)
  const { isLoading, error, data } = useSearchConversionCodesCategories({
    page,
    description: debouncedDescription,
  })

  const handleRowClick = (row: ConversionCodeCategory) => {
    const query = {
      target_name: tradingPartner.name,
      target: tradingPartner.id,
      source: row.party?.id,
      source_code: row.source_code,
      target_code: row.target_code,
      use_when: JSON.stringify(row.use_when),
      direction: row.direction,
      description: row.description,
    }
    const searchString = qs.stringify(query)

    history.push({
      pathname: `/trading-partner/${tradingPartner.id}/conversion-codes`,
      search: searchString,
    })
  }

  if (isLoading) return <Loading className="mt-8" />

  if (error) return <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />

  return (
    <Layout.Stack className="w-full mt-6">
      <Field className="flex">
        <Label>Partner name</Label>
        <TextField
          data-testid="conversion-code-filter-input"
          name="conversion-code-filter-input"
          onChange={e => setDescription(e.target.value)}
        />
      </Field>

      {isLoading ? (
        <Loading className="mt-8" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!data && (
          <>
            <Table className="w-full" data-testid="conversion-codes-list">
              <Table.Head>
                <Table.Row>
                  {COLUMNS.map(column => (
                    <Table.Cell key={column.Header}>{column.Header}</Table.Cell>
                  ))}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {data.results.map(row => (
                  <Table.Row
                    key={row.id}
                    onClick={() => handleRowClick(row)}
                    className="cursor-pointer"
                  >
                    {COLUMNS.map(cell => (
                      <Table.Cell className="text-sm" key={`${row?.id}-${cell.Header}`}>
                        {cell.Cell(row)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Layout.Box className="flex justify-end w-full">
              <SimplePagination
                currentPage={page}
                previousPage={() => setPage(prev => prev - 1)}
                nextPage={() => setPage(prev => prev + 1)}
                canPreviousPage={!!data.previous}
                canNextPage={!!data.next}
              />
            </Layout.Box>
          </>
        )
      )}
    </Layout.Stack>
  )
}

export default ConversionCodes
