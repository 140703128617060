/* eslint-disable no-unused-vars */
import downloadFile from 'common/helpers/downloadFile'
import { RouteHistory, RouteList, RouteRead } from 'common/types/kraken-core/Routes'
import SearchResponse from 'common/types/SearchResponse'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query'
import {
  createRoute,
  deleteRoute,
  exportRoute,
  getRoute,
  getRouteHistory,
  push,
  revertHistory,
  searchRoutes,
  updateRoute,
} from 'services/kraken-core/routes/routes.service'
import { QUERIES, useGetRouteParams, useSearchRoutesParams } from './types'

export const useSearchRoutes = (
  params: useSearchRoutesParams
): UseQueryResult<SearchResponse<RouteList[]>> => {
  const queryFn = async ({ page, code }: useSearchRoutesParams) => {
    const { response, error } = await searchRoutes({
      page: page || 1,
      page_size: 10,
      filters: [
        {
          property: 'code',
          value: code,
        },
      ],
    })

    if (error) throw error
    return response?.data
  }

  const query = useQuery([QUERIES.SEARCH_ROUTES, params], () => queryFn(params), {
    keepPreviousData: true,
  })

  return query
}

export const useGetRoute = (params: useGetRouteParams, config?: any): UseQueryResult<RouteRead> => {
  const queryFn = async ({ id }: useGetRouteParams) => {
    const { response } = await getRoute({ id })

    if (!response?.data) {
      const { response: searchResponse } = await searchRoutes({
        page: 1,
        page_size: 1,
        filters: [
          {
            property: 'code',
            value: id,
          },
        ],
      })

      const searchId = searchResponse?.data?.results?.[0]?.id

      if (searchId) {
        const { response: fallbackResponse } = await getRoute({
          id: searchId,
        })

        return fallbackResponse?.data || {}
      }

      return {}
    }

    return response.data
  }

  const query = useQuery([QUERIES.GET_ROUTE, params.id], () => queryFn(params), config)
  return query
}

export const onExport = async (idToExport: string, name: string) => {
  const result = await exportRoute(idToExport)

  if (result.response?.data) {
    const { created_at, updated_at, deleted_at, id, ...rest } = result.response.data as RouteRead

    downloadFile(JSON.stringify(rest), `route_${name}.json`, 'application/json')
  }
}

export const useUpdateRoute = (): UseMutationResult<any, unknown, RouteRead, unknown> => {
  const mutation = useMutation(async (params: RouteRead) => {
    const { response, error } = await updateRoute({ ...params })
    if (error) throw error
    return response?.data
  }, {})
  return mutation
}

export const useCreateRoute = (): UseMutationResult<any, unknown, RouteRead, unknown> => {
  const mutation = useMutation(async (params: RouteRead) => {
    const { response, error } = await createRoute({ ...params })
    if (error) throw error
    return response?.data
  }, {})
  return mutation
}

export const useDeleteRoute = (): UseMutationResult<any, unknown, string, unknown> => {
  const mutation = useMutation(async (id: string) => {
    const { response, error } = await deleteRoute(id)
    if (error) throw error
    return response?.data
  })
  return mutation
}

export const usePush = (): UseMutationResult<any, unknown, RouteRead, unknown> => {
  const mutation = useMutation(async (params: RouteRead) => {
    const { response, error } = await push(params)
    if (error) throw JSON.stringify(error.response?.data)
    return response?.data
  })
  return mutation
}

export const useGetRouteHistory = (params: useGetRouteParams): UseQueryResult<RouteHistory[]> => {
  const queryFn = async ({ id }: useGetRouteParams) => {
    if (!id) return {}

    const { response } = await getRouteHistory(id)

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_ROUTE_HISTORY, params], () => queryFn(params), {
    enabled: !!params.id,
  })

  return query
}

export const useRevertRouteHistory = (): UseMutationResult<any, unknown, RouteHistory, unknown> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(async (params: RouteHistory) => revertHistory(params), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([QUERIES.GET_ROUTE_HISTORY])
    },
  })

  return mutation
}
