import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import { Fragment, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'

export interface FileUploaderProps {
  handleUpload: (files: File[]) => void
  accept?: string | string[]
  multiple?: boolean
}

interface FileUploaderState {
  files?: File[]
  url?: string
}

function FileUploader(props: FileUploaderProps) {
  function handleFile(files: File[]) {
    props.handleUpload(files)
  }

  return (
    <Dropzone onDrop={handleFile} accept={props.accept} multiple={props.multiple}>
      {({ getRootProps, getInputProps }) => (
        <div>
          <div
            {...getRootProps()}
            style={{
              borderStyle: 'dashed',
              borderWidth: '2px',
              borderColor: Themes.Loadsmart['color-accent'],
            }}
            className="p-8 rounded"
          >
            <input {...getInputProps()} />
            <p>Drag and drop some files here, or click to select files</p>
          </div>
        </div>
      )}
    </Dropzone>
  )
}

export default FileUploader
