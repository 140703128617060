const uiSchema: any = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'General',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/account_uuid_from_partner',
            },
            {
              type: 'Control',
              scope: '#/properties/is_system_connection',
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Override',
      elements: [
        {
          type: 'Control',
          scope: '#/properties/override',
          options: {
            detail: {
              type: 'Group',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/url',
                },
                {
                  type: 'Control',
                  scope: '#/properties/client_id_from_intermediary',
                },
                {
                  type: 'Categorization',
                  elements: [
                    {
                      type: 'Category',
                      label: 'Credentials',
                      elements: [
                        {
                          type: 'Group',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/credentials',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'Category',
                      label: 'Metrics',
                      elements: [
                        {
                          type: 'Group',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/metrics',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'Category',
                      label: 'Response Replacement',
                      elements: [
                        {
                          type: 'Group',
                          elements: [
                            {
                              type: 'Control',
                              scope: '#/properties/quote:create',
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/shipment:event',
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/shipment:offer:accept',
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/shipment:tender:accept',
                            },
                            {
                              type: 'Control',
                              scope: '#/properties/quote:request-create-and-poll',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
  ],
}

export default uiSchema
