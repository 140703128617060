import { AxiosResponse, AxiosError } from 'axios'
import { authManager } from 'common/authManager'
import Environment from 'common/types/Environment'
import { authenticateLocally } from 'services/kraken-core/auth/auth.service'

import HTTPStatus from '../types/HTTPStatus'
import getEnv from './getEnv'

/**
 * This is an async wrapper for waiting a promise to be settled without the hussle of handling error.
 * It returns `[error, data]`.
 * @example
 * const [error, data] = await genericTo(myPromise())
 * @param promise - promise to be awaited for.
 * @param errorExt - error to be appended, in case the promise is rejected.
 */
export function genericTo<T>(
  promise: Promise<T>,
  errorExt?: object
): Promise<[any, T | undefined]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[any, undefined]>((err: any) => {
      if (errorExt) {
        Object.assign(err, errorExt)
      }

      return [err, undefined]
    })
}

function isUnauthorized(response: AxiosResponse | undefined): boolean {
  return response?.status === HTTPStatus.Unauthorized
}

export async function to<T>(
  promise: Promise<AxiosResponse<T>>,
  errorExt?: object
): Promise<[AxiosError | null, AxiosResponse<T> | undefined]> {
  const [error, response] = await genericTo<AxiosResponse<T>>(promise, errorExt)

  if (error && isUnauthorized(error.response)) {
    if (getEnv() === Environment.Dev) {
      await authenticateLocally()
    } else {
      console.error('Received unauthorized response, will start signin flow')
      await authManager.signInWithRedirect()
    }
  }

  return [error, response]
}
