import clsx from 'clsx'
import { Link, LinkProps } from '@loadsmart/loadsmart-ui'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import './index.css'

export interface ExternalLinkProps extends LinkProps {
  fontSize?: 'inherit' | 'large' | 'medium' | 'small'
}

const ExternalLink = (props: ExternalLinkProps) => (
  <Link {...props} className={clsx('external-link', props.className)}>
    {props.children}
    <OpenInNewIcon fontSize={props.fontSize} />
  </Link>
)

export default ExternalLink
