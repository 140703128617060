import { toast } from 'atoms/Toast'
import { IntegrationTemplate } from 'common/types/kraken-core/IntegrationTemplate'
import useDebouncedValue from 'hooks/useDebouncedValue'
import React, { useCallback, useEffect, useState } from 'react'
import { searchTemplates } from 'services/kraken-core/integration_template/integration.template.service'

export interface IntegrationOnboardingContextProps {
  children: any
}

export interface IntegrationTemplateFilter {
  label: string
  property: string
  value: string
}

export interface IntegrationOnboardingContextState {
  // Properties
  templates: Array<IntegrationTemplate> | []
  templateFilters: Array<IntegrationTemplateFilter>
  selectedTemplate?: IntegrationTemplate
  wizard?: {
    formSchema: any
    formOutput: any
  }
  isLoading: boolean

  // Actions
  searchForTemplates: () => void
  onTemplateSelection: (template: IntegrationTemplate) => void
  updateFilterValue: (filter: IntegrationTemplateFilter, newValue: string) => void
}

export const IntegrationOnboardingContext = React.createContext<
  IntegrationOnboardingContextState | undefined
>(undefined)

function IntegrationOnboardingContextWrapper({ children }: IntegrationOnboardingContextProps) {
  const [templates, setTemplates] = useState<Array<IntegrationTemplate>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [templateFilters, setTemplateFilters] = useState<Array<IntegrationTemplateFilter>>([
    {
      label: 'Party or Template name',
      property: 'key',
      value: '',
    },
  ])
  const debouncedFilters = useDebouncedValue(templateFilters, 500)

  function onTemplateSelection(template: IntegrationTemplate): void {
    console.log('Template was selected')
  }

  function updateFilterValue(filter: IntegrationTemplateFilter, newValue: string) {
    setTemplateFilters(prevFilters => {
      const filters = prevFilters.filter(value => value.property !== filter.property)
      const newFilter = { ...filter, property: filter.property, value: newValue }
      filters?.push(newFilter)
      return filters
    })
  }

  const searchForTemplates = useCallback(async () => {
    setIsLoading(true)
    const filters = templateFilters.map(t => ({
      property: t.property,
      value: t.value,
    }))
    searchTemplates(filters)
      .then(response => {
        if (response.error) {
          toast.error(String(response.error))
          setTemplates([])
          return
        }
        const data = response.response?.data
        const results = data.results || []
        setTemplates(results)
      })
      .catch(e => {
        toast.error(`Something went wrong: ${String(e)}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [templateFilters])

  const state = {
    templates,
    templateFilters,
    isLoading,
    onTemplateSelection,
    updateFilterValue,
    searchForTemplates,
  }

  // When template filters change
  useEffect(() => {
    searchForTemplates()
  }, [debouncedFilters])

  return (
    <IntegrationOnboardingContext.Provider value={state}>
      {children}
    </IntegrationOnboardingContext.Provider>
  )
}

export default IntegrationOnboardingContextWrapper
