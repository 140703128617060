import httpAuthenticationUiSchema from '../http/common/httpAuthentication'

const uiSchema: any = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'Authentication',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/HTTP-Authentication/properties/method',
            },
            httpAuthenticationUiSchema.authCustom,
            httpAuthenticationUiSchema.authBasicSchema,
            httpAuthenticationUiSchema.authTokenFromUrl,
            httpAuthenticationUiSchema.authJwt,
            httpAuthenticationUiSchema.authToken,
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'HTTP Permission',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/HTTP-Permission',
            },
          ],
        },
      ],
    },
  ],
}

export default uiSchema
