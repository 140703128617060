import { Label, Layout, Select, Text, TextField } from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import {
  onSearchDataModelSchemaEngines,
  onSearchTradingPartners,
} from 'common/components/datasources/datasource'
import {
  DataModelSchemaDetail,
  DataModelSchemaEngineList,
} from 'common/types/kraken-core/DataModelSchema'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'

export interface DataModelSchemaFormProps {
  object: DataModelSchemaDetail
  onChange: (changed: DataModelSchemaDetail) => void
}

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
  ENGINES: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (e: DataModelSchemaEngineList) => e.engine_name || '',
        getLabel: (e: DataModelSchemaEngineList) => e.engine_name || '',
      },
      fetch: onSearchDataModelSchemaEngines,
    }),
  ],
}

export default function DataModelSchemaForm(props: DataModelSchemaFormProps) {
  const handleChanged = (field: string, value: any) => {
    props.onChange({
      ...props.object,
      [field]: value,
    })
  }

  return (
    <Layout.Stack>
      <Layout.Stack data-testid="connection-setting-id" space="s">
        <Text variant="caption-bold" color="color-neutral">
          ID
        </Text>
        <Text variant="heading-sm-bold">{props.object.id}</Text>
      </Layout.Stack>

      <Layout.Stack space="s" className="w-full">
        <Label htmlFor="description" required>
          Description
        </Label>
        <TextField
          id="description"
          value={props.object?.description}
          onChange={e => handleChanged('description', e.target.value)}
        />
      </Layout.Stack>

      <Layout.Group>
        <Layout.Switcher className="w-full">
          <Layout.Stack space="s">
            <Label htmlFor="engine_selector" required>
              Engine
            </Label>
            <Select
              id="engine_selector"
              name="select_engine"
              onChange={e => {
                handleChanged(
                  'engine',
                  e.target.value ? (e.target.value as DataModelSchemaEngineList).engine_name : null
                )
              }}
              value={
                {
                  value: props.object.engine,
                  label: props.object.engine,
                } as Selectable
              }
              datasources={DATASOURCES.ENGINES}
            />
          </Layout.Stack>
          <Layout.Stack space="s">
            <Label htmlFor="owner_partner_selector">Owner</Label>
            <Select
              id="owner_partner_selector"
              name="select_owner_trading_partner"
              onChange={e => {
                handleChanged('owner', e.target.value ? (e.target.value as TradingPartner) : null)
              }}
              value={
                {
                  value: props.object.owner?.id,
                  label: props.object.owner?.name,
                } as Selectable
              }
              datasources={DATASOURCES.TRADING_PARTNER}
            />
          </Layout.Stack>
        </Layout.Switcher>
      </Layout.Group>
    </Layout.Stack>
  )
}
