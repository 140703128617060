import { Layout } from '@loadsmart/loadsmart-ui'
import { TranslationMap } from 'common/types/kraken-core/TranslationMap'
import { createContext } from 'react'
import DevelopmentDetailsImplemetation from './Implementation/DevelopmentDetailsImplementation'
import DevelopmentDetailsTests from './DevelopmentDetailsTests'

export interface DevelopmentDetailsProps {
  translationMap: TranslationMap
}

export interface DevelopmentDetailsContextProps {
  translationMap?: TranslationMap
}

export const DevelopmentDetailsContext = createContext<DevelopmentDetailsContextProps>({})

const DevelopmentDetails = (props: DevelopmentDetailsProps) => {
  return (
    <DevelopmentDetailsContext.Provider
      value={{
        translationMap: props.translationMap,
      }}
    >
      <Layout.Group>
        <Layout.Group>
          <DevelopmentDetailsTests />
        </Layout.Group>
        <Layout.Group className="flex-1">
          <DevelopmentDetailsImplemetation />
        </Layout.Group>
      </Layout.Group>
    </DevelopmentDetailsContext.Provider>
  )
}

export default DevelopmentDetails
