import { createAjv } from '@jsonforms/core'
import { JsonForms } from '@jsonforms/react'
import { BannerActionLarge, Layout } from '@loadsmart/loadsmart-ui'
import { useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { defaultRefResolver } from './common'
import { customRenderers, defaultCells, defaultRenderers } from './renderers/index'

export interface JsonFormProps {
  key?: string
  schema: any
  uiSchema?: any
  data: any
  readonly?: boolean
  options?: any
  // eslint-disable-next-line no-unused-vars
  onChange: (data: any, errors?: any) => void
}

export const defaultsAjv = createAjv({ useDefaults: true, allErrors: true })

const renderers: any = [...defaultRenderers, ...customRenderers]

const cells = [...defaultCells]

const FallBack = ({ error, resetErrorBoundary }: any) => (
  <Layout.Stack>
    <BannerActionLarge
      variant="warning"
      title="JsonForm: It was not possible to render the form"
      description={String(error)}
      action="Reload"
      onActionButtonClick={resetErrorBoundary}
    />
  </Layout.Stack>
)

const JsonForm = ({ schema, uiSchema, data, readonly, onChange }: JsonFormProps) => {
  const [resolvedSchema, setResolvedSchema] = useState<any>()
  const allMandatoryParametersAreAvaiable = resolvedSchema && onChange

  const defaultData = useMemo(() => {
    let val = {}
    if (schema && schema.default) {
      val = schema.default
    } else if (schema && schema.type === 'array') {
      val = []
    }
    return val
  }, [schema])

  useEffect(() => {
    /*
     * JsonForms 3.0 doens't automatically resolve schemas, this
     * repsonsability was delegated to the library's client
     * more details available here
     * https://github.com/eclipsesource/jsonforms/blob/master/MIGRATION.md#migrating-to-json-forms-30
     */
    if (schema)
      defaultRefResolver(schema).then(res => {
        setResolvedSchema(res)
      })
  }, [schema])

  if (!schema) {
    return <BannerActionLarge description="A Schema was not provided" />
  }

  if (!allMandatoryParametersAreAvaiable) {
    return <div> Loading... {JSON.stringify(resolvedSchema)}</div>
  }

  return (
    <ErrorBoundary FallbackComponent={FallBack} onReset={() => {}}>
      <JsonForms
        key="json-form"
        schema={resolvedSchema || {}}
        data={data || defaultData}
        uischema={uiSchema || undefined}
        renderers={renderers}
        cells={cells}
        onChange={onChange}
        readonly={readonly || false}
        ajv={defaultsAjv}
      />
    </ErrorBoundary>
  )
}

export default JsonForm
