import { Banner, Breadcrumbs, IconButton, Layout, Text } from '@loadsmart/loadsmart-ui'
import { capitalize } from 'lodash'
import { useHistory } from 'react-router-dom'
import { IntegrationTemplate } from 'common/types/kraken-core/IntegrationTemplate'
import { useContext } from 'react'
import Loading from 'atoms/Loading'
import { IntegrationOnboardingContext } from '../IntegrationOnboardingContext'
import { Paper, Tooltip } from '@mui/material'
import { Bolt, Edit, List } from '@mui/icons-material'
import LSDate, { DATE_FORMAT_MMDDYYHHMM } from 'common/Date.helpers'
import styled from 'styled-components'
import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import TemplateOwnershipTags from '../Common/IntegrationTemplateOwnershipTags'

export interface IntegrationTemplateListProps {
  templates: Array<IntegrationTemplate>
  onSelect: (template: IntegrationTemplate) => void
}

const StyledPaper = styled(Paper)`
  &:hover {
    background-color: whitesmoke;
  }
`

function IntegrationTemplateList({ templates }: IntegrationTemplateListProps) {
  const history = useHistory()
  const context = useContext(IntegrationOnboardingContext)

  function getNormalizedTemplateKeyParts(key: string) {
    const first = key.replaceAll(':', '_')
    const second = first.split('_').map(k => capitalize(k))
    return second
  }

  function handleOnClickOnboard(template: IntegrationTemplate) {
    history.push(`/integration/onboarding/template/${template.id}/onboard`)
  }

  function handleOnClickInstallations(template: IntegrationTemplate) {
    history.push(`/integration/onboarding/template/${template.id}/installations`)
  }

  function handleOnClickBuilder(template: IntegrationTemplate) {
    history.push(`/integration/onboarding/template/${template.id}/builder`)
  }

  return (
    <Layout.Stack className="w-full">
      <Layout.Group className="w-full">
        <Banner title={'Available onboarding templates are listed below'} className="w-full" />
      </Layout.Group>
      <Layout.Group className="w-full">
        {context?.isLoading && <Loading />}
        {!context?.isLoading && (
          <Layout.Group className="w-full">
            {templates.map(t => {
              return (
                <StyledPaper
                  key={t.key}
                  className="w-full"
                  elevation={1}
                  sx={{
                    padding: 3,
                  }}
                >
                  <Layout.Group justify="space-between" className="h-full">
                    <Layout.Stack>
                      <Breadcrumbs
                        entries={getNormalizedTemplateKeyParts(t.key || '').map(k => ({
                          label: k,
                        }))}
                      />
                      <Layout.Group align="center">
                        <Text variant="caption-bold">Updated at:</Text>
                        <Text variant="caption">
                          {LSDate(t.updated_at)?.format(DATE_FORMAT_MMDDYYHHMM)}
                        </Text>
                        <TemplateOwnershipTags template={t} />
                      </Layout.Group>
                    </Layout.Stack>

                    <Layout.Group align="center" justify="center" className="h-full">
                      <Tooltip title="Start Onboarding">
                        <IconButton
                          onClick={e => {
                            handleOnClickOnboard(t)
                          }}
                          style={{
                            accentColor: 'red',
                            backgroundColor: Themes.Loadsmart['color-primary'],
                          }}
                          scale="large"
                        >
                          <Bolt
                            style={{
                              color: Themes.Loadsmart['color-neutral-white'].toString(),
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Open Builder">
                        <IconButton
                          scale="large"
                          onClick={e => {
                            handleOnClickBuilder(t)
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="View Onboardings/Installations">
                        <IconButton scale="large" onClick={() => handleOnClickInstallations(t)}>
                          <List />
                        </IconButton>
                      </Tooltip>
                    </Layout.Group>
                  </Layout.Group>
                </StyledPaper>
              )
            })}
          </Layout.Group>
        )}
      </Layout.Group>
    </Layout.Stack>
  )
}

export default IntegrationTemplateList
