import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { RuntimeTranslationMap, TranslationMap } from 'common/types/kraken-core/TranslationMap'
import { TranslationSettings } from 'common/types/kraken-core/TranslationSettings'
import { searchTradingPartner } from 'services/kraken-core/trading_partner/tradingPartner.service'
import {
  searchRuntimeTranslationMaps,
  searchTranslationMaps,
} from 'services/kraken-core/translation_maps/translationMaps.service'
import { searchTranslationSettings } from 'services/kraken-core/translation_settings/translationSettings.service'

export const onSearchTradingPartners = async ({
  query,
}: {
  query: string
}): Promise<TradingPartner[]> => {
  const result = await searchTradingPartner({
    filters: [
      {
        property: 'name',
        value: query,
      },
    ],
    page_size: 100,
  })

  return result?.response?.data?.results || []
}

export const onSearchTranslationMaps = async ({
  query,
}: {
  query: string
}): Promise<TranslationMap[]> => {
  const result = await searchTranslationMaps({
    name: query,
    page_size: 100,
  })

  return result?.response?.data?.results || []
}

export const onSearchRuntimeTranslationMaps = async ({
  query,
}: {
  query: string
}): Promise<RuntimeTranslationMap[]> => {
  const result = await searchRuntimeTranslationMaps({
    name: query,
    page_size: 100,
  })
  return result?.response?.data?.results || []
}

export const onSearchTranslationSettings = async ({
  query,
}: {
  query: string
}): Promise<TranslationSettings[]> => {
  const result = await searchTranslationSettings({
    name: query,
    page_size: 100,
  })

  return result?.response?.data?.results || []
}
