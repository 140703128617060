import { Tag } from '@loadsmart/loadsmart-ui'
import Icon from 'atoms/Icon'
import styled from 'styled-components'

export interface DirectionTagProps {
  direction: 'inbound' | 'outbound'
  size?: 'small' | 'default' | 'large'
}

const StyledInboundTag = styled(Tag)`
  background-color: #a1887f !important;
  color: #fff;
`

const StyledOutboundTag = styled(Tag)`
  background-color: #2196f3 !important;
  color: #fff;
`

const DirectionTag = ({ direction, size }: DirectionTagProps) => {
  if (direction === 'inbound') {
    return (
      <StyledInboundTag size={size || 'default'} variant="accent">
        <Icon name="arrow-left" size={20} />
        {'  '}
        Inbound
      </StyledInboundTag>
    )
  }

  return (
    <StyledOutboundTag size={size || 'default'} variant="accent">
      Outbound <Icon name="arrow-right" size={20} />
    </StyledOutboundTag>
  )
}

export default DirectionTag
