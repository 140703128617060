import Loading from 'atoms/Loading'
import { Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import TranslationSettingsList from './containers/TranslationSettingsList'

const TranslationSettingsIndex = () => {
  const { path } = useRouteMatch()
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={path}>
          <TranslationSettingsList />
        </Route>
        <Route path={`${path}/:id`} exact>
          <TranslationSettingsList />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default TranslationSettingsIndex
