import { AxiosRequestConfig } from 'axios'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import {
  ListDataModelSchemaEnginesRequest,
  ListDataModelSchemaEnginesResponse,
  ListDataModelSchemaReponse,
  ListDataModelSchemaRequest,
  ValidateDataModelSchemaFile,
  ValidateDataModelSchemaFileResponse,
} from './types'
import { to } from 'common/helpers/awaitTo'
import { del, get, post, put } from 'common/HTTPClient'
import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import { DataModelSchemaDetail } from 'common/types/kraken-core/DataModelSchema'

export async function getDataModelSchema(
  id?: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<DataModelSchemaDetail>> {
  const [error, response] = await to(get(`/data_model/schemas/${id}`, { ...config }))
  if (error) throw error
  return { error, response }
}

export async function listDataModelSchema(
  data?: ListDataModelSchemaRequest,
  config?: AxiosRequestConfig
): Promise<PaginatedResponse<ListDataModelSchemaReponse>> {
  let params: any = {
    ...data,
  }

  if (data?.owner) {
    params = {
      ...params,
      owner: null,
      owner_name: data.owner.name,
    }
  }
  const [error, response] = await to(get('/data_model/schemas', { params, ...config }))
  if (error) throw error
  return response?.data
}

export async function listEngines(
  data?: ListDataModelSchemaEnginesRequest,
  config?: AxiosRequestConfig
): Promise<PaginatedResponse<ListDataModelSchemaEnginesResponse>> {
  const params = { ...data }
  const [error, response] = await to(get('/data_model/engines', { params, ...config }))
  if (error) throw error
  return response?.data
}

export async function updateDataModelSchema(
  entity: DataModelSchemaDetail,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<DataModelSchemaDetail>> {
  const [error, response] = await to(put(`/data_model/schemas/${entity.id}`, entity, { ...config }))
  if (error) throw error
  return { error, response }
}

export async function createDataModelSchema(
  entity: DataModelSchemaDetail,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<DataModelSchemaDetail>> {
  const [error, response] = await to(post('/data_model/schemas', entity, { ...config }))
  return { error, response }
}

export async function deleteDataModelSchema(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<DataModelSchemaDetail>> {
  const [error, response] = await to(del(`/data_model/schemas/${id}`, { ...config }))
  return { error, response }
}

export async function validateDataModelSchemaFile(
  params: ValidateDataModelSchemaFile,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ValidateDataModelSchemaFileResponse>> {
  const formData = new FormData()
  formData.append('file', params.file)
  const [error, response] = await to(
    post(`/data_model/schemas/${params.dataModelSchemaId}/test`, formData, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  )
  return { error, response }
}
