import {
  Button,
  Card,
  IconButton,
  Layout,
  LoadingDots,
  Tag,
  TagProps,
  Text,
  Tooltip,
} from '@loadsmart/loadsmart-ui'
import Icon from 'atoms/Icon'
import { TranslationMapDetailsContext } from 'pages/TranslationMaps/containers/TranslationMapsDetails'
import { DevelopmentTranslationTestCase } from 'pages/TranslationMaps/types'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import TestCaseExecutionStatus from './TestCase/TestCaseExecutionStatus'

import '../../index.css'
import {
  useDeleteTranslationTestCase,
  useRunTranslationTestCase,
  useSearchTranslationTestCases,
  useCloneTranslationTestCase,
} from 'pages/TranslationMaps/api'
import TestCaseDetail from './TestCase/TestCaseDetail'
import {
  TranslationTestCaseDetail,
  TranslationTestCaseExecutionResult,
  TranslationTestCaseResult,
} from 'common/types/kraken-core/TranslationMap'
import isEmpty from 'lodash.isempty'
import { toast } from 'react-toastify'
import TestCaseExecutionResult from './TestCase/TestCaseExecutionResult'
import styled from 'styled-components'
import { Grow } from '@mui/material'

export interface DevelopmentDetailsTestsProps {}

enum FilterStatus {
  ALL = 'all',
  PASSED = 'passed',
  FAILED = 'failed',
}

interface StyledFilterTagProps extends TagProps {
  selected: boolean
}

const StyledTag = (props: StyledFilterTagProps) => <Tag {...props}>{props.children}</Tag>

const StyledFilterTag = styled(StyledTag)`
  border: ${props => (props.selected ? '3px solid' : '')};
`

const DevelopmentDetailsTests = (props: DevelopmentDetailsTestsProps) => {
  const translationMapDetailsContext = useContext(TranslationMapDetailsContext)
  const translationMap = translationMapDetailsContext.translationMap
  const [localTests, setLocalTests] = useState<Array<DevelopmentTranslationTestCase>>([])
  const [filteredTests, setFilteredTests] = useState<Array<DevelopmentTranslationTestCase>>([])
  const [filterValue, setFilterValue] = useState<FilterStatus>(FilterStatus.ALL)
  const [showTestDetail, setShowTestDetails] = useState<boolean>(false)
  const [showTestExecutionResult, setShowTestExecutionResult] = useState<boolean>(false)
  const testDetailRef = useRef<DevelopmentTranslationTestCase>()

  const {
    data: loadedTests,
    isLoading: isLoadingTests,
    refetch: refetchTests,
  } = useSearchTranslationTestCases(
    {
      translationMapId: translationMap?.id as any,
    },
    { enabled: !isEmpty(translationMap?.id) }
  )

  const {
    mutate: deleteTest,
    isLoading: isDeletingTest,
    error: deleteTestError,
    isSuccess: deleteTestSuccess,
    data: deletedTest,
  } = useDeleteTranslationTestCase()

  const {
    mutateAsync: runTestMutationAsync,
    data: runTestResultResponse,
    isLoading: runTestIsLoading,
    error: runTestError,
  } = useRunTranslationTestCase()

  const {
    mutate: cloneMutation,
    isLoading: isCloning,
    isSuccess: cloneSuccess,
    isError: cloneError,
    data: clonedMutationData,
  } = useCloneTranslationTestCase()

  const updateLocalTest = useCallback(
    (testToBeUpdated: DevelopmentTranslationTestCase) => {
      setLocalTests(previous =>
        previous.map(localTest => {
          if (localTest.testCaseDetail?.id === testToBeUpdated.testCaseDetail?.id) {
            return {
              ...testToBeUpdated,
            }
          }
          return localTest
        })
      )
    },
    [localTests]
  )

  const showDetails = (test: DevelopmentTranslationTestCase) => {
    testDetailRef.current = test
    setShowTestDetails(true)
  }

  const closeDetails = () => {
    testDetailRef.current = undefined
    setShowTestDetails(false)
  }

  const showExecutionDetails = (test: DevelopmentTranslationTestCase) => {
    testDetailRef.current = test
    setShowTestExecutionResult(true)
  }

  const closeExecutionDetails = () => {
    testDetailRef.current = undefined
    setShowTestExecutionResult(false)
  }

  const runTest = async (testId: string, showNotification?: boolean | false) => {
    if (translationMap?.id && testId) {
      const localTest = localTests.find(p => p.testCaseDetail?.id === testId)
      updateLocalTest({
        ...localTest,
        isRunning: true,
      })
      const response = await runTestMutationAsync({
        translationMapId: translationMap?.id,
        translationTestCaseId: testId,
      })

      const resultExecution: TranslationTestCaseExecutionResult = {
        success: response.success,
        details: response.details,
      }

      if (response.expected_payload) {
        resultExecution.expected_payload = atob(String(response.expected_payload))
      }

      if (response.translated_payload) {
        resultExecution.translated_payload = atob(String(response.translated_payload))
      }

      updateLocalTest({
        ...localTest,
        isRunning: false,
        execution: resultExecution,
      })

      if (!showNotification) return

      if (resultExecution.success) {
        toast.success('Test has passed')
      } else {
        toast.error('Test has failed')
      }
    }
  }

  const runFilteredTests = async () => {
    filteredTests.forEach(async q => {
      if (q.testCaseDetail?.id) await runTest(q.testCaseDetail?.id)
    })
  }

  const createNew = () => {
    // 1. Create new local instance and open drawer
    testDetailRef.current = {
      isRunning: false,
      testCaseDetail: {
        description: '',
        expected_result: TranslationTestCaseResult.SUCCESS,
      },
    }
    setShowTestDetails(true)
  }

  const remove = (test: DevelopmentTranslationTestCase) => {
    if (!isEmpty(translationMap?.id) && !isEmpty(test.testCaseDetail?.id)) {
      testDetailRef.current = test
      deleteTest({
        translationMapId: translationMap?.id as any,
        translationTestCaseId: test.testCaseDetail?.id as any,
      })
    }
  }

  const clone = (test: DevelopmentTranslationTestCase) => {
    if (!test.testCaseDetail) {
      toast.error("There's no test selected")
      return
    }

    if (translationMap?.id && test.testCaseDetail?.id) {
      cloneMutation({
        translationMapId: translationMap?.id,
        translationTestCaseId: test.testCaseDetail?.id,
      })
    }
  }

  const handleOnCreatedNewTestCase = (created: TranslationTestCaseDetail) => {
    if (!created) return
    setLocalTests(p => {
      const newEntry: DevelopmentTranslationTestCase = {
        isRunning: false,
        testCaseDetail: {
          id: created.id,
          description: created.description,
          expected_result: created.expectation_definition?.result as any,
        },
      }
      return [...p, newEntry]
    })
  }

  const handleOnUpdateTestCase = (updated: TranslationTestCaseDetail) => {
    if (!updated) return
    setLocalTests(
      localTests.map(localTest => {
        if (localTest.testCaseDetail?.id === updated.id) {
          return {
            ...localTest,
            testCaseDetail: {
              description: updated.description,
              expected_result: updated.expectation_definition?.result as any,
              id: updated.id,
            },
          }
        }
        return localTest
      })
    )
  }

  const feedLocalTests = () => {
    if (loadedTests && loadedTests.results) {
      const tests: DevelopmentTranslationTestCase[] = loadedTests.results
        .map(testCase => {
          const alreadyInLocal = localTests.find(t => t.testCaseDetail?.id === testCase.id)

          return {
            testCaseDetail: testCase,
            isRunning: alreadyInLocal ? alreadyInLocal.isRunning : false,
            execution: alreadyInLocal ? alreadyInLocal.execution : undefined,
          }
        })
        .sort((a, b) => a.testCaseDetail.description.localeCompare(b.testCaseDetail.description))
      setLocalTests(tests)
    }
  }

  const filterBaseOnFilteredValue = () => {
    if (filterValue === FilterStatus.PASSED)
      return localTests.filter(t => t.execution && t.execution.success)

    if (filterValue === FilterStatus.FAILED)
      return localTests.filter(t => t.execution && !t.execution.success)

    return [...localTests]
  }

  useEffect(
    function onDelete() {
      if (deleteTestError) {
        toast.error(String(deleteTestError))
        return
      }

      if (deleteTestSuccess) {
        toast.success('Successfully deleted')
        setLocalTests(
          localTests.filter(t => t.testCaseDetail?.id != testDetailRef.current?.testCaseDetail?.id)
        )
        testDetailRef.current = undefined
      }
    },
    [deleteTestError, deleteTestSuccess, isDeletingTest, deletedTest]
  )

  useEffect(() => {
    feedLocalTests()
  }, [loadedTests])

  useEffect(() => {
    setFilteredTests(filterBaseOnFilteredValue())
  }, [localTests, filterValue])

  useEffect(() => {
    if (cloneError) {
      toast.error(`Cloned has failed: ${cloneError}`)
      return
    }

    if (cloneSuccess) {
      toast.success(`Cloned was created`)
      if (clonedMutationData) {
        refetchTests()
        const clonedTest: DevelopmentTranslationTestCase = {
          isRunning: false,
          testCaseDetail: {
            ...clonedMutationData,
          },
        }
        showDetails(clonedTest)
      }
      return
    }
  }, [cloneError, cloneSuccess])

  const TestsHeader = useMemo(() => {
    const passed = localTests.filter(t => t.execution && t.execution.success)
    const failed = localTests.filter(t => t.execution && !t.execution.success)

    return (
      <>
        <Text variant="heading-sm-bold">Tests</Text>
        <StyledFilterTag
          onClick={() => {
            setFilterValue(FilterStatus.ALL)
          }}
          className="cursor-pointer"
          selected={filterValue === FilterStatus.ALL}
        >{`ALL: ${localTests.length}`}</StyledFilterTag>
        <StyledFilterTag
          variant="danger"
          onClick={() => {
            setFilterValue(FilterStatus.FAILED)
          }}
          selected={filterValue === FilterStatus.FAILED}
          className="cursor-pointer"
        >{`FAILED: ${failed.length}`}</StyledFilterTag>
        <StyledFilterTag
          variant="accent"
          onClick={() => {
            setFilterValue(FilterStatus.PASSED)
          }}
          selected={filterValue === FilterStatus.PASSED}
          className="cursor-pointer"
        >{`PASSED: ${passed.length}`}</StyledFilterTag>
      </>
    )
  }, [localTests, filteredTests])

  return (
    <>
      {/* Execution result modal */}

      {testDetailRef.current ? (
        <TestCaseExecutionResult
          open={showTestExecutionResult}
          onClose={() => closeExecutionDetails()}
          localTest={testDetailRef.current}
        />
      ) : null}

      {/* Detail drawer */}
      <TestCaseDetail
        onClose={closeDetails}
        open={showTestDetail}
        testCaseId={testDetailRef.current?.testCaseDetail?.id}
        onCreated={handleOnCreatedNewTestCase}
        onUpdated={handleOnUpdateTestCase}
      />

      <Layout.Stack className="w-full">
        <Layout.Group justify="space-between" align="center">
          <Layout.Group align="center">{TestsHeader}</Layout.Group>
          <Layout.Group>
            <Button leading={<Icon name="bolt" />} onClick={() => runFilteredTests()}>
              Run
            </Button>
            <Button variant="primary" onClick={createNew}>
              New
            </Button>
          </Layout.Group>
        </Layout.Group>

        <Layout.Stack style={{ width: '30vw', overflow: 'scroll', maxHeight: '75vh' }}>
          {isLoadingTests ? <LoadingDots /> : null}
          {!localTests || localTests.length <= 0 ? (
            <Text variant="caption">
              There are no tests defined,
              <Text variant="caption-bold" className="cursor-pointer" onClick={createNew}>
                click here
              </Text>
              to create a new one...
            </Text>
          ) : null}
          {!isLoadingTests
            ? filteredTests.map(test => {
                return (
                  <Grow in exit key={test.testCaseDetail?.id}>
                    <div>
                      <Card status={'success' as any}>
                        <Card.Body>
                          <Layout.Group justify="space-between">
                            <Layout.Stack style={{ maxWidth: '22vw' }}>
                              <Text variant="caption-bold">Description</Text>
                              <Text>{test.testCaseDetail?.description}</Text>
                            </Layout.Stack>
                            <Layout.Stack>
                              <Text variant="caption-bold">Expectation</Text>
                              <Text variant="body-bold">
                                {test.testCaseDetail?.expected_result}
                              </Text>
                            </Layout.Stack>
                          </Layout.Group>
                        </Card.Body>
                        <Card.Separator />
                        <Card.Subtitle>
                          <Layout.Group justify="space-between" align="center" className="pt-4">
                            <TestCaseExecutionStatus
                              test={test}
                              onClick={() => showExecutionDetails(test)}
                            />
                            <Layout.Group justify="flex-end">
                              <Tooltip message="Clone">
                                <IconButton
                                  disabled={test.isRunning || isCloning}
                                  onClick={() => {
                                    clone(test)
                                  }}
                                  scale="default"
                                >
                                  <Icon name="copy" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip message="Run">
                                <IconButton
                                  disabled={test.isRunning}
                                  onClick={() => {
                                    if (test.testCaseDetail?.id)
                                      runTest(test.testCaseDetail.id, true)
                                  }}
                                  scale="default"
                                >
                                  <Icon name="bolt" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip message="Edit">
                                <IconButton
                                  onClick={() => showDetails(test)}
                                  disabled={test.isRunning}
                                  scale="default"
                                >
                                  <Icon name="edit" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip message="Delete">
                                <IconButton
                                  onClick={() => remove(test)}
                                  disabled={test.isRunning}
                                  scale="default"
                                >
                                  <Icon name="remove" />
                                </IconButton>
                              </Tooltip>
                            </Layout.Group>
                          </Layout.Group>
                        </Card.Subtitle>
                      </Card>
                    </div>
                  </Grow>
                )
              })
            : null}
        </Layout.Stack>
      </Layout.Stack>
    </>
  )
}

export default DevelopmentDetailsTests
