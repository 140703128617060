import { lazy, Suspense } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import MessagesDashboardContextProvider, { CONSTANTS } from './MessageDahsboardContext'
import MessagesDashboard from './MessagesDashboard'
import MessagesDashboardViewContextProvider from './MessagesDashboardViewContext'

const MessageDetail = lazy(() => import('./MessageDetail/MessageDetail'))

function MessagesIndex() {
  const { path } = useRouteMatch()

  return (
    <MessagesDashboardContextProvider>
      <Suspense fallback={<h1> Loading ...</h1>}>
        <Switch>
          <Route exact path={path}>
            <MessagesDashboard />
          </Route>

          <Route path={`${path}/:id`}>
            <MessagesDashboardViewContextProvider view={CONSTANTS.DETAIL_VIEW}>
              <MessageDetail />
            </MessagesDashboardViewContextProvider>
          </Route>
        </Switch>
      </Suspense>
    </MessagesDashboardContextProvider>
  )
}

export default MessagesIndex
