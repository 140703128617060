import { Tag, Table, LoadingDots, Layout, Text } from '@loadsmart/loadsmart-ui'
import SimplePagination from 'atoms/SimplePagination'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { GatewaySetting } from '../../../common/types/kraken-core/GatewaySettings'
import { useSearchRelatedGateways } from '../api'

export interface ConnectionRelatedGatewaysProps {
  connectionId: string
}

const ConnectionRelatedGateways = (props: ConnectionRelatedGatewaysProps) => {
  const [page, setPage] = useState<number>(1)

  const COLUMNS = [
    {
      Header: '#',
      Cell(row: GatewaySetting) {
        const idAsString = row.id?.slice(0, 8) || '-'

        return (
          <Link to={`/gateways/${row.id}`}>
            <Tag>{idAsString}</Tag>
          </Link>
        )
      },
    },
    {
      Header: 'Name',
      Cell(row: GatewaySetting) {
        return row.name
      },
    },
    {
      Header: 'Gateway type',
      Cell(row: GatewaySetting) {
        return row.gateway_type
      },
    },
    {
      Header: 'Active',
      alignment: 'right',
      Cell(row: GatewaySetting) {
        return row.is_active ? (
          <Tag size="small" variant="accent">
            Active
          </Tag>
        ) : (
          <Tag size="small" variant="danger">
            Inactive
          </Tag>
        )
      },
    },
  ]

  const { data: relatedGateways } = useSearchRelatedGateways({
    connection: props.connectionId,
    page,
  })

  return (
    <>
      {!relatedGateways && <LoadingDots />}

      {Boolean(relatedGateways && relatedGateways?.results?.length <= 0) && (
        <Layout.Box padding="s">
          <Text>No related gateways</Text>
        </Layout.Box>
      )}

      {Boolean(relatedGateways && relatedGateways?.results?.length > 0) && (
        <Layout.Stack>
          <Table className="gateway-settings-table" data-testid="gateway-settings-list">
            <Table.Head>
              {COLUMNS.map(column => (
                <Table.Cell key={column.Header} alignment={column.alignment as any}>
                  {column.Header}
                </Table.Cell>
              ))}
            </Table.Head>
            <Table.Body>
              {relatedGateways?.results?.map(row => (
                <Table.Row key={row.id}>
                  {COLUMNS.map(cell => (
                    <Table.Cell
                      className="text-sm"
                      key={cell.Header}
                      alignment={cell.alignment as any}
                    >
                      {cell.Cell(row)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <Layout.Box className="flex justify-end w-full">
            <SimplePagination
              currentPage={page}
              previousPage={() => setPage(prev => prev - 1)}
              nextPage={() => setPage(prev => prev + 1)}
              canPreviousPage={!!relatedGateways?.previous}
              canNextPage={!!relatedGateways?.next}
            />
          </Layout.Box>
        </Layout.Stack>
      )}
    </>
  )
}

export default ConnectionRelatedGateways
