import { Button, Layout } from '@loadsmart/loadsmart-ui'
import { useQueryParams } from 'hooks/useQueryParams'
import isEmpty from 'lodash.isempty'
import { useCallback, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import TranslationSettingsDetail from './TranslationSettingsDetail'
import { Drawer } from '@mui/material'

export interface TranslationSettingsDetailDrawerProps {
  open: boolean
  translationSettingsId?: string
  onClose: () => void
  onSave: () => void
}

const ROOT_PATH = '/translation-settings'

const TranslationSettingsDrawer = ({
  open,
  translationSettingsId,
  onClose,
  onSave,
}: TranslationSettingsDetailDrawerProps) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const queryParams = useQueryParams()
  const onSaveCallback = useRef<any>()

  const handleClose = useCallback(() => {
    if (translationSettingsId && pathname?.endsWith(translationSettingsId))
      history.replace({ pathname: ROOT_PATH, search: queryParams.toString() })
    onClose()
  }, [translationSettingsId, onClose])

  const handleSave = useCallback(async () => {
    if (onSaveCallback.current) {
      const result = await onSaveCallback.current()
      console.log(result)
      if (result && onSave) {
        onSave()
      }
    }
  }, [onSaveCallback.current, handleClose])

  useEffect(() => {
    if (translationSettingsId && !isEmpty(translationSettingsId)) {
      if (pathname.includes(translationSettingsId)) return
      history.push({
        pathname: `${pathname}/${translationSettingsId}`,
        search: queryParams.toString(),
      })
    }
  }, [translationSettingsId])

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Layout.Stack className="p-6 h-full" style={{ width: '50vw' }}>
        <TranslationSettingsDetail
          translationSettingsId={translationSettingsId}
          onLoadCallback={(loaded, onSave) => {
            onSaveCallback.current = onSave
          }}
        />

        <Layout.Stack className="h-full stick" align="flex-end" justify="flex-end">
          <Layout.Group className="w-full" justify="flex-end" align="flex-end">
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          </Layout.Group>
        </Layout.Stack>
      </Layout.Stack>
    </Drawer>
  )
}

export default TranslationSettingsDrawer
