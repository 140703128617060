import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Unwrapped } from '@jsonforms/material-renderers'
import { Grid } from '@mui/material'
import { CustomRendererMetadata } from '../abstraction'
import Advice from '../../../../atoms/Advice'

const { MaterialNativeControl } = Unwrapped

export const CONTROL_WITH_ADVICE_RENDERER_META: CustomRendererMetadata = {
  type: 'ControlWithAdvice',
  label: 'Control with Advice',
}

export const ControlWithAdvice = (props: ControlProps) => {
  let hasValidDefinition = false
  const { uischema } = props

  if (uischema.options && uischema.options.adviceType && uischema.options.adviceValue) {
    hasValidDefinition = true
  }
  return (
    <>
      {!hasValidDefinition && (
        <Advice type="alert" value={`Invalid definition: ${JSON.stringify(uischema)}`} />
      )}
      {hasValidDefinition && (
        <Grid container>
          <Advice type={uischema.options?.adviceType} value={uischema.options?.adviceValue} />
          <MaterialNativeControl {...props} />
        </Grid>
      )}
    </>
  )
}

export default withJsonFormsControlProps(ControlWithAdvice)
