import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { ProcessorPlugin, ProcessorPluginList } from 'common/types/kraken-core/Processors'

export async function listProcessors(
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<Array<ProcessorPluginList>>> {
  const [error, response] = await to(get(`/processors`, { ...config }))
  return { error, response }
}

export async function getProcessor(
  name: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ProcessorPlugin>> {
  const [error, response] = await to(get(`/processors/${name}`, { ...config }))
  return { error, response }
}

export async function getProcessorReferences(
  name: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ProcessorPlugin>> {
  const [error, response] = await to(get(`/processors/${name}/references`, { ...config }))
  return { error, response }
}
