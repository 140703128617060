const sftpSchema = {
  title: 'SFTP Settings',
  type: 'object',
  properties: {
    port: { title: 'Port', type: 'integer' },
    hostname: {
      title: 'Hostname',
      format: 'hostname',
      minLength: 1,
      maxLength: 65536,
      type: 'string',
    },
    timeout: { title: 'Timeout', type: 'integer' },
    banner_timeout: { title: 'Banner Timeout', type: 'number' },
    confirm_size_after_delivery: {
      title: 'Confirms the file size after delivery',
      type: 'boolean',
    },
    operation: {
      title: 'Operation',
      enum: ['operation:putfo', 'operation:open'],
      type: 'string',
    },
    open: {
      title: 'Open Settings',
      allOf: [{ $ref: '#/definitions/SFTPOpenSettingsSchema' }],
    },
    credentials: {
      title: 'Credentials',
      allOf: [{ $ref: '#/definitions/SFTPCredentialsSchema' }],
    },
    create_new_path: {
      title: 'Create path/directory if not exists',
      type: 'boolean',
    },
  },
  required: ['port', 'hostname', 'credentials'],
  definitions: {
    SFTPOpenSettingsSchema: {
      title: 'Open Settings',
      type: 'object',
      properties: {
        mode: { title: 'The File Open Mode', type: 'string' },
      },
      required: ['mode'],
    },
    CredentialStorage: {
      title: 'CredentialStorage',
      description: 'An enumeration.',
      enum: ['local', 'vault'],
      type: 'string',
    },
    SFTPPrivateKeySchema: {
      title: 'Private Key',
      type: 'object',
      properties: {
        type: { title: 'Type', enum: ['rsa'], type: 'string' },
        passphrase: {
          title: 'Password',
          type: 'string',
        },
        content: {
          title: 'Content',
          options: {
            multi: true,
            inputAttributes: { input_height: '200px' },
          },
          type: 'string',
        },
      },
    },
    SFTPCredentialsSchema: {
      title: 'Credentials',
      type: 'object',
      properties: {
        storage: {
          title: 'Storage Type',
          default: 'local',
          allOf: [{ $ref: '#/definitions/CredentialStorage' }],
        },
        username: {
          title: 'Username',
          minLength: 1,
          propertyOrder: 0,
          type: 'string',
        },
        password: {
          title: 'Password',
          propertyOrder: 100,
          type: 'string',
        },
        private_key: {
          title: 'Private Key',
          propertyOrder: 200,
          allOf: [{ $ref: '#/definitions/SFTPPrivateKeySchema' }],
        },
      },
    },
  },
}

export default sftpSchema
