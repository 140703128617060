import { useState, useEffect, useRef, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumbs, Layout, Dropdown, Button, Tabs } from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import { removeToken } from 'common/helpers/removeToken'
import ErrorPanel from 'molecules/ErrorPanel'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import { onExport, useGetRoute, usePush, useUpdateRoute } from '../api'
import { RouteRead } from 'common/types/kraken-core/Routes'
import { toast } from 'react-toastify'
import RouteDetailForm from '../components/RouteDetailForm'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import PushableEntityStatusTag from 'common/components/development/PusableEntityStatus'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import HistoryComponent from '../components/HistoryComponent'

export interface RoutesDetailProps {
  route?: RouteRead
}

const RoutesDetail = (props: RoutesDetailProps) => {
  useTabTitle('Route')
  const browserHistory = useHistory()
  const { id } = useParams<{ id: string }>()
  const [breadCrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([
    {
      label: 'Routes',
      active: true,
      url: '#',
      onClick: () => {
        browserHistory.push('/routes')
      },
    },
  ])
  const [model, setModel] = useState<RouteRead>()
  const { isLoading, error, data, refetch } = useGetRoute({ id })

  const handleOnSave = useRef(() => {})
  const handleExport = useRef(() => {})
  const handleOnPush = useRef(() => {})

  const history = useMemo(() => <HistoryComponent id={id} />, [id])

  const {
    mutate: update,
    isLoading: isUpdating,
    error: updateError,
    isSuccess: updateSuccess,
  } = useUpdateRoute()

  const {
    mutate: push,
    isLoading: isPushing,
    isSuccess: isPushSuccess,
    isError: isPushError,
    error: pushError,
  } = usePush()

  const topNavigationContext = useTopNavigationContext()

  const handleRefetch = () => {
    setTimeout(() => {
      refetch()
    }, 1000)
  }

  handleExport.current = () => {
    if (!data?.id) return
    onExport(data.id, data?.code || '')
  }

  handleOnSave.current = () => {
    if (model) {
      setModel(model)
      update(model)
      handleRefetch()
    }
  }

  handleOnPush.current = () => {
    if (model) {
      push(model)
    } else {
      toast.error("There's no Route to be pushed")
    }
  }

  useEffect(() => {
    if (model)
      setBreadcrumbs(prev => [
        prev[0],
        {
          label: model?.code || '',
        },
      ])
  }, [model])

  useEffect(() => {
    if (updateError) toast.error(`Error when updating: ${updateError}`)
    if (updateSuccess) toast.success('Successfully updated')
  }, [updateError, isUpdating])

  useEffect(() => {
    if (isPushError) {
      toast.error(`Error when pushing: ${pushError}`)
      return
    }

    if (isPushSuccess) {
      toast.success(`Successfully pushed`)
    }
    handleRefetch()
  }, [isPushError, isPushing])

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group className="w-full" align="center" justify="space-between">
          <Breadcrumbs entries={breadCrumbs} />
          <Layout.Group>
            {model?.status && <PushableEntityStatusTag status={model.status} />}
            <Button variant="primary" onClick={() => handleOnSave.current()}>
              Save
            </Button>
            <Dropdown>
              <Dropdown.Trigger>Actions</Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport.current()}>Export</Dropdown.Item>
                <Dropdown.Item onClick={() => handleOnPush.current()}>Push</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Layout.Group>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs])

  return (
    <>
      <Tabs className="flex flex-col" direction="horizontal" data-testid="route-tabs">
        <Tabs.Items>
          <Tabs.Item default name="settings-tab">
            Settings
          </Tabs.Item>
          <Tabs.Item name="history-tab">History</Tabs.Item>
        </Tabs.Items>

        <Tabs.Panels>
          <Tabs.Panel name="settings-tab">
            <Layout.Stack className="w-full" space="xl">
              {isLoading ? (
                <Loading className="mt-8" />
              ) : error ? (
                <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
              ) : (
                !!data && <RouteDetailForm route={data} onChange={changed => setModel(changed)} />
              )}
            </Layout.Stack>
          </Tabs.Panel>
          <Tabs.Panel name="history-tab">{history}</Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </>
  )
}

export default RoutesDetail
