import { Button, Card, Layout, Tag, Text } from '@loadsmart/loadsmart-ui'
import ExternalLink from 'atoms/ExternalLink'
import isEmpty from 'lodash.isempty'
import FileViewer from 'molecules/FileViewer/FileViewer'
import moment from 'moment'
import { useUpdateImplementation } from 'pages/TranslationMaps/api'
import { TranslationMapDetailsContext } from 'pages/TranslationMaps/containers/TranslationMapsDetails'
import { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { DevelopmentDetailsContext } from '../DevelopmentDetails'
import { TranslationMapDevelopmentLocalCache } from './types'

export interface DevelopmentDetailsImplemetationProps {}

const LOCAL_STORAGE_KEY = 'translation.implementation'
const IMPLEMENTATION_DOCS_URL =
  'https://loadsmart.atlassian.net/wiki/spaces/engineering/pages/3335389211/KRAKEN+Translation+-+How+To'

const getDevelopmentContextFromLocalStorage = (): TranslationMapDevelopmentLocalCache => {
  let localDevelopmentCache = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY) || '{}'
  ) as TranslationMapDevelopmentLocalCache
  if (!localDevelopmentCache || !localDevelopmentCache.entries) {
    localDevelopmentCache = {
      entries: [],
    }
  }
  return localDevelopmentCache
}

const StyledDocsLink = styled(ExternalLink)`
  font-size: 14px;
`

const DevelopmentDetailsImplemetation = (props: DevelopmentDetailsImplemetationProps) => {
  const implementationContext = useContext(DevelopmentDetailsContext)
  const translationMapDetailsContext = useContext(TranslationMapDetailsContext)
  const [decodedImplementation, setDecodedImplementaion] = useState<string>('')
  const [lastSaveAt, setLastSaveAt] = useState<Date>()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const encodedImplementation = useRef<string>(
    implementationContext.translationMap?.encoded_implementation || ''
  )
  const { mutate: updateImplementation, isSuccess, error } = useUpdateImplementation()

  const handleChange = (content: string) => {
    if (!content || isEmpty(content)) {
      return
    }
    const encoded = btoa(content)
    encodedImplementation.current = encoded
    saveInLocalCache()
    setIsDirty(true)
  }

  const handleOnSave = async () => {
    if (!implementationContext.translationMap) return
    if (!isEmpty(encodedImplementation.current)) {
      setLastSaveAt(new Date(Date.now()))
      updateImplementation({
        id: implementationContext.translationMap?.id,
        encoded_implementation: encodedImplementation.current,
      })
      clearLocalCache()
      setIsDirty(false)
    }
  }

  const clearLocalCache = () => {
    const localDevelopmentCache = getDevelopmentContextFromLocalStorage()
    const entriesDfferentOfThisContext =
      localDevelopmentCache.entries.filter(
        e => e.translationMapId != translationMapDetailsContext.translationMap?.id
      ) || []
    const updated: TranslationMapDevelopmentLocalCache = {
      entries: entriesDfferentOfThisContext,
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated))
  }

  const saveInLocalCache = () => {
    const translationMap = translationMapDetailsContext.translationMap
    if (!translationMap || !translationMap.id) {
      return
    }
    const localDevelopmentCache = getDevelopmentContextFromLocalStorage()
    if (!localDevelopmentCache.entries) {
      localDevelopmentCache.entries = []
    }

    let localStorageContext = localDevelopmentCache.entries.find(
      e => e.translationMapId === translationMapDetailsContext.translationMap?.id
    )
    if (!localStorageContext) {
      localStorageContext = {
        implementationHistory: [],
        translationMapId: translationMap.id,
      }
    }

    localStorageContext.implementationHistory.push({
      at: moment().utc().toISOString(),
      implementation: encodedImplementation.current,
      sequence: localStorageContext.implementationHistory.length + 1,
    })

    const updated = {
      entries: [
        ...localDevelopmentCache.entries.filter(e => e.translationMapId != translationMap.id),
        localStorageContext,
      ],
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated))
  }

  const getDevelopmentLocalStorageContext = () => {
    const localDevelopmentCache = getDevelopmentContextFromLocalStorage()
    return localDevelopmentCache.entries.find(
      e => e.translationMapId === implementationContext.translationMap?.id
    )
  }

  const isThereUnSavedChanges = () => {
    const context = getDevelopmentLocalStorageContext()
    return context && context.implementationHistory && context.implementationHistory.length > 0
  }

  const decodeImplementation = (encoded: string) => {
    return atob(encoded)
  }

  useEffect(() => {
    if (implementationContext.translationMap) {
      const context = getDevelopmentLocalStorageContext()
      const encoded = implementationContext.translationMap.encoded_implementation
      if (context && context.implementationHistory && context.implementationHistory.length > 0) {
        const last = context.implementationHistory[context.implementationHistory.length - 1]
        encodedImplementation.current = last.implementation
        setDecodedImplementaion(decodeImplementation(last.implementation))
      } else if (!isEmpty(encoded)) setDecodedImplementaion(decodeImplementation(encoded))
    }
  }, [implementationContext.translationMap])

  useEffect(() => {
    if (isSuccess) {
      toast.success('Successfully updated')
      if (translationMapDetailsContext.refetch) {
        translationMapDetailsContext.refetch()
      }
      return
    }

    if (error) {
      toast.error(`Something went wrong: ${error}`)
      return
    }
  }, [error, isSuccess])

  const formattedLastSaveDate = () => {
    return `Last save at ${lastSaveAt?.toLocaleTimeString()}`
  }

  return (
    <Layout.Stack className="w-full">
      <Layout.Group>
        <Layout.Group align="center" justify="space-between" className="w-full">
          <Layout.Group align="flex-end">
            <Text variant="heading-sm-bold">Implementation</Text>
            <StyledDocsLink href={IMPLEMENTATION_DOCS_URL}>How-to</StyledDocsLink>
          </Layout.Group>

          <Layout.Group align="center">
            <Text variant="caption">{lastSaveAt && formattedLastSaveDate()}</Text>
            {(isDirty || isThereUnSavedChanges()) && <Tag variant="warning">PENDING CHANGES</Tag>}
            <Button onClick={handleOnSave}>Save</Button>
          </Layout.Group>
        </Layout.Group>
      </Layout.Group>
      <Card>
        <FileViewer
          content={decodedImplementation}
          contentType="jinja"
          options={{
            heigth: window.innerHeight * 0.75,
          }}
          showOptions={false}
          onChange={change => handleChange(change)}
        />
      </Card>
    </Layout.Stack>
  )
}

export default DevelopmentDetailsImplemetation
