import { HistoryAware } from './HistoryAware'

export interface AbstractTranslationMap {
  id: string
  iac_reference?: object
  code?: string
  created_at: string
  updated_at: string
  deleted_at?: string
  name: string
  status?: string
  path: string
  loader: string
  input_content_type?: string
  input_encoding?: string
  output_reuse_original_message: boolean
  output_content_type: string
  output_encoding: string
  output_name_pattern: string
  output_headers?: object
  use_when_direction: 'inbound' | 'outbound'
  use_when_facts: Record<string, string>
  settings_data_schema?: object
  settings_ui_schema?: object
  encoded_implementation: string
}

export interface TranslationMap extends AbstractTranslationMap {
  runtime?: string
}

export type RuntimeTranslationMap = AbstractTranslationMap

export interface TranslationMapLoader {
  loader: string
  description: string
}

export enum TranslationMapLoaderEnum {
  RUNTIME_DATABASE_LOADER = 'translation:map:loader:runtime-database',
}

export interface TranslationMapEncoding {
  output_encoding: string
  encoding: string
  description: string
}

export interface TranslationMapContentType {
  output_content_type: string
  content_type: string
  description: string
}

export enum TranslationTestCaseResult {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface TranslationTestCaseConversionCode {
  source?: string
  target?: string
  source_code?: string
  target_code?: string
  source_value?: string
  target_value?: string
  use_when?: string
}

export interface TranslationTestCaseInputMessage {
  headers?: object
  source?: string
  target?: string
  message_uuid?: string
}

export interface TranslationTestCaseInput {
  payload?: string
  settings?: object
  conversion_codes?: Array<TranslationTestCaseConversionCode>
  message_receipts?: Array<any>
  lookup_table_entries?: Array<any>
  message?: TranslationTestCaseInputMessage
}

export interface TranslationTestCaseExpectation {
  result: TranslationTestCaseResult
  payload?: string
  headers: object
}

export interface TranslationTestCase {
  id?: string
  description: string
  expected_result: TranslationTestCaseResult
}
export interface TranslationTestCaseDetail {
  id?: string
  description: string
  input_definition: TranslationTestCaseInput
  expectation_definition: TranslationTestCaseExpectation
}

export interface TranslationTestCaseExecutionResult {
  success: boolean
  details?: string
  expected_payload?: string
  translated_payload?: string
}

export interface TranslationMapHistory extends TranslationMap, HistoryAware {}
