const ftpUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'General',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/hostname',
                },
                {
                  type: 'Control',
                  scope: '#/properties/port',
                },
              ],
            },
            {
              type: 'Control',
              scope: '#/properties/create_new_path',
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Authentication',
      elements: [
        {
          type: 'Group',
          label: 'Credentials',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/credentials',
            },
          ],
        },
      ],
    },
  ],
}

export default ftpUiSchema
