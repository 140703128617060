import { Select } from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import isEmpty from 'lodash.isempty'
import { onSearchTradingPartners } from 'common/components/datasources/datasource'
import { useEffect, useMemo, useState } from 'react'
import { getTradingPartner } from 'services/kraken-core/trading_partner/tradingPartner.service'

export interface TradingPartnerSelectorProps {
  selected?: {
    label?: string
    id?: string
  }
  onSelect: (selected: TradingPartner) => void
}

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
}

export default function TradingPartnerSelector(props: TradingPartnerSelectorProps) {
  const [selectedDataDetails, setSelectedDataDetails] = useState<TradingPartner>()
  const [selected, setSelected] = useState<any>({
    value: props.selected?.id,
    label: props.selected?.label,
  })

  useEffect(() => {
    async function get() {
      if (!props.selected?.id) return
      const d = await getTradingPartner({ id: props.selected.id })
      if (!d.error) {
        setSelectedDataDetails(d.response?.data)
      }
    }
    get()
  }, [props.selected])

  useEffect(() => {
    if (selectedDataDetails) {
      setSelected((c: any) => ({
        ...c,
        value: selectedDataDetails.id,
        label: selectedDataDetails.name,
      }))
    }
  }, [selectedDataDetails])

  const MemoizedSelector = useMemo(() => {
    const isValueNotFullyLoaded = selected.label === selected.value || isEmpty(selected.label)
    return (
      <Select
        name="select_trading_partner"
        onChange={e => props.onSelect(e.target.value as TradingPartner)}
        value={isValueNotFullyLoaded ? null : ({ ...selected } as Selectable)}
        datasources={DATASOURCES.TRADING_PARTNER}
        placeholder="Select a Trading Partner"
      />
    )
  }, [selected])

  return <>{MemoizedSelector}</>
}
