import {
  Button,
  Card,
  Dialog,
  IconButton,
  Label,
  Layout,
  LoadingDots,
  Tag,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Icon from 'atoms/Icon'
import IntegrationTestSuiteSelector from 'common/components/selectors/IntegrationTestSuiteSelector'
import {
  IntegrationTestCaseExecutionResult,
  IntegrationTestSuite,
  LocalIntegrationTestCase,
  LocalIntegrationTestCaseExecutionType,
} from 'common/types/kraken-core/IntegrationTest'
import useDebouncedValue from 'hooks/useDebouncedValue'
import {
  TabViewIndex,
  useIntegrationTestContext,
} from 'pages/IntegrationTest/IntegrationTestContext'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  useDeleteIntegrationTestCase,
  useGetIntegrationTestCaseList,
  useRunIntegrationTestCase,
} from '../api'
import IntegrationTestCaseDetailDrawer from './IntegrationTestCaseDetailDrawer'

import IntegrationTestCaseStatus from './IntegrationTestCaseStatus'

export default function IntegrationTestCaseList() {
  const context = useIntegrationTestContext()
  const [filters, setFilters] = useState<any>({})
  const debouncedFilters = useDebouncedValue(filters, 500)
  const [localTests, setLocalTests] = useState<Array<LocalIntegrationTestCase>>([])
  const [selectedLocalTest, setSelectedLocalTest] = useState<LocalIntegrationTestCase>()
  const [suiteId, setSuiteId] = useState<string>()
  const [confirmationDialog, setConfirmationDialog] = useState({
    opened: false,
    callback: () => {},
    title: '',
    message: '',
    confirmed: false,
  })
  const { data, refetch, isLoading } = useGetIntegrationTestCaseList(
    {
      filters: debouncedFilters,
      suite_id: context.suite?.id,
    },
    {
      enabled: false,
      cacheTime: 0,
    }
  )

  const {
    mutate: deleteTestCase,
    isError: isDeleteTestCaseError,
    isSuccess: isDeleteTestCaseSuccess,
    error: deleteTestCaseError,
  } = useDeleteIntegrationTestCase()

  const { mutateAsync: runTestCase } = useRunIntegrationTestCase()

  async function run(test: LocalIntegrationTestCase) {
    updateLocalTestCase({
      ...test,
      execution: {
        ...test.execution,
        isRunning: true,
      },
    })
    runTestCase({
      id: test.id,
      suite_id: context.suite?.id,
    })
      .then(response => {
        const testResult = response as IntegrationTestCaseExecutionResult
        updateLocalTestCase({
          ...test,
          execution: {
            isRunning: false,
            status: testResult.success
              ? LocalIntegrationTestCaseExecutionType.PASSED
              : LocalIntegrationTestCaseExecutionType.FAILED,
            result: testResult,
          },
        })
      })
      .catch(failure => {
        updateLocalTestCase({
          ...test,
          execution: {
            isRunning: false,
            status: LocalIntegrationTestCaseExecutionType.ERRROR,
            result: {
              success: false,
              details: String(failure),
            },
          },
        })
      })
  }

  const updateLocalTestCase = (testCaseToBeUpdated: LocalIntegrationTestCase) => {
    setLocalTests((c: Array<LocalIntegrationTestCase>) => {
      return c.map(test => {
        if (test.id === testCaseToBeUpdated.id) {
          return {
            ...test,
            ...testCaseToBeUpdated,
          }
        }
        return test
      })
    })
  }

  const handleOpenDetail = (testCase: LocalIntegrationTestCase) => {
    context.setTestDetailViewState({
      ...context.testDetailViewState,
      activeViewTab: TabViewIndex.ENTRY_POINT,
    })
    context.openTestCaseDetails(testCase)
  }

  const handleRemove = (testCase: LocalIntegrationTestCase) => {
    deleteTestCase({
      id: testCase.id,
      suite_id: context.suite?.id,
    })
  }

  const handleFetchList = () => {
    if (context.suite?.id) refetch()
  }

  const handleRunTest = (test: LocalIntegrationTestCase) => {
    run(test)
  }

  const handleCloneTest = async (test: LocalIntegrationTestCase) => {
    setConfirmationDialog(c => ({
      ...c,
      opened: true,
      confirmed: true,
      message: 'Are you sure you want to create a clone?',
      title: 'Confirm Clone',
      callback: async () => {
        // Create test
        const cloned = await context.cloneTestCase(test.id)
        if (cloned) {
          const localTest = {
            id: cloned.id || '',
            description: cloned.description || '',
            execution: {
              isRunning: false,
              status: LocalIntegrationTestCaseExecutionType.NOT_EXECUTED,
            },
          }
          localTests.push(localTest)
          handleOpenDetail(localTest)
        } else {
          toast.error('Something went wrong when cloning')
        }
        setConfirmationDialog(c => ({ ...c, opened: false, confirmed: false }))
      },
    }))
  }

  const handleRunAllTests = async () => {
    localTests.forEach(test => {
      run(test)
    })
  }

  const handleOpenExecutionDetails = (testCase: LocalIntegrationTestCase) => {
    setSelectedLocalTest(testCase)
    context.setTestDetailViewState({
      ...context.testDetailViewState,
      activeViewTab: TabViewIndex.EXECUTION,
    })
    context.openTestCaseDetails(testCase)
  }

  const COLUMNS = [
    {
      Header: '#',
      style: {
        width: '1%',
      },
      Cell(row: LocalIntegrationTestCase) {
        const idAsString = row.id?.slice(0, 8) || '-'
        return (
          <Tag className="cursor-pointer" variant="default" onClick={() => handleOpenDetail(row)}>
            {idAsString}
          </Tag>
        )
      },
    },
    {
      Header: 'Status',
      style: {
        width: '2%',
      },
      Cell(row: LocalIntegrationTestCase) {
        return (
          <Layout.Group align="center">
            <IntegrationTestCaseStatus test={row} onClick={() => handleOpenExecutionDetails(row)} />
          </Layout.Group>
        )
      },
    },
    {
      Header: 'Name',
      style: {
        width: '30%',
      },
      Cell(row: LocalIntegrationTestCase) {
        return <Layout.Group align="center">{row.description}</Layout.Group>
      },
    },
    {
      Header: 'Actions',
      style: {
        width: '5%',
        alignment: 'right',
      },
      Cell(row: LocalIntegrationTestCase) {
        return (
          <Layout.Group justify="flex-end">
            <IconButton
              scale="small"
              onClick={e => {
                handleCloneTest(row)
                e.stopPropagation()
              }}
            >
              <Icon name="copy" />
            </IconButton>
            <IconButton
              scale="small"
              onClick={e => {
                handleRunTest(row)
                e.stopPropagation()
              }}
            >
              <Icon name="bolt" />
            </IconButton>
            <IconButton
              scale="small"
              onClick={e => {
                handleRemove(row)
                e.stopPropagation()
              }}
            >
              <Icon name="remove" />
            </IconButton>
          </Layout.Group>
        )
      },
    },
  ]

  useEffect(() => {
    if (data) {
      const mappedTests: Array<LocalIntegrationTestCase> = []
      for (const test of data.results) {
        mappedTests.push({
          ...test,
          execution: {
            status: LocalIntegrationTestCaseExecutionType.NOT_EXECUTED,
            isRunning: false,
          },
        })
      }
      setLocalTests(mappedTests)
    }
  }, [data])

  useEffect(() => {
    handleFetchList()
  }, [context.suite, debouncedFilters])

  useEffect(() => {
    if (isDeleteTestCaseSuccess) {
      toast.success('Successfully deleted')
    } else if (isDeleteTestCaseError) {
      toast.error(`Something went wrong when deleting: ${deleteTestCaseError}`)
    }
    handleFetchList()
  }, [isDeleteTestCaseSuccess, isDeleteTestCaseError, deleteTestCaseError])

  return (
    <Layout.Stack className="w-full">
      <Dialog
        open={confirmationDialog.opened}
        onOverlayClick={() => setConfirmationDialog(c => ({ ...c, opened: false }))}
        scale="small"
      >
        <Dialog.Header>{confirmationDialog.title}</Dialog.Header>
        <Dialog.Body>{confirmationDialog.message}</Dialog.Body>
        <Dialog.ActionConfirm onConfirm={() => confirmationDialog.callback()} />
        <Dialog.ActionCancel
          onCancel={() => setConfirmationDialog(c => ({ ...c, opened: false }))}
        />
      </Dialog>

      <IntegrationTestCaseDetailDrawer
        onClose={() => {
          handleFetchList()
        }}
      />

      <Layout.Stack space="s">
        <Label>Test Suite</Label>
        <Layout.Group>
          <IntegrationTestSuiteSelector
            selected={context.suite}
            onSelect={(selected: IntegrationTestSuite) => {
              context.setSuite(selected)
            }}
          />
          {/* <Button
            variant="primary"
            onClick={() => {
              if (!suiteId) {
                toast.error('Must select one existing Suite')
                return
              }

              context.setSuite({
                id: suiteId,
                description: '',
              })
            }}
          >
            Go
          </Button> */}
        </Layout.Group>
      </Layout.Stack>

      {context.suite?.id ? (
        <Layout.Stack>
          <Card className="w-full">
            <Card.Title>Filters</Card.Title>
            <Card.Separator />
            <Card.Body>
              <Layout.Group>
                <Layout.Stack space="s">
                  <Label>Description</Label>
                  <TextField
                    value={filters.description}
                    onChange={e => setFilters((c: any) => ({ ...c, description: e.target.value }))}
                    size={100}
                  />
                </Layout.Stack>
              </Layout.Group>
            </Card.Body>
          </Card>

          <Card className="w-full">
            <Card.Title>
              <Layout.Group justify="flex-end">
                <Button leading={<Icon name="bolt" />} onClick={handleRunAllTests}>
                  Run All
                </Button>
                <Button variant="primary" onClick={() => context.createNewTestCase()}>
                  Create New
                </Button>
              </Layout.Group>
            </Card.Title>
            <Card.Separator />

            <TableContainer>
              <Table size="small" padding="normal" data-testid="gateway-settings-list">
                <TableHead>
                  <TableRow key={'head'}>
                    {COLUMNS.map(column => (
                      <TableCell
                        key={column.Header}
                        width={column.style.width}
                        align={(column.style.alignment as any) || 'left'}
                      >
                        <Text variant="button-sm">{column.Header}</Text>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading && localTests.length > 0
                    ? localTests.map(row => (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          {COLUMNS.map(cell => {
                            return (
                              <TableCell
                                key={`${cell.Header}.${row.id}`}
                                width={cell.style.width}
                                align={(cell.style.alignment as any) || 'left'}
                              >
                                {cell.Cell(row)}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>

            {isLoading ? (
              <Layout.Stack className="p-4" space="l">
                <LoadingDots />
              </Layout.Stack>
            ) : null}

            {!isLoading && localTests.length === 0 ? (
              <Layout.Stack className="p-2" space="l">
                <Text variant="body-bold">No test cases available.</Text>
              </Layout.Stack>
            ) : null}
          </Card>
        </Layout.Stack>
      ) : null}
    </Layout.Stack>
  )
}
