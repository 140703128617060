import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { ConversionCodeCategory } from 'common/types/kraken-core/ConversionCode'

export interface ListConversionCodeCategoryRequest {
  id?: string
  party?: string
  direction?: string
  key?: string
  description?: string
  source_code?: string
  target_code?: string
  page?: number
  page_size?: number
}

export interface ListConversionCodeCategoryReponse {
  count: number
  next: string
  previous: string
  results: Array<ConversionCodeCategory>
}

export async function listConversionCodeCategory(
  data?: ListConversionCodeCategoryRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<ListConversionCodeCategoryReponse>> {
  let params: any = {
    page: data?.page || 1,
    page_size: data?.page_size || 100,
  }

  if (data?.description) {
    params = {
      description: data.description,
    }
  }

  const [error, response] = await to(get('/conversion/category', { params, ...config }))

  if (error) throw error

  return { error, response }
}
