import {
  ConversionCode,
  ConversionCodeCategory,
  ConversionCodeCategoryDirection,
} from 'common/types/kraken-core/ConversionCode'
import {
  NotificationType,
  TradingPartner,
  TradingPartnerCode,
  TradingPartnerCodeType,
  TradingPartnerContact,
  TradingPartnerContactType,
  TradingPartnerInstructions,
} from 'common/types/kraken-core/TradingPartner'
import SearchResponse from 'common/types/SearchResponse'
import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from 'react-query'
import {
  ConversionCodeHistory,
  createConversionCode as createConversionCodeService,
  getConversionCodeHistory,
  listConversionCode,
  updateConversionCode as updateConversionCodeService,
} from 'services/kraken-core/conversion_code/conversionCode.service'
import { listConversionCodeCategory } from 'services/kraken-core/conversion_code/conversionCodeCategory.service'
import {
  createNotificationSetting,
  CreateNotificationSetting,
  deleteContact,
  deleteNotificationSetting,
  DeleteNotificationSetting,
  getTradingPartner,
  getTradingPartnerCodes,
  getTradingPartnerCodeTypes,
  getTradingPartnerInstructions,
  onGetTradingPartnerHistory,
  onSearchContactHistory,
  onSearchContacts,
  onSearchContactsTypes,
  onSearchNotificationTypes,
  SearchContactHistoryPayload,
  SearchContactsPayload,
  searchTradingPartner,
  SearchTradingPartnerHistoryPayload,
  updateContact,
  updateNotificationSetting,
  UpdateNotificationSetting,
  updateTradingPartnerInstructions,
  updateTranslationSetting,
  UpdateTranslationSetting,
  UpdateComponentSetting,
  updateComponentSetting,
  CreateTradingPartnerCode,
  createCode,
  UpdateTradingPartnerCode,
  DeleteTradingPartnerCode,
  deleteCode,
  updateCode,
  createContact,
} from 'services/kraken-core/trading_partner/tradingPartner.service'
import {
  QUERIES,
  useSearchConversionCodesParams,
  useSearchConversionCodesCategoryParams,
  useSearchTradingPartnersParams,
  useSearchTradingPartnerContactsParams,
  useSearchTradingPartnerContactHistoryparams,
} from './types'

export const useSearchTradingPartners = (
  params: useSearchTradingPartnersParams
): UseQueryResult<SearchResponse<TradingPartner[]>> => {
  const queryFn = async ({ page, name }: useSearchTradingPartnersParams) => {
    const { response } = await searchTradingPartner({
      page: page || 1,
      page_size: 10,
      filters: [
        {
          property: 'name',
          value: name,
        },
      ],
    })

    return response?.data || {}
  }

  const query = useQuery([QUERIES.SEARCH_TRADING_PARTNER, params], () => queryFn(params), {
    keepPreviousData: true,
  })

  return query
}

export const useGetTradingPartner = (
  id?: string,
  options?: any
): UseQueryResult<TradingPartner> => {
  const queryFn = async (partnerId: string) => {
    const { response } = await getTradingPartner({ id: partnerId })
    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_TRADING_PARTNER, id], () => queryFn(id || ''), {
    enabled: !!id,
    ...options,
  })
  return query
}

export const useGetTradingPartnerHistory = (
  id?: string
): UseQueryResult<SearchTradingPartnerHistoryPayload[]> => {
  const queryFn = async (partnerId: string) => {
    const history = await onGetTradingPartnerHistory(partnerId)

    return history || []
  }

  const query = useQuery([QUERIES.GET_TRADING_PARTNER_HISTORY, id], () => queryFn(id || ''), {
    enabled: !!id,
  })

  return query
}

export const useGetTradingPartnerInstructions = (
  id?: string
): UseQueryResult<TradingPartnerInstructions> => {
  const queryFn = async (partnerId: string) => {
    const { response } = await getTradingPartnerInstructions({
      id: partnerId,
    })

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_TRADING_PARTNER_INSTRUCTIONS, id], () => queryFn(id || ''), {
    enabled: !!id,
  })

  return query
}

export const useSearchConversionCodesCategories = (
  params: useSearchConversionCodesCategoryParams
): UseQueryResult<SearchResponse<ConversionCodeCategory[]>> => {
  const queryFn = async ({ description, page }: useSearchConversionCodesCategoryParams) => {
    const { response } = await listConversionCodeCategory({
      description,
      page: page || 1,
      page_size: 10,
    })

    return response?.data || {}
  }

  const query = useQuery(
    [QUERIES.SEARCH_CONVERSION_CODES_CATEGORIES, params],
    () => queryFn(params),
    {
      keepPreviousData: true,
    }
  )

  return query
}

export const useSearchConversionCodes = (
  params: useSearchConversionCodesParams
): UseQueryResult<SearchResponse<ConversionCode[]>> => {
  const queryFn = async ({ query = {}, page }: useSearchConversionCodesParams) => {
    const { direction, ...queryRest } = query

    if (direction === ConversionCodeCategoryDirection.INBOUND) {
      const { response } = await listConversionCode({
        ...queryRest,
        source: queryRest.target,
        target: queryRest.source,
        page: page || 1,
        page_size: 10,
      })

      return response?.data || {}
    }

    const { response } = await listConversionCode({
      ...queryRest,
      page: page || 1,
      page_size: 10,
    })

    return response?.data || {}
  }

  const query = useQuery([QUERIES.SEARCH_CONVERSION_CODES, params], () => queryFn(params), {
    keepPreviousData: true,
  })

  return query
}

export const useGetConversionCodeCategoryHistory = (
  id?: string
): UseQueryResult<ConversionCodeHistory[]> => {
  const queryFn = async (conversionCodeId: string) => {
    const { response } = await getConversionCodeHistory(conversionCodeId)

    return response?.data || {}
  }

  const query = useQuery([QUERIES.GET_CONVERSION_CODE_HISTORY, id], () => queryFn(id || ''), {
    enabled: !!id,
  })

  return query
}

export const useCreateUpdateConversionCode = (): UseMutationResult<
  any,
  unknown,
  ConversionCode & { direction: ConversionCodeCategoryDirection },
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (
      newData: ConversionCode & {
        direction: ConversionCodeCategoryDirection
      }
    ) => {
      if (newData.id) {
        const { response } = await updateConversionCodeService(newData)

        return response?.data
      }

      if (newData.direction === ConversionCodeCategoryDirection.INBOUND) {
        const { response } = await createConversionCodeService({
          ...newData,
          target: newData.source,
          source: newData.target,
        })

        return response?.data
      }

      const { response } = await createConversionCodeService(newData)

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.SEARCH_CONVERSION_CODES])
      },
    }
  )

  return mutation
}

export const useUpdateTradingPartnerInstructions = (): UseMutationResult<
  any,
  unknown,
  { id: string; instructions: TradingPartnerInstructions },
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (params: { id: string; instructions: TradingPartnerInstructions }) => {
      await updateTradingPartnerInstructions({
        ...params,
      })
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([QUERIES.GET_TRADING_PARTNER_INSTRUCTIONS, variables.id])
      },
    }
  )

  return mutation
}

export const useSearchNotificationTypes = (): UseQueryResult<NotificationType[]> => {
  const queryFn = async () => {
    const data = await onSearchNotificationTypes()

    return data || []
  }

  const query = useQuery([QUERIES.SEARCH_NOTIFICATION_TYPES], () => queryFn())

  return query
}

export const useSearchContactHistory = (
  params: useSearchTradingPartnerContactHistoryparams,
  options?: any
): UseQueryResult<SearchContactHistoryPayload[]> => {
  const queryFn = async (data: useSearchTradingPartnerContactHistoryparams) => {
    const { id, contact } = data

    if (!id || !contact) return []

    const history = await onSearchContactHistory({
      id,
      contact,
    })

    return history || []
  }

  const query = useQuery([QUERIES.SEARCH_CONTACTS_HISTORY, params], () => queryFn(params), {
    keepPreviousData: false,
    enabled: !!params.id && !!params.contact?.id,
  })

  return query
}

export const useSearchContacts = (
  params: useSearchTradingPartnerContactsParams
): UseQueryResult<SearchContactsPayload> => {
  const queryFn = async ({ page, id }: useSearchTradingPartnerContactsParams) => {
    const data = await onSearchContacts({
      id: id || '',
      page: page || 1,
      page_size: 10,
    })

    return data || []
  }

  const query = useQuery([QUERIES.SEARCH_CONTACTS, params], () => queryFn(params), {
    keepPreviousData: true,
    enabled: !!params.id,
  })

  return query
}

export const useSearchContactsTypes = (): UseQueryResult<TradingPartnerContactType[]> => {
  const queryFn = async () => {
    const data = await onSearchContactsTypes()

    return data || []
  }

  const query = useQuery([QUERIES.SEARCH_CONTACTS_TYPES], () => queryFn())

  return query
}

export const useUpdateContact = (): UseMutationResult<
  any,
  unknown,
  Partial<TradingPartnerContact>,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (contact: Partial<TradingPartnerContact>) => {
      const { response } = await updateContact({
        id: contact.owner as string,
        contact: contact,
      })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.SEARCH_CONTACTS])
      },
    }
  )

  return mutation
}

export const useDeleteContact = (): UseMutationResult<
  any,
  unknown,
  TradingPartnerContact,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (data: TradingPartnerContact) => {
      const { response } = await deleteContact({
        id: data.owner,
        contact: data,
      })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.SEARCH_CONTACTS])
      },
    }
  )

  return mutation
}

export const useCreateContact = (): UseMutationResult<
  any,
  unknown,
  TradingPartnerContact,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (data: TradingPartnerContact) => {
      const { response, error } = await createContact({
        tradingPartnerId: data.owner,
        contact: data,
      })
      if (error) throw JSON.stringify(error.response?.data)
      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.SEARCH_CONTACTS])
      },
    }
  )
  return mutation
}

export const useDeleteNotificationSetting = (): UseMutationResult<
  any,
  unknown,
  DeleteNotificationSetting,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (data: DeleteNotificationSetting) => {
      const { response } = await deleteNotificationSetting({ ...data })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.GET_TRADING_PARTNER])
      },
    }
  )

  return mutation
}

export const useCreateNotificationSetting = (): UseMutationResult<
  any,
  unknown,
  CreateNotificationSetting,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (data: CreateNotificationSetting) => {
      const { response } = await createNotificationSetting({ ...data })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.GET_TRADING_PARTNER])
      },
    }
  )

  return mutation
}

export const useUpdateNotificationSetting = (): UseMutationResult<
  any,
  unknown,
  UpdateNotificationSetting,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (data: UpdateNotificationSetting) => {
      const { response } = await updateNotificationSetting({ ...data })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERIES.GET_TRADING_PARTNER])
      },
    }
  )

  return mutation
}

export const useUpdateTranslationSetting = (): UseMutationResult<
  any,
  unknown,
  UpdateTranslationSetting,
  unknown
> => {
  const mutation = useMutation(async (data: UpdateTranslationSetting) => {
    const { response } = await updateTranslationSetting({ ...data })

    return response?.data
  }, {})
  return mutation
}

export const useUpdateComponentSetting = (): UseMutationResult<
  any,
  unknown,
  UpdateComponentSetting,
  unknown
> => {
  const mutation = useMutation(async (data: UpdateComponentSetting) => {
    const { response } = await updateComponentSetting({ ...data })

    return response?.data
  }, {})
  return mutation
}

export const useGetTradingPartnerCodeType = (): UseQueryResult<Array<TradingPartnerCodeType>> => {
  const queryFn = async () => {
    const { response } = await getTradingPartnerCodeTypes()
    return response?.data || []
  }
  const query = useQuery([QUERIES.GET_TRADING_PARTNER_CODE_TYPES], () => queryFn(), {})
  return query
}

export const useGetTradingPartnerCodes = (id?: string): UseQueryResult<TradingPartnerCode[]> => {
  const queryFn = async () => {
    const { response } = await getTradingPartnerCodes({ id: id || '' })

    return response?.data?.results || []
  }

  const query = useQuery([QUERIES.GET_TRADING_PARTNER_CODES], () => queryFn(), {
    cacheTime: 0,
  })

  return query
}

export const useCreateCode = (): UseMutationResult<
  any,
  unknown,
  CreateTradingPartnerCode,
  unknown
> => {
  const mutation = useMutation(async (data: CreateTradingPartnerCode) => {
    const { response, error } = await createCode({ ...data })
    if (error) throw JSON.stringify(error.response?.data)
    return response?.data
  })
  return mutation
}

export const useUpdateCode = (): UseMutationResult<
  any,
  unknown,
  UpdateTradingPartnerCode,
  unknown
> => {
  const mutation = useMutation(async (data: UpdateTradingPartnerCode) => {
    const { response, error } = await updateCode({ ...data })
    if (error) throw JSON.stringify(error.response?.data)
    return response?.data
  })
  return mutation
}

export const useDeleteCode = (): UseMutationResult<
  any,
  unknown,
  DeleteTradingPartnerCode,
  unknown
> => {
  const mutation = useMutation(async (data: DeleteTradingPartnerCode) => {
    const { response, error } = await deleteCode({ ...data })
    if (error) throw JSON.stringify(error.response?.data)
    return response?.data
  })
  return mutation
}
