import { Label, Layout, Select, TextField } from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import {
  onSearchDataModelSchemaEngines,
  onSearchTradingPartners,
} from 'common/components/datasources/datasource'
import { DataModelSchemaEngineList } from 'common/types/kraken-core/DataModelSchema'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { useEffect, useState } from 'react'
import { ListDataModelSchemaRequest } from 'services/kraken-core/data_model/types'

export interface DataModelSchemaListFiltersProps {
  filters?: ListDataModelSchemaRequest
  onChange: (changed: ListDataModelSchemaRequest) => void
}

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
  ENGINES: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (e: DataModelSchemaEngineList) => e.engine_name || '',
        getLabel: (e: DataModelSchemaEngineList) => e.engine_name || '',
      },
      fetch: onSearchDataModelSchemaEngines,
    }),
  ],
}

export default function DataModelSchemaListFilters(props: DataModelSchemaListFiltersProps) {
  const [filters, setFilters] = useState<ListDataModelSchemaRequest>(props.filters || {})

  const handleFilterChanged = (filterName: string, value: any) => {
    const updatedFilters = {
      ...filters,
      [filterName]: value,
    }
    setFilters(updatedFilters)
    props.onChange(updatedFilters)
  }

  return (
    <Layout.Group>
      <Layout.Switcher className="w-full">
        <Layout.Stack space="s" className="w-full">
          <Label htmlFor="description">Description</Label>
          <TextField
            id="description"
            value={filters.description}
            onChange={e => handleFilterChanged('description', e.target.value)}
          />
        </Layout.Stack>
        <Layout.Stack space="s">
          <Label htmlFor="engine_selector">Engine</Label>
          <Select
            id="engine_selector"
            name="select_engine"
            onChange={e => {
              handleFilterChanged(
                'engine',
                e.target.value ? (e.target.value as DataModelSchemaEngineList).engine_name : null
              )
            }}
            value={
              {
                value: filters.engine,
                label: filters.engine,
              } as Selectable
            }
            datasources={DATASOURCES.ENGINES}
          />
        </Layout.Stack>

        <Layout.Stack space="s">
          <Label htmlFor="owner_partner_selector">Owner</Label>
          <Select
            id="owner_partner_selector"
            name="select_owner_trading_partner"
            onChange={e => {
              handleFilterChanged(
                'owner',
                e.target.value ? (e.target.value as TradingPartner) : null
              )
            }}
            value={
              {
                value: filters.owner?.id,
                label: filters.owner?.name,
              } as Selectable
            }
            datasources={DATASOURCES.TRADING_PARTNER}
          />
        </Layout.Stack>
      </Layout.Switcher>
    </Layout.Group>
  )
}
