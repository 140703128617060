/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { Button, Layout, Tag, Tooltip } from '@loadsmart/loadsmart-ui'
import Editor from '@monaco-editor/react'
import monaco from 'monaco-editor'
import Icon from 'atoms/Icon'
import { useEffect, useState } from 'react'
import { EDI_X12_LANGUAGE_ID, registerEDIX12Languague } from './languages/EDIX12'

export enum FileViewTheme {
  DEFAULT = 'vs',
  DARK = 'vs-dark',
}

export interface FileViewOptions {
  width?: number | string
  heigth?: number | string
  theme?: FileViewTheme
  readonly?: boolean
  showValidations?: boolean
}
interface FileViewState {
  rawContent: string
}

export interface FileViewerValidation {
  validations: Array<{
    startLineNumber: number
    startColumnNumber: number
    endLineNumber: number
    endColumnNumber: number
    message: string
  }>
}

const DEFAULT_LANGUAGE = 'txt'
const DEFAULT_HEIGHT = 400

export interface FileViewerProps {
  key?: string
  content: string
  contentType: string
  url?: string
  options: FileViewOptions
  onChange?: (value: string) => void
  onValidation?: (validation: FileViewerValidation) => void
  onSave?: () => void
  showOptions?: boolean
}

const contentTypeLanguageMapping: any = {
  text: 'txt',
  'application/json': 'json',
  'application/xml': 'html',
  'text/xml': 'html',
  'application/EDI-X12': EDI_X12_LANGUAGE_ID,
  jinja: 'twig',
}

function FileViewer({
  content,
  contentType,
  onChange,
  onValidation,
  onSave,
  options,
  url,
  showOptions = true,
}: FileViewerProps) {
  const [validation, setValidation] = useState<FileViewerValidation>({ validations: [] })
  const [editorInstance, setEditorInstance] = useState<monaco.editor.IStandaloneCodeEditor>()
  const [shouldPrettify, setShouldPrettify] = useState(true)
  const [state] = useState<FileViewState>({
    rawContent: content,
  })
  const language = contentTypeLanguageMapping[contentType] ?? DEFAULT_LANGUAGE

  const executeAction = async (action: string) => {
    try {
      await editorInstance?.getAction(action).run()
    } catch (error) {
      console.error(`Error to execute ${action}: ${error}`)
    }
  }

  const formatDocument = () => {
    executeAction('editor.action.formatDocument')
  }

  const openFileInAnotherTab = () => {
    window.open(url)
  }

  const resetToRawContent = () => {
    editorInstance?.setValue(state.rawContent)
  }

  const validationMessage = (): string => {
    if (validation.validations.length > 0)
      return validation.validations
        .map(v => {
          const line = `from line ${v.startLineNumber} to ${v.endLineNumber}`
          const column = `from column ${v.startColumnNumber} to ${v.endColumnNumber}`
          return `${line}, ${column}: ${v.message}`
        })
        .join(', ')
    else return ''
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (editorInstance && content && shouldPrettify) {
      /**
       * Editor takes a while to fully respond to actions, but it doesn't expose
       * parts of the API that would allow us to correctly idenfity the state and
       * launch the command at the precise time.
       * So, delaying the command by a couple of MS mitigate the issue.
       *  */
      const timeout = setTimeout(() => {
        formatDocument()
        setShouldPrettify(false)
      }, 100)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [editorInstance, content, shouldPrettify])

  useEffect(() => {
    /**
     * For a unknown reason the Editor doesn't automatically trigger its internal
     * onChange event when the content is all cleared or erased. This is a workaround
     * to manually trigger onChange event when the last change made is equal to empty content
     * (which is the case when the content is cleared)
     */
    editorInstance?.onDidChangeModelContent((event: any) => {
      if (!onChange) return
      const change = event.changes?.[0]
      if (change?.text === '' && editorInstance.getValue() === '') {
        // Callback last change
        onChange(change.text)
      } else {
        // Callback current editor value
        onChange(editorInstance.getValue())
      }
    })
  }, [editorInstance])

  return (
    <Layout.Stack className="w-full">
      <Layout.Group justify="space-between">
        <Layout.Group align="center" justify="space-between">
          {showOptions && (
            <Layout.Group>
              <Button onClick={resetToRawContent} leading={<Icon name="edit" />}>
                Raw
              </Button>

              <Button onClick={formatDocument} leading={<Icon name="bolt" />}>
                Prettify
              </Button>

              <Button
                onClick={openFileInAnotherTab}
                leading={<Icon name="download" />}
                disabled={!url}
              >
                Download
              </Button>

              {onSave && (
                <Button onClick={onSave} variant="primary">
                  Save
                </Button>
              )}
            </Layout.Group>
          )}
        </Layout.Group>

        {options.showValidations && validation.validations.length > 0 ? (
          <Layout.Group>
            <Tooltip message={validationMessage()}>
              <Tag variant="danger" size="large" prefix="">
                File has validations errors
              </Tag>
            </Tooltip>
          </Layout.Group>
        ) : null}
      </Layout.Group>

      <div>
        <Editor
          options={{
            readOnly: options.readonly,
            formatOnType: true,
            formatOnPaste: true,
            automaticLayout: true,
            tabSize: 2,
          }}
          language={language}
          theme={options.theme ?? FileViewTheme.DEFAULT}
          value={content || ''}
          onMount={(editor, monaco) => {
            registerEDIX12Languague(monaco)
            setEditorInstance(editor)
          }}
          height={options.heigth || DEFAULT_HEIGHT}
          onValidate={validation => {
            if (validation && validation.length > 0) {
              const validationObject = {
                validations: [
                  ...validation.map(v => ({
                    startColumnNumber: v.startColumn,
                    startLineNumber: v.startLineNumber,
                    endColumnNumber: v.endColumn,
                    endLineNumber: v.endLineNumber,
                    message: v.message,
                  })),
                ],
              }
              setValidation(validationObject)

              if (onValidation) onValidation(validationObject)
            } else {
              setValidation({ validations: [] })
            }
          }}
        />
      </div>
    </Layout.Stack>
  )
}

export default FileViewer
