import { to } from 'common/helpers/awaitTo'
import { post } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { UploadFile } from 'common/types/kraken-core/UploadFiles'

export interface UploadFileRequest {
  file: File
}

export async function uploadFile(
  request: UploadFileRequest
): Promise<DefaultHTTPResponse<UploadFile>> {
  const formData = new FormData()
  formData.append('file', request.file)

  let error: any = null
  let response: any
  ;[error, response] = await to(
    post('/upload_files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  )
  return {
    error,
    response,
  }
}
