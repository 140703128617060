import { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { ExpectedReplyMessageStatus } from 'common/types/kraken-core/ExpectedReplyMessage'

export const STATUS_OPTIONS: Array<GenericOption> = [
  {
    label: 'Pending',
    value: ExpectedReplyMessageStatus.PENDING,
  },
  {
    label: 'Accepted partially',
    value: ExpectedReplyMessageStatus.ACCEPTED_PARTIALLY,
  },
  {
    label: 'Accepted with errors',
    value: ExpectedReplyMessageStatus.ACCEPTED_WITH_ERRORS,
  },
  {
    label: 'Rejected',
    value: ExpectedReplyMessageStatus.REJECTED,
  },
  {
    label: 'Accepted',
    value: ExpectedReplyMessageStatus.ACCEPTED,
  },
]
