import { Paper, Box, Typography, Modal } from '@mui/material'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { MessageDiffModalProps } from '../types'

const parseObjectHeaderToString: (
  objToTransform: Record<string, string>
) => string = objToTransform =>
  Object.keys(objToTransform)
    .map((k: any) => ({
      key: k,
      value: objToTransform[k] ?? '',
    }))
    .sort((a, b) => (a.key > b.key ? 1 : -1))
    .map(obj => `${obj.key}: ${obj.value}`)
    .join('\n')

const DiffModal = ({ headers, prevHeaders, onClose, open }: MessageDiffModalProps) => (
  <Modal
    id="diff-data-modal"
    open={open ?? false}
    onClose={onClose}
    className="flex tems-center justify-center"
  >
    <Paper className="p-4 my-auto ml-2 mr-2 flex flex-col text-xs" style={{ width: '90%' }}>
      <Box className="mb-2">
        <Typography variant="h5">Headers</Typography>
      </Box>

      <ReactDiffViewer
        splitView
        hideLineNumbers
        oldValue={parseObjectHeaderToString(prevHeaders)}
        newValue={parseObjectHeaderToString(headers)}
        compareMethod={DiffMethod.TRIMMED_LINES}
      />
    </Paper>
  </Modal>
)

export default DiffModal
