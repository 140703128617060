import clsx from 'clsx'

import './Loading.css'

export interface LoadingProps {
  className?: string
  mode?: 'logo' | 'dots'
}

const Loading = ({ className, mode = 'logo' }: LoadingProps) => (
  <div className={clsx('rg-loader flex', className)} data-testid="loading">
    {mode === 'logo' ? Logo : Dots}
  </div>
)

const Logo = (
  <svg viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" width={48} height={48}>
    <defs>
      <polygon
        id="LeftPolygon"
        points="7.73977482 14.7671186 0.602998621 22.0642808 5.68434189e-14 19.7419577 5.68434189e-14 7.40808824 7.73977482 -5.68434189e-14"
      />
      <path
        d="M7.51585478,0.222328505 L0,7.91966183 C0.922717525,8.85448107 1.43696385,9.32189069 1.54273897,9.32189069 C1.64851409,9.32189069 6.63683364,9.32189069 16.5076976,9.32189069 L23.6792279,0.489859437 L7.51585478,0.222328505 Z"
        id="BottomPolygon"
      />
      <polygon
        id="TopPolygon"
        points="13.9462998 0.94519043 6.73762063 8.2333374 1.54835032e-15 8.2333374 0 0 14.172313 0"
      />
      <polygon
        id="RightPolygon"
        points="0.564155649 8.30744485 8.78003341 0 8.78003341 15.3590303 0.564155649 15.3590303"
      />
    </defs>
    <g fillRule="evenodd" stroke="none" strokeWidth={1} fill="none">
      <g className="left-group">
        <mask id="LeftMask" fill="white">
          <use xlinkHref="#LeftPolygon" />
        </mask>
        <use id="LeftUse" xlinkHref="#LeftPolygon" />
        <path
          d="M7.61051365,14.6491268 L7.61051365,1.03748431 L1.2274855,7.42146901 C1.08209058,7.56782047 0.999827665,7.76486884 0.999827665,7.97148267 L0.999827665,20.8724119 C0.999827665,21.3009443 1.34801023,21.6491268 1.77654261,21.6491268 L14.6774718,21.6491268 C14.8840857,21.6491268 15.0820906,21.5668639 15.2274855,21.421469 L21.6114702,15.0384409 L7.99982767,15.0384409 C7.78460493,15.0384409 7.61051365,14.8643496 7.61051365,14.6491268"
          mask="url(#LeftMask)"
          id="LeftPath"
        />
      </g>
      <g className="bottom-group">
        <mask id="BottomMask" fill="white">
          <use xlinkHref="#BottomPolygon" />
        </mask>
        <path
          d="M6.8995187,0.611642525 L6.8995187,-13 L0.516490551,-6.6160153 C0.371095634,-6.46966384 0.288832721,-6.27261547 0.288832721,-6.06600164 L0.288832721,6.83492758 C0.288832721,7.26345996 0.637015284,7.61164253 1.06554767,7.61164253 L13.9664769,7.61164253 C14.1730907,7.61164253 14.3710956,7.52937961 14.5164906,7.3839847 L20.9004752,1.00095655 L7.28883272,1.00095655 C7.07360998,1.00095655 6.8995187,0.826865264 6.8995187,0.611642525"
          mask="url(#BottomMask)"
          id="BottomPath"
        />
      </g>
      <g className="top-group">
        <mask id="TopMask" fill="white">
          <use xlinkHref="#TopPolygon" />
        </mask>
        <use id="TopUse" xlinkHref="#TopPolygon" />
        <path
          d="M0.389141686,7.55587641 L6.99982767,7.55587641 C7.2150504,7.55587641 7.38914169,7.72996769 7.38914169,7.94519043 L7.38914169,14.5558764 L13.9998277,14.5558764 L13.9998277,1.72286192 C13.9998277,1.29337299 13.6516451,0.94519043 13.2221562,0.94519043 L0.389141686,0.94519043 L0.389141686,7.55587641 Z"
          mask="url(#TopMask)"
          id="TopPath"
        />
      </g>
      <g className="right-group">
        <mask id="RightMask" fill="white">
          <use xlinkHref="#RightPolygon" />
        </mask>
        <use id="RightUse" xlinkHref="#RightPolygon" />
        <path
          d="M-6,7.85172458 L0.61068598,7.85172458 C0.825908718,7.85172458 1,8.02581586 1,8.2410386 L1,14.8517246 L7.61068598,14.8517246 L7.61068598,2.0187101 C7.61068598,1.58922117 7.26250342,1.2410386 6.83301448,1.2410386 L-6,1.2410386 L-6,7.85172458 Z"
          mask="url(#RightMask)"
          id="RightPath"
        />
      </g>
    </g>
  </svg>
)

const Dots = (
  <div className="text-center">
    <div className="dot bg-neutral" />
    <div className="dot bg-neutral" />
    <div className="dot bg-neutral" />
  </div>
)

export default Loading
