import {
  DataModelSchemaDetail,
  DataModelSchemaList,
} from 'common/types/kraken-core/DataModelSchema'
import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query'
import {
  createDataModelSchema,
  deleteDataModelSchema,
  getDataModelSchema,
  listDataModelSchema,
  updateDataModelSchema,
  validateDataModelSchemaFile,
} from 'services/kraken-core/data_model/dataModel.service'
import {
  ListDataModelSchemaEnginesRequest,
  ListDataModelSchemaReponse,
  ListDataModelSchemaRequest,
  ValidateDataModelSchemaFileResponse,
} from 'services/kraken-core/data_model/types'
import { UseValidateModelSchemaFile } from './types'

export const useGetDataModelSchema = (
  id: string,
  config?: any
): UseQueryResult<DataModelSchemaDetail> => {
  const queryFn = async (id: string) => {
    const { response } = await getDataModelSchema(id)
    return response?.data
  }
  const query = useQuery(['data_model/schemas/get', id], () => queryFn(id), { ...config })
  return query
}

export const useSearchDataModelSchemas = (
  params: ListDataModelSchemaEnginesRequest,
  config?: any
): UseQueryResult<PaginatedResponse<ListDataModelSchemaReponse>> => {
  const queryFn = async (filters: ListDataModelSchemaRequest) => {
    const response = await listDataModelSchema(filters)
    return response
  }
  const query = useQuery(['data_model/schemas/get', params], () => queryFn(params), { ...config })
  return query
}

export const useUpdateDataModelSchema = (): UseMutationResult<
  any,
  unknown,
  DataModelSchemaDetail,
  unknown
> => {
  const mutation = useMutation(async (params: DataModelSchemaDetail) => {
    const { response } = await updateDataModelSchema({ ...params })
    return response?.data
  })
  return mutation
}

export const useCreateDataModelSchema = (): UseMutationResult<
  any,
  unknown,
  DataModelSchemaDetail,
  unknown
> => {
  const mutation = useMutation(async (params: DataModelSchemaDetail) => {
    const { response } = await createDataModelSchema({ ...params })
    return response?.data
  })
  return mutation
}

export const useDeleteDataModelSchema = (): UseMutationResult<any, unknown, string, unknown> => {
  const mutation = useMutation(async (id: string) => {
    const { response } = await deleteDataModelSchema(id)
    return response?.data
  })
  return mutation
}

export const useValidateDataModelSchemaFile = (): UseMutationResult<
  ValidateDataModelSchemaFileResponse,
  unknown,
  UseValidateModelSchemaFile,
  unknown
> => {
  const mutation = useMutation(async (params: UseValidateModelSchemaFile) => {
    const { response } = await validateDataModelSchemaFile({
      dataModelSchemaId: params.dataModelSchemaId,
      file: params.file,
    })
    return response?.data
  })
  return mutation
}
