// import analytics from 'utils/analytics'

import { httpClient } from 'common/HTTPClient'
import { tokenManager } from 'common/authManager'

/*
 * Given user credentials, request access and refresh token to the Auth API.
 * @param username
 * @param password
 */
export async function authenticateWithCredentials(username: string, password: string) {
  try {
    const { data } = await httpClient.post('/auth/token', {
      username,
      password,
    })
    return {
      accessToken: data.access,
      refreshToken: data.refresh,
    }
  } catch (error) {
    let formattedErrorMessage = `Something went wrong: ${error}`
    if (error.response && error.response.data) {
      formattedErrorMessage = `${error.response.data.detail}`
    }
    throw Error(formattedErrorMessage)
  }
}

/**
 * Specific implementation that calls Kraken API to refresh the internal token
 * @returns Promise<string>
 */
export async function refreshToken(): Promise<string> {
  const { data } = await httpClient.post('/auth/token/refresh', {
    refresh: tokenManager.getRefreshToken(),
  })
  tokenManager.setAccessToken(data.access)
  return data.access
}

export async function authenticateLocally() {
  try {
    if (tokenManager.getToken() && tokenManager.getRefreshToken()) {
      // Refresh
      refreshToken()
    } else {
      // Request new access token
      const username = process.env.REACT_APP_API_DEVELOPMENT_USER
      const password = process.env.REACT_APP_API_DEVELOPMENT_PASSWORD
      if (!username || !password) {
        const envValues = ['REACT_APP_API_DEVELOPMENT_USER', 'REACT_APP_API_DEVELOPMENT_PASSWORD']
        throw Error(`Missing env values: ${envValues}`)
      } else {
        const response = await authenticateWithCredentials(username, password)
        tokenManager.setAccessToken(response.accessToken)
        tokenManager.setRefreshToken(response.refreshToken)
      }
    }
  } catch (error) {
    console.error(`Failure when authenticating locally: ${error}`)
    tokenManager.clearTokens()
  }
}
