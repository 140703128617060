import {
  Breadcrumbs,
  Card,
  Dialog,
  IconButton,
  Label,
  Layout,
  LoadingDots,
  Select,
  Tag,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { INTEGRATION_ONBOARDING_ROUTES } from '../IntegrationOnboardingIndex'
import { useEffect, useMemo, useState } from 'react'
import { getTemplate } from 'services/kraken-core/integration_template/integration.template.service'
import { IntegrationTemplate } from 'common/types/kraken-core/IntegrationTemplate'
import { toast } from 'react-toastify'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import { useDeleteInstallation, useSearchInstallations } from '../api'
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { InstallationList } from 'common/types/kraken-core/Installation'
import { Link } from 'react-router-dom'
import LinkToParnter from 'atoms/LinkToPartner/LinkToPartner'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import SimplePagination from 'atoms/SimplePagination/SimplePagination'
import usePagination from 'hooks/usePagination/usePagination'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { onSearchTradingPartners } from 'common/components/datasources/datasource'
import useDebouncedValue from 'hooks/useDebouncedValue'
import Icon from '../../../atoms/Icon'

export interface TemplateInstallationListPros {
  templateId: string
}

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
}

export default function TemplateInstallationsList(props: TemplateInstallationListPros) {
  const {
    page,
    pageSize,
    next,
    previous,
    reset: resetPagination,
    updatePageSizeTo,
  } = usePagination({
    page: 1,
    pageSize: 10,
  })
  const [isLoadingTemplate, setIsLoadingTemplate] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [installationToDelete, setInstallationToDelete] = useState<InstallationList | null>(null)
  const [filters, setFilters] = useState<any>({
    integration_template_id: props.templateId,
  })
  const debouncedFilters = useDebouncedValue(filters, 500)
  const [integrationTemplate, setIntegrationTemplate] = useState<IntegrationTemplate>()
  const memoziedBreadcrumbs = useMemo<Array<BreadcrumbProps>>(() => {
    let breadcrumb = [
      {
        label: 'Integration Onboarding',
        active: false,
        url: INTEGRATION_ONBOARDING_ROUTES.INDEX,
      },
      {
        label: integrationTemplate?.key || '',
        active: false,
        url: `/integration/onboarding/template/${integrationTemplate?.id}/onboard`,
      },
      {
        label: 'Installations',
        active: true,
      },
    ]
    if (!integrationTemplate) {
      breadcrumb = [
        {
          label: 'Integration Onboarding',
          active: false,
          url: INTEGRATION_ONBOARDING_ROUTES.INDEX,
        },
      ]
    }

    return breadcrumb
  }, [integrationTemplate])

  const {
    data: installations,
    isLoading: isLoadingInstallations,
    isFetching: isFetchingInstallations,
  } = useSearchInstallations({ ...debouncedFilters, page, page_size: pageSize }, [])

  const {
    mutate: deleteInstallation,
    isLoading: isDeleting,
    isSuccess: isDeleted,
    error: deletedError,
    reset: deletedReset,
  } = useDeleteInstallation()

  const handleCloseDeleteConfirmation = () => {
    setInstallationToDelete(null)
  }

  useTabTitle('Temlplate Installations')

  function TopNavigation() {
    return (
      <Layout.Group className="w-full" align="center">
        <Breadcrumbs entries={memoziedBreadcrumbs} />
        {isLoading ? <LoadingDots /> : null}
      </Layout.Group>
    )
  }

  const { updateState: updateTopNavigation } = useTopNavigationContext({
    children: TopNavigation(),
  })

  const handleFilterChanged = (filterName: string, value: any) => {
    const updatedFilters = {
      ...filters,
      [filterName]: value,
    }
    setFilters(updatedFilters)
  }

  useEffect(() => {
    if (deletedError) {
      toast.error(`Something went wrong: ${String(deletedError)}`)
    }

    if (isDeleted) {
      toast.success('Deleted with success')
    }

    setTimeout(() => {
      deletedReset()
      setInstallationToDelete(null)
    }, 0)
  }, [isDeleted, deletedError])

  useEffect(
    function loadIntegraitonTemplate() {
      async function get() {
        setIsLoadingTemplate(true)
        const response = await getTemplate(props.templateId)
        if (response.error) {
          toast.error('Something went wrong when loading integration template')
        } else {
          setIntegrationTemplate(response.response?.data)
        }
        setIsLoadingTemplate(false)
      }
      get()
    },
    [props.templateId]
  )

  useEffect(() => {
    updateTopNavigation({
      children: TopNavigation(),
    })

    if (integrationTemplate?.id)
      setFilters((c: any) => ({
        ...c,
        integration_template_id: integrationTemplate?.id,
      }))
  }, [integrationTemplate, isLoading])

  useEffect(() => {
    setIsLoading([isLoadingInstallations, isLoadingTemplate].some(v => Boolean(v) === true))
  }, [isLoadingInstallations, isLoadingTemplate])

  useEffect(() => {
    resetPagination()
  }, [filters])

  const hasInstallations =
    installations && installations.results && installations.results.length > 0

  const COLUMNS = [
    {
      Header: '#',
      style: {
        width: '5%',
      },
      Cell(row: InstallationList) {
        const idAsString = row.id?.slice(0, 8) || '-'

        return (
          <Tag variant="default">
            <Link to={`installations/${row.id}`}>{idAsString}</Link>
          </Tag>
        )
      },
    },
    {
      Header: 'Party',
      style: {
        width: '5%',
      },
      Cell(row: InstallationList) {
        return <LinkToParnter partner={row.party} />
      },
    },
    {
      Header: 'Counter Party',
      style: {
        width: '10%',
      },
      Cell(row: InstallationList) {
        return <LinkToParnter partner={row.counterparty} />
      },
    },
    {
      Header: 'Type',
      style: {
        width: '10%',
      },
      Cell(row: InstallationList) {
        return <Tag variant="accent">{row.installation_type}</Tag>
      },
    },
    {
      Header: 'Created by',
      style: {
        width: '10%',
      },
      Cell(row: InstallationList) {
        return row.user?.email || row.user?.username
      },
    },
    {
      Header: 'Created at',
      style: {
        width: '10%',
      },
      Cell(row: InstallationList) {
        return LSDate(row.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)
      },
    },
    {
      Header: 'Active',
      alignment: 'right',
      style: {
        width: '10%',
      },
      Cell(row: InstallationList) {
        return row.is_active ? (
          <Tag size="small" variant="accent">
            Active
          </Tag>
        ) : (
          <Tag size="small" variant="danger">
            Inactive
          </Tag>
        )
      },
    },
    {
      Header: 'Actions',
      alignment: 'right',
      style: {
        width: '10%',
      },
      Cell(row: InstallationList) {
        return (
          <IconButton
            onClick={e => {
              setInstallationToDelete(row)
              e.stopPropagation()
            }}
          >
            <Icon name="remove" />
          </IconButton>
        )
      },
    },
  ]

  return (
    <>
      <Dialog
        open={!!installationToDelete}
        onOverlayClick={handleCloseDeleteConfirmation}
        scale="small"
      >
        <Dialog.Header>Delete</Dialog.Header>
        <Dialog.Body>Are you sure you want to delete this Installation?</Dialog.Body>
        <Dialog.Body style={{ color: 'red', fontWeight: 'bold' }}>
          All related components will also be deleted!
        </Dialog.Body>
        <Dialog.ActionConfirm
          disabled={isDeleting}
          onConfirm={() => installationToDelete && deleteInstallation(installationToDelete.id)}
        />
        <Dialog.ActionCancel onCancel={handleCloseDeleteConfirmation} />
      </Dialog>
      <Card>
        <Layout.Stack space="s">
          <Layout.Stack>
            <Card.Body>
              <Layout.Group>
                <Layout.Stack space="s">
                  <Label htmlFor="installation_type">Type</Label>
                  <TextField
                    id="installation_type"
                    value={filters.installation_type}
                    onChange={e => handleFilterChanged('installation_type', e.target.value)}
                  />
                </Layout.Stack>
                <Layout.Stack space="s">
                  <Label htmlFor="party_partner_selector">Party</Label>
                  <Select
                    id="party_partner_selector"
                    name="party_partner_selector"
                    onChange={e => {
                      handleFilterChanged(
                        'party',
                        e.target.value ? (e.target.value as TradingPartner).id : null
                      )
                    }}
                    value={
                      {
                        value: filters.party?.id,
                        label: filters.party?.name,
                      } as Selectable
                    }
                    datasources={DATASOURCES.TRADING_PARTNER}
                  />
                </Layout.Stack>
                <Layout.Stack space="s">
                  <Label htmlFor="owner_partner_selector">Counterparty</Label>
                  <Select
                    id="counterparty_partner_selector"
                    name="counterparty_partner_selector"
                    onChange={e => {
                      handleFilterChanged(
                        'counterparty',
                        e.target.value ? (e.target.value as TradingPartner).id : null
                      )
                    }}
                    value={
                      {
                        value: filters.counterparty?.id,
                        label: filters.counterparty?.name,
                      } as Selectable
                    }
                    datasources={DATASOURCES.TRADING_PARTNER}
                  />
                </Layout.Stack>
              </Layout.Group>
            </Card.Body>

            <Card.Separator />
          </Layout.Stack>

          <LinearProgress
            className={isFetchingInstallations ? 'opacity-100' : 'opacity-0'}
            style={{ marginTop: 0 }}
          />

          {!hasInstallations && !isLoading ? (
            <Layout.Box padding="s">
              <Text variant="caption-bold">There are no installations for this template</Text>
            </Layout.Box>
          ) : null}
          {hasInstallations ? (
            <Layout.Stack space="l">
              <TableContainer>
                <Table size="small" padding="normal" data-testid="gateway-settings-list">
                  <TableHead>
                    <TableRow key={'head'}>
                      {COLUMNS.map(column => (
                        <TableCell key={column.Header} width={column.style.width}>
                          <Text variant="button-sm">{column.Header}</Text>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {installations &&
                      installations.results.map((row: InstallationList) => (
                        <TableRow hover tabIndex={-1} key={row.id}>
                          {COLUMNS.map(cell => {
                            return (
                              <TableCell key={`${cell.Header}.${row.id}`} width={cell.style.width}>
                                {cell.Cell(row)}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Layout.Box padding="s">
                <SimplePagination
                  currentPage={page}
                  nextPage={next}
                  previousPage={previous}
                  pageSize={pageSize}
                  canNextPage={Boolean(installations?.next) && !isFetchingInstallations}
                  canPreviousPage={Boolean(installations?.previous) && !isFetchingInstallations}
                  onPageSize={updatePageSizeTo}
                />
              </Layout.Box>
            </Layout.Stack>
          ) : null}
        </Layout.Stack>
      </Card>
    </>
  )
}
