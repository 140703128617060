import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { del, get, post, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { PaginatedRequest, PaginatedResponse } from 'common/types/kraken-core/Pagination'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import {
  TranslationSettings,
  TranslationSettingsDetail,
} from 'common/types/kraken-core/TranslationSettings'

export interface SearchTranslationSettingsPageRequest extends PaginatedRequest {
  name?: string
  translation_map_name?: string
  trading_partner?: TradingPartner
}

export async function searchTranslationSettings(
  data?: SearchTranslationSettingsPageRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PaginatedResponse<TranslationSettings>>> {
  const params: any = {
    name: data?.name,
    translation_map_name: data?.translation_map_name,
    trading_partner_id: data?.trading_partner?.id,
    page: data?.page || 1,
    page_size: data?.page_size || 100,
  }
  const [error, response] = await to(get('/translation_settings', { ...config, params }))
  return { error, response }
}

export async function getTranslationSettings(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationSettingsDetail>> {
  const [error, response] = await to(get(`/translation_settings/${id}`, { ...config }))
  return { error, response }
}

export async function updateTranslationSettings(
  params: TranslationSettingsDetail,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationSettingsDetail>> {
  const [error, response] = await to(
    put(`/translation_settings/${params.id}`, params, { ...config })
  )
  return { error, response }
}

export async function createTranslationSettings(
  params: TranslationSettingsDetail,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationSettingsDetail>> {
  const [error, response] = await to(post(`/translation_settings`, params, { ...config }))
  return { error, response }
}

export async function deleteTranslationSettings(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<TranslationSettings>> {
  const [error, response] = await to(del(`/translation_settings/${id}`, { ...config }))
  return { error, response }
}
