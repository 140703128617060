import { InstallationDetail } from 'common/types/kraken-core/Installation'
import JsonForm from 'molecules/JsonForm/JsonForm'
import { useContext } from 'react'
import { useTemplateOnboardDashboardContext } from '../TemplateOnboard/TemplateOnboardDashboardContext'

export interface InstallationViewParameterFromIntegrationTemplateProps {
  installation: InstallationDetail
}

export default function InstallationViewParameterFromIntegrationTemplate(
  props: InstallationViewParameterFromIntegrationTemplateProps
) {
  const onboardingContext = useTemplateOnboardDashboardContext()

  return (
    <>
      {onboardingContext && onboardingContext.template ? (
        <JsonForm
          key="json-form"
          schema={onboardingContext.template.schema}
          data={props.installation.parameters}
          uiSchema={onboardingContext.template.ui_schema || undefined}
          onChange={({ data }) => {
            // TODO: we'll have to decide how the user will apply the changes on the parameters
          }}
        />
      ) : null}
    </>
  )
}
