import { AxiosRequestConfig } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { del, get, post, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import {
  IntegrationTestCase,
  IntegrationTestCaseDetail,
  IntegrationTestCaseExecutionResult,
  IntegrationTestSuite,
} from 'common/types/kraken-core/IntegrationTest'
import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import {
  IntegrationTestCaseCreate,
  IntegrationTestCaseDelete,
  IntegrationTestCaseGetDetail,
  IntegrationTestCaseGetList,
  IntegrationTestCaseRunCommand,
  IntegrationTestCaseUpdate,
} from 'pages/IntegrationTest/IntegrationTestCase/types'
import { IntegrationTestSuiteGetListFilters } from 'pages/IntegrationTest/IntegrationTestSuite/types'

const BASE_PATH = 'integration_test_suite'

export async function getIntegrationSuiteList(
  filters: IntegrationTestSuiteGetListFilters,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PaginatedResponse<IntegrationTestSuite>>> {
  const [error, response] = await to(get(`/${BASE_PATH}`, { ...config, params: filters }))
  return { error, response }
}

export async function getIntegrationTestCaseList(
  entity: IntegrationTestCaseGetList,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PaginatedResponse<IntegrationTestCase>>> {
  const [error, response] = await to(
    get(`/${BASE_PATH}/${entity.suite_id}/tests`, { ...config, params: entity.filters })
  )
  return { error, response }
}

export async function getIntegrationTestCaseDetail(
  entity: IntegrationTestCaseGetDetail,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<IntegrationTestCaseDetail>> {
  const [error, response] = await to(
    get(`/${BASE_PATH}/${entity.suite_id}/tests/${entity.id}`, { ...config })
  )
  return { error, response }
}

export async function createIntegtationTestCase(
  entity: IntegrationTestCaseCreate,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<IntegrationTestCaseDetail>> {
  const [error, response] = await to(
    post(`/${BASE_PATH}/${entity.suite_id}/tests`, entity.object, { ...config })
  )
  return { error, response }
}

export async function updateIntegrationTestCase(
  entity: IntegrationTestCaseUpdate,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<IntegrationTestCaseDetail>> {
  const [error, response] = await to(
    put(`/${BASE_PATH}/${entity.suite_id}/tests/${entity.id}`, entity.object, { ...config })
  )
  return { error, response }
}

export async function deleteIntegrationTestCase(
  entity: IntegrationTestCaseDelete,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<IntegrationTestCaseDetail>> {
  const [error, response] = await to(
    del(`/${BASE_PATH}/${entity.suite_id}/tests/${entity.id}`, { ...config })
  )
  return { error, response }
}

export async function runIntegrationTestCase(
  entity: IntegrationTestCaseRunCommand,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<IntegrationTestCaseExecutionResult>> {
  const [error, response] = await to(
    post(`/${BASE_PATH}/${entity.suite_id}/tests/${entity.id}/run`, { ...config })
  )
  return { error, response }
}
