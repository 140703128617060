import Loading from 'atoms/Loading'
import axios from 'axios'
import { toast } from 'atoms/Toast'
import { MessageRead } from 'common/types/kraken-core/Message'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { updateMessageBody } from 'services/kraken-core/messages/messages.service'
import { useMessagesDashboardViewContext } from 'pages/Messages/MessagesDashboardViewContext'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import { Layout } from '@loadsmart/loadsmart-ui'

export interface BodyContentReaderProps {
  message: MessageRead
}

interface BodyContentReaderState {
  content: string
  contentType: string
  url: string
  isLoading: boolean
}

function BodyContentReader({ message }: BodyContentReaderProps) {
  const context = useMessagesDashboardViewContext()
  const [state, setState] = useState<BodyContentReaderState>({
    content: '',
    contentType: message.content_type,
    url: message.body,
    isLoading: false,
  })

  useEffect(() => {
    setState(s => ({
      ...s,
      isLoading: true,
    }))

    axios({
      method: 'get',
      url: message.body,
      responseType: 'blob',
    })
      .then(response => {
        const blob = response.data
        if (!blob) return Promise.resolve('')

        return blob.text()
      })
      .then(stringValue => {
        setState(s => ({
          ...s,
          content: stringValue,
        }))
      })
      .catch(error => {
        setState(s => ({
          ...s,
          content: `There was failure while loading the content: ${error} `,
          contentType: 'txt',
        }))
      })
      .finally(() => {
        setState(s => ({
          ...s,
          isLoading: false,
        }))
      })
  }, [message.body, message.content_type])

  const handleChange = (content = '') => {
    setState(s => ({
      ...s,
      content,
    }))
  }

  const handleSave = async () => {
    analytics.event({
      category: AnalyticsEvent.MessageDetailUpdateTabContent,
      action: AnalyticsEventTrigger.change,
      params: {
        tabName: 'body',
      },
    })

    const base64Content = Buffer.from(state.content).toString('base64')

    const [error] = await updateMessageBody({
      id: message.id,
      content: base64Content,
      contentType: state.contentType,
    })

    if (error) return toast.error(`Something went wrong: ${error}`)

    toast.success('Updated with success')
    return context.readMessageDetails(message.id)
  }

  const FallbackComponent = () => <>Error on rendering</>

  return (
    <Layout.Box>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        {state.isLoading && (
          <div className="flex justify-center p-4">
            <Loading />
          </div>
        )}

        {!state.isLoading && (
          <FileViewer
            options={{
              readonly: false,
              heigth: 500,
              theme: FileViewTheme.DEFAULT,
            }}
            content={state.content}
            contentType={state.contentType}
            url={state.url}
            onChange={handleChange}
            onSave={handleSave}
          />
        )}
      </ErrorBoundary>
    </Layout.Box>
  )
}

export default BodyContentReader
