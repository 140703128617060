import { Button, TextField } from '@loadsmart/loadsmart-ui'
import Icon from 'atoms/Icon'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'

export interface SimplePaginationProps {
  currentPage: number
  canNextPage?: boolean
  canPreviousPage?: boolean
  previousPage: () => void
  nextPage: () => void
  onPageSize?: (pageSize: number) => void
  onPage?: (pageNumber: number) => void
  pageSize?: number
  pageSizeLimit?: number
  count?: number
  disabled?: boolean
  className?: string
}

const TEXT_FIELD_STYLE = { width: 'calc(3ch + 24px)' }

function SimplePagination({
  count,
  currentPage,
  canNextPage = true,
  canPreviousPage = true,
  nextPage,
  previousPage,
  onPageSize,
  onPage,
  disabled = false,
  className,
  pageSize,
  pageSizeLimit = 100,
}: SimplePaginationProps) {
  const [localPageSize, setLocalPageSize] = useState<number>(pageSize || 10)
  const [localPageNumber, setLocalPageNumber] = useState<number>(currentPage)

  useEffect(() => {
    setLocalPageNumber(currentPage)
  }, [currentPage])

  const totalPages = useMemo<number>(() => {
    if (count && pageSize) return Math.ceil(count / pageSize)
    return 1
  }, [count, pageSize])

  return (
    <div
      data-testid="simple-pagination"
      className={clsx('flex flex-row items-center text-sm justify-between', className)}
    >
      <div className="flex mr-2">
        {onPageSize && pageSize && (
          <div>
            <span>Page Size</span>
            <TextField
              type="number"
              style={TEXT_FIELD_STYLE}
              className="mx-2"
              value={localPageSize}
              data-testid="page-size-input"
              onChange={value => {
                const pageSizeValue = Number(value.target.value)
                setLocalPageSize(pageSizeValue)
              }}
              onBlur={() => {
                if (localPageSize <= 0 || localPageSize > pageSizeLimit) {
                  setLocalPageSize(pageSize)
                } else {
                  onPageSize(localPageSize)
                }
              }}
            />
          </div>
        )}
        <Button
          variant="secondary"
          onClick={() => {
            previousPage()
            setLocalPageNumber(localPageNumber - 1)
          }}
          disabled={!canPreviousPage || disabled}
          className="justify-center hidden w-9 lg:flex lg:mr-6"
          data-testid="previous-page"
        >
          <Icon
            name="chevron-right"
            aria-label="Previous page"
            className="transform rotate-180"
            size={16}
          />
        </Button>
        <div>
          <span>Page</span>
          <TextField
            type="number"
            disabled={disabled}
            style={TEXT_FIELD_STYLE}
            className="mx-2"
            value={localPageNumber}
            data-testid="page-input"
            onChange={event => {
              const pageNumberValue = Number(event.target.value)
              setLocalPageNumber(pageNumberValue)
            }}
            onBlur={() => {
              if (onPage && localPageNumber > 0 && localPageNumber <= totalPages) {
                onPage(localPageNumber)
              } else setLocalPageNumber(currentPage)
            }}
          />
          {onPage && count && pageSize && (
            <>
              <span>Of</span>
              <TextField readOnly value={totalPages} className="mx-2" style={TEXT_FIELD_STYLE} />
            </>
          )}
        </div>
        <Button
          variant="secondary"
          onClick={() => {
            nextPage()
            setLocalPageNumber(localPageNumber + 1)
          }}
          disabled={!canNextPage || disabled}
          className="justify-center hidden w-9 lg:flex lg:ml-6"
          data-testid="next-page"
        >
          <Icon name="chevron-right" aria-label="Next page" size={16} />
        </Button>
      </div>
    </div>
  )
}

export default SimplePagination
