import { IntegrationTestSuite } from 'common/types/kraken-core/IntegrationTest'
import { getIntegrationSuiteList } from 'services/kraken-core/integration_test/integration_test.service'

export const onSearchIntegrationTestSuite = async ({
  query,
}: {
  query: string
}): Promise<IntegrationTestSuite[]> => {
  const result = await getIntegrationSuiteList({
    description: query,
  })
  return result?.response?.data.results.filter((p: IntegrationTestSuite) =>
    RegExp(query, 'i').test(p.description)
  )
}
