import {
  Button,
  Card,
  EmptyState,
  Label,
  Layout,
  LoadingDots,
  Switch,
  Tag,
  Text,
  TextField,
  ToggleGroup,
  Tooltip,
} from '@loadsmart/loadsmart-ui'
import { Accordion, AccordionDetails, AccordionSummary, Drawer } from '@mui/material'
import Icon from 'atoms/Icon'
import isJson from 'common/helpers/isJSON'
import {
  TranslationTestCaseDetail,
  TranslationTestCaseResult,
} from 'common/types/kraken-core/TranslationMap'
import { isArray } from 'lodash'
import isEmpty from 'lodash.isempty'
import FileViewer from 'molecules/FileViewer/FileViewer'
import JsonForm from 'molecules/JsonForm/JsonForm'
import {
  useCreateTranslationTestCase,
  useGetTranslationTestCase,
  useUpdateTranslationTestCase,
} from 'pages/TranslationMaps/api'
import { TranslationMapDetailsContext } from 'pages/TranslationMaps/containers/TranslationMapsDetails'
import { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import {
  TestCaseInputConversionCodeSchema,
  TestCaseInputLookupTableEntriesSchema,
  TestCaseInputMessageReceiptsSchema,
  TestCaseInputMessageReceiptsUiSchema,
} from '../schemas'
import TradingPartnerSelector from 'common/components/selectors/TradingPartnerSelector'

export interface TestCaseDetailsProps {
  open: boolean
  onClose: () => void
  onCreated?: (created: TranslationTestCaseDetail) => void
  onUpdated?: (updated: TranslationTestCaseDetail) => void
  testCaseId?: string
}

const InfoIcon = ({ message }: { message: string }) => {
  return (
    <Tooltip message={message}>
      <Icon name="info" size={20} />
    </Tooltip>
  )
}

const HasDefinitionTag = () => {
  return <Tag variant="default">Has definition</Tag>
}

export default function TestCaseDetail(props: TestCaseDetailsProps) {
  const translationMapDetailsContext = useContext(TranslationMapDetailsContext)
  const translationMap = translationMapDetailsContext.translationMap
  const testCaseDetailRef = useRef<TranslationTestCaseDetail>()
  const [testCaseDetailState, setTestCaseDetailState] = useState<TranslationTestCaseDetail>()
  const [conversionCodesRawView, setConversionCodeRawView] = useState<boolean>(false)
  const [lookupTableEntriesRawView, setLookupTableEntriesRawView] = useState<boolean>(false)
  const [messageReceiptsRawView, setMessageReceiptsRawView] = useState<boolean>(false)

  const { data: loadedTestCase, isLoading: isLoadingTestCase } = useGetTranslationTestCase(
    {
      translationMapId: translationMapDetailsContext.translationMap?.id as any,
      translationTestCaseId: props.testCaseId as any,
    },
    {
      enabled:
        !isEmpty(translationMapDetailsContext.translationMap?.id) && !isEmpty(props.testCaseId),
    }
  )

  const {
    mutate: updateTestCase,
    isLoading: isUpdatingTestCase,
    isSuccess: isUpdateTestCaseSuccess,
    error: errorUpdateTestCase,
    data: updated,
  } = useUpdateTranslationTestCase()

  const {
    mutate: createTestCase,
    isLoading: isCreatingTestCase,
    isSuccess: isCreateTestCaseSuccess,
    error: errorCreateTestCase,
    data: created,
  } = useCreateTranslationTestCase()

  const handleOnSave = () => {
    if (!testCaseDetailRef.current) {
      toast.error("There's no instance to be saved")
      return
    }

    if (!translationMap?.id) {
      toast.error("There's no translation map available in the context")
      return
    }

    try {
      const instance = testCaseDetailRef.current
      const validations = [
        [isEmpty(instance.description), 'Description is required'],
        [isEmpty(instance.input_definition.payload), 'Input payload is required'],
        [isEmpty(instance.expectation_definition.payload), 'Expectation payload is required'],
      ]

      const validatedFields = validations.filter(e => e[0]).map(e => e[1])

      if (validatedFields.length > 0) {
        validatedFields.forEach(f => {
          toast.error(f)
        })
        return
      }

      const isUpdating = translationMap.id && !isEmpty(props.testCaseId)
      const isCreating = translationMap.id && isEmpty(props.testCaseId)

      if (isUpdating) {
        updateTestCase({
          translationMapId: translationMap.id,
          translationTestCase: instance,
        })
      } else if (isCreating) {
        createTestCase({
          translationMapId: translationMap.id,
          translationTestCase: instance,
        })
      }

      props.onClose()
    } catch (error) {
      toast.error(error)
    }
  }

  useEffect(
    function onPropsChange() {
      const isNew = !props.testCaseId || isEmpty(props.testCaseId)
      if (isNew) {
        testCaseDetailRef.current = {
          description: '',
          input_definition: {},
          expectation_definition: {
            headers: {},
            result: TranslationTestCaseResult.SUCCESS,
          },
        }
        setTestCaseDetailState({ ...testCaseDetailRef.current })
      }
    },
    [props.testCaseId, props.open]
  )

  useEffect(
    function onUpdate() {
      if (errorUpdateTestCase) {
        toast.error(String(errorUpdateTestCase))
        return
      }

      if (isUpdateTestCaseSuccess) {
        toast.success('Successfully updated')
        props.onUpdated ? props.onUpdated(updated) : null
      }
    },
    [errorUpdateTestCase, isUpdatingTestCase, isUpdateTestCaseSuccess]
  )

  useEffect(
    function onCreate() {
      if (errorCreateTestCase) {
        toast.error(String(errorCreateTestCase))
        return
      }

      if (isCreateTestCaseSuccess) {
        toast.success('Successfully updated')
        props.onCreated ? props.onCreated(created) : null
      }
    },
    [isCreateTestCaseSuccess, errorCreateTestCase]
  )

  useEffect(
    function onLoadTestCase() {
      testCaseDetailRef.current = loadedTestCase
      setTestCaseDetailState(loadedTestCase)
    },
    [loadedTestCase]
  )

  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        className: 'drawer',
      }}
      anchor="right"
    >
      <Layout.Stack className="p-4 h-full">
        <Layout.Stack className="w-full">
          <Label>Description</Label>
          <TextField
            name="description"
            className="w-full"
            required
            value={testCaseDetailState?.description}
            onChange={e => {
              if (testCaseDetailRef.current) {
                testCaseDetailRef.current.description = e.target.value

                setTestCaseDetailState({
                  ...testCaseDetailRef.current,
                  description: e.target.value,
                })
              }
            }}
            trailing={
              isEmpty(testCaseDetailRef.current?.description) ? <Icon name="warning" /> : ''
            }
          />
        </Layout.Stack>

        {isLoadingTestCase ? <LoadingDots /> : null}

        {!isLoadingTestCase ? (
          <Layout.Stack className="w-full">
            <Card>
              <Card.Title>Inputs</Card.Title>
              <Card.Body>
                <Layout.Stack space="none">
                  <Accordion>
                    <AccordionSummary expandIcon={<Icon name="arrow-expand" />}>
                      <Layout.Group align="center">
                        <Label required>Payload</Label>
                        {translationMap?.input_content_type && (
                          <Tag variant="default">{translationMap.input_content_type}</Tag>
                        )}
                      </Layout.Group>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: 0 }}>
                      <FileViewer
                        content={String(atob(testCaseDetailState?.input_definition.payload || ''))}
                        contentType={translationMap?.input_content_type || ''}
                        options={{
                          showValidations: true,
                        }}
                        onChange={value => {
                          if (testCaseDetailRef.current) {
                            testCaseDetailRef.current.input_definition.payload = btoa(value)
                          }
                        }}
                        onSave={() => {}}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<Icon name="arrow-expand" />}>
                      <Layout.Group align="center">
                        <Text>Message Properties</Text>
                        {!isEmpty(testCaseDetailState?.input_definition?.message) ? (
                          <HasDefinitionTag />
                        ) : null}
                      </Layout.Group>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: 0 }}>
                      <Layout.Stack space="s">
                        <Accordion>
                          <AccordionSummary expandIcon={<Icon name="arrow-expand" />}>
                            <Layout.Group align="center">
                              <Text>Headers</Text>
                              {!isEmpty(testCaseDetailState?.input_definition?.message?.headers) ? (
                                <HasDefinitionTag />
                              ) : null}
                            </Layout.Group>
                          </AccordionSummary>
                          <AccordionDetails style={{ paddingTop: 0 }}>
                            <FileViewer
                              content={JSON.stringify(
                                testCaseDetailState?.input_definition?.message?.headers,
                                null,
                                2
                              )}
                              contentType="application/json"
                              options={{}}
                              onChange={value => {
                                if (
                                  testCaseDetailRef.current &&
                                  testCaseDetailRef.current.input_definition &&
                                  isJson(value)
                                ) {
                                  if (!testCaseDetailRef.current.input_definition.message) {
                                    testCaseDetailRef.current.input_definition.message = {}
                                  }
                                  testCaseDetailRef.current.input_definition.message.headers = JSON.parse(
                                    value
                                  )
                                }
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Label>Source: </Label>
                        <TradingPartnerSelector
                          selected={{
                            id: testCaseDetailState?.input_definition?.message?.source,
                            label: testCaseDetailState?.input_definition?.message?.source,
                          }}
                          onSelect={selected => {
                            if (
                              testCaseDetailRef.current &&
                              testCaseDetailRef.current.input_definition
                            ) {
                              if (!testCaseDetailRef.current.input_definition.message) {
                                testCaseDetailRef.current.input_definition.message = {}
                              }
                              testCaseDetailRef.current.input_definition.message.source = selected
                                ? selected.id
                                : undefined
                            }
                          }}
                        />
                        <Label>Target: </Label>
                        <TradingPartnerSelector
                          selected={{
                            id: testCaseDetailState?.input_definition?.message?.target,
                            label: testCaseDetailState?.input_definition?.message?.target,
                          }}
                          onSelect={selected => {
                            if (
                              testCaseDetailRef.current &&
                              testCaseDetailRef.current.input_definition
                            ) {
                              if (!testCaseDetailRef.current.input_definition.message) {
                                testCaseDetailRef.current.input_definition.message = {}
                              }
                              testCaseDetailRef.current.input_definition.message.target = selected
                                ? selected.id
                                : undefined
                            }
                          }}
                        />
                        <Label>Message UUID: </Label>
                        <TextField
                          name="message_uuid"
                          className="w-full"
                          required
                          value={testCaseDetailState?.input_definition?.message?.message_uuid}
                          onChange={e => {
                            if (
                              testCaseDetailRef.current &&
                              testCaseDetailRef.current.input_definition
                            ) {
                              if (!testCaseDetailRef.current.input_definition.message) {
                                testCaseDetailRef.current.input_definition.message = {}
                              }
                              testCaseDetailRef.current.input_definition.message.message_uuid =
                                e.target.value
                            }
                          }}
                          trailing={
                            isEmpty(
                              testCaseDetailRef.current?.input_definition?.message?.message_uuid
                            ) ? (
                              <Icon name="warning" />
                            ) : (
                              ''
                            )
                          }
                        />
                      </Layout.Stack>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<Icon name="arrow-expand" />}>
                      <Layout.Group align="center">
                        <Text>Settings</Text>
                        <InfoIcon message="Settings values that will be available within the implementation" />
                        {!isEmpty(testCaseDetailState?.input_definition.settings) ? (
                          <HasDefinitionTag />
                        ) : null}
                      </Layout.Group>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Layout.Stack className="w-full">
                        {translationMap?.settings_data_schema && (
                          <JsonForm
                            data={testCaseDetailState?.input_definition.settings || {}}
                            schema={translationMap?.settings_data_schema}
                            uiSchema={translationMap?.settings_ui_schema}
                            onChange={value => {
                              if (testCaseDetailRef.current) {
                                testCaseDetailRef.current.input_definition.settings = value.data
                              }
                            }}
                          />
                        )}

                        {!translationMap?.settings_data_schema && (
                          <EmptyState
                            action={{
                              label: 'Configure',
                              onClick: () => {
                                window.open(`/translation-maps/${translationMap?.id}`, '_blank')
                              },
                            }}
                            icon={<Icon name="info" size={50} />}
                            title="Translation Map doesn't have settings available"
                            variant="card"
                          />
                        )}
                      </Layout.Stack>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<Icon name="arrow-expand" />}>
                      <Layout.Group align="center">
                        <Text>Conversion Codes</Text>
                        {!isEmpty(testCaseDetailState?.input_definition.conversion_codes) ? (
                          <HasDefinitionTag />
                        ) : null}
                      </Layout.Group>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: 0 }}>
                      <Layout.Stack className="w-full">
                        <Layout.Group align="center">
                          <Text variant="caption">Raw view</Text>
                          <Switch
                            active={conversionCodesRawView}
                            onToggle={() => setConversionCodeRawView(!conversionCodesRawView)}
                          />
                        </Layout.Group>

                        {!conversionCodesRawView ? (
                          <JsonForm
                            data={
                              testCaseDetailRef.current?.input_definition.conversion_codes || []
                            }
                            onChange={value => {
                              if (testCaseDetailRef.current && isArray(value.data)) {
                                testCaseDetailRef.current.input_definition.conversion_codes =
                                  value.data
                              }
                            }}
                            schema={TestCaseInputConversionCodeSchema}
                            uiSchema={null}
                          />
                        ) : null}

                        {conversionCodesRawView ? (
                          <FileViewer
                            content={
                              JSON.stringify(
                                testCaseDetailState?.input_definition.conversion_codes
                              ) || ''
                            }
                            contentType="application/json"
                            options={{}}
                            onChange={value => {
                              if (testCaseDetailRef.current && isJson(value)) {
                                testCaseDetailRef.current.input_definition.conversion_codes = JSON.parse(
                                  value
                                )
                              }
                            }}
                          />
                        ) : null}
                      </Layout.Stack>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Layout.Group align="center">
                        <Text>Lookup Table Entries</Text>
                        <InfoIcon message="Lookup Table Entries will be injected to be used within the implementation" />
                        {!isEmpty(testCaseDetailState?.input_definition.lookup_table_entries) ? (
                          <HasDefinitionTag />
                        ) : null}
                      </Layout.Group>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Layout.Group align="center">
                        <Text variant="caption">Raw view</Text>
                        <Switch
                          active={lookupTableEntriesRawView}
                          onToggle={() => setLookupTableEntriesRawView(!lookupTableEntriesRawView)}
                        />
                      </Layout.Group>

                      {!lookupTableEntriesRawView ? (
                        <JsonForm
                          data={
                            testCaseDetailRef.current?.input_definition.lookup_table_entries || []
                          }
                          onChange={value => {
                            if (testCaseDetailRef.current && isArray(value.data)) {
                              testCaseDetailRef.current.input_definition.lookup_table_entries =
                                value.data
                            }
                          }}
                          schema={TestCaseInputLookupTableEntriesSchema}
                          uiSchema={null}
                        />
                      ) : null}

                      {lookupTableEntriesRawView ? (
                        <FileViewer
                          content={
                            JSON.stringify(
                              testCaseDetailState?.input_definition.lookup_table_entries
                            ) || ''
                          }
                          contentType="application/json"
                          options={{}}
                          onChange={value => {
                            if (testCaseDetailRef.current && isJson(value)) {
                              testCaseDetailRef.current.input_definition.lookup_table_entries = JSON.parse(
                                value
                              )
                            }
                          }}
                        />
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Layout.Group align="center">
                        <Text>Message Receipts</Text>
                        <InfoIcon message="Message Receipts will be injected to be used within the implementation" />
                        {!isEmpty(testCaseDetailState?.input_definition.message_receipts) ? (
                          <HasDefinitionTag />
                        ) : null}
                      </Layout.Group>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Layout.Group align="center">
                        <Text variant="caption">Raw view</Text>
                        <Switch
                          active={messageReceiptsRawView}
                          onToggle={() => setMessageReceiptsRawView(!messageReceiptsRawView)}
                        />
                      </Layout.Group>

                      {!messageReceiptsRawView ? (
                        <JsonForm
                          data={testCaseDetailRef.current?.input_definition.message_receipts || []}
                          onChange={value => {
                            if (testCaseDetailRef.current && isArray(value.data)) {
                              testCaseDetailRef.current.input_definition.message_receipts =
                                value.data
                            }
                          }}
                          schema={TestCaseInputMessageReceiptsSchema}
                          uiSchema={TestCaseInputMessageReceiptsUiSchema}
                        />
                      ) : null}

                      {messageReceiptsRawView ? (
                        <FileViewer
                          content={
                            JSON.stringify(
                              testCaseDetailState?.input_definition.message_receipts
                            ) || ''
                          }
                          contentType="application/json"
                          options={{}}
                          onChange={value => {
                            if (testCaseDetailRef.current && isJson(value)) {
                              testCaseDetailRef.current.input_definition.message_receipts = JSON.parse(
                                value
                              )
                            }
                          }}
                        />
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                </Layout.Stack>
              </Card.Body>
            </Card>

            <Card>
              <Card.Title>Expectations</Card.Title>
              <Card.Body>
                <Layout.Stack space="none">
                  <Layout.Group align="center">
                    <Label required>Result must be:</Label>
                    <ToggleGroup
                      value={String(testCaseDetailState?.expectation_definition?.result || '')}
                      options={[
                        {
                          value: TranslationTestCaseResult.FAILURE,
                          label: 'FAILURE',
                          leading: <Icon name="warning" />,
                        },
                        {
                          value: TranslationTestCaseResult.SUCCESS,
                          label: 'SUCCESS',
                          leading: <Icon name="check" />,
                        },
                      ]}
                      onChange={value => {
                        if (
                          testCaseDetailRef.current &&
                          testCaseDetailRef.current.expectation_definition
                        ) {
                          testCaseDetailRef.current.expectation_definition.result = value.target
                            .value as any
                        }
                      }}
                    />
                  </Layout.Group>

                  <Layout.Stack space="none" className="pt-4">
                    <Accordion>
                      <AccordionSummary expandIcon={<Icon name="arrow-expand" />}>
                        <Layout.Group align="center">
                          <Label required>Payload</Label>
                          {translationMap?.output_content_type && (
                            <Tag variant="default">{translationMap?.output_content_type}</Tag>
                          )}
                        </Layout.Group>
                      </AccordionSummary>
                      <AccordionDetails style={{ paddingTop: 0 }}>
                        <FileViewer
                          content={String(
                            atob(testCaseDetailState?.expectation_definition?.payload || '')
                          )}
                          contentType={translationMap?.output_content_type || ''}
                          options={{
                            showValidations: true,
                          }}
                          onChange={value => {
                            if (
                              testCaseDetailRef.current &&
                              testCaseDetailRef.current.expectation_definition
                            ) {
                              testCaseDetailRef.current.expectation_definition.payload = btoa(value)
                            }
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Layout.Stack>
                </Layout.Stack>
              </Card.Body>
            </Card>
          </Layout.Stack>
        ) : null}

        <Layout.Stack className="h-full stick" align="flex-end" justify="flex-end">
          <Layout.Group justify="space-between" align="center" className="w-full">
            <Button onClick={props.onClose}>Cancel</Button>
            <Button variant="primary" onClick={handleOnSave}>
              Save
            </Button>
          </Layout.Group>
        </Layout.Stack>
      </Layout.Stack>
    </Drawer>
  )
}
