import {
  Banner,
  EmptyState,
  Label,
  Layout,
  Select,
  Text,
  TextField,
  Tooltip,
  TooltipPosition,
} from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import Divider from 'atoms/Divider'
import Icon from 'atoms/Icon'
import isJson from 'common/helpers/isJSON'
import {
  onSearchRuntimeTranslationMaps,
  onSearchTradingPartners,
  onSearchTranslationSettings,
} from 'common/helpers/kraken-core/hooks'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { RuntimeTranslationMap, TranslationMap } from 'common/types/kraken-core/TranslationMap'
import {
  TranslationSettings,
  TranslationSettingsDetail,
} from 'common/types/kraken-core/TranslationSettings'
import isEmpty from 'lodash.isempty'
import FileViewer from 'molecules/FileViewer/FileViewer'
import JsonForm from 'molecules/JsonForm/JsonForm'
import { useGetTradingPartner } from 'pages/TradingPartner/api'
import { useGetRuntimeTranslationMap } from 'pages/TranslationMaps/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetTranslationSettings } from '../api'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
  TRANSLATION_MAP: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tm: RuntimeTranslationMap) => tm.id || '',
        getLabel: (tm: RuntimeTranslationMap) => tm.name || '',
      },
      fetch: onSearchRuntimeTranslationMaps,
    }),
  ],
  TRANSLATION_SETTINGS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (ts: TranslationSettings) => ts.id || '',
        getLabel: (ts: TranslationSettings) => ts.name || '',
      },
      fetch: onSearchTranslationSettings,
    }),
  ],
}
export interface TranslationSettingsFormProps {
  translationSettings: TranslationSettingsDetail
  tradingPartnerFromContext?: TradingPartner
  onUpdate: (
    translationSettings: TranslationSettingsDetail,
    validationState?: FormValidation
  ) => void
}

export interface FormValidation {
  useWhen?: string
  settings?: string
  name?: string
}

const Form = ({
  translationSettings,
  onUpdate,
  tradingPartnerFromContext,
}: TranslationSettingsFormProps) => {
  const [state, setState] = useState<TranslationSettingsDetail>({
    ...translationSettings,
    trading_partner: translationSettings.trading_partner || tradingPartnerFromContext?.id,
  })
  const [validation, setValidation] = useState<FormValidation>({})

  const { data: referenceSettings } = useGetTranslationSettings({
    id: state?.reference_settings || '',
  })
  const { data: translationMap } = useGetRuntimeTranslationMap(
    state?.translation_map || referenceSettings?.translation_map
  )
  const { data: tradingPartner } = useGetTradingPartner(
    state?.trading_partner || tradingPartnerFromContext?.id
  )

  const handleChange = useCallback((key: any, value: any) => {
    setState((prev: any) => ({
      ...prev,
      [key]: value,
    }))
  }, [])

  const translationMapValue = {
    value: translationMap?.id,
    label: translationMap?.name,
  }

  const tradingPartnerValue = {
    value: tradingPartner?.id,
    label: tradingPartner?.name,
  }

  const referenceSettingsValue = {
    value: referenceSettings?.id,
    label: referenceSettings?.name,
  }

  const isTranslationMapSettingsAvailable = translationMap?.settings_data_schema

  const DynamicForm = useMemo(() => {
    return (
      <>
        {Boolean(translationMap?.settings_data_schema) && (
          <JsonForm
            data={translationSettings.settings || {}}
            schema={translationMap?.settings_data_schema}
            uiSchema={translationMap?.settings_ui_schema}
            onChange={value => {
              handleChange('settings', value.data)
            }}
          />
        )}
      </>
    )
  }, [translationMap, translationSettings.settings])

  useEffect(() => {
    if (onUpdate && state) onUpdate(state, validation)
  }, [state, validation])

  useEffect(() => {
    setState(current => ({
      ...translationSettings,
      name: !isEmpty(translationSettings.name) ? translationSettings.name : '',
      trading_partner: tradingPartnerFromContext?.id
        ? tradingPartnerFromContext?.id
        : translationSettings?.trading_partner,
    }))

    setValidation(current => {
      let validation = { ...current }

      if (translationSettings.use_when) delete validation.useWhen
      else {
        validation = { ...current, useWhen: 'Required' }
      }
      return validation
    })
  }, [translationSettings, tradingPartnerFromContext])

  return (
    <Layout.Stack space="m">
      <Layout.Switcher className="w-full">
        <Layout.Stack space="s">
          <Label required>Name</Label>
          <TextField value={state.name} onChange={e => handleChange('name', e.target.value)} />
        </Layout.Stack>
      </Layout.Switcher>
      <Layout.Switcher className="w-full">
        <Layout.Stack space="s">
          <Layout.Group align="center" space="s">
            <Label>Translation Map</Label>
            <Tooltip
              message="Existing runtime Translation Maps have already been pushed. Make sure your map is upt-to-date and currently pushed"
              position={TooltipPosition.Bottom}
            >
              <Icon name="info" size={20} />
            </Tooltip>
          </Layout.Group>
          <Select
            data-testid="translation_map_autocomplete"
            key="translation_map"
            name="traslation_map"
            onChange={e =>
              handleChange('translation_map', (e.target.value as TranslationMap)?.id || null)
            }
            value={translationMapValue.value ? (translationMapValue as Selectable) : undefined}
            datasources={DATASOURCES.TRANSLATION_MAP}
            disabled={Boolean(state.reference_settings)}
          />
        </Layout.Stack>
        <Layout.Stack space="s">
          <Layout.Group align="center" space="s">
            <Label>Reference Settings</Label>
            <Tooltip
              message="Reference settings this will inherite from"
              position={TooltipPosition.Bottom}
            >
              <Icon name="info" size={20} />
            </Tooltip>
          </Layout.Group>
          <Select
            data-testid="reference_settings_autocomplete"
            name="reference_settings"
            onChange={e =>
              handleChange(
                'reference_settings',
                (e.target.value as TranslationSettingsDetail)?.id || null
              )
            }
            value={
              (referenceSettingsValue.value
                ? (referenceSettingsValue as Selectable)
                : undefined) as any
            }
            datasources={DATASOURCES.TRANSLATION_SETTINGS}
            disabled={Boolean(state.translation_map)}
          />
        </Layout.Stack>
      </Layout.Switcher>
      <Layout.Switcher>
        <Layout.Stack space="s">
          <Text>Partner</Text>
          <Select
            data-testid="trading_partner_autocomplete"
            name="trading_partner"
            onChange={e =>
              handleChange('trading_partner', (e.target.value as TradingPartner)?.id || null)
            }
            value={
              (tradingPartnerValue.value ? (tradingPartnerValue as Selectable) : undefined) as any
            }
            datasources={DATASOURCES.TRADING_PARTNER}
            disabled={Boolean(tradingPartnerFromContext)}
          />
        </Layout.Stack>
      </Layout.Switcher>
      <div>
        <Accordion>
          <AccordionSummary>
            <Layout.Group align="center">
              <Layout.Group space="s" align="center">
                <Text variant="heading-sm-bold">Use When</Text>
                <Tooltip message="This is where we define when this settings must be applied. Make sure the message context matches with these values">
                  <Icon name="info" size={20} />
                </Tooltip>
              </Layout.Group>

              {!isEmpty(validation?.useWhen) && (
                <Text variant="caption-bold" color="color-danger">
                  {validation?.useWhen}
                </Text>
              )}
            </Layout.Group>
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: 0,
            }}
          >
            <FileViewer
              contentType="application/json"
              content={JSON.stringify(state?.use_when, null, 2)}
              options={{}}
              showOptions={false}
              onChange={value => {
                if (!isJson(value)) {
                  setValidation((v: any) => {
                    return {
                      ...v,
                      useWhen: 'Invalid JSON definition',
                    }
                  })
                  return
                }
                handleChange('use_when', JSON.parse(value))
                setValidation((v: any) => {
                  delete v.useWhen
                  return {
                    ...v,
                  }
                })
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            <Layout.Group space="s" align="center">
              <Text variant="heading-sm-bold">Settings</Text>
              <Tooltip message="The values which will be used to be injected in the translation map during the translation process">
                <Icon name="info" size={20} />
              </Tooltip>
            </Layout.Group>
          </AccordionSummary>
          <AccordionDetails>
            <Layout.Stack space="s" data-testid="settings-form">
              <Layout.Stack space="s">
                {state.reference_settings && (
                  <Banner
                    variant="warning"
                    title={
                      `By default all settings values will be inherited from the Reference Settings. ` +
                      `Nonetheless, it's possible to override this behavior redefining them here`
                    }
                  />
                )}
              </Layout.Stack>
              <Layout.Stack>
                {isTranslationMapSettingsAvailable && DynamicForm}

                {!isTranslationMapSettingsAvailable && Boolean(translationMap) && (
                  <EmptyState
                    icon={<Icon name="info" size={50} />}
                    title="Selected Translation Map doesn't have settings available"
                    variant="card"
                  />
                )}
              </Layout.Stack>
            </Layout.Stack>
          </AccordionDetails>
        </Accordion>
      </div>
    </Layout.Stack>
  )
}

export default Form
