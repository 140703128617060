import { PaginatedRequest } from 'common/types/kraken-core/Pagination'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'

export enum QUERIES {
  SEARCH_TRANSLATION_SETTINGS = 'translation_settings/search',
  GET_TRANSLATION_SETTINGS = 'translation_settings/get',
}

export interface useSearchTranslationSettingsParams extends PaginatedRequest {
  name?: string
  translation_map_name?: string
  trading_partner?: TradingPartner
}

export interface useGetTranslationSettingsParams {
  id: string
}
