import { Text } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

const StyledLink = styled(Text)`
  line-height: inherit;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export interface ConditionalCallableLinkProps {
  label: string
  onClick?: () => void
}

const ConditionalCallableLink = (props: ConditionalCallableLinkProps) => {
  return props.onClick ? (
    <StyledLink variant="link" onClick={props.onClick}>
      {props.label}
    </StyledLink>
  ) : (
    <Text onClick={props.onClick}>{props.label}</Text>
  )
}

export default ConditionalCallableLink
