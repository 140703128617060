// General imports, like internal and external libraries
import Loading from 'atoms/Loading'
import { Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'

import './index.css'

// Containers (Every page of our module)
import ExpectedReplyMessageList from './containers/ExpectedReplyMessageList'
import ExpectedReplyMessageDetail from './containers/ExpectedReplyMessageDetail'

const ExpectedReplyMessageIndex = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={path}>
          <ExpectedReplyMessageList />
        </Route>
        <Route path={`${path}/:id`} exact>
          <ExpectedReplyMessageDetail />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default ExpectedReplyMessageIndex
