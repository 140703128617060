import { Redirect, Switch } from 'react-router-dom'
import { LoginCallback } from '@okta/okta-react'
import MessagesIndex from 'pages/Messages/MessagesIndex'
import IntegrationOnboardIndex, {
  INTEGRATION_ONBOARDING_ROUTES,
} from 'pages/IntegrationOnboarding/IntegrationOnboardingIndex'
import TradingPartnerIndex from 'pages/TradingPartner'
import RoutesIndex from 'pages/Routes'
import ConnectionsIndex from 'pages/Connections'
import GatewaySettingsIndex from 'pages/Gateway'
import TranslationMapsIndex from 'pages/TranslationMaps'
import ExpectedReplyMessageIndex from 'pages/ExpectedReplyMessage'
import { ReactNode, useCallback, useState } from 'react'
import { Sidebar } from 'app/Sidebar/Sidebar'
import { TopNavigationHeader } from 'app/TopNavigationHeader'
import LoginPage from 'app/LoginPage/LoginPage'
import { NotFound } from 'pages/NotFound'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import ErrorPanel from 'molecules/ErrorPanel'
import { Button, Drawer, Layout } from '@loadsmart/loadsmart-ui'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import TranslationSettingsIndex from 'pages/TranslationSettings/TranslationSettingsIndex'
import { TopNavigationContextWrapper } from 'app/TopNavigationHeader/TopNavigationContext'
import Environment from 'common/types/Environment'
import getEnv from 'common/helpers/getEnv'
import { removeToken } from 'common/helpers/removeToken'
import PartnershipIndex from 'pages/Partnership'
import IntegrationTestDashboard from 'pages/IntegrationTest/IntegrationTestDashboard'
import ConversionCodesIndex from 'pages/ConversionCodes/ConversionCodeIndex'
import DataModelSchemasIndex from 'pages/DataModelSchema/DataModelSchemaIndex'

function ContentComponentWrapper({ children }: { children: ReactNode }) {
  return (
    <main
      className="items-center justify-start py-6 rg-main wrapper ml-50"
      style={{ paddingTop: '5em' }}
    >
      {children}
    </main>
  )
}

function PublicPage({ children }: { children: ReactNode }) {
  return <div>{children}</div>
}

function FallbackErrorPage(props: FallbackProps) {
  const [isDetailsOpened, setDetailDrawerOpened] = useState<boolean>(false)

  const toggleDetails = useCallback(() => {
    setDetailDrawerOpened(previous => !previous)
  }, [])

  return (
    <ContentComponentWrapper>
      <Drawer open={isDetailsOpened} onClose={toggleDetails}>
        <Drawer.Body>
          <ErrorPanel error={String(props.error.stack)} />
        </Drawer.Body>
      </Drawer>

      <Layout.Stack>
        <ErrorPanel
          title="Something didn't work as expected"
          error={removeToken(String(props.error))}
        />
        <Layout.Group>
          <Button variant="primary" onClick={props.resetErrorBoundary}>
            Reload
          </Button>
          <Button onClick={toggleDetails}>Details</Button>
        </Layout.Group>
      </Layout.Stack>
    </ContentComponentWrapper>
  )
}

function PrivatePage({ children }: { children: ReactNode }) {
  const EnvironmentBanner = () => {
    const env = getEnv()
    const dataTestId = 'env-banner'

    const DevBanner = (
      <div data-testid={dataTestId} className="bg-neutral p-1 text-center font-bold">
        Development
      </div>
    )

    const SandboxBanner = (
      <div
        data-testid={dataTestId}
        className="bg-warning-dark p-1 text-center font-bold text-neutral-lightest"
      >
        SANDBOX
      </div>
    )

    const QaBanner = (
      <div
        data-testid={dataTestId}
        className="bg-warning-dark p-1 text-center font-bold text-neutral-lightest"
      >
        QA
      </div>
    )

    const StagingBanner = (
      <div
        data-testid={dataTestId}
        className="bg-warning-dark p-1 text-center font-bold text-neutral-lightest"
      >
        Staging
      </div>
    )

    return (
      <div
        data-testid={dataTestId}
        style={{
          zIndex: '100',
          width: '12.5rem',
        }}
      >
        {env === Environment.Dev ? (
          DevBanner
        ) : env === Environment.Sandbox ? (
          SandboxBanner
        ) : env === Environment.QA ? (
          QaBanner
        ) : env === Environment.Staging ? (
          StagingBanner
        ) : (
          <div />
        )}
      </div>
    )
  }

  return (
    <TopNavigationContextWrapper>
      <div className="fixed z-10">
        <EnvironmentBanner />
        <Sidebar />
      </div>
      <header data-testid="app-header" className="w-full">
        <TopNavigationHeader />
      </header>
      <ErrorBoundary FallbackComponent={FallbackErrorPage}>
        <ContentComponentWrapper>{children}</ContentComponentWrapper>
      </ErrorBoundary>

      {/* <footer></footer> */}
    </TopNavigationContextWrapper>
  )
}

function Router() {
  return (
    <Switch>
      <Redirect exact from="/" to="/messages" />

      <PrivateRoute path="/messages">
        <PrivatePage>
          <MessagesIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/expected-reply-messages" strict>
        <PrivatePage>
          <ExpectedReplyMessageIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path={INTEGRATION_ONBOARDING_ROUTES.INDEX}>
        <PrivatePage>
          <IntegrationOnboardIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/trading-partner">
        <PrivatePage>
          <TradingPartnerIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/routes">
        <PrivatePage>
          <RoutesIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/gateways">
        <PrivatePage>
          <GatewaySettingsIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/connections">
        <PrivatePage>
          <ConnectionsIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/translation-maps">
        <PrivatePage>
          <TranslationMapsIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/translation-settings">
        <PrivatePage>
          <TranslationSettingsIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/partnership">
        <PrivatePage>
          <PartnershipIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/integration-tests">
        <PrivatePage>
          <IntegrationTestDashboard />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/conversion-codes">
        <PrivatePage>
          <ConversionCodesIndex />
        </PrivatePage>
      </PrivateRoute>
      <PrivateRoute path="/data-model/schemas">
        <PrivatePage>
          <DataModelSchemasIndex />
        </PrivatePage>
      </PrivateRoute>
      <PublicRoute path="/oidc/login/callback">
        <PublicPage>
          <LoginCallback />
        </PublicPage>
      </PublicRoute>

      <PublicRoute path="/login">
        <PublicPage>
          <LoginPage />
        </PublicPage>
      </PublicRoute>

      <PublicRoute path="*">
        <PublicPage>
          <NotFound />
        </PublicPage>
      </PublicRoute>
    </Switch>
  )
}

export default Router
