import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

import createHTTPClient from './HTTPClient'
import { authManager } from '../authManager'

const { CancelToken } = axios
export const httpClient = createHTTPClient()

export function getCancelTokenSource(): CancelTokenSource {
  return CancelToken.source()
}

export function getDefaultHeaders(overriddes?: {
  [key: string]: string
}): { [key: string]: string | undefined } {
  const token = authManager.getToken()

  return {
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    ...overriddes,
  }
}

function enhanceConfig(baseConfig: AxiosRequestConfig) {
  const defaultHeaders = getDefaultHeaders()
  const { headers } = baseConfig

  return {
    ...baseConfig,
    headers: {
      ...headers,
      ...defaultHeaders,
    },
  }
}

// TODO: create a single generic request creator that receives the method (get, post, etc..)

export function get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)
  return httpClient.get(url, enhancedConfig)
}

export function post(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.post(url, data, enhancedConfig)
}

export function put(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.put(url, data, enhancedConfig)
}

export function patch(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.patch(url, data, enhancedConfig)
}

// yep... delete is a reserverd word :(
export function del(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
  const providedConfig = config || {}
  const enhancedConfig = enhanceConfig(providedConfig)

  return httpClient.delete(url, enhancedConfig)
}
