import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Breadcrumbs, Label, Layout, Table, TextField } from '@loadsmart/loadsmart-ui'
import Loading from 'atoms/Loading'
import Pill from 'atoms/Pill'
import SimplePagination from 'atoms/SimplePagination'
import Field from 'atoms/Field'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { removeToken } from 'common/helpers/removeToken'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import ErrorPanel from 'molecules/ErrorPanel'
import useDebouncedValue from 'hooks/useDebouncedValue'

import { useSearchTradingPartners } from '../../api'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const COLUMNS = [
  {
    Header: 'Partner',
    Cell(row: TradingPartner) {
      return (
        <Link
          className="cursor-pointer text-primary font-semibold"
          to={`/trading-partner/${row.id}`}
        >
          {String(row.name)}
        </Link>
      )
    },
  },
  {
    Header: 'Tags',
    Cell(row: TradingPartner) {
      const tags: Array<string> = row.metadata?.tags || []
      return (
        <div className="flex flex-row">
          {tags.map(t => (
            <Pill key={t} className="mr-2">
              {String(t)}
            </Pill>
          ))}
        </div>
      )
    },
  },
  {
    Header: 'Created at',
    accessor: 'created_at',
    alignment: 'right',
    Cell(row: TradingPartner) {
      return LSDate(row.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM) || '-'
    },
  },
  {
    Header: 'Updated at',
    accessor: 'updated_at',
    alignment: 'right',
    Cell(row: TradingPartner) {
      return LSDate(row.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM) || '-'
    },
  },
]

const TradingPartnerList = () => {
  useTabTitle('Trading Partners')
  const [breadCrumbs] = useState([
    {
      label: 'Trading Partner',
      active: true,
    },
  ])
  const [page, setPage] = useState(1)
  const [name, setName] = useState<string>('')
  const debouncedName = useDebouncedValue<string>(name, 500)
  const { isLoading, error, data } = useSearchTradingPartners({ page, name: debouncedName })
  const topNavigationContext = useTopNavigationContext()

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group className="w-full" align="center" justify="space-between">
          <Breadcrumbs entries={breadCrumbs} />
        </Layout.Group>
      ),
    })
  }, [breadCrumbs])

  return (
    <>
      {isLoading ? (
        <Loading className="mt-8" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!data && (
          <Layout.Stack className="w-full mt-6">
            <Field className="flex">
              <Label>Partner name</Label>
              <TextField
                name="reference-code-filter-input"
                data-testid="reference-code-filter-input"
                onChange={e => setName(e.target.value)}
              />
            </Field>

            <Table className="w-full" data-testid="trading-partner-list">
              <Table.Head>
                {COLUMNS.map(column => (
                  <Table.Cell key={column.Header} alignment={column.alignment as any}>
                    {column.Header}
                  </Table.Cell>
                ))}
              </Table.Head>
              <Table.Body>
                {data.results.map(row => (
                  <Table.Row key={row.id}>
                    {COLUMNS.map(cell => (
                      <Table.Cell
                        className="text-sm"
                        key={cell.Header}
                        alignment={cell.alignment as any}
                      >
                        {cell.Cell(row)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Layout.Box className="flex justify-end w-full">
              <SimplePagination
                currentPage={page}
                previousPage={() => setPage(prev => prev - 1)}
                nextPage={() => setPage(prev => prev + 1)}
                canPreviousPage={!!data.previous}
                canNextPage={!!data.next}
              />
            </Layout.Box>
          </Layout.Stack>
        )
      )}
    </>
  )
}

export default TradingPartnerList
