import { ReactNode } from 'react'
import clsx from 'clsx'

import Status from '../../common/types/Status'
import getConditionalClasses from '../../common/helpers/getConditionalClasses'

import './Pill.css'

export interface PillProps {
  className?: string
  children?: ReactNode
  status?: Status
}

const combine = getConditionalClasses({
  scheme: ['light', 'dark'],
  status: ['neutral', 'danger', 'success'],
})

function Pill(props: PillProps) {
  const { status, className, children, ...others } = props
  const when = combine(props)

  return (
    <span
      {...others}
      className={clsx(
        'rg-pill rounded-full px-2 text-center py-0 inline-flex items-center uppercase',
        {
          'text-neutral-dark bg-neutral-lighter': when(['light', 'neutral']),
          'text-neutral-lightest bg-neutral-darker': when(['dark', 'neutral']),
        },
        {
          'text-success-dark bg-success-light': when(['light', 'success']),
          'text-success-light bg-success-dark': when(['dark', 'success']),
        },
        {
          'text-danger-dark bg-danger-light': when(['light', 'danger']),
          'text-danger-light bg-danger-dark': when(['dark', 'danger']),
        },
        className
      )}
    >
      {children}
    </span>
  )
}

Pill.defaultProps = {
  status: Status.Neutral,
  scheme: 'light',
}

export default Pill
