import { Layout, Tabs } from '@loadsmart/loadsmart-ui'
import JsonForm from 'molecules/JsonForm/JsonForm'
import { useMemo } from 'react'
import FormBuilderEditor from './FormBuilderEditor'

export interface FormBuilderPreviewProps {
  schema: any
  uiSchema: any
  data: any
  onFormChange: Function
  onDataChange: Function
}

const CUSTOM_TAB_PANEL_STYLE = {
  marginTop: '-2rem',
}

const FormBuilderPreview = ({
  schema,
  uiSchema,
  data,
  onFormChange,
  onDataChange,
}: FormBuilderPreviewProps) => {
  const DataPreview = useMemo(() => {
    return (
      <FormBuilderEditor
        json={data}
        onChange={(value: any) => {
          onDataChange(value)
        }}
      />
    )
  }, [data])

  return (
    <Tabs>
      <Tabs.Items>
        <Tabs.Item default name="preview" style={{ margin: 0 }}>
          Preview
        </Tabs.Item>
        <Tabs.Item name="data">Data</Tabs.Item>
      </Tabs.Items>
      <Tabs.Panels>
        <Tabs.Panel
          name="preview"
          style={{
            ...CUSTOM_TAB_PANEL_STYLE,
            height: '75vh',
            overflow: 'auto',
          }}
        >
          <Layout.Box>
            <JsonForm
              schema={schema}
              uiSchema={uiSchema}
              data={data}
              onChange={value => {
                onFormChange(value)
              }}
            />
          </Layout.Box>
        </Tabs.Panel>
        <Tabs.Panel name="data" style={{ ...CUSTOM_TAB_PANEL_STYLE }}>
          {DataPreview}
        </Tabs.Panel>
      </Tabs.Panels>
    </Tabs>
  )
}

export default FormBuilderPreview
