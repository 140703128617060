import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { del, get, post, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { RouteHistory, RouteList, RouteRead } from 'common/types/kraken-core/Routes'

export interface SearchRoutesRequest {
  page?: number
  page_size?: number
  filters?: Array<any>
}

export interface SearchRoutesPayload {
  next?: string
  previous?: string
  results: RouteList[]
}

export async function readRouteDetails(
  routeCode: string,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<RouteRead> | undefined]> {
  let error = null
  let result
  try {
    ;[error, result] = await to(get(`/routes?code=${routeCode}`, config))
    ;[error, result] = await to(get(`/routes/${result?.data?.results[0]?.id}`, config))

    return [error, result]
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function searchRoutes(
  data?: SearchRoutesRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<SearchRoutesPayload>> {
  const params: any = {
    page: data?.page || 1,
    page_size: data?.page_size || 100,
  }

  const filterByCode = data?.filters?.find(f => f.property === 'code').value
  if (filterByCode) params.code = filterByCode

  const [error, response] = await to(get('/routes', { ...config, params }))

  return { error, response }
}

export async function getRoute(
  data: {
    id: string
  },
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<RouteRead>> {
  const [error, response] = await to(get(`/routes/${data.id}`, { ...config }))

  return { error, response }
}

export async function createRoute(
  params: RouteRead,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<RouteRead>> {
  const [error, response] = await to(post(`/routes`, params, { ...config }))
  return { error, response }
}

export async function deleteRoute(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<RouteRead>> {
  const [error, response] = await to(del(`/routes/${id}`, { ...config }))
  return { error, response }
}

export async function updateRoute(
  params: RouteRead,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<RouteRead>> {
  const [error, response] = await to(put(`/routes/${params.id}`, params, { ...config }))
  return { error, response }
}

export async function exportRoute(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<RouteRead>> {
  const [error, response] = await to<RouteRead>(get(`/routes/${id}`, { ...config }))

  return { error, response }
}

export async function push(
  params: RouteRead,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<void>> {
  const [error, response] = await to(post(`/routes/${params.id}/push`, undefined, { ...config }))
  return { error, response }
}

export async function getRouteHistory(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<RouteHistory>> {
  const [error, response] = await to(get(`/routes/${id}/history`, { ...config }))

  if (error) throw error

  return { error, response }
}

export async function revertHistory(
  params: RouteHistory,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<RouteHistory>> {
  const [error, response] = await to(
    post(`/routes/${params.id}/history/${params.history_id}/revert`, {}, { ...config })
  )

  if (error) throw error

  return { error, response }
}
