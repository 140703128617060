import {
  MessageDirection,
  MessageStatus,
  TransactionType,
  Installation,
  IntegrationModule,
} from 'common/types/kraken-core/Message'
import Field from 'atoms/Field'
import {
  Label,
  BaseButton,
  Button,
  TextField,
  Select,
  ToggleGroup,
  Text,
  Layout,
  Tag,
  Switch,
  Tooltip,
} from '@loadsmart/loadsmart-ui'
// eslint-disable-next-line no-use-before-define
import { useEffect, useMemo, useState } from 'react'
import Icon from 'atoms/Icon'
import useForceUpdate from 'hooks/useForceUpdate'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import EventLike from 'common/types/EventLike'
import { onSearchTransactionTypes, onSearchTradingPartners } from '../api'
import RangeDatetimePicker, { DatetimeRange } from 'atoms/RangeDatetimePicker/RangeDatetimePicker'
import { useMessagesDashboardViewContext } from '../MessagesDashboardViewContext'
import styled from 'styled-components'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import TagSelector from 'atoms/TagsSelector/TagSelector'
import {
  onSearchInstallations,
  onSearchIntegrationModules,
  onSearchMessageStatus,
} from 'common/components/datasources/datasource'
import { capitalize } from 'lodash'
import { useMessagesDashboardContext } from '../MessageDahsboardContext'

const DIRECTION_FILTER_VALUES = [
  {
    label: 'Inbound',
    value: MessageDirection.INBOUND,
  },
  {
    label: 'Outbound',
    value: MessageDirection.OUTBOUND,
  },
]

const DELIVERED_FILTER_VALUES = [
  {
    label: 'Delivered',
    value: 'true',
  },
  {
    label: 'Not Delivered',
    value: 'false',
  },
]

const DATASOURCES = {
  TRADING_PARTNER: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tp: TradingPartner) => tp.id || '',
        getLabel: (tp: TradingPartner) => tp.name || '',
      },
      fetch: onSearchTradingPartners,
    }),
  ],
  TRANSACTION_TYPE: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (tt: TransactionType) => tt.key || '',
        getLabel: (tt: TransactionType) => tt.description || '',
      },
      fetch: onSearchTransactionTypes,
    }),
  ],
  MESSAGE_STATUS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (entity: MessageStatus) => entity.status || '',
        getLabel: (entity: MessageStatus) => `${capitalize(entity.status)}`,
      },
      fetch: onSearchMessageStatus,
    }),
  ],
  INSTALLATION: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (ins: Installation) => ins.id || '',
        getLabel: (ins: Installation) => ins.name || '',
      },
      fetch: onSearchInstallations,
    }),
  ],
  INTEGRATION_MODULE: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (int: IntegrationModule) => int.id || '',
        getLabel: (int: IntegrationModule) => int.name || '',
      },
      fetch: onSearchIntegrationModules,
    }),
  ],
}

const CustomSwitch = styled(Switch)`
  zoom: 80%;
`

function MessageFilter() {
  const dashboardContext = useMessagesDashboardContext()
  const context = useMessagesDashboardViewContext()

  const [showAdvancedFilters, setShowAdvancedFilters] = useState<boolean>(false)
  const [createdDatetimeRange, setCreatedDatetimeRange] = useState<DatetimeRange>()
  const [excludeTransactionType, setExcludeTransactionType] = useState<boolean>(
    context.filters.fields?.exclude_transaction_type != null ? true : false
  )

  const forceUpdate = useForceUpdate()

  const fields = context.filters.fields
  const setField = context.filters.setField
  const updateField = context.filters.updateField

  const advancedFiltersCounter = [
    fields.last_step,
    fields.exclude_last_step,
    fields.updated_at_from,
    fields.updated_at_to,
  ].filter(v => v).length

  const setComponentFiltersToDefaultValues = () => {
    setExcludeTransactionType(false)
  }

  const switchTransactionTypeFilter = () => {
    /*
      Here, we switch exclude tp's to "include tp's" because it wouldn't make
      sense to send both filters filled to the backend because they anulate each other.
    */
    if (excludeTransactionType === true && context.filters.fields.transaction_type) {
      updateField('exclude_transaction_type', context.filters.fields.transaction_type)
      updateField('transaction_type', undefined)
    } else if (context.filters.fields.exclude_transaction_type) {
      updateField('transaction_type', context.filters.fields.exclude_transaction_type)
      updateField('exclude_transaction_type', undefined)
    }
  }

  useEffect(() => {
    switchTransactionTypeFilter()
  }, [excludeTransactionType])

  useEffect(() => {
    switchTransactionTypeFilter()
  }, [])

  return (
    <Layout.Stack space="s">
      <Layout.Group space="s" align="flex-end">
        <Layout.Stack space="s">
          <Label>Partners</Label>
          <Select
            multiple
            id="select-trading-partner"
            name="select-trading-partner"
            onChange={setField('partner')}
            value={fields.partner}
            datasources={DATASOURCES.TRADING_PARTNER}
          />
        </Layout.Stack>
        <Layout.Stack space="s" justify="flex-end">
          <Label>Reference Code</Label>
          <TextField
            name="reference-code-filter-input"
            value={fields.reference_code ? fields.reference_code : ''}
            onChange={setField('reference_code')}
            size={40}
          />
        </Layout.Stack>
        <Layout.Stack space="s" justify="flex-start">
          <Layout.Group justify="space-between" align="center">
            <Label>Transaction Types</Label>
            <Layout.Group align="center" space="s">
              <Text variant="caption-bold">Exclude</Text>
              <CustomSwitch
                active={excludeTransactionType}
                onToggle={() => setExcludeTransactionType(c => !c)}
              />
            </Layout.Group>
          </Layout.Group>

          {!excludeTransactionType ? (
            <Select
              multiple
              id="transaction-type-partner"
              name="transaction-type-partner"
              onChange={setField('transaction_type')}
              value={fields.transaction_type}
              datasources={DATASOURCES.TRANSACTION_TYPE}
            />
          ) : (
            <Select
              multiple
              id="exclude-transaction-type-partner"
              name="exclude-transaction-type-partner"
              onChange={setField('exclude_transaction_type')}
              value={fields.exclude_transaction_type}
              datasources={DATASOURCES.TRANSACTION_TYPE}
            />
          )}
        </Layout.Stack>
        <Layout.Stack space="s" justify="flex-start">
          <Label>Delivered</Label>
          <ToggleGroup
            id="delivered"
            name="delivered"
            className="flex justify-center"
            onChange={(e: EventLike<any>) => {
              const value: any = e.target.value
              if (fields.delivered === value) {
                setField('delivered')({ target: { value: null } })
              } else {
                setField('delivered')({ target: { value } })
              }
            }}
            options={DELIVERED_FILTER_VALUES}
            value={fields.delivered}
          />
        </Layout.Stack>

        <Layout.Stack space="s" justify="flex-start">
          <Label>Direction</Label>
          <ToggleGroup
            id="direction"
            name="direction"
            className="flex justify-center"
            onChange={(e: EventLike<any>) => {
              const { value } = e.target
              if (fields.direction === value) {
                setField('direction')({ target: { undefined } })
              } else {
                setField('direction')({ target: { value } })
              }
            }}
            options={DIRECTION_FILTER_VALUES}
            value={fields.direction}
          />
        </Layout.Stack>
      </Layout.Group>
      {showAdvancedFilters ? (
        <Layout.Group space="s">
          <Field className="flex-1">
            <Label>Installation</Label>
            <Select
              name="message-installation-selector"
              multiple
              datasources={DATASOURCES.INSTALLATION}
              value={fields.installation}
              onChange={setField('installation')}
            />
          </Field>
          <Field className="flex-1">
            <Label>Integration Module</Label>
            <Select
              name="message-integration-selector"
              multiple
              datasources={DATASOURCES.INTEGRATION_MODULE}
              value={fields.integration}
              onChange={setField('integration')}
            />
          </Field>
          <Field className="flex-1">
            <Label>Last step</Label>
            <TextField
              name="last-step-filter-input"
              value={fields.last_step || ''}
              onChange={setField('last_step')}
              data-testid="last-step-field"
            />
          </Field>
          <Field className="flex-1">
            <Label>Exclude Last step</Label>
            <TextField
              name="not-last-step-filter-input"
              value={fields.exclude_last_step || ''}
              onChange={setField('exclude_last_step')}
              data-testid="exclude-last-step-field"
            />
          </Field>
          <Field className="flex">
            <Label>Updated At</Label>
            <RangeDatetimePicker
              onStartChange={value => {
                setField('updated_at_from')({
                  target: {
                    value,
                  },
                })
              }}
              onEndChange={value => {
                setField('updated_at_to')({
                  target: {
                    value,
                  },
                })
              }}
              value={{
                start: fields.updated_at_from,
                end: fields.updated_at_to,
              }}
            />
          </Field>
        </Layout.Group>
      ) : null}

      <Layout.Group align="flex-end" justify="space-between" space="s">
        <Layout.Group align="flex-end" space="s">
          <Layout.Group space="s">
            <Field className="flex">
              <Label>Created At</Label>
              {JSON.stringify(createdDatetimeRange)}
              <RangeDatetimePicker
                onStartChange={value => {
                  setField('created_at_from')({
                    target: {
                      value,
                    },
                  })
                }}
                onEndChange={value => {
                  setField('created_at_to')({
                    target: {
                      value,
                    },
                  })
                }}
                value={{
                  start: fields.created_at_from,
                  end: fields.created_at_to,
                }}
              />
            </Field>
          </Layout.Group>

          <Layout.Stack space="s">
            <Label>Status</Label>
            <Select
              name="message-status-selector"
              multiple
              datasources={DATASOURCES.MESSAGE_STATUS}
              value={fields.status}
              onChange={setField('status')}
              style={{
                width: '10rem',
              }}
            />
          </Layout.Stack>
          <Layout.Stack space="s">
            <Layout.Group align="center" space="s">
              <Label about="test">Tags</Label>
            </Layout.Group>

            <TagSelector
              values={fields.status_tags}
              options={dashboardContext.messageDetails.messageStatusTags.map(o => ({
                value: o.tag,
                description: o.description,
              }))}
              onChange={values => {
                setField('status_tags')({
                  target: {
                    value: values,
                  },
                })
              }}
            />
          </Layout.Stack>
        </Layout.Group>

        <Layout.Group space="s" align="center">
          {advancedFiltersCounter > 0 ? (
            <Tag variant="accent" data-testid="advanced-filter-counter">
              {advancedFiltersCounter}
            </Tag>
          ) : null}
          <Text
            variant="link"
            className="cursor-pointer"
            onClick={() => {
              analytics.event({
                category: AnalyticsEvent.MessageDashboardFiltersShowAdvancedFilters,
                action: AnalyticsEventTrigger.click,
              })
              setShowAdvancedFilters(c => !c)
            }}
          >
            Advanced filters
          </Text>
          <BaseButton
            onClick={async () => {
              analytics.event({
                category: AnalyticsEvent.MessageDashboardFiltersClearAll,
                action: AnalyticsEventTrigger.click,
              })
              await context.clearAllFilters()
              setComponentFiltersToDefaultValues()
              forceUpdate()
            }}
            leading={<Icon name="close" />}
          >
            Clear All
          </BaseButton>
          <Button
            variant="primary"
            id="search"
            onClick={async () => {
              analytics.event({
                category: AnalyticsEvent.MessageDashboardFiltersHitSearch,
                action: AnalyticsEventTrigger.click,
              })
              await context.searchMessages()
            }}
          >
            Search
          </Button>
        </Layout.Group>
      </Layout.Group>
    </Layout.Stack>
  )
}

export default MessageFilter
