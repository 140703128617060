import { Select } from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { IntegrationTestSuite } from 'common/types/kraken-core/IntegrationTest'
import { onSearchIntegrationTestSuite } from 'pages/IntegrationTest/IntegrationTestSuite/api'

export interface InterfaceTestSuiteSelectorProps {
  selected?: IntegrationTestSuite
  onSelect: (selected: IntegrationTestSuite) => void
}

const DATASOURCES = {
  INTEGRATION_TEST_SUITE: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (entity: IntegrationTestSuite) => entity.id || '',
        getLabel: (entity: IntegrationTestSuite) => entity.description || '',
      },
      fetch: onSearchIntegrationTestSuite,
    }),
  ],
}

export default function IntegrationTestSuiteSelector(props: InterfaceTestSuiteSelectorProps) {
  const selected = {
    label: props.selected ? props.selected.description : undefined,
    value: props.selected ? props.selected.id : undefined,
  }

  return (
    <Select
      id="select"
      name="select"
      onChange={e => props.onSelect(e.target.value as IntegrationTestSuite)}
      value={selected as any}
      datasources={DATASOURCES.INTEGRATION_TEST_SUITE}
    />
  )
}
