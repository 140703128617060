import { AxiosRequestConfig } from 'axios'
import { del, get, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { to } from 'common/helpers/awaitTo'
import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import { SearchIntegrationsPageRequest } from './types'
import { IntegrationModule } from '../../../common/types/kraken-core/IntegrationModule'

export async function searchIntegrationModule(
  data?: SearchIntegrationsPageRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PaginatedResponse<IntegrationModule>>> {
  const params: SearchIntegrationsPageRequest = {
    ...data,
    page: data?.page || 1,
    page_size: data?.page_size || 10,
  }
  const filterByName = data?.filters?.find(f => f.property === 'name').value
  if (filterByName) params.name = filterByName
  const [error, response] = await to(get('/integration_modules', { ...config, params }))
  return { error, response }
}
