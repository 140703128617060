import {
  Breadcrumbs,
  Button,
  Card,
  IconButton,
  Label,
  Layout,
  Modal,
  Switch,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import MessageList from './MessagesList'
import MessageFilter from './MessageFilter'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { CONSTANTS, useMessagesDashboardContext } from './MessageDahsboardContext'
import MessagesDashboardViewContextProvider from './MessagesDashboardViewContext'
import { DuplicateDashboardView, MessageDashboardView } from './types'
import Icon from 'atoms/Icon'
import { useEffect, useRef, useState } from 'react'
import { Tab, Tabs, Menu, MenuItem } from '@mui/material'
import { capitalize } from 'lodash'
import Divider from 'atoms/Divider'
import styled from 'styled-components'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

enum ViewMenuModalAction {
  NEW = 'create',
  UPDATE = 'rename',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
}

const CustomTabs = styled(props => <Tabs {...props} />)({
  paddingLeft: '0.25rem',
  paddingRight: '0.25rem',
})

const CustomViewTab = styled(props => <Tab {...props} />)({
  '&.MuiTab-root': {
    minHeight: '0',
    '&:active': {
      outline: 'none',
    },
    maxWidth: '50vw',
  },
  '&.Mui-selected': {
    outline: 'none',
  },
})

const CustomDottedIcon = styled(props => (
  <div {...props}>
    <Icon name="dots-horizontal" size={20} />
  </div>
))`
  transform: rotate(90deg);
`

const MAX_TAB_VIEW_CHARACTERSLENGTH = 50

function MessagesDashboard() {
  useTabTitle('Message Dashboard')
  const context = useMessagesDashboardContext()
  const [viewMenuAnchor, setViewMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [viewMenuModalMode, setViewMenuModalMode] = useState<string>()
  const [inheriteFiltersFromActiveView, setInheriteFiltersFromActiveView] = useState<boolean>(false)
  const [selectedView, setSelectedView] = useState<MessageDashboardView>()

  const viewRef = useRef<MessageDashboardView>()

  const isViewMenuOpened = Boolean(viewMenuAnchor)

  const handleOnMenuViewOpen = (element: HTMLElement) => {
    setViewMenuAnchorEl(element)
  }

  const handleCloseViewMenu = () => {
    setViewMenuAnchorEl(null)
  }

  const handleViewAction = (view: MessageDashboardView, action: ViewMenuModalAction) => {
    setSelectedView(view)
    setViewMenuModalMode(action)
    handleCloseViewMenu()
  }

  const handleCloseViewMenuModal = () => {
    setSelectedView(undefined)
  }

  const handleCreateNewView = () => {
    const index = context.getViews().length
    setSelectedView({
      index,
      name: `New view - ${index}`,
      filters: {},
      id: Math.random().toString(),
      pageSize: CONSTANTS.DEFAULT_VIEW.pageSize,
    })
    setInheriteFiltersFromActiveView(false)
    setViewMenuModalMode(ViewMenuModalAction.NEW)
  }

  const handleConfirmViewMenuModalAction = () => {
    if (!viewRef.current) return

    switch (viewMenuModalMode) {
      case ViewMenuModalAction.NEW:
        analytics.event({
          category: AnalyticsEvent.MessageDashboardViewsCreate,
          action: AnalyticsEventTrigger.click,
          params: {
            ...viewRef.current,
          },
        })
        context.createNewView({ ...viewRef.current, inheriteFiltersFromActiveView })

        break
      case ViewMenuModalAction.DELETE:
        analytics.event({
          category: AnalyticsEvent.MessageDashboardViewsRemove,
          action: AnalyticsEventTrigger.click,
          params: {
            ...viewRef.current,
          },
        })
        context.removeView(viewRef.current)
        break
      case ViewMenuModalAction.DUPLICATE:
        const duplicate: DuplicateDashboardView = {
          source: { ...viewRef.current },
        }
        analytics.event({
          category: AnalyticsEvent.MessageDashboardViewsDuplicate,
          action: AnalyticsEventTrigger.click,
          params: {
            ...duplicate,
          },
        })
        context.duplicateView(duplicate)
        break
      case ViewMenuModalAction.UPDATE:
        analytics.event({
          category: AnalyticsEvent.MessageDashboardViewsUpdate,
          action: AnalyticsEventTrigger.click,
          params: {
            ...viewRef.current,
          },
        })
        context.updateView(viewRef.current)
        break

      default:
        break
    }
    handleCloseViewMenuModal()
  }

  const getFormattedViewName = (view: MessageDashboardView) => {
    return view.name.length > MAX_TAB_VIEW_CHARACTERSLENGTH
      ? `${view.name.slice(0, MAX_TAB_VIEW_CHARACTERSLENGTH)}...`
      : view.name
  }

  useEffect(() => {
    if (selectedView) {
      viewRef.current = { ...selectedView }
    }
  }, [selectedView])

  useTopNavigationContext({
    children: (
      <Layout.Group className="w-full" align="center" justify="space-between">
        <Breadcrumbs
          entries={[
            {
              label: 'Message Dashboard',
              active: true,
            },
          ]}
        />
      </Layout.Group>
    ),
  })

  const activeIndex = context.getActiveViewIndex()
  const activeView = context.getViews().find((view, index) => index === activeIndex)
  const views = context.getViews()

  return (
    <>
      <Modal open={selectedView ? true : false}>
        {selectedView ? (
          <Layout.Stack>
            <Layout.Group justify="space-between" align="center">
              <Text variant="heading-md-bold">{`${capitalize(viewMenuModalMode)} view`}</Text>
              <IconButton onClick={handleCloseViewMenuModal}>
                <Icon name="close" />
              </IconButton>
            </Layout.Group>
            <Divider />

            {viewMenuModalMode === ViewMenuModalAction.NEW ||
            viewMenuModalMode === ViewMenuModalAction.UPDATE ||
            viewMenuModalMode === ViewMenuModalAction.DUPLICATE ? (
              <Layout.Stack>
                <Label>Name</Label>
                <TextField
                  defaultValue={selectedView.name}
                  placeholder="view name"
                  onChange={e => {
                    if (viewRef.current) viewRef.current.name = e.target.value
                  }}
                />
                {viewMenuModalMode === ViewMenuModalAction.NEW ? (
                  <Layout.Group align="center">
                    <Text variant="caption">Apply current selected filters?</Text>
                    <Switch
                      active={inheriteFiltersFromActiveView}
                      onToggle={() => setInheriteFiltersFromActiveView(c => !c)}
                    />
                  </Layout.Group>
                ) : null}
              </Layout.Stack>
            ) : null}

            {viewMenuModalMode === ViewMenuModalAction.DELETE ? (
              <Layout.Stack>
                <Text>
                  Are you sure you want to completly delete view{' '}
                  <Text variant="body-bold">{selectedView?.name}</Text>?
                </Text>
              </Layout.Stack>
            ) : null}

            <Layout.Group justify="center" align="center">
              <Button onClick={handleCloseViewMenuModal}>Cancel</Button>
              <Button variant="primary" onClick={handleConfirmViewMenuModalAction}>
                Confirm
              </Button>
            </Layout.Group>
          </Layout.Stack>
        ) : null}
      </Modal>

      <CustomTabs
        value={activeIndex}
        scrollButtons="auto"
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
      >
        {views.map((view, index) => {
          return (
            <CustomViewTab
              id={view.id}
              key={view.id}
              onClick={() => {
                context.setActiveViewIndex(index)
              }}
              title={view.name}
              label={
                <Layout.Group align="center" space="s" justify="space-between">
                  <Text variant={view.id === CONSTANTS.DEFAULT_VIEW.id ? 'body-bold' : 'body'}>
                    {getFormattedViewName(view)}
                  </Text>

                  <CustomDottedIcon
                    onClick={(e: any) => {
                      handleOnMenuViewOpen(e.currentTarget)
                    }}
                  />

                  <Menu
                    anchorEl={viewMenuAnchor}
                    open={isViewMenuOpened && activeIndex === index}
                    onClose={handleCloseViewMenu}
                  >
                    {view.id != CONSTANTS.DEFAULT_VIEW.id ? (
                      <MenuItem onClick={() => handleViewAction(view, ViewMenuModalAction.UPDATE)}>
                        <Layout.Group>
                          <Icon name="edit" />
                          <Text>Edit view</Text>
                        </Layout.Group>
                      </MenuItem>
                    ) : null}

                    <MenuItem onClick={() => handleViewAction(view, ViewMenuModalAction.DUPLICATE)}>
                      <Layout.Group>
                        <Icon name="copy" />
                        <Text>Duplicate view</Text>
                      </Layout.Group>
                    </MenuItem>
                    {view.id != CONSTANTS.DEFAULT_VIEW.id ? (
                      <MenuItem onClick={() => handleViewAction(view, ViewMenuModalAction.DELETE)}>
                        <Layout.Group>
                          <Icon name="remove" />
                          <Text>Delete view</Text>
                        </Layout.Group>
                      </MenuItem>
                    ) : null}
                  </Menu>
                </Layout.Group>
              }
            />
          )
        })}
        <CustomViewTab
          id="new-view"
          icon={<Icon name="add" />}
          iconPosition="end"
          label={<Text variant="body-bold">New view</Text>}
          onClick={handleCreateNewView}
        />
      </CustomTabs>

      {activeView ? (
        <div key={activeView.id}>
          <MessagesDashboardViewContextProvider view={activeView}>
            <Card>
              <Card.Body>
                <MessageFilter />
                <MessageList className="w-full" />
              </Card.Body>
            </Card>
          </MessagesDashboardViewContextProvider>
        </div>
      ) : null}
    </>
  )
}

export default MessagesDashboard
