import { Tag } from '@loadsmart/loadsmart-ui'
import { ExpectedReplyMessageStatus } from 'common/types/kraken-core/ExpectedReplyMessage'
import { STATUS_OPTIONS } from '../common'
import { ExpcetedReplyMessageStatusTagProps } from '../types'

function ExpectedReplyMessageStatusTag({
  status,
  size = 'small',
}: ExpcetedReplyMessageStatusTagProps) {
  const tagStyleMapping: any = {
    [ExpectedReplyMessageStatus.ACCEPTED]: 'accent',
    [ExpectedReplyMessageStatus.ACCEPTED_PARTIALLY]: 'warning',
    [ExpectedReplyMessageStatus.ACCEPTED_WITH_ERRORS]: 'warning',
    [ExpectedReplyMessageStatus.PENDING]: 'warning',
    [ExpectedReplyMessageStatus.REJECTED]: 'danger',
  }

  return (
    <Tag
      id="expected-reply-message-status-tag"
      data-testid="expected-reply-message-status-tag"
      size={size}
      variant={tagStyleMapping[status] || 'default'}
    >
      {String(STATUS_OPTIONS.find(s => s.value === status)?.label || 'Unknown')}
    </Tag>
  )
}

export default ExpectedReplyMessageStatusTag
