/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Breadcrumbs,
  Label,
  Layout,
  Modal,
  Table,
  TextField,
  Text,
  Button,
  Tabs,
  Accordion,
  Banner,
} from '@loadsmart/loadsmart-ui'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { Paper } from '@mui/material'
import { toast } from 'atoms/Toast'
import Loading from 'atoms/Loading'
import SimplePagination from 'atoms/SimplePagination'
import Field from 'atoms/Field'
import { removeToken } from 'common/helpers/removeToken'
import {
  ConversionCode,
  ConversionCodeCategoryDirection,
} from 'common/types/kraken-core/ConversionCode'
import ErrorPanel from 'molecules/ErrorPanel'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { useQueryParams } from 'hooks/useQueryParams'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { ConversionCodeHistory } from 'services/kraken-core/conversion_code/conversionCode.service'
import {
  useSearchConversionCodes,
  useCreateUpdateConversionCode,
  useGetConversionCodeCategoryHistory,
} from '../../api'
import { UpdateConversionCodesProps } from '../../types'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const COLUMNS = [
  {
    Header: 'Source value',
    Cell(row: ConversionCode) {
      return row.source_value
    },
  },
  {
    Header: 'Target value',
    Cell(row: ConversionCode) {
      return row.target_value
    },
  },
]

const UpdateConversionCodeModal = ({
  open,
  onClose,
  onSuccess,
  direction,
  conversionCode,
}: UpdateConversionCodesProps) => {
  useTabTitle('Trading Partner Conversion Codes')
  const query = useQueryParams()

  const {
    mutate: updateConversionCodeMutate,
    error: mutateError,
    isLoading: mutationIsLoading,
    data,
  } = useCreateUpdateConversionCode()
  const {
    data: conversionCodeHistory,
    isLoading: isLoadingHistory,
    error: errorHistory,
  } = useGetConversionCodeCategoryHistory(conversionCode?.id)
  const [conversionCodeUpdated, setConversionCodeUpdated] = useState<ConversionCode | undefined>({
    ...conversionCode,
    source_code: query.get('source_code') || '',
    target_code: query.get('target_code') || '',
    source: {
      id: query.get('source') || '',
      name: query.get('source_name') || '',
    },
    target: {
      id: query.get('target') || '',
      name: query.get('target_name') || '',
    },
    use_when: JSON.parse(query.get('use_when') || '{}'),
  })

  useEffect(() => {
    if (data) {
      onSuccess()
      toast.success(conversionCode?.id ? 'Updated with success' : 'Created with success')
    }
  }, [data])

  useEffect(() => {
    if (mutateError) {
      toast.error(String(mutateError))
    }
  }, [mutateError])

  const parseHistory = (history?: ConversionCodeHistory): string => {
    if (!history) return ''

    const { history_id, history_date, history_change_reason, history_user, ...rest } = history

    return JSON.stringify(rest, null, 2)
  }

  return (
    <Modal
      scale="large"
      onOverlayClick={() => onClose()}
      open={open}
      data-testid="conversion-code-modal"
    >
      <Tabs className="flex flex-col conversion-code-modal overflow-scroll" direction="horizontal">
        <Tabs.Items className="mt-2">
          <Tabs.Item default name="details">
            Conversion Code
          </Tabs.Item>

          {conversionCode?.id && <Tabs.Item name="history">History</Tabs.Item>}
        </Tabs.Items>

        <Tabs.Panels className="conversion-code-tabs">
          <Tabs.Panel name="details" className="h-full p-4">
            <Layout.Stack className="h-full">
              <Layout.Group>
                <Layout.Stack className="flex-1">
                  <Text>Source value</Text>
                  <TextField
                    defaultValue={conversionCodeUpdated?.source_value}
                    onChange={e =>
                      setConversionCodeUpdated(prev => ({
                        ...prev,
                        source_value: e.target.value,
                      }))
                    }
                  />
                </Layout.Stack>

                <Layout.Stack className="flex-1">
                  <Text>Target value</Text>
                  <TextField
                    defaultValue={conversionCodeUpdated?.target_value}
                    onChange={e =>
                      setConversionCodeUpdated(prev => ({
                        ...prev,
                        target_value: e.target.value,
                      }))
                    }
                  />
                </Layout.Stack>
              </Layout.Group>

              <Layout.Group align="flex-end" className="flex-1">
                <Layout.Stack className="flex-1">
                  <Button onClick={onClose}>Cancel</Button>
                </Layout.Stack>

                <Layout.Stack className="flex-1">
                  <Button
                    disabled={mutationIsLoading}
                    variant="primary"
                    onClick={() =>
                      updateConversionCodeMutate({
                        ...conversionCodeUpdated,
                        direction,
                      })
                    }
                  >
                    Update
                  </Button>
                </Layout.Stack>
              </Layout.Group>
            </Layout.Stack>
          </Tabs.Panel>

          <Tabs.Panel name="history">
            {isLoadingHistory ? (
              <Loading className="mt-8 justify-center" />
            ) : errorHistory ? (
              <ErrorPanel error={removeToken(JSON.stringify(errorHistory, null, 2))} />
            ) : (
              !!conversionCodeHistory &&
              conversionCodeHistory.map((history, index) => (
                <Accordion key={history.history_id}>
                  <Accordion.Toggle>
                    <Layout.Group>
                      {history.history_id}
                      {' - '}
                      {LSDate(history.history_date)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                      {history.history_user && ` by ${history.history_user}`}
                    </Layout.Group>
                  </Accordion.Toggle>

                  <Accordion.Body>
                    {history.history_change_reason && (
                      <Banner
                        title={history.history_change_reason}
                        variant="warning"
                        dismissible={false}
                        className="mt-4 mb-4"
                      />
                    )}

                    <ReactDiffViewer
                      splitView
                      hideLineNumbers
                      oldValue={parseHistory(conversionCodeHistory?.[index + 1])}
                      newValue={parseHistory(history)}
                      compareMethod={DiffMethod.TRIMMED_LINES}
                      styles={{
                        line: {
                          fontSize: 12,
                        },
                      }}
                    />
                  </Accordion.Body>
                </Accordion>
              ))
            )}
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </Modal>
  )
}

const ConversionCodeDetail = () => {
  const query = useQueryParams()
  const history = useHistory()
  const [searchParams] = useState({
    target: query.get('target'),
    source: query.get('source'),
    source_code: query.get('source_code'),
    target_code: query.get('target_code'),
    use_when: query.get('use_when'),
    direction: query.get('direction') as ConversionCodeCategoryDirection,
  })

  const [breadCrumbs] = useState([
    {
      label: 'Trading Partner',
      url: '#',
      onClick: () => {
        history.push('/trading-partner')
      },
      active: true,
    },
    {
      label: query.get('target_name') || '',
      url: '#',
      onClick: () => {
        history.push(`/trading-partner/${query.get('target')}`)
      },
      active: true,
    },
    {
      label: query.get('description') || '',
    },
  ])

  useTopNavigationContext({
    children: (
      <Layout.Group className="w-full" align="center">
        <Breadcrumbs entries={breadCrumbs} />
      </Layout.Group>
    ),
  })

  const getSearchParams = (sourceValue: string, page: number) => ({
    page,
    query: {
      ...searchParams,
      source_value: sourceValue,
    },
  })

  const [page, setPage] = useState(1)
  const [value, setValue] = useState<string>('')
  const debouncedValue = useDebouncedValue<string>(value, 500)
  const { isLoading, isFetching, error, data, refetch: refetchSearch } = useSearchConversionCodes(
    getSearchParams(debouncedValue, page)
  )
  const [conversionCodeToUpdate, setConversionCodeToUpdate] = useState<ConversionCode | undefined>()

  return (
    <>
      {conversionCodeToUpdate && (
        <UpdateConversionCodeModal
          open
          onClose={() => {
            setConversionCodeToUpdate(undefined)
          }}
          conversionCode={conversionCodeToUpdate}
          onSuccess={() => {
            refetchSearch().then(() => setConversionCodeToUpdate(undefined))
          }}
          direction={searchParams.direction}
        />
      )}

      {isLoading ? (
        <Loading className="mt-8" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!data && (
          <Paper className="p-2 w-full">
            <Layout.Stack className="w-full mt-6">
              <Layout.Group style={{ alignItems: 'end' }}>
                <Field className="flex flex-1">
                  <Label>Search conversion code by value</Label>
                  <TextField
                    name="conversion-code-filter-input"
                    data-testid="conversion-code-filter-input"
                    onChange={e => setValue(e.target.value)}
                  />
                </Field>

                <Button variant="primary" onClick={() => setConversionCodeToUpdate({})}>
                  Add new conversion code
                </Button>
              </Layout.Group>

              <Table className="w-full" data-testid="conversion-codes-detail-list">
                <Table.Head>
                  {COLUMNS.map(column => (
                    <Table.Cell key={column.Header}>{column.Header}</Table.Cell>
                  ))}
                </Table.Head>
                <Table.Body>
                  {data.results.map(row => (
                    <Table.Row
                      key={row.id}
                      className="cursor-pointer"
                      onClick={() => {
                        setConversionCodeToUpdate(row)
                      }}
                    >
                      {COLUMNS.map(cell => (
                        <Table.Cell className="text-sm" key={`${row?.id}-${cell.Header}`}>
                          {cell.Cell(row)}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              <Layout.Box className="flex justify-end w-full">
                <SimplePagination
                  currentPage={page}
                  previousPage={() => setPage(prev => prev - 1)}
                  nextPage={() => setPage(prev => prev + 1)}
                  canPreviousPage={!!data.previous && !isFetching}
                  canNextPage={!!data.next && !isFetching}
                />
              </Layout.Box>
            </Layout.Stack>
          </Paper>
        )
      )}
    </>
  )
}

export default ConversionCodeDetail
