import { TestExecutionResult } from './Common'
import { HistoryAware } from './HistoryAware'

export type GatewaySettingsDirection = 'inbound' | 'outbound'

export type GatewaySettingsTestResult = TestExecutionResult

export type GatewaySetting = {
  id: string
  iac_reference?: object
  created_at: string
  updated_at: string
  deleted_at: string
  owner: string
  direction: GatewaySettingsDirection
  name: string
  is_active: boolean
  definition: {
    [key: string]: object
  }
  gateway_type: string
  use_when: any
  partnership?: number
  connection: string
  protocol?: string
  private_endpoint?: string
  public_endpoint?: string
}

export type GatewayType = {
  gateway_type: string
  description: string
}

export interface GatewaySettingHistory extends GatewaySetting, HistoryAware {}

export interface GatewaySettingSchema {
  gateway_type: string
  doc?: string
  schema?: string
}

export enum GATEWAY_TYPES {
  INPUT_WEB = 'gateway:input:web',
  INPUT_POLLED = 'gateway:input:polled',
}
