import { Breadcrumbs, Layout } from '@loadsmart/loadsmart-ui'
import { useContext } from 'react'
import TemplateFilterComponent from './TemplateFilter/IntegrationTemplateFilter'
import IntegrationTemplateList from './TemplateList/IntegrationTemplateList'
import { IntegrationOnboardingContext } from './IntegrationOnboardingContext'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

function IntegrationOnboardDashboard() {
  useTabTitle('Integration Onboarding Dashboard')
  const context = useContext(IntegrationOnboardingContext)
  useTopNavigationContext({
    children: (
      <Layout.Group className="w-full" align="center">
        <Breadcrumbs
          entries={[
            {
              label: 'Integration Onboarding',
              active: true,
            },
          ]}
        />
      </Layout.Group>
    ),
  })

  return (
    <Layout.Stack>
      {context && (
        <>
          <TemplateFilterComponent
            filters={context.templateFilters}
            updateFilterValue={context.updateFilterValue}
          />

          <IntegrationTemplateList
            templates={context.templates}
            onSelect={context.onTemplateSelection}
          />
        </>
      )}
    </Layout.Stack>
  )
}

export default IntegrationOnboardDashboard
