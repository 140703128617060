import { Tag } from '@loadsmart/loadsmart-ui'
import { TransactionType } from 'common/types/kraken-core/Message'

interface TransactionTypeTagProps {
  transaction_type: TransactionType
}

const TransactionTypeTag = ({ transaction_type }: TransactionTypeTagProps) => {
  return <Tag variant="default">{String(transaction_type.key).toLocaleUpperCase()}</Tag>
}

export default TransactionTypeTag
