import { useCallback, useEffect, useRef, useState } from 'react'
import getID from '../../common/helpers/getID'

export interface useModalProps {
  id?: string
  open: boolean
  onOpen?: () => void
  onClose?: () => void
}

function useModal({
  open: openProp,
  id,
  onOpen,
  onClose,
}: useModalProps): {
  id: string
  open: boolean
  show: () => void
  hide: () => void
  toggle: () => void
} {
  const idRef = useRef(id || getID())
  const [open, setOpen] = useState(openProp)

  const show = useCallback(() => {
    setOpen(true)
    onOpen?.()
  }, [onOpen])

  const hide = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [onClose])

  const toggle = useCallback(() => {
    if (open) {
      hide()
    } else {
      show()
    }
  }, [hide, open, show])

  useEffect(() => {
    setOpen(openProp)
  }, [openProp])

  return {
    id: idRef.current,
    open,
    show,
    hide,
    toggle,
  }
}

export default useModal
