import { TranslationMap, TranslationMapLoaderEnum } from 'common/types/kraken-core/TranslationMap'
import isEmpty from 'lodash.isempty'

export const validateFields = (translationMap: TranslationMap) => {
  if (!translationMap) {
    throw Error('Invalid Translation Map instance')
  }

  const requiredProperties = [
    'name',
    'output_name_pattern',
    'loader',
    'output_content_type',
    'output_encoding',
    'input_content_type',
    'input_encoding',
  ]

  if (
    translationMap.loader &&
    !translationMap.loader.startsWith(TranslationMapLoaderEnum.RUNTIME_DATABASE_LOADER)
  ) {
    requiredProperties.push('path')
  }

  const missingProperties: string[] = []

  requiredProperties.forEach(rp => {
    if (isEmpty((translationMap as any)[rp])) {
      missingProperties.push(rp)
    }
  })

  if (missingProperties && missingProperties.length > 0)
    throw Error(`Missing required fields: ${missingProperties.join(', ')}`)
}
