import { useCallback, useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useHistory } from 'react-router'
import {
  Modal,
  Steps,
  Layout,
  Text,
  Button,
  BannerAction,
  BannerLarge,
} from '@loadsmart/loadsmart-ui'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import { toast } from 'atoms/Toast'
import Loading from 'atoms/Loading'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'

import { TranslationMap } from 'common/types/kraken-core/TranslationMap'
import { ImportModalProps, ImportStep, ImportSteps } from '../types'
import { useImportTranslationMap } from '../api'
import GeneralDetails from './GeneralDetails'
import { validateFields } from 'common/helpers/kraken-core/translationMaps.helper'

const ImportModal = ({ onClose, open, mode }: ImportModalProps) => {
  const history = useHistory()
  const [validations, setValidations] = useState<string>()
  const [importedData, setImportedData] = useState<any>(null)
  const loadedData = useRef<any>(null)
  const [actualStep, setActualStep] = useState<ImportStep>('upload-step')
  const {
    mutate,
    isLoading: importLoading,
    error: importError,
    data: importMutateData,
  } = useImportTranslationMap()

  useEffect(() => {
    setActualStep(mode === 'import' ? 'upload-step' : 'verify-step')

    if (mode === 'create') {
      setImportedData({
        created_at: '',
        updated_at: '',
        deleted_at: '',
        name: '',
        path: '',
        loader: '',
        output_reuse_original_message: true,
        output_content_type: '',
        output_encoding: '',
        output_name_pattern: '',
        output_headers: {},
        use_when_direction: 'inbound',
        use_when_facts: {},
      } as TranslationMap)
    }
  }, [mode])

  const handleFileUploaded = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()

      reader.onerror = () => {
        toast.error('There are some problems in your file, please review.')
      }

      reader.onload = () => {
        const binaryStr = reader.result
        setImportedData(JSON.parse(binaryStr?.toString() || '{}'))
      }

      reader.readAsText(file)
    })
  }, [])

  const handleClose = () => {
    setImportedData(null)
    setActualStep('upload-step')
    onClose()
  }

  const handleImport = () => {
    if (!loadedData.current) {
      toast.error('No data was loaded')
      return
    }
    const { rawUseWhen, rawOutputHeaders, ...rest } = loadedData.current

    let translationMapUseWhen
    let translationMapOutputHeaders

    try {
      translationMapUseWhen = rawUseWhen ? JSON.parse(rawUseWhen) : rest.use_when_facts
      translationMapOutputHeaders = rawOutputHeaders
        ? JSON.parse(rawOutputHeaders)
        : rest.output_headers
      setValidations(undefined)
      validateFields(rest as any)
      mutate({
        ...rest,
        use_when_facts: translationMapUseWhen,
        output_headers: translationMapOutputHeaders,
      })

      setActualStep('finish-step')
    } catch (error) {
      setValidations(`There are some problems in you form: ${error}`)
    }
  }

  const steps: ImportSteps =
    mode === 'create'
      ? [
          {
            complete: false,
            id: 'verify-step',
            label: 'Translation Map',
          },
          {
            complete: false,
            id: 'finish-step',
            label: 'Finish',
          },
        ]
      : [
          {
            complete: false,
            id: 'upload-step',
            label: 'Upload',
          },
          {
            complete: false,
            id: 'verify-step',
            label: 'Verify',
          },
          {
            complete: false,
            id: 'finish-step',
            label: 'Finish',
          },
        ]

  return (
    <Modal className="step-modal" scale="large" open={open}>
      <Steps current={actualStep} id="steps-import" steps={steps}>
        <Steps.Step id="upload-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            <Text variant="heading-sm-bold">Upload your Translation Map settings JSON file</Text>

            <Dropzone onDrop={handleFileUploaded} accept="application/json">
              {({ getRootProps, getInputProps }) => (
                <div>
                  <div
                    {...getRootProps()}
                    className="
                                            bg-neutral-lighter p-8 border-dashed border-primary-500 border rounded
                                        "
                  >
                    <input {...getInputProps()} />
                    <p>Drag n drop some files here, or click to select files</p>
                  </div>
                </div>
              )}
            </Dropzone>

            {importedData && (
              <FileViewer
                options={{
                  readonly: true,
                  heigth: 250,
                  theme: FileViewTheme.DEFAULT,
                }}
                content={JSON.stringify(importedData, null, 2)}
                contentType="application/json"
                showOptions={false}
              />
            )}

            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Cancel</Button>

              <Button
                variant="primary"
                disabled={!importedData}
                onClick={() => setActualStep('verify-step')}
              >
                Next
              </Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>

        <Steps.Step id="verify-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            {validations && validations.length > 0 ? (
              <BannerLarge description={validations} variant="danger" />
            ) : null}
            {importedData ? (
              <GeneralDetails
                translationMap={importedData as any}
                onUpdate={updated => {
                  loadedData.current = updated
                }}
              />
            ) : null}

            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="primary" onClick={handleImport}>
                {mode === 'import' && 'Import'}
                {mode === 'create' && 'Create'}
              </Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>

        <Steps.Step id="finish-step" className="flex flex-col flex-1">
          <Layout.Stack className="flex-1 h-full">
            {importLoading && <Loading className="mt-8 justify-center" />}
            {importError && (
              <ErrorPanel error={removeToken(JSON.stringify(importError, null, 2))} />
            )}
            {importMutateData && (
              <BannerAction
                action="Click here to see"
                onActionButtonClick={() => {
                  history.push(`translation-maps/${importMutateData.response.data?.id}`)
                }}
                scale="default"
                title="Translation Map successfully imported."
                variant="success"
                dismissible={false}
              />
            )}
            <Layout.Group justify="flex-end" align="flex-end" className="flex-1">
              <Button onClick={handleClose}>Close</Button>
            </Layout.Group>
          </Layout.Stack>
        </Steps.Step>
      </Steps>
    </Modal>
  )
}

export default ImportModal
