/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { ExpectedReplyMessageStatus } from 'common/types/kraken-core/ExpectedReplyMessage'
import { MessageRead } from 'common/types/kraken-core/Message'

export enum QUERIES {
  SEARCH_EXPECTED_REPLY_MESSAGES = 'expected_reply_messages',
  GET_EXPECTED_REPLY_MESSAGE = 'expected_reply_message/get',
}

export interface useSearchExpectedReplyMessageParams {
  page?: number
  pageSize?: number
  partnerName?: string
  createdAtStart?: string
  createdAtEnd?: string
  updatedAtStart?: string
  updatedAtEnd?: string
  status?: string
}

export interface useGetExpectedReplyMessageParams {
  id: string
}

export interface useUpdateStatusExpectedReplyMessageParams {
  id: string
  status: string
}

export interface ExpcetedReplyRelatedMessageProps {
  relatedMessage?: MessageRead
}

export interface ExpcetedReplyMessageStatusTagProps {
  status: ExpectedReplyMessageStatus
  size?: 'small' | 'default' | 'large'
}
