import { OktaAuth } from '@okta/okta-auth-js'
import oidcConfig from '../oidc/config'
import getEnv from './helpers/getEnv'
import Environment from './types/Environment'
import { User } from './types/User'

const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const TOKEN_ORIGIN = 'tokenOrigin'
const OKTA_STORAGE_TOKEN = 'okta-token-storage'

export const oktaAuth = new OktaAuth(oidcConfig.oidc)

export interface TokenManager {
  getToken: () => string | null
  clearTokens: () => void
  setAccessToken: (accessToken: any) => void
  setRefreshToken: (refreshToken: any) => void
  getRefreshToken: () => string | null
}

export const tokenManager: TokenManager = {
  getToken(): string | null {
    const { idToken: id } = JSON.parse(localStorage.getItem(OKTA_STORAGE_TOKEN) || '{}')
    return id?.idToken || localStorage.getItem(ACCESS_TOKEN)
  },

  clearTokens() {
    const storageKeys = [ACCESS_TOKEN, REFRESH_TOKEN, TOKEN_ORIGIN, OKTA_STORAGE_TOKEN]
    storageKeys.forEach(key => {
      localStorage.removeItem(key)
    })
  },

  setAccessToken(accessToken: any) {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  },

  setRefreshToken(refreshToken: any) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN)
  },
}

export interface AuthManager {
  startService: () => void
  signInWithRedirect: () => Promise<void>
  logout: () => void
  isAuthenticated: () => boolean
  getToken: () => string | null
  getUser: () => Promise<User>
  subscribeOnAuthStateChange: (handler: any) => void
}

export const authManager: AuthManager = {
  startService: () => {},
  signInWithRedirect: async () => {
    await oktaAuth.signInWithRedirect({
      originalUri: window.location.href,
    })
  },
  logout: () => {
    oktaAuth.signOut()
    tokenManager.clearTokens()
    window.location.href = '/login'
  },
  isAuthenticated: () => {
    if (getEnv() === Environment.Test) return true
    return tokenManager.getToken() ? true : false
  },
  getToken: () => {
    return tokenManager.getToken()
  },
  getUser: async () => {
    return oktaAuth.getUser().then(user => {
      return {
        first_name: user.given_name || '',
        last_name: user.family_name || '',
        username: user.name,
        id: user.email,
      }
    })
  },
  subscribeOnAuthStateChange: handler => {
    oktaAuth.authStateManager.subscribe(handler)
  },
}
