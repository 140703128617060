/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Breadcrumbs,
  Dropdown,
  Layout,
  Text,
  Banner,
  Button,
  TextField,
  Select,
  Switch,
  Tooltip,
  Drawer,
  LoadingDots,
  Tabs,
  Card,
} from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { toast } from 'atoms/Toast'
import Loading from 'atoms/Loading'
import { removeToken } from 'common/helpers/removeToken'
import ErrorPanel from 'molecules/ErrorPanel'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import LSString from 'common/String.helpers'
import { ConnectionProtocol, ConnectionSetting } from 'common/types/kraken-core/ConnectionSettings'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import ExternalLink from 'atoms/ExternalLink'
import { adminBaseURL } from 'common/HTTPClient/HTTPClient'
import HistoryComponent from 'pages/Connections/components/HistoryComponent'
import ConnectionSettingsDefitinion, {
  DefinitionValidationResult,
} from 'pages/Connections/components/ConnectionSettingsDefinition'
import TestExecutionResultComponent from 'molecules/TestExecutionResult/TestExecutionResultComponent'
import Icon from 'atoms/Icon'
import ConnectionRelatedGateways from 'pages/Connections/components/ConnectionRelatedGateways'
import {
  onExport,
  onSearchProtocol,
  onSearchTradingPartners,
  useGetConnectionSettings,
  useGetTradingPartner,
  useTestConnectionSetting,
  useUpdateConnectionSetting,
  useOpenCircuitBreaker,
  useCloseCircuitBreaker,
} from '../../api'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import ConditionalCallableLink from 'atoms/EntityInspectorLink/ConditionalCallableLink'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import IaCComponentReferenceWarning from 'atoms/IaCComponentReferenceWarning/IaCComponentReferenceWarning'

export interface ConnectionSettingsDetailsProps {
  id?: string
}

const ConnectionSettingsDetails = (props: ConnectionSettingsDetailsProps) => {
  useTabTitle('Connection Settings')
  const browserHistory = useHistory()
  const { id } = useParams<{ id: string }>()

  const [breadCrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([
    {
      label: 'Connection settings',
      active: true,
      url: '#',
      onClick: () => {
        browserHistory.push('/connections')
      },
    },
  ])
  const [connectionData, setConnectionData] = useState<any>()

  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [errors, setErrors] = useState<string | undefined>()
  const [isTestPanelOpened, setTestPanelOpened] = useState<boolean>(false)

  const [cachedConnectionData, setCachedConnectionData] = useState<any>()
  const rawDefinition = useRef<any>()
  const rawProtocolDefinition = useRef<any>()
  const definitionValidationResult = useRef<DefinitionValidationResult>()

  const handleSave = useRef<Function>(() => {})
  const handleTestExecution = useRef<Function>(() => {})
  const handleExport = useRef<Function>(() => {})
  const handleOpenCircuit = useRef<Function>(() => {})
  const handleCloseCircuit = useRef<Function>(() => {})

  const settings = useMemo(
    () => (
      <ConnectionSettingsDefitinion
        connection={connectionData}
        onChangeProtocolDefinition={value => {
          if (!value) return
          rawProtocolDefinition.current = value
        }}
        onChangeRawDefinition={value => {
          rawDefinition.current = JSON.parse(value || '')
        }}
        onValidationResult={(result: DefinitionValidationResult) => {
          definitionValidationResult.current = result
        }}
      />
    ),
    [connectionData]
  )

  const history = useMemo(() => <HistoryComponent id={id} />, [id])

  const relatedGateways = useMemo(() => <ConnectionRelatedGateways connectionId={id} />, [
    connectionData,
  ])

  const { isLoading, error, data: rawData, refetch } = useGetConnectionSettings({ id })

  const { data: ownerData } = useGetTradingPartner(rawData?.owner)

  const {
    mutate: updateConnectionSetting,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    error: updatedError,
    reset: updateReset,
  } = useUpdateConnectionSetting()

  const {
    mutate: testConnectionSettings,
    data: testConnectionResult,
    isSuccess: didTestSuccessfully,
    isLoading: isRunningTest,
    error: testFailedError,
  } = useTestConnectionSetting()

  const {
    mutate: openCircuitBreaker,
    data: openStateResult,
    isSuccess: didOpenStateSuccessfully,
    error: openStateFailedError,
  } = useOpenCircuitBreaker()

  const {
    mutate: closeCircuitBreaker,
    data: closeStateResult,
    isSuccess: didCloseStateSuccessfully,
    error: closeStateFailedError,
  } = useCloseCircuitBreaker()

  const vaultLink = useMemo(() => {
    if (connectionData?.vault?.id) {
      return `${adminBaseURL}/vault/vault/${connectionData.vault.id}`
    }
    return '/#'
  }, [connectionData])

  useEffect(() => {
    if (updatedError) {
      toast.error(`Something went wrong: ${String(updatedError)}`)
    }

    if (isUpdated) {
      toast.success('Updated with success')
    }

    setTimeout(() => {
      updateReset()
    }, 0)
  }, [isUpdated, updatedError])

  useEffect(() => {
    if (rawData) {
      setBreadcrumbs(prev => [
        prev[0],
        {
          label: rawData.name,
        },
      ])

      // Live update properties
      setConnectionData(rawData)

      // Local cached properties
      setCachedConnectionData({
        name: rawData.name,
      })
    }
  }, [rawData])

  useEffect(() => {
    setConnectionData((prev: any) =>
      prev
        ? {
            ...prev,
            owner_description: ownerData?.name,
          }
        : null
    )
  }, [ownerData?.name])

  useEffect(() => {
    setIsDirty(true)
    setErrors(undefined)
  }, [rawProtocolDefinition, rawDefinition])

  const handleChangeAndUpdate = (key: any, value: any) => {
    setConnectionData((prev: any) =>
      prev
        ? {
            ...prev,
            [key]: value,
          }
        : null
    )

    setIsDirty(true)
  }

  const handleChangeAndCache = (key: any, value: any) => {
    setCachedConnectionData((prev: any) => ({
      ...prev,
      [key]: value,
    }))
    setIsDirty(true)
  }

  handleExport.current = () => {
    if (!connectionData?.id) return
    onExport(connectionData.id, connectionData.name)
  }

  handleTestExecution.current = () => {
    if (!connectionData?.id) return
    testConnectionSettings({ ...connectionData })
    setTestPanelOpened(true)
  }

  handleOpenCircuit.current = () => {
    if (!connectionData?.id) return
    openCircuitBreaker({ ...connectionData })
  }

  useEffect(() => {
    if (openStateFailedError) {
      toast.error(`Something went wrong: ${String(openStateFailedError)}`)
    }

    if (didOpenStateSuccessfully) {
      toast.success(String(openStateResult))
      refetch()
    }
  }, [didOpenStateSuccessfully, openStateFailedError, openStateResult])

  handleCloseCircuit.current = () => {
    if (!connectionData?.id) return
    closeCircuitBreaker({ ...connectionData })
  }

  useEffect(() => {
    if (closeStateFailedError) {
      toast.error(`Something went wrong: ${String(closeStateFailedError)}`)
    }

    if (didCloseStateSuccessfully) {
      toast.success(String(closeStateResult))
      refetch()
    }
  }, [didCloseStateSuccessfully, closeStateFailedError, closeStateResult])

  handleSave.current = async () => {
    setErrors(undefined)

    // TODO: Implement form validation
    if (!connectionData?.protocol) {
      setErrors('Protocol is required')
      return
    }

    if (definitionValidationResult.current && !definitionValidationResult.current.isValid) {
      setErrors(`Definition is not valid`)
      return
    }

    const data: any = {
      ...connectionData,
      ...cachedConnectionData,
    }

    // Use raw definition or protocol definition
    if (rawDefinition.current) {
      data.definition = rawDefinition.current
    } else if (connectionData?.protocol) {
      data.definition = {
        ...data.definition,
        [connectionData?.protocol]: rawProtocolDefinition.current,
      }
    }

    updateConnectionSetting(data as ConnectionSetting)
    setErrors(undefined)
  }

  const DATASOURCES = {
    TRADING_PARTNER: [
      () => ({
        type: 'string',
        adapter: {
          getKey: (tp: TradingPartner) => tp.id || '',
          getLabel: (tp: TradingPartner) => tp.name || '',
        },
        fetch: onSearchTradingPartners,
      }),
    ],
    PROTOCOLS: [
      () => ({
        type: 'string',
        adapter: {
          getKey: (tp: ConnectionProtocol) => tp.protocol || '',
          getLabel: (tp: ConnectionProtocol) => tp.description || '',
        },
        fetch: onSearchProtocol,
      }),
    ],
  }

  const ownerValue = useMemo(
    () => ({
      value: connectionData?.owner,
      label: connectionData?.owner_description,
    }),
    [connectionData?.owner_description]
  )

  const topNavigationContext = useTopNavigationContext()

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group align="center" className="flex-1">
          <Layout.Box padding="none" className="flex-1">
            <Breadcrumbs entries={breadCrumbs} />
          </Layout.Box>
          {isUpdating && <LoadingDots />}
          {!isUpdating && (
            <Button variant="primary" disabled={!isDirty} onClick={() => handleSave.current()}>
              Save
            </Button>
          )}
          <Button trailing={<Icon name="bolt" />} onClick={() => handleTestExecution.current()}>
            Test
          </Button>
          <Layout.Box padding="none">
            <Dropdown>
              <Dropdown.Trigger>Actions</Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleExport.current()}>Export</Dropdown.Item>
                <Dropdown.Item onClick={() => handleOpenCircuit.current()}>
                  Open Circuit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCloseCircuit.current()}>
                  Close Circuit
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Layout.Box>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs, isUpdating])

  return (
    <Layout.Stack className="w-full" space="s">
      {errors && (
        <Banner scale="default" title={String(errors)} variant="danger" dismissible={false} />
      )}

      <Drawer open={isTestPanelOpened}>
        <Drawer.Header>Test Result</Drawer.Header>
        <Drawer.Body className="h-full">
          {isRunningTest && <LoadingDots />}
          {!isRunningTest && testConnectionResult && (
            <TestExecutionResultComponent result={testConnectionResult} />
          )}
        </Drawer.Body>
        <Drawer.Footer>
          <Button onClick={() => setTestPanelOpened(false)}>Close</Button>
        </Drawer.Footer>
      </Drawer>

      {isLoading ? (
        <Loading className="mt-8 justify-center" />
      ) : error ? (
        <ErrorPanel error={removeToken(JSON.stringify(error, null, 2))} />
      ) : (
        !!connectionData && (
          <Card>
            <Card.Body>
              <Layout.Stack>
                {connectionData.iac_reference ? <IaCComponentReferenceWarning /> : null}

                <Layout.Group space="m" className="mt-2 mb-4" justify="space-between">
                  <Layout.Stack data-testid="connection-setting-id" space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      ID
                    </Text>
                    <Text variant="heading-sm-bold">{connectionData.id}</Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Created at
                    </Text>
                    <Text variant="heading-sm-bold">
                      {LSDate(connectionData.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Updated at
                    </Text>
                    <Text variant="heading-sm-bold">
                      {LSDate(connectionData.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Deleted at
                    </Text>
                    <Text variant="heading-sm-bold">
                      {LSDate(connectionData.deleted_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                    </Text>
                  </Layout.Stack>
                  <Layout.Stack space="s" data-testid="connection-setting-active">
                    <Text variant="caption-bold" color="color-neutral">
                      Active
                    </Text>
                    <Switch
                      id="is_active"
                      name="is_active"
                      className="flex justify-center"
                      onToggle={e => handleChangeAndUpdate('is_active', !connectionData.is_active)}
                      active={connectionData.is_active}
                    />
                  </Layout.Stack>
                  <Layout.Stack space="s">
                    <Text variant="caption-bold" color="color-neutral">
                      Circuit State
                    </Text>
                    <Text variant="heading-sm-bold">
                      {LSString.normalizeCode(connectionData.circuit_state)}
                    </Text>
                  </Layout.Stack>
                </Layout.Group>
                <Layout.Switcher space="m" className="mb-4">
                  <Layout.Stack data-testid="connection-setting-name" space="s">
                    <Text>Name</Text>
                    <TextField
                      value={cachedConnectionData.name}
                      onChange={e => handleChangeAndCache('name', e.target.value)}
                    />
                  </Layout.Stack>
                </Layout.Switcher>

                <Layout.Group space="s">
                  <Layout.Stack data-testid="connection-setting-protocol" space="s">
                    <Text>Protocol</Text>
                    <Select
                      id="protocol"
                      name="protocol"
                      onChange={e => {
                        handleChangeAndUpdate(
                          'protocol',
                          (e.target.value as ConnectionProtocol)?.protocol
                        )
                        handleChangeAndUpdate(
                          'protocol_description',
                          (e.target.value as ConnectionProtocol)?.description
                        )
                      }}
                      value={
                        {
                          value: connectionData.protocol,
                          label: connectionData.protocol_description || connectionData.protocol,
                        } as Selectable
                      }
                      datasources={DATASOURCES.PROTOCOLS}
                    />
                  </Layout.Stack>
                  <Layout.Stack data-testid="connection-setting-owner" space="s">
                    <ConditionalCallableLink
                      onClick={
                        connectionData?.owner
                          ? () => {
                              window.open(`/trading-partner/${connectionData.owner}`)
                            }
                          : undefined
                      }
                      label="Owner"
                    />
                    <Select
                      id="owner"
                      name="owner"
                      onChange={e => {
                        handleChangeAndUpdate('owner', (e.target.value as TradingPartner)?.id)
                        handleChangeAndUpdate(
                          'owner_description',
                          (e.target.value as TradingPartner)?.name
                        )
                      }}
                      value={ownerValue as Selectable}
                      datasources={DATASOURCES.TRADING_PARTNER}
                    />
                  </Layout.Stack>
                  <div data-testid="connection-setting-vault" className="flex-1">
                    {connectionData.vault?.id && (
                      <Tooltip message="Go to Vault">
                        <Text>Vault</Text>
                        <ExternalLink
                          className="flex flex-row gap-1 ml-2"
                          href={vaultLink}
                          target="blank"
                        />
                      </Tooltip>
                    )}
                  </div>
                </Layout.Group>

                <Tabs
                  className="flex flex-col"
                  direction="horizontal"
                  data-testid="connection-setting-tabs"
                >
                  <Tabs.Items>
                    <Tabs.Item default name="definition-tab">
                      Definition
                    </Tabs.Item>
                    <Tabs.Item name="gateways-tab">Gateways</Tabs.Item>
                    <Tabs.Item name="history-tab">History</Tabs.Item>
                  </Tabs.Items>

                  <Tabs.Panels>
                    <Tabs.Panel name="definition-tab">{settings}</Tabs.Panel>
                    <Tabs.Panel name="gateways-tab">{relatedGateways}</Tabs.Panel>
                    <Tabs.Panel name="history-tab">{history}</Tabs.Panel>
                  </Tabs.Panels>
                </Tabs>
              </Layout.Stack>
            </Card.Body>
          </Card>
        )
      )}
    </Layout.Stack>
  )
}

export default ConnectionSettingsDetails
