import { IconButton, Layout, LoadingDots, Tag, Text } from '@loadsmart/loadsmart-ui'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Icon from 'atoms/Icon/Icon'
import LinkToParnter from 'atoms/LinkToPartner/LinkToPartner'
import LSDate, { DATE_FORMAT_MMDDYYHHMM } from 'common/Date.helpers'
import {
  DataModelSchemaDetail,
  DataModelSchemaList,
} from 'common/types/kraken-core/DataModelSchema'
import isEmpty from 'lodash.isempty'
import { useHistory } from 'react-router'

export interface DataModelSchemaListTableProps {
  data: Array<DataModelSchemaList>
  isLoading: boolean
  onDetail?: () => void
  onRemove?: (entity: DataModelSchemaDetail) => void
}

export default function DataModelSchemaListTable(props: DataModelSchemaListTableProps) {
  const history = useHistory()
  const COLUMNS = [
    {
      Header: '#',
      style: {
        width: '1%',
      },
      Cell(row: DataModelSchemaList) {
        const idAsString = row.id?.slice(0, 8) || '-'
        return (
          <Tag
            className="cursor-pointer"
            variant="default"
            onClick={() => {
              history.push(`schemas/${row.id}`)
            }}
          >
            {idAsString}
          </Tag>
        )
      },
    },
    {
      Header: 'Description',
      style: {
        width: '15%',
      },
      Cell(row: DataModelSchemaList) {
        return row.description
      },
    },
    {
      Header: 'Engine',
      style: {
        width: '15%',
      },
      Cell(row: DataModelSchemaList) {
        return <Tag>{row.engine}</Tag>
      },
    },
    {
      Header: 'Owner',
      style: {
        width: '5%',
      },
      Cell(row: DataModelSchemaList) {
        return row.owner ? <LinkToParnter partner={row.owner} /> : null
      },
    },
    {
      Header: '',
      style: {
        width: '15%',
      },
      Cell(row: DataModelSchemaList) {
        return LSDate(row.updated_at)?.format(DATE_FORMAT_MMDDYYHHMM)
      },
    },
    {
      Header: 'Actions',
      style: {
        width: '10%',
      },
      Cell(row: DataModelSchemaList) {
        return (
          <Layout.Group justify="flex-end">
            <IconButton onClick={() => (props.onRemove ? props.onRemove(row) : null)}>
              <Icon name="remove" />
            </IconButton>
          </Layout.Group>
        )
      },
    },
  ]

  return (
    <>
      <Table size="small" style={{ overflow: 'auto' }}>
        <TableHead>
          <TableRow>
            {COLUMNS.map((c, index) => (
              <TableCell style={c.style} key={`header-${index}`}>
                <Text variant="button-sm">{c.Header}</Text>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key={'table-body'}>
          {props.data.length > 0 &&
            props.data.map((row, index) => {
              return (
                <TableRow key={`table-row-${row.id}`}>
                  {COLUMNS.map((column, columnIndex) => (
                    <TableCell
                      style={column.style}
                      width={column.style.width}
                      size="small"
                      key={`table-cell-${index}-${columnIndex}`}
                    >
                      {column.Cell(row)}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}

          {props.data.length <= 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="left">
                {isEmpty(props.data) && !props.isLoading ? (
                  <Layout.Stack className="p-2" space="l">
                    <Text variant="body-bold">No data models available.</Text>
                  </Layout.Stack>
                ) : props.isLoading ? (
                  <LoadingDots />
                ) : null}
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </>
  )
}
