import {
  ControlProps,
  RankedTester,
  rankWith,
  and,
  isStringControl,
  not,
  isEnumControl,
  isAnyOfControl,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Unwrapped } from '@jsonforms/material-renderers'
import isEmpty from 'lodash.isempty'

const { MaterialTextControl } = Unwrapped

export const customHandleChange = (
  path: string,
  value: string,
  callBack: (path: string, value?: string) => void
) => {
  if (isEmpty(value)) {
    callBack(path, undefined)
    return
  }
  callBack(path, value)
}

export const CustomTextControl = (props: ControlProps) => {
  return (
    <MaterialTextControl
      {...props}
      handleChange={(path, value) => customHandleChange(path, value, props.handleChange)}
    />
  )
}

export const customTextControlTester: RankedTester = rankWith(
  3,
  and(isStringControl, not(isEnumControl), not(isAnyOfControl))
)
export default withJsonFormsControlProps(CustomTextControl)
