import commonSchema from './common'
import httpAuthenticationUiSchema from './common/httpAuthentication'

const httpCategories = [
  {
    type: 'Category',
    label: 'General',
    elements: [
      {
        type: 'Group',
        elements: [
          {
            type: 'Advice',
            options: {
              adviceType: 'info',
              adviceValue: 'This is the <b>HTTP generic connector definition</b><br/>',
            },
          },
          {
            type: 'Control',
            scope: '#/properties/url',
          },
          {
            type: 'Control',
            scope: '#/properties/remote_methods',
            options: {
              detail: commonSchema.remoteMethodDetail,
            },
          },
        ],
      },
    ],
  },
  {
    type: 'Category',
    label: 'Retry Strategy',
    elements: [
      {
        type: 'Group',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/request_retry',
          },
        ],
      },
    ],
  },
  {
    type: 'Category',
    label: 'Authentication',
    elements: [
      {
        type: 'Group',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/HTTP-Authentication/properties/method',
          },
          httpAuthenticationUiSchema.authCustom,
          httpAuthenticationUiSchema.authBasicSchema,
          httpAuthenticationUiSchema.authTokenFromUrl,
          httpAuthenticationUiSchema.authJwt,
          httpAuthenticationUiSchema.authToken,
        ],
      },
    ],
  },
  {
    type: 'Category',
    label: 'Test',
    elements: [
      {
        type: 'Group',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/test/properties/method',
          },

          {
            type: 'Group',
            label: 'Verb',
            elements: [
              {
                type: 'Control',
                scope: '#/properties/test/properties/HTTP-Verb',
              },
              {
                type: 'Control',
                scope: '#/properties/test/properties/HTTP-Endpoint',
              },
            ],
            rule: {
              effect: 'SHOW',
              condition: {
                scope: '#/properties/test/properties/method',
                schema: {
                  const: 'http:connector:tester:verb',
                },
              },
            },
          },
          {
            type: 'Group',
            label: 'Remote Method Inline',
            elements: [
              {
                type: 'Advice',
                options: {
                  adviceType: 'info',
                  adviceValue: 'To define a specific remote method',
                },
              },
              {
                type: 'Control',
                scope: '#/properties/test/properties/remote_method/properties/definition',
                options: {
                  detail: commonSchema.remoteMethodDetail,
                },
              },
            ],
            rule: {
              effect: 'SHOW',
              condition: {
                scope: '#/properties/test/properties/method',
                schema: {
                  const: 'http:connector:tester:inline-remote-method',
                },
              },
            },
          },
          {
            type: 'Group',
            label: 'Remote Method by Reference',
            elements: [
              {
                type: 'Advice',
                options: {
                  adviceType: 'info',
                  adviceValue:
                    'To refer to an existing remote method key where it will be used during the test execution',
                },
              },
              {
                type: 'Control',
                scope: '#/properties/test/properties/remote_method/properties/key',
              },
            ],
            rule: {
              effect: 'SHOW',
              condition: {
                scope: '#/properties/test/properties/method',
                schema: {
                  const: 'http:connector:tester:remote-method-by-reference',
                },
              },
            },
          },
        ],
      },
    ],
  },
]

const uiSchema: any = {
  type: 'Categorization',
  elements: httpCategories,
}

export default uiSchema
