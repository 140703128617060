// General imports, like internal and external libraries
import Loading from 'atoms/Loading'
import { Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import GatewaySettingsDetails from './containers/GatewaySettingsDetail'
import GatewaySettingsList from './containers/GatewaySettingsList'

import './index.css'

// Containers (Every page of our module)

const GatewaySettingsIndex = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={path}>
          <GatewaySettingsList />
        </Route>
        <Route path={`${path}/:id`} exact>
          <GatewaySettingsDetails />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default GatewaySettingsIndex
