import {
  IntegrationTestCaseDetail,
  IntegrationTestSuite,
  LocalIntegrationTestCase,
  LocalIntegrationTestCaseExecutionType,
} from 'common/types/kraken-core/IntegrationTest'
import { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getIntegrationTestCaseDetail } from 'services/kraken-core/integration_test/integration_test.service'
import {
  useCreateIntegrationTestCase,
  useGetIntegrationTestCaseDetail,
  useRunIntegrationTestCase,
} from './IntegrationTestCase/api'
import { onSearchIntegrationTestSuite } from './IntegrationTestSuite/api'
export interface IntegrationTestCaseDetailViewState {
  isTestCaseDetailViewOpened: boolean
  activeViewTab: TabViewIndex
}

export enum TabViewIndex {
  ENTRY_POINT = 0,
  INPUTS = 1,
  EXPECTATIONS = 2,
  EXECUTION = 3,
}

export interface IntegrationTestContextState {
  suite?: IntegrationTestSuite
  testCase?: IntegrationTestCaseDetail
  localTestCase?: LocalIntegrationTestCase
  testDetailViewState: IntegrationTestCaseDetailViewState

  // Actions
  setSuite: (suite: IntegrationTestSuite) => void
  setTestDetailViewState: (viewState: IntegrationTestCaseDetailViewState) => void
  createNewTestCase: () => void
  openTestCaseDetails: (localTest: LocalIntegrationTestCase) => void
  toggleTestCaseDetailView: () => void
  cloneTestCase: (id: string) => Promise<IntegrationTestCaseDetail | undefined>
  runTestCase: () => void
}

export const IntegrationTestContext = createContext<IntegrationTestContextState | undefined>({
  testDetailViewState: {
    isTestCaseDetailViewOpened: false,
    activeViewTab: TabViewIndex.ENTRY_POINT,
  },
  setSuite: (suite: IntegrationTestSuite) => {},
  setTestDetailViewState: viewState => {},
  createNewTestCase: () => {},
  openTestCaseDetails: (localTest: LocalIntegrationTestCase) => {},
  toggleTestCaseDetailView: () => {},
  cloneTestCase: async (id: string) => {
    return {} as any
  },
  runTestCase: () => {},
})

export interface IntegrationTestContextWrapperProps {
  children: any
}

export function useIntegrationTestContext() {
  const context = useContext(IntegrationTestContext)
  if (context === undefined)
    throw new Error(
      'useIntegrationTestContext must be used within a IntegrationTestContextProvider'
    )
  return context
}

const DEFAULT_LOCAL_TEST: LocalIntegrationTestCase = {
  id: '',
  description: '',
  execution: {
    isRunning: false,
    status: LocalIntegrationTestCaseExecutionType.NOT_EXECUTED,
  },
}

export default function IntegrationTestContextProvider({
  children,
}: IntegrationTestContextWrapperProps) {
  const [suite, setSuite] = useState<IntegrationTestSuite | undefined>()
  const [testCase, setTestCase] = useState<IntegrationTestCaseDetail | undefined>()
  const [testCaseId, setTestCaseId] = useState<string>()
  const [testDetailViewState, setTestDetailViewState] = useState({
    isTestCaseDetailViewOpened: false,
    activeViewTab: TabViewIndex.ENTRY_POINT,
  })
  const [localTestCase, setLocalTestCase] = useState<LocalIntegrationTestCase>(DEFAULT_LOCAL_TEST)

  const {
    data: fetchedTestCase,
    refetch: fetchTestCaseDetail,
    isLoading: isLoadingTestCase,
    isSuccess: isSuccessTestCase,
    error: errorTestCase,
  } = useGetIntegrationTestCaseDetail(
    {
      id: testCaseId,
      suite_id: suite?.id,
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      useErrorBoundary: true,
      onError: (error: any) => {
        toast.error(`Error when fetching: ${error}`)
      },
    }
  )

  const {
    error: runError,
    isError: isRunError,
    isSuccess: isRunSuccess,
    status: runStatus,
    data: runData,
    isLoading: runIsLoading,
    mutateAsync: runTestCaseAsync,
  } = useRunIntegrationTestCase()

  const { mutateAsync: createIntegrationTestCase } = useCreateIntegrationTestCase()

  const handleOpenTestCaseDetails = (localTest: LocalIntegrationTestCase) => {
    setTestDetailViewState(c => ({ ...c, isTestCaseDetailViewOpened: true }))
    setTestCaseId(localTest.id)
    setLocalTestCase(localTest)
  }

  const handleRunTest = () => {
    if (!localTestCase) {
      toast.error("There's no Test Case to be executed")
      return
    }

    setLocalTestCase(c => ({
      ...c,
      execution: {
        ...c.execution,
        isRunning: true,
      },
    }))

    setTestDetailViewState(c => ({
      ...c,
      activeViewTab: TabViewIndex.EXECUTION,
    }))

    runTestCaseAsync({
      id: localTestCase.id,
      suite_id: suite?.id,
    }).catch(failure => {
      toast.error(`Something went wrong when running the test: ${failure}`)
      setLocalTestCase(c => ({
        ...c,
        execution: {
          ...c.execution,
          result: {
            details: String(failure),
            success: false,
          },
          status: LocalIntegrationTestCaseExecutionType.ERRROR,
          isRunning: false,
        },
      }))
    })
  }

  const handleNewTestCase = () => {
    setTestCaseId(undefined)
    setTestCase({
      suite: suite?.id,
      http_mocks: [],
      lookup_table_mocks: [],
      ftp_mocks: [],
    })
    setTestDetailViewState(c => ({
      ...c,
      isTestCaseDetailViewOpened: true,
      activeViewTab: TabViewIndex.ENTRY_POINT,
    }))
    setLocalTestCase(DEFAULT_LOCAL_TEST)
  }

  const handleToggleTestCaseView = () => {
    if (testDetailViewState) {
      setTestCaseId(undefined)
    }
    setTestDetailViewState(c => ({
      ...c,
      isTestCaseDetailViewOpened: !c.isTestCaseDetailViewOpened,
    }))
  }

  const handleCloneTest = async (id: string): Promise<IntegrationTestCaseDetail | undefined> => {
    const toBeCloned = await getIntegrationTestCaseDetail({
      id: id,
      suite_id: suite?.id,
    })

    const cloned = {
      ...toBeCloned.response?.data,
      id: undefined,
      description: toBeCloned.response?.data.description.concat(' (CLONED)'),
    }

    const response = await createIntegrationTestCase({
      object: cloned,
      suite_id: suite?.id,
    })

    if (!response) {
      console.error(response)
      return undefined
    } else {
      return response
    }
  }

  useEffect(() => {
    if (testCaseId) {
      fetchTestCaseDetail().then(r => {
        if (r.data) {
          setTestCase(r.data)
        }
      })
    }
  }, [testCaseId])

  useEffect(() => {
    if (testCase) {
      setLocalTestCase(c => ({
        ...c,
        id: testCase.id || c.id,
        description: testCase.description || c.description,
        execution: {
          ...c.execution,
          isRunning: c.execution.isRunning,
          status: c.execution.status,
        },
      }))
    }
  }, [testCase])

  useEffect(() => {
    if (!runData) return

    setLocalTestCase(c => ({
      ...c,
      execution: {
        isRunning: false,
        status: runData?.success
          ? LocalIntegrationTestCaseExecutionType.PASSED
          : LocalIntegrationTestCaseExecutionType.FAILED,
        result: {
          details: runData.details,
          success: runData.success,
          checkers_outcomes: runData.checkers_outcomes,
          generated: runData.generated,
        },
      },
    }))
  }, [runData])

  useEffect(() => {
    onSearchIntegrationTestSuite({
      query: '',
    }).then(response => {
      setSuite(response.find(p => Boolean(p)))
    })
  }, [])

  return (
    <IntegrationTestContext.Provider
      value={{
        // State
        suite,
        testCase,
        testDetailViewState,
        localTestCase,

        // Actions
        setSuite,
        setTestDetailViewState,
        createNewTestCase: handleNewTestCase,
        openTestCaseDetails: handleOpenTestCaseDetails,
        toggleTestCaseDetailView: handleToggleTestCaseView,
        cloneTestCase: handleCloneTest,
        runTestCase: handleRunTest,
      }}
    >
      {children}
    </IntegrationTestContext.Provider>
  )
}
