import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Grid } from '@mui/material'
import { CustomRendererMetadata } from '../abstraction'
import Advice from '../../../../atoms/Advice'

export const ADVICE_RENDERER_META: CustomRendererMetadata = {
  type: 'Advice',
  label: 'Advice',
}

export const AdviceRenderer = (props: ControlProps) => {
  let hasValidDefinition = false
  const { options } = props.uischema

  if (options && options.adviceType && options.adviceValue) {
    hasValidDefinition = true
  }
  return (
    <>
      {!hasValidDefinition && (
        <Advice type="alert" value={`Invalid definition: ${JSON.stringify(props.uischema)}`} />
      )}
      {hasValidDefinition && (
        <Grid container>
          <Advice type={options?.adviceType} value={options?.adviceValue} />
        </Grid>
      )}
    </>
  )
}

export default withJsonFormsControlProps(AdviceRenderer)
