import { Box } from '@mui/material'
import { IPortDefaultProps } from '@mrblenny/react-flow-chart'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const PortCustom = (props: IPortDefaultProps) => {
  if (props.port.properties?.type === 'child') {
    return (
      <Box className="port" style={{ backgroundColor: '#929AA1' }}>
        <ChevronRightIcon fontSize="small" />
      </Box>
    )
  }

  return (
    <Box className="port" style={{ backgroundColor: props.port.properties.linkColor }}>
      <ExpandMoreIcon fontSize="small" />
    </Box>
  )
}

export default PortCustom
