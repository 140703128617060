import Loading from 'atoms/Loading'
import { lazy, Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import IntegrationOnboardingContextWrapper from './IntegrationOnboardingContext'
import IntegrationOnboardDashboard from './IntegrationOnboardingDashboard'
import TemplateOnboardIndex from './TemplateOnboard/TemplateOnboardIndex'
import TemplateInstallationsIndex from './Installation/TemplateInstallationsIndex'

export const INTEGRATION_ONBOARDING_ROUTES = {
  INDEX: '/integration/onboarding',
}

const TemplateBuilderIndex = lazy(() => import('./TemplateBuilder/TemplateBuilderIndex'))

function IntegrationOnboardIndex() {
  const { path } = useRouteMatch()

  return (
    <IntegrationOnboardingContextWrapper>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={path}>
            <IntegrationOnboardDashboard />
          </Route>
          <Route path={`${path}/template/:templateId/onboard`}>
            <TemplateOnboardIndex />
          </Route>
          <Route path={`${path}/template/:templateId/builder`}>
            <TemplateBuilderIndex />
          </Route>
          <Route path={`${path}/template/:templateId/installations`}>
            <TemplateInstallationsIndex />
          </Route>
        </Switch>
      </Suspense>
    </IntegrationOnboardingContextWrapper>
  )
}

export default IntegrationOnboardIndex
