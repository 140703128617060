import { useState } from 'react'
import { Box } from '@mui/material'
import { FlowChartWithState } from '@mrblenny/react-flow-chart'
import ChartBuilder from './builders/chart'
import { HandleOpenModal, MessageRouteFlowProps } from './types'
import DiffModal from './atoms/DiffModal'

// Custom Components
import NodeCustom from './atoms/Node'
import PortCustom from './atoms/Port'
import LinkCustom from './atoms/Link'

import './index.css'

function MessageRouteFlowDraw({ history, route, failures }: MessageRouteFlowProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [userScroll, setUserScroll] = useState<{ x: number; y: number }>()
  const [diffHeaders, setDiffHeaders] = useState<{
    headers: Record<any, any>
    prevHeaders: Record<any, any>
  }>({
    headers: {},
    prevHeaders: {},
  })

  const handleOpenModal: HandleOpenModal = ({ headers, prevHeaders }) => {
    setDiffHeaders({
      headers,
      prevHeaders,
    })

    setModalOpen(true)

    setUserScroll({
      x: window.scrollX,
      y: window.scrollY,
    })
  }

  const handleCloseModal = () => {
    setModalOpen(false)

    setImmediate(() => window.scroll(userScroll?.x ?? 0, userScroll?.y ?? 0))
  }

  const parseRoute = () => {
    const routeSteps = route?.definition?.steps ?? []

    const chartConfig = new ChartBuilder()
      .createNodes({ routeSteps })
      .feedHeaders({ history })
      .feedPreviousHeaders()
      .feedFailures({ failures })
      .feedPorts()
      .feedLinks({ failures })
      .createChildrenNodes().chart

    return chartConfig
  }

  const initialValue = parseRoute()

  if (!Object.values(initialValue.nodes).some(n => n.properties.headers)) return <Box />

  return (
    <>
      <DiffModal
        onClose={handleCloseModal}
        headers={diffHeaders.headers}
        prevHeaders={diffHeaders.prevHeaders}
        open={modalOpen}
      />

      <FlowChartWithState
        initialValue={initialValue}
        config={{
          readonly: true,
          zoom: {
            pan: {
              disabled: true,
            },
            wheel: {
              disabled: true,
            },
            zoomIn: {
              disabled: true,
            },
            zoomOut: {
              disabled: true,
            },
          },
          smartRouting: true,
        }}
        Components={{
          NodeInner: NodeCustom({ handleOpenModal }),
          Port: PortCustom,
          Link: LinkCustom,
        }}
      />
    </>
  )
}

export default MessageRouteFlowDraw
