import httpSchema from './http/schema'
import httpUiSchema from './http/uiSchema'
import sftpSchema from './sftp/schema'
import sftpUiSchema from './sftp/uiSchema'
import loadsmartApiSchema from './loadsmart-api/schema'
import loadsmartUiSchema from './loadsmart-api/uiSchema'
import webhookSchema from './webhook/schema'
import webhookUiSchema from './webhook/uiSchema'
import ftpUiSchema from './ftp/uiSchema'
import ftpSchema from './ftp/schema'

const schemas: any = {
  http: {
    model: httpSchema,
    ui: httpUiSchema,
  },
  sftp: {
    model: sftpSchema,
    ui: sftpUiSchema,
  },
  'loadsmart-api': {
    model: loadsmartApiSchema,
    ui: loadsmartUiSchema,
  },
  webhook: {
    model: webhookSchema,
    ui: webhookUiSchema,
  },
  ftp: {
    model: ftpSchema,
    ui: ftpUiSchema,
  },
}

export default schemas
