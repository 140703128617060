/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react'
import clsx from 'clsx'
import { isNil } from '@loadsmart/utils-object'
import {
  SingleDatePicker,
  DateRangePicker as RDDateRangePicker,
  DateRangePickerShape,
  SingleDatePickerShape,
} from 'react-dates'

import moment from 'moment'
import EventLike from '../../common/types/EventLike'
import ColorScheme from '../../common/types/ColorScheme'
import Status from '../../common/types/Status'
import getID from '../../common/helpers/getID'

import 'react-dates/initialize'

import 'react-dates/lib/css/_datepicker.css'
import './DatePicker.css'

export type DateRangePickerProps = Omit<
  DateRangePickerShape,
  'onDatesChange' | 'focusedInput' | 'onFocusChange'
> & {
  className?: string
  id?: string
  name: string
  onChange?: (event: EventLike<{ startDate: any; endDate: any }>) => void
  scheme?: ColorScheme
  status?: Status
}

function isOutsideRange() {
  return false
}

export function DateRangePicker({
  className,
  disabled,
  startDate,
  endDate,
  scheme,
  id = getID(),
  name,
  onChange,
  status,
  ...props
}: DateRangePickerProps) {
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null)

  const handleChange = useCallback(
    ({ startDate, endDate }) => {
      if (!onChange) {
        return
      }

      onChange({ target: { id, name, value: { startDate, endDate } } })
    },
    [id, name, onChange]
  )

  return (
    <div
      className={clsx(
        'rg-date-range-picker text-sm',
        {
          'cursor-not-allowed opacity-40': disabled,
        },
        {
          '-dark': scheme === 'dark',
          '-light': scheme === 'light',
          '-danger': status === Status.Danger,
        },
        className
      )}
    >
      <RDDateRangePicker
        displayFormat="MM/DD/YYYY"
        weekDayFormat="ddd"
        {...(props as DateRangePickerShape)}
        hideKeyboardShortcutsPanel
        showDefaultInputIcon
        small
        disabled={disabled}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={handleChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        inputIconPosition="after"
        isOutsideRange={isOutsideRange}
        minimumNights={0}
        numberOfMonths={2}
        showClearDates={!isNil(startDate) || !isNil(endDate)}
        isDayHighlighted={day => day.dayOfYear() === moment().dayOfYear()}
      />
    </div>
  )
}

DateRangePicker.defaultProps = {
  scheme: 'light',
  status: Status.Neutral,
  startDateId: getID(),
  endDateId: getID(),
}

export type DatePickerProps = Partial<SingleDatePickerShape> & {
  className?: string
  id?: string
  name: string
  date: any
  disabled?: boolean
  onChange?: (event: EventLike<any>) => void
  scheme?: ColorScheme
  status?: Status
}

export function DatePicker({
  className,
  date,
  disabled,
  scheme,
  id = getID(),
  name,
  onChange,
  status,
  ...props
}: DatePickerProps) {
  const [focused, setFocused] = useState(false)

  const handleChange = useCallback(
    date => {
      if (!onChange) {
        return
      }

      onChange({ target: { id, name, value: date } })
    },
    [id, name, onChange]
  )

  const handleFocusChange = useCallback(({ focused }) => setFocused(focused), [])

  return (
    <div
      className={clsx(
        'rg-date-picker text-sm',
        {
          'cursor-not-allowed opacity-40': disabled,
        },
        {
          '-dark': scheme === 'dark',
          '-light': scheme === 'light',
          '-danger': status === Status.Danger,
        },
        className
      )}
    >
      <SingleDatePicker
        id={id}
        displayFormat="MM/DD/YYYY"
        weekDayFormat="ddd"
        date={date}
        {...props}
        hideKeyboardShortcutsPanel
        showDefaultInputIcon
        small
        disabled={disabled}
        onDateChange={handleChange}
        focused={focused}
        onFocusChange={handleFocusChange}
        inputIconPosition="after"
        isOutsideRange={isOutsideRange}
        numberOfMonths={2}
        showClearDate={!isNil(date)}
      />
    </div>
  )
}

DatePicker.defaultProps = {
  scheme: 'light',
  status: Status.Neutral,
}
