import { ControlProps } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Layout } from '@loadsmart/loadsmart-ui'
import { Button, FormLabel } from '@mui/material'
import { UploadFile, RemoveCircle, Download } from '@mui/icons-material'
import { createRef, useEffect, useState } from 'react'
import { CustomRendererMetadata } from '../abstraction'
import FileViewer from 'molecules/FileViewer/FileViewer'

export interface UploadedFile {
  encoded_data_url: string
  content_type: string
  name: string
}

export const FILE_FIELD_RENDERER_META: CustomRendererMetadata = {
  type: 'FileField',
  label: 'File Field',
}

export const toBase64 = async (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = loaded => {
      resolve(loaded.target?.result)
    }
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })

function FileFieldControl(props: ControlProps) {
  const fileInputRef = createRef<HTMLInputElement>()
  const [data, setData] = useState<UploadedFile>()
  const [blob, setBlob] = useState<Blob>()
  const [rawContent, setRawContent] = useState<string>()

  const handleFile = async (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const base64: any = await toBase64(file)
      const uploadedFile = {
        encoded_data_url: base64,
        name: file.name,
        content_type: file.type,
      }
      setData(uploadedFile)
      props.handleChange(props.path, uploadedFile)
    }
  }

  const handleDownload = () => {
    if (blob && data) {
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = data.name
      link.click()
    }
  }

  const handleRemove = () => {
    if (data) {
      setData(undefined)
      props.handleChange(props.path, undefined)
    }
  }

  useEffect(() => {
    // Load values into local context
    if (props.data && fileInputRef.current) {
      setData({
        encoded_data_url: props.data.encoded_data_url,
        name: props.data.name,
        content_type: props.data.content_type,
      })
    }
  }, [props.data, fileInputRef.current])

  useEffect(() => {
    // Load values from local context to input field
    if (fileInputRef.current && data) {
      const file = new File([data.encoded_data_url], data.name, { type: data.content_type })
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      fileInputRef.current.files = dataTransfer.files

      const binaryString = window.atob(data.encoded_data_url.split(',')[1])
      const binaryLen = binaryString.length
      const bytes = new Uint8Array(binaryLen)
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      const blob = new Blob([bytes], { type: data.content_type })
      setBlob(blob)
    }
  }, [data, fileInputRef.current])

  useEffect(() => {
    if (!blob) return
    async function parseToRaw(blob: Blob) {
      const content = await blob.text()
      setRawContent(content)
    }
    parseToRaw(blob)
  }, [blob])

  if (!props.visible) {
    return null
  }

  return (
    <Layout.Stack space="s" align="flex-start" key={props.id} className="w-full">
      <FormLabel>{props.label}</FormLabel>
      <Layout.Group align="center" className="w-full">
        <Layout.Stack space="m" className="w-full">
          <input
            key={props.id}
            id={`contained-button-file-${props.id}`}
            ref={fileInputRef}
            accept="*"
            type="file"
            onChange={handleFile}
            style={{
              display: 'none',
            }}
          />

          <Layout.Group>
            <label htmlFor={`contained-button-file-${props.id}`}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                size="small"
                startIcon={<UploadFile />}
              >
                Upload
              </Button>
            </label>

            {data?.encoded_data_url ? (
              <Button
                variant="contained"
                color="error"
                component="span"
                size="small"
                onClick={handleRemove}
                startIcon={<RemoveCircle />}
              >
                Remove
              </Button>
            ) : null}

            {blob && data ? (
              <Button
                variant="contained"
                color="secondary"
                component="span"
                size="small"
                onClick={handleDownload}
                startIcon={<Download />}
              >
                Download
              </Button>
            ) : null}
          </Layout.Group>

          {data && rawContent && blob && (
            <FileViewer
              content={rawContent}
              contentType={data?.content_type}
              showOptions={false}
              options={{}}
            />
          )}
        </Layout.Stack>
      </Layout.Group>
    </Layout.Stack>
  )
}

export default withJsonFormsControlProps(FileFieldControl)
