// General imports, like internal and external libraries
import Loading from 'atoms/Loading'
import { Suspense, useEffect } from 'react'
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router'

import './index.css'

// Containers (Every page of our module)
import RoutesList from './containers/RouteList'
import RoutesDetail from './containers/RouteDetail'
import { readRouteDetails } from 'services/kraken-core/routes/routes.service'
import isEmpty from 'lodash.isempty'
import { Layout, LoadingDots, Tag, Text } from '@loadsmart/loadsmart-ui'

const RouteDetailFromCode = () => {
  const history = useHistory()
  const { code } = useParams<{ code: string }>()

  useEffect(() => {
    if (!isEmpty(code)) {
      readRouteDetails(code).then(([error, response]) => {
        if (response) {
          history.push(`/routes/${response?.data.id}`)
          // window.location.href = `/routes/${response?.data.id}`
        }
      })
    }
  }, [])

  return (
    <Layout.Group>
      <Text>
        Forwarding to <Tag variant="accent">{code}</Tag>
      </Text>
      <LoadingDots />
    </Layout.Group>
  )
}

const TradingPartnerIndex = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={path}>
          <RoutesList />
        </Route>
        <Route path={`${path}/:id`} exact>
          <RoutesDetail />
        </Route>
        <Route path={`${path}/code/:code`} exact>
          <RouteDetailFromCode />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default TradingPartnerIndex
