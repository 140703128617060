import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import { Layout, Tag, Text } from '@loadsmart/loadsmart-ui'
import { DetailTabsProps } from '../types'

const Tags = ({ tags = [] }: { tags?: string[] }) => (
  <div className="flex flex-row">
    {Array.from(tags).map(t => (
      <Tag key={t} className="mr-2" size="large" variant="default">
        <span className="uppercase">{t}</span>
      </Tag>
    ))}
  </div>
)

const Details = ({ tradingPartner }: DetailTabsProps) => (
  <Layout.Stack className="p-2">
    <Layout.Group justify="space-between" align="center">
      <Layout.Stack>
        <Text variant="caption-bold" color="color-neutral">
          ID
        </Text>
        <Text variant="heading-sm-bold">{tradingPartner.id}</Text>
      </Layout.Stack>

      <Layout.Stack>
        <Text variant="caption-bold" color="color-neutral">
          Created at
        </Text>
        <Text variant="heading-sm-bold">
          {LSDate(tradingPartner.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
        </Text>
      </Layout.Stack>
      <Layout.Stack>
        <Text variant="caption-bold" color="color-neutral">
          Updated at
        </Text>
        <Text variant="heading-sm-bold">
          {LSDate(tradingPartner.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
        </Text>
      </Layout.Stack>

      <Layout.Stack>
        <Text variant="caption-bold" color="color-neutral">
          Tags
        </Text>
        <Tags tags={tradingPartner.metadata?.tags} />
      </Layout.Stack>
    </Layout.Group>
  </Layout.Stack>
)

export default Details
