import { Text } from '@loadsmart/loadsmart-ui'
import ExternalLink from 'atoms/ExternalLink'
import { ExpcetedReplyRelatedMessageProps } from '../types'

function ExpcetedReplyRelatedMessage({ relatedMessage }: ExpcetedReplyRelatedMessageProps) {
  const direction = relatedMessage?.direction ?? ''
  const party = relatedMessage?.party ?? { name: '' }
  const transactionType = relatedMessage?.transaction_type.description

  function NonExistentRelatedMessage() {
    return <span />
  }

  function ExistentRelatedMessage() {
    return (
      <ExternalLink className="" href={`/messages/${relatedMessage?.id}`} target="blank">
        <Text id="related-message" data-testid="related-message">
          {String(`${party.name} - ${direction} - ${transactionType}`)}
        </Text>
      </ExternalLink>
    )
  }

  return relatedMessage ? <ExistentRelatedMessage /> : <NonExistentRelatedMessage />
}

export default ExpcetedReplyRelatedMessage
