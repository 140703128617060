const sftpUiSchema = {
  type: 'Categorization',
  elements: [
    {
      type: 'Category',
      label: 'General',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/hostname',
                },
                {
                  type: 'Control',
                  scope: '#/properties/port',
                },
                {
                  type: 'Control',
                  scope: '#/properties/operation',
                },
              ],
            },
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/timeout',
                },
                {
                  type: 'Control',
                  scope: '#/properties/banner_timeout',
                },
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/confirm_size_after_delivery',
                },
                {
                  type: 'Control',
                  scope: '#/properties/create_new_path',
                },
              ],
            },
            {
              type: 'Control',
              scope: '#/properties/open',
            },
          ],
        },
      ],
    },
    {
      type: 'Category',
      label: 'Authentication',
      elements: [
        {
          type: 'Group',
          elements: [
            {
              type: 'Control',
              scope: '#/properties/credentials/properties/storage',
            },
            {
              type: 'HorizontalLayout',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/credentials/properties/username',
                },
                {
                  type: 'Control',
                  scope: '#/properties/credentials/properties/password',
                },
              ],
            },
            {
              type: 'Group',
              label: 'Private Key',
              elements: [
                {
                  type: 'Control',
                  scope: '#/properties/credentials/properties/private_key/properties/type',
                },
                {
                  type: 'Control',
                  scope: '#/properties/credentials/properties/private_key/properties/passphrase',
                },
                {
                  type: 'Control',
                  scope: '#/properties/credentials/properties/private_key/properties/content',
                  options: {
                    multi: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

export default sftpUiSchema
