const { X12Parser } = require('node-x12')

export const EDI_X12_LANGUAGE_ID = 'edi-x12'
export const EDI_X12_EXTENSIONS: Array<string> = ['.edi', '.x12']
export const EDI_X12_ALIASES: Array<string> = ['EDI', 'X12']
export const EDI_X12_MIME_TYPES: Array<string> = ['application/EDI-X12']
export const EDI_X12_DISPLAY_NAME = 'EDI X12'

export function registerEDIX12Languague(monacoInstance: any) {
  const currentLanguages: Array<any> = monacoInstance.languages.getLanguages()
  if (currentLanguages.find(v => v.id === EDI_X12_LANGUAGE_ID)) {
    return
  }

  monacoInstance?.languages.register({
    id: EDI_X12_LANGUAGE_ID,
    extensions: EDI_X12_EXTENSIONS,
    aliases: EDI_X12_ALIASES,
    mimetypes: EDI_X12_MIME_TYPES,
  })

  monacoInstance.languages.setMonarchTokensProvider(EDI_X12_LANGUAGE_ID, {
    // Set defaultToken to invalid to see what you do not tokenize yet
    // defaultToken: 'invalid',
    keywords: [],
    typeKeywords: [],
    operators: [],
    symbols: [],
    escapes: [],

    // The main tokenizer for our languages
    tokenizer: {
      root: [
        // identifiers and keywords
        [/^[A-Z][\w]*/, 'type.identifier'], // to show class names nicely

        [/[^\\*~]+/, 'string'],
      ],

      comment: [],

      string: [],

      whitespace: [],
    },
  })

  monacoInstance?.languages.registerDocumentFormattingEditProvider(EDI_X12_LANGUAGE_ID, {
    displayName: EDI_X12_DISPLAY_NAME,
    provideDocumentFormattingEdits: (model: any, options: any, token: any): any => {
      let content = model.getValue()
      const parser = new X12Parser(false)
      const interchange = parser.parse(model.getValue())
      const { segmentTerminator } = interchange

      if (segmentTerminator !== '\n') {
        content = content.replaceAll(segmentTerminator, `${segmentTerminator}\n`)
      }

      return [
        {
          text: content,
          range: {
            endColumn: Number.MAX_SAFE_INTEGER,
            endLineNumber: Number.MAX_SAFE_INTEGER,
            startColumn: 1,
            startLineNumber: 1,
          },
        },
      ]
    },
  })
}
