import { ArrowBack } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useHistory } from 'react-router-dom'

export interface ReturnBackButtonProps {
  onClick?: () => void
}

export default function ReturnBackButton(props: ReturnBackButtonProps) {
  const history = useHistory()

  function defaultReturnBackAction() {
    if (history && history.length > 0) history.goBack()
  }

  return (
    <IconButton onClick={props.onClick ?? defaultReturnBackAction} data-testid="return-back-button">
      <ArrowBack className="cursor-pointer" />
    </IconButton>
  )
}
