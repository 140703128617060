import { IntegrationTemplate } from 'common/types/kraken-core/IntegrationTemplate'
import { useEffect, useState } from 'react'
import JsonForm from 'molecules/JsonForm/JsonForm'
import { Banner, Breadcrumbs, Button, Layout, Link, Modal, Text } from '@loadsmart/loadsmart-ui'
import { useModal } from 'atoms/Modal'
import Loading from 'atoms/Loading'
import TemplateActions from '../TemplateActions'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import TemplateOwnershipTags from '../Common/IntegrationTemplateOwnershipTags'
import { useQueryParams } from 'hooks/useQueryParams'
import { toast } from 'react-toastify'
import { useTemplateOnboardContext } from './TemplateOnboardContext'

const DOUBLE_CHECK_MESSAGE = 'Are you sure you want to start the onboarding process?'
const FOLLOW_ONBOARDING_MESSAGE = 'Follow the message resource for more details'

export interface TemplateOboardingInstallation {
  installationId: string
  encodedParameters: string
  decodedParameters: string
}

function TemplateOnboard() {
  const context = useTemplateOnboardContext()
  const queryParams = useQueryParams()
  const [installation, setInstallation] = useState<TemplateOboardingInstallation>()
  const [generatedData, setGeneratedData] = useState<any>({})
  const [confirmationWasConsented, setConfirmationConsent] = useState(false)
  const {
    id: confirmationModalId,
    open: confirmationModalIsOpen,
    toggle: toggleConfirmationModal,
  } = useModal({
    open: false,
    onOpen: () => {},
    onClose: () => {},
  })
  const topNavigationContext = useTopNavigationContext()

  const askForConfirmation = () => {
    toggleConfirmationModal()
  }

  const resetForNewOnboarding = () => {
    setConfirmationConsent(false)
    setGeneratedData({})
    if (confirmationModalIsOpen) toggleConfirmationModal()
    if (context?.resetForNewOnboarding) context.resetForNewOnboarding()
  }

  const confirm = async () => {
    if (context && context.confirmOnboard) {
      await context.confirmOnboard(generatedData)
    }
  }

  const clear = () => {
    setGeneratedData({})
  }

  function FallbackComponent() {
    return (
      <>
        {context?.isLoading && <Loading />}
        {!context?.isLoading && <>Some error happened</>}
      </>
    )
  }

  function ConfirmationModal() {
    return (
      <Modal
        open={confirmationModalIsOpen}
        onOverlayClick={() => {
          setConfirmationConsent(false)
          toggleConfirmationModal()
        }}
      >
        <Modal.Header title="Onboarding" />
        <Layout.Stack>
          {!confirmationWasConsented && !context?.isLoading && (
            <Text variant="body">{DOUBLE_CHECK_MESSAGE}</Text>
          )}

          {confirmationWasConsented && context?.confirmationResponse?.success && (
            <Layout.Stack>
              <Banner
                dismissible={false}
                variant="success"
                scale="large"
                description={context.confirmationResponse?.message}
              />
            </Layout.Stack>
          )}

          {confirmationWasConsented &&
            context?.confirmationResponse?.success == false &&
            !context.isLoading && (
              <Layout.Stack>
                <Banner
                  dismissible={false}
                  variant="danger"
                  scale="large"
                  description={context?.confirmationResponse?.message}
                />
              </Layout.Stack>
            )}

          {!context?.isLoading &&
            confirmationWasConsented &&
            context?.confirmationResponse?.templateID && (
              <div className="flex flex-col items-center">
                <div className="flex">
                  <Link href={`/onboarding/template/${context.confirmationResponse?.templateID}`}>
                    {FOLLOW_ONBOARDING_MESSAGE}
                  </Link>
                </div>
              </div>
            )}

          <Layout.Group align="center" justify="center">
            <Button
              onClick={() => {
                setConfirmationConsent(false)
                toggleConfirmationModal()
              }}
            >
              Cancel
            </Button>
            {confirmationWasConsented && context?.confirmationResponse?.success && (
              <Button variant="primary" onClick={() => resetForNewOnboarding()}>
                New onboarding
              </Button>
            )}

            {!confirmationWasConsented && (
              <Button
                variant="primary"
                disabled={confirmationWasConsented}
                onClick={() => {
                  setConfirmationConsent(true)
                  confirm()
                }}
              >
                Confirm
              </Button>
            )}
          </Layout.Group>
          {context?.isLoading && <Loading />}
        </Layout.Stack>
      </Modal>
    )
  }

  const OnboardForm = (template: IntegrationTemplate) => {
    const schema = template.schema || {}
    const uiSchema = template.ui_schema || undefined

    return (
      <div className="w-full">
        <JsonForm
          key="json-form"
          schema={schema}
          data={installation?.decodedParameters || generatedData}
          uiSchema={uiSchema || undefined}
          onChange={({ data }) => {
            setGeneratedData(data)
            if (context.view) {
              context.updateView({
                ...context.view,
                data,
              })
            }
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group className="w-full" align="center">
          <Breadcrumbs
            entries={[
              {
                label: 'Integration Onboarding',
                active: true,
                url: '/integration/onboarding',
              },
              {
                label: 'Onboarding',
                active: false,
              },
              {
                label: '',
                active: true,
              },
            ]}
          />
          {context?.template ? <TemplateOwnershipTags template={context.template} /> : null}
          <Text variant="heading-md">{context?.template?.key || ''}</Text>
        </Layout.Group>
      ),
    })

    if (!context?.template?.id) return

    const dataFromView = context.view?.data
    setGeneratedData(dataFromView)

    // TODO: To isolate this logic
    if (queryParams.has('installationId')) {
      try {
        const installationId = queryParams.get('installationId')
        const encodedInstallationParameters = queryParams.get('encodedInstallationParameters')

        if (encodedInstallationParameters) {
          const decodedInstallationParameters = JSON.parse(atob(encodedInstallationParameters))
          if (decodedInstallationParameters && installationId) {
            setInstallation({
              installationId,
              decodedParameters: decodedInstallationParameters,
              encodedParameters: encodedInstallationParameters,
            })
          }
        }
      } catch (error) {
        toast.error('Failure when loading installation parameters')
      }
    }
  }, [context.view?.id, context.template?.id])

  return (
    <>
      <ConfirmationModal />

      {!context || !context.template ? <FallbackComponent /> : null}

      {context.template ? (
        <>
          {context?.isLoading && <Loading className="pt-4" />}
          {!context?.isLoading && (
            <>
              <div className="flex flex-col w-full">
                {installation ? (
                  <Banner
                    variant="warning"
                    scale="large"
                    title={'Onboarding from re-installation'}
                    dismissible={false}
                    description={`
                You're about to update an already set-up installation.
                The settings displayed below are the ones used during the initial setup.
                If you modify these settings and proceed with the update,
                the current setup will be replaced with the updated one.
                `}
                  />
                ) : null}

                <div className="flex w-full pt-4">{OnboardForm(context.template)}</div>
                {!context?.isLoading && (
                  <TemplateActions
                    template={context.template}
                    clearFunction={clear}
                    generatedData={generatedData}
                    setGeneratedData={setGeneratedData}
                    askForConfirmationFunction={askForConfirmation}
                  />
                )}
              </div>
            </>
          )}
        </>
      ) : null}
    </>
  )
}

export default TemplateOnboard
