// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Modal, Paper } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Loading from 'atoms/Loading'
import { toast } from 'atoms/Toast'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMMSS_AMPM } from 'common/Date.helpers'
import LSString from 'common/String.helpers'
import { MessageRead, MessageHistoryRead, MessageHeaders } from 'common/types/kraken-core/Message'
import { readMessageHistory } from 'services/kraken-core/messages/messages.service'
import useCancelToken from 'hooks/useCancelToken'
import {
  Button,
  Section,
  Switch,
  Accordion as LSAccordion,
  Layout,
  Text,
  Tag,
} from '@loadsmart/loadsmart-ui'
import axios from 'axios'
import FileViewer, { FileViewTheme } from 'molecules/FileViewer/FileViewer'
import { ErrorBoundary } from 'react-error-boundary'
import { onRevertMessageHistory } from 'pages/Messages/api'
import MessageHeadersTable from './MessageHeadersTable'
import { useMessagesDashboardViewContext } from 'pages/Messages/MessagesDashboardViewContext'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import MessageStatusTag from 'pages/Messages/common/MessageStatusTag'

function extractPayloadContentTypeFromHttpHeaders(httpHeaders: any): string {
  const defaultMimeTypeIfNone = 'txt'
  const octetStreamMimeType = 'application/octet-stream'
  const ediX12MimeType = 'application/EDI-X12'
  let contentTypeFromHeaders = httpHeaders['content-type'] ?? defaultMimeTypeIfNone
  contentTypeFromHeaders =
    contentTypeFromHeaders === octetStreamMimeType ? ediX12MimeType : contentTypeFromHeaders

  // Get the first value from content type string
  // Example: 'application/xml; charset=utf-8' must be considered as 'application/xml'
  return contentTypeFromHeaders.split(';').find(Boolean)
}

export interface MessageHistoryProps {
  message: MessageRead
}

interface BodyContentReaderState {
  content?: string
  contentType?: string
  url?: string
  open: boolean
}

interface SimpleHistoryTabState {
  first: MessageHistoryRead
  last: MessageHistoryRead
}

const SimpleHistoryViewSection = ({
  history,
  bodyContent,
  title,
}: {
  history: MessageHistoryRead
  bodyContent: BodyContentReaderState
  title: string
}) => (
  <Section>
    <Section.Title className="flex justify-between">
      <Layout.Box>{title}</Layout.Box>
      <Layout.Box>{LSDate(history.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMMSS_AMPM)}</Layout.Box>
    </Section.Title>
    <Layout.Stack className="px-4">
      <LSAccordion>
        <LSAccordion.Toggle>Headers</LSAccordion.Toggle>
        <LSAccordion.Body>
          <MessageHeadersTable message={(history as unknown) as MessageRead} />
        </LSAccordion.Body>
      </LSAccordion>
      <LSAccordion>
        <LSAccordion.Toggle>Body</LSAccordion.Toggle>
        <LSAccordion.Body>
          {!bodyContent && <Loading className="justify-center mt-4" />}

          {bodyContent && (
            <FileViewer
              options={{
                readonly: false,
                heigth: 500,
                theme: FileViewTheme.DEFAULT,
              }}
              content={bodyContent.content || ''}
              contentType={bodyContent.contentType || 'txt'}
              url={bodyContent.url}
            />
          )}
        </LSAccordion.Body>
      </LSAccordion>
    </Layout.Stack>
  </Section>
)

const SimpleHistoryView = (props: Partial<SimpleHistoryTabState> = {}) => {
  const { first, last } = props
  const [bodyContent, setBodyContent] = useState<{
    first?: Omit<BodyContentReaderState, 'open'>
    last?: Omit<BodyContentReaderState, 'open'>
  }>()

  const loadBodyContent = async (type: 'first' | 'last', url: string) => {
    axios({
      method: 'get',
      url,
      responseType: 'blob',
    })
      .then(async response => {
        const blob = response.data
        if (!blob) {
          setBodyContent(prevState => ({
            ...prevState,
            [type]: {
              url,
              content: '',
            },
          }))
          return
        }
        const stringValue = await blob.text()
        setBodyContent(prevState => ({
          ...prevState,
          [type]: {
            url,
            content: stringValue,
            contentType: extractPayloadContentTypeFromHttpHeaders(response.headers),
          },
        }))
      })
      .catch(error => {
        toast.error(`Something went wrong: ${error}`)
      })
  }

  useEffect(() => {
    if (first?.storage_body_url) loadBodyContent('first', first.storage_body_url)
    if (last?.storage_body_url) loadBodyContent('last', last.storage_body_url)
  }, [])

  if (!first || !last) {
    return <Text>There is no history for this message</Text>
  }

  return (
    <>
      <SimpleHistoryViewSection
        history={first}
        bodyContent={bodyContent?.first as BodyContentReaderState}
        title="Input data"
      />

      {last.headers?.[MessageHeaders.LAST_STEP] && (
        <SimpleHistoryViewSection
          history={last}
          bodyContent={bodyContent?.last as BodyContentReaderState}
          title={`Actual data (Last step: ${LSString.normalizeCode(
            last.headers[MessageHeaders.LAST_STEP]
          )})`}
        />
      )}
    </>
  )
}

function ModalFileViewerFallbackComponent({ error, resetErrorBoundary }: any) {
  return (
    <Paper className="p-4 my-auto ml-2 mr-2 flex flex-col text-xs" style={{ width: '90%' }}>
      Error on reading content: {String(error)}
    </Paper>
  )
}

const MessageHistoryView = ({ message }: MessageHistoryProps) => {
  const context = useMessagesDashboardViewContext()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [history, setHistory] = useState<MessageHistoryRead[]>([])
  const [body, setBody] = useState<BodyContentReaderState>()
  const [isDetailedView, setIsDetailedView] = useState<boolean>(true)
  const [simpleHistoryState, setSimpleHistoryState] = useState<Partial<SimpleHistoryTabState>>({})

  const { getSource, clearSource, cancelPending, isCancel } = useCancelToken()

  useEffect(() => {
    const source = getSource()

    ;(async () => {
      setIsLoading(true)
      const [error, response] = await readMessageHistory(message.id, {
        cancelToken: source.token,
      })

      clearSource()

      if (error && !isCancel) toast.error(`Something went wrong: ${error}`)

      const data = response?.data || []

      setSimpleHistoryState({
        first: data[data.length - 1],
        last: data[0],
      })
      setHistory(data)
      setIsLoading(false)
    })()

    return () => cancelPending()
  }, [message.id])

  const handleViewBody = (m: MessageHistoryRead) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    analytics.event({
      category: AnalyticsEvent.MessageDetailHistoryViewBody,
      action: AnalyticsEventTrigger.click,
    })
    e.stopPropagation()
    setBody({
      open: true,
      content: undefined,
      contentType: undefined,
    })
    ;(async () => {
      const url = m.storage_body_url
      axios({
        method: 'get',
        url,
        responseType: 'blob',
      })
        .then(response => {
          const blob = response.data
          if (!blob) {
            throw Error("Response doesn't have data")
          }
          setBody({
            url,
            contentType: extractPayloadContentTypeFromHttpHeaders(response.headers),
            open: true,
          })
          return blob.text()
        })
        .then(stringValue => {
          setBody(s => ({
            ...s,
            content: stringValue,
            open: true,
          }))
        })
        .catch(error => {
          setBody({ open: false })
          toast.error(`Something went wrong: ${error}`)
        })
    })()
  }

  const handleRevert = (m: MessageHistoryRead) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    analytics.event({
      category: AnalyticsEvent.MessageDetailHistoryRevert,
      action: AnalyticsEventTrigger.click,
    })
    e.stopPropagation()
    ;(async () => {
      try {
        const params = {
          messageId: message.id,
          historyId: m.id,
        }
        analytics.event({
          action: 'click',
          category: AnalyticsEvent.MessageDetailHistoryRevert,
          params,
        })
        await onRevertMessageHistory(params)

        toast.success('Reverted with success')
        context?.readMessageDetails(message.id)
      } catch (error) {
        toast.error(`Something went wrong: ${error}`)
      }
    })()
  }

  return (
    <>
      <Modal
        id="diff-data-modal"
        open={!!body?.open}
        onClose={() => setBody({ open: false })}
        className="flex tems-center justify-center"
      >
        <Paper className="p-4 my-auto ml-2 mr-2 flex flex-col text-xs" style={{ width: '90%' }}>
          <ErrorBoundary FallbackComponent={ModalFileViewerFallbackComponent}>
            {!body?.content && <Loading className="justify-center mt-4" />}

            {body?.content && (
              <FileViewer
                options={{
                  readonly: false,
                  heigth: 500,
                }}
                content={body.content}
                contentType={body.contentType || 'txt'}
                url={body.url}
              />
            )}
          </ErrorBoundary>
        </Paper>
      </Modal>

      <Layout.Stack space="none">
        {isLoading && <Loading className="justify-center mt-8" />}

        {!isLoading && (
          <Layout.Sidebar className="items-center px-4">
            <Switch
              data-testid="switch-icon-active"
              className="my-4"
              onToggle={() => setIsDetailedView(prev => !prev)}
              active={isDetailedView}
            />
            <Text className="pl-4">Detailed view</Text>
          </Layout.Sidebar>
        )}

        {!isDetailedView && !isLoading && history.length && (
          <SimpleHistoryView {...simpleHistoryState} />
        )}

        {isDetailedView &&
          !isLoading &&
          history?.map(item => (
            <Accordion key={item.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Layout.Group justify="space-between" className="flex-1">
                  <Layout.Group space="s" align="center">
                    <Tag>{LSDate(item.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMMSS_AMPM)}</Tag>
                    <Text className="flex-1">
                      {LSString.normalizeCode(item.headers[MessageHeaders.LAST_STEP] ?? '')}
                    </Text>
                  </Layout.Group>

                  <Layout.Group space="s" align="center">
                    {item.status ? <MessageStatusTag status={item.status} /> : null}

                    <Button variant="primary" scale="small" onClick={handleRevert(item)}>
                      Revert
                    </Button>

                    <Button variant="secondary" scale="small" onClick={handleViewBody(item)}>
                      View body
                    </Button>
                  </Layout.Group>
                </Layout.Group>
              </AccordionSummary>

              <AccordionDetails>
                <Layout.Box className="text-xs overflow-x-auto ">
                  <pre>{JSON.stringify(item, null, 2)}</pre>
                </Layout.Box>
              </AccordionDetails>
            </Accordion>
          ))}
      </Layout.Stack>
    </>
  )
}

export default MessageHistoryView
