import {
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  IconButton,
  Label,
  Layout,
  Link,
  LoadingDots,
  Table,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { BreadcrumbProps } from '@loadsmart/loadsmart-ui/dist/components/Breadcrumbs'
import Icon from 'atoms/Icon'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import isEmpty from 'lodash.isempty'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDeletePartnership, useSearchPartnership } from '../api'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { Partnership } from 'common/types/kraken-core/Partnership'
import PartnershipDetailComponent from './PartnershipDetail'
import { useHistory, useParams } from 'react-router-dom'
import SimplePagination from 'atoms/SimplePagination'
import ActiveStatusTag from 'atoms/ActiveStatusTag/ActiveStatusTag'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'

const PartnershipList = () => {
  useTabTitle('Partnerships')
  const history = useHistory()
  const { id } = useParams<any>()
  const [partyFilter, setPartyFilter] = useState<string>('')
  const [counterPartyFilter, setCounterPartyFilter] = useState<string>('')
  const [selectedPartnership, setSelectedPartnership] = useState<Partial<Partnership>>()
  const [selectedToDelete, setSelectedToDelete] = useState<Partnership>()
  const [page, setPage] = useState<number>(1)

  const debounedPartyFilter = useDebouncedValue<string>(partyFilter, 500)
  const debounedCounterpartyFilter = useDebouncedValue<string>(counterPartyFilter, 500)

  const { data, refetch, isLoading, isError, error, isFetching } = useSearchPartnership({
    party_name: debounedPartyFilter,
    counterparty_name: debounedCounterpartyFilter,
    page,
    page_size: 10,
  })
  const {
    mutate: deletePartnership,
    error: deleteError,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleting,
  } = useDeletePartnership()
  const [breadCrumbs] = useState<BreadcrumbProps[]>([
    {
      label: 'Partnership',
      active: true,
    },
  ])
  const topNavigationContext = useTopNavigationContext()

  const handleEdit = (partnership: Partnership) => {
    setSelectedPartnership(partnership)
    history.push(`partnership/${partnership.id}`)
  }

  const handleCreateNew = () => {
    setSelectedPartnership({})
  }

  const handleCloseDetail = () => {
    setSelectedPartnership(undefined)
    history.replace('/partnership')
    refetch()
  }

  const handleOnPickupToDelete = (partnership: Partnership) => {
    setSelectedToDelete(partnership)
  }

  const handleDelete = () => {
    if (selectedToDelete && selectedToDelete.id) deletePartnership(selectedToDelete?.id)
  }

  const getParntershipRep = (parntership: Partnership) => {
    return `${parntership.party.name} - ${parntership.counterparty.name}`
  }

  useEffect(() => {
    topNavigationContext.updateState({
      children: (
        <Layout.Group
          className="w-full"
          data-testid="header"
          align="center"
          justify="space-between"
        >
          <Layout.Group align="center" className="flex-1">
            <Layout.Box padding="none" className="flex-1">
              <Breadcrumbs entries={breadCrumbs} />
            </Layout.Box>
          </Layout.Group>
        </Layout.Group>
      ),
    })
  }, [breadCrumbs])

  useEffect(() => {
    if (isError) {
      toast.error(`Something went wrong when loading: ${error}`)
      return
    }
  }, [error, isError])

  useEffect(() => {
    if (deleteError) {
      toast.error(`Error when deleting: ${deleteError}`)
    } else if (isDeleteSuccess) {
      toast.success('Successfully deleted')
      setSelectedToDelete(undefined)
      refetch()
    }
  }, [deleteError, isDeleteSuccess])

  useEffect(() => {
    if (id) {
      setSelectedPartnership({ id })
    }
  }, [id])

  return (
    <>
      <Dialog
        open={!!selectedToDelete}
        onOverlayClick={() => setSelectedToDelete(undefined)}
        scale="small"
      >
        <Dialog.Header>Delete</Dialog.Header>
        {selectedToDelete?.id ? (
          <Dialog.Body>
            Are you sure you want to delete this partnership{' '}
            <Text variant="body-bold">{getParntershipRep(selectedToDelete)}</Text>?
          </Dialog.Body>
        ) : null}

        <Dialog.ActionConfirm disabled={isDeleting} onConfirm={() => handleDelete()} />

        <Dialog.ActionCancel onCancel={() => setSelectedToDelete(undefined)} />
      </Dialog>

      <PartnershipDetailComponent
        open={selectedPartnership ? true : false}
        onClose={handleCloseDetail}
        id={selectedPartnership?.id}
      />
      <Layout.Stack className="w-full">
        <Card data-testid="filters-card" className="w-full">
          <Card.Title>Filters</Card.Title>
          <Card.Separator />
          <Card.Body>
            <Layout.Group>
              <Layout.Stack space="s">
                <Label>Party</Label>
                <TextField
                  key="party_filter"
                  value={partyFilter}
                  onChange={e => setPartyFilter(e.target.value)}
                />
              </Layout.Stack>
              <Layout.Stack space="s">
                <Label>Counterparty</Label>
                <TextField
                  key="counterparty_filter"
                  value={counterPartyFilter}
                  onChange={e => setCounterPartyFilter(e.target.value)}
                />
              </Layout.Stack>
            </Layout.Group>
          </Card.Body>
        </Card>
        <Card className="w-full">
          <Card.Body style={{ padding: 0 }}>
            <Layout.Stack>
              <Layout.Switcher>
                <Layout.Group align="center" className="p-4">
                  {Boolean(isFetching || isLoading) && <LoadingDots />}
                </Layout.Group>

                <Layout.Group align="center" justify="flex-end" className="p-4">
                  <Button variant="primary" onClick={handleCreateNew}>
                    Create New
                  </Button>
                </Layout.Group>
              </Layout.Switcher>

              <Layout.Group className="p-0">
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell
                        style={{
                          width: '10em',
                        }}
                      >
                        ID
                      </Table.HeadCell>
                      <Table.HeadCell>Party</Table.HeadCell>
                      <Table.HeadCell>Counterparty</Table.HeadCell>
                      <Table.HeadCell>Active?</Table.HeadCell>
                      <Table.HeadCell
                        style={{
                          width: '10em',
                        }}
                      >
                        Actions
                      </Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {data?.results
                      ? data?.results.map(ts => {
                          const idAsString = ts.uuid?.slice(0, 8) || '-'
                          return (
                            <Table.Row key={ts.id}>
                              <Table.Cell>{idAsString}</Table.Cell>
                              <Table.Cell>
                                <Link href={`/trading-partner/${ts.party.id}`}>
                                  {ts.party.name}
                                </Link>
                              </Table.Cell>
                              <Table.Cell>
                                <Link href={`/trading-partner/${ts.counterparty.id}`}>
                                  {ts.counterparty.name}
                                </Link>
                              </Table.Cell>
                              <Table.Cell>
                                <ActiveStatusTag active={ts.is_active} />
                              </Table.Cell>
                              <Table.Cell>
                                <Layout.Group>
                                  <IconButton
                                    name="edit"
                                    onClick={e => {
                                      handleEdit(ts)
                                    }}
                                  >
                                    <Icon name="edit" />
                                  </IconButton>
                                  <IconButton onClick={() => handleOnPickupToDelete(ts)}>
                                    <Icon name="remove" />
                                  </IconButton>
                                </Layout.Group>
                              </Table.Cell>
                            </Table.Row>
                          )
                        })
                      : null}
                  </Table.Body>
                </Table>
                {isEmpty(data?.results) && !isLoading && (
                  <Layout.Stack className="p-2" space="l">
                    <Text variant="body-bold">No partnerships available.</Text>
                  </Layout.Stack>
                )}
              </Layout.Group>
              <SimplePagination
                className="p-2"
                currentPage={page}
                nextPage={() => setPage(c => ++c)}
                previousPage={() => setPage(c => --c)}
                canNextPage={!isEmpty(data?.next)}
                canPreviousPage={!isEmpty(data?.previous)}
                onPage={pageNumber => {
                  if (pageNumber <= page && pageNumber >= 1) {
                    setPage(pageNumber)
                  }
                }}
              />
            </Layout.Stack>
          </Card.Body>
        </Card>
      </Layout.Stack>
    </>
  )
}

export default PartnershipList
