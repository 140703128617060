import FileViewer from 'molecules/FileViewer/FileViewer'

export interface FormBuilderJsonEditorProps {
  json: object
  onChange: Function
}

const FormBuilderEditor = ({ json, onChange }: FormBuilderJsonEditorProps) => {
  return (
    <FileViewer
      content={json ? JSON.stringify(json, null, 2) : ''}
      onChange={value => onChange(value)}
      options={{
        heigth: '75vh',
      }}
      showOptions={false}
      contentType="application/json"
    />
  )
}

export default FormBuilderEditor
