import { Select } from '@loadsmart/loadsmart-ui'
import { SelectDatasourceFunction } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { GatewaySetting } from 'common/types/kraken-core/GatewaySettings'
import isEmpty from 'lodash.isempty'
import { onSearchGateways } from 'common/components/datasources/datasource'
import { useEffect, useMemo, useState } from 'react'
import { getGatewaySettings } from 'services/kraken-core/gateway_settings/gateway_settings.service'

export interface GatewaySettingsSelectorProps {
  selected?: {
    label?: string
    id?: string
  }
  onSelect: (selected: GatewaySetting) => void
  dataSources?: SelectDatasourceFunction<any>[]
}

const DATASOURCES = {
  GATEWAY_SETTINGS: [
    () => ({
      type: 'string',
      adapter: {
        getKey: (entity: GatewaySetting) => entity.id || '',
        getLabel: (entity: GatewaySetting) => entity.name || '',
      },
      fetch: onSearchGateways,
    }),
  ],
}

export default function GatewaySettingsSelector(props: GatewaySettingsSelectorProps) {
  const [selectedDataDetails, setSelectedDataDetails] = useState<GatewaySetting>()
  const [selected, setSelected] = useState<any>({
    value: props.selected?.id,
    label: props.selected?.label,
  })

  useEffect(() => {
    async function get() {
      if (!props.selected?.id) return
      const d = await getGatewaySettings(props.selected?.id)
      if (!d.error) {
        setSelectedDataDetails(d.response?.data)
      }
    }
    get()
  }, [props.selected?.id])

  useEffect(() => {
    if (selectedDataDetails) {
      setSelected((c: any) => ({
        ...c,
        value: selectedDataDetails.id,
        label: selectedDataDetails.name,
      }))
    }
  }, [selectedDataDetails])

  const MemoizedSelector = useMemo(() => {
    const isValueNotFullyLoaded = selected.label === selected.value || isEmpty(selected.label)
    return (
      <Select
        name="select_gateway"
        onChange={e => props.onSelect(e.target.value as GatewaySetting)}
        value={
          isValueNotFullyLoaded
            ? null
            : { ...selected, label: selected.label || selectedDataDetails?.name }
        }
        datasources={props.dataSources || DATASOURCES.GATEWAY_SETTINGS}
        placeholder="Select a gateway settings"
      />
    )
  }, [selected])

  return <>{MemoizedSelector}</>
}
