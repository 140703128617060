import { InstallationDetail, InstallationList } from 'common/types/kraken-core/Installation'
import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import {
  UseQueryResult,
  useQuery,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query'
import {
  deleteInstallation,
  getInstallation,
  searchInstallations,
  updateInstallation,
} from 'services/kraken-core/installations/installations.service'
import { SearchInstallationsPageRequest } from 'services/kraken-core/installations/types'

export const useSearchInstallations = (
  params: SearchInstallationsPageRequest,
  initialData?: any
): UseQueryResult<PaginatedResponse<InstallationList>> => {
  const queryFn = async (filters: SearchInstallationsPageRequest) => {
    const { response } = await searchInstallations({
      ...filters,
    })
    return response?.data || {}
  }
  const query = useQuery(['get/installations', params], () => queryFn(params), {
    keepPreviousData: true,
    initialData,
  })
  return query
}

export const useGetInstallation = (
  id: string,
  options?: any
): UseQueryResult<InstallationDetail> => {
  const queryFn = async (id: string) => {
    if (!id) return {}
    const { response } = await getInstallation(id)
    return response?.data || {}
  }
  const query = useQuery(['get/installation', id], () => queryFn(id), {
    enabled: !!id,
    ...options,
  })
  return query
}

export const useUpdateInstallation = (): UseMutationResult<
  any,
  unknown,
  InstallationDetail,
  unknown
> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (params: InstallationDetail) => {
      const { response } = await updateInstallation({ ...params })

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get/installations', 'get/installation'])
      },
    }
  )

  return mutation
}

export const useDeleteInstallation = (): UseMutationResult<any, unknown, string, unknown> => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (id: string) => {
      const { response } = await deleteInstallation(id)

      return response?.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['get/installations'])
      },
    }
  )

  return mutation
}
