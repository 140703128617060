import { createContext, useState } from 'react'

export interface TopNavigationContextState {
  children?: any
}
export interface TopNavigationContextType {
  updateState: (state: TopNavigationContextState) => void
  currentState: TopNavigationContextState
}

export const TopNavigationContext = createContext<TopNavigationContextType>({
  currentState: {},
  updateState: () => {},
})

export function TopNavigationContextWrapper({ children }: any) {
  const [state, setState] = useState<TopNavigationContextState>({})

  return (
    <TopNavigationContext.Provider
      value={{
        updateState: state => {
          setState(state)
        },
        currentState: state,
      }}
    >
      {children}
    </TopNavigationContext.Provider>
  )
}

export default TopNavigationContext
