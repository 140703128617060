import { IconButton, Layout, LoadingDots, Select } from '@loadsmart/loadsmart-ui'
import { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import Icon from 'atoms/Icon'
import { toast } from 'atoms/Toast'
import { useListProcessor } from 'common/helpers/kraken-core/useProcessors'
import { ProcessorPlugin, ProcessorPluginList } from 'common/types/kraken-core/Processors'
import { capitalize } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { getProcessor } from 'services/kraken-core/processors/processors.service'
import ProcessorItem from './ProcessorItem'

export interface ProcessorCollectionProps {
  title: string
  data: any
  onChange: (value: any) => void
}

function capitalizeProcessorName(name: string) {
  return name.split(':').reduce((p: string, c: string) => `${capitalize(p)} ${capitalize(c)}`)
}

function ProcessorCollection(props: ProcessorCollectionProps) {
  const [processors, setProcessors] = useState<any>(Array.isArray(props.data) ? props.data : [])
  const [selectedProcessor, setSelectedProcessor] = useState<GenericOption>()
  const [availableProcessorChoices, setAvailableProcessorChoices] = useState<Array<GenericOption>>(
    []
  )
  const [availableProcessorPlugins, setAvailableProcessorPlugins] = useState<
    Array<ProcessorPluginList>
  >([])
  const [isLoading, setLoading] = useState<boolean>(false)

  const { data: processorList } = useListProcessor({
    staleTime: 20 * (60 * 1000), // 20 min,
  })

  const searchProcessorPlugins = useCallback(async () => {
    setAvailableProcessorPlugins(processorList || [])
  }, [processorList])

  const addProcessor = useCallback(async () => {
    if (!selectedProcessor || !selectedProcessor.value) return

    setLoading(true)
    const { response, error } = await getProcessor(String(selectedProcessor.value))

    if (error) {
      setLoading(false)
      toast.error(error)
      return
    }

    const processorDetails: ProcessorPlugin = response?.data
    let defaultRootDefinitionType: any = {}

    if (processorDetails.schema) {
      const parsedSchema = JSON.parse(processorDetails.schema)
      if (parsedSchema.type === 'array') defaultRootDefinitionType = []
    }

    setProcessors((previousData: any) => [
      ...previousData,
      { [selectedProcessor.value as string]: defaultRootDefinitionType },
    ])
    setLoading(false)
  }, [selectedProcessor])

  const updateProcessor = useCallback((index: number, processorData: any) => {
    setProcessors((previousData: any) => {
      const newData = previousData
      newData[index] = processorData
      return [...newData]
    })
  }, [])

  const removeProcessor = useCallback((index: number) => {
    setProcessors((previousData: any) => [
      ...previousData.filter((e: any, i: number) => i !== index),
    ])
  }, [])

  const getProcessorName = useCallback(
    (index: number) => {
      const processor = processors[index]
      const processorName = Object.keys(processor)[0]
      return processorName
    },
    [availableProcessorPlugins, processors]
  )

  const getProcessorCapitalizedName = useCallback(
    (name: string) => capitalizeProcessorName(name),
    []
  )

  const reorderUp = useCallback(
    (index: number) => {
      if (!processors) return
      if (index + 1 <= processors.length && index > 0) {
        const moveDown = processors[index - 1]
        const moveUp = processors[index]
        processors[index] = moveDown
        processors[index - 1] = moveUp
        setProcessors([...processors])
      }
    },
    [processors]
  )

  const reorderDown = useCallback(
    (index: number) => {
      if (!processors) return
      if (index + 1 < processors.length) {
        const moveDown = processors[index]
        const moveUp = processors[index + 1]
        processors[index] = moveUp
        processors[index + 1] = moveDown
        setProcessors([...processors])
      }
    },
    [processors]
  )

  const getProcessorSummary = useCallback(
    (index: number) => {
      if (!processors) return ''
      const data = processors
      const definition = data[index]
      const name = getProcessorName(index)
      return `${JSON.stringify(definition[name]).slice(0, 120)}...`
    },
    [processors]
  )

  useEffect(() => {
    searchProcessorPlugins()
  }, [props])

  useEffect(() => {
    if (!availableProcessorPlugins) return

    availableProcessorPlugins.forEach(p => {
      setAvailableProcessorChoices(previous => [
        ...previous,
        {
          value: p.name,
          label: capitalizeProcessorName(p.name),
        },
      ])
    })
  }, [availableProcessorPlugins])

  useEffect(() => {
    // Every time data change, call onChange ancestor
    props.onChange(processors)
  }, [processors])

  useEffect(() => {
    setProcessors(props.data)
  }, [props.data])

  return (
    <Layout.Stack className="w-full">
      <Layout.Stack space="l">
        <Layout.Group align="center">
          <Select
            data-testid="processor-selector"
            name="select_processor_plugin"
            className="w-96"
            style={{
              left: 0,
              top: 0,
            }}
            options={availableProcessorChoices}
            onChange={value => {
              if (!value || !value.target || !value.target.value) return
              const selection: any = value.target.value
              setSelectedProcessor({
                label: selection.label,
                value: selection.value,
              })
            }}
          />
          <IconButton
            data-testid="add-processor"
            onClick={() => {
              addProcessor()
            }}
            scale="large"
          >
            <Icon name="add" />
          </IconButton>
          {isLoading && <LoadingDots />}
        </Layout.Group>
        <Layout.Stack space="s" data-testid="processors-list">
          {processors &&
            Array.isArray(processors) &&
            processors.map((e: any, processorIndex: number) => {
              const processorName = getProcessorName(processorIndex)
              const processorCapitalizedName = getProcessorCapitalizedName(processorName)
              const summary = getProcessorSummary(processorIndex) || ''
              return (
                <ProcessorItem
                  key={`processor_item_${processorIndex}`}
                  index={processorIndex}
                  definition={e}
                  formattedName={processorCapitalizedName}
                  summary={summary}
                  name={processorName}
                  onUpdateProcessor={(index: number, value: any) => {
                    updateProcessor(index, value)
                  }}
                  onRemoveProcessor={(index: number) => {
                    removeProcessor(index)
                  }}
                  onReorderUp={(index: number) => {
                    reorderUp(index)
                  }}
                  onReorderDown={(index: number) => {
                    reorderDown(index)
                  }}
                />
              )
            })}
        </Layout.Stack>
      </Layout.Stack>
    </Layout.Stack>
  )
}

export default ProcessorCollection
