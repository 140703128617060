import { AxiosRequestConfig } from 'axios'
import { del, get, put } from 'common/HTTPClient'
import { DefaultHTTPResponse } from 'common/HTTPClient/DefaultHTTPResponse'
import { to } from 'common/helpers/awaitTo'
import { InstallationDetail, InstallationList } from 'common/types/kraken-core/Installation'
import { PaginatedResponse } from 'common/types/kraken-core/Pagination'
import { SearchInstallationsPageRequest } from './types'
import { ConnectionSetting } from '../../../common/types/kraken-core/ConnectionSettings'

export async function getInstallation(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<InstallationDetail>> {
  const [error, response] = await to(get(`/installations/${id}`, { ...config }))
  return { error, response }
}

export async function searchInstallations(
  data?: SearchInstallationsPageRequest,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<PaginatedResponse<InstallationList>>> {
  const params: SearchInstallationsPageRequest = {
    ...data,
    page: data?.page || 1,
    page_size: data?.page_size || 10,
  }
  const filterByName = data?.filters?.find(f => f.property === 'name').value
  if (filterByName) params.name = filterByName
  const filterByInstallationType = data?.filters?.find(f => f.property === 'installation_type')
    .value
  if (filterByInstallationType) params.installation_type = filterByInstallationType
  const [error, response] = await to(get('/installations', { ...config, params }))
  return { error, response }
}

export async function updateInstallation(
  installation: InstallationDetail,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<InstallationDetail>> {
  const [error, response] = await to(
    put(`/installations/${installation.id}`, installation, { ...config })
  )

  if (error) throw error

  return { error, response }
}

export async function deleteInstallation(
  id: string,
  config?: AxiosRequestConfig
): Promise<DefaultHTTPResponse<InstallationDetail>> {
  const [error, response] = await to(del(`/installations/${id}`, { ...config }))

  if (error) throw error

  return { error, response }
}
