import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import {
  MessageDashboardContextLocalStorage,
  MessageDashboardContextStoreManager,
} from './LocalStorageManager/messagesDashboardContextStoreManager'
import {
  CreateNewDashboardView,
  DuplicateDashboardView,
  MessageDashboardView,
  MessageDetailWrapper,
} from './types'
import getID from 'common/helpers/getID'
import { useHistory } from 'react-router-dom'
import { parse } from 'qs'
import isEmpty from 'lodash.isempty'
import moment from 'moment'
import { useGetMessageStatusTags } from './api'

export interface MessagesDashboardContextState {
  messageDetails: MessageDetailWrapper
  getActiveViewIndex: () => number
  getViews: () => Array<MessageDashboardView>
  getDetailView: () => MessageDashboardView
  loadViews: () => void
  removeView: (view: MessageDashboardView) => void
  updateView: (view: MessageDashboardView) => void
  duplicateView: (view: DuplicateDashboardView) => MessageDashboardView
  createNewView: (view: CreateNewDashboardView) => MessageDashboardView
  setActiveViewIndex: (index: number) => void
}

export const MessagesDashboardContext = createContext<MessagesDashboardContextState | undefined>(
  undefined
)

export interface MessagesDashboardContextWrapperProps {
  children: any
}

export const CONSTANTS = {
  DEFAULT_VIEW: {
    index: 0,
    id: 'all',
    name: 'All',
    filters: {
      created_at_from: moment().subtract(1, 'hour').toISOString(),
    },
    pageSize: 50,
  },
  DETAIL_VIEW: {
    index: -1,
    id: 'details',
    name: 'details',
    filters: {},
    pageSize: 0,
  },
}

function MessagesDashboardContextProvider({ children }: MessagesDashboardContextWrapperProps) {
  const history = useHistory()
  const [views, setViews] = useState<Array<MessageDashboardView>>([])
  const [activeIndexView, setActiveIndexView] = useState<number>(0)

  const { data: messageStatusTags } = useGetMessageStatusTags()

  const handleLoadViews = useCallback(() => {}, [])

  const handleRemoveView = useCallback(
    (view: MessageDashboardView) => {
      setViews(current => {
        return [...current.filter(v => v.id !== view.id)]
      })
      // By default, always go back to all
      setActiveIndexView(0)
    },
    [views]
  )

  const handleUpdateView = useCallback(
    (view: MessageDashboardView) => {
      setViews(current => {
        return [
          ...current.map(v => {
            return v.id === view.id
              ? {
                  ...view,
                }
              : v
          }),
        ]
      })
    },
    [views]
  )

  const handleCreateNewView = useCallback(
    (create: CreateNewDashboardView) => {
      // Views with index less than zero, are special ones, must not be considered
      const index = views.filter(v => v.index >= 0).length
      const name = isEmpty(create.name) ? `New view - ${index}` : create.name

      if (isEmpty(create.filters)) {
        create.filters = {
          created_at_from: moment().subtract(1, 'hour').toISOString(),
        }
      }

      const filters =
        Boolean(create.inheriteFiltersFromActiveView) === true
          ? views.find(v => v.index === activeIndexView)?.filters || {}
          : create.filters
      const newView = {
        id: getID(),
        index,
        name,
        filters,
        pageSize: CONSTANTS.DEFAULT_VIEW.pageSize,
      }
      setViews(c => {
        return [...c, newView]
      })

      setActiveIndexView(newView.index)
      return newView
    },
    [views]
  )

  const handleDuplicateView = useCallback(
    (duplicate: DuplicateDashboardView) => {
      const newDuplicated: CreateNewDashboardView = {
        name: duplicate.source.name,
        filters: { ...duplicate.source.filters },
        inheriteFiltersFromActiveView: false,
      }
      return handleCreateNewView(newDuplicated)
    },
    [handleCreateNewView]
  )

  const handleGetViews = useCallback(() => {
    return views.filter(v => v.id !== CONSTANTS.DETAIL_VIEW.id)
  }, [views])

  const handleGetDetailView = useCallback(() => {
    return views.find(v => v.id === CONSTANTS.DETAIL_VIEW.id) || CONSTANTS.DETAIL_VIEW
  }, [views])

  useEffect(function init() {
    const dashboardContext = MessageDashboardContextStoreManager.getDashboardContext()
    if (dashboardContext && dashboardContext.views) {
      const allView = dashboardContext.views.find(v => v.id === CONSTANTS.DEFAULT_VIEW.id)
      if (!allView) {
        dashboardContext.views.push(CONSTANTS.DEFAULT_VIEW)
      }

      const detailView = dashboardContext.views.find(v => v.id === CONSTANTS.DETAIL_VIEW.id)
      if (!detailView) {
        dashboardContext.views.push(CONSTANTS.DETAIL_VIEW)
      }

      const sortedViews = dashboardContext.views.sort((a, b) =>
        a.index < b.index ? -1 : a.index > b.index ? 1 : 0
      )
      setViews(sortedViews)
    }
  }, [])

  useEffect(
    function onUpdateViews() {
      const context: MessageDashboardContextLocalStorage = {
        views,
      }
      MessageDashboardContextStoreManager.saveDashboardContext(context)
    },
    [views]
  )

  useEffect(function onInit() {
    // By default, The 'All' view will load filters from url if any
    if (activeIndexView === CONSTANTS.DEFAULT_VIEW.index) {
      const filtersFromQueryString = parse(history.location.search.replace('?', ''))
      const defaultView =
        views.find(v => v.id === CONSTANTS.DEFAULT_VIEW.id) || CONSTANTS.DEFAULT_VIEW

      if (!isEmpty(filtersFromQueryString))
        handleUpdateView({
          ...defaultView,
          filters: {
            ...filtersFromQueryString,
          },
        })
    }
  }, [])

  const contextState: MessagesDashboardContextState = {
    getActiveViewIndex: () => activeIndexView,
    setActiveViewIndex: index => setActiveIndexView(index),
    createNewView: handleCreateNewView,
    loadViews: handleLoadViews,
    updateView: handleUpdateView,
    removeView: handleRemoveView,
    duplicateView: handleDuplicateView,
    messageDetails: {
      isLoading: false,
      messageStatusTags: messageStatusTags || [],
    },
    getViews: handleGetViews,
    getDetailView: handleGetDetailView,
  }

  return (
    <MessagesDashboardContext.Provider value={contextState}>
      {children}
    </MessagesDashboardContext.Provider>
  )
}

export function useMessagesDashboardContext() {
  const context = useContext(MessagesDashboardContext)
  if (context === undefined) {
    throw new Error(
      'useMessagesDashboardContext must be used within a MessagesDashboardContextProvider'
    )
  }
  return context
}

export default MessagesDashboardContextProvider
