import {
  IconArrowsExpandSide,
  IconArrowLeft,
  IconArrowRight,
  IconArrowsCornerDown,
  IconArrowsCornerUp,
  IconAttention,
  IconCaretDown,
  IconClip,
  IconClose,
  IconCopy,
  IconEye,
  IconFlagCheckered,
  IconMarkersPin,
  IconPencil,
  IconPlus,
  IconTrash,
  IconTruckContainer,
  IconTruckDryvan,
  IconTruckFlatbed,
  IconTruckLtlDryvan,
  IconTruckPtlDryvan,
  IconTruckRailDrybox,
  IconTruckReefer,
  IconCheck,
  IconBolt,
  IconDownload,
  IconInfoCircle,
  IconArrowsExpand,
  IconExpand,
  IconSkull,
  IconCog,
  IconImport,
  IconExport,
  IconRetry,
  IconCalendar,
  IconDotsHorizontal,
} from '@loadsmart/icons'

const mapping: Record<string, any> = {
  'caret-down': IconCaretDown,
  'chevron-right': IconArrowsExpandSide,
  'marker-pin': IconMarkersPin,
  'truck-container': IconTruckContainer,
  'truck-dryvan': IconTruckDryvan,
  'truck-flatbed': IconTruckFlatbed,
  'truck-ltl-dryvan': IconTruckLtlDryvan,
  'truck-ptl-dryvan': IconTruckPtlDryvan,
  'truck-rail-drybox': IconTruckRailDrybox,
  'truck-reefer': IconTruckReefer,
  'arrow-right': IconArrowRight,
  'arrow-left': IconArrowLeft,
  'arrow-expand': IconArrowsExpand,
  'arrow-corner-down': IconArrowsCornerDown,
  'arrow-corner-up': IconArrowsCornerUp,
  expand: IconExpand,
  add: IconPlus,
  bolt: IconBolt,
  check: IconCheck,
  clip: IconClip,
  close: IconClose,
  copy: IconCopy,
  edit: IconPencil,
  flag: IconFlagCheckered,
  download: IconDownload,
  peek: IconEye,
  remove: IconTrash,
  warning: IconAttention,
  info: IconInfoCircle,
  skull: IconSkull,
  cog: IconCog,
  import: IconImport,
  export: IconExport,
  retry: IconRetry,
  calendar: IconCalendar,
  'dots-horizontal': IconDotsHorizontal,
}

export type IconName =
  | 'add'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-expand'
  | 'arrow-corner-up'
  | 'arrow-corner-down'
  | 'bolt'
  | 'calendar'
  | 'caret-down'
  | 'caret-up'
  | 'check'
  | 'clip'
  | 'chevron-right'
  | 'close'
  | 'cog'
  | 'copy'
  | 'download'
  | 'edit'
  | 'expand'
  | 'export'
  | 'flag'
  | 'import'
  | 'marker-pin'
  | 'peek'
  | 'retry'
  | 'remove'
  | 'skull'
  | 'truck-container'
  | 'truck-dryvan'
  | 'truck-flatbed'
  | 'truck-ltl-dryvan'
  | 'truck-ptl-dryvan'
  | 'truck-rail-drybox'
  | 'truck-reefer'
  | 'warning'
  | 'info'
  | 'dots-horizontal'

export default mapping
